import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import appState from "../../../state/AppState";
import AppLogger from "../../../util/AppLogger";

@observer
export default class ZoneColorList extends Component {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        id: PropTypes.string,
        // Objeto con todos los campos que estamos mostrando
        row: PropTypes.object,
        // Nombre del campo a mostrar
        name: PropTypes.string,
        // Valor del campo a mostrar
        value: PropTypes.string,
    };


    render() {

        this.log("ZoneColorList" );
        this.log( this.props.value);


        let props = this.props;
        let value = props.value;

        if (props.zoneWorkOrderList) {
            value = appState.typifiedState.arrZones[value] && appState.typifiedState.arrZones[value];
        }

        if (props.assetLocationList && props.clients != null) {
            value = appState.typifiedState.arrZones[appState.clientCacheState.getClientById(props.row.storeId).posZoneId] &&
                appState.typifiedState.arrZones[appState.clientCacheState.getClientById(props.row.storeId).posZoneId];
        }
        if (props.client) {
            value = this.props.row.posZone;
        }
        if (props.user) {
            value = this.props.row.zoneAsigned;
        }
        if (props.asset) {
            value = this.props.row.zoneAsigned;
        }
        return (
            <div>
                {value &&
                <span>< i style={{ color: value && value.color }}
                          className={props.icon} /></span>
                }
                <span style={{ marginLeft: 8 }}>{value && value.code}</span>
                {props.children}
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
