import { observable } from 'mobx';
import Article from "../models/ArticleModel";
import BaseModelState from "./BaseModelState";


class ArticleState extends BaseModelState {

    @observable articles = [];

    async getArticles() {
        let articleQuery = new Article();
        {
            let result = await articleQuery.find();
            if (result == null) {
                result = [];
            }
            this.articles = result;
        }
    }


}

export default ArticleState;
