import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import TypifiedTableLinks from "./TypifiedTableLinks";
import TabsData from "../../layout/TabsData";
import TabsComponent from "../../components/fields/TabsComponent";
import InfoBlock from "../../components/InfoBlock";
import withTranslate from "../../translator/withTranslate";
import VsfButton from "../../components/VsfButton";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import HierarchicalEditTextListItem from "../../components/list/HierarchicalEditTextListItem";

@observer
class TypifiedValueList extends BaseList {

    constructor(props) {
        super(props);
        this.defaultOrder = "name";
        this.quickEdit = false;
        this.tabsData = new TabsData();
        this.includeSavedFavourites = false;
        this.includeChangeColumns = true;
        this.includeFilters = false;
        this.includeDownload = false;
        this.includeSearch = false;
    }

    async componentDidMount() {
        this.reloadTypifiedValuListHierarchyList();
    }

    async componentDidUpdate() {
        this.reloadTypifiedValuListHierarchyList();
    }

    async reloadTypifiedValuListHierarchyList() {

        // Carga del selector jerarquico
        this.log("componentDidUpdate.getTypifiedValuListHierarchy");
        const params = new URLSearchParams(this.props.location.search);
        this.type = params.get('type');
        await appState.typifiedState.loadTypifiedValues(this.type);
        this.log(appState.typifiedState.typifiedValuesForList);

        // Carga del listado normal
        this.log("getListData");
        let listData = this.getListData();
        listData.connection.items = appState.typifiedState.typifiedValuesForList;

    }

    getListFields() {
        return [
            /*{title:"Type", name:"type", component:TextListItem, visible: true},*/
            {
                title: this.props.t("Code"),
                name: "code",
                link: false,
                component: HierarchicalEditTextListItem,
                visible: true
            },
            { title: this.props.t("Value"), name: "value", component: TextListItem, visible: true },
            { title: this.props.t("Color"), name: "color", component: TextListItem, visible: true },
        ];
    }

    getListState() {
        return appState.typifiedValueState;
    }

    getDefaultOrder() {
        return "code";
    }

    getModelQuery() {
        const params = new URLSearchParams(this.props.location.search);
        let result = new TypifiedValueModel();
        const type = params.get('type');
        result.type = type;
        return result;
    }

    render() {
        let typifiedValue = new TypifiedValueModel();
        this.log("TypifiedValueList.render");
        const params = new URLSearchParams(this.props.location.search);
        this.propsUtil = new PropsUtil(this.props);
        const type = params.get('type');
        const { t, i18n } = this.props;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;
        this.log(type);
        return (
            <InfoBlock componentObject={this}>
                <ListItemSelect stateListColumnsSelect={this.stateListColumnsSelect} name={this.graphOperation} />

                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div class="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={"Maestros - " }
                    subtitle={typifiedValue.getTextFromType(type) || t("Listado")}
                    buttons={[{
                        label: 'Nuevo',
                        iconName: "fas fa-plus-circle",
                        link: "/typifiedValue/form/?type=" + params.get('type')
                    }]}
                />

                <div className="card mb-3">
                    {/*<TabsComponent*/}
                        {/*id={"?"}*/}
                        {/*tabs={this.tabsData.getDataTypified()}*/}
                        {/*active="Órdenes de Trabajo"*/}
                    {/*/>*/}
                    <div className="container-fluid">
                        <div className="row pt-3 pb-3 pl-3">
                            <TypifiedTableLinks getUrl={type} />
                            <div className="card-body col-md-8 col-lg-10 pb-0">

                                <SearchComponent deleteFilter={this.deleteFilter}
                                                 listState={this.getListState()}
                                                 listData={this.getListData()}
                                                 numEntriesPerPage={this.numEntriesPerPage}
                                                 fields={fields}
                                                 onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                                 model={this.getModelQuery()}
                                                 onClickAplicarFiltros={() => this.onClickAplicarFiltros()}
                                                 includeSavedFavourites={this.includeSavedFavourites}
                                                 includeChangeColumns={this.includeChangeColumns}
                                                 includeFilters={this.includeFilters}
                                                 includeDownload={this.includeDownload}
                                                 stateListColumnsSelect={this.stateListColumnsSelect}
                                                 includeSearch={this.includeSearch}
                                                 {...props}
                                                 updateFilters={(e) => this.updateInputFilterEvent(e)}
                                                 listComponent={this}
                                                 favouriteModalOpen={appState.layoutState.favouritesSaveMenuOpen}
                                                 toggleFavouriteModal={() => appState.layoutState.clickFavouritesSaveMenuOpen()}
                                />
                                <ListComponent listState={this.getListState()}
                                               listData={this.getListData()}
                                               status={this.getListStatus()}
                                               fields={fields}
                                               urlForm={"/typifiedValue/form"}
                                               quickEdit={this.quickEdit}
                                               {...props}
                                               params={"type=" + type}
                                               hierarchy={appState.typifiedState.isTypifiedValueHierarchy(type)}
                                >
                                    <EmailList name="name" />
                                </ListComponent>
                                <div className="text-right">
                                    <VsfButton
                                        //disabled={this.state.hasSomeRowEditing}
                                        label={"Crear nueva"}
                                        onClick={_ => this.propsUtil.changeUrl("/typifiedValue/form/?type=" + type)}
                                        //isEditing={this.state.hasSomeRowEditing}
                                        classButton="btn--loader"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />


            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(withApollo(TypifiedValueList));
