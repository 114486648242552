import {observable} from 'mobx';
import util from "../util/Util";
import SlotModel from "../models/SlotModel";
import {gql} from "apollo-boost";
import ApolloProxy from "../network/ApolloProxy";
import apoloClient from "../storage/ApoloClientInstance";


class SlotState {

    @observable slots = [];

    async reloadSlotsFromOrderId(orderId) {
        console.log("ContactState.reloadContactsFromClientId orderId:" + orderId);
        let slotsQuery = new SlotModel();
        slotsQuery.orderId = orderId;
        if (util.hasValue(slotsQuery.orderId)) {
            let result = await slotsQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.slots = result;
        }
    }


}

export default SlotState;
