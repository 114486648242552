import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import BaseList from "../base/BaseList";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo/index";
import BaseListFilter from "../../subpages/BaseListFilter";
import withRouter from "react-router-dom/es/withRouter";
import withTranslate from "../../translator/withTranslate";
import TextListItem from "../../components/list/TextListItem";
import WorkOrderStateListItem from "../../components/list/custom/WorkOrderStateListItem";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import WorkOrderModel from "../../models/WorkOrderModel";
import TextListItemResponsive from "../../components/list/TextListItemResponsive";
import ZoneColorList from "../../components/list/custom/ZoneColorList";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import WorkOrderListFilters from "./WorkOrderListFilters";
import ListPageCounts from "../../components/ListPageCounts";
import CreatedByListItem from "../../components/list/CreatedByListItem";
import DateListItem from "../../components/list/DateListItem";
import IsoDateListItem from "../../components/list/IsoDateListItem";


@observer
class WorkOrderList extends BaseList {


    constructor(props) {
        super(props);
        this.quickEdit = true;
        this.quickView = true;
        this.deleteFilter = this.deleteFilter.bind(this);
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.nameVariableStateFilter = "workOrderListFilters";
    }


    getListFields() {
        return [
            {
                title: this.props.t("Número OT"),
                name: "code",
                link: true,
                component: (props) => <WorkOrderStateListItem workOrderStatusCode={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Estado"),
                name: "statusDispatcherWorkOrder",
                component: (props) => <WorkOrderStateListItem workOrderStatusCode={true} workOrderStatus={true}
                                                              link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Código Orden"),
                name: "order.code",
                withOrder: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Tipo OT"),
                name: "type",
                link: false,
                component: (props) => <TextListItem typeOTList={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("F. Creación"),
                name: "createdAt",
                component: (props) => <IsoDateListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Subtipo OT"),
                name: "subType",
                link: false,
                component: (props) => <TextListItem subTypeOTList={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Cliente"),
                name: "client.name",
                withOrder: true,
                link: false,
                component: (props) => <TextListItemResponsive link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Ubicación Equipo"),
                name: "asset.location",
                withOrder: false,
                component: (props) => <TextListItem assetLocationOTList={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Zona"),
                withOrder: true,
                link: false,
                name: "client.posZoneId",
                component: (props) => <ZoneColorList zoneWorkOrderList={true} link={true}
                                                     icon="fas fa-map-marked-alt" {...props} />,
                visible: true
            },
            {
                title: this.props.t("Número Equipo"),
                name: "assetPlate",
                link: false,
                component: (props) => <TextListItem assetPlate={true} align="left" link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Tipo Equipo"),
                name: "asset.type",
                withOrder: false,
                link: false,
                component: (props) => <TextListItem assetType={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Subtipo Equipo"),
                name: "asset.subType",
                withOrder: false,
                link: false,
                component: (props) => <TextListItem assetSubType={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Técnico Principal"),
                name: "assignedToMainId",
                link: false,
                component: (props) => <CreatedByListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Descripcion de avería "),
                name: "generalDescription",
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Próxima planificación "),
                name: "nextScheduledDate",
                component: (props) => <DateListItem link={true} {...props} />,
                visible: true
            },
        ];
    }

    getListState() {
        return appState.workOrderState;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.userCacheState.loadUserCache();
        await appState.clientCacheState.loadClientCache();
        await appState.typifiedState.loadPriority();
        await appState.typifiedState.loadZones();
        await appState.typifiedState.loadWorkOrderType();
        await appState.typifiedState.loadModel();
        await appState.typifiedState.loadStatus();
        await appState.typifiedState.loadStoreId();
        await appState.typifiedState.loadSubTypeWorkOrder();
        await appState.typifiedState.loadAssetType();
        await appState.typifiedState.loadAssetSubtype();
        await appState.typifiedState.loadOrderStatus();
        appState.typifiedState.arrayLoadWorkOrderType();
        appState.typifiedState.arrayLoadWorkOrderStatusDispatcher();
        appState.typifiedState.arrayLoadZones();
        appState.typifiedState.arrayLoadsubTypeWorkOrders();
        appState.typifiedState.arrayLoadAssetType();
        appState.typifiedState.arrayLoadAssetSubType();


    }

    getModelQuery() {
        let result = new WorkOrderModel();
        result.addRelatedTable("order");
        result.addRelatedTable("client");
        result.addRelatedTable("asset");
        return result;
    }

    openQuickEdit(row) {
        this.getListState().quickEdit.row = row;
        this.propsUtil.changeUrlRequest({
            workOrderId: row.id,
            rightModal: "modalworkorderls",
            rightModalTab: "workorder"
        })
    }

    render() {
        let { t } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <InfoBlock componentObject={this}>

                <ListItemSelect stateListColumnsSelect={this.stateListColumnsSelect}
                                name={this.graphOperation}
                                subtitle={t('Órdenes de Trabajo')}
                />

                <BaseListFilter
                    title={'Órdenes de Trabajo'} listComponent={this} stateFilter={this.stateListFilter}>
                    {this.renderFilters()}
                </BaseListFilter>

                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div className="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={t("Órdenes de Trabajo")}
                    subtitle={t("Listado")}
                >
                </PageTitle>

                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent deleteFilter={this.deleteFilter}
                                         listState={this.getListState()}
                                         listData={this.getListData()}
                                         numEntriesPerPage={this.numEntriesPerPage}
                                         fields={fields}
                                         model={this.getModelQuery()}
                                         {...props}
                                         includeSavedFavourites={this.includeSavedFavourites}
                                         includeChangeColumns={this.includeChangeColumns}
                                         onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                         includeFilters={this.includeFilters}
                                         includeDownload={this.includeDownload}
                                         includeSearch={this.includeSearch}
                                         listComponent={this}
                                         favouriteModalOpen={this.state.favouriteModalOpen}
                                         toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                                         stateListColumnsSelect={this.stateListColumnsSelect}
                                         onClickAplicarFiltros={() => this.onClickAplicarFiltros()}
                                         {...props}
                                         updateFilters={(e) => this.updateInputFilterEvent(e)}
                        />
                        <ListComponent
                            quickView={this.quickView}
                            {...props}
                            listState={this.getListState()}
                            listData={this.getListData()}
                            status={this.getListStatus()}
                            fields={fields}
                            urlForm={"/work-order/form"}
                            openQuickEdit={(row) => this.openQuickEdit(row)}
                        />
                    </div>
                </div>
                <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                                listState={this.getListState()}
                                listData={this.getListData()} {...props} />
                <PaginationComponent
                    listData={this.getListData()}
                    numEntriesPerPage={this.numEntriesPerPage}
                    {...props}
                />

            </InfoBlock>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <WorkOrderListFilters key={"workOrderlist"} />
            </React.Fragment>
        )
    }


}

export default withRouter(withApollo(withTranslate(WorkOrderList, "WorkOrderList")));
