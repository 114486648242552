import React from "react";
import AbstractModel from "./AbstractModel";

export default class WorkOrderCartArticleModel extends AbstractModel {

    id;
    orderId;
    workOrderId;
    statusId;
    quantity;
    articleCode;
    articleName;
    articleId;
    used;
    articlePrice;
    articleCost;
    externalArticle;


    nameMainType = "workOrderCartArticle";
    graphFindByIdOperation = "workOrderCartArticle";
    graphFindOperation = "workOrderCartArticlesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "orderId": "",
            "workOrderId": "",
            "statusId": "",
            "quantity": "",
            "articleCode": "",
            "articleName": "",
            "articleId": "",
            "used": "",
            "articlePrice": "",
            "articleCost": "",
            "externalArticle": "",
        };
        return result;
    }

    async getOrderArticlesFromOrder(orderId) {
        let result = [];
        if (orderId != null) {
            let orderQuery = new WorkOrderCartArticleModel();
            orderQuery.orderId = orderId;
            result = await orderQuery.find();
        }
        return result;
    }

}
