import React from 'react';
import {observer} from 'mobx-react';
import {withApollo} from "react-apollo";
import withTranslate from "../translator/withTranslate";
import SavedFilterModel from "../models/SavedFilterModel";
import AppLogger from "../util/AppLogger";
import SlotModel from "../models/SlotModel";
import VsfButton from "../components/VsfButton";
import ScheduleVisitSlot from "./scheduledVisits/ScheduleVisitSlot";
import ScheduleVisitSlotModel from "../models/ScheduleVisitSlotModel";
import ChangeModel from "../models/ChangeModel";
import OrderModel from "../models/OrderModel";
import InputTypeComponent from "../components/fields/InputTypeComponent";
import util from "../util/Util";
import DateInputField from "../components/fields/DateInputField";

@observer
class PruebasForm extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

        await this.test3NivelesFindById();
    }

    async persistSlot() {
        //await this.testChanges();
        //await this.testJSX();
        let slot=new SlotModel();
        slot.slotUserRole="MAIN";
        slot.workOrderIds="-1";
        slot.scheduledDuration=1;
        await slot.persist();
        await this.testSlotQuery();
    }

    async test3NivelesFindById() {

        let slot = new SlotModel();
        slot.addRelatedTable("workOrder");
        slot.addRelatedTable("workOrder.workLog");
        let result = await slot.findById("42");
        let resultPlain = result.toPlainObject();

        this.log({resultPlain});
        //Slot > WorkOrder > WorkLog
        //workOrder.

    }


    async test3Niveles() {

        let slot = new SlotModel();
        slot.id="42";
        slot.addRelatedTable("workOrder");
        slot.addRelatedTable("workOrder.workLog");
        let result = await slot.find();
        let resultPlain = result[0].toPlainObject();

        this.log({resultPlain});
        //Slot > WorkOrder > WorkLog
        //workOrder.

    }

    async testChanges() {
        let order = new OrderModel();
        let changeQuery = new ChangeModel();
        changeQuery.modelName = order.getNameModelInDotNet();
        changeQuery.orderBy="id";
        changeQuery.orderMode="DESC";
        let changes = await changeQuery.find();

        this.log("testChanges");
        this.log(this.changes);
    }

    async testSavedFilter() {

        let savedFilterModel = new SavedFilterModel();
        savedFilterModel.modelName = "workOrders";
        savedFilterModel.isShared = false;
        let savedFilterModels = await savedFilterModel.find();
        this.log("savedFilterModels");
        this.log(this.savedFilterModels);
    }



    async testSlotQuery() {
        let slotQuery = new SlotModel();
        let dateFrom ="2019-06-01";

        slotQuery.filters = [
            {"fieldName": "scheduledTime", "fieldValue": "", "filterOperator": "EQ"},
        ]
        slotQuery.find();
    }

    async testSlotPersist() {
        let slotModel = new SlotModel();
        slotModel.orderId = "19";
        slotModel.workOrderIds = "-1";
        slotModel.scheduledDuration = 1;
        slotModel.slotUserRole = SlotModel.MAIN;
        slotModel.persist();
    }

    async testScheduleVisitSlotPersist() {
        let scheduleVisitSlotModel = new ScheduleVisitSlotModel();
        scheduleVisitSlotModel.scheduleVisitId = "19";
        scheduleVisitSlotModel.scheduledDuration = 1;
        scheduleVisitSlotModel.slotUserRole = SlotModel.MAIN;
        scheduleVisitSlotModel.persist();
    }

    testJSX() {
        let component = this.getList();
        this.log(component.props.required);
        this.log(component);
        this.locateRequired(component);
    }

    //Esta función itera sobre componentes e indica si son requeridos o no.
    locateRequired(component) {
        if (component!=null) {
            if (component.props.required) {
                this.log("Component required "+component.props.id);
            }
            if (Array.isArray(component.props.children)) {
                for(let componentChild of component.props.children) {
                    this.locateRequired(componentChild);
                }
            } else {
                this.locateRequired(component.props.children);
            }
        }
    }

    getList() {
        return (
            <div>
            <InputTypeComponent
                id={"2"}
    required={true}

            >

            </InputTypeComponent>
                <InputTypeComponent
                    id={"1"}
                    required={false}

                >

                </InputTypeComponent>


            </div>
        )
    }

    render() {
        const {t, i18n} = this.props;

        let timezone = util.getTimezone();
        let momentDate = util.getMomentFromDateWithoutTimezone("2019-09-18T19:00:00");
        let date = momentDate.toDate();
        return (
            <React.Fragment>
                Ejemplo de pruebas

                timezone:{timezone}
                <br/>
                    momentDate: {momentDate.toISOString() }
                <br/>
                date: {date.toString() }
                <table>
                    <tbody>
                        <tr>
                            <td>
                                Botón activo
                                {t("Repuestos")}
                            </td>
                            <td>
                                <VsfButton

                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                Fecha
                <DateInputField
                    prefix={"fas fa-calendar-alt"}
                    name={"scheduledTime"}
                    type={"text"}
                    classGroup={"col-3"}
                />

            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(withApollo(PruebasForm));
