import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import withTranslate from "../../translator/withTranslate";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import Select2Component from "../../components/fields/Select2Component";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import OrderModel from "../../models/OrderModel";
import CreateAndModify from "../../components/fields/CreateAndModify";
import { observable } from "mobx";
import PropsUtil from "../../util/PropsUtil";
import util from '../../util/Util';
import ContactModel from "../../models/ContactModel";
import PopUp from "../../components/PopUp";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import AlertModal from "../../subpages/AlertModal";
import MoreButtons from "../../components/MoreButtons";
import PropTypes from 'prop-types';
import InfoBlock from "../../components/InfoBlock";
import ErrosFromGql from "../../components/status/ErrosFromGql";
import SlotModel from "../../models/SlotModel";
import AppLogger from "../../util/AppLogger";
import moment from "moment";
import SpinnerClipLoaderComponent from "../../network/SpinnerClipLoaderComponent";

@observer
class OrderForm extends BaseForm {

    nameMainType = "order";
    @observable currentModalState = { modalOpen: false };

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        //Si este campo está relleno, lo busca a la hora de obtener el registro
        this.queryFieldWithId = "orderId";
        this.state = {
            errorFormulario: false,
            modalLoading: false,
        }
    }

    /**
     * El componente ya ha cargado el tipo de datos principal
     * @returns {Promise<void>}
     */
    async didReloadFormData(model) {
        let order = model;
        if (util.hasValue(order.clientId)) {
            await appState.contactState.reloadContactsFromClientId(order.clientId);
            await appState.clientState.reloadClientFromClientId(order.clientId)
        }
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        if (this.props.fromRightModal) {
            this.propsUtil.changeUrlRequest({
                "orderId": model.id
            });
        }
        await this.crearNuevoSlotVacio(model, previousId);
        await appState.scheduleDropState.loadOrder(model.id);
        await appState.assetState.getCountSlotsFromOrder(model.id);
    }


    async crearNuevoSlotVacio(model, previousId) {
        if (util.hasValue(model.id) && util.esVacio(previousId)) {
            let slotModel = new SlotModel();
            slotModel.orderId = model.id;
            slotModel.workOrderIds = "-1";
            slotModel.scheduledDuration = 1;
            slotModel.slotUserRole = SlotModel.MAIN;
            try {
                await slotModel.persist();
            } catch (e) {
                this.log("EXCEPCION=>");
                this.log(e);
            }
            if (this.ifPathStartsWith("/schedule/bytechnical")) {
                appState.scheduleDropState.allSlotsDict[slotModel.id] = slotModel.toPlainObject();
                await appState.scheduleDropState.loadClients();

            }
        }
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        this.log(this.props.location.pathname);
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    async componentDidMountImpl() {
        this.log("OrderForm.componentDidMount() 1");
        await super.componentDidMountImpl();
        await this.reloadOrders(this.getFormId());
        await appState.typifiedState.loadPriority();
        await appState.typifiedState.loadManufacturer();
        await appState.typifiedState.loadManufacturerRef();
        await appState.typifiedState.loadStatus();
        await appState.clientCacheState.loadClientCache();
        await appState.typifiedState.loadStoreId();
        await appState.typifiedState.loadZones();
        await appState.typifiedState.loadOrderStatus();
        appState.typifiedState.arrayLoadZones();


    }

    getModelQuery() {
        return new OrderModel();
    }

    async reloadOrders(orderId) {
        await appState.workOrderState.reloadFromOrderId(orderId);
    }


    onEditarContacto(order) {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalclient",
            "idOrderClient": order.clientId,
            "orderId": order.id,
            "rightModalTab": "contacts"
        });
    }


    onEditar() {
        if (appState.layoutState.loadingForm) {
            this.setState({
                modalLoading: true,
            });
        } else {
            this.setState({
                rightModal: true,
                popUp: "change",
                modalLoading: false
            });
        }

    }


    onDeleteOrder() {
        this.setState({
            rightModal: true,
            popUp: "eliminar"
        })
    }


    noCancel() {
        this.setState({ rightModal: false, popUp: "" });
    }

    onGenerarPresupuesto() {

    }


    volver() {
        appState.layoutState.modalChangeOpen = false;
    }

    async deleteOrder(order, func,) {
        let modelo = this.getModelQuery();
        modelo.hidrate(order);
        modelo.id = this.getFormId();
        try {
            await modelo.remove();
        } catch (e) {
            this.log("EXCEPCION=>");
            this.log(e);
        }
        return this.propsUtil.changeUrl("/order/ls/")

    }


    async onChangeClientId() {
        let order = this.graphDataMainType;
        await appState.contactState.reloadContactsFromClientId(order.clientId);
        await appState.clientState.reloadClientFromClientId(order.clientId);
    }

    getContactFromContactId(contactId) {
        let result = {};
        for (let contact of appState.contactState.contacts) {
            if (util.hasValue(contactId)) {
                if (contact.id == contactId) {
                    result = contact;
                    break;
                }
            }
        }
        return result;
    }

    clickErroresFormulario() {
        this.setState({
            errorFormulario: false
        });
        this.graphStatus.mutationError = 0;
    }

    getZoneLabelById(zoneIdContactRequest) {
        return this.value = zoneIdContactRequest;
    }

    getOptionsContact() {
        let order = this.graphDataMainType;
        if (order == null) {
            order = {}
        }
        let result = [];
        for (let contactJs of appState.contactState.contacts) {
            let contactModel = new ContactModel();
            contactModel.hidrate(contactJs);
            if (contactModel.id != order.requestContactId)
                result.push({ "value": contactModel.id, "label": contactModel.getFullName() });
        }
        return result
    }

    getOptionsRequest() {
        let order = this.graphDataMainType;
        if (order == null) {
            order = {}
        }
        let result = [];
        for (let contactJs of appState.contactState.contacts) {
            let contactModel = new ContactModel();
            contactModel.hidrate(contactJs);
            if (contactModel.id != order.contactId)
                result.push({ "value": contactModel.id, "label": contactModel.getFullName() });
        }
        return result
    }

    async urlScheduleFromOrder(orderId) {
        let slotQuery = new SlotModel();
        slotQuery.orderId = orderId;
        let slot = await slotQuery.findPlainObject();
        appState.layoutState.backgroundScheduled = orderId;
        let url = '/schedule/bytechnical?date=' + moment(slot[0].scheduledTime).format("YYYYMMDD");
        this.props.history.push(url);
    }

    getZoneFromAppState(storeId) {
        let result = '';
        if (util.hasValue(storeId)) {
            let client = appState.clientState.client;
            result = client && appState.typifiedState.arrZones[client.posZoneId] && appState.typifiedState.arrZones[client.posZoneId].value;
        }
        return result;
    }


    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let id = this.getFormId() || this.props.id;
        this.log("OrderForm.render() id:" + id);
        this.log(this.graphStatus.mutationLoading);
        let order = this.graphDataMainType;
        if (order == null) {
            order = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        //let valueLabelContacts=[];
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""] && errorsMapped[""].map(error => error.message);
            this.state.errorFormulario = true;
        }
        let valueLabelContacts = this.getOptionsContact();
        let valueLabelRequest = this.getOptionsRequest();
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Pedido"}
                                onBackModal={() => this.onBackModal()}
                                subtitle={order.code || t("Nuevo")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            ><MoreButtons
                                actions={
                                    [
                                        { title: "Generar presupuesto", onClick: () => this.onGenerarPresupuesto() },
                                        { title: "Eliminar pedido", onClick: () => this.onDeleteOrder() },
                                        {
                                            title: "Ver en Calendario", onClick: () =>
                                                this.propsUtil.changeUrl(this.urlScheduleFromOrder(order.id))

                                        }
                                    ]
                                }
                            />
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataOrder()}
                                active="Datos Generales"
                                alarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Pedido")}
                            subtitle={order.code || t("Nuevo")}
                        > <MoreButtons
                            actions={
                                [
                                    {
                                        title: "Crear Nuevo Pedido", onClick: () => {
                                            this.propsUtil.changeUrl("/order/form/")
                                        }
                                    },
                                    { title: "Generar presupuesto", onClick: () => this.onGenerarPresupuesto() },
                                    { title: "Eliminar pedido", onClick: () => this.onDeleteOrder() },
                                    {
                                        title: "Ver en Calendario", onClick: () =>
                                            this.urlScheduleFromOrder(order.id)
                                    }
                                ]
                            }
                        />
                        </PageTitle>


                    }
                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.graphDataMainType.id}
                            tabs={this.tabsData.getDataOrder()}
                            active={"Datos Generales"}
                            fromRightModal={this.props.fromRightModal}
                        />
                        }
                        <div className={modalClasses.body}>
                            <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                error={this.graphStatus.mutationError}
                                working={this.graphStatus.networkWorking}
                            />
                            <LoadingOrErrorInfo //formLoading={this.graphStatus.formLoading}
                                formError={this.graphStatus.formError}
                            />

                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}
                            >
                                <div className={'card mb-3'}>
                                    <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                        error={this.graphStatus.mutationError}
                                        working={this.graphStatus.networkWorking}
                                    />
                                    <LoadingOrErrorInfo //formLoading={this.graphStatus.formLoading}
                                        formError={this.graphStatus.formError}
                                    />

                                    <ErrosFromGql errorsMapped={errorsMapped} />

                                    <FormWrapper>
                                        <GroupOfField
                                            title={t('Datos.')}
                                            subtitle={t('Solicitud')}
                                            icon='fas fa-file-import'
                                        >
                                            <CreateAndModify
                                                updatedAt={order.updatedAt}
                                                updatedBy={order.updatedBy}
                                                createdBy={order.createdBy}
                                                createdAt={order.createdAt}
                                                code={order.code}
                                                getFormId={order.id}

                                            />
                                            <Select2Component
                                                value={order.status}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"status"}
                                                title={t("Estado")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                options={appState.typifiedState.orderStatusForSelect}
                                                errors={errorsMapped.status}
                                            />
                                            <InputTypeComponent
                                                value={order.comments}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"comments"}
                                                title={t("Comentarios")}
                                                classGroup={"col-md-6 col-lg-8"}
                                                type={"text"}
                                                info={t('Ej: Por favor, avisar el día antes de la visita')}
                                                errors={errorsMapped.comments}
                                            />
                                            {!util.hasValue(this.getFormId()) ?

                                                <Fragment>
                                                    <Select2Component
                                                        value={order.clientId}
                                                        onChange={(e) => {
                                                            let result = this.updateInputEvent(e);
                                                            this.onChangeClientId();
                                                            return result;
                                                        }}
                                                        name="clientId"
                                                        title={t("Cliente / Establecimiento")}
                                                        classGroup={"col-lg-5"}
                                                        options={appState.clientCacheState.clientCacheForOrderSelect}
                                                        errors={errorsMapped.clientName}
                                                    />
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <InputTypeComponent
                                                        value={appState.clientState.client.name}
                                                        onChange={(e) => this.updateInputEvent(e)}
                                                        name={"clientId"}
                                                        title={t("Cliente / Establecimiento")}
                                                        classGroup={"col-lg-5 "}
                                                        readOnly={true}
                                                    />
                                                </Fragment>
                                            }


                                            <InputTypeComponent
                                                value={appState.clientState.client.posAddress}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"clientPosAddress"}
                                                title={t("Ubicación")}
                                                prefix={"fas fa-map-marker-alt"}
                                                placeholder={t("Ubicacion")}
                                                classGroup={"col-lg-5 "}
                                                readOnly={true}
                                                errors={errorsMapped.clientPosAddress}
                                            />

                                            <InputTypeComponent
                                                // value={util.getString(this.getClientById(order.clientId).posZoneId)}
                                                value={this.getZoneFromAppState(order.clientId)}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"clientZone"}
                                                title={t("Zona")}
                                                classGroup={"col-lg-2 "}
                                                prefix={"fas fa-map-marked-alt"}
                                                readOnly={true}
                                            />

                                            <Select2Component
                                                value={order.requestContactId}
                                                onChange={(e) => {
                                                    this.updateInputEvent(e)
                                                }}
                                                name={"requestContactId"}
                                                title={t("Nombre (Solicitante)")}
                                                classGroup={"col-md-6 col-xl-3"}
                                                options={valueLabelRequest}
                                                errors={errorsMapped.requestContactId}
                                                forceMedia={this.props.fromRightModal ? "md" : ""}
                                            />

                                            <InputTypeComponent
                                                value={util.getString(this.getContactFromContactId(order.requestContactId).phoneNumber)}
                                                prefix={"fas fa-phone-volume"}
                                                readOnly={true}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"clientPhoneRequest"}
                                                title={t("Teléfono (Solicitante)")}
                                                classGroup={"col-md-6 col-xl-2"}
                                                forceMedia={this.props.fromRightModal ? "md" : ""}
                                            />

                                            <Select2Component
                                                value={order.contactId}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"contactId"}
                                                title={t("Nombre (Contacto)")}
                                                classGroup={"col-md-6 col-xl-3"}
                                                options={valueLabelContacts}
                                                errors={errorsMapped.contactId}
                                                forceMedia={this.props.fromRightModal ? "md" : ""}
                                            />
                                            <InputTypeComponent
                                                value={util.getString(this.getContactFromContactId(order.contactId).phoneNumber)}
                                                prefix={"fas fa-phone-volume"}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                readOnly={true}
                                                name={"clientPhoneContact"}
                                                title={t("Teléfono (Contacto)")}
                                                classGroup={"col-md-6 col-xl-2"}
                                                forceMedia={this.props.fromRightModal ? "md" : ""}
                                            />

                                            <div
                                                className={this.props.fromRightModal ? "col-xl-3" : "col-xl-2 text-right"}>
                                                <div
                                                    className={this.props.fromRightModal ? "button-form-group mt-0" : "button-form-group"}>
                                                    {order.clientId != null ?
                                                        <span className="link-underline ico ico--edit"
                                                              onClick={() => {
                                                                  if (appState.layoutState.formWithoutChanges)
                                                                      this.onEditarContacto(order);
                                                                  else {
                                                                      this.onEditar()
                                                                  }
                                                              }}
                                                        >{t("Editar Datos de Cliente")}</span>
                                                        :
                                                        <span className="link-underline prohibido ico ico--edit"
                                                        >{t("Editar Datos de Cliente")}</span>
                                                    }
                                                </div>
                                            </div>
                                        </GroupOfField>
                                    </FormWrapper>
                                </div>
                                {this.props.fromRightModal ?
                                    <div className=" fixed-bottom">
                                        <FormButtons id={id} formStatus={this.graphStatus} />
                                    </div>
                                    :
                                    <FormButtons id={id} formStatus={this.graphStatus} />
                                }
                            </form>
                        </div>
                    </div>
                    <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                                onCloseModal={() => this.setState({
                                    rightModal: false,
                                    popUp: ""
                                })}
                    >

                        {this.state.popUp == "eliminar" &&
                        <PopUp
                            title={t('Eliminar')}
                            subtitle={t('Pedido ' + order.code)}
                            icon="fas fa-exclamation-triangle"
                            text={t('¿Estas Seguro de Eliminar este pedido y todos sus datos relaccionados?')}
                            label1={'No eliminar'}
                            label3={'Eliminar'}
                            onClick1={() => this.noCancel()}
                            onClick3={(order) => this.deleteOrder(order)}
                        >
                        </PopUp>
                        }
                        {this.state.popUp == "change" &&
                        <PopUp
                            title={t('Va a salir sin guardar')}
                            icon="fas fa-exclamation-triangle"
                            text={t('Guarde los cambios del pedido antes de editar los datos del cliente')}
                            label2={'Salir'}
                            onClick2={() => this.noCancel()}
                        />
                        }
                    </AlertModal>

                </InfoBlock>
            </div>

        )
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(withApollo(withTranslate(OrderForm)));
