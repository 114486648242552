import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import SidebarItem from "./SidebarItem";
import SidebarSubItem from "./SidebarSubItem";
import appState from '../state/AppState'
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";


@observer
class Sidebar extends Component {

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    ifPathIsDashboard(pathMatch) {
        let result = false;
        if (pathMatch.length == '1') {
            result = true;
        }
        return result;
    }

    openCloseItemsMenu = () => {
        appState.layoutState.clickHiddenMenuMasterData();
    };
    openCloseItemsMenuArticles = () => {
        appState.layoutState.clickHiddenMenuArticles();
    };
    openCloseItemsMenuClients = () => {
        appState.layoutState.clickHiddenMenuClients();
    };
    openCloseItemsMenuSchedule = () => {
        appState.layoutState.clickHiddenMenuSchedule();
    };
    openCloseItemsMenuScheduleVisit = () => {
        appState.layoutState.clickHiddenMenuScheduleVisit();
    };

    render() {
        let props = this.props;
        const urlClient = '/client/ls?filters=%5B%7B"fieldKey"%3A"isPos"%2C"fieldName"%3A"isPos"%2C"fieldValue"%3A"False"%2C"filterOperator"%3A"EQ"%2C"fieldLabel"%3A"Clientes"%7D%5D';

        const { t, i18n } = this.props;
        const urlClientIsPos = '/posClients/ls?filters=%5B%7B"fieldKey"%3A"isPos"%2C"fieldName"%3A"isPos"%2C"fieldValue"%3A"True"%2C"filterOperator"%3A"EQ"%2C"fieldLabel"%3A"Establecimientos"%7D%5D';

        return (

            <div className="sidebar">
                <nav className="sidebar-nav">
                    <ul className="nav">

                        <SidebarItem href="/" icon="fas fa-door-open" title={t("Panel de Control")}
                                     active={this.ifPathIsDashboard(this.props.location.pathname)} />
                        <SidebarItem href="/schedule/bytechnical" icon="fas fa-calendar-alt" title={t("Calendario")}
                                     active={this.ifPathStartsWith("/schedule/bytechnical")} />
                        <SidebarItem href="/order/ls" icon="fas fa-file-import" title={t("Pedidos")}
                                     active={this.ifPathStartsWith("/order")} />
                        <SidebarItem href="/work-order/ls" icon="fas fa-file-alt" title={t("Órdenes de Trabajo")}
                                     active={this.ifPathStartsWith("/work-order")} />
                        <SidebarItem id='visit' line={true} click={this.openCloseItemsMenuScheduleVisit} href=""
                                     icon="fas fa-calendar-check" active={this.ifPathStartsWith("/schedule/visit/")}
                                     arrow={!appState.layoutState.scheduleVisitOpen}
                                     stateOpen={appState.layoutState.scheduleVisitOpen || appState.layoutState.sidebarMinimized4}
                                     title={t("Visitas Preventivas")}>
                            <SidebarSubItem href="/schedule/visit/byclient" icon="fas fa-calendar-check"
                                            title={t("Planificación Clientes")}
                                            active={this.ifPathStartsWith("/schedule/visit/byclient")} />
                            <SidebarSubItem href="/schedule/visit/ls" icon="fas fa-calendar-check"
                                            title={t("Tipos de Visita Preventivas")}
                                            active={this.ifPathStartsWith("/schedule/visit/ls")} />

                        </SidebarItem>
                        <SidebarItem href="/user/ls" icon="fas fa-users" title={t("Usuarios")}
                                     active={this.ifPathStartsWith("/user")} />
                        <SidebarItem id='articles' click={this.openCloseItemsMenuClients}
                                     active={this.ifPathStartsWith("/client")}
                                     href="" icon="fas fa-suitcase" arrow={!appState.layoutState.sidebarMinimized5}
                                     stateOpen={appState.layoutState.clientsOpen || appState.layoutState.sidebarMinimized5}
                                     title={t("Clientes")}>
                            <SidebarSubItem href={urlClientIsPos} icon="fas fa-box" title={t("Establecimientos")}
                                            active={this.ifPathStartsWith("/posClients/ls")} />
                            <SidebarSubItem href={urlClient} icon="fas fa-tachometer-alt" title={t("Clientes")}
                                            active={this.ifPathStartsWith("/client/ls")} />
                        </SidebarItem>
                        <SidebarItem id='articles' click={this.openCloseItemsMenuArticles}
                                     active={this.ifPathStartsWith("/asset/") || this.ifPathStartsWith("/article/")}
                                     href="" icon="fas fa-box" arrow={!appState.layoutState.sidebarMinimized2}
                                     stateOpen={appState.layoutState.articlesOpen || appState.layoutState.sidebarMinimized2}
                                     title={t("Artículos")}>
                            <SidebarSubItem href="/asset/ls" icon="fas fa-tachometer-alt" title={t("Equipos")}
                                            active={this.ifPathStartsWith("/asset/")} />
                            <SidebarSubItem href="/article/ls" icon="fas fa-box" title={t("Repuestos")}
                                            active={this.ifPathStartsWith("/article/")} />
                        </SidebarItem>
                        <SidebarItem href="/vehicle/ls" icon="fas fa-car" title={t("Vehículos")}
                                     active={this.ifPathStartsWith("/vehicle")} />
                        {/*<SidebarItem href="/client/ls" icon="fas fa-warehouse" line={true}  title={t("Almacenes")}/>*/}
                        {/*<SidebarItem href="/schedule" icon="fas fa-calendar-alt" title={t("Calendario de prueba" )} />*/}
                        <SidebarItem id='maestros' href="/typifiedValue/ls" icon="fas fa-list"
                                     active={this.ifPathStartsWith("/typifiedValue")} title={t("Maestros")} />

                    </ul>
                </nav>
            </div>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


export default withTranslate(withRouter(Sidebar));

