import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import { withApollo } from "react-apollo";
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import WorkOrderModel from "../../models/WorkOrderModel";
import HistoryComponent from "../../components/HistoryComponent";
import withTranslate from "../../translator/withTranslate";
import { observable } from "mobx";
import WorkLogModel from "../../models/WorkLogModel";

@observer
class WorkOrderHistory extends BaseForm {
    @observable workLogs = [];
    @observable clients = [];

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.loadStartClient()
        await this.loadWorkLog();
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    async loadStartClient() {
        this.log("loadStartClient");
        let workOrderQuery = new WorkOrderModel();
        let workOrder = await workOrderQuery.findById(this.getFormId());

        let workLogModel = new WorkLogModel();
        workLogModel.filters = [
            { "fieldName": "action", "fieldValue": "START_VISIT", "filterOperator": "EQ" },
            { "fieldName": "clientId", "fieldValue": workOrder.clientId, "filterOperator": "IN" },
            { "fieldName": "orderId", "fieldValue": workOrder.orderId, "filterOperator": "IN" }
        ];
        let workLog = await workLogModel.findPlainObject();
        this.clients = workLog;
        this.log("loadStartClient");
        this.log(workLog);
    }

    optionAction(type) {
        let tipos = {
            "END_WORK_ORDER": "Finalizada",
            "START_WORK_ORDER": "Empezada",
            "PAUSE_WORK_ORDER": "Pausada",
            "INPROCESS_WORK_ORDER": "Pausada-En proceso",

        };
        let result = tipos[type];
        return result;
    }

    async loadWorkLog() {
        this.log("loadWorkLog");
        let workLogModel = new WorkLogModel();
        workLogModel.filters = [
            { "fieldName": "workOrderId", "fieldValue": this.getFormId(), "filterOperator": "IN" },
        ];
        let workLogs = await workLogModel.find();
        let objWithWorkLogs = util.getDictMultipleFromArrayToArray(workLogs, "action");
        this.workLogs = objWithWorkLogs;

        this.log("workLogs");
        this.log(workLogs)
        this.log("this.workLogs");
        this.log(this.workLogs)
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        const { t, i18n } = this.props;

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Orden de Trabajo"}
                                subtitle={t("Histórico técnico")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Orden de Trabajo")}
                            subtitle={t("Histórico técnico")}
                        />
                    }

                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataWorkOrder()}
                            active={"Histórico técnico"}
                        />
                        }
                        <div className="card-body">
                            <div className="col-12 history-container">
                                <div className="history-list-container">
                                    <div className="history-list-rows">
                                        {this.clients.length == 0 &&
                                        <div
                                            className="col-md-9 history-list-item-updated">{t('No se ha trabajado en esta órden de Trabajo')}
                                        </div>
                                        }
                                        {this.clients.map((cliente) => (
                                            <div className="history-list-item">
                                                <div
                                                    className="col-md-9 history-list-item-updated">{t('Inicio de la visita en cliente: ') + " " + util.getMoment(cliente.time).format("YYYY-MM-DD HH:mm")}
                                                </div>
                                                <div className="history-change modified">
                                                        <span
                                                            className="history-change-title history-change-item modified">{t("Cambios del técnico")}</span>
                                                    {this.workLogs && Object.keys(this.workLogs).map(action => (
                                                        <>
                                                            <div
                                                                className="history-change-item">{this.optionAction(action) + " a las " + util.getMoment(this.workLogs[action].time).format("YYYY-MM-DD HH:mm")}
                                                            </div>
                                                            {this.workLogs && this.workLogs[action].map(workOrder => (

                                                                <>
                                                                    {workOrder.data && Object.entries(JSON.parse(workOrder.data)).map((value) => (
                                                                        <div className="history-change-item">
                                                <span
                                                    className="history-change-item-text">{value[0]}</span>
                                                                            <span
                                                                                className="history-change-item-text value"><i
                                                                                className="fa fa-arrow-right"> </i> {value[1]}</span>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ))}
                                                        </>

                                                    ))}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(withApollo(withTranslate(WorkOrderHistory)));
