import BaseValidation from "./BaseValidation";

export default class NumberMaxValueValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect=true;
        if(this.event==="change"){
            let maxValue = parseInt(args);
            if(parseFloat(inputValue)> maxValue) {
                isCorrect=false;
            }
        }
        return isCorrect;
    }

}
