import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withTranslate from "../../translator/withTranslate";
import appState from "../../state/AppState";
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import { withRouter } from "react-router-dom";

@observer
class ClientListItem extends Component {
    openClientModal() {
        this.log("openClientModal");
        this.propsUtil.changeUrlRequest({
            idOrderClient: this.props.row.clientId,
            rightModal: "modalclient",
            rightModalTab: "general",
            fromRightModal: true
        })
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        let value = appState.clientCacheState.getClientById(props.value).name;


        return (
            <div>
                <span onClick={() => this.openClientModal()} className="text-click text-underline">{props.value}</span>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withTranslate(ClientListItem))
