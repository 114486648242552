import React, { Component } from 'react';
import { observer } from 'mobx-react';


@observer
class BaseCommon extends Component {

    constructor(props) {
        super(props);
    }

    log(msg) {
        console.log(this.constructor.name + "=>");
        console.log(msg);
    }

    toPascalCase(string) {
        return `${string}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w+)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\s/, 'g'), '')
            .replace(new RegExp(/\w/), s => s.toUpperCase());
    }

}

export default BaseCommon;
