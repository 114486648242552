import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import { observer } from 'mobx-react';
import PropsUtil from "../../util/PropsUtil";
import { withRouter } from "react-router-dom";
import AppLogger from "../../util/AppLogger";
import appState from "../../state/AppState";

const Types = {
    ITEM: 'workInMonthUnit'
};
const itemSource = {
    beginDrag(props) {
        console.log("beginDrag");
        console.log({ props });
        return { ...props };
    },
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

@observer
class WorkInMonthUnitDrag extends Component {
    render() {
        this.propsUtil = new PropsUtil(this.props);
        const { isDragging, connectDragSource, src } = this.props;
        let messageHoursArr = [];
        //this.log("WorkInMonthUnitDrag()");
        // this.log(this.props.visitType);
        if (this.props.visitType) {
            for (let scheduledVisitSlot of this.props.visitType.scheduleVisitSlots) {
                messageHoursArr.push(scheduledVisitSlot.scheduledDuration + "h");
            }
        }
        let stateLoading = false;
        if (this.props.order) {
            if (this.props.order.loading) {
                stateLoading = true;
            }
        }
        let messageHours = messageHoursArr.join("/");
        return connectDragSource(
            <div className="drag-hours__item h-4" onClick={() => this.props.onClick(this.props.order)}
                 onMouseEnter={(e) => {
                     if (this.props.order) {
                         appState.layoutState.backgroundScheduled = this.props.order.id;
                     }
            }} onMouseLeave={(e) => {
                if (this.props.order) {
                    appState.layoutState.backgroundScheduled = 0;
                }
            }}>
                {/*<div className="drag-hours__item h-4" onClick={e => this.onClick(e, this.props.order)}>*/}
                <div className="drag-hours__item__content">
                    {stateLoading ?
                        <div className="num-hours">
                            <i className="fa fa-spinner" />
                        </div>
                        :
                        <>
                            <div className="num-hours">
                                {messageHours}
                            </div>
                            <div className="bar-hours" />
                        </>
                    }
                </div>
            </div>
        )
    }

    onClick(e, order) {
        if (order) {
            this.propsUtil.changeUrlRequest({
                "rightModalTab": "order",
                "rightModal": "modalorderls",
                "orderId": order.id
            });
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(withRouter(WorkInMonthUnitDrag))
