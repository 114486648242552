import React from 'react';

const LoadingOrErrorInfo = (props)=> {
    let className = "";
    if (props.working) {
        if (props.loading) {
            className = "show loading";
        } else if (props.error) {
            className = "show error";
        } else {
            className = "show success";
        }
    } else {
        className="";
    }
    return (
        <div style={{position:"absolute"}} className="loading-or-error-info">
            {props.formLoading && <p>Loading Query ...</p>}
            {props.formError && <p>ERROR Query ...</p>}
        </div>
    )
}

export default LoadingOrErrorInfo;