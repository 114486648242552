import React, { Component } from 'react';
import withTranslate from "../translator/withTranslate";

import appState from '../state/AppState';
import CardDashboardComponent from "../components/CardDashboardComponent";

class Dashboard extends Component {

    render() {
        const {t, i18n} = this.props;
        return (
            <div className="row">
                <CardDashboardComponent
                    title={t('Calendario')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-calendar-alt"
                    classGroup='col-md-4'
                    link='/schedule/bytechnical'
                    t={t}
                />
                <CardDashboardComponent
                    title={t('Pedidos')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-file-import"
                    classGroup='col-md-4'
                    link='/order/ls'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Órdenes de Trabajo')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-file-alt"
                    classGroup='col-md-4'
                    link='/work-order/ls'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Usuarios')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-users"
                    classGroup='col-md-4'
                    link='/user/ls'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Equipos')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-tachometer-alt"
                    classGroup='col-md-4'
                    link='/asset/ls'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Clientes')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-suitcase"
                    classGroup='col-md-4'
                    link='/client/ls'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Presupuestos')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    classButton={"disabled"}
                    icon="fas fa-file-medical-alt"
                    classGroup='col-md-4'
                    link='#'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Maestros')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-bars"
                    classGroup='col-md-4'
                    link='#'
                   t={t}
                />
                <CardDashboardComponent
                    title={t('Reporting')}
                    info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                    icon="fas fa-compass"
                    classButton={"disabled"}
                    classGroup='col-md-4'
                    link='#'
                   t={t}
                />
            </div>
        );
    }
}

export default withTranslate( Dashboard, 'Dashboard');
