import { observable,computed, autorun } from 'mobx';
import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import {gql} from "apollo-boost";
import UserModel from "../models/UserModel";


class WorkOrderCacheState{

    //bloque workOrder
    @observable workorders=[];
    @computed get workorderCacheForSelect(){
        let result=[];
        for(let workorder of this.workorders){
            result.push({value:workorder.id, label:workorder.id })
        }
        console.log('array de CacheForSelct');
        console.log('array de CacheForSelct',result);
        return result;
    }
    async loadWorkOrderCache(orderId) {
        if (this.workorders.length==0) {
            if (orderId != null){
                this.workorders = await this.loadAndGetWorkOrderCacheRowsById(orderId);
            } else
            this.workorders = await this.loadAndGetWorkOrderCacheRowsByType();
        }
        console.log('array de load')
        console.log(this.workorders);
    }

    async loadAndGetWorkOrderCacheRowsById(id) {
        let query = gql`
            query workOrder ($query:QueryInputType){
                workOrders(query:$query) {
                    id,
                    code,
                }
            }
        `;

        console.log('array de loadanget')
        console.log(this.workorders);
        let variables={
            query: {
                filters:[
                    {fieldName:"orderId",fieldValue:id }
                ]
            }
        }
        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({query,variables});
        let newData=resultQuery.data;
        let result=[];
        if (newData.workOrders!=null) {
            result = newData.workOrders;
        }
        return result;
    }

    async loadAndGetWorkOrderCacheRowsByType(type) {
        let query = gql`
            query workOrder ($query:QueryInputType){
                workOrders(query:$query) {
                    id,
                    code,
                }
            }
        `;

        console.log('array de loadanget')
        console.log(this.workorders);
/*        let variables={
            query: {
                filters:[
                    {fieldName:"orderId",fieldValue:type }
                ]
            }
        }*/
        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({query});
        let newData=resultQuery.data;
        let result=[];
        if (newData.workOrders!=null) {
            result = newData.workOrders;
        }
        return result;
    }




}

export default WorkOrderCacheState;
