import React, { Component } from 'react';
import Toy from "./Toy";
import appState from "../../state/AppState";
import {observer} from 'mobx-react';

@observer
class Pendings extends Component {


    render() {
        let ordersWithoutDate = appState.preventOrders.filter(order=>order.date==null);

        return (
            <React.Fragment>
                {ordersWithoutDate.map(order=>(
                    <Toy name={order.name} index={order.index}>

                    </Toy>
                ))}
            </React.Fragment>
        )
    }
}
export default Pendings;