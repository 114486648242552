import appState from "../state/AppState";

export default class FetchProxy {

    constructor() {
        this.debug=false;
        this.withAuthorization=false;
    }

    getDefaultHeaders() {
        return {
            //'Custom-Header': 'EXAMPLECUSTOM',
            'Content-Type': 'application/json;charset=UTF-8',
            //'Access-Control-Max-Age': 60*10*1000 //10 minutos de cache de OPTIONS
        };
    }

    /**
     * Manda a fetch una petición POST con el array de elementos que hay en postdataobj.
     * @param url
     * @param postdataobj
     * @param params Se pasan directamente como parametros a fetch
     * @returns {Promise<void>}
     */
    async fetchUrlPost(url, postdataobj, params) {
        //Encode the data
        if (params==null) {
            params={};
        }
        params["headers"]= this.getDefaultHeaders();
        if (this.withAuthorization) {
            let token = appState.loginState.getAccessToken;
            if (token) {
                params["headers"]["Authorization"] = `Bearer ${token}`;
            }
        }


        params["method"]="POST";
        params["body"]=JSON.stringify(postdataobj);
        let response = await this.fetchUrl(url,params);
        return response;
    }

    /**
     * Manda a fetch una petición GET
     * @param url
     * @param params Se pasan directamente como parametros a fetch
     * @returns {Promise<void>}
     */
    async fetchUrlGet(url, params) {
        //Encode the data
        if (params==null) {
            params={};
        }
        params["headers"]= this.getDefaultHeaders();
        if (this.withAuthorization) {
            let token = appState.loginState.getAccessToken;
            if (token) {
                params["headers"]["Authorization"] = `Bearer ${token}`;
            }
        }

        params["method"]="GET";
        let response = await this.fetchUrl(url,params);
        return response;
    }

    /**
     * Manda a fetch una petición POST con el array de elementos que hay en postdataobj.
     * @param url
     * @param postdataobj
     * @param params Se pasan directamente como parametros a fetch
     * @returns {Promise<void>}
     */
    async fetchUrlPostUrlEncoded(url, postdataobj, params) {
        //Encode the data
        const searchParams = Object.keys( postdataobj ).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(postdataobj[key]);
        }).join('&');
        if (params==null) {
            params={};
        }
        params["headers"]= this.getDefaultHeaders();
        params["method"]="POST";
        params["body"]=searchParams;
        let result = await this.fetchUrl(url,params);
        return result;
    }

    async fetchUrl(url, params) {
        if (params==null) {
            params={};
        }
        params["credentials"]='same-origin';
        let result = await fetch(url,params);
        return result;
    }

    /**
     * Redirige a la home de login si no se tiene permisos. No se usa el metodo. Está para poder usarlo en el futuro
     *
     * @param response
     */
    process401Error(response) {
        if (response.status==401) {
            window.location.href="login.php";
        }
    }


    // Logea en consola el mensaje. Sólo útil en depuración
    log(message) {
        if (this.debug) {
            console.log(message);
        }
    }


}
