import React from 'react';
import { observer } from 'mobx-react';
import SlotModel from "../../models/SlotModel";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import BaseEditableRow from "../base/BaseEditableRow";
import { computed, observable, toJS } from "mobx";
import appState from "../../state/AppState";
import Select2Component from "../../components/fields/Select2Component";
import WorkOrderModel from "../../models/WorkOrderModel";
import util from "../../util/Util";
import InfoBlock from "../../components/InfoBlock";
import WorkOrdersSelectInOrderForm from "../../components/WorkOrdersSelectInOrderForm";
import Overlay from "../../components/Overlay";
import { now } from "moment";
import AppLogger from "../../util/AppLogger";
import withTranslate from "../../translator/withTranslate";
import DateInputHours from "../../components/fields/DateInputHours";
import PropsUtil from "../../util/PropsUtil";
import IconsSelectCapactitation from "../../components/fields/IconsSelectCapactitation";

@observer
class OrderFormSlot extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });
    @observable workOrderRelaccionada;
    @observable workOrderCodeRelaccionada;

    constructor(props) {
        super(props);
        this.foreingKeyfield = "orderId";

    }

    @computed
    get mobxListado() {
        return appState.slotState.slots;
    }

    setMobxListado(newValue) {
        appState.slotState.slots = newValue;
    }

    async componentDidMount() {
        await super.componentDidMount();
        await appState.assetState.getCountSlotsFromOrder(this.getFormId());
        // await appState.assetState.getCountArticlesFromOrder(this.getFormId());
        await appState.typifiedState.loadCapacitation();
        await appState.userCacheState.loadUserCache();
        await this.workOrderRelaccionadas();
        appState.typifiedState.arrayLoadCapacitation();
        if (this.props.fromWorkOrder) {
            this.foreingKeyfield = "workOrderId";
            await appState.assetState.getCountSlotsFromWorkOrder(this.getFormId());
            // await appState.assetState.getCountArticlesFromWorkOrder(this.getFormId());
        }
    }


    getModelTable() {
        return new SlotModel();
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        this.log(this.props.location.pathname);
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    updateInputEvent(event) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        this.updateInput(event.target.name, event.target.value)
    }

    updateInput(key, value) {
        this.log("updateInput(key: " + key + ", value: " + value);
        let slot = this.props.row;
        let newValue = slot.workOrderIds;
        if (value === "-1") {
            if (util.perteneceA(value, slot.workOrderIds)) {
                newValue = newValue.replace(value, "");
            } else
                newValue = "-1"
        } else {
            if (newValue == null) {
                newValue = value;
            } else {
                if (util.perteneceA("-1", slot.workOrderIds))
                    newValue = value;
                else if (util.perteneceA(value, slot.workOrderIds)) {
                    if (newValue.endsWith(value))
                        newValue = newValue.replace("," + value, "");
                    else
                        newValue = newValue.replace(value + ",", "");
                } else {
                    newValue += "," + value;
                }
            }
        }
        slot.workOrderIds = newValue;
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = slot;
        }
    }

    async rowDidDelete(slot) {
        this.log("rowDidDelete")
        if (util.hasValue(slot.id)) {
            if (this.ifPathStartsWith("/schedule/bytechnical")) {
                await this.deleteAppstate(slot);
                await appState.scheduleDropState.loadClients();
                await this.props.reload(this.props.orderId);
            } else {
                await this.props.reload(this.getFormId());
            }
        } else {
            let arrCopy = toJS(this.mobxListado);
            arrCopy.splice(this.props.rowIndex, 1);
            this.log({ arrCopy });
            this.setMobxListado(arrCopy);
        }
    }

    async deleteAppstate(slot) {
        this.log("deleteAppstate");
        let optionTime = this.propsUtil.getRequest("optionTime") || "semana";
        let row = slot;
        if (appState.scheduleDropState.slotClicked && appState.scheduleDropState.slotClicked.id == row.id) {
            appState.layoutState.slotview = false;
        }
        await this.deleteSupport(row.id);
        appState.scheduleDropState.allSlotsDict[row.id].scheduledTime = "";
        if (!appState.scheduleDropState.flagSlots) {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
        } else {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
        }
        await appState.scheduleDropState.createTecnicosSinAsignar();
        await appState.scheduleDropState.calcularHoras(row.orderId);
        await appState.scheduleDropState.calcularTecnicos(row.orderId);

    }

    async deleteSupport(slotIid) {
        for (let id of Object.keys(appState.scheduleDropState.allSlotsDict)) {
            if (appState.scheduleDropState.allSlotsDict[id].assignedSlotMainId == slotIid)
                appState.scheduleDropState.allSlotsDict[id].scheduledTime = "";
        }
    }

    async updateSupport(slot) {
        this.log("updateSupport()");
        let slotSupports = new SlotModel();
        slotSupports.assignedSlotMainId = slot.id;
        let slots = await slotSupports.findPlainObject();
        if (slots.length > 0) {
            let idsSupports = {};
            for (let slot of slots) {
                if (idsSupports[slot.id] == null)
                    idsSupports[slot.id] = slot.scheduledTime;
            }
            for (let id of Object.keys(idsSupports)) {
                appState.scheduleDropState.allSlotsDict[id].scheduledTime = idsSupports[id];
            }
            await this.props.reload(slot.orderId);
        }
    }

    async editarAppstate(slot, fromClient) {
        let slotState = appState.scheduleDropState.allSlotsDict[slot.id];
        let optionTime = this.propsUtil.getRequest("optionTime") || "semana";
        let slotModel = new SlotModel();
        slotModel.addRelatedTable("workOrder");
        let slotQuery = await slotModel.findById(slot.id);
        let lineaSinAsignar = appState.scheduleDropState.allSlotsDict[slot.id] ?
            appState.scheduleDropState.allSlotsDict[slot.id].lineaSinAsignar : null;

        appState.scheduleDropState.allSlotsDict[slot.id] = slotQuery.toPlainObject();
        appState.scheduleDropState.allSlotsDict[slot.id].lineaSinAsignar = lineaSinAsignar;
        if (slotState.scheduledTime !== slot.scheduledTime) {
            await this.updateSupport(slot);
            appState.layoutState.slotview = false;
            if (fromClient) {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsByClient();
            } else if (!appState.scheduleDropState.flagSlots) {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
            } else {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
            }
            await appState.scheduleDropState.createTecnicosSinAsignar();
        }

        if (slotState.scheduledDuration !== slot.scheduledDuration) {
            await appState.scheduleDropState.calcularHoras(slot.orderId);
        }
        if (slotState.userId !== slot.userId) {
            await appState.scheduleDropState.calcularTecnicos(slot.orderId);
        }
    }

    async crearAppstate(slot, fromClient) {
        let slotModel = new SlotModel();
        let slotQuery = await slotModel.findById(slot.id);
        appState.scheduleDropState.allSlotsDict[slot.id] = slotQuery.toPlainObject();

        if (fromClient) {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsByClient();
        } else if (!appState.scheduleDropState.flagSlots) {
            if (slot.userId == null) {
                appState.scheduleDropState.allSlotsDict[slot.id].lineaSinAsignar = appState.scheduleDropState.rowsWithoutTechnicals.length;
            }
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits("semana");
        } else {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
        }
        await appState.scheduleDropState.calcularHoras(slot.orderId);
        await appState.scheduleDropState.calcularTecnicos(slot.orderId);
        await appState.scheduleDropState.createTecnicosSinAsignar();
    }

    /**
     * @param slot
     * @returns {Promise<void>}
     */
    async rowDidSave(slot) {
        this.log("rowDidSave()");
        this.log(slot);
        if (this.ifPathStartsWith("/schedule/bytechnical")) {
            if (appState.scheduleDropState.allSlotsDict[slot.id] != null) {
                await this.editarAppstate(slot)
            } else {
                await this.crearAppstate(slot);
            }
            await appState.scheduleDropState.loadClients();

        } else if (this.ifPathStartsWith("/schedule/visit")) {
            if (appState.scheduleDropState.allSlotsDict[slot.id] != null) {
                await this.editarAppstate(slot, true)
            } else {
                await this.crearAppstate(slot, true);
            }
        } else if (this.ifPathStartsWith("/client/")) {
            await this.props.reload(this.props.orderId);
        } else if (this.ifPathStartsWith("/order/form")) {
            await this.props.reload(this.getFormId());
        }
    }

    async cargarWorkOrdersDelPedido(orderId) {
        let workOrderQuery = new WorkOrderModel();
        let workOrders = await workOrderQuery.getWorkOrdersFromOrderId(orderId);
        let codigo = "";
        workOrders.map((workor) => {
            if (workor.id !== this.props.workOrderId)
                codigo += workor.code + " ";
        });
        if (codigo !== "") {
            this.workOrderRelaccionada = codigo;
        } else this.workOrderRelaccionada = "No hay O.T. Relaccionadas "
    }

    async workOrderRelaccionadas() {
        let workOrderQuery = new WorkOrderModel();
        let workOrder = null;
        if (this.props.workOrderId) {
            workOrder = await workOrderQuery.findById(this.props.workOrderId);
        }
        if (workOrder != null) {
            let slotQuery = new SlotModel();
            let slotsModels = await slotQuery.getSlotsFromOrder(workOrder.orderId);
            let coincidencia = false;
            for (let slot of slotsModels) {
                if (util.perteneceA(workOrder.id, this.props.row.workOrderIds)) {
                    this.workOrderRelaccionada = slot.workOrderIds;
                    coincidencia = true;
                }
            }
            if (!coincidencia) {
                this.workOrderRelaccionada = "-1";
            }
            let objWithWorkOrderCodes = {};
            if (this.workOrderRelaccionada !== "-1") {
                let workOrdersFromOrderId = await workOrderQuery.getWorkOrdersFromOrderId(workOrder.orderId);
                for (let workOrder of workOrdersFromOrderId) {
                    objWithWorkOrderCodes[workOrder.id] = workOrder;
                }
                let codigo = "";
                let ids = this.workOrderRelaccionada.split(",");
                ids.map(async(workor) => {
                    if (workor !== this.props.workOrderId) {
                        codigo += objWithWorkOrderCodes[workor].code + " ";
                    }
                });
                this.workOrderRelaccionada = codigo;
            } else {
                this.cargarWorkOrdersDelPedido(workOrder.orderId);
            }
        }
    }

    async onOpenSupport(slot) {
        let newModel = this.getModelTable().toPlainObject();
        newModel.orderId = slot.orderId;
        newModel.slotUserRole = SlotModel.SUPPORT;
        newModel.scheduledDuration = slot.scheduledDuration;
        newModel.scheduledTime = slot.scheduledTime;
        newModel.scheduledDate = slot.scheduledDate;
        newModel.workOrderIds = slot.workOrderIds;
        newModel.assignedSlotMainId = slot.id;
        newModel.isEditing = true;
        this.mobxListado.splice((this.props.rowIndex + 1), 0, newModel);
        this.props.setHasSomeRowEditing(true);
    }

    renderSupport() {
        let slot = this.props.row;
        let fechaNow = new Date(now());
        let fechaRow = new Date(slot.scheduledTime);
        let fecha = fechaRow.getTime() < fechaNow.getTime();
        if (slot.scheduledTime == null) {
            fecha = false;
        }
        if (slot.slotUserRole !== SlotModel.SUPPORT) {
            return (
                <div className="remove-add">
                    <div onClick={() => {
                        if (!slot.isEditing && !this.props.hasSomeRowEditing && !fecha)
                            this.onOpenSupport(slot)
                    }} title="Asignar usuario de apoyo"
                         className={slot.isEditing || this.props.hasSomeRowEditing || fecha ? "edit inactive" : "edit"}>
                        <span className="fas fa-user-plus" />
                    </div>
                </div>
            )
        }
    }

    downloadPDF(slot) {

    }

    onFixedValue(name) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        let slot = this.props.row;
        slot[name] = !slot[name];
    }

    handleInputHours(event) {
        let slot = this.props.row;
        let momentValue = util.getMoment(slot.scheduledTime);
        momentValue.hour(event.target.value);
        event.target.value = momentValue;
        this.handleInputChange(event);
    }


    workOrdersCodes() {
        let slot = this.props.row;
        let result = '';
        let workOrderModel = new WorkOrderModel();
        for (let workor of this.props.workOrders) {
            workOrderModel.hidrate(workor);
            if (util.perteneceA(workOrderModel.id, slot.workOrderIds)) {
                result += workOrderModel.code + ' ';
            }
        }
        return result;
    }

    getOptionsHours() {
        return ([
            { label: "08:00", value: 8 },
            { label: "09:00", value: 9 },
            { label: "10:00", value: 10 },
            { label: "11:00", value: 11 },
            { label: "12:00", value: 12 },
            { label: "13:00", value: 13 },
            { label: "14:00", value: 14 },
            { label: "15:00", value: 15 },
            { label: "16:00", value: 16 },
            { label: "17:00", value: 17 },
            { label: "18:00", value: 18 },
            { label: "19:00", value: 19 },
            { label: "20:00", value: 20 },
        ]);
    }

    render() {
        let slot = this.props.row;
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);

        if (slot.workOrderIds == null && this.props.workOrderId) {
            slot.workOrderIds = this.props.workOrderId;
        }
        let workOrderCode = this.workOrdersCodes();
        let errorsMapped = this.getGraphErrorsFromStatus();
        let optionHours = this.getOptionsHours();
        let usuario = appState.userCacheState.getUserById(slot.userId);
        let backgroundRow = "";
        if (appState.layoutState.backgroundRowScheduled != 0 && appState.layoutState.backgroundRowScheduled != slot.id) {
            backgroundRow = " rowSlotNoSeleccionada"
        }
        if (slot.isFinished) {
            backgroundRow = " rowSlotIsFinished ";
        }
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr aria-rowspan="2" className={slot.isEditing ? "editing" : backgroundRow}
                    style={{ 'border-bottom': 0 }}>
                    <td>
                        {slot.isEditing ?
                            <div className="d-flex">
                                <>{slot.slotUserRole === SlotModel.SUPPORT ?
                                    <div style={{ color: '#A2BFD0' }}>
                                        <i className="fa fa-user-alt" />&nbsp;
                                    </div>
                                    :
                                    <div style={{ color: '#3D708D' }}>
                                        <i className="fa fa-user-check" />&nbsp;
                                    </div>
                                }
                                </>
                                {!slot.isUserFixed ?
                                    <Select2Component
                                        value={slot.userId}
                                        onChange={(e) => this.handleInputChange(e)}
                                        name={"userId"}
                                        classGroup={'col-10'}
                                        options={appState.userCacheState.userCacheForSelect}
                                        showView={!slot.isEditing}
                                        errors={errorsMapped.userId}
                                    />
                                    :
                                    <div>
                                        {slot.userId != null ? ((usuario.firstName ? usuario.firstName : "") + " " +
                                            (usuario.lastName ? usuario.lastName : "")) : "Técnico sin asignar"}
                                    </div>
                                }
                                {util.hasValue(slot.userId) &&
                                <span onClick={() => this.onFixedValue("isUserFixed")} className="text-click ml-3">< i
                                    className={slot.isUserFixed ? "fas fa-lock" : "fas fa-lock-open"} /></span>
                                }
                            </div>
                            :
                            <div className="d-flex">
                                <>{slot.slotUserRole === SlotModel.SUPPORT ?
                                    <div style={{ color: '#A2BFD0' }}>
                                        <i className="fa fa-user-alt" />&nbsp;
                                    </div>
                                    :
                                    <div style={{ color: '#3D708D' }}>
                                        <i className="fa fa-user-check" />&nbsp;
                                    </div>
                                }
                                </>
                                <IconsSelectCapactitation color={"#3D708D"}
                                                          iconClass={"col-3"}
                                                          postfix={usuario.capacitationIds}>{slot.userId != null ? ((usuario.firstName ? usuario.firstName : "") + " " +
                                    (usuario.lastName ? usuario.lastName : "")) : "Técnico sin asignar"}</IconsSelectCapactitation>
                            </div>
                        }
                    </td>
                    <td>
                        {slot.isEditing ?

                            <div className="d-flex">
                                {!slot.isDateTimeFixed ?
                                    <>
                                        {slot.slotUserRole == SlotModel.SUPPORT ?
                                            <>
                                                {slot.scheduledTime ?
                                                    <Select2Component
                                                        title={util.getMoment(slot.scheduledTime).format("DD-MM-YYYY")}
                                                        placeholder={"Hora"}
                                                        value={util.getMoment(slot.scheduledTime).format("H")}
                                                        onChange={(e) => this.handleInputHours(e)}
                                                        name={"scheduledTime"}
                                                        options={optionHours}
                                                        errors={errorsMapped.scheduledTime} />
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <DateInputHours
                                                prefix={"fas fa-calendar-alt"}
                                                value={slot.scheduledTime}
                                                onChange={(e) => this.handleInputChange(e)}
                                                name={"scheduledTime"}
                                                type={"text"}
                                                hours={true}
                                                allowEmptyDate={true}
                                                errors={errorsMapped.scheduledTime} />

                                        }
                                    </>
                                    :
                                    <div>
                                        {util.hasValue(slot.scheduledTime) ? util.localizeIsoDateMinutes(slot.scheduledTime) : " "}
                                    </div>
                                }
                                <span onClick={() => this.onFixedValue("isDateTimeFixed")}
                                      className="text-click ml-3">< i
                                    className={slot.isDateTimeFixed ? "fas fa-lock" : "fas fa-lock-open"} /></span>
                            </div>
                            :
                            <div>
                                {util.hasValue(slot.scheduledTime) ? util.localizeIsoDateMinutes(slot.scheduledTime) : " "}

                            </div>
                        }
                    </td>
                    <td>
                        {slot.isEditing ?
                            <InputTypeComponent
                                value={slot.scheduledDuration}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"scheduledDuration"}
                                showView={!slot.isEditing}
                                className="form-control "
                                validate={"number|numberMinValue:1|numberMaxValue:10"}
                                errors={errorsMapped.scheduledDuration}
                            />
                            :
                            <>
                                {slot.scheduledDuration !== "" ? slot.scheduledDuration : "1"}
                            </>
                        }
                    </td>
                    {!this.props.fromWorkOrder ?
                        <td>
                            {slot.isEditing && slot.slotUserRole != SlotModel.SUPPORT ?
                                <>{slot.workOrderIds != null ? slot.workOrderIds === "-1" ? "Todas " : workOrderCode : ""}
                                    <button onClick={() => {
                                        appState.layoutState.favouritesDropMenuOpen = true
                                    }} id="btnGroupColumns" type="button"
                                            className="btn btn-outline-secondary dropdown-toggle"
                                            data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                    </button>
                                    <Overlay show={appState.layoutState.favouritesDropMenuOpen}
                                             onClick={_ => appState.layoutState.favouritesDropMenuOpen = false} />
                                    {appState.layoutState.favouritesDropMenuOpen &&
                                    <div className="table-responsive">
                                        <table className="dropdown-sublist table" style={{ zIndex: 100 }}>
                                            <WorkOrdersSelectInOrderForm
                                                values={slot.workOrderIds}
                                                {...this.props}
                                                onChange={(e) => this.updateInputEvent(e)}
                                            />
                                        </table>
                                    </div>
                                    }
                                </>
                                :
                                <div>{slot.workOrderIds != null ? slot.workOrderIds === "-1" ? "Todas " : workOrderCode : "Todas "}</div>
                            }
                        </td>
                        :
                        <td>
                            {slot.workOrderIds !== this.props.workOrderId ? this.workOrderRelaccionada : "No hay O.T. relaccionadas "}
                        </td>
                    }
                    <td>
                        {slot.isFinished ?
                            <div className="form-group">
                                <div className="remove-add">
                                    <div className="remove">
                                    <span onClick={() => this.downloadPDF(slot)}
                                          className="fas fa-file-pdf text-click" />&nbsp;
                                    </div>
                                </div>
                            </div>
                            :
                            this.renderEditBlock()
                        }

                    </td>
                </tr>
                <tr aria-rowspan="2" className={slot.isEditing ? "editing" : backgroundRow}>
                    <th>{t('Comentarios')}</th>

                    <td colSpan="5">
                        {slot.isEditing ?
                            <InputTypeComponent
                                value={slot.comments}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"comments"}
                                showView={!slot.isEditing}
                                className="form-control "
                                errors={errorsMapped.comments}
                            />
                            :
                            <>
                                {util.hasValue(slot.comments) ? slot.comments : ""}
                            </>
                        }
                    </td>
                </tr>
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withTranslate(OrderFormSlot);
