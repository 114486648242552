import React, { Component } from 'react';
import { observer } from 'mobx-react';
@observer
export default class EmailList extends Component {
    render() {
        let props= this.props;
        return (
            <div>
                {props.value}
            </div>
        );
    }
}
