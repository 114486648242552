import React, { Component } from 'react';
import appState from "../state/AppState";
import FilterWrapper from "./FilterWrapper";
import VsfModal from "../components/listaddons/VsfModal";
import {observer} from "mobx-react";
import AppLogger from "../util/AppLogger";
import {observable} from "mobx/lib/mobx";

@observer
export default class BaseListFilter extends Component {


    /**
     * Campos para mostrar el filtro aplicado
     * @type {{modalOpen: boolean}}
     */
    @observable stateFilter= {
        modalOpen:true, //Indica si se debe abrir el desplegable con los filtros
    }

    onAplicarFiltros() {
        this.props.stateFilter.modalOpen=false;
        this.props.listComponent.onClickAplicarFiltros();
    }

    onCloseModal() {
        this.props.stateFilter.modalOpen=false;
    }

    render() {
        this.log("render()");
        //let stateFilter = appState.userState.listFilter;
        let stateFilter = this.props.stateFilter;
        //let stateFilter = this.stateFilter;

        return (
            <VsfModal
                stateFilter={stateFilter}
                className="list-columns-modal"
                contentLabel="Example Modal"
            >
                <FilterWrapper {...this.props}
                                title={this.props.title}
                               onAplicarFiltros={_=>this.onAplicarFiltros()}
                               onCloseModal={_=>this.onCloseModal()}
                >
                    {this.props.children}
                </FilterWrapper>
            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
