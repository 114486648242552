import React, { Component } from 'react';
import { observer } from 'mobx-react';
import OrderModel from "../../models/OrderModel";
import { observable } from "mobx";
import AppLogger from "../../util/AppLogger";

@observer
class QuantityOTListItem extends Component {

    @observable value;

    async componentDidMount() {
        this.value = await this.getWorkOrders(this.props.value)
    }

    async getWorkOrders(orderId) {
        let orderQuery = new OrderModel();
        let workOrders = await orderQuery.getWorkOrdersFromOrderId(orderId);
        return workOrders.length;
    }


    render() {
        return (
            <div>
                <div>{this.value}</div>
            </div>
        );
    }

}

export default QuantityOTListItem
