import AbstractModel from "./AbstractModel";

export default class VehicleModel extends AbstractModel {
    id;
    plate;
    model;
    brand;
    subInventory;

    parentId;

    nameMainType = "vehicle";
    graphFindByIdOperation = "vehicle";
    graphFindOperation = "vehiclesConnection";
    graphExportOperation = "vehiclesExport";

    getArrayFields() {
        let result = {
            "id": { type: "CodeField" },
            "plate": "",
            "model": "",
            "brand": "",
            "subInventory":"",
        };
        return result;
    }

};





















