import React from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import Select2Component from "../../components/fields/Select2Component"
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import FiltersPage from "../FiltersPage";

@observer
class ClientListFilters extends FiltersPage {

    nameVariableStateFilter = "clientListFilters";


    initialize() {
        super.initialize();
        //Indica que aunque sea un Date se debe hacer la llamada a GQL como DateTime completo
        //this.log("constructor() this.nameVariableStateFilter:"+this.nameVariableStateFilter);
        //this.log(toJS(appState[this.nameVariableStateFilter]));
        appState[this.nameVariableStateFilter].filterType["createdAt"] = "DateTime";
    }

    render() {

        this.propsUtil = new PropsUtil(this.props);
        let { t } = this.props;

        return (
            <React.Fragment>

                <InputTypeComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.city}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"city"}
                    title={t("Localidad")}
                    placeholder={"Localidad"}
                    classGroup={"col-4"}
                    type={"text"}
                    instanceOf={"form-control"}
                    //errors={errorsMapped.city}
                />

                <InputTypeComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.district}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"district"}
                    title={t("Provincia")}
                    placeholder={"Provincia"}
                    classGroup={"col-4"}
                    type={"text"}
                    instanceOf={"form-control"}
                    //errors={errorsMapped.district}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.posZoneId}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"posZoneId"}
                    title={t("Zona Est.")}
                    classGroup={"col-3"}
                    options={appState.typifiedState.zonesForSelect}
                    //errors={errorsMapped.posZoneId}
                />
                <InputTypeComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.posCity}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"posCity"}
                    title={t("Localidad  Est.")}
                    placeholder={"Localidad  Est."}
                    classGroup={"col-3"}
                    type={"text"}
                    instanceOf={"form-control"}
                    //errors={errorsMapped.city}
                />
                <InputTypeComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.posDistrict}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"posDistrict"}
                    title={t("Provincia Est.")}
                    placeholder={"Provincia Est."}
                    classGroup={"col-3"}
                    type={"text"}
                    instanceOf={"form-control"}
                    //errors={errorsMapped.posDistrict}
                />
                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.isPos}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"isPos"}
                    title={t("Cliente - Establecimiento")}
                    classGroup={"col-3"}
                    options={[
                        {label:'Establecimiento' ,value:1},
                        {label:'Cliente' ,value:0}
                    ]}
                    //errors={errorsMapped.isPos}
                />

            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(withApollo(withTranslate(ClientListFilters, "ClientListFilters")));
