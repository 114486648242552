import React, { Component } from 'react';
import { observer } from 'mobx-react';
import appState from '../../state/AppState';
import util from '../../util/Util';
import AppLogger from "../../util/AppLogger";

@observer
export default class IconsSelectCapactitation extends Component {
    render() {
        let props = this.props;
        let icons = [];
        if (util.hasValue(props.postfix)) {
            for (let capacitation of props.postfix.split(',')) {
                if (appState.typifiedState.arrCapacitation[capacitation]) {
                    icons.push(appState.typifiedState.arrCapacitation[capacitation]);
                }
            }
        }
        this.log(" IconsSelectCapactitation");
        this.log(props.children);
        this.log(props.postfix);
        this.log(icons);
        if (props.select) {
            return (
                <div className="input-group-prepend">
                                <span>
                                    {props.children}
                                    {icons.map(icon => (
                                        <span className={icon.icon}></span>
                                    ))
                                    }
                                </span>
                </div>
            )
        } else
            return (
                <div className={'input-group pl-2 pr-2'}>
                    <p className="th-bottom__item" style={{ 'margin-bottom': 0 }}>  {props.children}</p>
                    {icons.map(icon => (
                        <span className={icon.icon + " pl-2"} />
                    ))
                    }
                </div>
            );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
