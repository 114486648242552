import { computed, observable } from 'mobx';
import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost";
import UserModel from "../models/UserModel";
import util from "../util/Util";
import appState from './AppState';
import React from 'react';
import IconsSelectCapactitation from "../components/fields/IconsSelectCapactitation";

class UserCacheState {

    //bloque user
    @observable users = [];

    @computed get userCacheForSelectList() {
        let result = [];
        for (let user of this.users) {
            result.push({
                value: user.id,
                label: ((user.firstName != null ? user.firstName : "") + ' ' + (user.lastName != null ? user.lastName : ""))
            })
        }
        return result;
    }

    @computed get userCacheForSelect() {
        let result = [];
        for (let user of this.users) {
            if (util.perteneceA(255, user.role)) {
                let key = ((user.firstName != null ? user.firstName : "") + ' ' + (user.lastName != null ? user.lastName : "")
                    + "-" + (appState.typifiedState.arrZones[user.zoneAsignedId] && appState.typifiedState.arrZones[user.zoneAsignedId].code || ''));
                result.push({
                    value: user.id,
                    label: <IconsSelectCapactitation key={user.firstName} color={"#3D708D"} iconClass={"col-3"} select={true}
                                                     postfix={user.capacitationIds}>{key}</IconsSelectCapactitation>
                })
            }
        }
        return result;
    }

    async loadUserCache() {
        if (this.users.length === 0) {
            this.users = await this.loadAndGetUserCacheRowsByType("id");
        }
    }

    /**
     * Metodo para sacar los registros del tipo pasado como parametro
     * @param type
     * @returns {Promise<null>}
     */
    async loadAndGetUserCacheRowsByType(type) {
        let query = gql`
            query users($query:QueryInputType){
                users(query:$query) {
                    id,
                    email,
                    lastName,
                    firstName,
                    role,
                    zoneAsignedId,
                    capacitationIds
                }
            }
        `;
        /* let variables={
             query: {
                 filters:[
                     {fieldName:"type",fieldValue:type}
                 ]
             }
         }*/
        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({ query });
        let newData = resultQuery.data;
        let result = [];
        if (newData.users != null) {
            result = newData.users;
        }
        return result;
    }

    /**
     * Obtiene el objeto cliente desde el array de clientes (cargados en memoria)
     * @param clientId
     */
    getUserById(clientId) {
        this.loadUserCache();
        let user = new UserModel();
        let plainUserFounded = null;
        for (let user of this.users) {
            if (user.id == clientId) {
                plainUserFounded = user;
            }
        }
        if (plainUserFounded != null) {
            user.hidrate(plainUserFounded);
        }
        return user;
    }
}

export default UserCacheState;
