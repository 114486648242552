import {observable} from 'mobx';
import BaseModelState from "./BaseModelState";

class ScheduleVisitState extends BaseModelState {

    @observable technicians = [{id:1,name:"Jose"}];

    addTech() {
        console.log("tecnicians");
        let techNew = {id:2, name: "2"};
        this.technicians.push(techNew);
    }
}

export default ScheduleVisitState;
