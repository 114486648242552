import React from 'react';
import { ClipLoader } from 'react-spinners';
import  './../scss/components/SpinnerClipLoader.scss';

class SpinnerClipLoaderComponent extends React.Component {

    DOHERTY_LIMIT=350;

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        console.log("SpinnerClipLoaderComponent.componentDidMount");
        //Solo sale el componente si esperamos más de 0,3 segundos
        setTimeout(_=>this.setState( {...this.state,loading:true}), this.DOHERTY_LIMIT);
        //this.setState( {...this.state, loading:true});
    }

    render() {
        let classesCss="SpinnerClipLoader text-center";
        if (this.state.loading) {
            classesCss+=" loading";
        }
        return (
            <div className={classesCss}>
                <ClipLoader
                    color={'#fff'}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default SpinnerClipLoaderComponent
