import React, { Component } from 'react';
import { observer } from 'mobx-react';
import appState from '../../state/AppState';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import util from '../../util/Util';
import { observable, toJS } from 'mobx';
import WorkOrderModel from "../../models/WorkOrderModel";
import PropsUtil from "../../util/PropsUtil";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/PopUp";
import InfoBlock from "../../components/InfoBlock";
import PropTypes from 'prop-types';
import TypifiedValueModel from "../../models/TypifiedValueModel";
import AppLogger from "../../util/AppLogger";

@observer
class OrderFormWoComponent extends Component {

    static defaultProps = {
        orderId: null
    };
    static propTypes = {
        //OrderId from this WorkOrders
        orderId: PropTypes.number,
    };


    constructor(props) {
        super(props);
        this.state = { removeModalActive: false }
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    async componentDidMount() {
        await appState.typifiedState.loadStatus();
        await appState.typifiedState.loadWorkOrderType();
        await appState.typifiedState.loadSubTypeWorkOrder();
        appState.typifiedState.arrayLoadWorkOrderStatusDispatcher();
        appState.assetState.getAssets();
        appState.typifiedState.arrayLoadWorkOrderType();
        appState.typifiedState.arrayLoadsubTypeWorkOrders();
    }

    onEditRow(workOrder) {
        let orderId = this.props.orderId;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalworkorderls",
            "orderId": orderId,
            "workOrderId": workOrder.id,
            "rightModalTab": "workorder"
        });
    };

    handleInputChange(event) {
        this.props.row[event.target.name] = event.target.value;
    };

    async onDeleteRow() {
        let workOrderModel = new WorkOrderModel();
        workOrderModel.hidrate(this.props.row);
        appState.loadingBarState.start();
        try {
            if (util.hasValue(workOrderModel.id)) {
                await workOrderModel.remove();
            }
            let arrCopy = toJS(appState.workOrderState.workOrders);
            arrCopy.splice(this.props.rowIndex, 1);
            appState.workOrderState.workOrders = arrCopy;
            this.props.setHasSomeRowEditing(false);
        } catch (e) {
            this.log("onDeleteRow Exception ");
            this.log({ e });
        }

        if (this.ifPathStartsWith("/schedule")) {
            await appState.scheduleDropState.getWorkOrders(this.props.row.orderId, appState.scheduleDropState.workOrderIds);
        }
        appState.loadingBarState.finalize();
    }

    getAssetFromAssetId(assetId) {
        let result = {};
        for (let asset of appState.assetState.assets) {
            if (util.hasValue(assetId)) {
                if (asset.id === assetId) {
                    result = asset;
                    break;
                }
            }
        }
        return result;
    }


    onRemoveWO() {
        this.setState({ removeModalActive: 1 });
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let workOrder = this.props.row;
        this.log("render");
        const { t, i18n } = this.props;
        let equipo = util.getString(this.getAssetFromAssetId(workOrder.assetId));

        return (
            <InfoBlock componentObject={this} wrapper={"tbody"}>
                <tr style={{ 'border-bottom': 0 }}>
                    <td className="title-cell"
                        style={{ color: appState.typifiedState.arrStatusDispatcher[workOrder.statusDispatcherWorkOrder] && appState.typifiedState.arrStatusDispatcher[workOrder.statusDispatcherWorkOrder].color}}>
                        <InputTypeComponent
                            value={workOrder.code}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"code"}
                            type={"text"}
                            className="form-control"
                            showView={true}
                        />
                    </td>
                    <td colSpan="5" className="notes-cell">
                        {workOrder.generalDescription ? "Notas: " + workOrder.generalDescription
                            : "Notas: No hay notas"
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <InputTypeComponent
                            value={workOrder.assetId ? equipo.plate : (workOrder.assetPlate || t("No asignado"))}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"assetPlate"}
                            type={"text"}
                            className="form-control"
                            showView={true}
                        />
                    </td>
                    <td>
                        <InputTypeComponent
                            value={workOrder.assetId ? appState.typifiedState.arrAssetTypes[equipo.type] :
                                appState.typifiedState.arrAssetTypes[workOrder.assetType]}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"assetType"}
                            type={"text"}
                            className="form-control"
                            showView={true}
                        />

                    </td>
                    <td>
                        <div className="form-group">
                            <InputTypeComponent
                                value={workOrder.assetId ? appState.typifiedState.arrAssetSubtypes[equipo.subType] :
                                    (appState.typifiedState.arrAssetSubtypes[workOrder.assetSubType] || t("No asignado"))}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"assetSubType"}
                                type={"text"}
                                className="form-control"
                                showView={true}

                            />
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <InputTypeComponent
                                value={appState.typifiedState.arrWorkOrderType[workOrder.type]}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"type"}
                                type={"text"}
                                className="form-control"
                                showView={true}

                            />
                        </div>
                    </td>
                    <td>
                        <InputTypeComponent
                            value={appState.typifiedState.arrSubTypeWorkOrders[workOrder.subType] || t("No asignado")}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"subType"}
                            type={"text"}
                            className="form-control"
                            showView={true}

                        />
                    </td>

                    <td>
                        <div className="form-group">
                            {workOrder.documents && workOrder.documents.length === 0 &&
                            <span className="no-attached">No</span>
                            }
                            {workOrder.documents && workOrder.documents.length > 0 &&
                            <span className="yes-attached">{workOrder.documents.length}</span>
                            }
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <div className="remove-add">
                                <div onClick={() => this.onEditRow(workOrder)} title="Editar Contacto"
                                     className="edit">
                                    <span className="fas fa-edit"> </span>
                                </div>
                                <div onClick={() => this.onRemoveWO()} title="Eliminar" className="remove">
                                    <span className="fas fa-trash-alt"> </span>
                                </div>
                            </div>
                        </div>
                    </td>

                    <AlertModal isOpen={this.state.removeModalActive}
                                onCloseModal={() => this.setState({ "removeModalActive": false })}
                    >
                        <PopUp
                            title={t('cancelar')}
                            subtitle={t('OT')}
                            icon="fas fa-exclamation-triangle"
                            text={t('¿Estas Seguro de eliminar ordenes de trabajo?')}
                            label1={'Eliminar'}
                            label2={'No Eliminar'}
                            onClick1={() => this.onDeleteRow()}
                            onClick2={() => this.setState({ "removeModalActive": false })}
                            //order={order}
                            func={this.handleFormSubmit}
                        />
                    </AlertModal>
                </tr>
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default OrderFormWoComponent;
