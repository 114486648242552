import React from 'react';
import Square from './Square';
import { ItemTypes } from './Constants';
import { DropTarget } from 'react-dnd';
import appState from "../state/AppState";

const squareTarget = {
    drop(props, monitor, component) {
        if (monitor.didDrop()) {
            // If you want, you can check whether some nested
            // target already handled drop
            return;
        }

        // Obtain the dragged item
        const item = monitor.getItem();
        console.log("drop=>");
        console.log({item});
        console.log({props});
        console.log({component});

        // You can do something with it
        //ChessActions.movePiece(item.fromPosition, props.position);

        // You can also do nothing and return a drop result,
        // which will be available as monitor.getDropResult()
        // in the drag source's endDrag() method
        //moveKnight(props.x, props.y);
        return { moved: true };
    },
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver()
    };
}

function BoardSquare(objParams) {
    let { x, y, connectDropTarget, isOver, children } = objParams;
    console.log("BoardSquare=>");
    console.log(objParams);
    return connectDropTarget(
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
        }}>
            <Square>
                {children}
            </Square>
            {isOver &&
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 1,
                opacity: 0.5,
                backgroundColor: 'yellow',
            }}>
                {x+","+y}
            </div>
            }
        </div>
    );
}

export default DropTarget(ItemTypes.KNIGHT, squareTarget, collect)(BoardSquare);