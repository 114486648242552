import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import OrderModel from "../../models/OrderModel";
import SlotModel from "../../models/SlotModel";
import WorkOrderModel from "../../models/WorkOrderModel";
import ClientModel from "../../models/ClientModel";
import AppLogger from "../../util/AppLogger";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import { now } from "moment";
import util from "../../util/Util";

const Types = {
    ITEM: 'workInMonthUnit'
};

const itemTarget = {
    hover(props, monitor, component) {
        if (!component) {
            return null
        }
        let item = monitor.getItem();
        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()

    },

    //Vuelco un elemento dentro de la zona "Dropable"
    async drop(props, monitor, component) {
        let codeMonth = props.monthItem.code;
        const propsFromVisitDragged = monitor.getItem();
        // La visita puede ser nula. Esto significa que estoy moviendo un elemento.
        if (propsFromVisitDragged.order == null) {
            let id = propsFromVisitDragged.visitType.id;
            //Creo el Cliente
            let cliente = new ClientModel();
            cliente.findById(props.storeId);

            //Creo el OrderModel
            let order = new OrderModel();
            order.scheduleVisitId = id;
            order.status = '298';
            order.clientId = props.storeId;
            order.comments = propsFromVisitDragged.visitType.name;
            order.scheduledDate = codeMonth;

            let orderPlain = order.toPlainObject();
            orderPlain.loading = true;
            await appState.scheduleDropState.scheduledOrders.push(orderPlain);
            let length = appState.scheduleDropState.scheduledOrders.length;
            await order.persist();

            //Creo una O.T
            let workOrder = new WorkOrderModel();
            let typifiedType = new TypifiedValueModel();
            typifiedType.filters = [
                { "fieldName": "type", "fieldValue": "workOrderType", "filterOperator": "EQ" },
                { "fieldName": "value", "fieldValue": "Preventiva", "filterOperator": "EQ" },
            ];
            let type = await typifiedType.find();
            typifiedType.filters = [
                { "fieldName": "type", "fieldValue": "workOrderType", "filterOperator": "EQ" },
                { "fieldName": "value", "fieldValue": "Preventivo", "filterOperator": "EQ" },
            ];
            let subType = await typifiedType.find();
            workOrder.orderId = order.id;
            workOrder.assetType = "No Asignado";
            workOrder.assetSubType = "No Asignado";
            workOrder.generalDescription = propsFromVisitDragged.visitType.name;
            workOrder.type = type[0].id || 273;
            workOrder.subType = subType[0].id || 280;
            await workOrder.persist();

            //Guardo los slots
            let assignedSlotMainId = "";
            for (let scheduleVisitSlot of propsFromVisitDragged.visitType.scheduleVisitSlots) {
                let slot = new SlotModel();
                slot.orderId = order.id;
                slot.createdByPreventive = 1;
                slot.scheduledDuration = scheduleVisitSlot.scheduledDuration;
                slot.isDateTimeFixed = 1;
                slot.slotUserRole = scheduleVisitSlot.slotUserRole;
                slot.workOrderIds = "-1";
                //     slot.slotUserRole = scheduleVisitSlot.slotUserRole;
                if (scheduleVisitSlot.slotUserRole === SlotModel.MAIN) {
                    await slot.persist();
                    assignedSlotMainId = slot.id
                }
                if (scheduleVisitSlot.slotUserRole === SlotModel.SUPPORT) {
                    slot.assignedSlotMainId = assignedSlotMainId
                    await slot.persist();
                }
            }

            appState.scheduleDropState.scheduledOrders[length - 1] = order.toPlainObject();
            appState.scheduleDropState.loadScheduledVisits();
            props.loadClients();
            props.getTotals();
            props.getSlots();
        } else {
            //Muevo un elemento.
            if (propsFromVisitDragged.order.scheduledDate != props.monthItem.code) {
                let orderObservable = propsFromVisitDragged.order;
                orderObservable.scheduledDate = codeMonth;
                let order = new OrderModel();
                order.hidrate(orderObservable);
                await order.persist();
                appState.scheduleDropState.loadScheduledVisits();
            }
        }
    }

};

function log(msg) {
    AppLogger.get().debug(msg, this);
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class MonthDrop extends Component {
    static defaultProps = {};
    static propTypes = {
        //Objeto que contiene el mes (code) y el nombre de la visita: {label:"Ene", year:19, code:'201901'},
        monthItem: PropTypes.object,
        //Identificador de la tienda para que al generar el OrderModel asigne este valor
        storeId: PropTypes.string
    };

    render() {
        let dateMonth = this.props.monthItem.code;
        let dateMonthNow = util.getMoment(Date(now())).format("YYYYMM");
        let clasName = "";
        if (dateMonth < dateMonthNow) {
            clasName = " inactive ";
        }
        //let ordersWithDate = appState.preventOrders.filter(order=>order.date!=null);
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <td key={this.props.monthItem.code} className={"drop-hours " + clasName}
                style={{ width: '60px', border: "1px solid #D3D3D3" }}>
                {this.props.children}
            </td>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DropTarget(Types.ITEM, itemTarget, collect)(MonthDrop)
