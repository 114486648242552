import BaseValidation from "./BaseValidation";

export default class MaxLengthValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect=true;
        if(this.event==="change"){
            // alert("length => " + args);
            let maxValue = (args);
            if((inputValue.length)> maxValue) {
                isCorrect=false;
            }
        }
        return isCorrect;
    }

}
