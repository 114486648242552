import React, { Component, Suspense } from 'react';
import './App.scss';
import { observer } from 'mobx-react';
import { ApolloProvider } from 'react-apollo';
import ApoloClientInstance from "./storage/ApoloClientInstance";
import AppRouter from "./AppRouter";
import AppState from "./state/AppState";
import LoginRouter from "./LoginRouter";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend';
import LoadTranslations from "./translator/LoadTranslations";
import VsfLoadingBar from "./network/VsfLoadingBar";
import appState from "./state/AppState";

const Spinner = () => {
    return <h1>Loading</h1>
};
//Al hacer un cambio de URL no hace scrollToTop
window.consoleapp={};
window.consoleapp.log = window.console.log;

window.console.log=()=> {};

@observer
class App extends Component {

    state={
        loadedMessages:false
    }

    async componentDidMount() {
        appState.loadingBarState.initialize();
        let loadTranslations = new LoadTranslations();
        await loadTranslations.loadMessages();
        this.setState({loadedMessages:true});
        setTimeout(function(){ if(appState.loadingBarState.loadingBarProgress != 100) appState.loadingBarState.finalize(); }, 1000);
    }

    render() {
        if (!this.state.loadedMessages) {
            return (
                <React.Fragment>
                    <VsfLoadingBar/>
                </React.Fragment>
            )
        }
        return (

        <div>
            {!AppState.loginState.isUserLogged?
               <LoginRouter/>
            :
                <Suspense fallback={<Spinner />}>
                    <ApolloProvider client={ApoloClientInstance}>
                        {/* Esto permite funcionalidad dnd en multiples pagnias. No se puede tener 2 veces el componente HTML5Backend */}
                        <DragDropContextProvider backend={HTML5Backend}>
                            <AppRouter/>
                        </DragDropContextProvider>
                    </ApolloProvider>
                </Suspense>
            }
            </div>

        );
    }
}
export default App;
