import {observable} from 'mobx';
import BaseModelState from "./BaseModelState";

class OrderState extends BaseModelState {

    @observable workOrders = [{id:1,name:"Jose"},{id:1,name:"Jose"}];

    addWorkOrder() {
        console.log("addWorkOrder");
        let workOrderNew = {id:2, name: "2"};
        this.workOrders.push(workOrderNew);
    }
}

export default OrderState;
