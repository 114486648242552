import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {DefaultRoute} from 'react-router-dom'
import appState from "../state/AppState";
import VsfLink from "../components/VsfLink";


@observer
class SidebarItem extends Component {
    render() {
        let props = this.props;
        let title = props.title;

        return (
            <li className="nav-item">
                {this.props.href != "" ?
                    <React.Fragment>

                    <VsfLink onClick={(e)=> this.clckMenu(e)} className={this.props.active==true?"nav-link active":"nav-link"} to={this.props.href}>
                        <i className={this.props.icon}></i>
                        <span className="nav-item__name">{title} {this.props.active}</span>
                        {this.props.badge != null &&
                        <span className="badge badge-info">{this.props.badge}</span>
                        }
                        {this.props.arrow  &&
                        <i className="fas fa-angle-left"></i>
                        }
                    </VsfLink>
                     {/*El siguiente sólo sale cuando está plegado el sidebar   */}
                    <VsfLink onClick={(e)=> this.clckMenu(e)} className="nav-link--slide" to={this.props.href}>
                        <span className="nav-item__name">{title} {this.props.active}</span>
                        {this.props.badge != null &&
                        <span className="badge badge-info">{this.props.badge}</span>
                        }
                        {this.props.arrow  &&
                        <i className="fas fa-angle-left"></i>
                        }
                    </VsfLink>
                    {this.props.children != null && this.props.children.length > 0 &&
                        <ul className="nav-item--dropdown">
                            {this.props.children}
                        </ul>
                    }
                        {this.props.line &&
                        <div className="nav-link--border"></div>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className={appState.layoutState.sidebarMinimized && this.props.active ?"nav-link active":"nav-link"} onClick={(e)=> this.props.click() }>
                            <i className={this.props.icon}></i>
                            <span className="nav-item__name master">{title}</span>
                            {this.props.badge != null &&
                            <span className="badge badge-info">{this.props.badge}</span>

                            }
                            {this.props.arrow && props.stateOpen ==false?
                                <i className="fas fa-angle-left"></i>
                                :

                                <span className="fas fa-angle-up"></span>

                            }
                        </div>

                        {props.stateOpen &&
                            <div>
                            {props.children != null  &&
                                <ul className="nav-item--dropdown">
                                    {/*<span className="fas fa-angle-left"></span>*/}
                                    {this.props.children}
                                </ul>
                            }
                            </div>
                        }

                        {appState.layoutState.sidebarMinimized &&
                            <div className="dropdown--slide">
                                {props.children != null  &&
                                    <ul className="nav-item--dropdown">
                                        {this.props.children}
                                    </ul>
                                }
                            </div>
                        }
                        {this.props.line &&
                        <div className="nav-link--border"></div>
                        }
                    </React.Fragment>
                }
            </li>
        );
    }
    clckMenu= (e)=>{
        /*
      console.log('hola dede el menu', e.target.href)
       let urlActive=  e.target.href;
           appState.layoutState.clickSisdeBarItem(urlActive)
*/
    };

   /* openCloseItemsMenur= (e) =>{
        console.log('evento ==e',e.target.id);
        /!*if(e.target.childNodes[0]=== 'Maestros') {
            appState.layoutState.clickHiddenMenuMasterData();
        }*!/
        //appState.layoutState.clickHiddenMenuMasterData();
    }*/
}

export default SidebarItem;

