import React from 'react';
import {observer} from 'mobx-react';
import {withApollo} from "react-apollo";
import BaseForm from "../base/BaseForm";
import withTranslate from "../../translator/withTranslate";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import ScheduleVisitModel from "../../models/ScheduleVisitModel";
import ScheduleVisitSlot from "./ScheduleVisitSlot";

@observer
class ScheduleVisitForm extends BaseForm {


    constructor(props) {
        super(props);
        this.nameMainType = "scheduleVisit";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false
        }
    }


    getModelQuery() {
        return new ScheduleVisitModel();
    }




    render() {
        const {t, i18n} = this.props;
        console.log("ScheduleVisitModel.render()");
        let scheduledVisit = this.graphDataMainType;
        if (scheduledVisit == null) {
            scheduledVisit = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();

        return (

            <React.Fragment>
                <PageTitle
                    title={t("Visitas Preventivas")}
                    subtitle={this.graphDataMainType.name||t("Nueva")}
                >
                </PageTitle>
                <form
                    className="model-form"
                    name="formulario"
                    noValidate
                    onSubmit={(e) => this.handleFormSubmit(e)}
                >

                    <div className={'card mb-3'}>
                        <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                           error={this.graphStatus.mutationError}
                                           working={this.graphStatus.networkWorking}
                        />
                        <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                            formError={this.graphStatus.formError}
                        />
                        {errorsMapped[""] != null ?
                            <div class="alert alert-danger" role="alert">
                                {errorsMapped[""].map(error => <div>{error.message}</div>)}
                            </div>
                            :
                            null
                        }
                        <FormWrapper>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataScheduleVisit()}
                                active={"Datos Generales"}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={" col-12"}
                            />
                            <GroupOfField title={t("Datos")}
                                          subtitle={t("Visita Preventiva")}
                                          icon="fa-clipboard"

                            >
                                <InputTypeComponent
                                    value={scheduledVisit.name}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"name"}
                                    title={t("Nombre para el nuevo Tipo de Visita Preventiva")}
                                    classGroup={"col-6"}
                                    type={"text"}
                                    errors={errorsMapped.name}
                                />


                                <ScheduleVisitSlot
                                    classGroup={"col-12"}
                                    id={this.graphDataMainType.id}
                                    {...this.props}
                                />
                            </GroupOfField>

                        </FormWrapper>
                    </div>
                    <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus}></FormButtons>
                </form>
            </React.Fragment>

        )
    }
}

export default withTranslate(withApollo(ScheduleVisitForm));
