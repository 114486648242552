import React from 'react';
import {observer} from 'mobx-react';
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";
import {toJS} from "mobx/lib/mobx";

@observer
class FiltersPage extends React.Component {

    nameVariableStateFilter="userListFilters-Personalizar-en-subclase";

    constructor(props) {
        super(props);
        this.initialize();
    }

    initialize() {
        if (appState[this.nameVariableStateFilter]==null) {
            appState[this.nameVariableStateFilter]={
                currentFilter: {},
                currentFilterOps: {},
                currentFilterLabels: {},
            }
        }
        appState[this.nameVariableStateFilter].filterType={};
        //this.log(toJS(appState[this.nameVariableStateFilter]));
        //this.log("FilterPage.constructor()");
        appState["currentFilterName"]=this.nameVariableStateFilter;
    }

    /**
     * Actualiza el valor de un filtro.
     * @param event
     * @param op operator filter
     */
    updateInputFilterEvent(event, op) {
        let name = event.target.name;
        let value = event.target.value;
        let label = event.target.label;
        if (op == null) {
            op = "EQ";
        }
        let currentFilter = {...appState[this.nameVariableStateFilter].currentFilter, [name]: value};
        let currentFilterOps = {...appState[this.nameVariableStateFilter].currentFilterOps, [name]: op};
        let currentFilterLabels = {...appState[this.nameVariableStateFilter].currentFilterLabels, [name]: label};
        appState[this.nameVariableStateFilter]={currentFilter, currentFilterOps, currentFilterLabels};
    }

    render() {
        return null;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default FiltersPage;
