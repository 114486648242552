import React from 'react';
import { observer } from "mobx-react/index";
import PageTitle from "../components/PageTitle";
import user from "../models/UserModel";
import UserModel from "../models/UserModel";
import appState from "../state/AppState";
import BaseForm from "./base/BaseForm";
import Select2Component from "../components/fields/Select2Component";
import NetworkStatusInfo from "../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../components/status/LoadingOrErrorInfo";
import withTranslate from "../translator/withTranslate";
import { observable } from "mobx";
import AppLogger from "../util/AppLogger";
import FormButtons from "../components/FormButtons";

@observer
class ProfileForm extends BaseForm {
    nameMainType = "user";
    @observable user;

    constructor(props) {
        super(props);
        this.nameMainType = "user";
        this.graphData.data[this.nameMainType] = {};

    }

    async componentDidMountImpl() {
        this.log("OrderForm.componentDidMount()");
        await super.componentDidMountImpl();
        let user = await appState.loginState.getUser();
        //let user = await appState.userState.reloadUserByEmail(mail);
        this.graphData.data[this.nameMainType] = user.toPlainObject();

        appState.typifiedState.loadLocale();
        appState.typifiedState.loadTimeZones();
    }

    async formDidSave(model, previousId) {
        await super.formDidSave(model, previousId);
        if (model.timeZone != null) {
            window.localStorage.setItem('currentTimeZone', model.timeZone);
        }
        if (model.locale != null) {
            window.localStorage.setItem('currentLanguage', model.locale);
        }
    }

    getFormId() {
        let id = appState.loginState.getUserId();
        return id;
    }

    getModelQuery() {
        return new UserModel();
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        let mailOK = localStorage.getItem('mail');
        const { t, i18n } = this.props;
        let profileUser = this.graphDataMainType;
        if (profileUser == null) {
            profileUser = {};
        }
        this.log('user====>', profileUser);

        let localesForSelect = [];
        for (let locale of appState.typifiedState.locales) {
            localesForSelect.push({ value: locale.code, label: locale.value })
        }

        let timeZonesForSelect = [];
        for (let timeZone of appState.typifiedState.timeZones) {
            timeZonesForSelect.push({ value: timeZone.code, label: timeZone.value })
        }

        return (
            <React.Fragment>

                <PageTitle
                    title={t('Mi perfil')}
                    subtitle={mailOK}
                    toLowerCase={true}
                />
                <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                   error={this.graphStatus.mutationError}
                                   working={this.graphStatus.networkWorking}
                />
                <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                    formError={this.graphStatus.formError}
                />

                <div className="card user mb-3 p-2">
                    <div className="card-body">
                        <div className="user__info">
                            <div className="row">
                                <div className="col-md-3 mt-2">
                                    <div className="user__pic">
                                        <img src="/img/logo.png" alt="Usuario" />
                                        <button type="button" className="user__pic__edit" title="Editar Foto"><span
                                            class="fas fa-pen-square" /></button>
                                    </div>
                                </div>


                                <div className="col-md-8 mt-2">


                                    <form
                                        className="model-form"
                                        name="formulario"
                                        noValidate
                                        onSubmit={(e) => this.handleFormSubmit(e)}
                                    >
                                        <div className="form-row with-border">
                                            <div className="form-group no-event col-md-4">
                                                <p className="control-label"
                                                   aria-labelledby="userName">{t("Nombre")}</p>
                                                <div className="form-control font-weight-bold"
                                                     id="userName">{profileUser ? profileUser.firstName : ""}</div>
                                            </div>
                                            <div className="form-group no-event col-md-4">
                                                <p className="control-label"
                                                   aria-labelledby="userSurame">{t("Apellidos")}</p>
                                                <div className="form-control"
                                                     id="userSurname">{profileUser ? profileUser.lastName : ""}</div>
                                            </div>
                                            <div className="form-group no-event col-md-4">
                                                <p className="control-label"
                                                   aria-labelledby="userEmail">{t("Email")}</p>
                                                <div className="form-control"
                                                     id="userEmail">{profileUser ? profileUser.email : ""}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">

                                            <Select2Component value={profileUser.locale}
                                                              onChange={(e) => this.updateInputEvent(e)}
                                                              name={"locale"}
                                                              title={t("Lenguaje")}
                                                              classGroup={"col-6"}
                                                              options={localesForSelect}
                                                //errors={//errorsMapped.locale}
                                            />


                                            <Select2Component
                                                value={profileUser.timeZone}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"timeZone"}
                                                title={t('Zona Horaria')}
                                                classGroup={"col-6"}
                                                options={timeZonesForSelect}
                                                //errors={//errorsMapped.locale}
                                            />

                                        </div>
                                        <FormButtons id={profileUser.id} formStatus={this.graphStatus} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withTranslate(ProfileForm);
