import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import ClientModel from "../models/ClientModel";
import util from "../util/Util";

class Client extends BaseModelState {

    @observable isVAT;
    @observable isPos;

    clickHiddenMenu(){
        this.sidebarMinimized = !this.sidebarMinimized;
        let sidebarMinimizedNumber="0";

    }

    @observable clients=[];
    @observable client=[];

    async reloadClientFromClientId(clientId) {
        let clientQuery = new ClientModel();
        clientQuery.id = clientId;
        if (util.hasValue(clientQuery.id)) {
            let result = await clientQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.client = result[0];
        }
    }

}

export default Client;
