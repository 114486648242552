import React, { Component } from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";

@observer
class ClientListNameItem extends Component {
    render() {
        let props= this.props;
        let value = appState.clientCacheState.arrClients[props.value];

        return (
            <div>
                <div>{value}</div>
            </div>
        );
    }
}
export default ClientListNameItem
