import React from 'react';
import { observer } from "mobx-react/index";
import appState from '../../state/AppState';
import { withApollo } from "react-apollo";
import PropTypes from 'prop-types';
import { computed } from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import PropsUtil from "../../util/PropsUtil";
import { withRouter } from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import VsfButton from "../../components/VsfButton";
import AppLogger from "../../util/AppLogger";
import ScheduleVisitSlotModel from "../../models/ScheduleVisitSlotModel";
import ScheduleVisitSlotForm from "./ScheduleVisitSlotForm";
import withTranslate from "../../translator/withTranslate";

@observer
class ScheduleVisitSlot extends BaseEditableTable {


    static defaultProps = {
        foreingKeyfield: "",
    }

    static propTypes = {
        foreingKeyfield: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.foreingKeyfield = "scheduleVisitId";
    }

    @computed get mobxListado() {
        return appState.scheduleSlotState.scheduleSlots;
    }

    getModelTable() {
        return new ScheduleVisitSlotModel();
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("ScheduleVisitSlot componentDidMount =>");
        this.log("this.getFormId() =>");
        this.log(this.getFormId());

        await this.reloadTable(this.getFormId());
    }

    async reloadTable(scheduleId) {
        let scheduleQuery = new ScheduleVisitSlotModel();
        let scheduleModel = await scheduleQuery.getVisitSlotFromScheduleId(scheduleId);
        appState.scheduleSlotState.scheduleSlots = util.arrayModelToPlainObjects(scheduleModel);
    }

    calcularHoras() {
        let result = 0.0;
        this.mobxListado.map((visitSlot, index) => {
            if (visitSlot.scheduledDuration != null) {
                console.log("numero " + visitSlot.scheduledDuration);
                if (result == 0)
                    result = parseInt(visitSlot.scheduledDuration);
                else if (visitSlot.scheduledDuration != '') {
                    result = parseInt(visitSlot.scheduledDuration) + parseInt(result);
                }
            }
        });
        return result;
    }

    render() {
        this.log("ScheduleVisitSlot render =>");
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let horas = this.calcularHoras();
        return (


            <InfoBlock componentObject={this}>
                <div className={this.props.classGroup || "row mt-4"}>
                    <div className="col-7">
                        <div className="table-edit">
                            <table className="table"
                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                <thead>
                                <tr>
                                    <th scope="col">{t("Rol")}</th>
                                    <th scope="col">{t("Tiempo Estimado")}</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody className="border-end">

                                {this.mobxListado.map((code, index) => (
                                    <ScheduleVisitSlotForm
                                        key={code && code.id}
                                        rowIndex={index}
                                        scheduledSlotsLenghtCero={this.mobxListado.length == 1}
                                        row={code}
                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                        {...this.props}
                                    />
                                ))}

                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>{t('TOTAL')}</td>
                                    <td colSpan="2">{horas + " h."}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="text-right">
                            <VsfButton
                                disabled={this.state.hasSomeRowEditing}
                                label={"Crear nueva"}
                                onClick={_ => this.onOpenRowNewEmpty()}
                                isEditing={this.props.id == null ? true : this.state.hasSomeRowEditing}
                                classButton="btn--loader"
                            />
                        </div>
                    </div>
                    {/*<OrderSlot key={index} slot={slot} />*/}
                </div>
            </InfoBlock>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo(withTranslate(ScheduleVisitSlot)));
