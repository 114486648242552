import React from 'react';
import Autosuggest from 'react-autosuggest';
import FormInputWrapper from "./FormInputWrapper";
import util from "../../util/Util";

class AutoSuggestComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            isLoading: false,
            suggestions: []
        };
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.value != null && this.props.value != undefined && this.props.value != "") {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    /**
     * Lanza el proceso de cargar suggestions
     */
    async loadSuggestions(value) {

        this.setState({
            isLoading: true
        });

        await this.props.loadSuggestions(value).then((newSuggestions) => {
            this.setState({
                isLoading: false,
                suggestions: newSuggestions
            });
            return true;
        });

    }

    /**
     * Cambia el valor del autosuggest
     *
     * @param event
     * @param newValue
     */
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    /**
     * Se lanza cuando seleccionamos una suggestion
     *
     * @param event
     * @param suggestion
     * @param suggestionValue
     * @param suggestionIndex
     * @param sectionIndex
     * @param method
     */
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.onChange(suggestion.value);
    };

    /**
     * Recibe un valor, y a partir de ahi recarga las suggestions
     *
     * @param value
     */
    onSuggestionsFetchRequested = ({ value }) => {
        if (value.length > 2) {
            this.loadSuggestions(value);
        } else {
            this.setState({
                suggestions: []
            });
        }
    };

    /**
     * Resetea el array de suggestions
     */
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    /**
     * Obtiene el nombre de la suggestion para mostrar
     */
    getSuggestionValue(suggestion) {
        return suggestion.label;
    }

    /**
     * Renderiza la suggestion en la lista del autosuggest
     */
    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.label}</span>
        );
    }

    /**
     * Limpia el value del autosuggest
     */
    clearAutosuggest() {
        this.setState({
            value: ""
        });
        this.props.onChange("");
    }

    render() {

        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: this.props.placeholder,
            value: util.hasValue(value) ? value : "",
            onChange: this.onChange
        };

        return (
            <>
                <FormInputWrapper
                    classGroup={this.props.classGroup}
                    classInputType={"Select2Component"}
                    name={this.props.name}
                    postfix={this.props.postfix}
                    prefix={this.props.prefix}
                    errors={this.props.errors}
                    title={this.props.title}
                    required={this.props.required}
                    isClearable={this.props.isClearable}
                    info={this.props.info}
                    forceMedia={this.props.forceMedia}
                >
                    {this.props.showView ?
                        <div>
                            {this.props.value}
                        </div>
                        :
                        <div>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                onSuggestionSelected={this.onSuggestionSelected}
                                inputProps={inputProps}
                            />
                            {this.state.value != "" &&
                            <span onClick={() => this.clearAutosuggest()} aria-label="Clear value"
                                  className="Select-clear-zone react-autosuggest__clear" title="Clear value">
                                    <span className="Select-clear">×</span>
                                </span>
                            }
                        </div>
                    }
                </FormInputWrapper>
            </>
        )
    }

}

export default AutoSuggestComponent;
