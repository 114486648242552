import PropTypes from 'prop-types'
import React from 'react'
import ReactDom from 'react-dom'
import Transition from 'react-transition-group/Transition'
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";

const overlayStyles = {
    transition: 'opacity 0.3s ease',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.3)',
    left: -99999,
    top: 0,
    right: 0,
    bottom: 0
};

const body = document.getElementsByTagName('body')[0];

let globalLayoutZIndex = 110;

class Overlay extends React.Component {
    static defaultProps = {
        delay: 200,
        style: {
            backgroundColor: 'rgba(0,0,0,0.2)', zIndex: appState.layoutState.zIndex,
        }
    };

    static propTypes = {
        // timeout in milisecond
        delay: PropTypes.number,
        //show/hide the overlay element
        show: PropTypes.bool.isRequired
    };
    el;
    modalPush;

    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
        };
        this.currentLayaoutZIndex = 100;
        this.el = document.createElement('div');
    }

    componentDidMount() {
        this.log("componentDidMount");
        this.modalPush = document.getElementsByClassName('modal-push')[0];
        if (this.modalPush) {
            this.modalPush.append(this.el);
        } else
            body.appendChild(this.el);

        this.currentLayaoutZIndex = globalLayoutZIndex;
        globalLayoutZIndex++;
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        if (this.modalPush) {
            this.modalPush.removeChild(this.el);
        } else
            body.removeChild(this.el);
        globalLayoutZIndex--;
    }

    componentWillReceiveProps(props) {
        let delay = props.delay;
        clearTimeout(this.timeout);
        if (props.show) {
            if (delay) {
                this.timeout = setTimeout(() => {
                    if (this.props.show) {
                        this.setState({ show: true })
                    }
                }, delay)
            } else {
                this.setState({ show: true })
            }
        } else {
            this.setState({ show: false })
        }
    }

    render() {
        let props = this.props;
        let { show, className, duration } = props;
        let styles = Object.assign({}, overlayStyles, props.style);
        //styles["zIndex"] = this.currentLayaoutZIndex;

        return ReactDom.createPortal(
            <Transition in={show} timeout={300}>
                {(state) => {
                    styles.opacity = ['entered', 'entering'].indexOf(state) !== -1 ? 1 : 0
                    if (state === 'exited') styles.pointerEvents = 'none';
                    return (
                        <div className={className} style={{ ...styles, }}
                             onClick={_ => this.props.onClick(_)}>
                            {props.children}
                        </div>
                    )
                }}
            </Transition>
            , this.el
        )
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default Overlay
