import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo/index";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import BaseListFilter from "../../subpages/BaseListFilter"
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import AssetModel from "../../models/AssetModel";
import MoreButtons from "../../components/MoreButtons";
import PropsUtil from "../../util/PropsUtil";
import AssetListFilters from "./AssetListFilters";
import ListPageCounts from "../../components/ListPageCounts";
import ZoneColorList from "../../components/list/custom/ZoneColorList";
import {action} from "mobx";

@observer
class AssetList extends BaseList {

    constructor(props) {
        super(props);
        this.nameVariableStateFilter = "assetListFilters";
        this.nameMainType = "asset";
        this.deleteFilter = this.deleteFilter.bind(this);
        this.quickView = true;
        this.state = {
            ...this.state,
            appear: false
        };
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
    }


    getListFields() {
        return [
            {
                title: this.props.t("Número Equipo"),
                name: "plate",
                link: true,
                visible: true,
                component: (props) => <TextListItem link={true} {...props} />
            },
            {
                title: this.props.t("Tipo de Equipo"),
                name: "typeAsset.value",
                visible: true,
                component: (props) => <TextListItem link={true} {...props} />
            },
            {
                title: this.props.t("Subtipo de Equipo"),
                visible: true,
                name: "subTypeAsset.value",
                component: (props) => <TextListItem  link={true} {...props} />
            },
            {
                title: this.props.t("Fabricante"),
                visible: true,
                name: "manufacturerAsset.value",
                component: (props) => <TextListItem link={true} {...props} />
            },
            {
                title: this.props.t("Número Serie"),
                name: "serialNumber",
                component: TextListItem,
                visible: true },
            {
                title: this.props.t("Propietario"),
                name: "clientPropietary.name",
                component: (props) =>
                    <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Cliente"),
                name: "clientPos.name",
                component: (props) =>
                    <TextListItem link={true} {...props} />,
                visible: true
            },

            {
                title: this.props.t("Garantía"),
                name: "warranty", component: (props) =>
                    <TextListItem warrantyAsset={true} link={true} {...props} />,
                visible: true
            },
            {   title: this.props.t("Fin Garantía"),
                name: "endDateWarranty",
                component: TextListItem,
                visible: true
            },

            // {
            //     title: this.props.t("Zona"),
            //     visible: true,
            //     name: "zone",
            //     component: (props) => <ZoneColorList link={true} clients={appState.clientCacheState.clients}
            //                                          zoneWorkOrderList={true} assetLocationList={true}
            //                                          icon="fas fa-map-marked-alt" {...props} />,
            // },
            // {
            //     title: this.props.t("Explotación"),
            //     name: "clientExploitation.name",
            //     component: (props) =><TextListItem link={true} {...props} />,
            //     visible: true
            // },

            // {
            //     title: this.props.t("Mantenimiento"),
            //     name: "maintenance",
            //     component: (props) =>
            //         <TextListItem link={true} clients={appState.clientCacheState.clients}{...props} />,
            //     visible: true
            // },
            // {
            //     title: this.props.t("Estado"),
            //     name: "statusAsset.value",
            //     withOrder: false,
            //     component: (props) => <TextListItem link={false} {...props} />,
            //     visible: true
            // },
        ];
    }

    getListState() {
        return appState.assetState;
    }

    getDefaultOrder() {
        return "plate";
    }
    @action
    async componentDidMountImpl() {
        await Promise.all([
            // await appState.typifiedState.loadStoreId(),
            // await appState.typifiedState.loadManufacturerRef(),
            // await appState.typifiedState.loadAssetStatus();
            // await appState.typifiedState.loadZones(),
            // await appState.typifiedState.loadManufacturer(),
            // await appState.typifiedState.loadManufacturer(),
            // await appState.typifiedState.loadAssetType(),
            // await appState.typifiedState.loadAssetSubtype(),
            // await appState.typifiedState.arrayLoadAssetSubType(),
            // await appState.typifiedState.arrayLoadAssetType(),
            // await appState.typifiedState.arrayLoadManufacturer(),
            // await appState.typifiedState.arrayLoadZones(),

            // await appState.clientCacheState.loadClientCache()
    ]);
        await super.componentDidMountImpl();

    }


    openQuickEdit(row) {
        this.getListState().quickEdit.row = row;
        this.propsUtil.changeUrlRequest({
            assetId: row.id,
            rightModal: "modalassets",
            rightModalTab: "assets",
            fromRightModal: true
        })
    }

    getModelQuery() {
        let result =new AssetModel();
        result.addRelatedTable('statusAsset');
        result.addRelatedTable('typeAsset');
        result.addRelatedTable('manufacturerAsset');
        result.addRelatedTable('subTypeAsset');

        result.addRelatedTable('clientPropietary');
        // result.addRelatedTable('clientExploitation');
        result.addRelatedTable('clientPos');


        return result;
    }

    render() {
        let { t } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <div>
                <ListItemSelect stateListColumnsSelect={this.stateListColumnsSelect}
                                name={this.graphOperation}
                                subtitle={'Equipos'}
                />
                <BaseListFilter listComponent={this} stateFilter={this.stateListFilter}>
                    {this.renderFilters()}
                </BaseListFilter>
                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div class="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={t("Equipos")}
                    subtitle={t("Listado")}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nuevo Equipo", onClick: () => {
                                    this.propsUtil.changeUrl("/asset/form/")
                                }
                            }
                        ]
                    }
                />
                </PageTitle>
                <div className="card mb-3">
                    <div className="card-body pb-0">

                        <SearchComponent
                            deleteFilter={this.deleteFilter}
                            listState={this.getListState()}
                            listData={this.getListData()}
                            numEntriesPerPage={this.numEntriesPerPage}
                            fields={fields}
                            {...props}
                            includeSavedFavourites={this.includeSavedFavourites}
                            onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                            includeChangeColumns={this.includeChangeColumns}
                            includeFilters={this.includeFilters}
                            includeDownload={this.includeDownload}
                            stateListColumnsSelect={this.stateListColumnsSelect}
                            includeSearch={this.includeSearch}
                            model={this.getModelQuery()}
                            listComponent={this}
                            favouriteModalOpen={this.state.favouriteModalOpen}
                            toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                        />
                        <ListComponent
                            quickEdit={this.quickEdit}
                            getListState={this.getListState()}
                            listData={this.getListData()}
                            quickView={this.quickView}
                            status={this.getListStatus()}
                            numEntriesPerPage={this.numEntriesPerPage}
                            openQuickEdit={(row) => this.openQuickEdit(row)}
                            fields={fields}
                            urlForm={"/asset/form"}
                            {...props}>
                            <EmailList name="name" />
                        </ListComponent>
                    </div>
                </div>
                <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                                listState={this.getListState()}
                                listData={this.getListData()} {...props} />
                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />
            </div>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <AssetListFilters key={"assetlist"} />
            </React.Fragment>
        )
    }
}

export default withRouter(withApollo(withTranslate(AssetList, "AssetList")));
