import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Links from "../util/Links";
import VsfLink from "./VsfLink";
import AppLogger from "../util/AppLogger";
import PropsUtil from "../util/PropsUtil";

@observer
export default class PaginationComponent extends Component {

    getUrlLinkPageNext() {
        let links = new Links(this.props.location);
        return links.getUrlLinkPageNext(this.props.listData.connection.pageInfo.endCursor);
    }

    hasNext() {
        return this.props.listData.connection.pageInfo.hasNextPage;
    }


    getUrlLinkPagePrev() {
        let links = new Links(this.props.location);
        return links.getUrlLinkPagePrev(this.props.listData.connection.pageInfo.startCursor);
    }

    getUrlLinkPageNumber(number) {
        let links = new Links(this.props.location);
        return links.getUrlLinkPageNumber(number);
    }


    getUrlLinkPageFirst() {
        let links = new Links(this.props.location);
        return links.getUrlLinkPageFirst(this.props.listData.connection.pageInfo.startCursor);
    }

    getUrlLinkPageLast() {
        let links = new Links(this.props.location);
        return links.getUrlLinkPageLast(this.props.listData.connection.pageInfo.startCursor);
    }

    hasPrev() {
        return this.props.listData.connection.pageInfo.hasPreviousPage;
    }

    getAddPage(pageToPrint, currentPage) {
        let linkActive = (pageToPrint != currentPage);
        let result = {
            className: linkActive ? "page-item" : "page-item linkNotAllowed",
            to: this.getUrlLinkPageNumber(pageToPrint),
            text: pageToPrint,
        };
        return result;
    }

    getAddPageSeparator() {
        return {
            className: "page-item disabled",
            text: "...",
        }
    }

    getPaginationValues() {
        return [
            { number: 10, label: "10 filas" },
            { number: 20, label: "20 filas" },
            { number: 100, label: "100 filas" },
            { number: 500, label: "500 filas" },
        ]
    }

    onPaginationChange(e) {
        //console.log({e});
        const { name, value } = e.target;
        //compruebo si el valor de paginación es válido
        let values = this.getPaginationValues();
        let esValorValido = values.filter(item => item.number == value).length > 0;
        if (esValorValido) {
            let links = new Links(this.props.location);
            this.log("links")
            let newUrl = links.getUrlLinkPagination(value);
            let page = this.propsUtil.getRequest('page');
            if (page != null){
                newUrl = newUrl.replace("&page=" + page,"");
                newUrl = newUrl.replace("page=" + page,"");
            }
            this.props.history.push(newUrl);

        }
    }

    render() {
        let props = this.props;
        this.propsUtil = new PropsUtil(this.props);

        const params = new URLSearchParams(this.props.location.search);
        let page = parseInt(params.get('page'));
        if (page == 0 || isNaN(page)) {
            page = 1;
        }
        let totalCount = props.listData.connection.totalCount;
        let from = this.props.numEntriesPerPage * (page - 1) + 1;
        let to = from + this.props.listData.connection.items.length - 1;

        let lastNumberPage = parseInt(totalCount / this.props.numEntriesPerPage) + 1;
        let pages = [];
        let pageFirstAllowed = false;
        if (page > 1) {
            pageFirstAllowed = true;
        }
        let pageLastAllowed = false;
        if (page < lastNumberPage) {
            pageLastAllowed = true;
        }
        let pagePrevAllowed = false;
        if (page > 1) {
            pagePrevAllowed = true;
        }
        let pageNextAllowed = false;
        if (page < lastNumberPage) {
            pageNextAllowed = true;
        }
        console.log({ lastNumberPage, from, to, page, totalCount, numEntriesPerPage: this.props.numEntriesPerPage });

        pages.push({
            className: pageFirstAllowed ? "page-item" : "page-item linkNotAllowed",
            to: this.getUrlLinkPageFirst(),
            icon: "fa fa-backward"
        });
        pages.push({
            className: pagePrevAllowed ? "page-item" : "page-item linkNotAllowed",
            to: this.getUrlLinkPagePrev(),
            icon: "fa fa-caret-left"
        });
        //Mostramos las páginas anterior y siguiente
        let numberPagesSlot = 1;
        if (page - numberPagesSlot > 1) {
            pages.push(this.getAddPageSeparator());
        }
        for (let i = numberPagesSlot; i >= 1; i--) {
            if (page - i > 0) {
                pages.push(this.getAddPage(page - i, page));
            }
        }
        pages.push(this.getAddPage(page, page));
        for (let i = 1; i <= numberPagesSlot; i++) {
            if (page + i <= lastNumberPage) {
                pages.push(this.getAddPage(page + i, page));
            }
        }
        if (page + numberPagesSlot < lastNumberPage) {
            pages.push(this.getAddPageSeparator());
        }
        pages.push({
            className: pageNextAllowed ? "page-item" : "page-item linkNotAllowed",
            to: this.getUrlLinkPageNext(),
            icon: "fa fa-caret-right"
        });
        pages.push({
            className: pageLastAllowed ? "page-item" : "page-item linkNotAllowed",
            to: this.getUrlLinkPageNumber(lastNumberPage),
            icon: "fa fa-forward"
        });


        return (
            <div className="row">
                <div className="pagination-bottom col-12 d-flex justify-content-end">
                    <div className="pagination-div">
                        <div className="rowsPerPage ml-1 mr-1">
                            <select className="form-control" onChange={e => this.onPaginationChange(e)}>
                                {this.getPaginationValues().map(paginationObj => (
                                    <option value={paginationObj.number}

                                    >{paginationObj.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="pagination-div">
                        <ul className="pagination">
                            {pages.map((item, index) => (
                                <li key={index} className={item.className}>
                                    {item.to ?
                                        <VsfLink
                                            className="page-link" title="" to={item.to}>
                                            {item.icon &&
                                            <span className={item.icon}></span>
                                            }
                                            {item.text}
                                        </VsfLink>
                                        :
                                        <React.Fragment>
                                            <span className="page-link" tabIndex="-1">{item.text}</span>
                                        </React.Fragment>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
