import BaseValidation from "./BaseValidation";

export default class PhoneValidation extends BaseValidation {

    validate(name, inputValue, args) {

        let isCorrect = true;
        if (this.event==="change") {
            let rexSpainPhone = /^[0-9]{0,9}$/;
            if (!rexSpainPhone.test(inputValue)) {
                isCorrect = false;
            }
        }
        return isCorrect;
    }

}
