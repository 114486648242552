import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { DefaultRoute } from 'react-router-dom'
import withTranslate from "../../translator/withTranslate";
import moment from "moment";
import appState from '../../state/AppState';
import HourDrop from "./HourDrop";
import GraphException from "../../network/GraphException";
import GqlErrors from "../../components/status/GqlErrors";
import WorkUnit from "./WorkUnit";
import util from '../../util/Util';
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import SlotModel from "../../models/SlotModel";
import SlotDetailView from "../../components/SlotDetailView";
import DateInputField from "../../components/fields/DateInputField";
import PropsUtil from "../../util/PropsUtil";
import DateUtil from "../../util/DateUtil";
import Links from "../../util/Links";
import Select2Component from "../../components/fields/Select2Component";
import EmptyDrop from "./EmptyDrop";
import UserModel from "../../models/UserModel";
import SpinnerClipLoaderComponent from "../../network/SpinnerClipLoaderComponent";
import BaseListFilter from "../../subpages/BaseListFilter";
import SearchComponent from "../../components/SearchComponent";
import IconsSelectCapactitation from "../../components/fields/IconsSelectCapactitation";

@observer
class ScheduleByTechnical extends Component {

    @observable    gqlErrors = null;
    @observable slotsFromClientDict = [];
    @observable clients = [];
    @observable tecnicos = [];
    @observable currentUser = {};

    constructor(props) {
        super(props);
        let links = new Links(this.props.location);
        let currentFilterFromUrl = links.getCurrentFilterFromUrl();
        let currentFilter = {};
        let currentFilterOps = {};
        let currentFilterLabels = {};
        for (let [i, obj] of Object.entries(currentFilterFromUrl)) {
            currentFilter[obj.key] = obj.value;
            currentFilterOps[obj.key] = obj.op;
            currentFilterLabels[obj.key] = obj.label;
        }
        // Obtenemos del localStorage el parametro que controla si esta expandido el panel de cargas de trabajo por planificar
        let scheduleByTechnicalExpandedPanel = "0";
        if (window.localStorage.getItem("scheduleByTechnicalExpandedPanel") != null) {
            scheduleByTechnicalExpandedPanel = window.localStorage.getItem("scheduleByTechnicalExpandedPanel");
        }
        this.state = {
            ...this.state,
            show: true,
            loading: true,
            stateFilter: false,
            currentFilter: currentFilter,
            currentFilterOps: currentFilterOps,
            currentFilterLabels: currentFilterLabels,
            expandedTab: scheduleByTechnicalExpandedPanel
        };

        this.dateUtil = new DateUtil();
    }

    @computed get stateListFilter() {
        return this.getListState().listFilter;
    }

    getDateFrom() {
        return this.getDateFromIso().replace('-', '');
    }

    getDateFromIso() {
        let requestFecha = this.propsUtil.getRequest("date");
        let from;
        if (requestFecha == null) {
            from = util.getMoment().format("YYYY-MM-DD");
        } else {
            from = moment(requestFecha).format("YYYY-MM-DD");
        }
        return from;
    }

    getDateFromForGql() {
        let requestFechaIso = this.getDateFromIso();
        let from;
        if (requestFechaIso == null) {
            from = util.getMomentFromDateWithoutTimezone(util.getMomentFromDateWithoutTimezone(Date.now()).format("YYYY-MM-DD") + "T00:00:00").toISOString();
        } else {
            from = util.getMomentFromDateWithoutTimezone(requestFechaIso + "T00:00:00").toISOString();
        }
        return from;
    }

    getDateToForGql() {
        let requestFecha = this.getDateFromIso();
        let result;
        if (requestFecha == null) {
            result = util.getMomentFromDateWithoutTimezone(util.getMomentFromDateWithoutTimezone(Date.now()).format("YYYY-MM-DD") + "T23:59:59").toISOString();
        } else {
            let momentTo = util.getMomentFromDateWithoutTimezone(requestFecha).add('days', 31);
            let strFechaTo = momentTo.format("YYYY-MM-DD");
            result = util.getMomentFromDateWithoutTimezone(strFechaTo + "T23:59:59").toISOString();
        }
        return result;
    }

    getDateMonth() {
        let from = ("" + this.getDateFrom()).substring(0, 6);
        return from;
    }

    getDiasMes() {
        return this.dateUtil.getDiasMesFromDate(this.getDateFrom());
    }

    getWeeks() {
        return this.dateUtil.getWeeksFromDate(this.getDateFrom());
    }

    getListState() {
        return appState.orderState;
    }

    getListData() {
        return this.getListState().listData;
    }

    getModelQuery() {
        let result = new SlotModel();
        return result;

    }

    async componentDidMount() {
        this.log("componentDidMount()");
        this.setState({ loading: true });
        this.currentUser = {};
        let [user] = await Promise.all([
            appState.loginState.getUser(),
            appState.typifiedState.loadEmploiments(),
            appState.typifiedState.loadZones(),
            appState.clientCacheState.loadClientCache(),
            appState.typifiedState.loadWorkOrderType(),
            appState.typifiedState.loadStatus(),
            appState.typifiedState.loadSubTypeWorkOrder(),
            appState.typifiedState.loadCapacitation(),
            appState.assetState.getAssets(),
            appState.typifiedState.loadAssetType(),
            appState.typifiedState.loadAssetSubtype(),
        ]);
        appState.typifiedState.arrayLoadAssetTypeCodes();
        appState.typifiedState.arrayLoadWorkOrderType();
        appState.typifiedState.arrayLoadCapacitation();
        appState.typifiedState.arrayLoadWorkOrderStatus();
        appState.typifiedState.arrayLoadWorkOrderTypeCodes();
        appState.typifiedState.arrayLoadsubTypeWorkOrders();
        appState.typifiedState.arrayLoadZones();
        appState.typifiedState.arrayLoadAssetType();
        appState.typifiedState.arrayLoadAssetSubType();
        this.currentUser = user;
        try {
            await Promise.all([
                this.reloadCalendario(),
                this.loadUsers()
            ]);
            //await this.loadWorkOrdersOnlyWithMonth();
        } catch (e) {
            this.log("componentDidMount. Exception");
            let gqlErrors = new GraphException().getErrorsFromException(e);
            this.log({ gqlErrors });
            this.gqlErrors = gqlErrors;
        }
        this.setState({ loading: false });
    }

    async loadUsers() {
        await appState.userCacheState.loadUserCache();
        await this.getTechnicians();
    }

    getSlotQuery() {
        let slotQuery = new SlotModel();
        slotQuery.addRelatedTable("workOrder");
        slotQuery.addRelatedTable("workOrder.workLog");
        return slotQuery;
    }

    async loadSlotsWithinDates() {
        this.log("loadSlotsWithinDates");
        let dateFrom = this.getDateFromForGql();
        let dateTo = this.getDateToForGql();
        let dateSchedule = this.getDateMonth();
        let cliente = this.state.currentFilter.cliente;
        let optionTime = this.propsUtil.getRequest("optionTime") || "semana";
        // slotWithDatesQuery
        let slotWithDatesQuery = this.getSlotQuery();
        slotWithDatesQuery.filters = [
            { "fieldName": "scheduledTime", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "scheduledTime", "fieldValue": dateTo, "filterOperator": "LTEQ" },
        ];
        if (cliente != null) {
            slotWithDatesQuery.filters.push({
                "fieldName": "clientId",
                "fieldValue": cliente,
                "filterOperator": "IN"
            });
        }
        slotWithDatesQuery.orderBy = "scheduledTime";
        // slotsWithNullDatesQuery
        let slotsWithNullDatesQuery = this.getSlotQuery();
        slotsWithNullDatesQuery.filters = [
            { "fieldName": "scheduledTime", "fieldValue": "", "filterOperator": "EQ" },
            { "fieldName": "scheduledDate", "fieldValue": "", "filterOperator": "EQ" },
        ];
        if (cliente != null) {
            slotsWithNullDatesQuery.filters.push({
                "fieldName": "clientId",
                "fieldValue": cliente,
                "filterOperator": "IN"
            });
        }
        // slotsWithScheduledDateQuery
        // Añadir estos elementos y en el anterior que sea vacio.
        let slotsWithScheduledDateQuery = this.getSlotQuery();
        slotsWithScheduledDateQuery.filters = [
            { "fieldName": "scheduledDate", "fieldValue": dateSchedule, "filterOperator": "EQ" },
            { "fieldName": "scheduledTime", "fieldValue": "", "filterOperator": "EQ" },
        ];
        if (cliente != null) {
            slotsWithScheduledDateQuery.filters.push({
                "fieldName": "clientId",
                "fieldValue": cliente,
                "filterOperator": "IN"
            });
        }

        let [slotsWithDates,
            slotsWithNullDates,
            slotsWithScheduledDate] = await Promise.all([
            slotWithDatesQuery.find(),
            slotsWithNullDatesQuery.find(),
            slotsWithScheduledDateQuery.find(),
        ]);

        let slots = [...slotsWithDates, ...slotsWithNullDates, ...slotsWithScheduledDate];
        //Todos los slots sin fecha de planificación que cumplan que su order.ScheduledDate esté en un mes que estoy visualizando
        let slotsPlain = util.arrayModelToPlainObjects(slots);
        appState.scheduleDropState.allSlotsDict = util.getDictSingleFromArray(slotsPlain);
        appState.scheduleDropState.addEmptyCellToWorkOrders();
        appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
        appState.scheduleDropState.createTecnicosSinAsignar();
    }

    /**
     * Devuelve un array con los ids de las WorkOrders
     * @param hour
     * @param technical
     * @param isoDate
     * @param emptyCell
     */
    getWorkUnitsIdFrom({ hour, technical, isoDate, emptyCell }) {
        let cache = appState.scheduleDropState.allSlotsGrouped;
        let techid = technical && technical.id;
        let lineaSinAsignar = emptyCell && emptyCell.id;
        let key = util.getString(hour) + ";" + util.getString(techid) + ";" + util.getString(lineaSinAsignar) + ";" + isoDate;
        let result = cache[key];
        if (result == null) {
            result = [];
        }
        return result;
    }

    async getTechnicians() {
        let tecnicos = [];
        let zona = this.state.currentFilter.posZoneId;
        let users;
        this.log("getTechnicians()");
        let usersQuery = new UserModel();

        if (zona != null) {
            usersQuery.filters = [];
            usersQuery.filters.push({ "fieldName": "zoneAsignedId", "fieldValue": zona, "filterOperator": "EQ" });
            users = await usersQuery.find();
        } else {
            users = appState.userCacheState.users;
        }
        for (let user of users) {
            if (util.perteneceA(255, user.role)) {
                tecnicos.push({
                    id: user.id,
                    name: `${user.firstName || ""} ${user.lastName || ""}`,
                    capacitationIds: user.capacitationIds || ""
                });
            }
        }
        this.tecnicos = tecnicos;
    }

    getArray(inicio, numElementos) {
        let result = [];
        for (let i = inicio; i <= numElementos; i++) {
            result.push(i);
        }
        return result;
    }

    async changeUrlTimeOption(e) {
        this.setState({ loading: true });
        if (!appState.scheduleDropState.flagSlots) {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(e.target.value);
        } else {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
        }
        await this.propsUtil.changeUrlRequest({ optionTime: e.target.value });
        let url = this.props.location.pathname + this.props.location.search;
        if (!appState.layoutState.arrayWithLocations.includes(url)) {
            appState.layoutState.arrayWithLocations.pop();
            appState.layoutState.arrayWithLocations.push(url);
        }
        this.setState({ loading: false });
    }

    async changeDate(e) {
        this.setState({ loading: true });
        await this.propsUtil.changeUrlRequest({ date: moment(e.target.value).format("YYYYMMDD") });
        let url = this.props.location.pathname + this.props.location.search;
        if (!appState.layoutState.arrayWithLocations.includes(url)) {
            appState.layoutState.arrayWithLocations.push(url);
        }
        await this.reloadCalendario();
        this.setState({ loading: false });
    }

    async reloadCalendario() {
        await this.loadSlotsWithinDates();
        let zona = this.state.currentFilter.posZoneId;
        let cliente = this.state.currentFilter.cliente;
        let loadClients = window.localStorage.getItem('loadClients');
        if (loadClients === "Pendiente") {
            await appState.scheduleDropState.loadPendingClients(zona, cliente);
        } else {
            await appState.scheduleDropState.loadClients(zona, cliente);
        }
    }

    async cambiarFlag() {
        this.log("cambiarFlag");
        let optionTime = this.propsUtil.getRequest("optionTime") || 'semana';
        appState.scheduleDropState.flagSlots = !appState.scheduleDropState.flagSlots;
        if (!appState.scheduleDropState.flagSlots) {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
        } else {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
        }

    }

    async reloadClients(loadClients) {
        this.setState({ loading: true });
        let zona = this.state.currentFilter.posZoneId;
        let cliente = this.state.currentFilter.cliente;
        if (loadClients === "Pendiente") {
            await appState.scheduleDropState.loadPendingClients(zona, cliente);
        } else {
            await appState.scheduleDropState.loadClients(zona, cliente);
        }
        this.setState({ loading: false });
    }

    onEditarCliente(id) {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalclient",
            "idOrderClient": id,
            "rightModalTab": "general"
        });
    }

    onNewOrder() {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalorderls",
            "rightModalTab": "order",
            orderInitial: null
        });
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    /**
     * Devuelve los estilos dependiendo si el panel de cargas de trabajo por planificar esta expandido o no
     *
     * @returns {*}
     */
    getStylePropertiesForExpandedPanel() {

        // Si esta no expandida, devolvemos las propiedades para disminuir el tamaño de la tab
        if (this.state.expandedTab === "0") {
            return { height: "190px", overflow: "auto" };
        } else {
            return {};
        }
    }

    async goToDay(dia) {
        this.setState({ loading: true });
        let date = dia.replace('-', '');

        await this.propsUtil.changeUrlRequest({ date: date.replace("-", ""), optionTime: "twodias" });
        let url = this.props.location.pathname + this.props.location.search;
        if (!appState.layoutState.arrayWithLocations.includes(url)) {
            appState.layoutState.arrayWithLocations.pop();
            appState.layoutState.arrayWithLocations.push(url);
        }
        await this.reloadCalendario();
        this.setState({ loading: false });
    }

    /**
     * A partir del parametro recibido, activa o desactiva el panel de cargas de trabajo por planificar
     *
     * @param expanded
     */
    toggleExpandedPanel(expanded) {
        // Guardamos la variable en el estado
        this.setState({
            expandedTab: expanded
        });
        // Guardamos la variable en el localStorage
        window.localStorage.setItem("scheduleByTechnicalExpandedPanel", expanded);
    }

    addOrSubtractOneDay(isoDate, option) {
        let objWithDateRange = this.getHoursMinAndMaxFromAllSlotsGrouped();
        if (appState.scheduleDropState.dateRangeForced[isoDate] == null) {
            appState.scheduleDropState.dateRangeForced[isoDate] = { min: 8, max: 20 };
            appState.scheduleDropState.dateRangeForced[isoDate]["min"] = objWithDateRange[isoDate]["min"];
            appState.scheduleDropState.dateRangeForced[isoDate]["max"] = objWithDateRange[isoDate]["max"];
        }
        if (option === "izquierda") {
            if (--appState.scheduleDropState.dateRangeForced[isoDate]["min"] < 0) {
                appState.scheduleDropState.dateRangeForced[isoDate]["min"] = 8;
                objWithDateRange = this.getHoursMinAndMaxFromAllSlotsGrouped();
                appState.scheduleDropState.dateRangeForced[isoDate]["min"] = objWithDateRange[isoDate]["min"];
                appState.scheduleDropState.dateRangeForced[isoDate]["min"] = objWithDateRange[isoDate]["min"];
            }
        } else if (option === "derecha") {
            if (++appState.scheduleDropState.dateRangeForced[isoDate]["max"] > 23) {
                appState.scheduleDropState.dateRangeForced[isoDate]["max"] = 20;
                objWithDateRange = this.getHoursMinAndMaxFromAllSlotsGrouped();
                appState.scheduleDropState.dateRangeForced[isoDate]["max"] = objWithDateRange[isoDate]["max"];
            }
        }
    }

    getHoursMinAndMaxFromAllSlotsGrouped(optionTime) {
        let objWithDateRange = {};
        let tamaño = 7;
        if (optionTime === "twodias") {
            tamaño = 2;
        }
        if (optionTime === "mes") {
            tamaño = 31;
        }
        let momentDate = util.getMomentFromDateWithoutTimezone(this.getDateFromIso());
        for (let i = 0; i < tamaño; i++) {
            let dateiso = momentDate.format("YYYY-MM-DD");
            objWithDateRange[dateiso] = { min: 8, max: 20 };
            momentDate = momentDate.add(1, "days");
        }
        for (let key of Object.keys(appState.scheduleDropState.allSlotsDict)) {
            /** @type SlotModel */
            let slot = appState.scheduleDropState.allSlotsDict[key];
            if (slot.scheduledTime != null) {
                let momentDate = util.getMoment(slot.scheduledTime);
                let hora = momentDate.hour();
                let isoDate = momentDate.format("YYYY-MM-DD");
                if (objWithDateRange[isoDate] == null) {
                    objWithDateRange[isoDate] = { min: 8, max: 20 };
                }
                objWithDateRange[isoDate]["min"] = Math.min(objWithDateRange[isoDate]["min"], hora);
                objWithDateRange[isoDate]["max"] = Math.max(objWithDateRange[isoDate]["max"], hora);

                if (((hora + slot.scheduledDuration) - 1) > objWithDateRange[isoDate]["max"]) {
                    objWithDateRange[isoDate]["max"] = Math.max(objWithDateRange[isoDate]["max"], (hora + slot.scheduledDuration) - 1);
                    if (objWithDateRange[isoDate]["max"] > 23) {
                        objWithDateRange[isoDate]["max"] = 23;
                    }
                }
                if (((hora + slot.scheduledDuration) - 1) > 23) {
                    momentDate = momentDate.add(1, "days");
                    isoDate = momentDate.format("YYYY-MM-DD");
                    if (objWithDateRange[isoDate] == null) {
                        objWithDateRange[isoDate] = { min: 8, max: 20 };
                    }
                    objWithDateRange[isoDate]["min"] = 0;
                }
                if (objWithDateRange[isoDate]["max"] > 23) {
                    objWithDateRange[isoDate]["max"] = 23;
                }
            }
        }
        for (let isoDate of Object.keys(appState.scheduleDropState.dateRangeForced)) {
            objWithDateRange[isoDate]["min"] = Math.min(objWithDateRange[isoDate]["min"], appState.scheduleDropState.dateRangeForced[isoDate]["min"]);
            objWithDateRange[isoDate]["max"] = Math.max(objWithDateRange[isoDate]["max"], appState.scheduleDropState.dateRangeForced[isoDate]["max"]);
        }
        this.log({ objWithDateRange });
        return objWithDateRange;
    }

    getPartitionsFromOptionTime(optionTime) {
        let partitions = {};
        this.log({ dateFormIso: this.getDateFromIso() });
        let momentDate = util.getMomentFromDateWithoutTimezone(this.getDateFromIso());
        let objWithDateRange = this.getHoursMinAndMaxFromAllSlotsGrouped();
        let tamaño = 7;
        if (optionTime === "twodias") {
            tamaño = 2;
        }
        if (optionTime === "mes") {
            tamaño = 31;
        }
        for (let i = 0; i < tamaño; i++) {
            let dateiso = momentDate.format("YYYY-MM-DD");
            momentDate = momentDate.add(1, "days");
            if (optionTime === "mes") {
                partitions[dateiso] = [8, 14];
            } else {
                partitions[dateiso] = this.getArray(objWithDateRange[dateiso].min, objWithDateRange[dateiso].max);
            }
        }
        return partitions;
    }

    render() {
        this.log("render()");
        const { t, i18n } = this.props;
        let links = new Links(this.props.location);
        const currentFilter = links.getCurrentFilterFromUrl();
        this.propsUtil = new PropsUtil(this.props);
        let optionTime = this.propsUtil.getRequest("optionTime") || 'semana';
        let objWithDateRange = {};
        let diasMes = this.getDiasMes();
        let fields = this.state.listFields;
        let weeks = this.getWeeks();
        let dias;
        let pixelsPerHour = 1;
        let pixelsPerDropItem = 1;
        let pixelsPerDropItemMenu = 1;
        let numHoursSlot = 1;
        let particionesDia = this.getPartitionsFromOptionTime(optionTime);
        this.log({ particionesDia });
        if (optionTime === "mes") {
            objWithDateRange = {}; //this.getHoursMinAndMaxForMes();
            dias = diasMes;
            pixelsPerHour = 18;
            pixelsPerDropItem = pixelsPerHour * 2;
            pixelsPerDropItemMenu = pixelsPerDropItem;
            numHoursSlot = 1;
        } else if (optionTime === "semana") {
            objWithDateRange = this.getHoursMinAndMaxFromAllSlotsGrouped(optionTime);
            dias = [];
            for (let i = 0; i < 7; i++) {
                dias.push(diasMes[i]);
            }
            weeks = null;
            pixelsPerHour = 15;
            pixelsPerDropItem = pixelsPerHour - 1;
            pixelsPerDropItemMenu = pixelsPerHour - 1;
            numHoursSlot = 1;
        } else if (optionTime === "twodias") {
            objWithDateRange = this.getHoursMinAndMaxFromAllSlotsGrouped(optionTime);
            dias = [diasMes[0], diasMes[1]];
            weeks = null;
            pixelsPerHour = 12 * 3;
            pixelsPerDropItem = pixelsPerHour - 1;
            pixelsPerDropItemMenu = pixelsPerHour;
            numHoursSlot = 1;
        }

        let sinTecnicos = appState.scheduleDropState.rowsWithoutTechnicals;
        return (
            <InfoBlock componentObject={this}>
                {this.state.loading &&
                <SpinnerClipLoaderComponent />
                }
                <GqlErrors errors={this.gqlErrors} />
                <SlotDetailView
                    key={appState.scheduleDropState.slotClicked && appState.scheduleDropState.slotClicked.id}
                    slot={appState.scheduleDropState.slotClicked} />
                <div className="title-section">
                    <div className="row">
                        <div className="col-8 text-left d-md-flex align-items-md-center">
                            <h1 className="title title--inline">{t('Planificación')}</h1>
                            <h2 className="subtitle">
                                {t('Calendario') + ' - ' + t('Time zone in') + " " + this.currentUser ? this.currentUser.timeZone : ""}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <Select2Component
                                        value={optionTime || 'semana'}
                                        name={"schedudeByTime"}
                                        clearable={false}
                                        classGroup={"col-md-5 col-lg-5 col-xl-2 pr-xl-0"}
                                        options={[
                                            { label: t("Mes"), value: "mes", },
                                            { label: t("Semana"), value: "semana", },
                                            { label: t("2 dias"), value: "twodias", },
                                        ]}
                                        onChange={e => this.changeUrlTimeOption(e)}
                                    />
                                    <div className="col-md-5 col-lg-5 col-xl-2 schedule-calendar m-0  no-label pr-xl-0">
                                        <DateInputField
                                            prefix={"fas fa-calendar-alt"}
                                            value={this.getDateFromIso()}
                                            classGroup="input-group"
                                            fromCalendar={true}
                                            onChange={(e) => this.changeDate(e)}
                                            name={"scheduleDate"}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className="col-xl-2 text-md-left mb-3 ml-3 mb-xl-0 pl-xl-0 pt-xl-1">
                                        <button className="btn btn--red"
                                                onClick={() => {
                                                    this.onNewOrder()
                                                }}
                                        >{t("Crear nuevo pedido")}
                                        </button>
                                    </div>
                                    {optionTime != "mes" &&
                                    <div className="col-xl-2 text-md-left mb-3 ml-3 mb-xl-0 pl-xl-0 pt-xl-1">
                                        <span className="link-underline "
                                              onClick={() => this.cambiarFlag()}
                                        >{appState.scheduleDropState.flagSlots ? t("Calendario Real") : t("Juntar slots")}</span>
                                    </div>
                                    }

                                </div>
                                <SearchComponent deleteFilter={(e) => this.deleteFilter(e)}
                                                 listState={this.getListState()}
                                                 listData={this.getListData()}
                                                 fields={fields}
                                                 withoutFlex={true} withoutResults={false}
                                                 onClickAplicarFiltros={() => this.onClickAplicarFiltros()}
                                                 model={this.getModelQuery()}
                                                 includeFilters={true}
                                                 stateListColumnsSelect={this.stateListColumnsSelect}
                                                 {...this.props}
                                                 updateFilters={(e) => this.updateInputFilterEvent(e)}
                                                 listComponent={this}
                                />
                            </div>
                        </div>
                        <BaseListFilter title={'Calendario'} listComponent={this} stateFilter={this.stateListFilter}>
                            {this.renderFilters()}
                        </BaseListFilter>

                        <div className="table-second second mb-3">
                            {/*<div className="table-responsive">*/}
                            <div className="table-responsive">
                                <table className={"table drop-work-unit mb-4 " + optionTime}>
                                    <caption>
                                        {t('Planificación Cargas de Trabajo')}
                                        <div className="c-tooltip">
                                            <button type="button" id="states" className="c-tooltip__ico"><span
                                                class="fas fa-question-circle" /></button>
                                            <div className="c-tooltip__content" aria-labelledby="states">
                                                {/*preventiva*/}
                                                <div className="c-tooltip__content-item">
                                                    <p id="state-prevent"
                                                       className="c-tooltip__content-item-text">preventiva</p>
                                                    <div className="c-tooltip__content-item-state"
                                                         aria-labelledby="state-prevent">
                                                        <div
                                                            className="c-tooltip__content-item-color color-prevent user-state-check" />
                                                        <div
                                                            className="c-tooltip__content-item-color color-prevent--lines user-state" />
                                                    </div>
                                                </div>
                                                {/*programada*/}
                                                <div className="c-tooltip__content-item">
                                                    <p id="state-program"
                                                       className="c-tooltip__content-item-text">programada</p>
                                                    <div className="c-tooltip__content-item-state"
                                                         aria-labelledby="state-program">
                                                        <div
                                                            className="c-tooltip__content-item-color color-program user-state-check" />
                                                        <div
                                                            className="c-tooltip__content-item-color color-program--lines user-state" />
                                                    </div>
                                                </div>
                                                {/*correctiva*/}
                                                <div className="c-tooltip__content-item">
                                                    <p id="state-correct"
                                                       className="c-tooltip__content-item-text">correctiva</p>
                                                    <div className="c-tooltip__content-item-state"
                                                         aria-labelledby="state-correct">
                                                        <div
                                                            className="c-tooltip__content-item-color color-corrective user-state-check" />
                                                        <div
                                                            className="c-tooltip__content-item-color color-corrective--lines user-state" />
                                                    </div>
                                                </div>
                                                {/*eventos*/}
                                                <div className="c-tooltip__content-item">
                                                    <p id="state-events"
                                                       className="c-tooltip__content-item-text">eventos</p>
                                                    <div className="c-tooltip__content-item-state"
                                                         aria-labelledby="state-events">
                                                        <div
                                                            className="c-tooltip__content-item-color color-events" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </caption>
                                    <thead>
                                    {weeks &&
                                    <tr>
                                        <td className={"week"}>

                                        </td>
                                        {weeks.map(week => (
                                            <td key={"week" + "-" + week.firstDay}
                                                className={"week with-" + week.colspan + "-days"}
                                                colSpan={week.colspan}>
                                                <div class="nameDay">
                                                    {week.firstDay} {week.monthName.substr(0, 3)}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                    }
                                    <tr>
                                        <th className="th-bottom__item p-0">
                                            <ul className="th-bottom text th-tecnico">
                                                <li className="th-bottom__item">
                                                    {t("Tecnicos")}</li>
                                            </ul>
                                        </th>
                                        {dias.map(dia => (
                                            <td scope="col" key={"head" + "-" + dia.isoDate}
                                                className={"day p-0 weekDay weekDay-" + dia.weekDay}>
                                                {/*<b>{dia.isoDate}</b>*/}
                                                {optionTime === "mes" ?
                                                    <ul className="th-bottom num "
                                                        style={{ "width": pixelsPerDropItemMenu }}>
                                                        <li className="th-bottom__item mr-1">
                                                        <span className="text-click"
                                                              onClick={() => this.goToDay(dia.isoDate)}>
                                                        {dia.dayOfMonth}<span
                                                            className="day"> {dia.labelWeekDay}
                                                        </span>
                                                    </span>
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul className="th-bottom num">
                                                        <div className="text-click font-weight-bold ml-2"
                                                             onClick={() => this.addOrSubtractOneDay(dia.isoDate, "izquierda")}>
                                                            <span
                                                                className={objWithDateRange[dia.isoDate].min == 0 ? "fas fa-angle-double-right" : "fas fa-angle-left"} />
                                                        </div>

                                                        <div className="text-click"
                                                             onClick={() => this.goToDay(dia.isoDate)}>
                                                            {dia.dayOfMonth}<span
                                                            className="day"> {dia.labelWeekDay}
                                                        </span>
                                                        </div>
                                                        <div className="text-click font-weight-bold mr-2 "
                                                             onClick={() => this.addOrSubtractOneDay(dia.isoDate, "derecha")}>
                                                            <span
                                                                className={objWithDateRange[dia.isoDate].max == 23 ? "fas fa-angle-double-left" : "fas fa-angle-right"}> </span>
                                                        </div>
                                                    </ul>
                                                }
                                                {optionTime === "twodias" &&
                                                <table className="label-days">
                                                    <tr>
                                                        {this.getArray(objWithDateRange[dia.isoDate].min, objWithDateRange[dia.isoDate].max).map((hour, i) => (
                                                            <td style={{ 'border-bottom': 0 }}>
                                                                <div style={{ "width": pixelsPerDropItem }}>
                                                                    {hour}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </table>
                                                }

                                                {optionTime === "semana" &&
                                                <table className="label-days">
                                                    <tr>
                                                        {particionesDia[dia.isoDate].map((hour, i) => (
                                                            <td style={{ 'border-bottom': 0 }}>
                                                                <div style={{ "width": pixelsPerDropItem }}>
                                                                    {hour}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </table>
                                                }
                                            </td>
                                        ))}
                                    </tr>


                                    </thead>
                                    <tbody>
                                    {this.tecnicos.map(tecnico => (
                                        <>
                                            <tr key={tecnico.id}>
                                                <th scope="row" className={"th-name-tecnico"}>
                                                    <div className="th-bottom text">
                                                            <IconsSelectCapactitation color={"#3D708D"}
                                                                                      iconClass={"col-3"}
                                                                                      postfix={tecnico.capacitationIds}>{tecnico.name}</IconsSelectCapactitation>

                                                    </div>
                                                </th>
                                                {dias.map(dia => (
                                                    <td key={tecnico.name + "-" + dia.isoDate}
                                                        className={"weekDay weekDay-" + dia.weekDay}>

                                                        <table className={""}>
                                                            <tbody>
                                                            <tr>
                                                                {particionesDia[dia.isoDate].map((hour, i) => (
                                                                    <HourDrop objWithDateRange={objWithDateRange}
                                                                              key={i} index={i}
                                                                              hour={hour}
                                                                              technical={tecnico}
                                                                              isoDate={dia.isoDate}
                                                                              flagSlots={appState.scheduleDropState.flagSlots}
                                                                              setErrors={(e) => this.setErrors(e)}
                                                                              finSemana={dia.weekDay === 6 || dia.weekDay === 7}
                                                                              optionTime={optionTime}
                                                                              pixelsPerHour={pixelsPerHour}
                                                                              numHoursSlot={numHoursSlot}>
                                                                        {this.getWorkUnitsIdFrom({
                                                                            hour,
                                                                            technical: tecnico,
                                                                            isoDate: dia.isoDate,
                                                                            optionTime,
                                                                            emptyCell: null
                                                                        }).map(slotId => (
                                                                            <WorkUnit pixelsPerHour={pixelsPerHour}
                                                                                      key={slotId}
                                                                                      optionTime={optionTime}
                                                                                      particionesDia={particionesDia[dia.isoDate]}
                                                                                      slotId={slotId}
                                                                                      hour={hour}
                                                                                      isoDate={dia.isoDate}
                                                                            />

                                                                        ))
                                                                        }
                                                                    </HourDrop>
                                                                ))}
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                ))}
                                            </tr>
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                                {/*<div className="table-responsive">*/}
                                <table className={"table drop-work-unit mb-3 " + optionTime}>
                                    <caption>
                                            <span className="text-click "
                                                  onClick={() => this.setState({
                                                      show: !this.state.show
                                                  })}>{t('Planificación sin Técnico')}
                                                <i className={this.state.show ? "fas fa-angle-left" : "fas fa-angle-down"} />
                                    </span>
                                    </caption>

                                    <thead>
                                    <tr>
                                        <th className="th-bottom__item p-0">
                                            <ul className="th-bottom text th-tecnico">
                                                <li className="th-bottom__item">
                                                    {t("Sin Tecnicos")}</li>
                                            </ul>
                                        </th>

                                        {dias.map(dia => (

                                            <td scope="col"
                                                key={"head" + "-" + dia.isoDate}
                                                className={"day p-0 weekDay weekDay-" + dia.weekDay}>
                                                {/*<b>{dia.isoDate}</b>*/}

                                                {optionTime === "mes" ?
                                                    <ul className="th-bottom num "
                                                        style={{ "width": pixelsPerDropItemMenu }}>
                                                        <li className="th-bottom__item mr-1">
                                                        <span className="text-click"
                                                              onClick={() => this.goToDay(dia.isoDate)}>
                                                        {dia.dayOfMonth}<span
                                                            className="day"> {dia.labelWeekDay}
                                                        </span>
                                                    </span>
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul className="th-bottom num">
                                                        <span className="text-click font-weight-bold ml-2"
                                                              onClick={() => this.addOrSubtractOneDay(dia.isoDate, "izquierda")}>
                                                            <span className="fas fa-minus"> </span>

                                                    </span>
                                                        <div>
                                                            <li className="th-bottom__item mr-1 ">
                                                            <span className="text-click"
                                                                  onClick={() => this.goToDay(dia.isoDate)}>
                                                        {dia.dayOfMonth}<span
                                                                className="day"> {dia.labelWeekDay}
                                                        </span>
                                                    </span>
                                                            </li>
                                                        </div>
                                                        <span className="text-click font-weight-bold mr-2"
                                                              onClick={() => this.addOrSubtractOneDay(dia.isoDate, "derecha")}>
                                                            <span className="fas fa-plus"> </span>
                                                    </span>
                                                    </ul>
                                                }
                                                {optionTime == "twodias" &&
                                                <table class="label-days">
                                                    <tr>
                                                        {particionesDia[dia.isoDate].map((hour, i) => (
                                                            <td style={{ 'border-bottom': 0 }}>
                                                                <div style={{ "width": pixelsPerDropItem }}>
                                                                    {hour}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </table>
                                                }

                                                {optionTime === "semana" &&
                                                <table class="label-days">
                                                    <tr>
                                                        {particionesDia[dia.isoDate].map((hour, i) => (
                                                            <td style={{ 'border-bottom': 0 }}>
                                                                <div style={{ "width": pixelsPerDropItem }}>
                                                                    {hour}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </table>
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    </thead>

                                    {this.state.show &&
                                    <tbody>
                                    {sinTecnicos.map(sinTecnico => (
                                        <tr key={t("sinTecnico") + sinTecnico.id}>
                                            <th scope="row" className={"th-name-tecnico "}>
                                                <div className="th-bottom text ">
                                                    <p className="th-bottom__item mb-0">{sinTecnico.name}</p>
                                                </div>
                                            </th>
                                            {dias.map(dia => (
                                                <td key={"sintecnico" + sinTecnico.id + "-" + dia.dayOfMonth}
                                                    className={"weekDay weekDay-" + dia.weekDay}>
                                                    <table className={""}>

                                                        <tbody>
                                                        <tr>
                                                            {particionesDia[dia.isoDate].map((hour, i) => (
                                                                <HourDrop objWithDateRange={objWithDateRange} key={i}
                                                                          hour={hour} technical={null}
                                                                          emptyCell={sinTecnico}
                                                                          optionTime={optionTime}
                                                                          finSemana={dia.weekDay === 6 || dia.weekDay === 7}
                                                                          setErrors={(e) => this.setErrors(e)}
                                                                          flagSlots={appState.scheduleDropState.flagSlots}
                                                                          isoDate={dia.isoDate}
                                                                          particionesDia={particionesDia[dia.isoDate]}
                                                                          pixelsPerHour={pixelsPerHour}
                                                                          numHoursSlot={numHoursSlot}>
                                                                    {this.getWorkUnitsIdFrom({
                                                                        hour,
                                                                        technical: null,
                                                                        isoDate: dia.isoDate,
                                                                        emptyCell: sinTecnico
                                                                    }).map(slotId => (
                                                                        <WorkUnit slotId={slotId}
                                                                                  particionesDia={particionesDia[dia.isoDate]}
                                                                                  isoDate={dia.isoDate}
                                                                                  key={slotId}
                                                                                  optionTime={optionTime}
                                                                                  hour={hour}
                                                                                  pixelsPerHour={pixelsPerHour} />

                                                                    ))}
                                                                </HourDrop>
                                                            ))}
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                    }
                                </table>

                            </div>
                        </div>
                        <EmptyDrop
                            optionTime={optionTime}
                        >
                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="block-table">
                                        <div className="caption">
                                            {t('Cargas de trabajo por')}
                                            <span className="font-weight-bold"> {t('Planificar')}</span>
                                            {this.state.expandedTab === "1"
                                                ?
                                                <span onClick={() => this.toggleExpandedPanel("0")}
                                                      className="expandButton"><i
                                                    class="far fa-caret-square-up" /></span>
                                                :
                                                <span onClick={() => this.toggleExpandedPanel("1")}
                                                      className="expandButton"><i class="far fa-caret-square-down"></i></span>
                                            }
                                            <span className="text-click font-weight-bold right"
                                                  onClick={() => this.reloadClients("Todas")}>
                                                {t('Todas')}
                                            </span>
                                            <span className="text-click font-weight-bold right">/</span>
                                            <span className="text-click font-weight-bold right"
                                                  onClick={() => this.reloadClients("Pendiente")}>
                                                {t('Pendientes')}
                                            </span>
                                        </div>
                                        <div className="container-fluid block-table__body"
                                             style={this.getStylePropertiesForExpandedPanel()}>
                                            <div className="row">
                                                {appState.scheduleDropState.clients.map((client) => (
                                                    <div className="col-lg-4 mt-2" key={client.id}>
                                                        <div className="visits">
                                                            <span className="visits__text text-click"
                                                                  onClick={() => {
                                                                      this.onEditarCliente(client.id);
                                                                  }}
                                                            >{client.name}</span>
                                                            <div className="right-drag-hours">
                                                                {appState.scheduleDropState.slotsFromClientDict[client.id] && appState.scheduleDropState.slotsFromClientDict[client.id].map((slot) => (
                                                                    <WorkUnit inClientBlock={true}
                                                                              key={slot.id}
                                                                              slotId={slot.id} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </EmptyDrop>
                    </div>
                </div>
            </InfoBlock>
        )
    }


    /** Aplica los filtros en la pantalla de filtros **/
    async onClickAplicarFiltros() {
        let filtersArray = this.getFiltersFromStateForUrl(this.state.currentFilter);
        let links = new Links(this.props.location);
        let newUrl = links.getUrlLinkWithFilters(filtersArray);
        appState.workOrderState.showFilters = false;
        this.props.history.push(newUrl);
        this.setState({ stateFilter: false });
        this.setState({ loading: true });
        await this.reloadCalendario();
        await this.getTechnicians();
        this.setState({ loading: false });

    }

    getFiltersFromStateForUrl(currentFilterState) {
        let filtersArray = [];
        for ([key, value] of Object.entries(currentFilterState)) {
            let fieldName = util.getDelim(key, "_", 0);
            let op = this.state.currentFilterOps[key];
            let label = this.state.currentFilterLabels[key];
            if (op == null) {
                op = "EQ";
            }
            if (label == null) {
                label = value;
            }
            if (value != null && value !== "") {
                filtersArray.push({
                    "fieldKey": key,
                    "fieldName": fieldName,
                    fieldValue: value,
                    filterOperator: op,
                    fieldLabel: label
                })

            }
        }
        return filtersArray;
    }

    updateInputFilterEvent(event, op) {
        let name = event.target.name;
        let value = event.target.value;
        let label = event.target.label;
        if (op == null) {
            op = "EQ";
        }
        let currentFilter = { ...this.state.currentFilter, [name]: value };
        let currentFilterOps = { ...this.state.currentFilterOps, [name]: op };
        let currentFilterLabels = { ...this.state.currentFilterLabels, [name]: label };
        this.setState({ currentFilter, currentFilterOps, currentFilterLabels });
    }

    renderFilters() {
        let { t } = this.props;
        return (
            <>
                <Select2Component
                    value={this.state.currentFilter.posZoneId}
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    name={"posZoneId"}
                    title={t("Zona")}
                    optionsWrappedWithCommas={false}
                    operatorFilter={"STRIN"}
                    classGroup={"col-5 mr-1"}
                    options={appState.typifiedState.zonesForSelect}
                />
                <Select2Component
                    value={this.state.currentFilter.cliente}
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    name="cliente"
                    title={t("Establecimientos")}
                    operatorFilter={"IN"}
                    classGroup={"col-5"}
                    options={appState.clientCacheState.clientCacheForOrderSelect}
                    //errors={errorsMapped.clientName}
                />
            </>
        )
    }


    async deleteFilter(filter, value = null, label = null) {
        let newCurrentFilter;
        let newCurrentLabels;
        let currentFilterState = this.state;
        if (filter.op == "IN" || filter.op == "STRIN") {
            newCurrentFilter = currentFilterState.currentFilter;
            newCurrentLabels = currentFilterState.currentFilterLabels;
            // Eliminar values
            let values = newCurrentFilter[filter.key];
            let newValues = "";
            if (values != null) {
                newValues = values.replace("" + value + ",", "");
            }
            if (values == newValues) {
                newValues = values.replace("," + value + "", "");
            }
            if (values == newValues) {
                newValues = values.replace("" + value + "", "");
            }
            newCurrentFilter[filter.key] = newValues;
            // Eliminar labels
            let labels = newCurrentLabels[filter.key];
            if (labels != null) {
                let newLabels = labels.replace("/'" + label + "/',", "");
                if (labels == newLabels) {
                    newLabels = labels.replace(",/'" + label + "/'", "");
                }
                if (labels == newLabels) {
                    newLabels = labels.replace("/'" + label + "/'", "");
                }
                newCurrentLabels[filter.key] = newLabels;
                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                    delete newCurrentLabels[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter,
                        currentFilterLabels: newCurrentLabels
                    });
                }
            } else {
                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter
                    });
                }
            }
        } else {
            newCurrentFilter = currentFilterState.currentFilter;
            delete newCurrentFilter[filter.key];
            newCurrentLabels = currentFilterState.currentFilterLabels;
            delete newCurrentLabels[filter.key];
        }
        let filtersArray = this.getFiltersFromStateForUrl(newCurrentFilter);
        let links = new Links(this.props.location);
        let newUrl = links;
        if (filtersArray.length != 0) {
            newUrl = links.getUrlLinkWithFilters(filtersArray);
        }
        this.setState({ loading: true });
        this.props.history.push(newUrl)
        await this.reloadCalendario();
        await this.getTechnicians();
        this.setState({ loading: false });
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withTranslate(ScheduleByTechnical);
