import { observable, action, computed, autorun } from 'mobx';
import PropsUtil from "../util/PropsUtil";

import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost";
import appState from "./AppState";

class LayoutState {
    //bloque status
    @observable userDropMenuOpen = false;
    /**
     * Indica si se ve la barra de la derecha o no
     * @type {boolean}
     */
    @observable zIndex = 99;
    @observable sidebarMinimized = (window.localStorage.getItem("sidebarMinimized") == "1");
    @observable masterDataOpen = (window.localStorage.getItem("masterDataOpen") == "1");
    @observable sidebarMinimized2 = (window.localStorage.getItem("sidebarMinimized2") == "1");
    @observable sidebarMinimized3 = (window.localStorage.getItem("sidebarMinimized3") == "1");
    @observable sidebarMinimized4 = (window.localStorage.getItem("sidebarMinimized4") == "1");
    @observable sidebarMinimized5 = (window.localStorage.getItem("sidebarMinimized5") == "1");
    @observable articlesOpen = (window.localStorage.getItem("articlesOpen") == "1");
    @observable clientsOpen = (window.localStorage.getItem("clientsOpen") == "1");
    @observable scheduleOpen = (window.localStorage.getItem("scheduleOpen") == "1");
    @observable scheduleVisitOpen = (window.localStorage.getItem("scheduleVisitOpen") == "1");
    @observable backgroundScheduled = 0;
    @observable backgroundRowScheduled = 0;
    @observable scheduledDateBlocked = "";
    @observable scheduledUserBlocked = "";
    @observable scheduledIsFinished = false;

    @observable user = false;
    @observable config = false;
    @observable menu = false;
    @observable downloadExcelCsv = false;
    @observable slotview = false;
    @observable arrayWithLocations = [];

    @observable menuCreateNewActive = false;
    @observable formWithoutChanges = true;
    @observable modalChangeOpen = false;
    @observable loadingForm = false;
    @observable userDropMenuOpen = (window.localStorage.getItem("userDropMenuOpen") == "1");
    @observable favouritesDropMenuOpen = false;
    @observable favouritesSaveMenuOpen = false;
    @observable favouritesleftMenuOpen = false;
    @observable workOrdersSelectEditing = false;
    @observable workOrdersSelectEditingPoint = { pageX: 0, pageY: 0 };

    @observable infoBlockEnabled = false;
    @observable schedudeByTime = "mes";


    calcZIndex() {
        this.zIndex += 10;
    }

    clickHiddenMenu() {
        this.sidebarMinimized = !this.sidebarMinimized;
        let sidebarMinimizedNumber = "0";
        if (this.sidebarMinimized) {
            sidebarMinimizedNumber = "1";
        }
        //Si está minificado, también cambio el masterDataOpen
        if (this.sidebarMinimized) {
            this.masterDataOpen = false;
        }
        window.localStorage.setItem("sidebarMinimized", sidebarMinimizedNumber);
    }

    clickHiddenMenuMasterData() {
        console.log('click Master data');
        this.masterDataOpen = !this.masterDataOpen;
        let masterDataOpenNumber = "0";
        if (this.masterDataOpen) {
            masterDataOpenNumber = "1";
        }
        window.localStorage.setItem("masterDataOpen", masterDataOpenNumber);
    }

    clickHiddenMenuSchedule() {
        console.log('clickHiddenMenuSchedule');
        this.scheduleOpen = !this.scheduleOpen;
        let sidebarMinimizedNumberSchedule = "0";
        if (this.scheduleOpen) {
            sidebarMinimizedNumberSchedule = "1";
        }
        window.localStorage.setItem("scheduleOpen", sidebarMinimizedNumberSchedule);
    }

    clickHiddenMenuScheduleVisit() {
        console.log('clickHiddenMenuScheduleVisit');
        this.scheduleVisitOpen = !this.scheduleVisitOpen;
        let sidebarMinimizedNumberScheduleVisit = "0";
        if (this.scheduleVisitOpen) {
            sidebarMinimizedNumberScheduleVisit = "1";
        }
        window.localStorage.setItem("scheduleVisitOpen", sidebarMinimizedNumberScheduleVisit);
    }

    clickHiddenMenuArticles() {
        this.sidebarMinimized2 = !this.sidebarMinimized2;
        let sidebarMinimizedNumber2 = "0";
        if (this.sidebarMinimized2) {
            sidebarMinimizedNumber2 = "1";
        }
        //Si está minificado, también cambio el masterDataOpen
        if (this.sidebarMinimized2) {
            this.articlesOpen = false;
        }
        window.localStorage.setItem("sidebarMinimized2", sidebarMinimizedNumber2);
    }

    clickHiddenMenuClients() {
        this.sidebarMinimized5 = !this.sidebarMinimized5;
        let sidebarMinimizedNumber5 = "0";
        if (this.sidebarMinimized5) {
            sidebarMinimizedNumber5 = "1";
        }
        //Si está minificado, también cambio el masterDataOpen
        if (this.sidebarMinimized5) {
            this.clientsOpen = false;
        }
        window.localStorage.setItem("sidebarMinimized5", sidebarMinimizedNumber5);
    }


    clickAddNew() {
        this.menuCreateNewActive = !this.menuCreateNewActive;
    }

    clickUser() {
        this.user = !this.user;
    }

    clickConfig() {
        this.config = !this.config;
    }

    clickMenu() {
        this.menu = !this.menu;
    }

    clickDownloadExcelCsv() {
        this.downloadExcelCsv = !this.downloadExcelCsv;
    }

    clickUserDropMenuOpen() {
        this.userDropMenuOpen = !this.userDropMenuOpen;
        let userDropMenuOpenNumber = "0";
        if (this.addnew) {
            userDropMenuOpenNumber = "1";
        }
        window.localStorage.setItem("userDropMenuOpen", userDropMenuOpenNumber);
    }

    clickslotView() {
        this.slotview = !this.slotview;
    }

    clickFavouritesSaveMenuOpen() {
        this.favouritesSaveMenuOpen = !this.favouritesSaveMenuOpen;
    }
}

export default LayoutState;
