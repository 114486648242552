import React, { Component } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost/lib/index";
import { observer } from "mobx-react/index";
import appState from "../state/AppState";
import util from "../util/Util";
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";
import Overlay from "./Overlay";


var sendRequest = function sendRequest(data, url, options) {
    var api = {
        onheaders: function onheaders() {
        },
        onprogress: function onprogress() {
        },
        onload: function onload() {
        },
        ontimeout: function ontimeout() {
        },
        onerror: function onerror() {
        },
        onabort: function onabort() {
        },
        abort: function abort() {
            aborted = true;
            xhr.abort();
        }
    };

    // timeout identifier, only used when timeout is defined
    var aborted = false;
    var headersReceived = false;

    // set default options
    options = Object.assign(
        {
            method: 'POST',
            headers: {},
            withCredentials: false
        },
        options
    );

    // encode url
    url = encodeURI(url);

    // if method is GET, add any received data to url

    if (isGet(options.method) && data) {
        url =
            '' +
            url +
            encodeURIComponent(
                typeof data === 'string' ? data : JSON.stringify(data)
            );
    }

    // create request
    var xhr = new XMLHttpRequest();

    // progress of load
    var process = isGet(options.method) ? xhr : xhr.upload;
    process.onprogress = function (e) {
        // no progress event when aborted ( onprogress is called once after abort() )
        if (aborted) {
            return;
        }

        api.onprogress(e.lengthComputable, e.loaded, e.total);
    };

    // tries to get header info to the app as fast as possible
    xhr.onreadystatechange = function () {
        // not interesting in these states ('unsent' and 'openend' as they don't give us any additional info)
        if (xhr.readyState < 2) {
            return;
        }

        // no server response
        if (xhr.readyState === 4 && xhr.status === 0) {
            return;
        }

        if (headersReceived) {
            return;
        }

        headersReceived = true;

        // we've probably received some useful data in response headers
        api.onheaders(xhr);
    };

    // load successful
    xhr.onload = function () {
        // is classified as valid response
        if (xhr.status >= 200 && xhr.status < 300) {
            api.onload(xhr);
        } else {
            api.onerror(xhr);
        }
    };

    // error during load
    xhr.onerror = function () {
        return api.onerror(xhr);
    };

    // request aborted
    xhr.onabort = function () {
        aborted = true;
        api.onabort();
    };

    // request timeout
    xhr.ontimeout = function () {
        return api.ontimeout(xhr);
    };

    // open up open up!
    xhr.open(options.method, url, true);

    // set timeout if defined (do it after open so IE11 plays ball)
    if (isInt(options.timeout)) {
        xhr.timeout = options.timeout;
    }

    // add headers
    Object.keys(options.headers).forEach(function (key) {
        xhr.setRequestHeader(key, options.headers[key]);
    });

    // set type of response
    if (options.responseType) {
        xhr.responseType = options.responseType;
    }

    // set credentials
    if (options.withCredentials) {
        xhr.withCredentials = true;
    }

    // let's send our data
    xhr.send(data);

    return api;
};

let url = 'https://vsf-1259.s3.eu-west-1.amazonaws.com/key-prueba-jpg?X-Amz-Expires=14400&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJXXY5ZHTN6VSBCKA/20190320/eu-west-1/s3/aws4_request&X-Amz-Date=20190320T143945Z&X-Amz-SignedHeaders=host&X-Amz-Signature=b64e7e05095a9a959af3d64ebbd12bf1f2ef5163ad332642cbd7b3354ac1229e';

let apiUrl = "";
let action = {
    url: ""
};
let baseurl = "http://localhost:5000/api/document";

let documentAddQuery = async(fileKey) => {
    let query = gql`
        query DocumentAdd($fileKey:String){
            documentAdd(fileKey:$fileKey) {
                presignedURL,
                url,
                urlSource
            }
        }
    `;
    let variables = {
        fileKey: fileKey
    };

    let apolloProxy = new ApolloProxy(apoloClient);
    let resultQuery = await apolloProxy.graphQuery({ query, variables });
    let newData = resultQuery;
    return newData;
};

const images = require.context('../../public/img/fileicons', true);

const imagePath = (name) => images(name, true);


registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

@observer
class UploadFileComponent extends Component {

    constructor(props) {
        super(props);
        this.pond = React.createRef();

        this.state = {
            // Set initial files, type 'local' means this is a file
            // that has already been uploaded to the server (see docs)
            files: [],
            select: "",
        };
    };

    handleInit() {
        this.pond.current._pond.on("addfile", function (error, file) {
            if (error) {
                return;
            }
            // Set file metadata here in order to retrieve it in the custom process function
            // file attributes like fileExtension and filenameWithoutExtension
            // are not availabe in the file object in the custom process function
            file.setMetadata('fileInfo', {
                filenameWithoutExtension: file.filenameWithoutExtension,
                fileExtension: file.fileExtension
            });
        });
    }

    getDocuments() {
        let result = [];
        result = this.props.documents;
        if (result == null) {
            result = [];
        }
        return result;
    }

    addDocument({ name, url, urlSource, size }) {

        window.setTimeout(() => {
            let createdAt = util.getToday();
            this.log("addDocument today");
            this.log(createdAt);
            let documentToAdd = { name, url, urlSource, size, createdAt };
            if (this.props.documents)
                this.props.documents.push(documentToAdd);
            this.onChange();
            this.log("addDocument=>");
            this.log({ documentToAdd });
        }, 2000);
    }

    onChange() {
        appState.layoutState.formWithoutChanges = false;
    }

    onDocumentAdded(e) {

    }

    deleteDocument = (index) => () => {
        this.props.documents.splice(index, 1);
        this.onChange();
    };


    getImageThumbnail = (document) => {
        let result = "";
        if (util.hasValue(document.url)) {

            let extensionesValidas = [];
            extensionesValidas.push(".png");
            extensionesValidas.push(".gif");
            extensionesValidas.push(".jpeg");
            extensionesValidas.push(".jpg");

            let isImage = false;

            extensionesValidas.map((extension) => {
                if (document.url.includes(extension)) {
                    isImage = true;
                }
            });

            if (isImage) {
                result = document.url;
            } else {
                let iPath;
                try {
                    iPath = imagePath('./' + document.url.split('.').pop() + '.png');
                } catch (err) {
                    /*No encuentra un icono específico con el formato del archivo subido*/
                    iPath = this.getDefaultIconPath();
                }
                result = iPath;
            }
        }
        return result;
    };

    /*Devuelve una ruta de un icono genérico que mostramos por defecto cuando no tenemos un icono para el formato del documento*/
    getDefaultIconPath() {
        return imagePath('./undefined.png');
    }

    // getImageAlt = (document) => {
    //     return document.name;
    // };

    renderImage(path) {
        return <img src={path} />;
    }


    render() {
        this.log("UploadFileComponent. RENDER documents=>");
        let documents = this.getDocuments();
        let { t } = this.props;
        let props = this.props;
        // acceptedFileTypes={['image/jpeg','image/png']}
        // maxFileSize={'600KB'}
        // allowFileSizeValidation={true}
        // allowFileTypeValidation={true}
        let optionsSelectFile = [
            { label: "Back-End", value: "back-End", },
            { label: "Technical", value: "technical", },
            { label: "Photo", value: "photo", },
        ];
        return (
            <div className={props.classGroup}>
                <FilePond
                    ref={this.pond}
                    server={this.getServer()}
                    files={this.state.files}
                    onDocumentAdded={(e) => this.onDocumentAdded(e)}
                    oninit={() => this.handleInit()}
                    labelIdle={t('Arrastre los ficheros aquí</br>o</br><span class="filepond--label-action"> Añadir ficheros </span>')}
                    allowMultiple={true}
                >
                </FilePond>

                <label htmlFor={props.name}
                       className="control-label">
                    {props.title}
                    {props.required && (<React.Fragment> *</React.Fragment>)}
                </label>

                <div className="uploaded-elements-container">
                    <div className="row">

                        {documents.map((document, index) => (
                            <div key={index} className="col-3 col-xl-2">
                                <div className="fileItem">
                                    <div className="fileItem-header" title={document.name}>
                                        <span onClick={this.deleteDocument(index)} className="delete-file"><i
                                            className="fa fa-trash-alt"> </i></span>
                                        <a className="download-file" target="_blank" href={document.url}>
                                            <span>{document.name}</span>
                                        </a>
                                    </div>
                                    <div className="fileItem-content">
                                        <div>
                                            <a className="download-file" target="_blank" href={document.url}>
                                                {this.renderImage(this.getImageThumbnail(document))}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="fileItem-footer">
                                        {document.createdAt ?
                                            <span>{util.getFechaForRequestBookingApi(document.createdAt)}</span>
                                            :
                                            <span>-</span>
                                        }
                                        <span className="ml-1 text-click"
                                              onClick={() => this.setState({ select: document.id })}>{document.field || "documents"}
                                            <span
                                                className="fas fa-angle-down" />
                                        </span>
                                        <span className="size">{util.formatBytes(document.size)}</span>
                                    </div>
                                </div>
                                {this.state.select == document.id &&
                                <>
                                    <Overlay show={this.state.select == document.id}
                                             onClick={() => this.setState({ select: false })} />
                                    <div className="dropdown-sublist" style={{ zIndex: 100 }}>
                                        {optionsSelectFile.map((obj, index) => (
                                            <div className="dropdown-sublist__item ">
                                                <a onClick={() => this.onChangeFileType(obj, document)}>{t(obj.label)}</a>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                }
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        );
    }

    onChangeFileType(obj, document) {
        this.log("onChangeFileType")
        this.onChange();
        document.field = obj.value;
        this.log(document);

        this.setState({ select: "" })
    }

    getServer() {

        let me = this;
        let baseFolder = this.props.baseFolder;
        // let baseFolder ="22/photo";

        let server = {
            url: baseurl,
            process5: {
                method: 'PUT',
                withCredentials: false,
                headers: {},
                timeout: 70000,
                onload: (e) => {
                    console.log("onload");
                    console.log(e);
                },
                onerror: (e) => {
                    console.log("onError");
                    console.log(e);
                },
                onaddfilestart: (e) => {
                    console.log("onaddfilestart");
                    console.log(e);
                },
                ondata: (e) => {
                    console.log("onData");
                    console.log(e);
                }
            },
            process: function (fieldName, file, metadata, load, error, progress, abort) {
                var filepondRequest = new XMLHttpRequest({
                    contentType: 'binary/octet-stream',
                    processData: false,
                });
                console.log("process=>");
                console.log({ fieldName, file, metadata, load, error, progress, abort });

                //let filePathName="txt";
                let fileKey = baseFolder + "/" + file.name;
                //"/tmp/prueba.jpg","key-prueba-jpg"
                let signedResult = documentAddQuery(fileKey);
                signedResult.then((response) => {
                    console.log(response);
                    try {
                        let presignedUrl = response.data.documentAdd.presignedURL;
                        let urlSource = response.data.documentAdd.urlSource;
                        let url = response.data.documentAdd.url;
                        var filepondFormData = new FormData();
                        filepondFormData.append("file", file);
                        filepondRequest.upload.onprogress = function (e) {
                            progress(e.lengthComputable, e.loaded, e.total);
                        };
                        filepondRequest.open("PUT", presignedUrl);
                        filepondRequest.onload = function () {
                            load(`${"file.name"}`);
                            console.log("Loaded " + file.name);
                            me.addDocument({ name: file.name, url, urlSource, size: file.size });
                        };
                        let binaryFile = filepondFormData.get("file");
                        let binaryfile = file;


                        filepondRequest.send(binaryFile);
                        console.log("/process");
                    } catch (e) {
                        console.log("Error uploading file=>");
                        console.log({ e });
                    }
                })
                //filepondRequest.open("put", url);


                return {
                    abort: function () {
                        filepondRequest.abort();
                        abort();
                    }
                };
            }
        }
        return server;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(UploadFileComponent);
