import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {DefaultRoute, Link, withRouter} from 'react-router-dom'
import VsfButton from "./VsfButton";
import PropTypes from 'prop-types';
import appState from "../state/AppState";
import Overlay from "./Overlay";
import withTranslate from "../translator/withTranslate";


@observer
class MoreButtons extends Component {

    static propTypes = {
        actions: PropTypes.array,
    }
    static defaultProps = {
        actions: []
    }


    constructor() {
        super();
    }

    /**
     * Si ha cabiado el orden del listado recargo el listado
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        console.log("CreateNew. componentDidUpdate");
    }

    onClick(evento) {
        appState.layoutState.menu = false
        evento();
    }

    render() {
        console.log("oooooo" + this.props.actions);
        const {t, i18n} = this.props;
        return (
            <>
                <Overlay show={appState.layoutState.menu} onClick={_ => appState.layoutState.menu = false}/>
                {
                    <div className="col-4 text-right">
                        <VsfButton
                            onClick={() => appState.layoutState.clickMenu()}
                            classButton={"btn--points"}
                            icon={"fas fa-ellipsis-v"}
                            disabled={false}
                        />
                        {appState.layoutState.menu &&
                        <div className="dropdown-sublist" style={{zIndex: 100}}>
                            {this.props.actions.map((accion, index) => (
                                <div className="dropdown-sublist__item ">
                                    <a onClick={()=>this.onClick(accion.onClick)}>{t(accion.title)}<span>{accion.icon&& <i className={accion.icon}></i>}</span></a>


                                </div>
                   ))}
                        </div>
                        }
                    </div>
                }
            </>
        );
    }

}

//withRouter es necesario para que AppWrapper pueda estar dentro de <Router> siendo @observable
export default withTranslate(withRouter(MoreButtons));

