import React, { Component } from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/AppState';
import OrderModel from "../../models/OrderModel";
import {observer} from "mobx-react";
import CreateAndModify from "../../components/fields/CreateAndModify";
import TabsData from "../../layout/TabsData";
import Links from "../../util/Links";
import TabsComponent from "../../components/fields/TabsComponent";
import BaseForm from "../base/BaseForm";

@observer
class OrderViewSumary extends BaseForm {

    static propTypes = {
        // id del Work OrderModel a visualizar
        id: PropTypes.number,
        // Datos iniciales para poder mostrar al arrancar.
        dataInitial: PropTypes.object,
    }

    constructor(props) {
        super(props);
        appState.orderState.row= new OrderModel();
        this.tabsData = new TabsData();
    }

    async componentDidMount() {
        console.log("OrderViewSumary.componentDidMount()");
        //cargamos la información inicial.
        if (this.props.dataInitial) {
            appState.orderState.row = this.props.dataInitial;
        }
        //console.log(this.props.plainWorkOrder);

        //Cargamos la información de la RED
        try {
            let orderQuery = new OrderModel();
            let order = await orderQuery.findByIdNotNull(this.props.id);
            let plainOrder=order.toPlainObject();
            appState.orderState.row = plainOrder;

            console.log({plainOrder});
            console.log(order);
        } catch (e) {
            //Si hay un error mostramos un mensaje de error.
        }
    }

    getFormId() {
        let links = new Links();
        return links.getFormId(this.props);
    }
    render() {
        let  myWorkOrderStatic={
            requestOk:'Juan Pedro Urrutia',
            requestPhone: 627894574,
            contact:'Pedro Parra',
            contactPhone: 627885474,
            ubicacion: 'Norte',
            note:'Por favor, avisar el dia de antes de la visita'

        }
        let order = new OrderModel();
        order.hidrate(appState.orderState.row);
        let id = this.getFormId()|| this.props.id;
        return (
            <div className="modal-push">
                <div className="modal-head">
                    <div className="row">
                        <div className="col-12 col-md-9 text-left">
                            <div className="modal-title">
                                <p>Pedido <span>{order.code}</span></p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 d-flex justify-content-end">
                            <button type="button" className="edit-modal" aria-label="Editar">
                                <span className="far fa-edit"></span>
                            </button>
                            <button type="button" className="close fusion" aria-label="Cerrar" onClick={()=>this.props.onCloseModal()}>
                                <span className="fas fa-times"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-body full-height">
                    <div className="row">
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataOrder()}
                            active="Datos Generales"
                            fromRightModal={this.props.fromRightModal}
                        />
                        <div className="col-12">
                            <div className="tab-content tab-content--modal" id="modalTabContent">
                                {/*datos generales*/}
                                <div className="tab-pane fade show active" id="datosGenerales" role="tabpanel" aria-labelledby="datosGenerales-tab">
                                    <div className="editable">
                                        <CreateAndModify
                                            updatedAt={order.updatedAt}
                                            updatedBy={order.updatedBy}
                                            createdBy={order.createdBy}
                                            createdAt={order.createdAt}
                                            code={order.code}
                                            getFormId={order.id}
                                        />
                                        <div className="block">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="estado" className="control">Estado</div>
                                                        <div className="div-control" aria-labelledby="cliente">{order.status}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-12">
                                                    <div className="group">
                                                        <p className="notice exclamation">{order.comments}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="cliente" className="control">Cliente / Establecimiento</div>
                                                        <div className="div-control" aria-labelledby="cliente">{order.clientName}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="group">
                                                        <div id="ubicacion" className="control">Ubicación</div>
                                                        <div className="div-control ubicacion" aria-labelledby="ubicacion">{order.clientPosAddress}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="group">
                                                        <div id="ubicacion" className="control">Zona</div>
                                                        <div className="div-control zona" aria-labelledby="ubicacion">{order.clientZone}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="solicitante" className="control">Solicitante</div>
                                                        <div className="div-control double" aria-labelledby="solicitante">
                                                            <div className="div-control__name">{order.requestContactId}</div>
                                                            <div className="div-control__phone">{order.clientPhoneRequest}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="contacto" className="control">Contacto</div>
                                                        <div className="div-control double" aria-labelledby="contacto">
                                                            <div className="div-control__name">{order.contactId}</div>
                                                            <div className="div-control__phone">{order.clientPhoneContact}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-0 col-lg-4"></div>
                                            </div>
                                        </div>
                                       {/* <p className="notice exclamation">{order.note}</p>
                                        <div className="block">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="tipo-intervencion" className="control">Tipo Intervención</div>
                                                        <div className="div-control red" aria-labelledby="tipo-intervencion">{order.type}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="subtipo-intervencion" className="control" >Subtipo de Intervención</div>
                                                        <div className="div-control red" aria-labelledby="subtipo-intervencion">{order.subType}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="group">
                                                        <div id="sintoma-averia" className="control">Síntoma de la Avería</div>
                                                        <div className="font-weight-bold div-control" aria-labelledby="sintoma-averia">{order.symptomId}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="group">
                                                        <div id="tipo-equipo" className="control">Tipo de Equipo</div>
                                                        <div className="font-weight-bold div-control" aria-labelledby="tipo-equipo">{order.assetType}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="group">
                                                        <div id="fabricante" className="control">Fabricante</div>
                                                        <div className="div-control" aria-labelledby="fabricante">{order.assetManufacturer}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="group">
                                                        <div id="modelo" className="control">Modelo</div>
                                                        <div className="div-control" aria-labelledby="modelo">{order.assetModel}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="group">
                                                        <div id="numero-serie" className="control">Número de Serie</div>
                                                        <div className="div-control" aria-labelledby="numero-serie">{order.assetSerialNumber}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="group">
                                                        <div id="numero-equipo" className="control">Número de Equipo</div>
                                                        <div className="font-weight-bold div-control" aria-labelledby="numero-equipo">{order.assetPlate}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="group">
                                                        <div id="emplazamiento" className="control">Emplazamiento</div>
                                                        <div className="font-weight-bold div-control" aria-labelledby="emplazamiento">{order.assetLocation}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                       {/* <p className="notice comment">{order.clientComments}</p>
                                        <div className="attach-images">
                                            <figure className="attach-images__item">
                                                <img src="" alt=""/>
                                            </figure>
                                            <figure className="attach-images__item">
                                                <img src="" alt=""/>
                                            </figure>
                                        </div>*/}
                                    </div>
                                </div>
                            {/*    planificación
                                <div className="tab-pane fade" id="planificacion" role="tabpanel" aria-labelledby="planificacion-tab">planificación</div>
                                piezas
                                <div className="tab-pane fade" id="piezas" role="tabpanel" aria-labelledby="piezas-tab">piezas</div>
                                histórico
                                <div className="tab-pane fade" id="historico" role="tabpanel" aria-labelledby="historico-tab">histórico</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderViewSumary;
