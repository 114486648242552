import React, {Component} from 'react';
import {observer} from 'mobx-react';
import VsfButton from "./VsfButton";
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";


@observer
export default class FormButtons extends Component {
    render() {
        this.log("render()")
        this.log(this.props.withPassword)
        let desactivado, formContrasenya;
        if (this.props.withPassword != null) {
            if (this.props.withPassword) {
                desactivado = true;
                formContrasenya = true;
                this.log(desactivado)
                this.log(formContrasenya)
            } else
                formContrasenya = false;
        } else {
            desactivado = appState.layoutState.formWithoutChanges;
            formContrasenya = true;
        }
        return (
            <div className="form_container form_actions mt-3 mb-3 col-12">
                <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0 text-left">
                        {this.props.id != "" && this.props.remove &&
                        <React.Fragment>
                            <button type="button" className="btn btn-danger delete js-delete">
                                <i className="fa fa-trash" /> Eliminar
                            </button>

                        </React.Fragment>
                        }

                    </div>
                    <div className="col-md-4 text-right ">
                        {this.props.button}
                    </div>
                    <div className="col-md-4 mt-3 mt-md-0 text-right">
                        <VsfButton
                            label={"Guardar"}
                            loading={this.props.formStatus.mutationLoading}
                            loadedError={this.props.formStatus.mutationError}
                            icon="fa fa-save"
                            type="submit"
                            disabled={desactivado}
                            formButton={formContrasenya}
                            classButton="btn--disabled"
                        />
                    </div>
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

