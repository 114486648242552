import React from 'react';
import {observer} from 'mobx-react';
import {DefaultRoute} from 'react-router-dom'
import PageTitle from "../components/PageTitle"
import {withApollo} from "react-apollo";
import appState from '../state/AppState';
import BaseForm from "./base/BaseForm";
import FormWrapper from "../components/FormWrapper";
import withTranslate from "../translator/withTranslate";
import NetworkStatusInfo from "../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../components/status/LoadingOrErrorInfo";
import {DragDropContextProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import WorkCard from "../scheduler/WorkCard";
import BoardSquare from "../scheduler/BoardSquare";
import Pendings from "../dndrop/prueba/Pendings";
import Box from "../dndrop/prueba/Box";
import MoreButtons from "../components/MoreButtons";


@observer
class ScheduleTest extends BaseForm {

    nameMainType="client";

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType]={};
    }

    render() {
        let {t} = this.props;

        let days=[];
        for(let i=1;i<=31;i++) {
            days.push(i);
        }
        let technicals=[];
        for(let i=0;i<5;i++) {
            technicals.push({ name:"Technical "+i});
        }
        return (
            <React.Fragment>
                <PageTitle
                    title={t("Calendario prueba")}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nuevo Calendario", onClick: () => {
                                   // this.propsUtil.changeUrl("/asset/form/")
                                }
                            }
                        ]
                    }
                />
                </PageTitle>
                <form
                    className="model-form"
                    name="formulario"
                    novalidate
                    onSubmit={(e) => this.handleFormSubmit(e)}
                >
                    <div className={'card mb-3'}>

                        <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                           error={this.graphStatus.mutationError}
                                           working={this.graphStatus.networkWorking}
                        />
                        <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                            formError={this.graphStatus.formError}
                        />


                        <FormWrapper>
                           <table>
                                <thead>
                                    <tr>
                                        <th><WorkCard/></th>
                                        {days.map(day=>
                                            <th>{day}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {technicals.map(technical=>
                                        <tr>
                                            <th>
                                                {technical.name}
                                                <WorkCard/>
                                            </th>
                                            {days.map(day=>
                                                <td>
                                                    <div style={{"width":60, height:45, border:"1px solid red"}}>

                                                        <BoardSquare>
                                                            <WorkCard/>
                                                        </BoardSquare>
                                                    </div>


                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="row">
                                <div className="col-12 col-lg-4 text-left">
                                    <button type="button" className="btn-table">Zona</button>
                                    <button type="button" className="btn-table active">Mes en curso</button>
                                </div>
                                <div className="col-12 col-lg-8 text-right">
                                    <div role="group" aria-label="Button group with nested dropdown">
                                        <div className="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fas fa-columns"></span>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                <a className="dropdown-item" href="#">link 1</a>
                                                <a className="dropdown-item" href="#">link 2</a>
                                            </div>
                                        </div>
                                        <div className="btn-group" role="group">
                                            <button id="btnGroupDrop2" type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fas fa-download"></span>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                                                <a className="dropdown-item" href="#">link1</a>
                                                <a className="dropdown-item" href="#">link2</a>
                                            </div>
                                        </div>
                                        <div className="btn-group" role="group">
                                            <button id="btnGroupDrop3" type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fas fa-download"></span>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                                                <a className="dropdown-item" href="#">link1</a>
                                                <a className="dropdown-item" href="#">link2</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="schedule-table">
                                        <div className="table-responsive">
                                            <table className="table" summary="Tabla de planificación de horas para trabajo de los técnicos">
                                                <caption>Planificación Cargas de trabajo por Técnico</caption>
                                                <thead className="schedule-table__head">
                                                    <tr>
                                                        <th scope="col" className="schedule-table__head__name"><span className="fa fa-sort-amount-down" aria-hidden="true"></span> Técnico <span className="fa fa-sort-amount-up" aria-hidden="true"></span> </th>
                                                        {days.map(day=>
                                                            <th scope="col" className="schedule-table__head__day">
                                                                <div>{
                                                                    day}
                                                                    <span className="week-day">**v</span>
                                                                </div>
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="schedule-table__body">
                                                {technicals.map(technical=>
                                                    <tr>
                                                        <th scope="row">
                                                            <span className="technical-name">Jose Antonio</span>
                                                        </th>
                                                        {days.map(day=>
                                                            <td>
                                                                <div className="td-hours">
                                                                    <div className="td-hours__item">
                                                                        <BoardSquare>
                                                                        </BoardSquare>
                                                                    </div>
                                                                    <div className="td-hours__item">
                                                                        <BoardSquare>
                                                                        </BoardSquare>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                     </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                             </div>

                        </FormWrapper>
                    </div>
                    <WorkCard/>
                    <WorkCard/>
                </form>

            </React.Fragment>
        )
    }

}

appState.preventOrders=[
    {id:"1", index:"1", name:"1", date:"20190101"},
    {id:"2", index:"2", name:"2", date:"20190101"},
    {id:"3", index:"3", name:"3", date:"20190101"},
    {id:"4", index:"4", name:"4", date:null},
    {id:"5", index:"5", name:"5", date:null}
]

const DraggableScheduler = (props) => {

    return <DragDropContextProvider backend={HTML5Backend}>
        <ScheduleTest {...props}/>


        <Box>

        </Box>
        <Pendings/>


    </DragDropContextProvider>
}



export default withApollo(withTranslate(DraggableScheduler,"ScheduleTest"));
