import React from 'react';
import { observer } from 'mobx-react';
import appState from '../../state/AppState';
import ContactModel from "../../models/ContactModel";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import PropTypes from 'prop-types';
import { computed, toJS } from 'mobx';
import BaseEditableRow from "../base/BaseEditableRow";
import InfoBlock from "../../components/InfoBlock";
import Select2Component from "../../components/fields/Select2Component";
import GroupOfField from "../../components/layout/GroupOfField";

@observer
class ClientContact extends BaseEditableRow {
    static propTypes = {
        setHasSomeRowEditing: PropTypes.func, //Función que se llama cuando el elemento editable actual pasa a ser no editable
        hasSomeRowEditing: PropTypes.bool, //Algún elemento se está editando actualmente. No tiene por qué ser este elemento. Hace que se "nublen" los botones de "editar"
    }
    static defaultProps = {
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    }

    constructor(props) {
        super(props);
        this.state = {}
        this.foreingKeyfield = "clientId";
    }

    @computed
    get mobxListado() {
        return appState.contactState.contacts;
    }

    setMobxListado(newValue) {
        appState.contactState.contacts = newValue;
    }

    getModelTable() {
        return new ContactModel();
    }

    async componentDidMount() {
        await super.componentDidMount();
        // await appState.typifiedState.loadEmploiments();
        // await appState.typifiedState.arrayLoadEmploimets();
    }

    render() {
        let contact = this.props.row;
        // let errorsMapped = this.getGraphErrorsFromStatus();
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr>
                    <td>
                        <div className="form-group">
                            <InputTypeComponent
                                value={contact.firstName}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"firstName"}
                                type={"text"}
                                showView={!contact.isEditing}
                                className="form-control"
                            />
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <InputTypeComponent
                                value={contact.phoneNumber}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"phoneNumber"}
                                type={"text"}
                                validate={"phone"}
                                showView={!contact.isEditing}
                                className="form-control"
                                // errors={errorsMapped.phoneNumber}
                            />
                        </div>
                    </td>

                    <td>
                        <div className="form-group text-lowercase">
                            <InputTypeComponent
                                className={" form-control"}
                                value={contact.email}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"email"}
                                type={"text"}
                                showView={!contact.isEditing}
                                // className={"form-control"}
                                required={true}
                                validate={"email"}
                                // errors={errorsMapped.email}
                            />
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <InputTypeComponent
                                className={" form-control"}
                                value={contact.position}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"position"}
                                type={"text"}
                                showView={!contact.isEditing}

                            />
                        </div>
                    </td>
                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }
}

export default ClientContact;
