
import React from 'react';

const GqlErrors = (props)=> {
    let className = "";
    let errorsMapped=props.errorsMapped;
    let errors = props.errors;

    if (errors==null) {
        return null;
    }
    if (errors.length==0) {
        return null;
    }
    return (
        <div class="alert alert-danger" role="alert">
            <React.Fragment>
                {errors.map(error => <div>{error.message}</div>)}
            </React.Fragment>
        </div>
    )
}

export default GqlErrors;
