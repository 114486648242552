import Model from "./Model";
import AbstractModel from "./AbstractModel";

export default class AssetCodesModel extends AbstractModel {

    id;
    assetId;
    code;
    type;
    providerName;

    nameMainType = "assetCode";
    graphFindByIdOperation = "assetCode";
    graphFindOperation = "assetCodesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "assetId": "",
            "code": "",
            "type": "",
            "providerName": "",
        };
        return result;
    }

    async getAssetCodesFromAssetId(assetId) {
        let result = [];
        if (assetId != null) {
            let codesQuery = new AssetCodesModel();
            codesQuery.assetId = assetId;
            result = await codesQuery.find();
        }
        return result;
    }

}
