import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withTranslate from "../translator/withTranslate";

@observer
export default class PageTitle extends Component {
    render() {
        let props = this.props;
        const { t, i18n } = this.props;
        return (
            <div className="title-section">
                <div className="row">
                    <div className="col-8 text-left d-md-flex align-items-md-center">
                        <h1 className="title title--inline">{props.title}</h1>
                        <h2 className={this.props.toLowerCase ? "text-lowercase subtitle " : "subtitle "}>{props.subtitle}</h2>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

