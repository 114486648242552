import AbstractModel from "./AbstractModel";


export default class DateRangesModel extends AbstractModel {


    id;
    startDate;
    endDate;
    comments;
    type;
    userId;


    nameMainType = "dateRange";
    graphFindByIdOperation = "dateRange";
    graphFindOperation = "dateRangesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "userId": "",
            "startDate": "",
            "endDate": "",
            "type": "",
            "comments": "",
        };
        return result;
    }

    async getDateRanges() {
        let dateRanges = new DateRangesModel();
        return await dateRanges.find();
    }

    async getDateRangesFromUserId(userId) {
        let result = [];
        if (userId != null) {
            let dateRangesQuery = new DateRangesModel();
            dateRangesQuery.userId = userId;
            result = await dateRangesQuery.find();
        }
        return result;
    }

};





















