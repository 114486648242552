import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import OrderModel from "../../models/OrderModel";
import SlotModel from "../../models/SlotModel";
import AppLogger from "../../util/AppLogger";

const Types = {
    ITEM: 'workInMonthUnit'
}

class EmptyDropHandler {
    async drop(props, monitor, component) {
        console.log("EmptyDrop.drop");
        if (monitor.didDrop()) {
            return;
        }
        // Obtain the dragged item
        const propsFromVisitDragged = monitor.getItem();

        if (propsFromVisitDragged.order != null) {

            let orderObservable = propsFromVisitDragged.order;
            let order = new OrderModel();
            order.hidrate(orderObservable);
            //Elimino el order
            let slotQuery = new SlotModel();
            let slotsModels = await slotQuery.getSlotsFromOrder(order.id);
            let conRelaciones = false;
            slotsModels.map((slot, index) => {
                if (slot.scheduledTime != null) {
                    conRelaciones = true;
                }
            });
            let orderQuery = new OrderModel();
            orderQuery.filters = [
                { "fieldName": "orderPreventiveId", "fieldValue": order.id, "filterOperator": "EQ" },
            ];
            let ordersRelaccionadas = await orderQuery.find();
            ordersRelaccionadas.map(order => {
                if (order.id != null) {
                    conRelaciones = true;
                }
            });
            if (conRelaciones) {
                props.onDeleteWithSlot(orderObservable);
            } else {
                appState.scheduleDropState.scheduledOrders = appState.scheduleDropState.scheduledOrders.filter(orderIterate => orderIterate.id != order.id);
                await order.remove();
            }
            props.getTotals();
            props.loadClients();
            props.getSlots();
        }
    }
}


function log(msg) {
    AppLogger.get().debug(msg, this);
}

let emptyDropHandler = new EmptyDropHandler();

const itemTarget = {
    drop(props, monitor, component) {
        emptyDropHandler.drop(props, monitor, component);
    }
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class EmptyDrop extends Component {
    static propTypes = {
        //No se manda ningun par�metro
    }

    render() {
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div className="empty-drop">
                {this.props.children}
            </div>
        )
    }
}

export default DropTarget(Types.ITEM, itemTarget, collect)(EmptyDrop)
