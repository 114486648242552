import React from 'react';
import appState from '../../state/AppState';
import { observer } from "mobx-react";
import TabsComponent from "../../components/fields/TabsComponent";
import PageTitle from "../../components/PageTitle";
import UserModel from "../../models/UserModel";
import withTranslate from "../../translator/withTranslate";
import GroupOfField from "../../components/layout/GroupOfField";
import { withRouter } from "react-router-dom";
import { computed, observable } from 'mobx';
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import VsfButton from "../../components/VsfButton";
import BaseEditableTable from "../base/BaseEditableTable";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import UserAvailabilityForm from "./UserAvailabilityForm";
import DateRangesModel from "../../models/DateRangesModel";

@observer
class UserAvailability extends BaseEditableTable {

    /**
     * Nombre del campo de la clase principal
     */
    foreingKeyfield;

    constructor(props) {
        super(props);
        this.nameMainType = "user";
        this.initializeGraphData(this.nameMainType);
        this.foreingKeyfield = "userId";
    }

    @computed get mobxListado() {
        this.log("UserAvailability.mobxListado");
        return appState.dateRangesState.dateRanges;
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    getModelQuery() {
        return new DateRangesModel();
    }

    getModelTable() {
        return new DateRangesModel();
    }

    async reloadTable(userId) {
        appState.dateRangesState.reloadDatesFromUserId(userId);

    }

    render() {
        let { t } = this.props;
        let user = this.graphDataMainType;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Usuario"}
                                subtitle={user.email}
                                onBackModal={() => this.onBackModal()}
                                mode={"edit"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.graphDataMainType.id || this.props.userId}
                                tabs={this.tabsData.getDataUser()}
                                active="Disponibilidad"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={"Usuario"}
                            subtitle={user.email}
                        />
                    }

                    <div className={'card mb-3'}>
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.graphDataMainType.id}
                            tabs={this.tabsData.getDataUser()}
                            active="Disponibilidad"
                            fromRightModal={this.props.fromRightModal}
                        />
                        }
                        <div className={modalClasses.body}>
                            <div className="card-body">
                                <div className="row">
                                    <GroupOfField
                                        title={t('Disponibilidad Usuario')}
                                        icon="fas fa-id-card"
                                    />

                                </div>
                                <div className="row mt-4">
                                    <div className="table-edit">
                                        <div className="col-12">
                                            <table className="table visible-text"
                                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                                <thead>
                                                {this.mobxListado.length == 0 &&
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{t('No hay ningun estado creado')}
                                                    </td>
                                                </tr>
                                                }
                                                {this.mobxListado.length != 0 &&

                                                <tr>
                                                    <th scope="col">{t('Estado Temporal')}</th>
                                                    <th scope="col">{t('Fecha Inicio')}</th>
                                                    <th scope="col">{t('Fecha Fin')}</th>
                                                    <th scope="col">{t('Comentarios')}</th>
                                                    <th scope="col">&nbsp;</th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">
                                                { this.mobxListado.map((usuario, index) => (
                                                    <UserAvailabilityForm
                                                        key={usuario.id}
                                                        userId={this.props.userId}
                                                        rowIndex={index}
                                                        row={usuario}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    />
                                                ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.props.fromRightModal ? "fixed-bottom mb-4" : " "}>
                                    <div className="row">
                                        <div className="col-12 text-right">

                                            <VsfButton
                                                disabled={this.state.hasSomeRowEditing}
                                                label={"Crear nueva"}
                                                onClick={_ => this.onOpenRowNewEmpty()}
                                                classButton={"btn btn--loader"}
                                            />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        )
    }

}

export default withRouter(withTranslate(UserAvailability, "UserAvailability"));

