import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, DefaultRoute, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import SummaryPage from "./SummaryPage";
import WorkOrderList from "./pages/workOrders/WorkOrderList";
import WorkOrderForm from "./pages/workOrders/WorkOrderForm";
import OrderList from "./pages/orders/OrderList";
import Orderform from "./pages/orders/OrderForm";
import posed, { PoseGroup } from 'react-pose';
import ClientList from "./pages/clients/ClientList";
import ClientForm from "./pages/clients/ClientForm";
import UserList from "./pages/users/UserList";
import UserForm from "./pages/users/UserForm";
import EmployeeCapacitation from "./pages/users/EmployeeCapacitationTable";
import AssetList from './pages/assets/AssetList';
import AssetForm from "./pages/assets/AssetForm";
import Schedule from "./pages/ScheduleTest";
import Home from "./pages/Home";
import TypifiedValueList from "./pages/typifieds/TypifiedValueList";
import TypifiedValueForm from "./pages/typifieds/TypifiedValueForm";
import AppWrapper from "./layout/AppWrapper";
import ScheduleByClient from "./dndrop/scheduleByClient/ScheduleByClient";
import ClientSchedule from "./dndrop/clientSchedule/ClientSchedule";
import ScheduleVisitList from "./pages/scheduledVisits/ScheduleVisitList";
import WorkOrderFormSlots from "./pages/workOrders/WorkOrderFormSlots";
import WorkOrderFormItems from "./pages/workOrders/WorkOrderFormArticles"
import ScheduleByTechnical from "./dndrop/scheduleByTechnical/ScheduleByTechnical";
import ClientContacts from "./pages/clients/ClientContacts";
import ArticleList from "./pages/articles/ArticleList"
import ArticleForm from "./pages/articles/ArticleForm";
import ProfileForm from "./pages/ProfileForm";
import OrderFormSlots from "./pages/orders/OrderFormSlots";
import ChangePassword from "./pages/ChangePassword";
import OrderFormWo from "./pages/orders/OrderFormWorkOrders";
import OrderFormArticles from "./pages/orders/OrderFormArticles";
import ArticleFormLocation from "./pages/articles/ArticleFormLocation";
import OrderViewSumary from "./pages/orders/OrderViewSumary";
import PruebasForm from "./pages/PruebasForm";
import AssetFormLocation from "./pages/assets/AssetFormLocation";
import ScheduleVisitForm from "./pages/scheduledVisits/ScheduleVisitForm";
import WorkOrderHistory from "./pages/workOrders/WorkOrderHistory";
import OrderHistory from "./pages/orders/OrderHistory";
import AssetHistory from "./pages/assets/AssetHistory";
import ArticleHistory from "./pages/articles/ArticleHistory";
import ClientHistory from "./pages/clients/ClientHistory";
import Document from "./pages/Document";
import UserHistory from "./pages/users/UserHistory";
import ErrorBoundary from "./components/ErrorBoundary";
import ClientPosList from "./pages/clients/ClientPosList";
import WorkOrderHistoryTech from "./pages/workOrders/WorkOrderHistoryTech";
import UserAvailability from "./pages/users/UserAvailability";
import VehicleList from "./pages/vehicles/VehicleList";
import VehicleForm from "./pages/vehicles/VehicleForm";
import ConfigTranslates from "./pages/config/ConfigTranslates";

const RouteContainer = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        //delay: 300,
        delay: 0,
        /*
        transition: {
            y: { type: 'spring', stiffness: 1000, damping: 15 },
            default: { duration: 300 }
        }
        */
    },
    visible: {
        opacity: 1,
        transition: {duration: 0}
    },
    exit: {
        y: 0,
        opacity: 0,
    }
});

/*
const PoseGroup2 = (props)=> {
    return  {...props.children}
};
*/
/** Permite que al hacer click la página suba al TOP **/

const history = createBrowserHistory();

//window.history.scrollRestoration="manual";

/*
history.listen(_ => {

    let yScroll=0;
    window.scrollTo(0, yScroll);
    console.log("createBrowserHistory. top:"+yScroll);
    console.log("createBrowserHistory=>");
    console.log({_});
})
*/


/** Fin pagina subir a TOP */

/**
 * HashRouter hace que al cambiar las rutas se haga un scroll to TOP.
 *
 * BrowserRouter hace que no haga ningún scroll to top por defecto. Pero podemos hacer que con páginas nuevas si lo haga
 */
@observer
class AppRouter extends Component {
    render() {
        //<ScrollRemember2/>
        let props = this.props;
        console.log({props});
        let Try = ErrorBoundary;
        let PoseGroupEmpty = React.Fragment;
        return (
            <BrowserRouter hashType2="hashbang" history={history}>
                <AppWrapper {...props.children}>
                    <Route render={({location}) => (
                        <PoseGroupEmpty>
                            <RouteContainer key={location.pathname}>
                                <Switch location={location}>
                                    <Route exact={true} path="/" render={routeprops => (
                                        <Try><Home key="home" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/article/ls" render={routeprops => (
                                        <Try><ArticleList key="article-list" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/article/form/:id?" exact={true} render={routeprops => (
                                        <Try><ArticleForm key="article-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/article/form/:id?/history" exact={true} render={routeprops => (
                                        <Try><ArticleHistory key="article-history" {...routeprops} {...props} /></Try>
                                    )}/>
                                    {/*hay que poner el exact a true, sino no abre la segunda direccion*/}
                                    <Route path="/article/form/:id?/location" exact={true} render={routeprops => (
                                        <Try><ArticleFormLocation key="article-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/schedule/visit/ls" render={routeprops => (
                                        <Try><ScheduleVisitList key="schedule-visit-list" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/schedule/visit/form/:id?" exact={true} render={routeprops => (
                                        <Try><ScheduleVisitForm key="schedule-visit-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/summary" render={routeprops => (
                                        <Try><SummaryPage key="summary" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/client/form/:id/prevent" render={routeprops => (
                                        <Try><ClientSchedule key="client-prevent" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/client/form/:id/contacts" render={routeprops => (
                                        <Try><ClientContacts key="client-contacs" {...routeprops} {...props} /></Try>
                                    )}/>

                                    {/* <Route path="/client/form/new/contacts" render={routeprops => (
                                                <ClientContacts key="client-new-contacs" {...routeprops} {...props} />
                                            )}/>*/}
                                    <Route path="/schedule/visit/byclient" key="client-schedule" render={routeprops => (
                                        <Try><ScheduleByClient key="client-schedule" {...routeprops} {...props} /></Try>
                                    )}/>*
                                    <Route path="/schedule/bytechnical" key="schedule-detail" render={routeprops => (
                                        <Try><ScheduleByTechnical key="schedule-detail" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/order/form/:id?" exact={true} render={routeprops => (
                                        <Try><Orderform key="order-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/order/form/:id?/work-order" exact={true} render={routeprops => (
                                        <Try><OrderFormWo key="order-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/order/form/:id?/history" key="order-history" exact={true}
                                           render={routeprops => (
                                               <Try><OrderHistory key="order-history" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/order/form/:id?/articles" exact={true} render={routeprops => (
                                        <Try><OrderFormArticles key="order-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/order/ls" exact={true} render={routeprops => (
                                        <Try><OrderList key="order-ls" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/work-order/form/:id?" key="work-order-form-items" exact={true}
                                           render={routeprops => (
                                               <Try><WorkOrderForm key="work-order-ls2form" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/work-order/form/:id?/assigment" key="work-order-form-assigment"
                                           exact={true} render={routeprops => (
                                        <Try><WorkOrderFormSlots key="work-order-form-assigment" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/work-order/form/:id?/items" key="work-order-formu" exact={true}
                                           render={routeprops => (
                                               <Try><WorkOrderFormItems key="work-order-items" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/work-order/form/:id?/history" key="work-order-history" exact={true}
                                           render={routeprops => (
                                               <Try><WorkOrderHistory key="work-order-history" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/work-order/form/:id?/historytech" key="work-order-historytech" exact={true}
                                           render={routeprops => (
                                               <Try><WorkOrderHistoryTech key="work-order-history" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/work-order/ls" key="work-order-ls" exact={true}
                                           render={routeprops => (
                                               <Try><WorkOrderList key="work-order-ls2" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/client/ls" key="client-ls" exact={true} render={routeprops => (
                                        <Try><ClientList key="client-ls" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/posClients/ls" key="posClient-ls" exact={true} render={routeprops => (
                                        <Try><ClientPosList key="posClient-ls" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/client/form/:id?" key="client-form" exact={true}
                                           render={routeprops => (
                                               <Try><ClientForm key="client-form" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/client/form/:id?/history" key="client-history" exact={true}
                                           render={routeprops => (
                                               <Try><ClientHistory key="client-history" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/user/ls" key="user-ls" exact={true} render={routeprops => (
                                        <Try><UserList key="user-ls" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/user/form/:id?/history" key="user-history" exact={true}
                                           render={routeprops => (
                                               <Try><UserHistory key="user-history" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/user/form/:id?" key="user-form" exact={true} render={routeprops => (
                                        <Try><UserForm key="user-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/user/form/:id?/userAvailability" key="user-availability" exact={true} render={routeprops => (
                                        <Try><UserAvailability key="user-availability" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/user/form/:id?/employeeCapacitation" key="user-form" exact={true} render={routeprops => (
                                        <Try><EmployeeCapacitation key="user-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/asset/ls" key="asset-ls" exact={true} render={routeprops => (
                                        <Try><AssetList key="asset-ls" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/asset/form/:id?" key="asset-form" exact={true} render={routeprops => (
                                        <Try><AssetForm key="asset-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/asset/form/:id?/history" key="asset-history" exact={true}
                                           render={routeprops => (
                                               <Try><AssetHistory key="asset-history" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/asset/form/:id?/location" key="asset-form-location" exact={true}
                                           render={routeprops => (
                                               <Try><AssetFormLocation key="asset-form" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/user/form/:id?/document" key="user-form-document" exact={true}
                                           render={routeprops => (
                                               <Try><Document key="asset-form-document" pestanya="usuarios" model="user" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/vehicle/ls" key="vehicle-ls" exact={true} render={routeprops => (
                                        <Try><VehicleList key="vehicle-ls" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/vehicle/form/:id?" key="vehicle-form" exact={true} render={routeprops => (
                                        <Try><VehicleForm key="vehicle-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/asset/form/:id?/document" key="asset-form-document" exact={true}
                                           render={routeprops => (
                                               <Try><Document key="asset-form-document" pestanya="equipos" model="asset" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/order/form/:id?/document" key="order-form-document" exact={true}
                                           render={routeprops => (
                                               <Try><Document key="order-form-document" pestanya="pedidos" model="order" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/work-order/form/:id?/document" key="work-order-form-document"
                                           exact={true}
                                           render={routeprops => (
                                               <Try><Document key="work-order-form-document" pestanya="ordenes de trabajo" model="workOrder" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/article/form/:id?/document" key="article-form-document" exact={true}
                                           render={routeprops => (
                                               <Try><Document key="article-form-document" pestanya="repuestos" model="articles"{...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/client/form/:id?/document" key="client-form-document" exact={true}
                                           render={routeprops => (
                                               <Try><Document key="client-form-document" pestanya="clientes" model="client"{...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/schedule/visit/form/:id?/document" key="schedule-visit-form-document" exact={true}
                                           render={routeprops => (
                                               <Try><Document key="schedule-visit-form-document" pestanya="Visitas Preventivas" model="scheduleVisit"{...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/schedule" key="schedule" exact={true} render={routeprops => (
                                        <Try><Schedule key="equipo-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/profile/form" key="profile-form" exact={true} render={routeprops => (
                                        <Try><ProfileForm key="profile-form" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/config/translates" key="config-translates" exact={true} render={routeprops => (
                                        <Try><ConfigTranslates key="config-translates" {...routeprops} {...props} /></Try>
                                    )}/>
                                    <Route path="/order/form/:id?/slots" key="order-form-slots" exact={true}
                                           render={routeprops => (
                                               <Try><OrderFormSlots key="order-form-slots" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/change/password" key="change-password" exact={true}
                                           render={routeprops => (
                                               <Try><ChangePassword key="change-password" {...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/order/view/sumary" key="order-view-sumary" exact={true}
                                           render={routeprops => (
                                               <Try><OrderViewSumary key="order-view-sumary" id={1}{...routeprops} {...props} /></Try>
                                           )}/>
                                    <Route path="/pruebas" key="pruebas" exact={true}
                                           render={routeprops => (
                                               <Try><PruebasForm key="pruebas" {...routeprops} {...props} /></Try>
                                           )}/>
                                    {this.renderMasters()}
                                    <Route render={routeprops => (
                                        <Page404 key="page404" {...routeprops} {...props} />
                                    )}/>
                                </Switch>
                            </RouteContainer>
                        </PoseGroupEmpty>
                    )}/>
                </AppWrapper>
            </BrowserRouter>
        );
    }

    renderMasters() {
        let Try = ErrorBoundary;

        return (
            <React.Fragment>
                <Route path="/typifiedValue/ls" key="typifiedValue-ls" exact={true} render={routeprops => (
                    <Try><TypifiedValueList key="typifiedValue-ls" {...routeprops} {...this.props} /></Try>
                )}/>
                <Route path="/typifiedValue/form/:id?" key="typifiedValue-form" exact={true} render={routeprops => (
                    <Try><TypifiedValueForm key="typifiedValue-form" {...routeprops} {...this.props} /></Try>
                )}/>
            </React.Fragment>
        );
    }
}

export default AppRouter;
