import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ListPageCounts from "./ListPageCounts";
import appState from "./../state/AppState";
import Overlay from "../components/Overlay";
import PropTypes from 'prop-types';
import FavouriteModal from "./FavouriteModal"
import Links from "../util/Links";
import SavedFilterModel from "../models/SavedFilterModel";
import AppLogger from "../util/AppLogger";
import PropsUtil from "../util/PropsUtil";
import withTranslate from "../translator/withTranslate";
import VsfButton from "./VsfButton";
import FilterSharedComponent from "./FilterSharedComponent";
import LeftModalInList from "./LeftModalInList";

@observer
class SearchComponent extends Component {

    static defaultProps = {
        onDownloadExcel: _ => {
        }
    };

    static propTypes = {
        //Acción de descarga de Excel en el listado
        onDownloadExcelOrCsv: PropTypes.func,
        model: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            savedFilterModels: [],
            savedFilterModelsShared: [],
            valueFilterQ: ""
        }
    }

    openModalWithListColumns() {
        this.props.listState.listColumnsSelect.modalOpen = true;
    }

    openModalWithFilter() {
        this.props.listState.listFilter.modalOpen = true;
    }

    reloadPage() {
        console.log('refresh');
        window.location.reload()
    }

    getInFilterValues = (filter) => {

        this.log("SearchComponent.getInFilterValues(filter) =>");
        this.log(filter);

        let filterKeysArray = filter.value.split(",");

        let filterLabelsArray = [];
        if (filter.label != null) {
            filterLabelsArray = filter.label.split("/'").filter((value, key) => (key % 2 != 0));
        } else {
            filterLabelsArray = filter.value.split(",");
        }

        let filterArrayWithoutCommas = [];

        filterLabelsArray.map((label) => {
            filterArrayWithoutCommas.push({ label: label });
        });

        filterKeysArray.map((value, key) => {
            filterArrayWithoutCommas[key].value = value;
        });

        this.log(filterArrayWithoutCommas);

        return filterArrayWithoutCommas;

    };

    getTitle(nameField) {
        let model = this.props.model;
        let result = nameField;
        if (model != null) {
            result = model.getLabelFromFieldName(nameField);
        }
        return result;
    }

    getNameFilter(valueObj) {
        let result = this.getTitle(valueObj.name);
        this.log('getNameFilter', result)
        if (valueObj.op == "GT" || valueObj.op == "GTEQ") {
            result += " (Desde)";
        }
        if (valueObj.op == "LT" || valueObj.op == "LTEQ") {
            result += " (Hasta)";
        }
        return result;
    }

    async reloadFilters() {
        let savedFilterModel = new SavedFilterModel();
        savedFilterModel.modelName = this.props.model.nameMainType;
        savedFilterModel.isShared = false;
        savedFilterModel.createdBy = appState.loginState.userId;
        let savedFilterModels = await savedFilterModel.find();
        let savedFilterModelShared = new SavedFilterModel();
        savedFilterModelShared.modelName = this.props.model.nameMainType;
        savedFilterModelShared.isShared = true;
        let savedFilterModelsShared = await savedFilterModelShared.find();

        this.setState({
            savedFilterModels,
            savedFilterModelsShared
        })

    }

    async componentDidMount() {
        await this.reloadFilters();

    }

   async onClickFavourite(favourite) {

        this.log("onClickFavourite");
        this.log(favourite);

        let configuration = JSON.parse(favourite.configuration);
        this.log(configuration);

        // FILTROS

        this.log("setFilters");
        let filtersArray = [];
        for ([key, favourite] of Object.entries(configuration.currentFilter)) {
            this.log(favourite);
            if (favourite != null) {
                filtersArray.push({
                    fieldKey: favourite.key,
                    fieldName: favourite.name,
                    fieldLabel: favourite.label,
                    fieldValue: favourite.value,
                    filterOperator: favourite.op
                })
            }
        }

        this.propsUtil.changeUrlRequest({ filters: JSON.stringify(filtersArray) });
        this.props.listComponent.setListComponent(filtersArray);

        // COLUMNAS

        console.log("setColumns");

        this.refreshVisibileFields(configuration.columns);
        this.refreshListFieldsVisibility();
        this.refreshSortableFields(configuration.columns);
        this.reorderListFields();

        this.log(this.props.stateListColumnsSelect);

        // Cierra el menu
        appState.layoutState.favouritesleftMenuOpen = false;
        appState.layoutState.favouritesleftMenuOpen = false

    }

    refreshVisibileFields(columns) {

        console.log("refreshVisibileFields");

        let newVisibleFields = {};
        for ([key, column] of Object.entries(columns)) {
            if (column != null) {
                if (column.visible) {
                    newVisibleFields[column.name] = true;
                } else {
                    newVisibleFields[column.name] = false;
                }
            }
        }

        this.log(newVisibleFields);

        this.props.stateListColumnsSelect.visibleFields = newVisibleFields;

    }


    refreshSortableFields(columns) {

        console.log("refreshSortableFields");

        let newSortableFields = [];

        columns.map((value, index) => {
            newSortableFields.push(value.name);
        });

        this.log(newSortableFields);

        this.props.stateListColumnsSelect.sortableFields = newSortableFields;

    }

    /* METODOS COMPARTIDOS CON LISTITEMSSELECT */

    /**
     * Obtiene los datos del orden y visibilidad de las columnas del listado a partir del state
     *
     * @returns {{sortable: *, visible: *}}
     */
    getLocalStorageListFields() {
        let result = {
            sortable: this.props.stateListColumnsSelect.sortableFields,
            visible: this.props.stateListColumnsSelect.visibleFields
        };
        return result;
    }

    setItemOnLocalStorage(key, value) {
        window.localStorage.setItem(key, value);
    }

    /**
     * Actualiza la visibilidad de las columnas una vez hemos presionado un check en el modal de las columnas visibles o al inicio de ejecucion
     */
    refreshListFieldsVisibility() {

        // Primero, recorro los listFiels y por cada uno de ellos, actualizo su visibilidad
        var visibleFields = this.props.stateListColumnsSelect.visibleFields;

        this.props.stateListColumnsSelect.listFields.forEach(function (listField, index) {
            if (listField != null) {
                if (visibleFields[listField.name]) {
                    listField.visible = true;
                } else {
                    listField.visible = false;
                }
            }
        });

        // Por ultimo, guardamos la informacion en LocalStorage
        this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()));
        //console.log("refreshListFieldsVisibility: " + JSON.stringify(this.getLocalStorageListFields()) );
    }

    /**
     * Reordena las columnas del listado despues de un movimiento en el modal o en la carga
     *
     */
    reorderListFields() {

        // Primero, reordenamos las columnas en el listado
        let listFields = [];
        var baseListFields = this.props.stateListColumnsSelect.listFields;
        this.props.stateListColumnsSelect.sortableFields.forEach(function (name, index) {
            //console.log({baseListFields});
            let field = baseListFields.find(listField => listField != null && "" + listField.name === name);
            if (field != null) {
                listFields.push(field);
            }
        });

        // Segundo, guardamos en el state las columnas actualizadas
        this.props.stateListColumnsSelect.listFields = listFields;

        // Por ultimo, guardamos la informacion en LocalStorage
        this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()));

    }

    /* FIN METODOS COMPARTIDOS CON LISTITEMSSELECT */

    updateQ(event) {
        let valueFilterQ = event.target.value;
        this.setState({ valueFilterQ });
    }

    aplicarFiltroQ() {
        return this.propsUtil.changeUrlRequest({ "q": this.state.valueFilterQ })

    }

    onCloseLeftModal() {
        appState.layoutState.favouritesleftMenuOpen = false
    }

    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        console.log("SearchComponent.render");
        let links = new Links(this.props.location);
        const currentFilter = links.getCurrentFilterFromUrl();
        let withoutResults = true;
        if (this.props.withoutResults != null)
            withoutResults = this.props.withoutResults;
        const q = this.propsUtil.getRequest('q');
        let props = this.props;
        return (
            <div className="search">
                <div className="row align-items-center">
                    {withoutResults &&
                    <div className="col-12 col-lg-5 order-2 order-lg-0 mb-3">
                        <ListPageCounts numEntriesPerPage={this.props.numEntriesPerPage} {...props} />
                    </div>
                    }
                    <div className="col-12 col-lg-7">
                        <div
                            className={this.props.withoutFlex ? "justify-content-end flex-wrap " : "d-flex justify-content-end flex-wrap "}>
                            {this.props.includeSearch &&
                            <div className="ml-2 mb-3">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.aplicarFiltroQ();
                                }}>
                                    <div className="input-group">
                                        <input type="search"
                                               className="form-control"
                                               name="q"
                                               defaultValue={q}
                                               placeholder={this.props.t("Buscar")}
                                               onChange={(e) => this.updateQ(e)}
                                               aria-label="Buscar"
                                               aria-describedby="basic-addon2"
                                        />
                                        <div className="input-group-append">
                                            <button type="button" onClick={() => this.aplicarFiltroQ()}
                                                    className="btn-search btn btn-outline-secondary">
                                                <span className="fa fa-search"> </span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            }
                            {this.props.includeFilters &&
                            <div className="ml-2 mb-3">
                                <button onClick={() => this.openModalWithFilter()} type="button"
                                        className="btn btn-outline-secondary m-0.5">
                                    <span className="fa fa-filter"> </span>
                                </button>
                            </div>
                            }
                            {this.props.includeSavedFavourites &&
                            <div className="ml-2 mb-3">
                                <div className="btn-group" role="group"
                                     aria-label="Button group with nested dropdown">
                                    {this.props.refresh &&
                                    <button onClick={() => this.reloadPage()} type="button"
                                            className="btn-refresh btn btn-outline-secondary m-0.5">
                                        <span className="fa fa-sync-alt"> </span>
                                    </button>
                                    }
                                    <div className="btn-group" role="group">
                                        <button onClick={() => {
                                            appState.layoutState.favouritesleftMenuOpen = true
                                        }} id="btnGroupColumns" type="button"
                                                className="btn btn-outline-secondary dropdown-toggle"
                                                data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false"
                                        >
                                            <span className="fa fa-bookmark"> </span>
                                        </button>
                                        <LeftModalInList
                                            isOpen={appState.layoutState.favouritesleftMenuOpen}
                                            title={"Filtros "}
                                            onCloseModal={() => this.onCloseLeftModal()}
                                        >
                                            <FilterSharedComponent
                                                savedFilterModels={this.state.savedFilterModels}
                                                savedFilterModelsShared={this.state.savedFilterModelsShared}
                                                onClickFavourite={(e) => this.onClickFavourite(e)}
                                                stateListColumnsSelect={this.props.stateListColumnsSelect}
                                                reloadFilters={() => this.reloadFilters()}
                                            />

                                        </LeftModalInList>

                                    </div>
                                    {this.props.includeChangeColumns &&
                                    <div className="btn-group" role="group">
                                        <button onClick={() => this.openModalWithListColumns()} id="btnGroupColumns"
                                                type="button"
                                                className="btn btn-outline-secondary dropdown-toggle"
                                                data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false"
                                        >

                                            <span className="fa fa-columns"> </span>
                                        </button>
                                        <div
                                            className="dropdown-menu-columns dropdown-menu dropdown-menu-triangle dropdown-menu-right"
                                            aria-labelledby="btnGroupColumns">
                                            <div className="row row-no-padding">
                                                <div className="col-sm-4 z">
                                                    <div className="form-group">
                                                        <input type="checkbox" name="column_siniestro_id"
                                                               id="column_siniestro_id"
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="column_siniestro_id"
                                                               title="ID Siniestro">ID Siniestro</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.props.includeDownload &&
                                    <div className="btn-group" role="group">
                                        <>
                                            <Overlay show={appState.layoutState.downloadExcelCsv}
                                                     onClick={_ => appState.layoutState.downloadExcelCsv = false} />
                                            {
                                                <div className="col-4 text-right">
                                                    <VsfButton
                                                        onClick={() => appState.layoutState.clickDownloadExcelCsv()}
                                                        classButton={"btn-outline-secondary dropdown-toggle"}
                                                        icon={"fa fa-download"}
                                                        disabled={false}
                                                    />
                                                    {appState.layoutState.downloadExcelCsv &&
                                                    <div className="dropdown-sublist" style={{ zIndex: 100 }}>

                                                        <div className="dropdown-sublist__item ">
                                                            <a onClick={(e) => this.props.onDownloadExcelOrCsv("EXCEL")}>{t("Excel ")}<span>
                                                            <i className="fa fa-file-excel" /></span></a>
                                                        </div>
                                                        <div className="dropdown-sublist__item ">
                                                            <a onClick={(e) => this.props.onDownloadExcelOrCsv("CSV")}>{t("CSV ")}<span>
                                                            <i className="fa fa-file-csv" /></span></a>
                                                        </div>

                                                    </div>
                                                    }
                                                </div>
                                            }
                                        </>

                                    </div>
                                    }
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-12">
                        <div className={this.props.withoutFlex ? "" : "filter-tags"}>
                            <div className="filter-tags__left">
                                <div className="filter-tags__block">
                                    {/*<p id="type-order" className="filter-tags__block__label">tipo orden de trabajo</p>*/}
                                    {Object.entries(currentFilter).map(([index, valueObj]) => (
                                            valueObj.op !== "IN" && valueObj.op !== "STRIN"
                                                ?
                                                <ul key={index} className="filter-tags__block__tags"
                                                    aria-labelledby="type-order">
                                                    <label
                                                        className='m-2 filter-tags__block__label2'>{this.getNameFilter(valueObj)}:</label>
                                                    <li>
                                                        <span className="tag-item tag-item--text">{valueObj.label}</span>
                                                        <span onClick={() => this.props.deleteFilter(valueObj)}
                                                              className="tag-item tag-item--close text-click"
                                                              title="Cerrar etiqueta">
                                                    <span className="fas fa-times"> </span>
                                                </span>
                                                    </li>
                                                </ul>
                                                :
                                                <React.Fragment>
                                                    <label
                                                        className='m-2 filter-tags__block__label2'>{this.getNameFilter(valueObj)}:</label>
                                                    {
                                                        this.getInFilterValues(valueObj).map((filter, index) => {
                                                            return (
                                                                <ul key={index} className="filter-tags__block__tags"
                                                                    aria-labelledby="type-order">

                                                                    <li>
                                                                    <span
                                                                        className="tag-item tag-item--text">{filter.label}</span>
                                                                        <span
                                                                            onClick={() => this.props.deleteFilter(valueObj, filter.value, filter.label)}
                                                                            className="tag-item tag-item--close text-click"
                                                                            title="Cerrar etiqueta">
                                                                <span className="fas fa-times"> </span>
                                                            </span>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                        )
                                    )}
                                </div>
                            </div>
                            {this.props.includeSavedFavourites &&
                            <>
                                {
                                    this.propsUtil.getRequest("filters") != null &&
                                    <div className="filter-tags__right" role="button" tabindex="0">
                                <span href="#" onClick={() => this.props.toggleFavouriteModal()}
                                      className="link-underline">{t("Guardar Filtros")}</span>
                                    </div>
                                }
                            </>
                            }
                        </div>
                    </div>
                </div>
                {this.props.includeSavedFavourites &&
                <FavouriteModal
                    reloadFilters={() => this.reloadFilters()}
                    openModal={this.props.favouriteModalOpen}
                    closeModal={() => this.props.toggleFavouriteModal()}
                    columns={this.props.listState.listColumnsSelect.listFields}
                    currentFilter={new Links(this.props.location).getCurrentFilterFromUrl()}
                    listComponent={this.props.listComponent}
                    model={this.props.model}
                    {...this.props}
                />
                }

            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

};
export default withTranslate(SearchComponent)
