import BaseFieldComponent from "../BaseFieldComponent";
import BaseValidation from "./BaseValidation";

export default class EmailValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect=true;
        if (this.event==="blur") {
            // /^[679][0-9]{8}$/
            let regValidateEmail = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|\""(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            if (!regValidateEmail.test(inputValue)) {
                isCorrect = false;
            }
        }
        this.log("inputValue: "+inputValue +  " args:"+ JSON.stringify(args) +"  isCorrect:"+isCorrect);
        return isCorrect;
    }

}
