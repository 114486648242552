import DocumentModel from "./DocumentModel";
import AbstractModel from "./AbstractModel";
import util from "../util/Util";
import ClientModel from "./ClientModel";
import OrderModel from "./OrderModel";
import AssetModel from "./AssetModel";
import WorkLogModel from "./WorkLogModel";

export default class WorkOrderModel extends AbstractModel {

    id;
    scheduleVisitId;
    status;
    storeId;
    localHour;
    assetId;

    /*Bloque Asset*/
    assetPlate;
    assetSubType;
    assetType;
    statusDispatcherWorkOrder;
    assignedToMainId;
    clientId;
    code;
    contactId;
    createdAt;
    createdBy;
    documents;
    generalDescription;
    orderId;
    priority;
    subType;
    symptom;
    symptomOther;
    type;
    cantidad;
    nextScheduledDate;

    nameMainType = "workOrder";
    graphFindByIdOperation = "workOrder";
    graphFindOperation = "workOrdersConnection";
    graphExportOperation = "workOrdersExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "localHour": "",
            "assetId": { label: "Equipo" },
            "assignedToMainId": { label: "Tecnico Asignado" },
            /*Bloque Asset*/

            "assetPlate": { label: "Nº matricula" },
            "assetSubType": { label: "Subtipo Equipo" },
            "assetType": { label: "Tipo Equipo" },
            "statusDispatcherWorkOrder": "",
            "assignedEndDate": "",
            "clientId": { readonly: true, label: "Cliente" },
            "code": { label: "Número de OT" },
            "contactId": "",
            "generalDescription": { label: "Descripción" },
            "orderId": { label: "Pedido" },
            "priority": "",
            "createdAt": { readonly: true, label: "Creado" },
            "status": { label: "Estado" },
            "storeId": "",
            "subType": { label: "Subtipo OT" },
            "symptom": { label: "Síntoma" },
            "symptomOther": { label: "Otro síntoma" },
            "type": { label: "Tipo OT" },
            "nextScheduledDate": { label: "Próxima planificación" },
        };

        this.addRelatedTableDocuments(result, DocumentModel);
        this.addRelatedTableClients(result, ClientModel);
        this.addRelatedTableOrders(result, OrderModel);
        this.addRelatedTableAssets(result, AssetModel);
        this.addRelatedTableWorkLog(result, WorkLogModel);

        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["code", ...baseFields];
    }

    async getWorkOrdersFromOrderId(orderId) {
            let workOrderQuery = new WorkOrderModel();
        workOrderQuery.orderId = orderId;
        workOrderQuery.addRelatedTable("workLog");
        if (util.hasValue(workOrderQuery.orderId)) {
            let result = await workOrderQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            return result;
        }
    }
}
