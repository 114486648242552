import React, {Fragment} from 'react';
import {observer} from 'mobx-react';
import {DefaultRoute, withRouter} from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import TextAreaComponent from "../../components/fields/TextAreaComponent";
import Select2Component from "../../components/fields/Select2Component";
import {withApollo} from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import PageTitle from "../../components/PageTitle";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import TabsData from "../../layout/TabsData";
import TabsComponent from "../../components/fields/TabsComponent"
import WorkOrderModel from "../../models/WorkOrderModel";
import PropsUtil from "../../util/PropsUtil";
import PropTypes from 'prop-types';
import PageTitleRightModal from "../../components/PageTitleRightModal";
import CreateAndModify from "../../components/fields/CreateAndModify";
import {computed, observable} from "mobx";
import InfoBlock from "../../components/InfoBlock";
import MoreButtons from "../../components/MoreButtons";
import util from "../../util/Util";
import OrderModel from "../../models/OrderModel";
import AppLogger from "../../util/AppLogger";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/PopUp";
import AutoSuggestComponent from "../../components/fields/AutoSuggestComponent"
import AssetModel from "../../models/AssetModel";
import withTranslate from "../../translator/withTranslate";
import SlotModel from "../../models/SlotModel";

@observer
class WorkOrderForm extends BaseForm {

    static defaultProps = {
        fromRightModal: false,
    };

    static propTypes = {
        fromRightModal: PropTypes.bool,
    };
    @observable pedidoCode = "";
    previousRowForDiscard = null;
    controller = null;
    signal = null;

    constructor(props) {
        super(props);
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();

        this.state = {
            changes: [],
            historyVisible: false,
            errorFormulario: false,
            modalFinalizada: false,
        };
        // this.queryFieldWithId = "workOrderId";
    }

    @computed
    get mobxListado() {
        return appState.workOrderState.workOrders;
    }


    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("client");
        return result;
    }


    getModelQuery() {
        let result = new WorkOrderModel();
        //Al hacer las llamadas a Find devuelve también los documentos relacionados
        return result;
    }

    /*
      @computed get graphData() {
      return appState.workOrderState.graphData;
      }

      @computed get graphStatus() {
      return appState.workOrderState.graphStatus;
      }
      */
    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("componentDidMount");
        await appState.clientCacheState.loadClientCache();
        await appState.assetState.getAssets();
        await appState.userCacheState.loadUserCache();

        await appState.typifiedState.loadStatus();

        await appState.typifiedState.loadPriority();
        await appState.typifiedState.loadSymptom();
        await appState.typifiedState.loadWorkOrderType();
        await appState.typifiedState.loadSubTypeWorkOrder();
        // await appState.typifiedState.load();

        await appState.typifiedState.loadAssetType();
        await appState.typifiedState.loadAssetSubtype();
        await appState.typifiedState.loadManufacturer();

        appState.typifiedState.arrayLoadAssetType();
        appState.typifiedState.arrayLoadAssetSubType();
        appState.typifiedState.arrayLoadManufacturer();
        appState.typifiedState.arrayLoadWorkOrderStatus();


        await this.processHistory("dotnetwebapi.Models.WorkOrderModel");
        await this.codigoPedido();

        // Cargamos la workOrder correspondiente para cargar adecuadamente los selects jerarquicos

        let workOrder;
        if (this.previousRowForDiscard != null) {
            workOrder = this.previousRowForDiscard;
        } else {
            workOrder = this.graphDataMainType;
        }

        appState.typifiedState.workOrderTypeSelected = workOrder.type;

        appState.typifiedState.assetTypeSelected = workOrder.assetType;

    }

    async componentWillUpdate() {

        // Cargamos la workOrder correspondiente para cargar adecuadamente los selects jerarquicos

        let workOrder;
        if (this.previousRowForDiscard != null) {
            workOrder = this.previousRowForDiscard;
        } else {
            workOrder = this.graphDataMainType;
        }

        appState.typifiedState.workOrderTypeSelected = workOrder.type;

        appState.typifiedState.assetTypeSelected = workOrder.assetType;

    }

    postFillInputVariables(model) {
        let orderId = this.props.orderId;
        if (orderId != null) {
            model.orderId = orderId;
        }
    }

    async deleteWorkOrder(workOrder, func,) {
        this.log(".deleteWorkOrder() =>");
        this.log(workOrder);

        let modelo = this.getModelQuery();
        modelo.hidrate(workOrder);
        modelo.id = this.getFormId();
        try {
            await modelo.remove();
        } catch (e) {
            this.log("EXCEPCION=>");
            this.log(e);
        }
        return this.propsUtil.changeUrl("/work-order/ls/")

    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        if (this.ifPathStartsWith("/schedule")) {
            await appState.scheduleDropState.getWorkOrders(model.orderId, appState.scheduleDropState.workOrderIds);
            let slotQuery = new SlotModel();
            let slots = await slotQuery.getSlotsFromOrder(model.orderId);
            for (let slot of util.arrayModelToPlainObjects(slots)) {
                if (util.perteneceA(model.id, slot.workOrderIds)) {
                    appState.scheduleDropState.allSlotsDict[slot.id] = slot
                } else if (util.perteneceA("-1", slot.workOrderIds)) {
                    appState.scheduleDropState.allSlotsDict[slot.id] = slot
                }
            }
        }
        //Actualizo el array de workOrders que se visualizan
        appState.layoutState.arrayWithLocations.pop();
        await this.actualizaListadWorkOrders(model, previousId);
        if (this.props.fromRightModal) {
            this.propsUtil.changeUrlRequest({
                "workOrderId": model.id
            });
        }

    }

    async actualizaListadWorkOrders(model, previousId) {
        if (this.props.orderId != null) {
            let workOrderQuery = new WorkOrderModel();
            workOrderQuery.addRelatedTable("documents");
            let workOrder = await workOrderQuery.findById(model.id);
            let workOrderMobx = workOrder.toPlainObject();
            workOrderMobx['documents'] = workOrder.documents;

            if (util.hasValue(previousId)) {
                for (let i = 0; i < appState.workOrderState.workOrders.length; i++) {
                    if (appState.workOrderState.workOrders[i].id == model.id) {
                        appState.workOrderState.workOrders[i] = workOrderMobx
                    }
                }
            } else {
                appState.workOrderState.workOrders.push(workOrderMobx);
            }
        }
    }


    async codigoPedido() {

        let workOrderModel = new WorkOrderModel();
        let workor = await workOrderModel.findById(this.getFormId());
        if (workor != null) {
            let orderModel = new OrderModel();
            let pedido = await orderModel.findById(workor.orderId);
            this.pedidoCode = pedido.code;
        }

    }

    clickErroresFormulario() {
        this.log("clickErroresFormulario()")
        this.setState({
            errorFormulario: false
        })
        this.graphStatus.mutationError = 0;
    }

    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    /**
     * Obtiene los Assets en cada llamada de Autosuggest y los transforma en array
     *
     * @param value
     * @returns Array
     */
    async getAssetsSugestions(value) {

        let assetsArray = [];

        // Abortamos la anterior llamada
        this.abortFetch();

        try {

            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let signal = this.signal;

            // Obtenemos los Assets
            let assetsQuery = new AssetModel();
            assetsQuery.filters = [
                {"fieldName": "plate", "fieldValue": value, "filterOperator": "SUBSTR"},
            ];

            let assets = await assetsQuery.find();

            // Transformamos a formato Autosuggest
            assets.map((asset) => {
                let assetObject = {};
                assetObject.label = asset.plate;
                assetObject.value = asset.plate;
                assetsArray.push(assetObject);
            });


        } catch (e) {
            console.log("Error => " + e);
        }

        return assetsArray;
    }

    onEditarAsset(workorder) {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalassets",
            "assetId": workorder.assetId,
            "workOrderId": workorder.id,
            "rightModalTab": "assets"
        });
    }

    onDeleteWorkOrder() {
        this.setState({rightModal: true, popUp: "eliminar"});
    }

    noDelete() {
        this.setState({rightModal: false, popUp: ""});
    }

    getAssetFromAssetId(assetId) {
        let result = {};
        if (util.hasValue(assetId)) {

            for (let asset of appState.assetState.assets) {
                if (util.hasValue(assetId)) {
                    if (asset.id === assetId) {
                        result = asset;
                        break;
                    }
                }
            }
        }
        return result;
    }

    getAssetFromPlate(valuePlate) {
        let assetSelected;
        if (util.hasValue(valuePlate)) {
            for (let asset of appState.assetState.assets) {
                if (asset.plate === valuePlate) {
                    assetSelected = asset;
                    break;
                }
            }
        } else {
            assetSelected = {};
        }
        return assetSelected;
    }

    /**
     * Metodo que antes de llamar a updateInputEvent al seleccionar un workOrderType, actualiza los selects adecuadamente
     *
     * @param e
     */
    updateInputEventWorkOrderType(e) {
        // Borramos la workOrderType2, ya que al cambiar de workOrderType, las workOrderType2 se recargan
        this.updateInput("subType", "");
        // Actualizamos en el typifiedState la variable asociada a la workOrderType por la cual se filtran las workOrderType2
        appState.typifiedState.workOrderTypeSelected = e.target.value;
        // Ahora si, actualizamos la workOrderType
        this.updateInputEvent(e);
    }


    /**
     * Metodo que antes de llamar a updateInputEvent al seleccionar un assetType, actualiza los selects adecuadamente
     *
     * @param e
     */
    updateInputEventAssetType(e) {
        // Borramos la workOrderType2, ya que al cambiar de workOrderType, las workOrderType2 se recargan
        this.updateInput("assetSubType", "");
        // Actualizamos en el typifiedState la variable asociada a la workOrderType por la cual se filtran las workOrderType2
        appState.typifiedState.assetTypeSelected = e.target.value;
        // Ahora si, actualizamos la workOrderType
        this.updateInputEvent(e);

    }

    render() {
        this.log("appState.typifiedState.loadStatus");
        this.log(appState.typifiedState.statusDispatcher);

        this.propsUtil = new PropsUtil(this.props);
        const {t} = this.props;
        let id = this.getFormId();
        let workOrder;
        if (this.previousRowForDiscard != null) {
            workOrder = this.previousRowForDiscard;
        } else {
            workOrder = this.graphDataMainType;
        }
        // this.log("render()");
        // this.log(workOrder);

        let errorsMapped = this.getGraphErrorsFromStatus();
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""] && errorsMapped[""].map(error => error.message);
            this.state.errorFormulario = true;
        }

        let modalClasses = util.getModalClasses();

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <form
                        className="model-form"
                        name="formulario"
                        noValidate
                        onSubmit={(e) => this.handleFormSubmit(e)}
                    >
                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal
                                    title={"Orden de trabajo"}
                                    onBackModal={() => this.onBackModal()}
                                    subtitle={workOrder.code}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}
                                >
                                    <MoreButtons
                                        actions={
                                            [
                                                {
                                                    title: "Ir a Pedidos", onClick: () => {
                                                        this.propsUtil.changeUrl("/order/ls/")
                                                    }
                                                },
                                                {
                                                    title: "Eliminar Orden de trabajo",
                                                    onClick: () => this.onDeleteWorkOrder()
                                                }
                                            ]
                                        }/>
                                </PageTitleRightModal>
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataWorkOrder()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                            </div>
                            :
                            <PageTitle
                                title={t("Órdenes de Trabajo")}
                                subtitle={workOrder.code || t("Nueva")}
                            > <MoreButtons
                                actions={
                                    [
                                        {
                                            title: "Ir a Pedidos", onClick: () => {
                                                this.propsUtil.changeUrl("/order/ls/")
                                            }
                                        },
                                        {title: "Eliminar Orden de trabajo", onClick: () => this.onDeleteWorkOrder()}
                                    ]
                                }/>
                            </PageTitle>
                        }

                        <div className={'card mb-3'}>

                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id || this.props.id}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Datos Generales"}
                                fromRightModal={this.props.fromRightModal}
                            />
                            }
                            <div className={modalClasses.body}>
                                <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                    error={this.graphStatus.mutationError}
                                    working={this.graphStatus.networkWorking}
                                />
                                <LoadingOrErrorInfo //formLoading={this.graphStatus.formLoading}
                                    formError={this.graphStatus.formError}
                                />
                                {errorsMapped[""] != null ?
                                    <div className="alert alert-danger alertsticky" role="alert">
                                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                                    </div>
                                    :
                                    null
                                }

                                <FormWrapper>
                                    <GroupOfField
                                        title={t('Datos.')}
                                        subtitle={t('Orden de trabajo')}
                                        icon="fas fa-file-alt"

                                    >
                                        <CreateAndModify
                                            updatedAt={workOrder.updatedAt}
                                            updatedBy={workOrder.updatedBy}
                                            createdBy={workOrder.createdBy}
                                            createdAt={workOrder.createdAt}
                                            status={workOrder.status}
                                            code={this.pedidoCode}
                                            getFormId={workOrder.id}
                                            client={workOrder.client != null ? workOrder.client.name : null}
                                        />
                                        <TextAreaComponent
                                            value={workOrder.generalDescription}
                                            title={t('Descripcion de la Avería')}
                                            name={"generalDescription"}
                                            placeholder={t('Ej. No enfría lo suficiente')}
                                            classGroup={'col-12'}
                                            onChange={(e) => this.updateInputEvent(e)}
                                        />

                                        <Select2Component
                                            value={workOrder.statusDispatcherWorkOrder}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"statusDispatcherWorkOrder"}
                                            title={t("Estado")}
                                            clearable={false}
                                            classGroup={"col-md-4"}
                                            options={appState.typifiedState.statusDispatcherForSelect}
                                            errors={errorsMapped.statusDispatcherWorkOrder}
                                        />

                                        <Select2Component
                                            value={workOrder.type}
                                            onChange={(e) => this.updateInputEventWorkOrderType(e)}
                                            name={"type"}
                                            title={t("Tipo Orden de Trabajo")}
                                            classGroup={"col-md-4"}
                                            options={appState.typifiedState.workOrderTypeForSelect}
                                            errors={errorsMapped.type}
                                        />
                                        <Select2Component
                                            value={workOrder.subType}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"subType"}
                                            title={t("Subtipo Orden de Trabajo")}
                                            classGroup={"col-md-4"}
                                            options={appState.typifiedState.subTypeWorkOrdersForSelect}
                                            errors={errorsMapped.subType}
                                        />
                                        <Select2Component
                                            value={workOrder.symptom}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"symptom"}
                                            title={t("Síntomas de la Avería")}
                                            classGroup={"col-12"}
                                            options={appState.typifiedState.symptomForSelect}
                                            errors={errorsMapped.symptom}
                                        />
                                        <InputTypeComponent
                                            value={workOrder.symptomOther}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"symptomOther"}
                                            title={t("Otros Síntomas")}
                                            placeholder={t("Otros Síntomas")}
                                            classGroup={"col-12"}
                                            type={"text"}
                                            errors={errorsMapped.symptomOther}
                                        />

                                        <AutoSuggestComponent
                                            value={this.getAssetFromAssetId(workOrder.assetId).plate}
                                            onChange={(assetSelected) => {
                                                this.handleAutosuggestSelection("assetPlate", assetSelected);
                                                if (workOrder.assetPlate !== '') {
                                                    workOrder.assetId = this.getAssetFromPlate(workOrder.assetPlate).id;
                                                } else {
                                                    workOrder.assetId = "";
                                                }
                                            }}
                                            name={"assetPlate"}
                                            title={t("Número de Equipo ")}
                                            classGroup={"col-md-6 col-lg-4"}
                                            errors={errorsMapped.assetPlate}
                                            info={t('Matrícula o Placa')}
                                            loadSuggestions={(value) => this.getAssetsSugestions(value)}
                                            placeholder={t("Escribe...")}

                                        />

                                        {!util.hasValue(workOrder.assetId)
                                            ?
                                            <Fragment>
                                                <Select2Component
                                                    value={(workOrder.assetType)}
                                                    onChange={(e) => this.updateInputEventAssetType(e)}
                                                    name={"assetType"}
                                                    // readOnly={true}
                                                    title={t("Tipo de Equipo")}
                                                    classGroup={"col-md-6 col-lg-4"}
                                                    placeholder={t("Tipo de Equipo")}
                                                    errors={errorsMapped.assetType}
                                                    options={appState.typifiedState.assetTypesForSelect}
                                                />
                                                <Select2Component
                                                    value={workOrder.assetSubType}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"assetSubType"}
                                                    title={t("Subtipo de Equipo")}
                                                    placeholder={t("Subtipo de Equipo")}
                                                    //readOnly={true}
                                                    classGroup={"col-md-12 col-lg-4"}
                                                    errors={errorsMapped.assetSubType}
                                                    options={appState.typifiedState.assetSubtypeForSelect}
                                                />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <InputTypeComponent
                                                    value={util.getString(appState.typifiedState.arrAssetTypes[this.getAssetFromAssetId(workOrder.assetId).type])}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"assetType"}
                                                    readOnly={true}
                                                    title={t("Tipo de Equipo")}
                                                    classGroup={"col-md-6 col-lg-4"}
                                                    placeholder={t("Tipo de Equipo")}
                                                />
                                                <InputTypeComponent
                                                    value={util.getString(appState.typifiedState.arrAssetSubtypes[this.getAssetFromAssetId(workOrder.assetId).subType])}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"assetSubType"}
                                                    title={t("Subtipo de Equipo")}
                                                    placeholder={t("Subtipo de Equipo")}
                                                    readOnly={true}
                                                    classGroup={"col-md-12 col-lg-4"}
                                                />
                                            </Fragment>
                                        }

                                        <InputTypeComponent
                                            value={util.getString(appState.typifiedState.arrManufacturer[this.getAssetFromAssetId(workOrder.assetId).manufacturer])}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"assetManufacturer"}
                                            title={t("Fabricante")}
                                            classGroup={"col-md-4"}
                                            placeholder={t("Fabricante")}
                                            readOnly={true}
                                            options={appState.typifiedState.manufacturerForSelect}
                                            // errors={errorsMapped.assetManufacturer}
                                        />
                                        <InputTypeComponent
                                            value={util.getString(this.getAssetFromAssetId(workOrder.assetId).model)}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"assetModel"}
                                            title={t("Modelo")}
                                            readOnly={true}
                                            classGroup={"col-md-4"}
                                            placeholder={t("Modelo")}
                                            // errors={errorsMapped.assetModel}
                                        />
                                        <InputTypeComponent
                                            value={util.getString(this.getAssetFromAssetId(workOrder.assetId).serialNumber)}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"assetSerialNumber"}
                                            readOnly={true}
                                            title={t("Número de Serie")}
                                            placeholder={t("Número de Serie")}
                                            classGroup={"col-md-4"}
                                            type={"text"}
                                            info={t('Fabricante')}
                                            // errors={errorsMapped.assetSerialNumber}
                                        />
                                        <InputTypeComponent
                                            value={util.getString(this.getAssetFromAssetId(workOrder.assetId).location)}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"location"}
                                            title={t("Ubicacion del Equipo")}
                                            readOnly={true}
                                            placeholder={t("Ubicacion del Equipo")}
                                            classGroup={"col-md-6"}
                                            type={"text"}
                                            // errors={errorsMapped.location}
                                        />
                                        <InputTypeComponent
                                            value={util.getString(this.getAssetFromAssetId(workOrder.assetId).posAddress)}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"assetLocation"}
                                            title={t("Emplazamiento")}
                                            placeholder={t("Emplazamiento")}
                                            readOnly={true}
                                            classGroup={"col-md-6"}
                                            type={"text"}
                                            info={t('Lugar del Equipo dentro de las Instalaciones del Cliente')}
                                            // errors={errorsMapped.assetLocation}
                                        />


                                        <div className="col-xl-2 text-right">
                                            <div className="button-form-group">
                                                {util.hasValue(workOrder.assetId) ?
                                                    <span className="link-underline ico ico--edit"
                                                          onClick={() => this.onEditarAsset(workOrder)}
                                                    >{t("Editar Datos del equipo")}</span>
                                                    :
                                                    <span className="link-underline disabled prohibido ico ico--edit"
                                                    >{t("Editar Datos del equipo")}</span>
                                                }
                                            </div>
                                        </div>

                                    </GroupOfField>
                                </FormWrapper>
                                {this.props.fromRightModal ?
                                    <div className=" fixed-bottom">
                                        <FormButtons id={id} formStatus={this.graphStatus}/>
                                    </div>
                                    :
                                    <FormButtons id={id} formStatus={this.graphStatus}/>
                                }
                                <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                                            onCloseModal={() => this.setState({
                                                rightModal: false,
                                                popUp: ""
                                            })}
                                >
                                    {this.state.popUp == "eliminar" &&
                                    <PopUp
                                        title={t('Eliminar')}
                                        subtitle={t('Orden de trabajo ' + workOrder.code)}
                                        icon="fas fa-exclamation-triangle"
                                        text={t('¿Estas Seguro de Eliminar esta orden de trabajo y todos sus datos relaccionados?')}
                                        label1={t('No eliminar')}
                                        label2={t('Eliminar')}
                                        onClick1={() => this.noDelete()}
                                        onClick2={(workOrder) => this.deleteWorkOrder(workOrder)}
                                    >
                                    </PopUp>
                                    }
                                </AlertModal>
                            </div>
                        </div>

                    </form>
                </InfoBlock>
            </div>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(withApollo(withTranslate(WorkOrderForm)));

