import { observable } from 'mobx';
import util from "../util/Util";
import BaseModelState from "./BaseModelState";
import DateRangesModel from "../models/DateRangesModel";


class DateRangesState extends BaseModelState {

    @observable dateRanges = [];

    async reloadDatesFromUserId(userId) {
        let dateRangesQuery = new DateRangesModel();
        dateRangesQuery.userId = userId;
        if (util.hasValue(dateRangesQuery.userId)) {
            let result = await dateRangesQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.dateRanges = result;
        }
    }


}

export default DateRangesState;
