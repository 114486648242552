import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, toJS } from "mobx";
import { withApollo } from "react-apollo";
import OrderForm from "../pages/orders/OrderForm";
import withTranslate from "../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import WorkOrderModel from "../models/WorkOrderModel";
import OrderModel from "../models/OrderModel";
import RightModalInList from "../subpages/RightModalInList";
import PropsUtil from "../util/PropsUtil";
import OrderFormSlots from "../pages/orders/OrderFormSlots"
import OrderFormArticles from "../pages/orders/OrderFormArticles";
import OrderFormWorkOrders from "../pages/orders/OrderFormWorkOrders";
import PropTypes from 'prop-types';
import ClientContacts from "../pages/clients/ClientContacts";
import ClientForm from "../pages/clients/ClientForm";
import ClientSchedule from "../dndrop/clientSchedule/ClientSchedule";
import AppLogger from "../util/AppLogger";
import WorkOrderForm from "../pages/workOrders/WorkOrderForm";
import WorkOrderFormSlots from "../pages/workOrders/WorkOrderFormSlots";
import OrderHistory from "../pages/orders/OrderHistory";
import Document from "../pages/Document";
import WorkOrderFormItems from "../pages/workOrders/WorkOrderFormArticles";
import WorkOrderHistory from "../pages/workOrders/WorkOrderHistory";
import ClientHistory from "../pages/clients/ClientHistory";
import AssetForm from "../pages/assets/AssetForm";
import AssetFormLocation from "../pages/assets/AssetFormLocation";
import AssetHistory from "../pages/assets/AssetHistory";
import AssetModel from "../models/AssetModel";
import appState from "../state/AppState";
import AlertModal from "../subpages/AlertModal";
import PopUp from "./PopUp";
import ArticleModel from "../models/ArticleModel";
import ArticleHistory from "../pages/articles/ArticleHistory";
import ArticleFormLocation from "../pages/articles/ArticleFormLocation";
import ArticleForm from "../pages/articles/ArticleForm";

/**
 * Muestra las pestañas que nos haya solicitado a traves de las distintaws URLs de la aplicación
 */
@observer
class RightModals extends Component {

    static defaultProps = {};

    static propTypes = {
        orderInitial: PropTypes.object, //La pantalla está relacionada con un pedido
        // Se precarga la información en este objeto, pero se debe consultar desde el campo orderId
        text: PropTypes.string,
        workOrderInitial: PropTypes.object, //La pantalla está relacionada con una orden de trabajo.
        assetInitial: PropTypes.object, //La pantalla está relacionada con una orden de trabajo.
        articleInitial: PropTypes.object, //La pantalla está relacionada con una orden de trabajo.
    };
    @observable    order = {};
    @observable    workOrder = {};
    @observable    asset = {};
    @observable    article = {};
    @observable    gqlErrors = null;

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.reloadWithProps(this.props);
    }


    async reloadWithProps(props) {
        await this.reloadWithPropsOrder(props);
        await this.reloadWithPropsWorkOrder(props);
        await this.reloadWithPropsAsset(props);
        await this.reloadWithPropsArticles(props);
    }

    async reloadWithPropsArticles(props) {
        let articleId = this.propsUtil.getRequest("articleId");
        if (props.articleInitial != null) {
            this.article = props.articleInitial;
        }
        if (props.articleId !== articleId) {
            let articleQuery = new ArticleModel();
            if (articleId != null) {
                let articleModel = await articleQuery.findByIdNotNull(articleId);
                this.article = articleModel.toPlainObject();
            } else {
                this.article = null
            }
        }
    }

    async reloadWithPropsWorkOrder(props) {
        let workOrderId = this.propsUtil.getRequest("workOrderId");
        if (props.workOrderInitial != null) {
            this.workOrder = props.workOrderInitial;
        }
        if (props.workOrderId !== workOrderId) {
            let workOrderQuery = new WorkOrderModel();
            if (workOrderId != null) {
                let workOrderModel = await workOrderQuery.findById(workOrderId);
                this.workOrder = workOrderModel.toPlainObject();
            } else {
                this.workOrder = null
            }
        }
    }

    async reloadWithPropsAsset(props) {
        let assetId = this.propsUtil.getRequest("assetId");
        if (props.assetInitial != null) {
            this.asset = props.assetInitial;
        }
        if (props.assetId !== assetId) {
            let assetQuery = new AssetModel();
            if (assetId != null) {
                let assetModel = await assetQuery.findById(assetId);
                this.asset = assetModel.toPlainObject();
            } else {
                this.asset = null
            }
        }
    }

    async reloadWithPropsOrder(props) {
        let orderId = this.propsUtil.getRequest("orderId");
        if (props.orderInitial != null) {
            this.order = props.orderInitial;
        }
        if (props.orderId !== orderId) {
            let orderQuery = new OrderModel();
            if (orderId != null) {
                let orderModel = await orderQuery.findById(orderId);
                this.order = orderModel.toPlainObject();
            } else {
                this.order = null
            }
        }
    }

    onCloseModal() {
        this.log("onCloseModal");
        if (!appState.layoutState.formWithoutChanges && !appState.layoutState.loadingForm) {
            appState.layoutState.modalChangeOpen = true;

        } else {
            appState.layoutState.arrayWithLocations.pop();
            this.props.history.push(appState.layoutState.arrayWithLocations.pop())
            appState.layoutState.modalChangeOpen = false;
        }
        if (appState.layoutState.slotview == false) {
            appState.layoutState.backgroundScheduled = 0
        }
    }

    volver() {
        appState.layoutState.modalChangeOpen = false;

    }

    componentWillReceiveProps(props) {
        this.reloadWithProps(props);
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let { t } = this.props;
        let props = this.props;

        return (
            <React.Fragment>
                {appState.layoutState.modalChangeOpen &&
                <AlertModal isOpen={appState.layoutState.modalChangeOpen}
                            onCloseModal={() => {
                                appState.layoutState.formWithoutChanges = false;
                                this.onCloseModal()
                            }}
                >
                    <PopUp
                        title={t('Has salido sin guardar')}
                        icon="fas fa-exclamation-triangle"
                        text={t('¿Quieres guardar los cambios? Los cambios se perderán si no lo guardas')}
                        label1={'Salir sin guardar'}
                        label2={'Volver'}
                        labelGuardar={true}
                        onClick1={() => {
                            appState.layoutState.formWithoutChanges = true;
                            this.onCloseModal()
                        }}
                        onClick2={() => {
                            this.volver()
                        }}
                    />
                </AlertModal>
                }

                <RightModalInList
                    isOpen={this.propsUtil.getRequest("rightModal") === "modalassets"}
                    onCloseModal={() => this.onCloseModal()}
                >
                    {this.propsUtil.getRequest("rightModalTab") === "assets" &&
                    <AssetForm
                        key={this.asset && this.asset.id}
                        id={this.asset && this.asset.id}
                        fromRightModal={true} {...props}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "general" &&
                    <AssetForm
                        key={this.asset && this.asset.id}
                        id={this.asset && this.asset.id}
                        fromRightModal={true} {...props}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "location" &&
                    <AssetFormLocation
                        key={this.asset && this.asset.id}
                        id={this.asset && this.asset.id}
                        fromRightModal={true} {...props}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "history" &&
                    <AssetHistory
                        key={this.asset && this.asset.id}
                        id={this.asset && this.asset.id}
                        fromRightModal={true} {...props}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "document" &&
                    <Document
                        key={this.asset && this.asset.id}
                        id={this.asset && this.asset.id}
                        workOrderId={this.workOrder && this.workOrder.assetId}
                        // assetId={}
                        fromRightModal={true}
                        pestanya={"equipos"}
                        model="asset"
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                </RightModalInList>

                <RightModalInList
                    isOpen={this.propsUtil.getRequest("rightModal") === "modalarticles"}
                    onCloseModal={() => this.onCloseModal()}
                >
                    {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                    <ArticleForm
                        key={this.article && this.article.id}
                        id={this.article && this.article.id}
                        dataInitial={this.article}
                        fromRightModal={true}
                        {...props}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "location" &&
                    <ArticleFormLocation
                        key={this.article && this.article.id}
                        id={this.article && this.article.id}
                        articleId={this.article && this.articleid}
                        dataInitial={toJS(this.article)}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "history" &&
                    <ArticleHistory
                        key={this.article && this.article.id}
                        id={this.article && this.article.id}
                        articleId={this.article && this.article.id}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "document" &&
                    <Document
                        key={this.article && this.article.id}
                        id={this.article && this.article.id}
                        articleId={this.article && this.article.id}
                        fromRightModal={true}
                        pestanya={"repuestos"}
                        model="articles"
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                </RightModalInList>


                <RightModalInList
                    isOpen={this.propsUtil.getRequest("rightModal") === "modalorderls"}
                    onCloseModal={() => this.onCloseModal()}
                >
                    {this.propsUtil.getRequest("rightModalTab") === "workorders" &&
                    <OrderFormWorkOrders
                        key={this.order && this.order.id}
                        id={this.order && this.order.id}
                        fromRightModal={true} {...props}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "order" &&
                    <OrderForm
                        key={this.order && this.order.id}
                        id={this.order && this.order.id}
                        orderId={this.order && this.order.id}
                        dataInitial={this.order && this.order}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "slots" &&
                    <OrderFormSlots
                        key={this.order && this.order.id}
                        id={this.order && this.order.id}
                        orderId={this.order && this.order.id}
                        dataInitial={toJS(this.order)}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                    <OrderFormArticles
                        key={this.order && this.order.id}
                        id={this.order && this.order.id}
                        orderId={this.order && this.order.id}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "history" &&
                    <OrderHistory
                        key={this.order && this.order.id}
                        id={this.order && this.order.id}
                        orderId={this.order && this.order.id}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "document" &&
                    <Document
                        key={this.order && this.order.id}
                        id={this.order && this.order.id}
                        orderId={this.order && this.order.id}
                        fromRightModal={true}
                        pestanya={"pedidos"}
                        model="order"
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                </RightModalInList>


                <RightModalInList
                    isOpen={this.propsUtil.getRequest("rightModal") === "modalclient"}
                    onCloseModal={() => this.onCloseModal()}
                >
                    {this.propsUtil.getRequest("rightModalTab") === "contacts" &&
                    <ClientContacts
                        orderId={this.propsUtil.getRequest("orderId")}
                        clientId={this.propsUtil.getRequest("idOrderClient")}
                        key={this.order && this.order.clientId}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "general" &&
                    <ClientForm
                        key={this.order && this.order.clientId}
                        id={this.order && this.order.clientId}
                        clientId={this.propsUtil.getRequest("idOrderClient")}
                        orderId={this.order && this.order.id}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "prevent" &&
                    <ClientSchedule
                        key={this.order && this.order.clientId}
                        orderId={this.order && this.order.id}
                        id={this.order && this.order.clientId}
                        clientId={this.propsUtil.getRequest("idOrderClient")}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "history" &&
                    <ClientHistory
                        key={this.order && this.order.clientId}
                        id={this.order && this.order.clientId}
                        clientId={this.propsUtil.getRequest("idOrderClient")}
                        orderId={this.order && this.order.id}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "document" &&
                    <Document
                        key={this.order && this.order.clientId}
                        id={this.order && this.order.clientId || this.propsUtil.getRequest("idOrderClient")}
                        orderId={this.order && this.order.id}
                        clientId={this.propsUtil.getRequest("idOrderClient")}
                        fromRightModal={true}
                        pestanya={"clientes"}
                        model="client"
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                </RightModalInList>

                <RightModalInList
                    isOpen={this.propsUtil.getRequest("rightModal") === "modalworkorderls"}
                    onCloseModal={() => this.onCloseModal()}
                >
                    {this.propsUtil.getRequest("rightModalTab") === "workorder" &&
                    <WorkOrderForm
                        key={this.workOrder && this.workOrder.id}
                        orderId={this.order && this.order.id ||this.propsUtil.getRequest("orderId") }
                        id={this.workOrder && this.workOrder.id}
                        workOrderId={this.workOrder && this.workOrder.id}
                        dataInitial={toJS(this.workOrder)}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "schedule" &&
                    <WorkOrderFormSlots
                        id={this.workOrder.id}
                        workOrderId={this.workOrder && this.workOrder.id}
                        key={this.workOrder && this.workOrder.id}
                        dataInitial={toJS(this.workOrder)}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }

                    {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                    <WorkOrderFormItems
                        id={this.workOrder && this.workOrder.id}
                        fromRightModal={true}
                        key={this.workOrder && this.workOrder.id}
                        dataInitial={toJS(this.workOrder)}
                        workOrderId={this.workOrder && this.workOrder.id}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "history" &&
                    <WorkOrderHistory
                        id={this.workOrder && this.workOrder.id}
                        workOrderId={this.workOrder && this.workOrder.id}
                        key={this.workOrder && this.workOrder.id}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "document" &&
                    <Document
                        id={this.workOrder && this.workOrder.id}
                        workOrderId={this.workOrder && this.workOrder.id}
                        key={this.workOrder && this.workOrder.id}
                        fromRightModal={true}
                        orderId={this.order && this.order.id}
                        model="workOrder"
                        pestanya={"ordenes de trabajo"}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                </RightModalInList>
            </React.Fragment>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(withApollo(withTranslate(RightModals, "RightModals")));
