import BaseValidation from "./BaseValidation";

export default class NumberValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect=true;
        if (this.event==="change") {
            // /^[679][0-9]{8}$/
            let regValidateNumber = /^[0-9]{0,8}$/
;            if (!regValidateNumber.test(inputValue)) {
                isCorrect = false;
            }
        }
        return isCorrect;
    }
}
