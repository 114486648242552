import OrderModel from "./OrderModel";
import AbstractModel from "./AbstractModel";
import WorkOrderModel from "./WorkOrderModel";


export default class SlotModel extends AbstractModel {

    static MAIN="MAIN";
    static SUPPORT="SUPPORT";

    id;
    orderId;
    scheduledDuration;
    scheduledTime;
    scheduledDate;
    slotUserRole;
    userId;
    createdByPreventive;
    workOrderIds;
    isDateTimeFixed;
    isUserFixed;
    isDurationEdited;
    comments;
    clientId;
    scheduledEndTime;
    isFinished;

    assignedSlotMainId;
    typeWorkOrder;

    nameMainType = "slot";
    graphFindByIdOperation = "slot";
    graphFindOperation = "slotsConnection";
    graphExportOperation = "slotsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "orderId": "",
            "scheduledDuration": "",
            "scheduledTime": { type: "DateTimeField" },
            "slotUserRole": "",
            "scheduledDate": { readonly: true },
            "userId": "",
            "createdByPreventive": "",
            "comments": "",
            "isDurationEdited": "",
            "scheduledEndTime": { readonly: true },
            "isUserFixed": "",
            "isFinished": { readonly: true },
            "isDateTimeFixed": "",
            "clientId":  { readonly: true },
            "workOrderIds": "",
            "typeWorkOrder": { readonly: true },
            "assignedSlotMainId": "",
        };
        this.addRelatedTableOrders(result, OrderModel);
        this.addRelatedTableWorkOrder(result, WorkOrderModel);
        return result;
    }

    async getSlots() {
        let slotsQuery = new SlotModel();
        return await slotsQuery.find();
    }

    async getSlotsFromOrder(orderId) {
        let result = [];
        if (orderId != null) {
            let slotsQuery = new SlotModel();
            slotsQuery.addRelatedTable("workOrder");
            slotsQuery.orderId = orderId;
            //slotsQuery.workOrderIds="40";
            result = await slotsQuery.find();
        }
        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["typeWorkOrder", ...baseFields];
    }


};





















