import React, { Component } from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import util from "../../util/Util";

@observer
class CreatedByListItem extends Component {
    render() {
        let props = this.props;
        let value = appState.userCacheState.getUserById(props.value).firstName != null ? appState.userCacheState.getUserById(props.value).firstName : "";
        value += appState.userCacheState.getUserById(props.value).lastName != null ? " " + appState.userCacheState.getUserById(props.value).lastName : "";

        if (!util.hasValue(value)) {
            value = "No asignado";
        }

        return (
            <div>
                <div>{value}</div>
            </div>
        );
    }
}

export default CreatedByListItem
