import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import Toy from "./Toy";
import appState from "../../state/AppState";
import {observer} from 'mobx-react';
import { findDOMNode } from 'react-dom';

const Types = {
    ITEM: 'toy'
}


const itemTarget = {
    hover(props, monitor, component) {
        if (monitor.isOver({ shallow: false })) {
            console.log("itemTarget.hover1 SHALLOW");
            return;
        } else {
            console.log("itemTarget.hover1 NOT SHALLOW");
        }
        console.log({props});
        console.log({component});

        if (!component) {
            return null
        }
        let item = monitor.getItem();
        console.log({item});

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        console.log({dragIndex,hoverIndex});
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()
        console.log({hoverBoundingRect});

    },
    drop(props, monitor, component) {
        console.log("drop");
        // Obtain the dragged item
        const orderFromProps = monitor.getItem();
        console.log("drop=>");
        console.log(orderFromProps);
        console.log({props});
        console.log({component});


        console.log({props});
        let ordersWithName = appState.preventOrders.filter(order=>order.name==orderFromProps.name);
        console.log("endDrag length:"+ordersWithName.length);
        ordersWithName[0].date="20190101";
    }
}


function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class Box extends Component {


    render() {

        let ordersWithDate = appState.preventOrders.filter(order=>order.date!=null);

        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div style={{width:400, height:400, backgroundColor:'#aaaaaa'}}>

                {ordersWithDate.map(order=>(
                    <Toy name={order.name} index={order.index}>

                    </Toy>
                ))}
            </div>
        )
    }
}
export default DropTarget(Types.ITEM, itemTarget, collect)(Box)