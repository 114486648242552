import { observable } from 'mobx';
import util from "../util/Util";
import WorkOrderCartArticleModel from "../models/WorkOrderCartArticleModel";

class WorkOrderCartArticlesState {
    @observable workOrderArticles = [];

    async reloadOrderArticlesFromOrderId(orderId) {
        console.log("reloadContactsFromOrderId:" + orderId);
        let workOrderArticlesQuery = new WorkOrderCartArticleModel();
        workOrderArticlesQuery.orderId = orderId;
        if (util.hasValue(workOrderArticlesQuery.orderId)) {
            let result = await workOrderArticlesQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.workOrderArticles = result;
        }
    }
    async reloadOrderArticlesFromWorkOrderId(workOrderId) {
        console.log("OrderArticleState.reloadContactsFromClientId workOrderId:"+workOrderId);
        let workOrderArticlesQuery = new WorkOrderCartArticleModel();
        workOrderArticlesQuery.workOrderId = workOrderId;
        if (util.hasValue(workOrderArticlesQuery.workOrderId)) {
            let result = await workOrderArticlesQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.workOrderArticles = result;
        }
    }
}

export default WorkOrderCartArticlesState;
