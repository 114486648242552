import React from 'react';
import {observer} from 'mobx-react';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import BaseEditableRow from "../base/BaseEditableRow";
import {computed} from "mobx";
import appState from "../../state/AppState";
import Select2Component from "../../components/fields/Select2Component";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import AssetCodesModel from "../../models/AssetCodesModel";
import util from '../../util/Util';


@observer
class AssetCodeForm extends BaseEditableRow {

    constructor(props) {
        super(props);
        this.foreingKeyfield = "assetId";
    }

    @computed
    get mobxListado() {
        return appState.assetCodesState.assetCodes;
    }

    async componentDidMount() {
        await super.componentDidMount();

        await appState.typifiedState.loadManufacturer();
        await appState.typifiedState.loadAssetCodes();
        await appState.typifiedState.arrayLoadManufacturer();
        await appState.typifiedState.arrayLoadAssetCodes();

    }

    getManufacurerFromId(typifiedId){
        let result ="";
        if(util.hasValue(typifiedId)){
            result = appState.typifiedState.arrManufacturer[typifiedId];
            if(util.hasValue(result)){
                return result;
            }
        }
        return result;
    }

    getTypeCodeFromId(typifiedId){
        let result ="";
        if(util.hasValue(typifiedId)){
            result = appState.typifiedState.arrAssetCodes[typifiedId];
            if(util.hasValue(result)){
                return result;
            }
        }
        return result;
    }

    setMobxListado(newValue) {
        appState.assetCodesState.assetCodes = newValue;
    }

    getModelTable() {
        return new AssetCodesModel();
    }

    render() {
        let assetCode = this.props.row;

        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr className={assetCode.isEditing ? "editing" : ""}>
                    <td>
                        <InputTypeComponent
                            value={assetCode.code}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"code"}
                            showView={!assetCode.isEditing}
                            className="form-control"
                        />
                    </td>
                    <td>
                        {assetCode.isEditing ?
                            <Select2Component
                                value={assetCode.type}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"type"}
                                options={appState.typifiedState.assetCodesForSelect}
                                showView={!assetCode.isEditing}
                            />
                            :
                            <div>
                                {this.getTypeCodeFromId(assetCode.type)}
                            </div>
                        }
                    </td>
                    <td>
                        {assetCode.isEditing ?
                            <Select2Component
                                value={assetCode.providerName}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"providerName"}
                                options={appState.typifiedState.manufacturerForSelect}
                                showView={!assetCode.isEditing}
                            />
                            :
                            <div>
                                {this.getManufacurerFromId(assetCode.providerName)}
                            </div>
                        }
                    </td>
                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default AssetCodeForm;
