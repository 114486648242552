import React, { Component } from 'react';
import VsfButton from "../components/VsfButton";
import withTranslate from "../translator/withTranslate";

class FilterWrapper extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="modal-push" style={{ display: "block" }}>
                <div className="modal-head">
                    <div className="row">
                        <div className="col-10 text-left">
                            <div className="modal-title">
                                <p>{t("filtros ")}<span>{t(this.props.title)}</span></p>
                            </div>
                        </div>
                        <div className="col-2 text-right">
                            <button type="button" className="close" aria-label="Close"
                                    onClick={() => this.props.onCloseModal()}
                            >
                                <span className="fas fa-times"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={this.props.modalBody  || "modal-body"}>
                    <div className="row">
                        {this.props.children}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="mb-3 text-right">
                        <VsfButton
                            classButton="btn--loader"
                            onClick={() => this.props.onAplicarFiltros()}
                            label={"Aplicar Filtros"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(FilterWrapper)
