import AbstractModel from "./AbstractModel";


export default class WorkLogModel extends AbstractModel {


    id;
    userId;
    action;
    time;
    slotId;
    workOrderId;
    orderId;
    clientId;
    data;

    nameMainType = "workLog";
    graphFindByIdOperation = "workLog";
    graphFindOperation = "workLogsConnection";
    graphExportOperation = "workLogExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "userId": "",
            "action": "",
            "slotId": "",
            "workOrderId": "",
            "orderId": "",
            "clientId": "",
            "time":"",
            "data": "",
        };
        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return [ ...baseFields];
    }


}
