import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import { observer } from 'mobx-react';
import SlotModel from "../../models/SlotModel";
import AppLogger from "../../util/AppLogger";
import appState from '../../state/AppState';


const Types = {
    ITEM: 'workUnit'
};

class EmptyDropHandler {
    async drop(props, monitor, component) {
        log("EmptyDrop.drop");
        const propsFromVisitDragged = monitor.getItem();
        let slotObservable = appState.scheduleDropState.allSlotsDict[propsFromVisitDragged.slotId];
        if (slotObservable.scheduledTime != null) {
            log("EmptyDrop.Dentro");
            slotObservable.scheduledTime = "";
            slotObservable.userId = "";
            let slot = new SlotModel();
            slot.hidrate(slotObservable);
            await slot.persist();
            //Si envio comillas simples, cuando digo si un slot esta asignado o no(cambio color a rojo) no me recoge
            //como si fuese null, me recoge "", por eso no cambia a azul
            slot.scheduledTime = null;
            slot.userId = null;
            appState.scheduleDropState.allSlotsDict[propsFromVisitDragged.slotId] = slot;
            if (!appState.scheduleDropState.flagSlots) {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(props.optionTime);
            } else {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
            }
        }
    }
}


function log(msg) {
    AppLogger.get().debug(msg, this);
}

let emptyDropHandler = new EmptyDropHandler();


const itemTarget = {
    drop(props, monitor, component) {
        emptyDropHandler.drop(props, monitor, component);
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class EmptyDrop extends Component {

    render() {
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div className="empty-drop">
                {this.props.children}
            </div>
        )
    }
}

export default DropTarget(Types.ITEM, itemTarget, collect)(EmptyDrop)
