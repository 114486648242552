import moment from './moment';

class Util {

    /**
     * La duración es de tipo 01:00 (hh:mm)
     * @param strDuracion
     * @returns {*}
     */
    getDurationFromApi(strDuracion) {
        let partes = strDuracion.split(":");
        let duracion = 0;
        try {
            duracion = parseInt(partes[0]) * 60 + parseInt(partes[1]);
        } catch (e) {
        }
        return duracion + " min";
    }

    /**
     * Devuelve las fechas en formato "8/11/17"
     * strDate es en formato AAAAMMDD
     */
    getFechaForRequestBookingApi(strDate) {
        let mDate = moment(strDate, 'YYYYMMDD');
        let result = mDate.format("DD/MM/YYYY");
        return result;
    }

    /**
     *
     * @param isoDate En formato ISO: 2019-06-20T12:46:19Z
     */
    localizeIsoDate(isoDate) {
        let result = "";
        if (this.hasValue(isoDate)) {
            let mDate = moment(isoDate, 'YYYY-MM-DD');
            result = mDate.format("DD/MM/YYYY");
        }
        return result;
    }

    /**
     * Devuelve una cadena con el numero "numero" y con el tamaño size añadiendo 0 al inicio para conseguir el tamaño
     * Ejemplo numDigitos(5,4)=> "0005"
     * @param numero
     * @param size
     * @returns {*}
     */
    numDigitos(numero, size) {
        let i;
        let res = numero;
        for (i = 0; i < size; i++) res = "0" + res;
        return right(res, size);
    }

    right(cadena, size) {
        return cadena.substring(cadena.length - size);
    }

    left(cadena, size) {
        return cadena.substr(0, size);
    }

    //timezone="Europe/Madrid";
    //timezone="Europe/Lisbon";
    getTimezone() {
        let timezone = window.localStorage.getItem('currentTimeZone');
        if (timezone == null) {
            timezone = "Europe/Lisbon";
        }
        return timezone;
    }

    //timezone="Atlantic/Azores";
    //timezone="America/Toronto";
    //PT	+3843-00908	Europe/Lisbon	mainland	+00:00	+01:00
    //PT	+3238-01654	Atlantic/Madeira	Madeira Islands	+00:00	+01:00
    //PT	+3744-02540	Atlantic/Azores	Azores	−01:00	+00:00
    /**
     * Deveulve la fecha hasta el segundo transformado al timezone del usuario
     * @param isoDate En formato ISO: 2019-06-20T12:46:19Z
     */
    localizeIsoDateHour(isoDate) {
        let result = "";
        if (this.hasValue(isoDate)) {
            let mDate = moment(isoDate);
            result = mDate.tz(this.getTimezone()).format("DD/MM/YYYY HH:mm:ss");
        }
        //return isoDate + " **" + this.getTimezone() + " ** " + result;
        return result;
    }

    localizeIsoDateMinutes(isoDate) {
        let result = "";
        if (this.hasValue(isoDate)) {
            let mDate = moment(isoDate);
            result = mDate.tz(this.getTimezone()).format("DD/MM/YYYY HH:mm");
        }
        //return isoDate + " **" + this.getTimezone() + " ** " + result;
        return result;
    }

    getDelim(cadena, delim, num) {
        let res = "";
        try {
            let v = cadena.split(delim);
            res = v[num];
        } catch (e) {
        }
        return res;
    }

    left(cadena, size) {
        let result = "";
        if (cadena != null) {
            if (size > 0) {
                if (cadena.length >= size) {
                    result = cadena.substring(0, size);
                }
            }
        }
        return result;
    }

    rad2degr(rad) {
        return rad * 180 / Math.PI;
    }

    degr2rad(degr) {
        return degr * Math.PI / 180;
    }

    /**
     * @param latLngInDeg array of arrays with latitude and longtitude
     *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
     *   [longtitude2] ...]
     *
     * @return array with the center latitude longtitude pairs in
     *   degrees.
     */
    getGeoLatLngCenter(latLngInDegr) {
        let LATIDX = "geo_latitude";
        let LNGIDX = "geo_longitude";
        let sumX = 0;
        let sumY = 0;
        let sumZ = 0;

        for (let i = 0; i < latLngInDegr.length; i++) {
            let lat = this.degr2rad(latLngInDegr[i][LATIDX]);
            let lng = this.degr2rad(latLngInDegr[i][LNGIDX]);
            // sum of cartesian coordinates
            sumX += Math.cos(lat) * Math.cos(lng);
            sumY += Math.cos(lat) * Math.sin(lng);
            sumZ += Math.sin(lat);
        }

        let avgX = sumX / latLngInDegr.length;
        let avgY = sumY / latLngInDegr.length;
        let avgZ = sumZ / latLngInDegr.length;

        // convert average x, y, z coordinate to latitude and longtitude
        let lng = Math.atan2(avgY, avgX);
        let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
        let lat = Math.atan2(avgZ, hyp);

        return ({ geo_latitude: this.rad2degr(lat), geo_longitude: this.rad2degr(lng) });
    }

    getGeoDelta(center, latLngInDegr) {
        let LATIDX = "geo_latitude";
        let LNGIDX = "geo_longitude";
        let result = {};
        result[LATIDX] = 0.001;
        result[LNGIDX] = 0.001;
        for (let i = 0; i < latLngInDegr.length; i++) {
            let latDiff = Math.abs(latLngInDegr[i][LATIDX] - center[LATIDX]);
            let longDiff = Math.abs(latLngInDegr[i][LNGIDX] - center[LNGIDX]);
            if (result[LATIDX] < latDiff) {
                result[LATIDX] = latDiff;
            }
            if (result[LNGIDX] < longDiff) {
                result[LNGIDX] = longDiff;
            }
        }
        return result;
    }

    isEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    isPhone(phoneNumber) {
        let phoneno = /^\+?[0-9]{9,12}$/;
        let result;
        if (phoneNumber == null) phoneNumber = "";
        if (phoneNumber.match(phoneno)) {
            result = true;
        } else {
            result = false;
        }
        console.log("isPhone " + phoneNumber + " =>" + result);
        return result;
    }

    esVacio(str) {
        let result = !this.hasValue(str);
        return result;
    }

    hasValue(str) {
        let result = true;
        if (str == null) {
            result = false;
        } else if (str == "") {
            result = false;
        }
        return result;
    }

    getString(str) {
        let result = "";
        if (str == undefined) {

        } else if (str == null) {

        } else {
            result = str;
        }
        return result;
    }

    firstUpperCase(string) {
        let result = "";
        try {
            result = string[0].toUpperCase() + string.substring(1);
        } catch (e) {
        }
        return result;
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    /**
     * Convierte una cadena en un entero y lo devuelve
     * @param valor
     * @returns {number}
     */
    str2int(valor) {
        let res = 0;
        if (valor === null) valor = "0";
        try {
            res = parseInt(valor);
            if (isNaN(res)) {
                res = 0;
            }
        } catch (e) {
            res = 0;
        }
        return res;
    }

    /*
     ** Dado un numero en español con decimales devuelve el float asociado. Por ejemplo 4,4 devuelve float(4.4)
     */
    str2float(valor) {
        let res = 0.0;
        if (valor == null) {
            valor = "0";
        }
        valor = this.getString(valor);
        try {
            valor = valor.replace(",", ".");
            res = parseFloat(valor);
            if (isNaN(res)) {
                res = 0.0;
            }
        } catch (e) {
        }
        return res;
    }

    toPascalCase(string) {
        return `${string}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w+)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\s/, 'g'), '')
            .replace(new RegExp(/\w/), s => s.toUpperCase());
    }

    trim(valor) {
        return valor.trim();
    }

    perteneceLista(valor, lista) {
        let res = false;
        let pos = (";" + lista + ";").indexOf(";" + valor + ";");
        if (pos != -1) {
            res = true;
        }
        return res;
    }

    perteneceListaComa(valor, lista) {
        let res = false;
        let pos = ("," + lista + ",").indexOf("," + valor + ",");
        if (pos != -1) {
            res = true;
        }
        return res;
    }

    perteneceA(valor, lista) {
        let res = false;
        if (lista != null) {
            let pos = (lista).indexOf(valor, 0);
            if (pos != -1) {
                res = true;
            }
        }
        return res;
    }

    arrayModelToPlainObjects(arr) {
        return arr.map((a) => a.toPlainObject())
    }


    numDigitos(valor, ancho) {
        let res, i;
        res = valor + "";
        for (i = (res + "").length; i < ancho; i++) {
            res = "0" + res;
        }
        return res;
    }

    /**
     * //date es de la forma 2019-06-09T23:00:00Z
     * Para verlo en mes, cambiar el return por 8 o por 16
     * @param date
     * @returns {number}
     */
    getHourFromDatetime(date) {
        //return 16;
        return this.getMoment(date).format("H");
    }

    getDateFromDatetime(date) {
        return this.getMoment(date).format("YYYY-MM-DD");
    }

    /**
     *
     * @param date Debe estar en formato Universal (acabado en Z). No funciona si se le pasa una fecha por ejemplo 2019-09-10
     * @returns {*}
     */
    getMoment(date) {
        return moment(date).tz(this.getTimezone());
    }

    /**
     * A partir de una fecha sin timezone (2013-11-18T11:55:00) deveulve un objeto moment con la fecha en el timezone seleccionado.
     * @param date
     * @returns {*}
     */
    getMomentFromDateWithoutTimezone(date) {
        return moment.tz(date, this.getTimezone());
    }

    /**
     * Devuelve true si una fecha es valida
     * @param d
     * @returns {boolean}
     */
    isValidDate(d) {
        return d instanceof Date && !isNaN(d) && d != null;
    }

    /**
     * A partir de un formato moment devuelve la fecha en ISOString (2013-11-18T11:55:00Z)
     */
    formatToISOString(moment) {
        return this.left(moment.toISOString(), 19) + "Z";
    }

    /**
     * A partir de una feche en formato del navegador
     * (por ejemplo Fri Sep 13 2019 15:00:00 GMT+0200 (hora de verano de Europa central))
     * devuelve 2019-09-13T15:00:00
     * @param today
     * @returns {string}
     */
    getDateStringFromDateLocalizedBroser(today) {
        let result = "";
        let dd = this.numDigitos(today.getDate(), 2);
        let mm = this.numDigitos(today.getMonth() + 1, 2); //Enero es 0!
        let yyyy = today.getFullYear();

        let hours = this.numDigitos(today.getHours(), 2);
        let minutes = this.numDigitos(today.getMinutes(), 2);
        let seconds = this.numDigitos(today.getSeconds(), 2);

        result = "" + yyyy + "-" + mm + "-" + dd + "T" + hours + ":" + minutes + ":" + seconds;
        return result;
    }

    /**
     *  A partir de una hora sin datetime devuelve una hora con datetime
     * @param hour
     * @returns {*}
     */
    getHourFromHourWithoutTimezone(hour) {
        let date = new Date(2000, 1, 1, hour);
        return moment.tz(date, this.getTimezone()).format("H");
    }


    getModalClasses() {
        let modalClasses = {
            push: "modal-push",
            body: "modal-body",
            footer: "modal-footer",
            head: "modal-head"
        }

        return modalClasses;
    }

    getDictSingleFromArray(arr) {
        let result = {};
        let fieldName = "id";
        for (let obj of arr) {
            let key = obj[fieldName];
            result[key] = obj;
        }
        return result;
    }

    getDictMultipleFromArray(arr, fieldName) {
        let result = {};
        for (let obj of arr) {
            let key = obj[fieldName];
            result[key] = obj;
        }
        return result;
    }

    getDictMultipleFromArrayToArray(arr, fieldName) {
        let result = {};
        for (let obj of arr) {
            let key = obj[fieldName];
            if (result[key] == null) {
                result[key] = [];
            }
            result[key].push(obj)
        }
        return result;
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getToday() {
        return moment();
    }

}

export default new Util();
