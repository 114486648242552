import React from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

registerLocale('es-ES', es);

import "react-datepicker/dist/react-datepicker.css";
import { observer } from 'mobx-react';
import IconsInputsComponent from "./IconsInputsComponent";
import withTranslate from "../../translator/withTranslate";
import AppLogger from "../../util/AppLogger";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

@observer
class DateIntervalInputField extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    handleChange(date) {
        if (this.props.onChange != null) {
            let value;
            if (date != null) {
                value = date.toISOString().substr(0, 10);
            }
            let eventObj = {
                target: {
                    name: this.props.name,
                    value
                }
            }
            this.props.onChange(eventObj);
        }
    }

    handleChangeTo(date) {
        if (this.props.onChangeTo != null) {
            let value;
            if (date != null) {
                value = date.toISOString().substr(0, 10);
            }
            let eventObj = {
                target: {
                    name: this.props.nameTo,
                    value
                }
            }
            this.props.onChangeTo(eventObj);
        }
    }

    render() {
        //console.log("DateInputField render value:" + this.props.value);
        //console.log("DateInputField render name:" + this.props.name);
        this.log("Render DateInterval");
        const { t, i18n } = this.props;
        let seletedDateStr = this.props.value;
        if (seletedDateStr != null) {
            seletedDateStr = new Date(this.props.value.substr(0, 10));
        }
        let seletedDateStrTo = this.props.valueTo;
        if (seletedDateStrTo != null) {
            seletedDateStrTo = new Date(this.props.valueTo.substr(0, 10));
        }


        let props = this.props;
        let form = this.form;
        if (form == null) {
            form = {};
        }

        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        return (
            <div className={props.classGroup}>
                <div className={"form-group DateIntervalInputField " + form.formCssClass}>

                    <label htmlFor={props.name}
                           className="control-label">{props.title}
                        {props.required && (<React.Fragment> *</React.Fragment>)}
                    </label>
                    <IconsInputsComponent
                        postfix={this.props.postfix}
                        prefix={this.props.prefix}
                    >
                        <DatePicker
                            selected={seletedDateStr}
                            onChange={(date) => this.handleChange(date)}
                            value={this.props.value}
                            showYearDropdown
                            dateFormat="yyyy-MM-dd"
                            scrollableYearDropdown
                            locale="es"
                            yearDropdownItemNumber={15}
                            readOnly={props.readOnly}
                            className="form-control"
                            timeCaption="Time"

                        />
                        <>
                            <div className="js-info ">{props.info || " "}</div>
                        </>
                    </IconsInputsComponent>
                    <IconsInputsComponent
                        postfix={this.props.postfix}
                        prefix={this.props.prefix}
                    >
                        <DatePicker
                            selected={seletedDateStrTo}
                            valueto={this.props.valueTo}
                            onChange={(date) => this.handleChangeTo(date)}
                            showYearDropdown
                            dateFormat="yyyy-MM-dd"
                            scrollableYearDropdown
                            locale="es"
                            yearDropdownItemNumber={15}
                            readOnly={props.readOnly}
                            className="form-control"
                            timeCaption="Time"

                        />
                        <>
                            <div className="js-info ">{props.info2 || " "}</div>
                        </>
                    </IconsInputsComponent>
                    {errors.map(error => <div>{error.message}</div>)}
                </div>

            </div>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(DateIntervalInputField);
