import React from 'react';
import { observer } from 'mobx-react';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import RadioButtonComponent from "../../components/fields/RadioButtonComponent";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import Select2Component from "../../components/fields/Select2Component";
import DateInputField from "../../components/fields/DateInputField";
import withTranslate from "../../translator/withTranslate";
import MoreButtons from "../../components/MoreButtons";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/PopUp";
import PropsUtil from "../../util/PropsUtil";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import AssetModel from "../../models/AssetModel";
import { withRouter } from "react-router-dom";
import AssetCodesTable from "./AssetCodesTable";
import InfoBlock from "../../components/InfoBlock";
import util from "../../util/Util";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import SpinnerClipLoaderComponent from "../../network/SpinnerClipLoaderComponent";

@observer
class AssetForm extends BaseForm {

    constructor(props) {
        super(props);
        this.nameMainType = "asset";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
            copyAsset: {},
            loadingDuplicate: false
        }
    }

    onCancelOrder() {
        return this.propsUtil.changeUrlRequest({ rightModal: "2" });
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.typifiedState.loadStatus();
        await appState.typifiedState.loadAssetType();
        await appState.typifiedState.loadAssetSubtype();
        await appState.typifiedState.loadManufacturer();
        await appState.typifiedState.loadModel();
        await appState.typifiedState.loadManufacturerRef();
        await appState.typifiedState.loadStoreId();
        await appState.typifiedState.loadAssetStatus();
        // await appState.typifiedState.loadAssetPlate();
        await appState.clientCacheState.loadClientCache();

        await appState.typifiedState.arrayLoadAssetType();
        await appState.typifiedState.arrayLoadAssetSubType();

    }

    async componentWillUpdate() {
        // Cargamos el asset correspondiente para cargar adecuadamente los selects jerarquicos
        let asset = this.graphDataMainType;
        appState.typifiedState.assetTypeSelected = asset.type;

        if (asset.storeId == null && !this.props.fromRightModal && this.getFormId() == null) {
            asset.storeId = util.hasValue(this.propsUtil.getRequest("storeId")) ? this.propsUtil.getRequest("storeId") : null;
            asset.propietary = util.hasValue(this.propsUtil.getRequest("propietary")) ? this.propsUtil.getRequest("propietary") : null;
        }
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        //Actualizo el array de workOrders que se visualizan
        await this.actualizaCamposEquipo(model, previousId);
    }

    async actualizaCamposEquipo(model, previousId) {
        if (util.hasValue(previousId)) {
            for (let i = 0; i < appState.assetState.assets.length; i++) {
                if (appState.assetState.assets[i].id == model.id) {
                    appState.assetState.assets[i] = model.toPlainObject()
                }
            }
        }
    }

    getModelQuery() {
        return new AssetModel();
    }

    updateInputEvent(event, op) {
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(event.target.name, event.target.value)

    }

    clickErroresFormulario() {
        this.log("clickErroresFormulario()")
        this.setState({
            errorFormulario: false
        });
        this.graphStatus.mutationError = 0;
    }

    copyAsset(asset) {
        this.setState({ loadingDuplicate: true });
        let assetModel = new AssetModel();
        try {
            assetModel.hidrate(asset);
            assetModel.id = "";
            assetModel.persist();
        } catch (e) {
            this.log('Error Duplicate');
            this.log(e);
        }
        setTimeout(() => {
            this.setState({ loadingDuplicate: false });
        }, 2000);


    }

    noCancelOrder() {
        return this.propsUtil.changeUrlRequest({ rightModal: null });
    }

    /**
     * Metodo que antes de llamar a updateInputEvent al seleccionar un assetType, actualiza los selects adecuadamente
     *
     * @param e
     */
    updateInputEventAssetType(e) {
        // Borramos la workOrderType2, ya que al cambiar de workOrderType, las workOrderType2 se recargan
        this.updateInput("subType", "");
        // Actualizamos en el typifiedState la variable asociada a la workOrderType por la cual se filtran las workOrderType2
        appState.typifiedState.assetTypeSelected = e.target.value;
        // Ahora si, actualizamos la workOrderType
        this.updateInputEvent(e);
    }

    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let id = this.getFormId() || this.props.id;
        let asset = this.graphDataMainType;
        if (asset == null) {
            asset = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""].map(error => error.message)
            this.state.errorFormulario = true;
        }
        let optionsSiNo = [
            { label: "Sí", value: "1", id: "1" },
            { label: "No", value: "0", id: "0" },
        ];
        return (

            <form
                className="model-form"
                name="formulario"
                noValidate
                onSubmit={(e) => this.handleFormSubmit(e)}
            >

                <InfoBlock componentObject={this}>
                    <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                        {this.props.fromRightModal ?

                            <div className={modalClasses.head}>
                                <PageTitleRightModal
                                    title={"Equipos"}
                                    onBackModal={() => this.onBackModal()}
                                    subtitle={this.graphDataMainType.plate || t("Nuevo")}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}
                                />

                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataAssets()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                            </div>
                            :
                            <PageTitle
                                title={t("Equipos")}
                                subtitle={this.graphDataMainType.plate || t("Nuevo")}
                            >
                                <MoreButtons
                                    actions={
                                        [
                                            {
                                                title: "Duplicar Equipo ", onClick: () => this.copyAsset(asset)
                                            },
                                            {
                                                title: "Dar de Baja",
                                                icon: 'fas fa-exclamation-triangle',
                                                onClick: () => this.onCancelOrder()
                                            }
                                        ]
                                    }
                                />
                            </PageTitle>
                        }


                        <div className={'card mb-3'}>

                            <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                               error={this.graphStatus.mutationError}
                                               working={this.graphStatus.networkWorking}
                            />
                            {errorsMapped[""] != null ?
                                <div className="alert alert-danger" role="alert">
                                    {errorsMapped[""].map(error => <div>{error.message}</div>)}
                                </div>
                                :
                                null
                            }
                            <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                                formError={this.graphStatus.formError}
                            />
                        </div>

                        <div className={'card mb-3'}>
                            <FormWrapper>
                                {!this.props.fromRightModal &&
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataAssets()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                                }


                                <div className={modalClasses.body}>
                                    <div className={"col-12"}>
                                        <div className={"row"}>
                                            <Select2Component
                                                value={asset.status}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"status"}
                                                title={t("Estado")}
                                                classGroup={"col-md-3"}
                                                options={appState.typifiedState.statusAssetsForSelect}
                                                errors={errorsMapped.status}
                                            />
                                            <Select2Component
                                                value={asset.propietary}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"propietary"}
                                                title={t("Propietario")}
                                                classGroup={"col-md-3"}
                                                options={appState.clientCacheState.clientCacheForSelect}
                                                errors={errorsMapped.propietary}
                                            />
                                            {/*<Select2Component*/}
                                                {/*value={asset.maintenance}*/}
                                                {/*onChange={(e) => this.updateInputEvent(e)}*/}
                                                {/*name={"maintenance"}*/}
                                                {/*title={t("Mantenimiento")}*/}
                                                {/*classGroup={"col-md-3"}*/}
                                                {/*options={appState.clientCacheState.clientCacheForSelect}*/}
                                                {/*errors={errorsMapped.maintenance}*/}
                                            {/*/>*/}
                                            {/*<Select2Component*/}
                                                {/*value={asset.exploitation}*/}
                                                {/*onChange={(e) => this.updateInputEvent(e)}*/}
                                                {/*name={"exploitation"}*/}
                                                {/*title={t("Explotación")}*/}
                                                {/*classGroup={"col-md-3"}*/}
                                                {/*options={appState.clientCacheState.clientCacheForSelect}*/}
                                                {/*errors={errorsMapped.exploitation}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>

                                    <GroupOfField title={t('Datos')}
                                                  subtitle={t('Equipo')}
                                                  icon="fas fa-file-import"
                                    >

                                        <AlertModal isOpen={this.state.errorFormulario}
                                                    onCloseModal={() => this.clickErroresFormulario()}
                                        >
                                            <PopUp
                                                title={t('Error')}
                                                icon="fas fa-exclamation-triangle"
                                                text="textoErrores"
                                                label1='Aceptar'
                                                onClick1={() => this.clickErroresFormulario()}
                                            >
                                            </PopUp>
                                        </AlertModal>

                                        <div className={"col-12"}>
                                            <div className={"row"}>
                                                <InputTypeComponent
                                                    value={asset.plate}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"plate"}
                                                    title={t("Numero de Matricula")}
                                                    validate={"alphanumeric|maxLength:20"}
                                                    classGroup={"col-md-3"}
                                                    errors={errorsMapped.plate}
                                                />
                                                <Select2Component
                                                    value={asset.type}
                                                    onChange={(e) => this.updateInputEventAssetType(e)}
                                                    name={"type"}
                                                    title={t("Tipo de equipo")}
                                                    classGroup={"col-md-3"}
                                                    options={appState.typifiedState.assetTypesForSelect}
                                                    errors={errorsMapped.type}
                                                />
                                                <Select2Component
                                                    value={asset.subType}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"subType"}
                                                    title={t("Subtipo de equipo")}
                                                    classGroup={"col-md-3"}
                                                    options={appState.typifiedState.assetSubtypeForSelect}
                                                    errors={errorsMapped.subType}
                                                />
                                                <Select2Component
                                                    value={asset.manufacturer}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"manufacturer"}
                                                    title={t("Fabricante")}
                                                    classGroup={"col-md-3"}
                                                    options={appState.typifiedState.manufacturerForSelect}
                                                    errors={errorsMapped.manufacturer}
                                                />
                                                <InputTypeComponent
                                                    value={asset.model}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"model"}
                                                    title={t("Modelo")}
                                                    placeholder="Modelo del equipo"
                                                    classGroup={"col-md-3"}
                                                    type={"text"}
                                                    validate={"alphanumeric|maxLength:20"}
                                                    info={t('Modelo')}
                                                    errors={errorsMapped.model}
                                                />

                                                <InputTypeComponent
                                                    value={asset.serialNumber}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"serialNumber"}
                                                    title={t("Número de Serie")}
                                                    placeholder="Número de Serie"
                                                    classGroup={"col-md-6"}
                                                    type={"text"}
                                                    validate={"alphanumeric|maxLength:20"}
                                                    info={t('Fabricante')}
                                                    errors={errorsMapped.serialNumber}
                                                />
                                            </div>
                                        </div>
                                    </GroupOfField>

                                    {/*<AssetCodesTable*/}
                                        {/*classGroup={this.props.fromRightModal ? "col-12" : "col-8"}*/}
                                        {/*id={this.getFormId() || this.props.id}*/}
                                        {/*foreingKeyfield={this.foreingKeyfield}*/}
                                        {/*fromRightModal={this.props.fromRightModal}*/}
                                    {/*/>*/}

                                    <GroupOfField
                                        title={t('Datos de')}
                                        subtitle={t('Garantía')}
                                        icon="fas fa-circle"
                                    >
                                        <div className={"col-12"}>
                                            <div className={"row"}>
                                                <RadioButtonComponent
                                                    value={asset.warranty || "0"}
                                                    title={t("Garantía")}
                                                    styleLabel={'m-5'}
                                                    checked={"0"}
                                                    name={"warranty"}
                                                    options={optionsSiNo}
                                                    classGroup={"col-3"}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                />
                                                {asset.warranty == 1 ?
                                                    <DateInputField
                                                        value={asset.endDateWarranty}
                                                        prefix={"fas fa-calendar-alt"}
                                                        title={t("Fecha Fin de la Garantía")}
                                                        onChange={(e) => this.updateInputEvent(e)}
                                                        name={"endDateWarranty"}
                                                        type={"text"}
                                                        classGroup={"col-3"}

                                                    />
                                                    :
                                                    <InputTypeComponent
                                                        value={""}
                                                        onChange={(e) => this.updateInputEvent(e)}
                                                        name={"endDateWarranty"}
                                                        title={t("Fecha Fin de la Garantía")}
                                                        placeholder=""
                                                        classGroup={"col-3"}
                                                        type={"text"}
                                                        errors={errorsMapped.manufacturer}
                                                        readOnly={true}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </GroupOfField>
                                </div>
                            </FormWrapper>
                        </div>
                        {this.props.fromRightModal ?
                            <div className=" fixed-bottom">
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </div>
                            :
                            <FormButtons id={id} formStatus={this.graphStatus} />
                        }

                        {this.state.loadingDuplicate &&
                        <SpinnerClipLoaderComponent />
                        }

                        <AlertModal isOpen={this.propsUtil.getRequest("rightModal") === "2"}
                                    onCloseModal={() => this.propsUtil.changeUrlRequest({ rightModal: null })}
                        >
                            <PopUp
                                title={t('Dar de Baja')}
                                icon="fas fa-exclamation-triangle"
                                text={t('¿Estas Seguro de que desea Dar de baja este equipo?')}
                                label1={t('No')}
                                label2={t('Si')}
                                onClick1={this.noCancelOrder}
                                onClick2={this.cancelAsset}
                                func={this.handleFormSubmit}
                            />
                        </AlertModal>

                    </div>
                </InfoBlock>
            </form>

        )
    }

    wait() {
    }
}

export default withRouter(withApollo(withTranslate(AssetForm, "AssetModel")));
