import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "./../base/BaseForm";
import withTranslate from "../../translator/withTranslate";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import OrderModel from "../../models/OrderModel";
import OrderFormWoComponent from "./OrderFormWoComponent";
import PropTypes from 'prop-types';
import VsfButton from '../../components/VsfButton';
import PropsUtil from "../../util/PropsUtil";
import { observable } from "mobx";
import WorkOrderModel from "../../models/WorkOrderModel";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';

@observer
class OrderFormWorkOrders extends BaseForm {
    static defaultProps = {
        workOrderId: null,
    };
    static propTypes = {
        //Acción de descarga de Excel en el listado
        workOrderId: PropTypes.string,
    };

    @observable currentModalState = { modalOpen: false };

    constructor(props) {
        super(props);
        this.log("constructor()");
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            hasSomeRowEditing: false, // Muestra este formulario en modo edición en algono de los elementos

        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable(this.getFormId());
        await appState.typifiedState.loadAssetType();
        appState.typifiedState.arrayLoadAssetType();
        await appState.typifiedState.loadAssetSubtype();
        appState.typifiedState.arrayLoadAssetSubType();
    }

    async reloadTable(orderId) {
        await appState.workOrderState.reloadFromOrderId(orderId);
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelTable() {
        let result = new WorkOrderModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("asset");
        return result;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const { t, i18n } = this.props;
        let order = this.graphDataMainType;
        let workOrder = appState.workOrderState.workOrders;
        if (order == null) {
            order = {}
        }
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Pedido"}
                                onBackModal={() => this.onBackModal()}
                                subtitle={order.code || t("Nuevo")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder()}
                                active="Órdenes de Trabajo"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Pedido")}
                            subtitle={order.code || t("Nuevo")}
                        />
                    }

                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataOrder()}
                            active={"Órdenes de Trabajo"}
                        />
                        }
                        <GroupOfField
                            title={(workOrder.length >= 1) ? t('Creadas') : t('Todavia no has añadido ninguna orden de trabajo')}
                            subtitle={(workOrder.length >= 1) ? workOrder.length + " " + t('Órdenes de trabajo') : ''}
                            icon='fas fa-file'
                        />
                        <div className={modalClasses.body}>
                            <div className="card-body">

                                <div className="mt-4">
                                    <div className="table-edit second">
                                        <div className="col-12">
                                            <table className="table"
                                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                                <thead>
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{workOrder.length !== 0 ? t('Número Orden de Trabajo') : t('No hay ordenes de trabajo creadas')}
                                                    </td>
                                                </tr>
                                                {workOrder.length !== 0 &&
                                                <tr>
                                                    <th scope="col">{t('Numero de Equipo')}</th>
                                                    <th scope="col">{t('Tipo de Equipo')}</th>
                                                    <th scope="col">{t('Subtipo de Equipo')}</th>
                                                    <th scope="col">{t('Tipo de OT')}</th>
                                                    <th scope="col">{t('Subtipo de OT')}</th>
                                                    <th scope="col">{t('Adjuntos')}</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody>
                                                {console.log('workOrders===>', workOrder)}
                                                {workOrder.map((wo, index) => (
                                                        <OrderFormWoComponent
                                                            key={wo.id || 0}
                                                            row={wo || workOrder.length}
                                                            orderId={this.getFormId()}
                                                            rowIndex={index}
                                                            {...this.props}
                                                            remove={this.onRemoveWO}
                                                        />
                                                    )
                                                )}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 text-right">
                                        <VsfButton
                                            label={"Crear Nueva"}
                                            onClick={() => this.createdWO()}
                                            icon={"fa fa-plus"}
                                            classGroup={'button-form-group rext-right col-12'}
                                            classButton="btn--loader"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </InfoBlock>
            </div>
        )

    }

    onRemoveWO() {
        return this.propsUtil.changeUrlRequest({ rightModal: "3" });
    }

    createdWO() {
        let order = this.graphDataMainType;
        return this.propsUtil.changeUrlRequest({
            "rightModalTab": "workorder",
            "rightModal": "modalworkorderls",
            "orderId": order.id,
        });
    }


}

export default withRouter(withApollo(withTranslate(OrderFormWorkOrders)));


