import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";

const Types = {
    ITEM: 'workUnitInClient'
}
const itemSource = {
    endDrag() {
        appState.layoutState.scheduledDateBlocked = "";
        appState.layoutState.scheduledUserBlocked = "";
    },
    beginDrag(props) {
        console.log("beginDrag");
        console.log({ props });
        return { ...props };
    },
    /*
    endDrag(props) {
        console.log("endDrag");
        console.log({props});
        let ordersWithName = appState.preventOrders.filter(order=>order.name==props.name);
        console.log("endDrag length:"+ordersWithName.length);
        ordersWithName[0].date="20190101";
    }
    */
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

@observer
class WorkUnitInClientDrag extends Component {
    render() {
        //this.log("render()WorkUnitInClientDrag");
        const { isDragging, connectDragSource, src } = this.props;
        let textInClientBlock = "";
        let slot = {};
        if (this.props.slotId.id == null) {
            slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        }

        if (this.props.inClientBlock) {
            if (slot.scheduledTime != null || slot.userId != null) {
                textInClientBlock = "*"
            }
        }
        let totalTime;
        if (slot.order && slot.order.scheduleVisitId != null && appState.scheduleDropState.scheduledDict[slot.order.scheduleVisitId] != null) {
            if (appState.scheduleDropState.scheduledDict[slot.order.scheduleVisitId].estimatedtotalTime > slot.scheduledDuration) {
                totalTime = appState.scheduleDropState.scheduledDict[slot.order.scheduleVisitId].estimatedtotalTime;
            } else {
                totalTime = util.str2int(slot.scheduledDuration);
            }
        } else {
            totalTime = util.str2int(slot.scheduledDuration);
        }

        if (this.props.disabled) {
            return (<div className="drag-hours__item h-4" style={{ width: this.props.width }}
                         onClick={(e) => {
                             this.onClick(e, slot);
                         }} onMouseEnter={(e) => {
                appState.layoutState.backgroundScheduled = slot.orderId;
            }} onMouseLeave={(e) => {
                if (!appState.layoutState.slotview) {
                    appState.layoutState.backgroundScheduled = 0;
                }
            }}>
                <div className="drag-hours__item__content">
                    <div className="num-hours">
                        {totalTime}<span className="small">h</span>
                    </div>
                    <div className="bar-hours" />
                </div>
            </div>)
        } else
            return connectDragSource(
                <div className="drag-hours__item h-4" style={{ width: this.props.width }}
                     onClick={(e) => {
                         this.onClick(e, slot);
                     }} onMouseEnter={(e) => {
                    appState.layoutState.backgroundScheduled = slot.orderId;
                }} onMouseLeave={(e) => {
                    if (!appState.layoutState.slotview) {
                        appState.layoutState.backgroundScheduled = 0;
                    }
                }}>
                    <div className="drag-hours__item__content">
                        <div className="num-hours">
                            {totalTime}<span className="small">h</span>
                            {textInClientBlock}
                        </div>
                        <div className="bar-hours" />
                    </div>
                </div>
            )
    }

    onClick(event, slotPlain) {
        let difX = event.screenX - event.target.screenX;
        let difY = event.screenY - event.target.screenY;

        let boundingRect = event.target.getBoundingClientRect();
        let pantallaEmpizaEn = window.pageYOffset;
        let elementoEmpiezaEn = pantallaEmpizaEn + boundingRect.top;
        difY = event.pageY - elementoEmpiezaEn;
        let pantallaXEmpizaEn = window.pageXOffset;
        let elementoXEmpiezaEn = pantallaXEmpizaEn + boundingRect.left;
        difX = event.pageX - elementoXEmpiezaEn;

        let pageX = event.pageX - difX;
        let pageY = event.pageY - difY;

        //this.log("event.target=>");
        //this.log(event.target);
        appState.scheduleDropState.slotClickedPosition = { left: pageX, top: pageY };
        appState.scheduleDropState.slotClicked = slotPlain;
        appState.layoutState.clickslotView();
        appState.layoutState.backgroundScheduled = slotPlain.orderId;
        appState.layoutState.backgroundRowScheduled = slotPlain.id;
        // this.log("Clicked!!!")
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(WorkUnitInClientDrag)
