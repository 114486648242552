import React from 'react';
import { observer } from 'mobx-react';
import GroupOfField from "../components/layout/GroupOfField";
import PageTitle from "../components/PageTitle"
import { withApollo } from "react-apollo";
import BaseForm from "./base/BaseForm";
import FormWrapper from "../components/FormWrapper";
import FormButtons from "../components/FormButtons";
import NetworkStatusInfo from "../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../components/status/LoadingOrErrorInfo";
import withTranslate from "../translator/withTranslate";
import PropsUtil from "../util/PropsUtil";
import TabsComponent from "../components/fields/TabsComponent";
import TabsData from "../layout/TabsData";
import UploadFileComponent from "../components/UploadFileComponent";
import OrderModel from "../models/OrderModel";
import WorkOrderModel from "../models/WorkOrderModel";
import ArticleModel from "../models/ArticleModel";
import ClientModel from "../models/ClientModel";
import ScheduleVisitModel from "../models/ScheduleVisitModel";
import AssetModel from "../models/AssetModel";
import util from "../util/Util";
import PageTitleRightModal from "../components/PageTitleRightModal";
import InfoBlock from "../components/InfoBlock";
import UserModel from "../models/UserModel";
import appState from '../state/AppState';


@observer
class Document extends BaseForm {
    nameMainType = this.props.model;

    constructor(props) {
        super(props);
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
    }

    getModelQuery() {
        let modelos = {
            "pedidos": new OrderModel(),
            "ordenes de trabajo": new WorkOrderModel(),
            "equipos": new AssetModel(),
            "repuestos": new ArticleModel(),
            "clientes": new ClientModel(),
            "preventivas": new ScheduleVisitModel(),
            "usuarios": new UserModel(),
            "Visitas Preventivas": new ScheduleVisitModel(),
        };
        let result = modelos[this.props.pestanya];
        this.log("Document.constructor() getModelQuery:" + result + " pestanya:" + this.props.pestanya);
        result.addRelatedTable("documents");
        return result;
    }


    async formDidSave(model, previousId) {
        //Actualizo el array de workOrders que se visualizan
        await this.actualizaListadWorkOrders(model, previousId);
    }

    async actualizaListadWorkOrders(model, previousId) {
        this.log("actualizaListadWorkOrders =>");
        if (this.props.orderId != null) {
            if (util.hasValue(this.props.id)) {
                for (let i = 0; i < appState.workOrderState.workOrders.length; i++) {
                    if (appState.workOrderState.workOrders[i].id == model.id) {
                        appState.workOrderState.workOrders[i] = model.toPlainObject()
                    }
                }
            } else {
                appState.workOrderState.workOrders.push(model.toPlainObject());
            }
        }
    }

    tabsDocument() {
        let tipos = {
            "pedidos": this.tabsData.getDataOrder(),
            "ordenes de trabajo": this.tabsData.getDataWorkOrder(),
            "equipos": this.tabsData.getDataAssets(),
            "repuestos": this.tabsData.getDataArticles(),
            "clientes": this.graphDataMainType.isPos == 1 ? this.tabsData.getDataClientPos() : this.tabsData.getDataClient(),
            "preventivas": this.tabsData.getDataTypified(),
            "Visitas Preventivas": this.tabsData.getDataScheduleVisit(),
            "usuarios": this.tabsData.getDataUser(),
        };
        return tipos[this.props.pestanya];
    }

    /*updateInputEvent(event) {
        this.updateInput(event.target.name, event.target.value)
    }*/
    render() {
        const { t, i18n } = this.props;
        let id = this.getFormId() || this.props.id;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let model = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        return (

            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={'Documentos'}

                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={id}
                                tabs={this.tabsDocument()}
                                fromRightModal={this.props.fromRightModal}
                                active={"Documentos"}
                                classNameModal={" col-12"}
                            />
                        </div>
                        :
                        <PageTitle
                            title={'Documentos'}
                        />
                    }

                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={id}
                            tabs={this.tabsDocument()}
                            active={"Documentos"}
                            classNameModal={" col-12"}
                        />
                        }
                        <div className="card-body">

                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}
                            >
                                <div className={'card mb-3'}>

                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                       error={this.graphStatus.mutationError}
                                                       working={this.graphStatus.networkWorking}
                                    />
                                    <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                                        formError={this.graphStatus.formError}
                                    />
                                    {errorsMapped[""] != null ?
                                        <div className="alert alert-danger" role="alert">
                                            {errorsMapped[""].map(error => <div>{error.message}</div>)}
                                        </div>
                                        :
                                        null
                                    }
                                    <FormWrapper>

                                        <GroupOfField title={t('Documentacion')}
                                                      icon="fas fa-book"
                                        >

                                            <div className={"col-12"}>
                                                <div className={"row"}>
                                                    <UploadFileComponent
                                                        title={t("Subida de Archivos")}
                                                        required={true}
                                                        name={'uploadFiles'}
                                                        classGroup={"col-12"}
                                                        documents={model.documents}
                                                        baseFolder={this.nameMainType + "/" + this.getFormId() + "/documents"}
                                                    />
                                                </div>
                                            </div>
                                        </GroupOfField>
                                    </FormWrapper>
                                </div>
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </form>
                        </div>
                    </div>
                </InfoBlock>
            </div>


        )
    }
}

export default withTranslate(withApollo(Document));
