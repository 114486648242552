import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { computed, toJS } from 'mobx';
import Links from "../../util/Links";
import appState from "../../state/AppState";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import GraphException from "../../network/GraphException";

@observer
class BaseEditableRow extends Component {

    static propTypes = {
        setHasSomeRowEditing: PropTypes.func, //Función que se llama cuando el elemento editable actual pasa a ser no editable
        hasSomeRowEditing: PropTypes.bool, //Algún elemento se está editando actualmente. No tiene por qué ser este elemento. Hace que se "nublen" los botones de "editar"
    };

    static defaultProps = {
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    };
    previousRowForDiscard = null;
    appStateKeyAfterSave = "genericFormRowState"; //cambiar-en-subclase si pueden convivir 2 listados

    /**
     * crear el elemento foreingKeyField
     * this.foreingKeyfield = "clientId";
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Get del estado para poder usarlo en eliminarTabla
     * Cambiar por el estado que se quiera conseguir
     * return appState.contactState.contacts;
     * @returns {Array}
     */
    @computed
    get mobxListado() {
        return null;
    }

    setMobxListado(newValue) {
    }

    /**
     * Cambiar por el modelo que se quiera conseguir
     * return  new ContactModel();
     */
    getModelTable() {

    }

    handleAutosuggestSelection(fieldName, suggestSelected) {
        this.log(".handleAutosuggestSelection() =>");
        this.log(fieldName);
        this.log(suggestSelected);
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        appState.layoutState.formWithoutChanges = false;
        let eventObj = {
            target: {
                name: fieldName,
                value: suggestSelected
            }
        };
        this.handleInputChange(eventObj)
    };

    onEditRow() {
        this.log(".onEditRow() =>");
        this.log(this.props.row.id);
        this.props.row.isEditing = true;
        this.props.setHasSomeRowEditing(true);
    };

    async onDeleteRow() {
        this.log(".onDeleteRow() =>");
        let modelo = this.getModelTable();
        modelo.hidrate(this.props.row);
        appState.loadingBarState.start();

        try {

            if (util.hasValue(modelo.id)) {
                await modelo.remove();
            }
            if (this.props.fromWorkOrder) {
                await appState.assetState.getCountSlotsFromWorkOrder(this.getFormId());
                await appState.assetState.getCountArticlesFromWorkOrder(this.getFormId());
            } else {
                await appState.assetState.getCountSlotsFromOrder(this.getFormId());
                await appState.assetState.getCountArticlesFromOrder(this.getFormId());
            }
            try {
                await this.rowDidDelete(this.props.row);
            } catch (e) {
                this.log("onrowDidDelete Exception ");
                this.log({ e });
            }
            this.props.setHasSomeRowEditing(false);
        } catch (e) {
            this.log("onDeleteRow Exception ");
            this.log({ e });
        }
        appState.loadingBarState.finalize();
    }

    onDiscardRow() {
        this.log("onDiscardRow");
        appState[this.appStateKeyAfterSave].mutationError = 0;
        appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = [];
        if (this.props.row.id == null) {
            this.onDeleteRow();
        } else {
            if (this.previousRowForDiscard != null) {
                for (let i = 0; i < this.mobxListado.length; i++) {
                    let row = this.mobxListado[i];
                    if (row.id === this.props.row.id) {
                        this.mobxListado[i] = this.previousRowForDiscard;
                    }
                }
            }
        }
        this.props.row.isEditing = false;
        this.props.setHasSomeRowEditing(false);
        this.previousRowForDiscard = null;
    };

    handleInputChange(event) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        this.props.row[event.target.name] = event.target.value;
    };

    getFormId() {
        let result = this.props[this.foreingKeyfield];
        if (result == null) {
            let links = new Links();
            result = links.getFormId(this.props);
        }
        return result;
    }

    getGraphErrorsFromStatus() {
        let result = new GraphException().getGraphErrorsFromStatus(appState[this.appStateKeyAfterSave].mutationGraphQlResponse);
        return result;
    }

    renderDefaults() {
        if (this.props.row.scheduledDuration == null || this.props.row.scheduledDuration == "") {
            this.props.row.scheduledDuration = 1;
        }
        if (this.props.row.quantity == null || this.props.row.quantity == "") {
            this.props.row.quantity = 1;
        }
        if (this.props.row.workOrderIds == null || this.props.row.workOrderIds == "") {
            this.props.row.workOrderIds = "-1";
        }
        if (this.props.row.price == null || this.props.row.price == "") {
            this.props.row.price = "1";
        }
    }

    async rowDidDelete(modelo) {
        let arrCopy = toJS(this.mobxListado);
        arrCopy.splice(this.props.rowIndex, 1);
        this.log({ arrCopy });
        this.setMobxListado(arrCopy);
    }

    rowDidSave(modelo) {

    }

    /**
     * Guarda e en base de datos
     * @returns {Promise<void>}
     */
    async onSaveRow() {
        this.log(".onSaveRow() =>");
        this.renderDefaults();
        let modelo = this.getModelTable();
        modelo.hidrate(this.props.row);
        if (this.props.fromWorkOrder && this.foreingKeyfield === "orderId") {
            modelo[this.foreingKeyfield] = this.props.orderId;
        } else {
            modelo[this.foreingKeyfield] = this.getFormId();
        }
        appState.loadingBarState.start();
        appState[this.appStateKeyAfterSave].mutationError = 0;
        appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = [];
        try {
            await modelo.persist();
            this.props.row.id = modelo.id;
            this.props.row.isEditing = false;
            this.previousRowForDiscard = null;
            this.props.setHasSomeRowEditing(false);

            this.rowDidSave(modelo);

        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = errors;
            appState[this.appStateKeyAfterSave].mutationError = 1;
        }


        appState.loadingBarState.finalize();
    };

    render() {
        return (
            <></>
        )
    }

    renderSupport() {

    }

    renderEditBlock() {
        let rowModel = this.props.row;
        return (
            <div className="form-group">
                {rowModel.isEditing &&
                <div className="remove-add">
                    {this.renderSupport()}
                    <div onClick={() => this.onSaveRow()} title="Guardar" className="edit">
                        <span className="fas fa-save"> </span>
                    </div>
                    <div onClick={() => this.onDiscardRow()} title="Eliminar" className="remove">
                        <span className="fas fa-times"> </span>
                    </div>
                </div>
                }
                {!rowModel.isEditing && !this.props.hasSomeRowEditing &&
                <div className="remove-add">
                    {this.renderSupport()}
                    <div onClick={() => this.onEditRow()} title="Editar" className="edit">
                        <span className="fas fa-edit"> </span>
                    </div>
                    {this.props.scheduledSlotsLenghtCero ?
                        <div title="Eliminar" className="remove">
                            <span className="fas fa-trash-alt"> </span>
                        </div>
                        :
                        <div onClick={() => this.onDeleteRow()} title="Eliminar" className="remove">
                            <span className="fas fa-trash-alt"> </span>
                        </div>
                    }
                </div>
                }
                {!rowModel.isEditing && this.props.hasSomeRowEditing &&
                <div className="remove-add">
                    {this.renderSupport()}
                    <div title="Editar Contacto" className="edit inactive">
                        <span className="fas fa-edit" />
                    </div>
                    <div title="Eliminar" className="remove inactive">
                        <span className="fas fa-trash-alt" />
                    </div>
                </div>
                }
            </div>
        )
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default BaseEditableRow;
