import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {dir} from "async";
import PropTypes from 'prop-types';
import withTranslate from "../../translator/withTranslate";

@observer
export default class RadioButtonComponent extends Component {

    static defaultProps = {
        onChange: _=>{},
        options:[
            {
                label:"si",
                value:"1",
                id:"1",

            },
            {
                label:"no",
                value: "0",
                id: "0",

            },
        ]
    }

    static propTypes = {
        onChange: PropTypes.func,
        options: PropTypes.array
    }

    onChange(event) {
        console.log("onChange "+event.target.name);
        this.props.onChange(event)
    }

    render() {
        let props = this.props;
        return (
            <div className={"RadioFormField " + this.props.classGroup}>
                <div className="form-group flota-form">
                    <label className="col-form-label pt-0">
                        {props.title}</label>



                    <div className="row m-0">
                        {props.options.map( opp =>
                        <div key={opp.id} className="form-checkform-check-inline mr-1">
                            <input autoComplete="off"
                                   type="radio"
                                   className="form-radio-input"
                                   value={opp.value}
                                   id={opp.id}
                                   name={this.props.name}
                                   checked={opp.value===this.props.value}
                                   onChange={(e)=>this.onChange(e)}
                            />
                            <label className="form-radio-label"
                                   htmlFor={opp.id}>
                                {opp.label}
                            </label>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

