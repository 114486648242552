import React, { Component } from 'react';
import appState from "../state/AppState";

/**

 Esta clase no se utiliza actualmente
 */
export default class QuickEditWrapper extends Component {
    render() {
        return (
            <div className="modal-push" style={{display:"block"}}>
                <div className="modal-head">
                    <div className="row">
                        <div className="col-10 text-left">
                            <div className="modal-title">
                                <p>Edición <span>Rápida</span></p>
                            </div>
                        </div>
                        <div className="col-2 text-right">
                            <button type="button" className="close" aria-label="Cerrar"
                                    onClick={()=>this.props.onCloseModal()}
                            >
                                <span className="fas fa-times"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="row">
                        {this.props.children}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="mb-3 col-md-6">
                            <button onClick={() => this.props.onSaveButton()} type="button" className="btn btn-secondary btn-block">Guardar</button>
                        </div>
                        {/*<div className="mb-3 col-md-6">*/}
                            {/*<button type="button" className="btn btn-primary btn-block"*/}
                                    {/*onClick={()=>this.props.onAplicarFiltros() }*/}
                            {/*>Descartar</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}