import React, { Component } from 'react';
import { observer } from "mobx-react";
import AppLogger from "../../util/AppLogger";
import appState from "../../state/AppState";
import util from "../../util/Util";

@observer
class UserRoleListItem extends Component {
    render() {
        let props = this.props;
        let value = props.value;
        if (props.role) {
            let valores = props.value.split(",");
            let result = "";
            for (let rolId of valores) {
                result += appState.typifiedState.arrRoles[rolId].value + " ";
            }
            value = result;
        }
        if (props.employment) {
            value = appState.typifiedState.arrEmploiments[props.value];

        }
        return (
            <div>{util.hasValue(value) ? value : ""}</div>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default UserRoleListItem;
