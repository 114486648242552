import React from 'react';

const ErrorsFromGql = (props)=> {

    return (
        <>
            {props.errorsMapped[""] != null ?
                <div className="alert alert-danger" role="alert">
                    {props.errorsMapped[""].map(error => <div>{error.message}</div>)}
                </div>
                :
                null
            }
        </>
    )
}

export default ErrorsFromGql;