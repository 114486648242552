import AbstractModel from "./AbstractModel";

export default class TypifiedValueModel extends AbstractModel {

    id;
    type;
    code;
    value;
    icon;
    image;
    color;

    nameMainType = "typifiedValue";
    graphFindByIdOperation = "typifiedValue";
    graphFindOperation = "typifiedValuesConnection";

    getArrayFields() {
        let result = {
            "id": { type: "CodeField" },
            "type": "",
            "code": "",
            "color": "",
            "image": "",
            "value": "",
            "parentId": "",
            "icon": { label: "Icono" },
        };
        return result;
    }

    /**
     * Dado un tipo de maestro obtiene el valor a mostrar
     * @param type
     * @returns {*}
     */
    getTextFromType(type) {
        let tipos = {
            "diagnosis": "Diagnosticos",
            "assetType": "Tipos de equipos",
            "sympthom": "Sintomas",
            "status": "Estados",
            "quality": "Revisiones de Calidad",
            "workOrderType": "Tipos de Orden de trabajo",
            "subType": "Subtipos de Orden de trabajo",
            "interventionCategory": "Categoría de intervención",
            "zone": "Zonas",
            "manufacturer": "Fabricantes",
            "model": "Modelos",
            "relationship": "Relaciones de contactos",
        };
        return   tipos[type];
    }
}

