import React, { Component } from 'react';
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import { DropTarget } from "react-dnd/lib/index";
import { findDOMNode } from "react-dom";
import PropTypes from 'prop-types';
import WorkUnitInClientDrag from "./WorkUnitInClientDrag";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import { now } from "moment";
import SlotModel from "../../models/SlotModel";

const Types = {
    ITEM: 'workUnitInClient'
}


const itemTarget = {
    hover(props, monitor, component) {
        console.log("itemTarget.hover2");
        console.log({ props });
        console.log({ component });
        if (!component) {
            return null
        }
        let item = monitor.getItem();
        console.log({ item });

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        console.log({ dragIndex, hoverIndex });
        // Don't replace items with themselves
        // if (dragIndex === hoverIndex) {
        //     return
        // }
        // dragIndex puede ser 5 y hover 1. Debo poner la posici�n 5 antes de la 1
        let preventOrders = appState.preventOrders;

        // Creo un elemento "provisional". Si al final se cancela el movimiento deber�a quitar el elemento "ghost"
        let currentElementMoving = null;
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
                currentElementMoving = preventOrders[i];
                currentElementMoving.date = "2019prov";
                currentElementMoving.type = "ghost-moving";
            }
        }

        let newPreventOrders = [];
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
            } else {
                if (preventOrders[i].name == hoverIndex) {
                    newPreventOrders.push(currentElementMoving);
                }
                newPreventOrders.push(preventOrders[i]);
            }
        }
        appState.preventOrders = newPreventOrders;
        console.log("moved");

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()
        console.log({ hoverBoundingRect });
    },
    drop(props, monitor, component) {
        console.log("drop");
        // Obtain the dragged item
        const orderFromProps = monitor.getItem();
        appState.layoutState.scheduledDateBlocked = "";
        appState.layoutState.scheduledUserBlocked = "";
        console.log("drop=>");
        console.log(orderFromProps);
        console.log({ props });
        console.log({ component });
    }
}

function collectTarget(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class WorkUnitInClient extends Component {
    static defaultProps = {
        inClientBlock: false
    }
    static propTypes = {
        //Objeto que contiene el mes (code) y el nombre de la visita: {label:"Ene", year:19, code:'201901'},
        name: PropTypes.string,
        // Elemento que identifica a un elemento ya planificado en un mes. Tenemos el WorkOrderModel asociado
        slotId: PropTypes.string,
        // Indica que este elemento está en la pestaña clientes. De esta forma si está asignado es gris
        inClientBlock: PropTypes.bool,
        pixelsPerHour: PropTypes.number, //Numero de pixel por cada hora
    };

    colorBox() {
        let slot = {};
        if (this.props.slotId.id == null) {
            slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        }
        let claseSlot = " drag-hours2 color-prevent ";
        if (slot.slotUserRole === SlotModel.SUPPORT) {
            claseSlot += " color-prevent--lines ";
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Preventiva") {
            claseSlot += " color-prevent ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-prevent--lines ";
            }
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Programada") {
            claseSlot += " color-program ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-program--lines  ";
            }
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Evento") {
            claseSlot += " color-events ";
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Correctiva") {
            claseSlot += " color-corrective ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-corrective--lines ";
            }
        }
        if (appState.layoutState.backgroundScheduled == slot.orderId) {
            claseSlot += " seleccionada "
        } else {
            claseSlot += ""
        }
        if (!this.props.inClientBlock && slot.isDateTimeFixed) {
            claseSlot += " is-date-fixed ";
        } else {
            claseSlot += " ";
        }
        return claseSlot
    }

    render() {
        //this.log("render()WorkUnitInClient");
        const { connectDropTarget } = this.props;
        this.log("render=>");
        let slot = {};
        if (this.props.slotId.id == null) {
            slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        }
        if (slot == null) {
            return (
                <>slotId: {this.props.slotId} NULL
                </>
            );
        }
        let claseSlot = this.colorBox();
        let totalTime;
        if (slot.order && slot.order.scheduleVisitId != null && appState.scheduleDropState.scheduledDict[slot.order.scheduleVisitId] != null) {
            if (appState.scheduleDropState.scheduledDict[slot.order.scheduleVisitId].estimatedtotalTime > slot.scheduledDuration) {
                totalTime = appState.scheduleDropState.scheduledDict[slot.order.scheduleVisitId].estimatedtotalTime;
            } else {
                totalTime = util.str2int(slot.scheduledDuration);
            }
        } else {
            totalTime = util.str2int(slot.scheduledDuration);
        }
        let width = 6 * totalTime;
        if (width > 16) {
            width = 16;
        }
        if (width == 0) {
            width = 15;
        }
        let disabled = false;
        let isoDate = this.props.isoDate;
        let hour = this.props.hour;

        let fechaTabla = isoDate + " " + util.numDigitos(hour, 2) + ":00";
        let fechaAhora = util.getMoment(Date(now())).format("YYYY-MM-DD HH:mm");
        if (fechaTabla < fechaAhora) {
            disabled = true;
        } else {
            disabled = false;
        }
        if (this.props.inClientBlock) {
            if (slot.scheduledTime != null || slot.userId != null) {
                disabled = true
            } else {
                disabled = false
            }
        }
        let classAsignada = "";
        if (this.props.inClientBlock) {

        } else {
            classAsignada = " drag-absolute";
        }

        return connectDropTarget(
            <div>
                {disabled ?
                    <div className={claseSlot + " no-active " + classAsignada} style={{ width: width }}
                         data-order-id={slot.orderId}>
                        <WorkUnitInClientDrag {...this.props} disabled={true} width={width} />
                    </div>
                    :
                    <div className={claseSlot + classAsignada} style={{ width: width }}
                         data-order-id={slot.orderId}>
                        <WorkUnitInClientDrag {...this.props} width={width} />
                    </div>
                }
            </div>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DropTarget(Types.ITEM, itemTarget, collectTarget)(WorkUnitInClient)
