import React from 'react';
import { observer } from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import { withApollo } from "react-apollo";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import { computed } from "mobx/lib/mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import VsfButton from "../../components/VsfButton";
import GroupOfField from "../../components/layout/GroupOfField";
import OrderFormArticle from "./OrderFormArticle";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import PropsUtil from "../../util/PropsUtil";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import OrderModel from "../../models/OrderModel";
import withTranslate from "../../translator/withTranslate";
import { withRouter } from "react-router-dom";

@observer
class OrderFormArticles extends BaseEditableTable {


    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
        this.initializeGraphData(this.nameMainType);
    }

    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    setMobxListado(newValue) {
        appState.workOrderCartArticlesState.workOrderArticles = newValue;
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }

    async componentDidMountImpl() {
        super.componentDidMountImpl();
        this.log("componentDidMount(---------)");
        await this.reloadTable(this.getFormId());
        await appState.typifiedState.loadStatusArticle();
        await appState.typifiedState.arrayLoadArticlesStatus();
        await appState.articlesState.getArticles();
    }


    async reloadTable(orderId) {
        this.log("reload. orderId:" + orderId);
        let ordersArticlesQuery = new WorkOrderCartArticleModel();
        let ordersArticlesModels = await ordersArticlesQuery.getOrderArticlesFromOrder(orderId);
        await appState.workOrderState.reloadFromOrderId(orderId);

        appState.workOrderCartArticlesState.workOrderArticles = util.arrayModelToPlainObjects(ordersArticlesModels);
    }

    calculaCantidad() {
        let result = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles;
        articulos.map((articulo, index) => {
            if (articulo.quantity != null) {
                if (result == 0)
                    result = (this.formatDecimal(articulo.quantity));
                else if (articulo.quantity != '') {
                    result += (this.formatDecimal(articulo.quantity));
                }
            }
        });
        return result;
    }

    formatDecimal(number) {
        let result = parseFloat((number.toString()).replace(",", "."));
        return (result);
    }

    calculaPrecio() {
        let result = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles;
        articulos.map((articulo, index) => {
            if (articulo.articlePrice != null) {
                if (articulo.articlePrice != '' && articulo.quantity != '') {
                    if (articulo.quantity != null) {
                        if (result == 0)
                            result = (this.formatDecimal(articulo.articlePrice)) * (this.formatDecimal(articulo.quantity));

                        else
                            result += (this.formatDecimal(articulo.articlePrice)) * (this.formatDecimal(articulo.quantity));
                    } else {
                        if (result == 0)
                            result = (this.formatDecimal(articulo.articlePrice));
                        else {
                            result += (this.formatDecimal(articulo.articlePrice));
                        }
                    }
                }
            }
        });
        return result.toFixed(2);
    }


    render() {
        const { t, iln } = this.props;
        let order = this.graphDataMainType;
        let cantidad = this.calculaCantidad();
        let precio = this.calculaPrecio();
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();


        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Pedido"}
                                subtitle={order.code || t("Nuevo")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder()}
                                active="Piezas"
                                numAlarm={3}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Pedido")}
                            subtitle={order.code || t("Nuevo")}
                        />
                    }
                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataOrder()}
                            active={"Piezas"}
                        />
                        }
                        <div className={modalClasses.body}>

                            <div className="card-body">
                                <div className="row">
                                    <GroupOfField
                                        title={t('Pedido')}
                                        subtitle={t('Piezas')}
                                        icon='fas fa-calendar'
                                    />
                                </div>
                                <div className="row mt-4">
                                    <div className="table-edit">
                                        <div className="col-12">
                                            <table className="table visible-text"
                                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                                <thead>
                                                {this.mobxListado.length == 0 &&
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{t('No hay ninguna pieza creada')}
                                                    </td>
                                                </tr>
                                                }
                                                {this.mobxListado.length != 0 &&

                                                <tr>
                                                    <th scope="col">{t("Codigo")}</th>
                                                    <th scope="col">{t("Nombre")}</th>
                                                    <th scope="col">{t("Cantidad")}</th>
                                                    <th scope="col">{t("Precio")}</th>
                                                    <th scope="col">{t("Coste")}</th>
                                                    <th scope="col">{t("Precio total")}</th>
                                                    <th scope="col">{t("Estado")}</th>
                                                    <th scope="col">{t('Orden de Trabajo')}</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">
                                                {this.mobxListado.map((article, index) => (
                                                    <OrderFormArticle
                                                        key={article && article.id}
                                                        orderId={order.id}
                                                        rowIndex={index}
                                                        row={article}
                                                        workOrders={appState.workOrderState.workOrders}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    />
                                                ))}

                                                </tbody>
                                                <thead>
                                                {this.mobxListado.length != 0 &&
                                                <tr>
                                                    <td colSpan="2">{t('TOTAL')}</td>
                                                    <td>
                                                        {isNaN(cantidad) ?
                                                            0
                                                            :
                                                            cantidad
                                                        }
                                                    </td>
                                                    <td colSpan="6">{precio + " €"}</td>
                                                </tr>
                                                }
                                                </thead>
                                            </table>

                                            <div className="col-12 text-right">
                                                <VsfButton
                                                    disabled={this.state.hasSomeRowEditing}
                                                    label={"Crear nueva"}
                                                    isEditing={this.state.hasSomeRowEditing}
                                                    classButton="btn--loader"
                                                    onClick={_ => this.onOpenRowNewEmpty()}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        );
    }

}

export default withRouter(withTranslate(withApollo(OrderFormArticles)));
