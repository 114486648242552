import { observable } from 'mobx';
import util from "../util/Util";
import ContactModel from "../models/ContactModel";


class ContactState {

    @observable contacts=[];

    async reloadContactsFromClientId(clientId) {
        console.log("ContactState.reloadContactsFromClientId clientId:"+clientId);
        let contactQuery = new ContactModel();
        contactQuery.clientId = clientId;
        if (util.hasValue(contactQuery.clientId)) {
            let result = await contactQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.contacts = result;
        }
    }
}

export default ContactState;
