import Model from "./Model";
import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import TypifiedValueModel from "./TypifiedValueModel";
import VehicleModel from "./VehicleModel";

export default class UserModel extends AbstractModel {
    address;
    birthDate;
    city;
    company;
    contactPerson;
    contactPhoneNumber;
    contactRelationship;
    country;
    email;
    employeeNumber;
    firstName;
    status;
    id;
    idNumber;
    companyPhoneNumber;
    lastName;
    locale;
    mobileNumber;
    password;
    phoneNumber;
    employment;
    postalCode;
    province;
    insuranceNumber;
    capacitationIds;
    role;
    stockControl;
    citizenNumber;
    subinventory;
    userVehicle;
    timeZone;
    zones;
    zoneAsignedId;
    nameMainType="user";
    graphFindByIdOperation="user";
    graphFindOperation="usersConnection";
    graphExportOperation="usersExport";

    getArrayFields() {
        let resultBase=super.getArrayFields();
        let result = {
            ...resultBase,
            "address":{label: 'Direccion'},
            "birthDate":{label: 'Fecha de Nacimiento'},
            "city":{label: 'Ciudad'},
            "company":{ label: "Empresa" },
            "contactPerson":{label: 'Persona de contacto'},
            "contactPhoneNumber":{label: 'Telefono de Contacto'},
            "contactRelationship":{label: 'Relacion'},
            "companyEmail":{ label: "Email de empresa" },
            "country":{label: 'Pais'},
            "email":"",
            "employeeNumber":{ label: "Número de empleado" },
            "employment":{ label: "Puesto de trabajo" },
            "firstName":{label: 'Nombre'},
            "idNumber":{label: 'DNI'},
            "lastName":{label: 'Apellido'},
            "citizenNumber":{ label: "Número de ciudadano" },
            "insuranceNumber":{ label: "Número de la SS" },
            "locale":"",
            "mobileNumber":{ label: "Número de móvil" },
            "password":"",
            "phoneNumber":{label: 'Numero de Telefono'},
            "postalCode":{label: 'Código Postal'},
            "province":{label: 'Provincia'},
            "role":{ label: "Rol" },
            "stockControl":"",
            "subinventory":"",
            "companyPhoneNumber":{ label: "Número de empresa" },
            "capacitationIds":"",
            "timeZone":"",
            "userVehicle":{ label: "Vehículo" },
            "status":{ label: "Estado" },
            // "zoneAsignedId":{ label: "Zona de trabajo" },
            // "zoneAsigned":{ label: "Zona de trabajo" },
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        this.addRelatedTableZoneAsigned(result, TypifiedValueModel);
        this.addRelatedTableRelationship(result, TypifiedValueModel);
        this.addRelatedTableVehicle(result, VehicleModel);
        this.addRelatedTableEmployment(result, TypifiedValueModel);



        return result;
    }

};
