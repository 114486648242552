import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppLogger from "../util/AppLogger";
import withTranslate from "../translator/withTranslate";
import SavedFilterModel from "../models/SavedFilterModel";
import appState from "../state/AppState";
import util from "../util/Util";
import AlertModal from "../subpages/AlertModal";
import PropsUtil from "../util/PropsUtil";
import { withRouter } from "react-router-dom";
import PopUp from "./PopUp";

@observer
class FilterSharedComponent extends Component {
    constructor(props) {
        super(props);

    }

    async onDeleteFilter(id) {
        this.log(".onDeleteFilter() =>");
        let savedFilterQuery = new SavedFilterModel();
        let savedFilterModel = await savedFilterQuery.findById(this.state.id);
        appState.loadingBarState.start();
        try {
            if (util.hasValue(savedFilterModel.id)) {
                await savedFilterModel.remove();
            }
            this.props.reloadFilters();
        } catch (e) {
            this.log("onDeleteRow Exception ");
            this.log({ e });
        }

        appState.loadingBarState.finalize();
         this.propsUtil.changeUrlRequest({ rightModal: null, popUp: null });
    }

    openModal(id) {
        this.setState({
            id
        });
        return this.propsUtil.changeUrlRequest({
            rightModal: "2",
            popUp: "eliminar"
        });
    }

    noCancel() {
        return this.propsUtil.changeUrlRequest({ rightModal: null, popUp: null });
    }

    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        return (
            <div className="c-filters-shared">
                <article className="c-filters-shared__col">
                    <h1 className="c-filters-shared__col-title">{t("Mis Filtros")}</h1>
                    <ul className="c-filters-shared-list">
                        {this.props.savedFilterModels.map((value, index) => (
                            <li className="c-filters-shared-list__item" key={value.name + index}>
                                <span onClick={() => this.props.onClickFavourite(value)}
                                      className="c-filters-shared-list__item-link">{value.name}</span>
                                <div onClick={() => this.openModal(value.id)} title="Eliminar"
                                     className="remove">
                                    <span className="fas fa-trash-alt"> </span>
                                </div>
                            </li>
                        ))
                        }
                    </ul>
                </article>
                <article className="c-filters-shared__col">
                    <h1 className="c-filters-shared__col-title">{t("Filtros Compartidos")}</h1>
                    <ul className="c-filters-shared-list">
                        {this.props.savedFilterModelsShared.map((value, index) => (
                            <li className="c-filters-shared-list__item" key={value.name + index}>
                                <span onClick={() => this.props.onClickFavourite(value)}
                                      className="c-filters-shared-list__item-link">{value.name}</span>
                            </li>
                        ))
                        }
                    </ul>
                </article>
                <AlertModal isOpen={this.propsUtil.getRequest("rightModal") === "2"}
                            onCloseModal={() => this.propsUtil.changeUrlRequest({ rightModal: null, popUp: null })}
                >

                    {this.propsUtil.getRequest("popUp") === "eliminar" &&
                    <PopUp
                        title={t('Eliminar')}
                        icon="fas fa-exclamation-triangle"
                        text={t('¿Estas Seguro de Eliminar este filtro?')}
                        label1={'No eliminar'}
                        label2={'Eliminar'}
                        onClick1={() => this.noCancel()}
                        onClick2={() => this.onDeleteFilter()}
                    >
                    </PopUp>
                    }
                </AlertModal>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


export default withRouter(withTranslate(FilterSharedComponent));

