import React from "react";
import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";

export default class Article extends AbstractModel {

    id;
    code;
    enable;
    name;
    family;
    subfamily;
    description;
    manufacturer;
    model;
    manufacturerRef;
    warranty;
    endDateWarranty;
    barCode;
    documentation;
    type;

    nameMainType="article";
    graphFindByIdOperation="article";
    graphFindOperation="articlesConnection";
    graphExportOperation="articlesExport";

    getArrayFields() {
        let result = {
            "id":{type:"CodeField"},
            "code":"",
            "enable":{ label: "Disponibilidad" },
            "name":{ label: "Nombre" },
            "family":{ label: "Familia" },
            "subfamily":{ label: "Subfamilia" },
            "description":{ label: "Descripción" },
            "manufacturer":"",
            "model":{ label: "Modelo" },
            "manufacturerRef":"",
            "warranty":{ label: "Garantía" },
            "endDateWarranty":{ label: "Fecha fin de garantía" },
            "type":{ label: "Tipo" },
            "uRLbarCode":"",
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }

}
