import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import util from '../../util/Util';
import moment from "moment";

@observer
export default class IsoDateListItem extends Component {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        id: PropTypes.string,
        // Objeto con todos los campos que estamos mostrando
        row: PropTypes.object,
        // Nombre del campo a mostrar
        name: PropTypes.string,
        // Valor del campo a mostrar
        value: PropTypes.string,
    }


    render() {
        let props= this.props;

        let fecha = moment(props.value).format("DD-MM-YYYY");
        return (
            <div className="isoDate">
                {fecha}
                {props.children}
            </div>
        );
    }
}
