import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withTranslate from "../translator/withTranslate";
import {withApollo} from "react-apollo";

@observer
class ListPageCounts extends Component {

    constructor(props)
    {
        super(props);
    }

    render() {
        const {t, i18n} = this.props;
        const params = new URLSearchParams(this.props.location.search);
        let page = parseInt(params.get('page'));
        if (page==0 || isNaN(page)) {
            page=1;
        }

        let props= this.props;
        let totalCount = props.listData.connection.totalCount;
        let from = this.props.numEntriesPerPage * (page-1) + 1;
        let to = from+this.props.listData.connection.items.length - 1;

        return (
            <React.Fragment>
                {t('Mostrando del') } {from} - {to} {t("de")} { totalCount} { t('resultados')}
            </React.Fragment>
        );
    }
  };

ListPageCounts.defaultsProps = {
    /** Connection respuesta de Graphql con el n�mero de elementos mostrados */
    connection: null,
    numEntriesPerPage: 1,
};
export default withTranslate(ListPageCounts);
