import React, { Component } from 'react';
import AppLogger from "../util/AppLogger";

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.log(info);
        this.log(error);
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    render() {
        let hasError=this.state.hasError;
        //hasError=true;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <h1>Something went wrong.</h1>
                    <div>
                        Examine console for detailed error
                    </div>
                </>
            );
        }
        return this.props.children;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}