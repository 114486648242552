import React from 'react';
import { observer } from 'mobx-react';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import BaseEditableRow from "../base/BaseEditableRow";
import { computed, toJS } from "mobx";
import appState from "../../state/AppState";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import WorkOrderModel from "../../models/WorkOrderModel";
import Select2Component from "../../components/fields/Select2Component";
import ArticleModel from "../../models/ArticleModel";
import InfoBlock from "../../components/InfoBlock";
import Overlay from "../../components/Overlay";
import WorkOrdersSelectInOrderForm from "../../components/WorkOrdersSelectInOrderForm";
import { withApollo } from "react-apollo";
import withTranslate from "../../translator/withTranslate";
import AutoSuggestComponent from "../../components/fields/AutoSuggestComponent";
import util from "../../util/Util";

@observer
class OrderFormArticle extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });

    constructor(props) {
        super(props);
        this.foreingKeyfield = "orderId";
    }


    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    setMobxListado(newValue) {
        appState.workOrderCartArticlesState.workOrderArticles = newValue;
    }

    async componentDidMountImpl() {
        super.componentDidMountImpl();
        await appState.assetState.getCountArticlesFromOrder(this.getFormId());
        await appState.assetState.getCountSlotsFromOrder(this.getFormId());
        await appState.typifiedState.loadWorkOrderType();
        await appState.typifiedState.arrayLoadWorkOrderType();
        await appState.typifiedState.loadSubTypeWorkOrder();
        await appState.typifiedState.arrayLoadsubTypeWorkOrders();

        if (this.props.fromWorkOrder) {
            this.foreingKeyfield = "workOrderId";
            await appState.assetState.getCountArticlesFromWorkOrder(this.getFormId());
            await appState.assetState.getCountSlotsFromWorkOrder(this.getFormId());
        }
    }

    getArticlefromArticleId(articleId) {
        let result = {};
        if (util.hasValue(articleId)) {
            for (let article of appState.articlesState.articles) {
                if (util.hasValue(articleId)) {
                    if (article.id === articleId) {
                        result = article;
                        break;
                    }
                }
            }
        }
        return result;
    }


    getArticleFromCode(code) {
        this.log("getArticleFromCode.render()"+ code);
        this.log(appState.articlesState.articles);
        let articleSelected;
        if (util.hasValue(code)) {
            for (let article of appState.articlesState.articles) {
                if (article.code === code) {
                    articleSelected = article;
                    break;
                }
            }
        } else {
            articleSelected = {};
        }
        this.log(articleSelected);
        return articleSelected;
    }

    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }


    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    async getArticlesConection(value) {
        this.log("getArticlesConection.render()");
        let articlesArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let signal = this.signal;
            // Obtenemos los Assets
            let articlesQuery = new ArticleModel();
            articlesQuery.filters = [
                { "fieldName": "code", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let articles = await articlesQuery.find();
            // Transformamos a formato Autosuggest
            articles.map((article) => {
                let articleObject = {};
                articleObject.label = article.code;
                articleObject.value = article.code;
                articlesArray.push(articleObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        this.log(articlesArray);
        return articlesArray;
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }

    showSelectsWorksOrder() {
        appState.layoutState.favouritesDropMenuOpen = true;
    }

    updateInputEvent(event) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        this.updateInput(event.target.name, event.target.value)
    }

    updateInput(key, value) {
        let workOrderArticle = this.props.row;
        workOrderArticle.workOrderId = value;
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = workOrderArticle;
        }
    }

    render() {
        this.log("OrderFormArticle.render()");
        const { t, i18n } = this.props;
        let workOrderArticle = this.props.row;
        let workOrderCode = this.props.workOrders.map((workor) => {
            let workOrderModel = new WorkOrderModel();
            let workOrderFounded = new WorkOrderModel();
            workOrderModel.hidrate(workor);
            if (workOrderModel.id == workOrderArticle.workOrderId) {
                workOrderFounded = workOrderModel;
            }
            return workOrderFounded.code;
        });
        let errorsMapped = this.getGraphErrorsFromStatus();
        this.log(errorsMapped);

        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                {errorsMapped[""] != null &&
                <div className="alert alert-danger alertsticky" role="alert">
                    {errorsMapped[""].map(error => <div>{error.message}</div>)}
                </div>

                }
                <tr>
                    <td scope="row">
                        {workOrderArticle.isEditing ?
                            <AutoSuggestComponent
                                value={this.getArticlefromArticleId(workOrderArticle.articleId).code}
                                onChange={(articleCodeSelected) => {
                                    this.handleAutosuggestSelection("articleCode", articleCodeSelected);
                                    if (workOrderArticle.articleCode !== '') {
                                        workOrderArticle.articleId = this.getArticleFromCode(articleCodeSelected).id;
                                        workOrderArticle.articleName = this.getArticleFromCode(articleCodeSelected).name;
                                    } else {
                                        workOrderArticle.articleId = "";
                                        workOrderArticle.articleName = "";
                                    }
                                }}
                                name={"articleCode"}
                                errors={errorsMapped.articleCode}
                                loadSuggestions={(value) => this.getArticlesConection(value)}
                                placeholder={t("Escribe...")}
                            />
                            :
                            <>
                                {this.getArticlefromArticleId(workOrderArticle.articleId).code}
                            </>
                        }

                    </td>
                    <td scope="row">
                        {workOrderArticle.isEditing ?
                            <InputTypeComponent
                                value={workOrderArticle.articleName}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"articleName"}
                                type={"text"}
                                readOnly={true}
                                className="form-control"
                                errors={errorsMapped.articleName}
                            />
                            :
                            <>
                                {workOrderArticle.articleName}
                            </>
                        }

                    </td>
                    <td>
                        {workOrderArticle.isEditing ?
                            <InputTypeComponent
                                value={workOrderArticle.quantity}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"quantity"}
                                type={"text"}
                                validate={"number"}
                                showView={!workOrderArticle.isEditing}
                                className="form-control"
                                errors={errorsMapped.quantity}
                            />
                            :
                            <>
                                {workOrderArticle.quantity || 1}
                            </>
                        }
                    </td>
                    <td>
                        {workOrderArticle.isEditing ?
                            <InputTypeComponent
                                value={workOrderArticle.articlePrice}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"articlePrice"}
                                validate={"decimal"}
                                showView={!workOrderArticle.isEditing}
                                className="form-control"
                                errors={errorsMapped.articlePrice}
                            />
                            :
                            <>
                                {workOrderArticle.articlePrice + " €"}
                            </>
                        }
                    </td>
                    <td>
                        {workOrderArticle.isEditing ?
                            <InputTypeComponent
                                value={workOrderArticle.articleCost}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"articleCost"}
                                validate={"decimal"}
                                showView={!workOrderArticle.isEditing}
                                className="form-control"
                                errors={errorsMapped.articleCost}
                            />
                            :
                            <>
                                {workOrderArticle.articleCost + " €"}
                            </>
                        }
                    </td>

                    <td>
                        {
                            isNaN((parseFloat(workOrderArticle.price) * parseFloat((workOrderArticle.quantity)))) ?
                                (0 + " €")
                                :
                                ((parseFloat(workOrderArticle.price.replace(",", ".")) * parseFloat((workOrderArticle.quantity) || 1)).toFixed(2)) + " €"
                        }
                    </td>


                    <td>
                        {workOrderArticle.isEditing ?
                            <Select2Component
                                value={workOrderArticle.statusId}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"status"}
                                options={appState.typifiedState.statusArticlesForSelect}
                                errors={errorsMapped.status}
                                showView={!workOrderArticle.isEditing}
                            />
                            :
                            <>
                                {appState.typifiedState.arrStatusArticles[workOrderArticle.status]}
                            </>
                        }
                    </td>
                    {!this.props.fromWorkOrder &&
                    <td>

                        {workOrderArticle.isEditing ?
                            <>
                                <div>{workOrderCode}</div>
                                <button onClick={() => {
                                    this.showSelectsWorksOrder()
                                }} id="btnGroupColumns" type="button"
                                        className="btn btn-outline-secondary dropdown-toggle"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    {t("Seleccione una orden de trabajo ")}
                                </button>
                                <Overlay show={appState.layoutState.favouritesDropMenuOpen}
                                         onClick={_ => appState.layoutState.favouritesDropMenuOpen = false} />
                                {appState.layoutState.favouritesDropMenuOpen &&
                                <div className="table-responsive">

                                    <table className="dropdown-sublist table" style={{ zIndex: 100 }}>


                                        <WorkOrdersSelectInOrderForm
                                            fromArticles={false}
                                            values={workOrderArticle.workOrderId}
                                            {...this.props}
                                            onChange={(e) => this.updateInputEvent(e)}
                                        />
                                    </table>

                                </div>
                                }
                                {errorsMapped.workOrderId && errorsMapped.workOrderId.map(error =>
                                    <small className="js-error help-block text-danger">{error.message}</small>)}

                            </>
                            :
                            <div>{workOrderCode}</div>
                        }
                    </td>
                    }

                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }
}

export default withApollo(withTranslate(OrderFormArticle));
