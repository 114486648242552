import React from 'react';
import { observer } from 'mobx-react';
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import withTranslate from "../../translator/withTranslate";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import Select2Component from "../../components/fields/Select2Component";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import DateInputField from "../../components/fields/DateInputField";
import ArticleModel from "../../models/ArticleModel";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import RadioButtonComponent from "../../components/fields/RadioButtonComponent";
import MoreButtons from "../../components/MoreButtons";
import ArticleCodesTable from "./ArticleCodesTable";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/PopUp";
import InfoBlock from "../../components/InfoBlock";
import util from "../../util/Util";
import PageTitleRightModal from "../../components/PageTitleRightModal";

@observer
class ArticleForm extends BaseForm {

    constructor(props) {
        super(props);
        this.nameMainType = "articles";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
            copyArticle: {}
        }
    }


    getModelQuery() {
        return new ArticleModel();
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        appState.typifiedState.loadArticleFamily();
        appState.typifiedState.loadArticleSubfamily();
        appState.typifiedState.loadArticleType()

    }

    duplicar(article) {
        this.log(article);
        let copyArticle = article;
        this.log('copia de articulo');
        this.log(copyArticle);
        this.setState(copyArticle.id = null)
        this.handleFormSubmit()
    }

    clickErroresFormulario() {
        this.log("clickErroresFormulario()")
        this.setState({
            errorFormulario: false
        })
        this.graphStatus.mutationError = 0;
    }

    render() {
        const { t, i18n } = this.props;
        let id = this.getFormId() || this.props.id;
        let modalClasses = util.getModalClasses();
        let article = this.graphDataMainType;
        if (article == null) {
            article = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""].map(error => error.message)
            this.state.errorFormulario = true;
        }

        let optionsSiNo = [
            { label: "si", value: "1", id: "1" },
            { label: "no", value: "0", id: "0" },
        ];
        let optionsWarranty = [
            { label: "si", value: "1", id: "warranty1" },
            { label: "no", value: "0", id: "warranty0" },
        ];

        return (

            <form
                className="model-form"
                name="formulario"
                noValidate
                onSubmit={(e) => this.handleFormSubmit(e)}
            >

                <InfoBlock componentObject={this}>
                    <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal
                                    title={"Repuestos"}
                                    subtitle={this.graphDataMainType.code || t("Nueva")}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}
                                />

                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataArticles()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                            </div>
                            :
                            <PageTitle
                                title={t("Repuestos")}
                                subtitle={this.graphDataMainType.code || t("Nueva")}
                            > <MoreButtons
                                actions={
                                    [
                                        { title: "Duplicar", onClick: () => this.duplicar(article) }
                                    ]
                                }
                            />
                            </PageTitle>
                        }

                        <div className={'card mb-3'}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataArticles()}
                                active={"Datos Generales"}
                            />
                            }
                            <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                               error={this.graphStatus.mutationError}
                                               working={this.graphStatus.networkWorking}
                            />
                            <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                                formError={this.graphStatus.formError}
                            />
                            {errorsMapped[""] != null ?
                                <div className="alert alert-danger" role="alert">
                                    {errorsMapped[""].map(error => <div>{error.message}</div>)}
                                </div>
                                :
                                null
                            }
                            <FormWrapper>
                                <div className={modalClasses.body}>
                                    <GroupOfField title={t("Datos")}
                                                  subtitle={t("Pieza")}
                                                  icon="fa-clipboard"
                                    >
                                        <AlertModal isOpen={this.state.errorFormulario}
                                                    onCloseModal={() => this.clickErroresFormulario()}
                                        >
                                            <PopUp
                                                title={t('Error')}
                                                icon="fas fa-exclamation-triangle"
                                                text={textoErrores}
                                                label1='Aceptar'
                                                onClick1={() => this.clickErroresFormulario()}
                                            >
                                            </PopUp>
                                        </AlertModal>
                                        <InputTypeComponent
                                            value={article.code}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"manufacturer"}
                                            title={t("Referencia (Interna)")}
                                            placeholder=""
                                            classGroup={"col-3"}
                                            type={"text"}
                                            errors={errorsMapped.manufacturer}
                                            readOnly={true}
                                        />

                                        <Select2Component
                                            value={article.type}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"type"}
                                            title={t("Tipo")}
                                            classGroup="col-3"
                                            options={appState.typifiedState.articleTypeForSelect}
                                            //errors={errorsMapped.emploiment}
                                        />

                                        <RadioButtonComponent
                                            value={article.enable || "1"}
                                            title={t("Disponibilidad")}
                                            styleLabel={'m-5'}
                                            options={optionsSiNo}
                                            checked={"1"}
                                            name={"enable"}
                                            classGroup={"col-2 col-md-5"}
                                            onChange={(e) => this.updateInputEvent(e)}
                                        />
                                        <InputTypeComponent
                                            value={article.name}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"name"}
                                            title={t("Nombre")}
                                            placeholder="Nombre"
                                            classGroup={"col-6"}
                                            type={"text"}
                                            errors={errorsMapped.name}
                                        />
                                        <Select2Component
                                            value={article.family}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"family"}
                                            title={t("Familia")}
                                            classGroup="col-3"
                                            options={appState.typifiedState.articleFamilyForSelect}
                                            //errors={errorsMapped.emploiment}
                                        />
                                        <Select2Component
                                            value={article.subfamily}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"subfamily"}
                                            title={t("Sub-Familia")}
                                            classGroup="col-3"
                                            options={appState.typifiedState.articleSubfamilyForSelect}
                                            //errors={errorsMapped.emploiment}
                                        />


                                        <InputTypeComponent
                                            value={article.description}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"description"}
                                            title={t("Descripción")}
                                            placeholder="Descripción"
                                            classGroup={"col-12"}
                                            type={"text"}
                                            errors={errorsMapped.description}
                                        />
                                    </GroupOfField>
                                    <GroupOfField title={t("Códigos de Referencia")} icon="fa-clipboard">

                                        <ArticleCodesTable
                                            classGroup={"col-8"}
                                            editing={!util.hasValue(this.graphDataMainType.id)}
                                            foreingKeyfield={this.foreingKeyfield}
                                        />
                                    </GroupOfField>

                                    <GroupOfField title={t("Garantías")} icon="fa-clipboard">
                                        <RadioButtonComponent
                                            value={article.warranty || "0"}
                                            title={t("Garantía")}
                                            styleLabel={'m-5'}
                                            name={"warranty"}
                                            checked={"0"}
                                            options={optionsWarranty}
                                            classGroup={"col-2"}
                                            onChange={(e) => this.updateInputEvent(e)}
                                        />
                                        {article.warranty == 1 ?
                                            <DateInputField
                                                value={article.endDateWarranty}
                                                prefix={"fas fa-calendar-alt"}
                                                title={t("Fecha Fin de la Garantía")}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"endDateWarranty"}
                                                type={"text"}
                                                classGroup={"col-3"}

                                            />
                                            :
                                            <InputTypeComponent
                                                value={""}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"endDateWarranty"}
                                                title={t("Fecha Fin de la Garantía")}
                                                placeholder=""
                                                classGroup={"col-3"}
                                                type={"text"}
                                                errors={errorsMapped.manufacturer}
                                                readOnly={true}
                                            />
                                        }


                                    </GroupOfField>
                                </div>
                            </FormWrapper>
                        </div>
                        {this.props.fromRightModal ?
                            <div className=" fixed-bottom">
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </div>
                            :
                            <FormButtons id={id} formStatus={this.graphStatus} />
                        }

                    </div>
                </InfoBlock>

            </form>

        )
    }
}


export default withTranslate(withApollo(ArticleForm));
