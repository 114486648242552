import DocumentModel from "./DocumentModel";
import AbstractModel from "./AbstractModel";
import TypifiedValueModel from "./TypifiedValueModel";
import ClientModel from "./ClientModel";



export default class AssetModel extends AbstractModel {
    category;
    createdAt;
    createdBy;
    description;
    endDateWarranty;
    erpCode;
    exploitation;
    id;
    location;
    maintenance;
    manufacturer;
    manufacturerRef;
    model;
    subType;
    plate;
    pos;
    posAddress;
    propietary;
    serial;
    zone;
    serialNumber;
    status;
    storeId;
    type;
    updatedAt;
    updatedBy;
    warranty;

    nameMainType="asset";
    graphFindByIdOperation="asset";
    graphFindOperation="assetsConnection";
    graphExportOperation="assetsExport";

    getArrayFields() {
        let result = {
            "id":{type:"CodeField"},
            "category":"",
            "createdAt":{readonly:true,label:"Creado"},
            "createdBy":{readonly:true,label:"Creado"},
            "endDateWarranty":{label: 'Fecha fin de garantia'},
            "erpCode":"",
            "exploitation":{ label: "Explotacion" },
            "location":{label: 'Ubicacion'},
            "maintenance":{ label: "Mantenimiento" },
            "manufacturer":{label: 'Fabricante'},
            "manufacturerRef":"",
            "model":{label: 'Modelo'},
            "plate":{label: 'Numero de Equipo'},
            "subType":{label: 'Subtipo de Equipo'},
            "pos":"",
            "posAddress":{ label: "Dirección" },
            "propietary":{ label: "Propietario" },
            "serial":"",
            "serialNumber":{label: 'Numero de Serie'},
            "status":{label: 'Estado'},
            "storeId":{label: 'Emplazamiento'},
            "zone":{label: 'Zona'},
            "type":{label: 'Tipo de Equipo'},
            "updatedAt":{readonly:true,label:"Actualizado en"},
            "updatedBy":{readonly:true,label:"Actualizado por"},
            "warranty":{ label: "Garantía" },
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        this.addRelatedStatusAsset(result, TypifiedValueModel);
        this.addRelatedTypeAsset(result, TypifiedValueModel);
        this.addRelatedManufacturerAsset(result, TypifiedValueModel);
        this.addRelatedTableAssetSubType(result, TypifiedValueModel);

        this.addRelatedTableClientPos(result, ClientModel);
        this.addRelatedTableClientPropietary(result, ClientModel);


        return result;
    }
};




















