import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {dir} from "async";
import {Link} from "react-router-dom";
import Links from "../util/Links";
import VsfLink from "./VsfLink";
import AppLogger from "../util/AppLogger";

import ScrollEvent from 'react-onscroll';
import ResizeDetector from '../util/ResizeDetector';

import appState from "../state/AppState";

/*
let columnsSelect = [
    {title:"Name", name:"name"}
];
*/

@observer

class SortTableHeadsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productAvailability: [],
            showFixedTableHeader: false,
            showFixedTableHeaderFired: false,
            memoryTableHeaderLeft: 0,
            left: 0
        };

        this.handleScrollCallback = this.handleScrollCallback.bind(this);
        this.onResizeCallback = this.onResizeCallback.bind(this);

    }

    componentDidMount() {
        //console.log(this.props.location);
        //const params = new URLSearchParams(this.props.location.pathName);
    }

    getUrlLinkOrder(newFieldOrder) {
        let links = new Links(this.props.location);
        return links.getUrlLinkOrder(newFieldOrder);
    }

    onResizeCallback() {
        this.resizeStickTableHeaderIfNeeded();
    }

    handleScrollCallback() {
        this.resizeStickTableHeaderIfNeeded();
    }

    /**
     * Controlamos el cambio entre thead normal y fixed con sus consecuencias
     *
     */
    resizeStickTableHeaderIfNeeded(){

        this.log("resizeStickTableHeaderIfNeeded() =>");

        this.log(this.props.scrollLeft);
        this.log(this.props.scrollWidth);
        this.log(this.state.memoryTableHeaderLeft);

        // Activamos por defecto a false el cambio entre normal y sticky
        let showFixedTableHeaderFired = false;

        // Si el scroll general de la pagina es superior a 150 (el tamaño entre el top 0 y el thead de una tabla) activamos (o seguimos activando) la sticky header
        if(window.scrollY > 150){

            this.log("ACTIVAR STICKY");

            // Obtenemos el ancho actual de la tabla completa
            let width = document.getElementById("table").offsetWidth;
            this.width = width;

            // Si no estabamos en sticky antes, lanzamos el evento de entrar en sticky para guardar el left que tendra la thead sticky
            if(!this.state.showFixedTableHeader){
                showFixedTableHeaderFired = true;
                this.saveLeft();
            }

            // Guardamos la informacion en el estado
            this.setState({
                showFixedTableHeader: true,
                showFixedTableHeaderFired,
                fixedTableWidth: width
            });

            // Calculamos los anchos de las columnas de la thead en sticky fixed
            let tds = document.querySelectorAll(".table tr:first-child td");
            let newState=[];
            for (let i=0;i<tds.length;i++) {
                let width0 = tds[i].offsetWidth;
                newState["fixedTableWidth_"+i]=width0;
            }

            this.setState(newState);

        } else {

            // Si el width es mayor que cero, es que estabamos previamente en sticky y no en un limbo de scroll
            if (this.width>0) {

                this.log("DESACTIVAR STICKY");

                // Guardamos en memoria el left que tenia la tabla en sticky para recuperarlo para luego
                this.saveLeft();

                // Reinicio width para que cuando vuelva a necesitar salir la stick header lo haga correctamente
                this.width=-1;

                // Guardamos la informacion en el estado

                this.setState({
                    fixedTableWidth: "100%",
                    showFixedTableHeader: false
                });

            }

        }

        this.log("FIN resizeStickTableHeaderIfNeeded()");

    }

    /**
     * Guardamos el left actual en memoria
     *
     */
    saveLeft(){

        this.log("recalcLeft");

        // Este es el left a guardar en memoria
        this.state.memoryTableHeaderLeft = this.state.left;

        this.log("FIN recalcLeft()");

    }

    /**
     * Calculamos el left que tendra el thead desde memoria si hemos dado un salto o con las props
     *
     * @returns {number}
     */
    calcLeft() {

        this.log("calcLeft");

        // Primero, obtenemos el left de la memoria por si hemos cambiado de normal a fixed
        let finalLeft = this.state.memoryTableHeaderLeft;

        // Si el scroll es correcto (no ha dado un salto extraño, que lo da cuando cambiamos de modo de thead en el limbo de scroll), lo aplicamos al left
        if(this.props.scrollLeft < this.props.scrollWidth - 10) {

            let initialLeft = 0;

            // Añadimos el left inicial dependiendo si el panel esta abierto o cerrado
            if (appState.layoutState.sidebarMinimized == 0) {
                initialLeft = 245;
            } else {
                initialLeft = 87;
            }

            finalLeft = initialLeft - this.props.scrollLeft;

        }

        this.state.left = finalLeft;

        this.log("FIN calcLeft()");

    };

    /**
     * Cada vez que cambian las props de anchura y scroll de tabla, actualizamos el left del thead
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        this.calcLeft();
    }

    /**
     * Obtiene la clave de la columna del thead para pintar su anchura correctamente
     *
     * @param key
     * @param showColumnActions
     * @returns {string}
     */
    getThIndex(key, showColumnActions){

        let index = "fixedTableWidth_";

        // Dependiendo de si mostramos el boton de accion rapida, sumamos o restamos al indice
        if(showColumnActions){
            index += (key + 0);
        } else {
            index += (key - 1);
        }

        return index;

    }

    render() {
        const params = new URLSearchParams(this.props.location.search);
        let props= this.props;
        const pathname = this.props.location.pathname;
        let columnsSelect = this.props.fields;
        const fieldOrder = params.get("order");
        const orderMode = params.get("orderMode");
        let showColumnActions=false;
        if (props.quickEdit || this.props.quickView ) {
            showColumnActions=true;
        }
        showColumnActions=true;
        return (
            <thead className={this.state.showFixedTableHeader ? "thead-fixed" : ""} style={ {"left": this.state.left, "width": this.state.fixedTableWidth} }>

                <ResizeDetector onResize={this.onResizeCallback} />

                <ScrollEvent handleScrollCallback={this.handleScrollCallback} />

                <tr>
                    { showColumnActions &&
                    <th style={ {"width":this.state["fixedTableWidth_0"]} }>

                    </th>
                    }
                    {columnsSelect.map( ((column, key) =>
                        column.visible &&
                            <th
                                key={column.name}
                                scope="col"
                                data-col={column.name}
                                className={fieldOrder==column.name?"active":""}
                                style={ {"width": this.state[this.getThIndex(key, showColumnActions)]} }
                            >
                                {column.withOrder===false ?
                                        <span>{column.title}</span>
                                    :
                                    <VsfLink title="ID Siniestro: Ordenar por esta columna Ascendentemente (A->Z)" to={this.getUrlLinkOrder(column.name)}>
                                        <span>{column.title}</span>
                                        {fieldOrder==column.name?
                                            (
                                                <React.Fragment>
                                                    {orderMode==="DESC"?
                                                        <i className="fas fa-sort-up"></i>
                                                        :
                                                        <i className="fas fa-sort-down"></i>
                                                    }
                                                </React.Fragment>
                                            )
                                            :
                                            null
                                        }

                                    </VsfLink>
                                }

                            </th>
                    ))}
                </tr>
            </thead>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default SortTableHeadsComponent;