import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import VsfButton from "../../components/VsfButton";
import BaseEditableTable from "../base/BaseEditableTable";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import { computed } from "mobx";
import InfoBlock from "../../components/InfoBlock";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import util from '../../util/Util';
import WorkOrderModel from "../../models/WorkOrderModel";
import OrderFormArticle from "../orders/OrderFormArticle";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";
import Select2Component from "../../components/fields/Select2Component";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import DateInputField from "../../components/fields/DateInputField";

@observer
class ArticleFormLocation extends BaseEditableTable {

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "articles";
        this.initializeGraphData(this.nameMainType);
    }

    @computed get mobxListado() {
        return appState.articlesState.articles;
    }


    getModelTable() {
        return new ArticleModel();
    }

    getModelQuery() {
        return new ArticleModel();
    }


    render() {
        this.log("ArticleFormLocation render =>");
        const { t, i18n } = this.props;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let article = this.graphDataMainType;

        return (

            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Repuestos"}
                                subtitle={article.code || t("Nueva")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId() || this.props.articleId}
                                tabs={this.tabsData.getDataArticles()}
                                active="Ubicación"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Repuestos")}
                            subtitle={article.code || t("Nueva")}
                        />
                    }
                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataWorkOrder()}
                            num={1}
                            active={"Ubicación"}
                        />
                        }
                        <div className={modalClasses.body}>

                            <div className="card-body">
                                <div className="row">
                                    <GroupOfField
                                        title={t('Ubicación')}
                                        subtitle={t('Repuestos')}
                                        icon='fas fa-calendar'
                                    />
                                </div>
                                <div className="row mt-4">
                                    <div className="table-edit">
                                        <div className="col-12">
                                            <table className="table visible-text"
                                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                                <thead>
                                                {this.mobxListado.length == 0 &&
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{t('No hay ninguna pieza creada')}
                                                    </td>
                                                </tr>
                                                }
                                                {this.mobxListado.length != 0 &&
                                                <tr>
                                                    <th scope="col">{t("Almacén")}</th>
                                                    <th scope="col">{t("Ubicación")}</th>
                                                    <th scope="col">{t("Unidades Disponibles")}</th>
                                                    <th scope="col">{t("Unidades pedidas")}</th>
                                                    <th scope="col">{t("Fecha Estimada de Recepción")}</th>
                                                    <th scope="col">{t("Fecha real de recepcion")}</th>

                                                    <th>&nbsp;</th>

                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">

                                                {this.mobxListado.map((article, index) => (
                                                    <ArticleFormLocationRow
                                                        key={article.id}
                                                        orderId={this.props.orderId}
                                                        rowIndex={index}
                                                        row={article}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    />
                                                ))}

                                                <tr>
                                                    <td scope="row">
                                                        <div>
                                                            {"Almacen_1"}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"Direccion Almacen_1"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"140"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"15000"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"8-Mar 2019"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"9 Mar 2019"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"Botones"}
                                                        </div>

                                                        {/*{this.renderEditBlock()}*/}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">

                                                        <Select2Component
                                                            // value={article.articleName}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            name={"articleName"}
                                                            //options={orderArticleOptions}
                                                        />


                                                    </td>
                                                    <td>
                                                        <InputTypeComponent
                                                            // value={article.quantity}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            name={"quantity"}
                                                            //  showView={!article.isEditing}
                                                            className="form-control"
                                                            readOnly={true}

                                                        />
                                                    </td>
                                                    <td>
                                                        <InputTypeComponent
                                                            // value={article.price}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            name={"price"}
                                                            //  showView={!article.isEditing}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputTypeComponent
                                                            // value={article.status}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            name={"status"}
                                                            // showView={!article.isEditing}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInputField
                                                            prefix={"fas fa-calendar-alt"}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            name={"scheduledTime"}
                                                            type={"text"}
                                                            classGroup={"col-3"}

                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInputField
                                                            prefix={"fas fa-calendar-alt"}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            name={"scheduledTime"}
                                                            type={"text"}
                                                            classGroup={"col-3"}

                                                        />
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {"Botones"}
                                                        </div>
                                                    </td>
                                                </tr>


                                                </tbody>
                                            </table>
                                            <div className="col-12 text-right">
                                                <VsfButton
                                                    disabled={this.state.hasSomeRowEditing}
                                                    label={"Crear nueva"}
                                                    onClick={_ => this.onOpenRowNewEmpty()}
                                                    isEditing={this.state.hasSomeRowEditing}
                                                    classButton="btn--loader"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo(withTranslate(ArticleFormLocation)));
