import React from 'react';
import { observer } from 'mobx-react';
import {withRouter } from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import {computed} from "mobx";
import PropTypes from 'prop-types';
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import SlotModel from "../../models/SlotModel";
import InfoBlock from "../../components/InfoBlock";
import SlotsTable from "../../components/SlotsTable";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import WorkOrderModel from "../../models/WorkOrderModel";
import DateInputField from "../../components/fields/DateInputField";
import AppLogger from "../../util/AppLogger";
import withTranslate from "../../translator/withTranslate";

@observer
class WorkOrderFormSlots extends BaseForm {

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
    }

    @computed get mobxListado() {
        return appState.slotState.slots;
    }

    getModelQuery() {
        let result = new WorkOrderModel();
        return result;
    }

    getModelTable() {
        return new SlotModel();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        // await appState.order
    }


    render() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        const { t } = this.props;
        let workOrder = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {errorsMapped[""] != null ?
                        <div className="alert alert-danger" role="alert">
                            {}
                            {errorsMapped[""].map(error => <div>{error.message}</div>)}
                        </div>
                        :
                        null
                    }
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Orden de Trabajo"}
                                subtitle={workOrder.code || t("Nuevo")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Planificación"}
                                fromRightModal={this.props.fromRightModal}

                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Orden de Trabajo")}
                            subtitle={workOrder.code || t("Nuevo")}
                        />
                    }

                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataWorkOrder()}
                            active={"Planificación"}
                        />
                        }
                        <div className={modalClasses.body}>

                            <div className="card-body">
                                <div className="row align-items-center">
                                    <GroupOfField
                                        title='Orden de Trabajo'
                                        subtitle='Asignación'
                                    />
                                </div>
                                <SlotsTable
                                    foreingKeyfield={"workOrder"}
                                    workOrders={appState.workOrderState.workOrders}
                                    fromWorkOrder={true}
                                    idWorkOrder={this.getFormId()}
                                />
                                <DateInputField
                                    prefix={"fas fa-calendar-alt"}
                                    value={workOrder.interventionProposedDate}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"interventionProposedDate"}
                                    title={t("Periodo de Ejecución")}
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}

                                    // errors={errorsMapped.interventionProposedDate}
                                />
                            </div>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo(withTranslate(WorkOrderFormSlots)));
