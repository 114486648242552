import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import VsfButton from "../../components/VsfButton";
import BaseEditableTable from "../base/BaseEditableTable";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import { computed } from "mobx";
import InfoBlock from "../../components/InfoBlock";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import util from '../../util/Util';
import WorkOrderModel from "../../models/WorkOrderModel";
import OrderFormArticle from "../orders/OrderFormArticle";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";

@observer
class WorkOrderFormArticles extends BaseEditableTable {

    constructor(props) {
        super(props);
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.foreingKeyfield = "workOrder";
        this.state = {
            orderId: 0,
            fromWorkOrder: true,
            hasSomeRowEditing: false
        }
    }

    @computed get mobxListado() {
        return appState.WorkOrderCartArticlesState.workOrderArticles;
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }

    async componentDidMountImpl() {
        super.componentDidMountImpl();
        this.log("componentDidMountImpl(---------)");
        await appState.articlesState.getArticles();
        await this.reloadTable(this.getFormId());
    }

    async reloadTable(workOrderId) {
        this.log("reloadTable()");
        let workOrderQuery = new WorkOrderModel();
        let workOrderModel = await workOrderQuery.findById(workOrderId)
        let ordersArticlesQuery = new WorkOrderCartArticleModel();
        let ordersArticlesModels = await ordersArticlesQuery.getOrderArticlesFromOrder(workOrderModel.orderId);
        this.setState({
            orderId: workOrderModel.orderId
        });

        let misOrdersArticles = [];
        for (let orderArticle of util.arrayModelToPlainObjects(ordersArticlesModels)) {
            if (this.getFormId() == orderArticle.workOrderId) {
                misOrdersArticles.push(orderArticle);
            }
        }
        appState.WorkOrderCartArticlesState.workOrderArticles = misOrdersArticles;

    }


    calculaPrecio() {
        let result = 0;
        let articulos = appState.WorkOrderCartArticlesState.workOrderArticles;
        articulos.map((articulo, index) => {
            if (articulo.articlePrice != null) {
                if (articulo.articlePrice != '' && articulo.quantity != '') {
                    if (articulo.quantity != null) {
                        if (result == 0)
                            result = parseInt(articulo.articlePrice) * parseInt(articulo.quantity);
                        else
                            result += parseInt(articulo.articlePrice) * parseInt(articulo.quantity);
                    } else {
                        if (result == 0)
                            result = parseInt(articulo.articlePrice);
                        else {
                            result += parseInt(articulo.articlePrice);
                        }
                    }
                }
            }
        });

        console.log("eeeeeee22" + result);
        return result;
    }

    render() {
        this.log("WorkOrderArticles render =>");
        const { t, i18n } = this.props;
        let modalClasses = util.getModalClasses();
        let precio = this.calculaPrecio();
        this.propsUtil = new PropsUtil(this.props);
        let articles = this.mobxListado;
        let workOrder = this.graphDataMainType;

        return (

            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Orden de Trabajo"}
                                subtitle={workOrder.code || t("Nueva")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId() || this.props.workOrderId}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active="Piezas"
                                numAlarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Orden de Trabajo")}
                            subtitle={workOrder.code || t("Nueva")}
                        />
                    }
                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataWorkOrder()}
                            num={1}
                            active={"Piezas"}
                        />
                        }
                        <div className={modalClasses.body}>

                            <div className="card-body">
                                <div className="row">
                                    <GroupOfField
                                        title={t('Orden de Trabajo')}
                                        subtitle={t('Repuestos')}
                                        icon='fas fa-calendar'
                                    />
                                </div>
                                <div className="row mt-4">
                                    <div className="table-edit">
                                        <div className="col-12">
                                            <table className="table visible-text"
                                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                                <thead>
                                                {this.mobxListado.length == 0 &&
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{t('No hay ninguna pieza creada')}
                                                    </td>
                                                </tr>
                                                }
                                                {this.mobxListado.length != 0 &&
                                                <tr>
                                                    <th scope="col">{t("Codigo")}</th>
                                                    <th scope="col">{t("Nombre")}</th>
                                                    <th scope="col">{t("Cantidad")}</th>
                                                    <th scope="col">{t("Precio")}</th>
                                                    <th scope="col">{t("Coste")}</th>
                                                    <th scope="col">{t("Precio total")}</th>
                                                    <th scope="col">{t("Estado")}</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">
                                                {this.mobxListado.map((article, index) => (
                                                    <OrderFormArticle
                                                        key={article && article.id}
                                                        orderId={this.props.orderId}
                                                        rowIndex={index}
                                                        row={article}
                                                        fromWorkOrder={this.state.fromWorkOrder}
                                                        workOrders={appState.workOrderState.workOrders}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    />
                                                ))}

                                                </tbody>
                                                <tfoot>
                                                {this.mobxListado.length != 0 &&
                                                <tr>
                                                    <td colSpan="3">TOTAL</td>
                                                    <td colSpan="5">{precio + " €"}</td>
                                                </tr>
                                                }
                                                </tfoot>
                                            </table>
                                            <div className="col-12 text-right">
                                                <VsfButton
                                                    disabled={this.state.hasSomeRowEditing}
                                                    label={"Crear nueva"}
                                                    onClick={_ => this.onOpenRowNewEmpty()}
                                                    isEditing={this.state.hasSomeRowEditing}
                                                    classButton="btn--loader"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo(withTranslate(WorkOrderFormArticles)));
