import React from 'react';
import { observer } from 'mobx-react';
import { dir } from "async";
import BaseFieldComponent from "./BaseFieldComponent";
import FormInputWrapper from "./FormInputWrapper";


@observer
export default class CheckboxUniqueComponent extends BaseFieldComponent {

    onChange(event) {
        console.log(event);
        if (this.props.checked) {
            let valueUnchecked = this.props.valueUnchecked;
            if (valueUnchecked == null) {
                valueUnchecked = "";
            }
            event.target.value = "";
        } else {
            event.target.value = this.props.value;
        }
        this.props.onChange(event);
    }

    render() {
        let props = this.props;
        let classNameInput = this.getClassNameInput();
        return (
            <div
                className={"form-checkform-check-inline " + this.props.className}
            >
                <FormInputWrapper
                    classGroup={props.classGroup}
                    classInputType={"Select2Component"}
                    //name={props.name}
                    postfix={this.props.postfix}
                    prefix={this.props.prefix}
                    errors={this.props.errors}
                    //title={this.props.title}
                    required={this.props.required}
                    isClearable={this.props.isClearable}
                    info={this.props.info}
                >
                    <input
                        autoComplete="off"
                        type="checkbox"
                        style={{ display: "block" }}
                        name={props.name}
                        checked={this.props.checked}
                        id={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={e => this.onChange(e)}
                        readOnly={props.readOnly}
                    />
                    <label className="form-radio-label" htmlFor={props.name}>{props.title}</label>
                </FormInputWrapper>
            </div>


        );
    }
}

