import React from 'react';
import { observer } from 'mobx-react';
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import withTranslate from "../../translator/withTranslate";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import Select2Component from "../../components/fields/Select2Component";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import DateInputField from "../../components/fields/DateInputField";
import UserModel from "../../models/UserModel";
import MoreButtons from "../../components/MoreButtons";
import PropsUtil from "../../util/PropsUtil";
import { observable } from "mobx";
import AppLogger from "../../util/AppLogger";
import ModalEnviarContrasenya from "../../components/ModalEnviarContrasenya";
import InfoBlock from "../../components/InfoBlock";
import PopUp from "../../components/PopUp";
import AlertModal from "../../subpages/AlertModal";
import VsfButton from "../../components/VsfButton";
import TabsData from "../../layout/TabsData";
import TabsComponent from "../../components/fields/TabsComponent";
import util from "../../util/Util";
import VehicleModel from "../../models/VehicleModel";
import AutoSuggestComponent from "../../components/fields/AutoSuggestComponent";


@observer
class UserForm extends BaseForm {
    nameMainType = "user";
    @observable accessToken = window.localStorage.getItem('accessToken');
    @observable refreshToken = window.localStorage.getItem('refreshToken');
    @observable hasNoErrors = false;
    @observable passwordErrors = [];
    @observable erroresFormulario = false;
    @observable withPassword = false;

    constructor(props) {
        super(props);
        this.log("UserForm.constructor()");
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.vehicleState.getVehicles();
        await appState.typifiedState.loadRelationships();
        await appState.typifiedState.loadZones();
        await appState.typifiedState.loadEmploiments();
        await appState.typifiedState.loadRolUser();


    }


    getModelQuery() {
        return new UserModel();
    }

    comprobarContrasenya(regexp, contrasenya, mensaje) {
        let regularExp = new RegExp(regexp);
        let encontrado = true;
        if (!regularExp.test(contrasenya)) {
            for (let i = 0; i < this.passwordErrors.length; i++) {
                if (this.passwordErrors[i].message == mensaje) {
                    encontrado = false;
                }
            }
            if (encontrado)
                this.passwordErrors.push({ "message": mensaje })
        } else
            for (let i = 0; i < this.passwordErrors.length; i++) {
                if (this.passwordErrors[i].message == mensaje) {
                    this.passwordErrors.splice(i, 1)
                }
            }
    }

    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    async getVehiclesSugestions(value) {
        let vehiclesArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let signal = this.signal;
            // Obtenemos los Assets
            let vehiclesQuery = new VehicleModel();
            vehiclesQuery.filters = [
                { "fieldName": "plate", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let vehicles = await vehiclesQuery.find();
            // Transformamos a formato Autosuggest
            vehicles.map((vehicle) => {
                let vehicleObject = {};
                vehicleObject.label = vehicle.plate;
                vehicleObject.value = vehicle.plate;
                vehiclesArray.push(vehicleObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return vehiclesArray;
    }

    getVehicleFromPlate(vehiclePlate) {
        let vehiclesSelected;
        if (util.hasValue(vehiclePlate)) {
            for (let vehicle of appState.vehicleState.vehicles) {
                if (vehicle.plate === vehiclePlate) {
                    vehiclesSelected = vehicle;
                    break;
                }
            }
        } else {
            vehiclesSelected = {};
        }
        return vehiclesSelected;
    }

    getVehicleFromVehicleId(vehiclesId) {
        let result = {};
        if (util.hasValue(vehiclesId)) {
            for (let vehicle of appState.vehicleState.vehicles) {
                if (util.hasValue(vehiclesId)) {
                    if (vehicle.id === vehiclesId) {
                        result = vehicle;
                        break;
                    }
                }
            }
        }
        return result;
    }

    isPasswordValid(event) {
        this.log("isPasswordValid()");
        let encontrado = true;
        let contrasenya = event.target.value;

        if (!/^(?=.*\d)(?=.*[\W])(?=.*[A-Z])(?=.*[a-z])\S{8,}$/.test(contrasenya)) {
            this.withPassword = true;
            this.hasNoErrors = false;
        } else {
            this.hasNoErrors = true;
            this.withPassword = false;
        }
        if (this.passwordErrors[0] != null) {
            encontrado = false;
        }
        if (encontrado)
            this.passwordErrors.push({ "message": "" })
        if (this.hasNoErrors) {
            this.passwordErrors = [];
        }
        this.updateInputEvent(event);
    }

    clickErroresFormulario() {
        this.setState({
            errorFormulario: false
        });
        this.graphStatus.mutationError = 0;
    }

    urlForWorkOrderFromUser(user) {
        let url = '/work-order/ls/?filters=';
        url += '[{\"fieldKey\":\"assignedToMainId\",\"fieldName\":\"assignedToMainId\",\"fieldValue\":\"' + user.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + user.firstName + " " + user.lastName + '\"}]';
        return url;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const { t } = this.props;
        this.log("UserForm.render()");
        let id = this.getFormId();
        let user = this.graphDataMainType;
        //Si el sistema da un error user será null
        if (user == null) {
            user = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let userStatus = [
            { label: "Activo", value: "Activo" },
            { label: "Baja Temporal", value: "Baja Temporal" },
            { label: "Baja definitiva", value: "Baja definitiva" },
        ];

        let textoErrores = "";
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""] && errorsMapped[""].map(error => error.message);
            if (util.hasValue(textoErrores)) {
                this.state.errorFormulario = true;
            }
        }

        return (
            <InfoBlock componentObject={this}>
                <ModalEnviarContrasenya user={user} />
                <PageTitle
                    title={t("Usuario")}
                    subtitle={user.email || t('Nuevo')}
                >
                    {this.getFormId() != null &&
                    <MoreButtons
                        actions={
                            [{
                                title: "Recuperar Contraseña", onClick: () => {
                                    this.propsUtil.changeUrlRequest({ rightModal: "recover" })
                                }
                            },
                                {
                                    title: "Ver sus Ordenes de Trabajo", onClick: () => {
                                        this.propsUtil.changeUrl(this.urlForWorkOrderFromUser(user))
                                    },
                                }
                            ]
                        }
                    />
                    }
                </PageTitle>
                <form
                    className="model-form"
                    name="formulario"
                    onSubmit={(e) => this.handleFormSubmit(e)}
                >

                    <div className={'card mb-3 pb-3 pb-lg-0'}>

                        <TabsComponent
                            id={this.graphDataMainType.id}
                            tabs={this.tabsData.getDataUser()}
                            active={"Datos Generales"}
                            fromRightModal={this.props.fromRightModal}
                            classNameModal={" col-12"}
                        />
                        <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                           error={this.graphStatus.mutationError}
                                           working={this.graphStatus.networkWorking}
                        />
                        <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                            formError={this.graphStatus.formError}
                        />
                        {errorsMapped[""] != null ?
                            <div className="alert alert-danger" role="alert">
                                {}
                                {errorsMapped[""].map(error => <div>{error.message}</div>)}
                            </div>
                            :
                            null
                        }
                        <FormWrapper>

                            <AlertModal isOpen={this.state.errorFormulario}
                                        onCloseModal={() => this.clickErroresFormulario()}
                            >
                                <PopUp
                                    title={t('Error')}
                                    icon="fas fa-exclamation-triangle"
                                    text={textoErrores}
                                    label1={'Aceptar'}
                                    onClick1={() => this.clickErroresFormulario()}
                                >
                                </PopUp>
                            </AlertModal>


                            <GroupOfField title={t("Identificacion del Usuario")}
                                          icon="fa-clipboard"
                            >
                                {this.getFormId() == null ?
                                    <InputTypeComponent
                                        value={user.email}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"email"}
                                        title={t("Email")}
                                        placeholder="email"
                                        classGroup={"col-lg-4"}
                                        type={"text"}
                                        required={true}
                                        validate={"email"}
                                        errors={errorsMapped.email}
                                    />
                                    :
                                    <InputTypeComponent
                                        value={user.email}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"email"}
                                        title={t("Email")}
                                        placeholder="email"
                                        autoComplete={true}
                                        classGroup={"col-lg-4"}
                                        type={"text"}
                                        validate={"email"}
                                        readOnly={true}
                                        errors={errorsMapped.email}
                                    />
                                }
                                {this.getFormId() == null &&
                                <InputTypeComponent
                                    value={user.password}
                                    onChange={(e) => this.isPasswordValid(e)}
                                    name={"password"}
                                    title={t("Password")}
                                    placeholder="password"
                                    classGroup={"col-lg-4"}
                                    type={"password"}
                                    hasNoErrors={this.hasNoErrors}
                                    required={true}
                                    errors={this.passwordErrors}
                                />
                                }
                                <Select2Component
                                    value={user.role}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"role"}
                                    title={t("Rol")}
                                    classGroup="col-md-4"
                                    options={appState.typifiedState.rolesForSelect}
                                    multi={true}
                                    optionsWrappedWithCommas={false}
                                    errors={errorsMapped.role}
                                />
                            </GroupOfField>
                            <GroupOfField title={t("Datos Generales")}
                                          subtitle={t("Técnico")}
                                          icon="fa-clipboard"

                            >
                                <InputTypeComponent
                                    value={user.firstName}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"firstName"}
                                    title={t("Nombre")}
                                    placeholder="Nombre"
                                    classGroup={"col-md-6 col-lg-2"}
                                    type={"text"}
                                    errors={errorsMapped.firstName}
                                />
                                <InputTypeComponent
                                    value={user.lastName}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"lastName"}
                                    title={t("Apellidos")}
                                    placeholder="Apellidos"
                                    classGroup={"col-md-6 col-lg-7"}
                                    type={"text"}
                                    errors={errorsMapped.lastName}
                                />

                                <DateInputField
                                    birthDate={true}
                                    prefix={"fas fa-calendar-alt"}
                                    value={user.birthDate}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"birthDate"}
                                    title={t("Fecha de Nacimiento")}
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}
                                    errors={errorsMapped.birthDate}
                                />

                                <InputTypeComponent
                                    value={user.idNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"idNumber"}
                                    title={t("NIF")}
                                    placeholder="NIF"
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}
                                    errors={errorsMapped.idNumber}
                                />
                                <InputTypeComponent
                                    value={user.insuranceNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"insuranceNumber"}
                                    title={t("Número SS")}
                                    placeholder="Introduzca su número de la Seguridad Social..."
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}
                                    errors={errorsMapped.insuranceNumber}
                                />
                                <InputTypeComponent
                                    value={user.citizenNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"citizenNumber"}
                                    title={t("Número Ciudadano")}
                                    placeholder="Número Ciudadano"
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}
                                    errors={errorsMapped.citizenNumber}
                                />
                                {this.getFormId() == null ?
                                    <Select2Component
                                        value={user.status}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"status"}
                                        clearable={false}
                                        title={t("Estado")}
                                        classGroup="col-md-3"
                                        options={userStatus}
                                        errors={errorsMapped.status}
                                    />
                                    :
                                    <Select2Component
                                        value={user.status}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"status"}
                                        clearable={false}
                                        title={t("Estado")}
                                        classGroup="col-md-3"
                                        options={userStatus}
                                        errors={errorsMapped.status}
                                    />
                                }

                                <InputTypeComponent
                                    value={user.address}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"address"}
                                    title={t("Direccion")}
                                    placeholder="Direccion"
                                    classGroup={"col-md-6"}
                                    type={"text"}
                                    errors={errorsMapped.address}
                                />
                                <InputTypeComponent
                                    value={user.city}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"city"}
                                    title={t("Ciudad")}
                                    placeholder="Ciudad"
                                    classGroup={"col-md-6"}
                                    type={"text"}
                                    errors={errorsMapped.city}
                                />
                                <InputTypeComponent
                                    value={user.province}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"province"}
                                    title={t("Provincia")}
                                    placeholder="Provincia"
                                    classGroup={"col-md-4"}
                                    type={"text"}
                                    errors={errorsMapped.province}
                                />
                                <InputTypeComponent
                                    value={user.country}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"country"}
                                    title={t("País")}
                                    placeholder="Pais"
                                    classGroup={"col-md-4"}
                                    type={"text"}
                                    errors={errorsMapped.country}
                                />
                                <InputTypeComponent
                                    value={user.postalCode}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"postalCode"}
                                    title={t("Código Postal")}
                                    placeholder="Código Postal"
                                    classGroup={"col-md-4"}
                                    classInput={"col-12"}
                                    type={"text"}
                                    validate={"postalCode"}
                                    errors={errorsMapped.postalCode}
                                />
                                <InputTypeComponent
                                    value={user.phoneNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"phoneNumber"}
                                    title={t("Télefono 1")}
                                    validate={"phone"}
                                    placeholder="Télefono 1"
                                    classGroup={"col-md-6 col-lg-4"}
                                    type={"text"}
                                    errors={errorsMapped.phoneNumber}
                                />
                                <InputTypeComponent
                                    value={user.mobileNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"mobileNumber"}
                                    validate={"phone"}
                                    title={t("Télefono movil")}
                                    placeholder="Télefono 2"
                                    classGroup={"col-md-6 col-lg-4"}
                                    type={"text"}
                                    errors={errorsMapped.mobileNumber}
                                />
                                <InputTypeComponent
                                    value={user.contactPerson}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"contactPerson"}
                                    title={t("Contacto")}
                                    placeholder="Contacto"
                                    classGroup={"col-md-6 col-lg-4"}
                                    type={"text"}
                                    errors={errorsMapped.contactPerson}
                                />
                                <Select2Component
                                    value={user.contactRelationship}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"contactRelationship"}
                                    title={t("Relación")}
                                    classGroup={"col-md-6 col-lg-4"}
                                    options={appState.typifiedState.relationshipsForSelect}
                                    errors={errorsMapped.contactRelationship}
                                />
                                <InputTypeComponent
                                    value={user.contactPhoneNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"contactPhoneNumber"}
                                    title={t("Teléfono (contacto)")}
                                    validate={"phone"}
                                    placeholder="Teléfono"
                                    classGroup={"col-lg-4"}
                                    type={"text"}
                                    errors={errorsMapped.contactPhoneNumber}
                                />
                            </GroupOfField>

                            <GroupOfField title={t("Relación Laboral")} icon="fa-clipboard">
                                <InputTypeComponent
                                    value={user.company}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"company"}
                                    title={t("Empresa")}
                                    placeholder="Empresa"
                                    classGroup={"col-lg-2"}
                                    type={"text"}
                                    errors={errorsMapped.employeeNumber}
                                />
                                <InputTypeComponent
                                    value={user.employeeNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"employeeNumber"}
                                    title={t("Número de Empleado")}
                                    placeholder="Número de Empleado"
                                    classGroup={"col-md-6 col-lg-2"}
                                    type={"text"}
                                    info={"Sólo para empleados de Serlusa"}
                                    errors={errorsMapped.employeeNumber}
                                />
                                <Select2Component
                                    value={user.employment}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"employment"}
                                    title={t("Puesto de Trabajo")}
                                    classGroup="col-md-4"
                                    inputClass="col-2"
                                    options={appState.typifiedState.emploimentsForSelect}
                                    errors={errorsMapped.employment}
                                />

                                <InputTypeComponent
                                    value={user.companyPhoneNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"companyPhoneNumber"}
                                    title={t("Teléfono Empresa")}
                                    validate={"phone"}
                                    placeholder="Teléfono Empresa"
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}
                                    errors={errorsMapped.companyPhoneNumber}
                                />
                                <InputTypeComponent
                                    value={user.companyEmail}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"companyEmail"}
                                    title={t("Email Empresa")}
                                    placeholder="Email Empresa"
                                    classGroup={"col-md-6 col-lg-3"}
                                    type={"text"}
                                    validate={"email"}
                                    errors={errorsMapped.companyEmail}
                                />

                                <AutoSuggestComponent
                                    value={this.getVehicleFromVehicleId(user.userVehicle).plate}
                                    onChange={(vehicleSelected) => {
                                        this.handleAutosuggestSelection("userVehicle", vehicleSelected);
                                        if (user.userVehicle !== '') {
                                            user.userVehicle = this.getVehicleFromPlate(user.userVehicle).id;
                                        } else {
                                            user.userVehicle = "";
                                        }
                                    }}
                                    name={"userVehicle"}
                                    title={t("Vehiculo")}
                                    classGroup={"col-md-6 col-lg-4"}
                                    errors={errorsMapped.assetPlate}
                                    info={t('Matrícula')}
                                    loadSuggestions={(value) => this.getVehiclesSugestions(value)}
                                    placeholder={t("Escribe...")}

                                />
                                <InputTypeComponent
                                    value={this.getVehicleFromVehicleId(user.userVehicle).brand}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"assetType"}
                                    readOnly={true}
                                    title={t("Marca")}
                                    classGroup={"col-md-6 col-lg-4"}
                                    placeholder={t("Marca")}
                                />


                                <Select2Component
                                    value={user.zoneAsignedId}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"zoneAsignedId"}
                                    title={t("Zona de trabajo")}
                                    classGroup="col-md-6 col-lg-3"
                                    options={appState.typifiedState.zonesForSelect}
                                    errors={errorsMapped.zoneAsignedId}
                                />
                                <VsfButton
                                    label={"Añadir Zona Temporal"}
                                    classGroup="col-md-6 col-lg-2 mt-4"
                                    classButton="btn--disabled"
                                />
                            </GroupOfField>
                        </FormWrapper>
                    </div>
                    <FormButtons id={id} formStatus={this.graphStatus}
                                 withPassword={this.getFormId() == null ? this.withPassword : null} />
                </form>
            </InfoBlock>

        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(withApollo(UserForm));
