import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import TypifiedValueModel from "../../../models/TypifiedValueModel";
import appState from "../../../state/AppState";
import util from "../../../util/Util";

@observer
export default class WorkOrderStateListItem extends Component {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        id: PropTypes.string,
        // Objeto con todos los campos que estamos mostrando
        row: PropTypes.object,
        // Nombre del campo a mostrar
        name: PropTypes.string,
        // Valor del campo a mostrar
        value: PropTypes.string,
    };


    render() {
        let props = this.props;
        let value;
        if (props.orderStatusList) {
            value = util.hasValue(appState.typifiedState.arrOrderStatus[this.props.value]) && appState.typifiedState.arrOrderStatus[this.props.value];

        } else if (props.workOrderStatus) {
            value = appState.typifiedState.arrStatusDispatcher[this.props.value] && appState.typifiedState.arrStatusDispatcher[this.props.value];
        }
        return (
            <div style={{
                color: props.row.status && props.workOrderStatusCode ?
                    appState.typifiedState.arrStatusDispatcher[props.row.statusDispatcherWorkOrder] &&
                    appState.typifiedState.arrStatusDispatcher[props.row.statusDispatcherWorkOrder].color :
                    appState.typifiedState.arrOrderStatus[props.row.status] &&
                    appState.typifiedState.arrOrderStatus[props.row.status].color
            }}>
                {(props.workOrderStatus || props.orderStatusList) ? (value && value.value) : this.props.value}
            </div>
        );
    }
}
