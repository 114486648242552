import Model from "./Model";

export default class AbstractModel extends Model {

    updatedAt;
    updatedBy;

    getArrayFields() {
        let resultBase=super.getArrayFields();
        let result = {
            ...resultBase,
            "updatedAt" : {readonly:true, label:"Actualizado"},
            "updatedBy" : {readonly:true, label:"Actualizado por"},
            "createdAt":{readonly:true, label:"Creado"},
            "createdBy":{readonly:true, label:"Creado por"},
        }
        return result;
    }

    getLabelFromFieldName(name) {
        console.log('name of field', name)
        let arrayFields = this.getArrayFields();
        let result=null;
        if (arrayFields[name]!=null) {
            result = arrayFields[name].label;
        }
        if (result==null) {
            result=name;
        }
        return result;
    }

    getResponseFieldsFromMutation() {
        return ["id","createdAt","createdBy","updatedAt","updatedBy"];
    }



    addRelatedTableDocuments(result, childType) {
        if (this._relatedTables["documents"]) {
            result["documents"]={type:"Relation", onlyRead:true, childType:childType,
            }
        }
    }
    addRelatedStatusAsset(result, childType) {
        if (this._relatedTables["statusAsset"]) {
            result["statusAsset"]={type:"RelationOne", onlyRead:true, childType:childType,
            }
        }
    }
    addRelatedTypeAsset(result, childType) {
        if (this._relatedTables["typeAsset"]) {
            result["typeAsset"]={type:"RelationOne", onlyRead:true, childType:childType,
            }
        }
    }
    addRelatedManufacturerAsset(result, childType) {
        if (this._relatedTables["manufacturerAsset"]) {
            result["manufacturerAsset"]={type:"RelationOne", onlyRead:true, childType:childType,
            }
        }
    }

    addRelatedTableAssetSubType(result, childType) {
        if (this._relatedTables["subTypeAsset"]) {
            result["subTypeAsset"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableClientPos(result, childType) {
        if (this._relatedTables["clientPos"]) {
            result["clientPos"]={type:"RelationOne", onlyRead:true, childType:childType,
            }
        }
    }
    addRelatedTableClientPropietary(result, childType) {
        if (this._relatedTables["clientPropietary"]) {
            result["clientPropietary"]={type:"RelationOne", onlyRead:true, childType:childType,
            }
        }
    }

    addRelatedTableZoneAsigned(result, childType) {
        if (this._relatedTables["zoneAsigned"]) {
            result["zoneAsigned"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableVehicle(result, childType) {
        if (this._relatedTables["vehicle"]) {
            result["vehicle"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableEmployment(result, childType) {
        if (this._relatedTables["employmentUser"]) {
            result["employmentUser"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableRelationship(result, childType) {
        if (this._relatedTables["relationship"]) {
            result["relationship"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableChain(result, childType) {
        if (this._relatedTables["chain"]) {
            result["chain"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTablePosZone(result, childType) {
        if (this._relatedTables["posZone"]) {
            result["posZone"]={type:"RelationOne", onlyRead:true, childType:childType,
            }
        }
    }

    addRelatedTableContacts(result, childType) {
        if (this._relatedTables["contact"]) {
            result["contact"]={
                type:"RelationOne", onlyRead:true, childType:childType
            }
        }
    }
    addRelatedTableRequestContacts(result, childType) {
        if (this._relatedTables["contact"]) {
            result["request"]={
                type:"RelationOne", onlyRead:true, childType:childType
            }
        }
    }

    addRelatedTableClients(result, childType) {
        if (this._relatedTables["client"]) {
            result["client"]={
                type:"RelationOne", onlyRead:true, childType:childType
            }
        }
    }
    addRelatedTableOrders(result, childType) {
        if (this._relatedTables["order"]) {
            result["order"]={
                type:"RelationOne", onlyRead:true, childType:childType
            }
        }
    }

    addRelatedTableWorkOrder(result, childType) {
        if (this._relatedTables["workOrder"]) {
            result["workOrder"]={
                type:"Relation", onlyRead:true, childType:childType
            }
        }
    }

    addRelatedTableWorkLog(result, childType) {
        if (this._relatedTables["workLog"]) {
            result["workLog"]={
                type:"Relation", onlyRead:true, childType:childType
            }
        }
    }

    addRelatedTableAssets(result, childType) {
        if (this._relatedTables["asset"]) {
            result["asset"]={
                type:"RelationOne", onlyRead:true, childType:childType
            }
        }
    }



}
