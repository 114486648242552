import React from 'react';
import {observer} from 'mobx-react';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import BaseEditableRow from "../base/BaseEditableRow";
import {computed} from "mobx";
import appState from "../../state/AppState";
import Select2Component from "../../components/fields/Select2Component";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import ArticleCodesModel from "../../models/ArticleCodesModel";
import util from "../../util/Util";


@observer
class ArticleCodeForm extends BaseEditableRow {

    constructor(props) {
        super(props);
        this.foreingKeyfield = "articleId";
    }

    @computed
    get mobxListado() {
        return    appState.articleCodesState.articleCodes;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("componentDidMount");
        await appState.typifiedState.loadManufacturer();
        await appState.typifiedState.loadAssetCodes();

        await appState.typifiedState.arrayLoadManufacturer();
        await appState.typifiedState.arrayLoadAssetCodes();
    }

    getManufacurerFromId(typifiedId){
        let result ="";
        if(util.hasValue(typifiedId)){
            result = appState.typifiedState.arrManufacturer[typifiedId];
            if(util.hasValue(result)){
                return result;
            }
        }
        return result;
    }

    getTypeCodeFromId(typifiedId){
        let result ="";
        if(util.hasValue(typifiedId)){
            result = appState.typifiedState.arrAssetCodes[typifiedId];
            if(util.hasValue(result)){
                return result;
            }
        }
        return result;
    }

    setMobxListado(newValue) {
        appState.articleCodesState.articleCodes = newValue;
    }

    getModelTable() {
        return new ArticleCodesModel();
    }

    render() {
        let articleCode = this.props.row;

        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr className={articleCode.isEditing ? "editing" : ""}>
                    <td>
                        <InputTypeComponent
                            value={articleCode.code}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"code"}
                            showView={!articleCode.isEditing}
                            className="form-control"
                            validate={"alphanumeric|maxLength:20"}
                        />
                    </td>
                    <td>
                        {articleCode.isEditing ?
                            <Select2Component
                                value={articleCode.type}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"type"}
                                options={appState.typifiedState.assetCodesForSelect}
                                showView={!articleCode.isEditing}
                            />
                            :
                            <div>
                                {this.getTypeCodeFromId(articleCode.type)}
                            </div>
                        }
                    </td>
                    <td>
                        {articleCode.isEditing ?
                            <Select2Component
                                value={articleCode.providerName}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"providerName"}
                                options={appState.typifiedState.manufacturerForSelect}
                                showView={!articleCode.isEditing}
                            />
                            :
                            <div>
                                {this.getManufacurerFromId(articleCode.providerName)}

                            </div>
                        }
                    </td>
                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default ArticleCodeForm;
