import React from 'react';
import {observer} from "mobx-react/index";
import appState from '../../state/AppState';
import {withApollo} from "react-apollo";
import PropTypes from 'prop-types';
import {computed} from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import VsfButton from "../../components/VsfButton";
// import WorkOrderModel from "../../models/WorkOrderModel";
import AppLogger from "../../util/AppLogger";
import AssetCodesModel from "../../models/AssetCodesModel";
import AssetCodeForm from "./AssetCodeForm";
import withTranslate from "../../translator/withTranslate";
// import FormButtons from "../../components/FormButtons";

@observer
class AssetCodesTable extends BaseEditableTable {


    static defaultProps = {
        foreingKeyfield: "",
        fromOrder: false,
    }

    static propTypes = {
        foreingKeyfield: PropTypes.string,
        fromOrder: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.foreingKeyfield = this.props.foreingKeyfield;
        this.log("foreingKeyfield",this.foreingKeyfield);
    }

    @computed get mobxListado() {
        return appState.assetCodesState.assetCodes;
    }

    getModelTable() {
        return new AssetCodesModel();
    }



    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadThisTable(this.props.id);
    }

    async reloadThisTable(assetId) {
        let assetCodes = new AssetCodesModel();
        let assetModel = await assetCodes.getAssetCodesFromAssetId(assetId);
        appState.assetCodesState.assetCodes = util.arrayModelToPlainObjects(assetModel);
    }


    render() {
        this.log("AssetCodesTable render =>");
        const {t, i18n} = this.props;
        this.propsUtil = new PropsUtil(this.props);


        return (
            <InfoBlock componentObject={this}>
                <div className={this.props.classGroup || "row mt-4"}>
                    <div className="col-12">
                        <div className="table-edit">
                            <table className="table visible-text"
                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                <thead>
                                <tr>
                                    <th scope="col">{t('Código')}</th>
                                    <th scope="col">{t('Tipo de Código')}</th>
                                    <th scope="col">{t('Fabricante/Proveedor')}</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody className="border-end">
                                {this.mobxListado.length==0 ?
                                    <tr>
                                        <td colSpan={3} className={"to-transform-none"}>
                                            Para añadir nuevos códigos de referencia, primero debes guardar las características del equipo
                                        </td>
                                    </tr>
                                    :
                                    this.mobxListado.map((code, index) => (
                                            <AssetCodeForm
                                                key={code && code.id}
                                                rowIndex={index}
                                                row={code}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                            />
                                        ))
                                }


                                </tbody>
                            </table>
                        </div>
                        {this.props.fromRightModal ?
                            <div className="text-right">
                                <VsfButton
                                    disabled={this.state.hasSomeRowEditing}
                                    label={"Crear nueva"}
                                    onClick={_ => this.onOpenRowNewEmpty()}
                                    isEditing={false }
                                    // isEditing={this.props.id == null ? true : this.state.hasSomeRowEditing}
                                    classButton="btn--loader"
                                />
                            </div>
                            :
                            <div className="text-right">
                                <VsfButton
                                    disabled={this.state.hasSomeRowEditing}
                                    label={"Crear nueva"}
                                    onClick={_ => this.onOpenRowNewEmpty()}
                                    // isEditing={false }
                                    isEditing={this.props.id == null ? true : this.state.hasSomeRowEditing}
                                    classButton="btn--loader"
                                />
                            </div>
                        }

                    </div>

                </div>

            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo( withTranslate(AssetCodesTable)));
