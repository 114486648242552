import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute } from 'react-router-dom'
import BaseCommon from "./BaseCommon";
import GraphException from "../../network/GraphException";
import appState from "../../state/AppState";
import { computed, observable, toJS } from 'mobx';
import util from '../../util/Util';
import Links from "../../util/Links";
import './../../scss/components/form-show_history.scss';
import './../../scss/components/form-file_uploader.scss';
import ChangeModel from "../../models/ChangeModel";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";

@observer
class BaseForm extends BaseCommon {

    nameMainType = "";
    @observable changes = [];

    /**
     * Al cargar la información de base de datos la guardo aquí. De esa forma podré saber qué elementos mandar nuevos (solo los modificados)
     */
    modelPlainFirstLoaded = {};

    constructor(props) {
        super(props);
        //this.graphData.data[this.nameMainType]={};
        //this.setFormMobxData({});
        //this.nameMainType="queryType";
        this.queryFieldWithId = null;
    }

    @computed get graphDataMainType() {
        return this.graphData.data[this.nameMainType];
    }

    @computed get graphStateObj() {

        let stateObj = appState[this.nameMainType + "State"];
        if (stateObj == null) {
            throw "graphStateObj() The Form must has a State of the form appState[\"" + this.nameMainType + "State\"]";
        }
        return stateObj;
    }

    @computed get graphData() {
        return this.graphStateObj.graphData;
    }

    @computed get graphStatus() {
        //  this.log('pasando por  this.graphStatus');
        // this.log(this.graphStateObj.graphStatus);
        return this.graphStateObj.graphStatus;
    }


    async reloadCounts() {
        if (this.nameMainType == "order") {
            let id = "";
            if (util.hasValue(this.getFormId())) {
                id = this.getFormId();
            }
            // await appState.assetState.getCountArticlesFromOrder(id);
            await appState.assetState.getCountSlotsFromOrder(id);
        }
        if (this.nameMainType == "workOrder") {
            let id = "";
            if (util.hasValue(this.getFormId())) {
                id = this.getFormId();
            }
            // await appState.assetState.getCountArticlesFromWorkOrder(id);
            await appState.assetState.getCountSlotsFromWorkOrder(id);
        }
    }

    initializeGraphData(nameMainType) {
        let id = this.getFormId();
        // appState.orderState.graphData.data["order"]
        if (this.graphData.data[nameMainType] && this.graphData.data[nameMainType].id === id) {
        } else {
            this.graphData.data[nameMainType] = {};
        }
    }

    updateTextArea(e) {

    }


    updateInputEvent(event, op) {
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(event.target.name, event.target.value)
    }

    updateInput(key, value) {
        let modelFromState = this.graphDataMainType;
        modelFromState[key] = value;
        this.log("updateInput(key: " + key + ", value: " + value);
        this.log({ modelFromState });
        appState.layoutState.formWithoutChanges = false;
        if (this.previousRowForDiscard != null) {
            this.previousRowForDiscard = this.graphDataMainType;
            this.previousRowForDiscard[key] = value;
        }
    }

    onBack() {
        window.history.go(-1);
    }

    toPascalCase(str) {
        return util.toPascalCase(str)
    }

    onBackModal() {
        appState.layoutState.arrayWithLocations.pop();

        this.props.history.push(appState.layoutState.arrayWithLocations.pop())
    }


    onCloseModal() {
        if (!appState.layoutState.formWithoutChanges && !appState.layoutState.loadingForm) {
            appState.layoutState.modalChangeOpen = true;
        } else {
            this.propsUtil.changeUrlRequest({
                fromRightModal: null,
                rightModal: null,
                articleId: null,
                rightModalTab: null,
                workOrderId: null,
                orderId: null,
                idOrderClient: null,
                assetId: null,
            });
            appState.layoutState.arrayWithLocations = [];

        }
    }

    getGraphErrorsFromStatus() {
        let result = new GraphException().getGraphErrorsFromStatus(this.graphStatus.mutationGraphQlResponse);
        let client = this.graphDataMainType;
        if (client == null) {
            if (result[""] == null) {
                result[""] = [];
            }
            let error = { message: "Data error" };
            result[""].push(error);
        }
        // this.log('pasand por  getGraphErrorsFromStatus() ');
        // this.log(result);
        return result;
    }

    async componentDidMount() {
        await this.componentDidMountImpl();
    }

    async componentDidMountImpl() {
        this.log("BaseForm.componentDidMount() props.id:" + this.props.id + " getFormId():" + this.getFormId());
        await this.reloadForm();
        await this.reloadCounts();
    }

    /**
     * Lanza una query contra graphQL
     * Si devuelve error Timeout lanza una excepción como esta: {extensions:{data:{field:""}}, message:"Error al conectar con servidor"}
     * Si devuelve un error 400 (con datos nulos) devuelve los mensajes de error de esta forma
     * En caso de exito devuelve la respuesta Graphql
     * @returns {Promise<void>}
     */
    async graphQuery(queryParams) {

        let resultQuery = { data: null };
        try {
            resultQuery = await this.props.client.query(queryParams);
        } catch (e) {
            data = null;
            let errorMessage = {
                extensions: { data: { field: "" } },
                message: "GraphException.Error al conectar con servidor"
            };
            this.graphStatus.mutationGraphQlResponse.errors = [errorMessage];
            throw new GraphException({
                message: "GraphException.Error al conectar con servidor",
                errors: [errorMessage]
            });
        }
        if (resultQuery.data == null) {
            throw new GraphException({ message: "GraphException.Los datos obtenidos son null", errors: [{}] });
        }
        return resultQuery;
    }

    getObjectWithDefaults(graphData) {
        const model = this.getObjectWithDefaults2(this.getDataFromGraphQLResponse(graphData));
        return model;
    }

    getModelQueryForView() {
        return this.getModelQuery();
    }

    getModelQuery() {
        let modelQuery = null;
        return modelQuery;
    }

    /**
     * El componente ya ha cargado el tipo de datos principal
     * @returns {Promise<void>}
     */
    async didReloadFormData(model) {

    }

    async reloadForm() {
        let id = this.getFormId();
        this.log("reloadForm()");

        this.graphStatus.formLoading = true;
        appState.loadingBarState.start();
        this.graphStatus.formError = false;
        this.graphStatus.mutationGraphQlResponse.errors = [];

        try {
            let modelQuery = this.getModelQueryForView();
            if (modelQuery != null) {
                let model;
                if (util.esVacio(id)) {
                    model = this.getModelQueryForView();
                } else {
                    model = await modelQuery.findByIdNotNull(id);
                }
                this.modelPlainFirstLoaded = model.setAndGetInitialState();
                //this.modelPlainFirstLoaded=model._fieldsOnLoad;

                //workOrder.clientComments="Jose";

                let modelPlain = Object.assign({}, model.toPlainObject());
                this.log({ modelPlain });
                this.graphData.data[this.nameMainType] = modelPlain;
                await this.didReloadFormData(model);

                //this.log({order:toJS(this.graphData.data[this.nameMainType])});

            } else {
                /*
                // Versión legacy sin tirar de los nuevos modelos
                let apolloProxy = new ApolloProxy(this.props.client);
                let resultQuery2 = await apolloProxy.graphQuery({
                    query: this.getGraphQLQuery(),
                    variables: { id }
                });
                let newData=resultQuery2.data;
                if (id==null) {
                    newData[this.nameMainType]=this.getObjectWithDefaults2({})
                }
                this.graphData.data = newData;
                */
            }
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.graphStatus.mutationGraphQlResponse.errors = errors;
        }
        appState.layoutState.formWithoutChanges = true;
        this.graphStatus.formLoading = false;
        appState.loadingBarState.finalize();

        this.log(toJS(this.graphDataMainType));

        this.log("/reloadForm");
    }

    /**
     * Devuelve el id: String del formulario
     */
    getFormId() {
        let result;
        // Lo coje de queryString
        if (this.queryFieldWithId != null) {
            this.propsUtil = new PropsUtil(this.props);
            let identifier = this.propsUtil.getRequest(this.queryFieldWithId);
            result = identifier;
        }
        // Lo coje de props
        if (result == null) {
            result = this.props[this.queryFieldWithId];
        }
        if (result == null) {
            // Lo coge de la url /order/form/4
            let links = new Links();
            result = links.getFormId(this.props);
        } else {
            result = result + "";
        }
        return result;
    }

    async handleFormSubmit(e) {

        if (e) {
            e.preventDefault()
        }
        this.log("save");
        try {
            appState.loadingBarState.start();
            this.graphStatus.networkWorking = true;
            this.graphStatus.mutationError = false;
            this.graphStatus.mutationLoading = true;
            appState.layoutState.loadingForm = true;
            this.graphStatus.mutationGraphQlResponse = {
                errors: []
            };
            let modelPlain = toJS(this.graphDataMainType);
            let model = this.getModelQuery();
            model.hidrate(modelPlain);
            model.recoverInitialState(this.modelPlainFirstLoaded);
            let previousId = model.id;
            this.postFillInputVariables(model);
            //this.log("BaseForm. model.persist()");
            await model.persist();
            // El campo id y el resto de campos necesarios los actualizo
            for (let fieldName of model.getResponseFieldsFromMutation()) {
                this.graphDataMainType[fieldName] = model[fieldName];
            }

            // Actualizo lo que he guardado para que la siguiente guardado solo se guarde lo cambiado
            this.modelPlainFirstLoaded = model.setAndGetInitialState();

            //this.log("/BaseForm. model.persist()");
            /*
            let variables = {
                id: this.getFormId()
            }
            variables[this.nameMainType]=model;
            this.postFillInputVariables(variables);
            let mutation = this.getGraphQLMutation();
            const resultQuery = await apolloProxy.mutate({mutation, variables});
            */
            await this.formDidSave(model, previousId);
        } catch (e) {
            this.log("BaseForm. handleFormSubmit. Exception");
            let errors = new GraphException().getErrorsFromException(e);
            this.graphStatus.mutationGraphQlResponse.errors = errors;
            this.graphStatus.mutationError = 1;
        }
        this.graphStatus.mutationLoading = false;
        appState.loadingBarState.finalize();
        setTimeout(() => this.graphStatus.networkWorking = false, 1000);
    }

    /**
     * The formulario has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {

    }

    postFillInputVariables(variables) {
        //variables[this.nameMainType]["type"]="";
    }

    showHistory = () => {
        this.setState({
            historyVisible: !this.state.historyVisible
        });
    };

    showItemHistory = (index) => () => {
        let changes = this.state.changes;
        changes[index].visible = !changes[index].visible;
        this.setState({
            changes
        });
    };

    async processHistory(modelName) {

        this.log("processHistory =>");

        let changes = [];

        let changeQuery = new ChangeModel();
        changeQuery.modelName = modelName;
        // changeQuery.id = this.getFormId();
        let changesCall = await changeQuery.find();
        this.log(changesCall);

        // PROCESAMOS LOS DATOS DE HISTORICO OBTENIDOS DE LA LLAMADA (AÑADIMOS EL CAMPO VISIBLE PARA MOSTRAR/OCULTAR)

        // CHANGE 1
        changes.push({
            id: "2",
            createdBy: 35,
            action: "U",
            createdAt: "20180101205039",
            fieldsChanged: "Tipo de Intervencion;Numero OT; Tipo de Equipo",
            jsonInitial: { name: 1, surname: 2, surname2: 3 },
            jsonChange: { name: 4, surname: 5, surname2: 6 },
            modelName: "dotnetwebapi.Models.WorkOrderModel",
            visible: false
        });

        // CHANGE 2
        changes.push({
            id: "3",
            createdBy: 31,
            action: "U",
            createdAt: "20190101205039",
            fieldsChanged: "Tipo de Intervencion",
            jsonInitial: { name: "a" },
            jsonChange: { name: "b" },
            modelName: "dotnetwebapi.Models.WorkOrderModel",
            visible: false
        });

        // LO AÑADIMOS AL ESTADO

        this.setState({
            changes,
            historyVisible: false
        });

        this.log(changes);

    }

    /**
     * Metodo que se activa al seleccionar una opcion del autosuggest
     *
     * @param fieldName
     * @param suggestSelected
     */
    handleAutosuggestSelection(fieldName, suggestSelected) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = this.graphDataMainType;
        }
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(fieldName, suggestSelected)
    };

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default BaseForm;
