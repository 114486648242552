import Links from "./Links";
import AppException from "./AppException";

class PropsUtil  {

    props;

    constructor(props) {
        this.props=props;
    }

    getRequest(key) {
        const params = new URLSearchParams(this.props.location.search);

        let result = params.get(key);
        return result;
    }

    changeUrlRequest(params) {
        let links = new Links(this.props.location);
        let newUrl = links.getUrlLink(params);
        this.props.history.push(newUrl);
    }

    getUrlRequest(params) {
        if (this.props.location==null) {
            throw AppException("Para usar el método Links.getUrlRequest, la clase debe tener HOC withRouter()");
        }
        let links = new Links(this.props.location);
        let newUrl = links.getUrlLink(params);
        return newUrl;
    }

    changeUrl(newUrl) {
        this.props.history.push(newUrl);
    }

    inArray(valor,array) {
        let result=true;
        if (array.indexOf(valor)==-1) {
            result=false;
        }
        return result;
    }


}

export default PropsUtil;
