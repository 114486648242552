import React, { Component } from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import util from "../../util/Util"
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";

@observer
class CreateAndModify extends Component {

    static defaultProps = {}

    static propTypes = {}

    render() {
        let props = this.props;
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let form = this.form;
        if (form == null) {
            form = {};
        }
        let appstate = appState.userCacheState.getUserById(props.createdBy);
        let creadoPor = appstate.firstName ? (appstate.firstName + " ") : ""
        + appstate.lastName ? appstate.lastName : "";

        //let fecha = moment(props.createdAt).locale('es').format("D MMMM YY");

        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }

        return (
            <div className="editable col-12">
                {this.props.getFormId != null &&
                <div className="row">
                    <div className="col-md-12">
                        <p className="last-modified">{t("Última modificación ")}
                            <span>{util.localizeIsoDateHour(props.updatedAt)}</span> {t("por ")}
                            <span>{appState.userCacheState.getUserById(props.updatedBy).email}</span>
                        </p>
                    </div>
                </div>
                }
                {this.props.getFormId != null &&

                <div className="block block--border col-12">
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <div className="group">
                                <div className="control"
                                     aria-labelledby="pedidos-solicitud">{t('ID_Pedidos/Solicitud')}</div>
                                <div id="pedidos-solicitud" className="font-weight-bold div-control">{props.code}</div>
                            </div>
                        </div>
                        {this.props.client != null &&
                        <div className="col-md-4 col-lg-3">
                            <div className="group">
                                <div className="control"
                                     aria-labelledby="pedidos-solicitud">{t('Cliente')}</div>
                                <div id="pedidos-solicitud" className="div-control">{props.client}</div>
                            </div>
                        </div>
                        }
                        <div className="col-md-4 col-lg-3">
                            <div className="group">
                                <div className="control" aria-labelledby="fecha-creacion">{t('Fecha de creación')}</div>
                                <div id="fecha-creacion"
                                     className="div-control">{util.localizeIsoDateHour(props.createdAt)}</div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-3">
                            <div className="group">
                                <div className="control" aria-labelledby="creada-por">{t('Creada por')}</div>
                                <div id="creada-por" className="div-control">{creadoPor}</div>
                            </div>
                        </div>
                        {this.props.status &&
                        <div className="col-md-12 col-lg-3">
                            <div className="group">
                                <div className="control" aria-labelledby="creada-por">{t('Estado del Tecnico')}</div>
                                <div id="creada-por"
                                     className="div-control">{appState.typifiedState.arrStatus[this.props.status] &&
                                appState.typifiedState.arrStatus[this.props.status].value}</div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>

        );
    }
}

export default withTranslate(CreateAndModify);

