import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import appState from "../state/AppState";
import Overlay from "../components/Overlay";
import VsfLink from "../components/VsfLink";
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";
import util from '../util/Util'

@observer
class UserButton extends Component {

    constructor() {
        super();
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div>
                <a role="button"
                   className="user-dropdown"
                   onClick={() => appState.layoutState.clickUser()}>
                    <span className="user-avatar">
                                    <i className="fa fa-user"/>
                                </span>
                    {this.props.userInfo()}
                </a>

                <span className="fas fa-cogs text-click" onClick={() => appState.layoutState.clickConfig()} />

                <Overlay show={appState.layoutState.user} onClick={_ => appState.layoutState.user = false} />
                {appState.layoutState.user &&
                <React.Fragment>
                    <dl className="dropdown-sublist" style={{ zIndex: 100 }}>
                        <dt className="dropdown-sublist__head">{t('Usuario')}</dt>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.user = false}
                                     to={"/profile/form"}>{t('Perfil')}</VsfLink></dd>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.user = false}
                                     to="/change/password">{t('Cambiar Contraseña')}</VsfLink></dd>
                        <dd className="dropdown-sublist__item">
                            <a onClick={(e) => {
                                appState.loginState.doLogout();
                            }}>{t("Cerrar Sesion")}</a></dd>

                    </dl>
                </React.Fragment>
                }


                <Overlay show={appState.layoutState.config} onClick={_ => appState.layoutState.config = false} />
                {appState.layoutState.config &&
                <React.Fragment>
                    <dl className="dropdown-sublist" style={{ zIndex: 100 }}>
                        <dt className="dropdown-sublist__head">{t('Configuracion')}</dt>
                        {appState.typifiedState.arrRolesByCode["ADMIN"] &&
                        util.perteneceA(appState.typifiedState.arrRolesByCode["ADMIN"].id, this.props.user.role) &&
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.config = false}
                                     to={"/config/translates"}>{t('Traducciones')}</VsfLink></dd>
                        }

                    </dl>
                </React.Fragment>
                }

            </div>
        );
    }

}

//withRouter es necesario para que AppWrapper pueda estar dentro de <Router> siendo @observable
export default withTranslate(withRouter(UserButton));

