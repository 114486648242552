import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import BaseListFilter from "../../subpages/BaseListFilter";
import OrderModel from "../../models/OrderModel";
import IsoDateListItem from "../../components/list/IsoDateListItem";
import WorkOrderStateListItem from "../../components/list/custom/WorkOrderStateListItem";
import ZoneColorList from "../../components/list/custom/ZoneColorList";
import PropsUtil from "../../util/PropsUtil";
import MoreButtons from "../../components/MoreButtons";
import InfoBlock from "../../components/InfoBlock";
import ListPageCounts from "../../components/ListPageCounts";
import CreatedByListItem from "../../components/list/CreatedByListItem";
import OrderListFilters from "./OrderListFilters";
import QuantityOTListItem from "../../components/list/QuantityOTListItem";
import ClientListItem from "../../components/list/ClientListItem";

@observer
class OrderList extends BaseList {

    constructor(props) {
        super(props);
        this.defaultOrder = "name";
        this.deleteFilter = this.deleteFilter.bind(this);
        this.quickEdit = true;
        this.quickView = true;
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.nameVariableStateFilter = "orderListFilters";
    }


    getListFields() {
        return [

            {
                title: this.props.t("Número Pedido"),
                name: "code",
                link: true,
                component: (props) => <WorkOrderStateListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("F. Creación"),
                name: "createdAt",
                component: (props) => <IsoDateListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Creado Por"),
                name: "createdBy",
                component: (props) => <CreatedByListItem link={true}  {...props} />,
                visible: true
            },
            {
                title: this.props.t("Estado"),
                name: "status",
                component: (props) => <WorkOrderStateListItem orderStatusList={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Cliente"),
                name: "client.name",
                component: (props) => <ClientListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Ubicación"),
                name: "client.posAddress",
                component: (props) => <TextListItem link={true} {...props} />,
                withOrder: false,
                visible: true
            },
            {
                title: this.props.t("Zona"),
                name: "client.posZoneId",
                withOrder: true,
                component: (props) => <ZoneColorList link={true} zoneWorkOrderList={true}
                                                     icon="fas fa-map-marked-alt" {...props} />,
                visible: true

            },
            {
                title: this.props.t("Solicitante"),
                name: "request.firstName",
                withOrder: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Tlf. Solicitante"),
                name: "request.phoneNumber",
                withOrder: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Contacto"),
                name: "contact.firstName",
                withOrder: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Tlf. Contacto"),
                name: "contact.phoneNumber",
                withOrder: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Cantidad OT"),
                name: "id",
                withOrder: false,
                component: (props) => <QuantityOTListItem link={false}{...props} />,
                visible: true
            },
            {
                name: "comments",
                title: this.props.t("Comentario"),
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
        ];
    }


    getModelQuery() {
        let result = new OrderModel();
        result.addRelatedTable("client");
        result.addRelatedTable("request");
        result.addRelatedTable("contact");

        return result;

    }

    getListState() {
        return appState.orderState;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.typifiedState.loadOrderStatus();
        await appState.clientCacheState.loadClientCache();
        await appState.typifiedState.loadStoreId();
        await appState.typifiedState.loadZones();
        await appState.userCacheState.loadUserCache();
        await appState.typifiedState.arrayLoadOrderStatus();
        appState.typifiedState.arrayLoadZones();
    }


    openQuickEdit(row) {
        this.getListState().quickEdit.row = row;
        this.propsUtil.changeUrlRequest({
            orderId: row.id,
            rightModal: "modalorderls",
            rightModalTab: "order",
            fromRightModal: true
        })
    }


    render() {
        this.propsUtil = new PropsUtil(this.props);
        const { t, i18n } = this.props;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <InfoBlock componentObject={this}>

                <ListItemSelect
                    stateListColumnsSelect={this.stateListColumnsSelect}
                    name={this.graphOperation}
                    subtitle={t('Pedidos')}
                />
                <BaseListFilter title={'Pedidos'} listComponent={this} stateFilter={this.stateListFilter}>
                    {this.renderFilters()}
                </BaseListFilter>
                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div className="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={t("Pedido")}
                    subtitle={t("Listado")}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nuevo Pedido", onClick: () => {
                                    this.propsUtil.changeUrl("/order/form/")
                                }
                            }
                        ]
                    }
                />
                </PageTitle>

                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent deleteFilter={this.deleteFilter}
                                         listState={this.getListState()}
                                         listData={this.getListData()}
                                         numEntriesPerPage={this.numEntriesPerPage}
                                         fields={fields}
                                         onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                         model={this.getModelQuery()}
                                         onClickAplicarFiltros={() => this.onClickAplicarFiltros()}
                                         includeSavedFavourites={this.includeSavedFavourites}
                                         includeChangeColumns={this.includeChangeColumns}
                                         includeFilters={this.includeFilters}
                                         includeDownload={this.includeDownload}
                                         stateListColumnsSelect={this.stateListColumnsSelect}
                                         includeSearch={this.includeSearch}
                                         {...props}
                                         updateFilters={(e) => this.updateInputFilterEvent(e)}
                                         listComponent={this}
                                         favouriteModalOpen={appState.layoutState.favouritesSaveMenuOpen}
                                         toggleFavouriteModal={() => appState.layoutState.clickFavouritesSaveMenuOpen()}
                        />
                        <ListComponent quickView={this.quickView}
                                       listState={this.getListState()}
                                       listData={this.getListData()}
                                       status={this.getListStatus()}
                                       fields={fields}
                                       urlForm={"/order/form"}
                                       openQuickEdit={(row) => this.openQuickEdit(row)}
                                       {...props}>
                        </ListComponent>
                    </div>

                </div>
                <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                                listState={this.getListState()}
                                listData={this.getListData()} {...props} />

                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />


            </InfoBlock>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <OrderListFilters key={"OrderList"} />
            </React.Fragment>
        )
    }


}

export default withRouter(withApollo(withTranslate(OrderList, "OrderList")));
