import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import {runInAction, toJS, action} from 'mobx';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import SlotModel from "../../models/SlotModel";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import { now } from "moment";
import GraphException from "../../network/GraphException";

const Types = {
    ITEM: 'workUnit'
};

class DropImpl {
    @action
    async dropImpl(props, monitor, component) {
        console.log("Monthdrop.drop");
        if (monitor.didDrop()) {
            return;
        }

        appState.layoutState.scheduledDateBlocked = "";
        appState.layoutState.scheduledUserBlocked = "";
        appState.layoutState.scheduledIsFinished = false;
        let hour = props.hour;
        let isoDate = props.isoDate;
        let technical = props.technical;
        let emptyCell = props.emptyCell;
        let optionTime = props.optionTime;

        // Obtain the dragged item
        const propsFromWorkUnitDragged = monitor.getItem();
        // La visita puede ser nula. Esto significa que estoy moviendo un elemento.
        if (propsFromWorkUnitDragged.slotId != null) {
            let slotObservable = appState.scheduleDropState.allSlotsDict[propsFromWorkUnitDragged.slotId];
            let slotModel = new SlotModel();
            slotModel.hidrate(slotObservable);

            let scheduledTimeInCurrentLocale = isoDate + "T" + util.numDigitos(hour, 2) + ":00:00";
            let momentScheduledTime = util.getMomentFromDateWithoutTimezone(scheduledTimeInCurrentLocale);
            if (!slotObservable.isDateTimeFixed) {
                slotModel.scheduledTime = momentScheduledTime.toISOString();
            }
            //slotModel.localHour=hour;
            slotModel.userId = technical && technical.id;
            //slotObservable.totalTimeEstimated++;
            //Si no tengo hora, le pongo las 8
            slotModel.localHour = hour;
            if (slotModel.localHour == null) {
                slotModel.localHour = 8;
            }

            //graphQL
            let slotPlain = slotModel.toPlainObject();
            slotPlain.lineaSinAsignar = emptyCell && emptyCell.id;
            if (slotPlain.lineaSinAsignar != null) {
                slotModel.userId = "";
            }
            try {
                //appState.scheduleDropState.allSlotsDict["-1"] = slotModel.toPlainObject();
                runInAction(() => {
                    let workOrderPlain = toJS(slotObservable["workOrder"]);
                    let slotModelPlain = slotModel.toPlainObject();
                    slotModelPlain["workOrder"]=workOrderPlain;

                    appState.scheduleDropState.allSlotsDict[propsFromWorkUnitDragged.slotId] = slotModelPlain;
                    appState.scheduleDropState.createTecnicosSinAsignar();
                    if (!appState.scheduleDropState.flagSlots) {
                        appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
                    } else {
                        appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
                    }
                });

                await slotModel.persist();
                await this.recargarSlotDesdeGQL(props, monitor, propsFromWorkUnitDragged);

            } catch (e) {
                await this.recargarSlotDesdeGQL(props, monitor, propsFromWorkUnitDragged);
                log("HourDrop. Exception");
                let gqlErrors = new GraphException().getErrorsFromException(e);
                log({gqlErrors});
                props.setErrors(gqlErrors);
            }
        }
    }

    async recargarSlotDesdeGQL(props, monitor, propsFromWorkUnitDragged) {
        let emptyCell = props.emptyCell;
        let optionTime = props.optionTime;

        let slot = new SlotModel();
        slot.addRelatedTable("workOrder");
        let slotModel = await slot.findById(propsFromWorkUnitDragged.slotId);
        let slotSupports = new SlotModel();
        slotSupports.assignedSlotMainId = slotModel.id;
        let slotsSupport = await slotSupports.findPlainObject();
        runInAction(() => {
            appState.scheduleDropState.allSlotsDict[propsFromWorkUnitDragged.slotId] = slotModel.toPlainObject();
            appState.scheduleDropState.allSlotsDict[propsFromWorkUnitDragged.slotId].lineaSinAsignar = emptyCell && emptyCell.id;
            if (slotsSupport.length > 0) {
                let idsSupports = {};
                for (let slot of slotsSupport) {
                    if (idsSupports[slot.id] == null)
                        idsSupports[slot.id] = slot.scheduledTime;
                }
                for (let id of Object.keys(idsSupports)) {
                    appState.scheduleDropState.allSlotsDict[id].scheduledTime = idsSupports[id];
                }
                appState.scheduleDropState.createTecnicosSinAsignar();
            }
            if (!appState.scheduleDropState.flagSlots) {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
            } else {
                appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
            }
        });
    }
}

const itemTarget = {
    hover(props, monitor, component) {
        if (monitor.isOver({ shallow: false })) {
            //console.log("itemTarget.hover1 SHALLOW");
            return;
        } else {
            //console.log("itemTarget.hover1 NOT SHALLOW");
        }
        console.log({ props });
        console.log({ component });
        let item = monitor.getItem();

        let slot = toJS(appState.scheduleDropState.allSlotsDict[item.slotId]);
        if (props.optionTime == "mes") {
            if (slot.isDateTimeFixed) {
                let fecha = util.getMoment(slot.scheduledTime).format("H") < 14 ?
                    (util.getMoment(slot.scheduledTime).format("YYYY-MM-DD") + ' 08:00') :
                    (util.getMoment(slot.scheduledTime).format("YYYY-MM-DD") + ' 14:00');
                appState.layoutState.scheduledDateBlocked = fecha;
            } else {
                appState.layoutState.scheduledDateBlocked = "";
            }
            if (slot.isUserFixed) {
                appState.layoutState.scheduledUserBlocked = slot.userId;
            } else {
                appState.layoutState.scheduledUserBlocked = "";
            }
        } else {
            if (slot.isDateTimeFixed) {
                appState.layoutState.scheduledDateBlocked = util.getMoment(slot.scheduledTime).format("YYYY-MM-DD HH:mm");
            } else {
                appState.layoutState.scheduledDateBlocked = "";
            }
            if (slot.isUserFixed) {
                appState.layoutState.scheduledUserBlocked = slot.userId;
            } else {
                appState.layoutState.scheduledUserBlocked = "";
            }
        }
        if (slot.isFinished == 1) {
            appState.layoutState.scheduledIsFinished =true ;
        }

        // log({ item });

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        console.log({ dragIndex, hoverIndex });
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()
        console.log({ hoverBoundingRect });

    },
    drop(props, monitor, component) {
        // drop no puede ser async (devolver un Promise), por lo que lo sacamos de aquí
        let dropImpl = new DropImpl();
        dropImpl.dropImpl(props, monitor, component);
        return undefined;
    }

};

function log(msg) {
    AppLogger.get().debug(msg, this);
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class HourDrop extends Component {


    static propTypes = {
        //Objeto que contiene el dia, la hora, el técnico: {day:"2019-10-20", hour:"8", technical:9},
        hourItem: PropTypes.object,
        hour: PropTypes.number, // La hora en la que empieza esta tarea
        isoDate: PropTypes.string, // La fecha en formato 2019-10-20
        technical: PropTypes.object, // {id:"2", name:"sdfsd"}
        emptyCell: PropTypes.object, // Linea donde aparece la tarea {id:"2", name:"sdfsd"}
        pixelsPerHour: PropTypes.number, //Numero de pixel por cada hora
        numHoursSlot: PropTypes.number, //Numero de horas en cada slot
        index: PropTypes.number, //Indice dentro del día
        length: PropTypes.number, //Número de elementos
    };

    render() {
        const { connectDropTarget } = this.props;
        let isoDate = this.props.isoDate;
        let hour = this.props.hour;
        let fechaTabla = isoDate + " " + util.numDigitos(hour, 2) + ":59";
        let fechaAhora = util.getMoment(Date(now())).format("YYYY-MM-DD HH:mm");
        let width = this.props.pixelsPerHour * this.props.numHoursSlot;
        let slotFixed = false;
        let fechaFixed = isoDate + " " + util.numDigitos(hour, 2) + ":00";
        if (util.hasValue(appState.layoutState.scheduledDateBlocked) && fechaFixed != appState.layoutState.scheduledDateBlocked) {
            slotFixed = true;
        }
        if (appState.layoutState.scheduledIsFinished) {
            slotFixed = true;
        }
        if (util.hasValue(appState.layoutState.scheduledUserBlocked) && this.props.technical &&
            this.props.technical.id != appState.layoutState.scheduledUserBlocked) {
            slotFixed = true;
        }
        let className = "drop-hours "
        if (this.props.finSemana) {
            className += " finSemana"
        }
        if (this.props.optionTime != "mes") {
            if (this.props.hour >= appState.scheduleDropState.entradaComida && this.props.hour < appState.scheduleDropState.salidaComida) {
                className += " finSemana"
            }

        }

        if (fechaTabla < fechaAhora || slotFixed) {
            return (
                <td className={className + " inactive"} style={{ width }}>
                    {this.props.children}
                </td>)
        } else {
            return connectDropTarget(
                <td className={className} style={{ width }}>
                    {this.props.children}
                </td>
            )
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DropTarget(Types.ITEM, itemTarget, collect)(HourDrop)
