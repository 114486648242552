import React, { Component } from 'react';
import appState from '../state/AppState';
import { observer } from "mobx-react/index";
import VsfButton from "../components/VsfButton";
import VsfLink from "../components/VsfLink";

@observer
class LoginPage extends Component {

    async loginClick(){
        await appState.loginState.doLogin();
        if (appState.loginState.accessToken!="") {
            //window.location.href="/#!/user/ls";
        }
        console.log('boton de click');
    }

    updateInputEventLogin(e) {
        const {name, value} = e.target;
        appState.loginState.dataUser.Email = value;
        console.log('nameUser=>',name);
        let mail= appState.loginState.dataUser.email = value;
        console.log('mailUser=>',mail);

        localStorage.setItem('mail',mail);
    }

    updateInputEventPass(e) {
        const {name, value} = e.target;

        appState.loginState.dataUser.password = value;
        console.log(name);
        console.log(appState.loginState.dataUser.password = value)
    }


    render(){
        return (
            <section className="container-fluid align-items-center login">
                <div className="row justify-content-center align-items-center login-block">
                    <div className="blur-block"></div>
                    <form name="login" className="text-center login-form js-login-form" onSubmit={(e) => {
                        e.preventDefault();
                        //this.loginClick();
                        return false;
                    }}>
                        <input type="hidden" name="op" value="login"/>
                            <input type="hidden" id="_token" name="_token"
                                   value="gEjMTpIPVEOHenacxFAZPv0UVhZ8h78M6VhS49C0"/>
                                <input type="hidden" name="referer" value=""/>
                                {appState.loginState.errroLogin &&
                                    <div className="login-error js-login-error mb-3 p-3">
                                         <i className="fa fa-exclamation-circle"></i>
                                        <span>{appState.loginState.errroLogin}</span>
                                        </div>
                                }
                                            <div className="login-logo mb-3 ml-auto mr-auto"></div>
                                            {/*<div className="login-intro text-center mb-3">*/}
                                                {/*Inciar sesión en <strong>Nuddo</strong> introduciendo tu email y contraseña*/}
                                            {/*</div>*/}
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1"><span
                                                        className="fas fa-envelope"></span></span>
                                                </div>
                                                <input type="text"
                                                       name="login"
                                                       className="form-control"
                                                       size="16"
                                                       placeholder="E-mail"
                                                       aria-label="Email"
                                                       aria-describedby="basic-addon1"
                                                       autoComplete="off"
                                                       onChange={(e) => this.updateInputEventLogin(e)}
                                                />
                                            </div>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1"><span
                                                        className="fas fa-lock"></span></span>
                                                </div>
                                                <input type="password"
                                                       onChange={(e) => this.updateInputEventPass(e)}
                                                       name="password"
                                                       className="form-control"
                                                       size="16"
                                                       placeholder="Password"
                                                       aria-label="Password"
                                                       aria-describedby="basic-addon1"
                                                       autoComplete="off"/>
                                            </div>
                                            <div className="form-group custom-checkbox mt-3 mb-0">
                                                <input type="checkbox" id="remember-access" tabindex="0"  />
                                                    <label htmlFor="remember-access" className="mb-0">Recordar datos de
                                                        acceso</label>
                                            </div>
                                            <div className="form-group mt-3 mb-0">
                                                <VsfButton
                                                    label={'Acceder'}
                                                    classButton="btn-block btn-lg btn-primary mt-0 mb-0 js-login-btn"
                                                    disabled={appState.loginState.loginButtonLoading}
                                                    onClick={() => this.loginClick()}
                                                    type="submit"/>

                                            </div>
                                            <div className="form-group mt-2 mb-0 link-remember">
                                                <VsfLink  to={'/password/reset/'}>¿No recuerdas tu contraseña?</VsfLink>
                                            </div>
                    </form>
                </div>
            </section>

        );
    }
}

export default LoginPage;
