import Model from "./Model";
import AbstractModel from "./AbstractModel";

export default class ArticleCodesModel extends AbstractModel {

    id;
    articleId;
    code;
    type;
    providerName;

    nameMainType = "articleCode";
    graphFindByIdOperation = "articleCode";
    graphFindOperation = "articleCodesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "articleId": "",
            "code": "",
            "type": "",
            "providerName": "",
        };
        return result;
    }

    async getArticleCodesFromArticleId(articleId) {
        let result = [];
        if (articleId != null) {
            let codesQuery = new ArticleCodesModel();
            codesQuery.articleId = articleId;
            result = await codesQuery.find();
        }
        return result;
    }

}
