import { observable, computed, autorun, action } from 'mobx';
import FetchProxy from "../network/FetchProxy";
import config from "../config/config";
import AppLogger from "../util/AppLogger";
import appState from "./AppState";
import util from "../util/Util";
import LoadTranslations from "../translator/LoadTranslations";

class LoginState {

    @observable accessToken = window.localStorage.getItem('accessToken');

    @observable userId = window.localStorage.getItem('userId');

    @observable refreshToken = window.localStorage.getItem('refreshToken');


    /* "Email": "test@vsf.es",
     "Password": "Password1!",*/
    @observable loginButtonLoading = false;
    @observable errroLogin = "";
    @observable dataUser = {};

    /* *"Email": "eangulo@vsf.esddd",
     "Password": "Password1*"*/
    @computed get isUserLogged() {
        let result = false;
        let accessToken = this.accessToken;
        /*
        if (accessToken==="") {
            accessToken = window.localStorage.getItem('accessToken');
        }
        */
        if (accessToken !== "" && accessToken != null) {
            result = true;
        }
        console.log({ _isUserLogged: 0, token: accessToken });
        return result;
    }

    @computed get getAccessToken() {
        let result = false;
        let accessToken = this.accessToken;
        console.log({ accessToken });
        return accessToken;
    }

    getUserId() {
        return this.userId;
    }

    async getUser() {
        this.log("getUser() this.userId: " + this.userId);
        let result = null;
        if (util.hasValue(this.userId)) {
            if (this.user != null) {
                result = this.user;
            } else {
                this.log("getUser() Loading ...");
                result = await appState.userState.getUserById(this.userId);
            }
        }
        this.log("getUser() result=> ");
        this.log(result);
        return result;
    }

    /*
        async fetchUrl(url, params) {
            if (params==null) {
                params={};
            }
            params["credentials"]='same-origin';
            let result = await fetch(url,params);
            return result;
        }

        SetCookie (name,value,expires,path,domain,secure) {
            document.cookie = name + "=" + escape (value) +
                ((expires) ? "; expires=" + expires.toGMTString() : "") +
                ((path) ? "; path=" + path : "") +
                ((domain) ? "; domain=" + domain : "") +
                ((secure) ? "; secure" : "");
        }


        /*
                // Revisar https://github.com/videojs/video.js/issues/5247
                //https://github.com/videojs/video.js/issues/5247
                this.SetCookie("Prueba","Valor",null,"/","cloudfront.net",false);
                let params={};
                params["headers"]= {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Access-Control-Allow-Origin':'*'
                };
                params["mode"]='no-cors';
                params["method"]="GET";
                let url="http://d258l8cxmo1tmv.cloudfront.net/testfilters.xlsx";
                let prueba2 = await this.fetchUrl(url,params);
    */

    async doLogin() {
        let httpApi = new FetchProxy();
        try {
            this.loginButtonLoading = true;
            this.log("doLogin()");
            let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/token",
                {
                    "Email": this.dataUser.email,
                    "Password": this.dataUser.password,
                }
            );

            //console.log('resultado del response');
            //console.log(response);
            let responseJson = await response.json();
            //console.log(responseJson.message)
            //console.log(responseJson.message)
            console.log(responseJson.errorCode);
            if (responseJson.errorCode == 0) {
                this.loadAccessTokenInfo(responseJson.data);
                let user = this.getUser();
                if (user.timeZone != null) {
                    window.localStorage.setItem('currentTimeZone', user.timeZone);
                }
                if (user.locale != null) {
                    window.localStorage.setItem('currentLanguage', user.locale);
                    let loadTranslations = new LoadTranslations();
                    await loadTranslations.loadMessages();
                }
                //window.location.reload();
            } else {
                this.log(responseJson);
                this.errroLogin = responseJson.message;
            }
            this.log("/doLogin. response=>");
            this.log({ response });
        } catch (e) {
            this.log("doLogin. Exception");
            this.log({ e });
            this.errroLogin = "Se ha producido un error. Por favor vuelva a intentarlo pasados unos minutos";
        }
        this.loginButtonLoading = false;
        this.log("/doLogin");
    }

    @action
    doLogout() {
        //window.location.reload();
        window.localStorage.setItem('accessToken', "");
        window.localStorage.setItem('refreshToken', "");
        window.localStorage.setItem('userId', "");
        window.location.href = "/";

        //Esto no hace falta porque se recarga la página
        /*
        this.accessToken = "";
        this.accessTokenExpiration = "";
        this.refreshToken = "";
        this.userId = "";
        */
    }

    loadAccessTokenInfo(jsonData) {
        this.accessToken = jsonData.access_token;
        this.accessTokenExpiration = jsonData.access_token_expiration;
        this.refreshToken = jsonData.refresh_token;
        this.userId = jsonData.user_id;
        //No son async porque pueden hacerse en el background
        window.localStorage.setItem('accessToken', this.accessToken);
        window.localStorage.setItem('accessTokenExpiration', this.accessTokenExpiration);
        window.localStorage.setItem('refreshToken', this.refreshToken);
        window.localStorage.setItem('userId', this.userId);
    }

    async doRefreshToken() {
        let httpApi = new FetchProxy();
        try {
            if (this.refreshToken != "") {
                let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/refresh",
                    { "RefreshToken": this.refreshToken }
                );
                let responseJson = await response.json();
                console.log(responseJson);

                this.loadAccessTokenInfo(responseJson);
            }
        } catch (e) {

        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default LoginState;
