import React from 'react';
import appState from '../../state/AppState';
import { observer } from "mobx-react";
import PageTitle from "../../components/PageTitle";
import withTranslate from "../../translator/withTranslate";
import GroupOfField from "../../components/layout/GroupOfField";
import { withRouter } from "react-router-dom";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import TranslationsModel from "../../models/TranslationsModel";
import BaseCommon from "../base/BaseCommon";
import AppLogger from "../../util/AppLogger";
import Select2Component from "../../components/fields/Select2Component";
import { observable } from "mobx";
import TypifiedValueModel from "../../models/TypifiedValueModel";

@observer
class ConfigTranslates extends BaseCommon {

    @observable languages = [];

    constructor(props) {
        super(props);
        this.nameMainType = "translation";
        this.state = {
            text: {}
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        this.reloadTable();
        await this.loadLanguages();
    }

    async loadLanguages() {
        let typifiedQuery = new TypifiedValueModel();
        typifiedQuery.type = "locales";
        let languages = await typifiedQuery.find();
        let result = [];
        for (let lang of languages) {
            result.push(lang.value)
        }
        this.languages = result;
    }

    async reloadTable() {
        let device = this.propsUtil.getRequest("device");
        let translationQuery = new TranslationsModel();
        if (util.hasValue(device)) {
            translationQuery.device = device;
        }
        translationQuery.orderBy = "label";
        let translations = await translationQuery.findPlainObject();
        appState.translationState.translations = translations;
    }


    handleOnBlur(e, translation) {
        this.log("handleOnBlur");
        if ((translation.message != e.target.value) && util.hasValue(e.target.value)) {
            translation.message = e.target.value;
            let toSave = new TranslationsModel();
            toSave.hidrate(translation);
            toSave.message = e.target.value;
            toSave.persist();
        }
    }

    handleOnBlurNewLanguage(e, lang) {
        let device = this.propsUtil.getRequest("device");
        this.log("handleOnBlur");
        if (util.hasValue(e.target.value)) {
            let toSave = new TranslationsModel();
            toSave.message = e.target.value;
            toSave.label = e.target.name;
            toSave.lang = lang;
            if (util.hasValue(device)) {
                toSave.device = device;
            } else {
                toSave.device = TranslationsModel.WEB;
            }
            toSave.persist();
        }
    }

    async changeDevice(e) {
        await this.propsUtil.changeUrlRequest({ device: e.target.value });
        await this.reloadTable();
    }

    render() {
        let { t } = this.props;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let device = this.propsUtil.getRequest("device") ? this.propsUtil.getRequest("device") : "";
        let filasIndexedByLabel = {};
        for (let translation of appState.translationState.translations) {
            if (filasIndexedByLabel[translation.label] == null) {
                filasIndexedByLabel[translation.label] = {};
            }
            filasIndexedByLabel[translation.label][translation.lang] = translation;

        }
        let options = [
            { label: "Web", value: TranslationsModel.WEB },
            { label: "Movil", value: TranslationsModel.MOBILE },
            { label: "Todo", value: "" },
        ];

        return (
            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    <PageTitle
                        title={"Traducciones"}
                    />
                    <div className={'card mb-3'}>
                        <div className={modalClasses.body}>
                            <div className="card-body">
                                <div className="row">
                                    <GroupOfField
                                        title={t('Traducciones')}
                                        icon="fas fa-id-card"
                                    />

                                </div>
                                <div className="row">
                                    <div className="col-2 ml-5">
                                        <Select2Component
                                            value={device}
                                            onChange={(e) => this.changeDevice(e)}
                                            name={"scheduleDate"}
                                            clearable={false}
                                            classGroup={"col-12 mt-3"}
                                            type={"text"}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="table-edit">
                                        <div className="col-12">
                                            <table className="table visible-text">
                                                <thead>
                                                {Object.entries(filasIndexedByLabel).length > 0 &&
                                                <tr key={filasIndexedByLabel[0]}>
                                                    <th scope="col">{t('Label')}</th>
                                                    {this.languages.map(lang => (
                                                        <th key={lang} scope="col">{lang}</th>
                                                    ))
                                                    }
                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">

                                                {Object.entries(filasIndexedByLabel).map(([label, objWithLanguages]) => (
                                                    <tr key={label}>
                                                        <td>
                                                            {label}
                                                        </td>
                                                        {this.languages.map((language) => (
                                                            <td key={label + language}>
                                                                {objWithLanguages[language] == null ?
                                                                    <InputTypeComponent
                                                                        value={this.state.text[label]}
                                                                        onBlur={(e) => this.handleOnBlurNewLanguage(e, language)}
                                                                        name={label}
                                                                        type={"text"}
                                                                        className="form-control"
                                                                    />
                                                                    :
                                                                    (<>
                                                                            <InputTypeComponent
                                                                                value={objWithLanguages[language].message}
                                                                                onBlur={(e) => this.handleOnBlur(e, objWithLanguages[language])}
                                                                                name={"message"}
                                                                                type={"text"}
                                                                                className="form-control"
                                                                            />
                                                                        </>
                                                                    )}

                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(withTranslate(ConfigTranslates, "ConfigTranslates"));
