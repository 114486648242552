import React, { Component } from 'react';
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import { DropTarget } from "react-dnd/lib/index";
import PropTypes from 'prop-types';
import WorkUnitDrag from "./WorkUnitDrag";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import { now } from "moment";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import { observable } from "mobx";
import SlotModel from "../../models/SlotModel";

const Types = {
    ITEM: 'workUnit'
}

const dropdebug = true;

const itemTarget = {

    hover(props, monitor, component) {
        if (dropdebug) {
            log("itemTarget.hover2");
            log({ props });
            log({ component });
        }
        if (!component) {
            return null
        }
        let item = monitor.getItem();
        if (dropdebug) {
            log({ item });
        }

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        if (dropdebug) {
            console.log({ dragIndex, hoverIndex });
        }
        // Don't replace items with themselves
        // if (dragIndex === hoverIndex) {
        //     return
        // }

        // dragIndex puede ser 5 y hover 1. Debo poner la posici�n 5 antes de la 1
        let preventOrders = appState.preventOrders;

        // Creo un elemento "provisional". Si al final se cancela el movimiento deber�a quitar el elemento "ghost"
        let currentElementMoving = null;
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
                currentElementMoving = preventOrders[i];
                currentElementMoving.date = "2019prov";
                currentElementMoving.type = "ghost-moving";
            }
        }

        let newPreventOrders = [];
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
            } else {
                if (preventOrders[i].name == hoverIndex) {
                    newPreventOrders.push(currentElementMoving);
                }
                newPreventOrders.push(preventOrders[i]);
            }
        }
        appState.preventOrders = newPreventOrders;
        console.log("moved");

        // Determine rectangle on screen
        /*
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()
        console.log({ hoverBoundingRect });
        */
    },
    drop(props, monitor, component) {
        console.log("drop");
        // Obtain the dragged item
        const orderFromProps = monitor.getItem();
        console.log("drop=>");
        appState.layoutState.scheduledDateBlocked = "";
        appState.layoutState.scheduledUserBlocked = "";
        appState.layoutState.scheduledIsFinished = false;
        console.log(orderFromProps);
        console.log({ props });
        console.log({ component });
    }
}

function log(msg) {
    AppLogger.get().debug(msg, this);
}

function collectTarget(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class WorkUnit extends Component {
    static defaultProps = {
        inClientBlock: false
    }
    static propTypes = {
        //Objeto que contiene el mes (code) y el nombre de la visita: {label:"Ene", year:19, code:'201901'},
        name: PropTypes.string,
        // Elemento que identifica a un elemento ya planificado en un mes. Tenemos el WorkOrderModel asociado
        slotId: PropTypes.string,
        // Indica que este elemento está en la pestaña clientes. De esta forma si está asignado es gris
        inClientBlock: PropTypes.bool,
        pixelsPerHour: PropTypes.number, //Numero de pixel por cada hora
    };

    @observable finalizada = {};

    getAssetFromAssetId(assetId) {
        let result = {};
        for (let asset of appState.assetState.assets) {
            if (util.hasValue(assetId)) {
                if (asset.id === assetId) {
                    result = asset;
                    break;
                }
            }
        }
        return result;
    }

    async componentDidMount() {
        let typifiedModel = new TypifiedValueModel();
        typifiedModel.type = "status";
        let typifiedStatus = await typifiedModel.find();
        this.finalizada = util.getDictSingleFromArray(typifiedStatus);
    }

    workOrdersCodes() {
        let slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        let workOrdersCodes = []
        if (slot.workOrder) {
            slot.workOrder.map(workOrder => {
                if (workOrder.assetId != null) {
                    workOrdersCodes.push(<span
                        className={workOrder.status == this.finalizada.id ? "small color-text-green" : "small "}>

                                    {appState.typifiedState.arrAssetTypesCodes[this.getAssetFromAssetId(workOrder.assetId).type] ?
                                        appState.typifiedState.arrAssetTypesCodes[this.getAssetFromAssetId(workOrder.assetId).type] + " "
                                        : "NA"}
                                </span>)
                } else {
                    workOrdersCodes.push(<span
                        className={workOrder.status == this.finalizada.id ? "small color-text-green" : "small "}>

                                    {util.hasValue(appState.typifiedState.arrAssetTypesCodes[workOrder.assetType]) ?
                                        appState.typifiedState.arrAssetTypesCodes[workOrder.assetType] + " "
                                        : "NA"
                                    }</span>)
                }
            })
        }
        return workOrdersCodes;
    }

    checkDisabled() {
        let disabled = false
        let slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        let isoDate = this.props.isoDate;
        let hour = this.props.hour;
        let fechaTabla = isoDate + " " + util.numDigitos(hour, 2) + ":59";
        let fechaAhora = util.getMoment(Date(now())).format("YYYY-MM-DD HH:mm");
        if (fechaTabla < fechaAhora) {
            disabled = true;
        } else {
            disabled = false;
        }
        if (this.props.inClientBlock) {
            if (slot.scheduledTime != null || slot.userId != null) {
                disabled = true
            } else {
                disabled = false
            }
        }
        return disabled;
    }

    colorBox() {
        let slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        let claseSlot = " drag-hours2 color-prevent ";
        if (slot.slotUserRole === SlotModel.SUPPORT) {
            claseSlot += " color-prevent--lines ";
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Preventiva") {
            claseSlot += " color-prevent ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-prevent--lines ";
            }
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Programada") {
            claseSlot += " color-program ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-program--lines  ";
            }
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Evento") {
            claseSlot += " color-events ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-events--lines  ";
            }
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Correctiva") {
            claseSlot += " color-corrective ";
            if (slot.slotUserRole === SlotModel.SUPPORT) {
                claseSlot += " color-corrective--lines ";
            }
        }
        if (appState.layoutState.backgroundScheduled == slot.orderId) {
            claseSlot += " seleccionada "
        } else {
            claseSlot += ""
        }
        let todasFinalizadas = true;
        if (slot.workOrder) {
            slot.workOrder.map(workOrder => {
                if (workOrder.status != this.finalizada.id) {
                    todasFinalizadas = false;
                }
            });
            if (todasFinalizadas) {
                claseSlot += " border-color-green";
            } else {
                if (!this.props.inClientBlock && slot.isUserFixed) {
                    claseSlot += " is-user-fixed ";
                } else {
                    claseSlot += " ";
                }
                if (!this.props.inClientBlock && slot.isDateTimeFixed) {
                    claseSlot += " is-date-fixed ";
                } else {
                    claseSlot += " ";
                }
            }
        }

        return claseSlot
    }

    render() {
        const { connectDropTarget } = this.props;
        let slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        let prevent = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        if (prevent == null) {
            return (
                <> NULL </>);
        }
        let workOrdersCodes = this.workOrdersCodes();
        let claseSlot = this.colorBox();
        let totalTime = slot.scheduledDuration;
        let width = 6;
        if (totalTime % 1 == 0) {
            width = util.str2int(this.props.pixelsPerHour) * Math.round(totalTime);
        } else {
            width = (util.str2int(this.props.pixelsPerHour) * Math.round(totalTime)) - (util.str2int(this.props.pixelsPerHour) / 2);
        }
        if (this.props.optionTime == "mes") {
            width = 6 * totalTime;
        }
        if (width <= 0) {
            width = util.str2int(this.props.pixelsPerHour) / 2;
        }
        let disabled = this.checkDisabled();

        let classAsignada = "";
        if (!this.props.inClientBlock) {
            classAsignada = " drag-absolute";
        }

        if (this.props.inClientBlock || this.props.optionTime == "mes" && width > 18) {
            width = 18;
        }

        if (disabled) {
            return (
                <div>
                    <div className={claseSlot + " no-active " + classAsignada}
                         style={{ width: width }}
                         data-order-id={slot.orderId}>
                        <WorkUnitDrag {...this.props} disabled={true} width={width} workOrdersCodes={workOrdersCodes} />
                    </div>
                </div>
            );
        } else {
            return connectDropTarget(
                <div>
                    <div className={claseSlot + classAsignada}
                         style={{ width: width }}
                         data-order-id={slot.orderId}>
                        <WorkUnitDrag {...this.props} width={width} workOrdersCodes={workOrdersCodes} />
                    </div>
                </div>
            )
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DropTarget(Types.ITEM, itemTarget, collectTarget)(WorkUnit)
