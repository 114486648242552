import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SlotModel from "../../models/SlotModel";
import util from "../../util/Util";
import { now } from "moment";
import { toJS } from "mobx";

const Types = {
    ITEM: 'workUnitInClient'
}


const itemTarget = {
    hover(props, monitor, component) {
        let item = monitor.getItem();
        let slot = toJS(appState.scheduleDropState.allSlotsDict[item.slotId]);
        appState.layoutState.scheduledUserBlocked = slot.clientId;
        if (props.optionTime == "mes") {
            if (slot.isDateTimeFixed) {
                let fecha = util.getMoment(slot.scheduledTime).format("H") < 14 ?
                    (util.getMoment(slot.scheduledTime).format("YYYY-MM-DD") + ' 08:00') :
                    (util.getMoment(slot.scheduledTime).format("YYYY-MM-DD") + ' 14:00');
                appState.layoutState.scheduledDateBlocked = fecha;
            } else {
                appState.layoutState.scheduledDateBlocked = "";
            }
        }
    },
    async drop(props, monitor, component) {
        if (monitor.didDrop()) {
            return;
        }
        appState.layoutState.scheduledDateBlocked = "";
        appState.layoutState.scheduledUserBlocked = "";
        let hour = props.hour;
        let isoDate = props.isoDate;
        let technical = props.technical;
        let emptyCell = props.emptyCell;

        // Obtain the dragged item
        const propsFromWorkUnitDragged = monitor.getItem();
        // La visita puede ser nula. Esto significa que estoy moviendo un elemento.
        if (propsFromWorkUnitDragged.slotId != null) {
            let slotObservable = appState.scheduleDropState.allSlotsDict[propsFromWorkUnitDragged.slotId];
            let slotModel = new SlotModel();
            slotModel.hidrate(slotObservable);
            slotModel.scheduledTime = isoDate + "T" + util.numDigitos(hour, 2) + ":00:00";
            //slotModel.localHour=hour;
            slotModel.userId = technical && technical.id;
            //slotObservable.totalTimeEstimated++;
            //Si no tengo hora, le pongo las 8
            slotModel.localHour = hour;
            if (slotModel.localHour == null) {
                slotModel.localHour = 8;
            }
            //graphQL
            let slotPlain = slotModel.toPlainObject();
            slotPlain.lineaSinAsignar = emptyCell && emptyCell.id;
            if (slotPlain.lineaSinAsignar != null) {
                slotModel.userId = "";
            }
            slotModel.persist();
            let slot = new SlotModel();
            slot.addRelatedTable("order");
            slotModel = await slot.findById(propsFromWorkUnitDragged.slotId);
            appState.scheduleDropState.allSlotsDict[propsFromWorkUnitDragged.slotId] = slotModel.toPlainObject();
            ;
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsByClient();
            props.loadClients();
        }
    }
}


function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class ClientHourDrop extends Component {

    static propTypes = {
        //Objeto que contiene el dia, la hora, el técnico: {day:"2019-10-20", hour:"8", technical:9},
        hourItem: PropTypes.object,
        hour: PropTypes.number, // La hora en la que empieza esta tarea
        isoDate: PropTypes.string, // La fecha en formato 2019-10-20
        client: PropTypes.object, // {id:"2", name:"sdfsd"}
        emptyCell: PropTypes.object, // Linea donde aparece la tarea {id:"2", name:"sdfsd"}
        pixelsPerHour: PropTypes.number, //Numero de pixel por cada hora
        numHoursSlot: PropTypes.number, //Numero de horas en cada slot
    };

    render() {
        const { connectDropTarget } = this.props;
        let isoDate = this.props.isoDate;
        let hour = this.props.hour;
        let fechaTabla = isoDate + " " + util.numDigitos(hour, 2) + ":00";
        let fechaAhora = util.getMoment(Date(now())).format("YYYY-MM-DD HH:mm");
        let width = util.str2int(this.props.pixelsPerHour);
        let fechaFixed = isoDate + " " + util.numDigitos(hour, 2) + ":00";
        let slotFixed = false;
        if (util.hasValue(appState.layoutState.scheduledDateBlocked) && fechaFixed != appState.layoutState.scheduledDateBlocked) {
            slotFixed = true;
        }
        if (util.hasValue(appState.layoutState.scheduledUserBlocked) && this.props.client.id != appState.layoutState.scheduledUserBlocked) {
            slotFixed = true;
        }
        let className = ""
        if (this.props.finSemana) {
            className = " finSemana"
        }
        if (width > 18) {
            width = 18;
        }
        if (fechaTabla < fechaAhora || slotFixed) {
            return (
                <td className={"drop-hours th-bottom__item inactive" + className} style={{ width }}>
                    {this.props.children}
                </td>)
        } else {
            return connectDropTarget(
                <td className={"drop-hours th-bottom__item" + className} style={{ width }}>
                    {this.props.children}
                </td>
            )
        }
    }

}


export default DropTarget(Types.ITEM, itemTarget, collect)(ClientHourDrop)
