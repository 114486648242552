import React, { Component } from 'react';
import appState from '../state/AppState';
import Login from "./Login";
import Dashboard from "./Dashboard";

class Home extends Component {

    render() {
        let userLogged = appState.loginState.isUserLogged;
        let a= null;
        //a.key=9;
        if (!userLogged) {
            return (
                <Login/>
            )
        }
        return (
            <Dashboard/>
        );
    }
}

export default Home;
