import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import { withApollo } from "react-apollo";
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import HistoryComponent from "../../components/HistoryComponent";
import AssetModel from "../../models/AssetModel";
import withTranslate from "../../translator/withTranslate";

@observer
class AssetHistory extends BaseForm {
    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
    }

    getModelQuery() {
        return new AssetModel();
    }

    render() {
        console.log("AssetHistory render =>");
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        const { t, i18n } = this.props;

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Equipos"}
                                subtitle={t("Histórico")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets()}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Equipos")}
                            subtitle={t("Histórico")}
                        />
                    }

                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataAssets()}
                            active={"Histórico"}
                        />
                        }
                        <div className="card-body">
                            <HistoryComponent
                                id={this.getFormId()}
                                modelo={this.getModelQuery()}
                            />


                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(withApollo(withTranslate(AssetHistory)));
