
let singleton=null;

class AppLogger {

    /**
     *
     * @returns {AppLogger}
     */
    static get() {
        if (singleton==null) {
            singleton=new AppLogger();
        }
        return singleton;
    }

    info(msg, classObj) {
        this.debug(msg,classObj);
    }

    onlyDebug=[
        "RightModals"
    ]

    debug(msg, classObj) {
        let message="";
        let className = "[]";
        if (classObj) {
            className = classObj.constructor.name;
        }
        message="["+className+ "] ";
        if (typeof msg === 'string') {
            message+=msg;
            consoleapp.log(message);
        } else {
            consoleapp.log(message +" =>");
            consoleapp.log(msg);
        }
    }

}


export default AppLogger;