import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import AppLogger from "../../util/AppLogger";

const Types = {
    ITEM: 'workUnit'
};
const itemSource = {
    endDrag() {
        appState.layoutState.scheduledDateBlocked = "";
        appState.layoutState.scheduledUserBlocked = "";
        appState.layoutState.scheduledIsFinished = false;
    },
    beginDrag(props) {
        console.log("beginDrag");
        console.log({ props });
        return { ...props };
    },
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }
}

function log(msg) {
    AppLogger.get().debug(msg, this);
}

@observer
class WorkUnitDrag extends Component {

    render() {
        const { isDragging, connectDragSource, connectDragPreview, src } = this.props;
        let slot = appState.scheduleDropState.allSlotsDict[this.props.slotId];
        let title = "";
        if (slot.typeWorkOrder == 0) {
            title = "Preventiva";
        }

        if (this.props.disabled) {
            return (<div className="drag-hours__item h-4" style={{ width: this.props.width }}
                         title={title} onClick={(e) => {
                this.onClick(e, slot);
            }} onMouseEnter={(e) => {
                appState.layoutState.backgroundScheduled = slot.orderId;
            }} onMouseLeave={(e) => {
                appState.layoutState.backgroundScheduled = 0;
            }}>
                <div className="drag-hours__item__content">
                    <div className="num-hours">
                        <div>
                            {this.props.workOrdersCodes && this.props.workOrdersCodes.map(workOrder => (
                                workOrder
                            ))}
                        </div>
                    </div>
                    <div className="bar-hours" />
                </div>
            </div>)
        } else {
            let connectionDragSource = connectDragSource(
                <div className="drag-hours__item h-4" style={{ width: this.props.width }}
                     title={title} onClick={(e) => {
                    this.onClick(e, slot);
                }} onMouseEnter={(e) => {
                    appState.layoutState.backgroundScheduled = slot.orderId;
                }} onMouseLeave={(e) => {
                    if (!appState.layoutState.slotview) {
                        appState.layoutState.backgroundScheduled = 0;
                    }
                }}>
                    <div className="drag-hours__item__content">
                        <div className="num-hours">
                            <div>
                                {this.props.workOrdersCodes && this.props.workOrdersCodes.map(workOrder => (
                                    workOrder
                                ))}
                            </div>
                        </div>
                        <div className="bar-hours" />
                    </div>
                </div>
            );
            let connectionDragPreview = connectDragPreview(connectionDragSource, { offsetX: 5, offsetY: 20 });
            return connectionDragPreview;
        }
    }

    onClick(event, slotPlain) {
        let difX = event.screenX - event.target.screenX;
        let difY = event.screenY - event.target.screenY;
        let boundingRect = event.target.getBoundingClientRect();
        let pantallaEmpizaEn = window.pageYOffset;
        let elementoEmpiezaEn = pantallaEmpizaEn + boundingRect.top;
        difY = event.pageY - elementoEmpiezaEn;
        let pantallaXEmpizaEn = window.pageXOffset;
        let elementoXEmpiezaEn = pantallaXEmpizaEn + boundingRect.left;
        difX = event.pageX - elementoXEmpiezaEn;

        let pageX = event.pageX - difX;
        let pageY = event.pageY - difY;
        //this.log("event.target=>");
        //this.log(event.target);
        appState.scheduleDropState.slotClickedPosition = { left: pageX, top: pageY };
        appState.scheduleDropState.slotClicked = slotPlain;
        appState.layoutState.clickslotView();
        appState.layoutState.backgroundScheduled = slotPlain.orderId;
        appState.layoutState.backgroundRowScheduled = slotPlain.id;
        //this.log("Clicked!!!")
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(WorkUnitDrag)
