// import Model from "./Model";
import DocumentModel from "./DocumentModel";
import AbstractModel from "./AbstractModel";
import TypifiedValueModel from "./TypifiedValueModel";

export default class ClientModel extends AbstractModel {
    address;
    city;
    code;
    companyName;
    createdAt;
    createdBy;
    district;
    id;
    isPos;
    name;
    posAddress;
    posCity;
    posDistrict;
    posPostalCode;
    postalCode;
    posZoneId;
    updatedAt;
    updatedBy;
    vatID;
    isVAT;
    chainId;
    geoLongitude;
    geoLatitude;
    parentId;

    nameMainType="client";
    graphFindByIdOperation="client";
    graphFindOperation="clientsConnection";
    graphExportOperation="clientsExport";

    getArrayFields() {
        let result = {
            "id":{type:"CodeField"},
            "address":{label:"Direccion"},
            "city":{label:"Ciudad"},
            "code":{label:"Código"},
            "companyName":{label:"Razon Social"},
            "createdAt":{readonly:true,label:"Creado"},
            "createdBy":{readonly:true,label:"Creado por"},
            "district":{label:"Provincia"},
            "isPos":{type:"BoolField", label:"Datos"},
            "name":{label:"Nombre"},
            "posAddress":{label:"Dirección Est."},
            "posCity":{label:"Localidad"},
            "posDistrict":{label:"Provincia Est."},
            "posPostalCode":{label:"Código Postal Est."},
            "postalCode":{label:"Provincia Est."},
            "posZoneId":{label:"Zona"},
            "updatedAt":{readonly:true,label:"Actualizado en"},
            "updatedBy":{readonly:true,label:"Actualizado Por"},
            "vatID":{type:"CodeField",label:"NIF"},
            "isVAT":{type:"BoolField",label: "Datos Fiscales"},
            "parentId":{label:"Pertenece a"},
            "chainId":{label:"Cadena "},
            "geoLongitude":{},
            "geoLatitude":{}

        };
        this.addRelatedTableDocuments(result, DocumentModel);
        this.addRelatedTableChain(result, TypifiedValueModel);
        this.addRelatedTablePosZone(result, TypifiedValueModel);

        return result;
    }

};





















