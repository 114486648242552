import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { dir } from "async";
import withTranslate from "../translator/withTranslate";
import VsfButton from "./VsfButton";
import PropsUtil from "../util/PropsUtil";
import { withRouter } from "react-router-dom";

@observer
class CardDashboardComponent extends Component {

    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        return (

            <div className={this.props.classGroup}>
                <div className="card card-home">
                    <div className="card-body text-center">
                        <div className="icon">
                            <i className={this.props.icon}/>
                        </div>
                        <h4>{this.props.title}</h4>
                        <p>{this.props.info}</p>
                        <VsfButton classButton={this.props.classButton || "btn-primary"} onClick={()=>this.propsUtil.changeUrl(this.props.link)} label={'Acceder'} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslate(CardDashboardComponent));



















