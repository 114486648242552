import React from 'react';
import { observer } from "mobx-react/index";
import PageTitle from "../components/PageTitle";
import user from "../models/UserModel";
import appState from "../state/AppState";
import InputTypeComponent from "../components/fields/InputTypeComponent";
import PropsUtil from "../util/PropsUtil";
import RightModalInList from "../subpages/RightModalInList";
import BaseForm from "./base/BaseForm";
import withTranslate from "../translator/withTranslate";


@observer
class ChangePassword extends BaseForm {

    constructor(props) {
        super(props);
        this.nameMainType = "user";
        this.initializeGraphData(this.nameMainType);
        //this.queryFieldWithId = "userId";
    }

    clickInfo() {
        console.log('click en info************************************************************************')
        //this.propsUtil.changeUrl({rightModal: "info"})
        this.propsUtil.changeUrl('rightModal=recovery')
    }

    closeInfo() {
        this.propsUtil.changeUrl('')
    }

    render() {
        let mail = appState.loginState.dataUser.Email;
        const { t, i18n } = this.props;

        this.propsUtil = new PropsUtil(this.props);
        return (
            <React.Fragment>
                <PageTitle
                    title={mail}
                    subtitle={'Recupera Contraseña'}
                />
                <div className="card user mb-3 p-2">
                    <div className="card-body">
                        <div className="change-password">
                            <form action="#" method="#" className="user__form">
                                <div className="form-row">
                                    {/*<div className="col-12 form-group">*/}
                                    {/*<label htmlFor="passwordActual" className="control-label dark">Mail</label>*/}
                                    {/*<input type="text" id="mail" minlenght="8" className="form-control" />*/}
                                    {/*</div>*/}
                                    <InputTypeComponent
                                        //  value={password}
                                        onChange={(event) => this.updateInputEvent(event)}
                                        name={"passwordNow"}
                                        title={t("Contraseña Actual")}
                                        classGroup={"col-md-6 col-xl-12"}
                                        forceMedia={this.props.fromRightModal ? "md" : ""}
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="col-12 form-group">
                                        <p className="passwordNew-explication" aria-labelledby="passwordNew">
                                            {t("Recuerda, tu contraseña debe tener al menos 8 caracteres. La contraseña debe contener letras mayúsculas y minúsculas, cifras y caracteres especiales. Por ejemplo: Nuddo$19")}
                                        </p>
                                    </div>
                                    <InputTypeComponent
                                        //  value={phoneContactRequest}
                                        name={"clientPhoneRequest"}
                                        title={t("Nueva Contraseña")}
                                        classGroup={"col-md-6 col-xl-12"}
                                        forceMedia={this.props.fromRightModal ? "md" : ""}
                                        iconB={true}
                                        labelClick={this.clickInfo}
                                    >
                                        <button type="button" onClick={() => this.clickInfo()}
                                                className="btn--openModal" data-toggle="modal" data-target="#modalInfo"
                                                title={t("Mas informacion en nueva ventana")}><span
                                            className="fas fa-info-circle"></span></button>
                                    </InputTypeComponent>

                                </div>
                                <div className="form-row">
                                    <InputTypeComponent
                                        //  value={phoneContactRequest}
                                        onChange={(event) => this.updateInputEvent(event)}
                                        name={"clientPhoneRequest"}
                                        title={t("Confirmación Nueva Contraseñal")}
                                        classGroup={"col-md-6 col-xl-12"}
                                        forceMedia={this.props.fromRightModal ? "md" : ""}
                                    />
                                </div>
                                <div className="form-row button-wrapper">
                                    <div className="col-md-6 mt-2">
                                        <button type="reset" className="btn" onClick={() => this.closeInfo()}>
                                            <span>{t("cancelar")}</span></button>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <button type="submit" className="btn btn--red"><span>{t("guardar")}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <RightModalInList isOpen={this.propsUtil.getRequest("rightModal") === "info"}
                                  onCloseModal={() => this.propsUtil.changeUrlRequest({ rightModal: null })}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-header__title ico ico--info">{t("Más información sobre")} <span
                                    className="font-weight-bold">{t("Crear una Contraseña Segura")}</span></h3>
                            </div>
                            <div className="modal-body">
                                <dl className="modal-info__list">
                                    <dt className="modal-info__list__title">{t("Para generar una contraseña segura debes tener en cuenta")}:
                                    </dt>
                                    <dd className="modal-info__list__item">{t("La contraseña debe tener al menos 8 caracteres.")}
                                    </dd>
                                    <dd className="modal-info__list__item">{t("La contraseña debe contener letras mayúsculas y minúsculas, cifras y caracteres especiales.")}
                                    </dd>
                                    <dt className="modal-info__list__title">{t("Una contraseña segura no debe contener")}:
                                    </dt>
                                    <dd className="modal-info__list__item">{t("Información personal que es fácil de averiguar. Por ejemplo, nombre, apellidos o fecha de nacimiento.")}
                                    </dd>
                                    <dd className="modal-info__list__item">{t("Palabras simples, frases hechas, conjuntos de símbolos fáciles de adivinar. Por ejemplo: password, contraseña, abcd, qwerty, asdfg, 1234567.")}
                                    </dd>
                                </dl>
                            </div>
                            <div className="modal-footer">
                                <div className="button-wrapper">
                                    <button type="button"
                                            onClick={() => this.closeInfo()}
                                            className="btn btn--closeModal"
                                            data-dismiss="modal">
                                        <span>{t("Cerrar")}</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </RightModalInList>
            </React.Fragment>
        );
    }

}

export default withTranslate(ChangePassword);

