import React from 'react';
import { observer } from 'mobx-react';
import FormInputWrapper from "./FormInputWrapper";
import BaseFieldComponent from "./BaseFieldComponent";
import PropTypes from 'prop-types';
import AppLogger from "../../util/AppLogger";
import { SketchPicker } from 'react-color';

@observer
export default class InputTypeComponent extends BaseFieldComponent {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        readOnly: PropTypes.bool, // Muestra el campo editable, pero con fondo gris en modo read Only
        showView: PropTypes.bool, // Muestra el campo en modo solo texto
    };

    static defaultProps = {
        readOnly: false,
        showView: false
    };

    constructor(props) {
        super(props);
    }

    handleChangeComplete(name, value) {
        let eventObj = {
            target: {
                name: name,
                value: value.hex
            }
        };
        this.props.onChange(eventObj);
    }

    render() {
        let props = this.props;
        let value = props.value;
        if (value == null) {
            value = "";
        }


        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                {...props}
            >
                {this.props.showView ?
                    <div>
                        {props.value}
                    </div>
                    :
                    <SketchPicker
                        color={this.props.value || ''}
                        onChangeComplete={(e) => this.handleChangeComplete(this.props.name, e)}
                    />
                }
                {props.children}
            </FormInputWrapper>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


