import React from 'react';
import {observer} from 'mobx-react';
import {DefaultRoute, withRouter} from 'react-router-dom'
import config from "../config/config";
import FetchProxy from "../network/FetchProxy";
import UserModel from "../models/UserModel";
import PropTypes from 'prop-types';
import AlertModal from "../subpages/AlertModal";
import PropsUtil from "../util/PropsUtil";
import PopUp from "./PopUp";
import {observable, toJS} from "mobx";
import VsfButton from "./VsfButton";
import AppLogger from "../util/AppLogger";
import withTranslate from "../translator/withTranslate";

@observer
class ModalEnviarContrasenya extends React.Component {


    static defaultProps = {
        user: null
    }

    static propTypes = {
        user: PropTypes.instanceOf(UserModel),
    }
    @observable loading = false;

    constructor(props) {
        super(props);
    }

    onCancel() {
        return this.propsUtil.changeUrlRequest({rightModal: null});
    }

    async onSendRecovery(email) {
        let httpApi = new FetchProxy();
        try {
            this.loading = true;
            let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/recovery",
                {
                    "Email": email,
                }
            );

            this.log('resultado del response');
            this.log(response);
            let responseJson = await response.json();
            this.log(responseJson.message)
            this.log(responseJson.errorCode);
            if (responseJson.errorCode == 0) {
                let respuesta = responseJson.data.access_token;
                this.log('respuesta');
                this.log(respuesta)

                // window.localStorage.setItem('accessToken',this.accessToken);
                // window.localStorage.setItem('refreshToken',this.refreshToken);
                //window.location.reload();
            } else {
                this.log(responseJson);
            }
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        setTimeout(() => {
            this.loading = false;
        }, 1000);
        setTimeout(() => {
            return this.propsUtil.changeUrlRequest({rightModal: null});
        }, 2500);
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const {t, i18n} = this.props;
        return (
            <AlertModal isOpen={this.propsUtil.getRequest("rightModal") === "recover"}
                        onCloseModal={() => this.propsUtil.changeUrlRequest({
                            rightModal: null,
                            rightModalTab: null,
                            idOrderClient: null,
                        })}
            >
                {/*<RecoverPassword id={user}/>*/}
                <PopUp
                    title={t('Recuperar contraseña')}
                    icon="fas fa-exclamation-triangle"
                    text={t(`Se mandará un correo al usuario ${this.props.user.email} con las instrucciones para poder cambiar la contraseña`)}
                    label1={'Cancelar'}
                    onClick1={() => this.onCancel()}
                    moreButtons={
                        [
                            <VsfButton
                                label={t('Enviar mensaje')}
                                onClick={(email) => this.onSendRecovery(this.props.user.email)}
                                loading={this.loading}
                                classButton="btn--loader"
                                messageOnLoading={"Enviando"}
                                messageOnSuccess={"Enviado"}
                                messageOnFailure={"Error al enviar"}
                                classGroup={'button-form-group'}
                            />
                        ]
                    }
                >
                </PopUp>
            </AlertModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withTranslate(ModalEnviarContrasenya));


