import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter, DefaultRoute, Route, Switch} from 'react-router-dom'
import {createBrowserHistory} from "history";
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset';
//import {CSSTransition, TransitionGroup} from "react-transition-group";
import posed, {PoseGroup} from 'react-pose';
import Page404 from "./pages/Page404";


const RouteContainer = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        //delay: 300,
        delay: 0,
        /*
        transition: {
            y: { type: 'spring', stiffness: 1000, damping: 15 },
            default: { duration: 300 }
        }
        */
    },
    visible: {
        opacity: 1,
        transition: { duration: 0 }
    },
    exit: {
        y: 0,
        opacity: 0,
    }
});

/*
const PoseGroup2 = (props)=> {
    return  {...props.children}
};
*/
/** Permite que al hacer click la p�gina suba al TOP **/
const history = createBrowserHistory();

history.listen(_ => {
    window.scrollTo(0, 0)
})
/** Fin pagina subir a TOP */

@observer
class LoginRouter extends Component {
    render() {
        let props= this.props;
        console.log({props});
        return (
            <BrowserRouter hashType2="hashbang" history={history}>

                    <Route render={({ location }) => (
                        <PoseGroup>
                            <RouteContainer key={location.pathname}>

                                <Switch location={location}>
                                    <Route exact={true} path="/" render={routeprops => (

                                        <Login key="home" {...routeprops} {...props} />
                                    )}/>
                                    <Route path="/password/reset" render={routeprops => (
                                        <PasswordReset key="password-reset" {...routeprops} {...props} />
                                    )}/>
                                    <Route render={routeprops => (
                                        <Page404 key="page404" {...routeprops} {...props} />
                                    )}/>
                                </Switch>
                            </RouteContainer>
                        </PoseGroup>
                    )} />
            </BrowserRouter>
        );
    }
}

export default LoginRouter;
