import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {DefaultRoute, Link, withRouter} from 'react-router-dom'
import VsfButton from "./VsfButton";
import PropTypes from 'prop-types';
import appState from "../state/AppState";
import Overlay from "./Overlay";

import '../scss/components/info-block.scss';
import AppLogger from "../util/AppLogger";

@observer
class InfoBlock extends Component {

    static propTypes = {
        componentName:PropTypes.string,
        componentObject:PropTypes.object,
        wrapper:PropTypes.string //En vez de div utilizar� este elemento para la maquetaci�n
    }

    static defaultProps = {
        componentName:"",
        componentObject:null,
        wrapper:null
    }

    getTypeName(obj){
        //this.log(obj);
        let result = obj.constructor.name;
        //let result = Object.prototype.toString.call(obj);
        //return Object.prototype.toString.call(obj).slice(8, -1);
        return result;
    }

    withInfo() {
        let result=true;
        result=false;
        result = appState.layoutState.infoBlockEnabled;
        return result;
    }

    render() {
        let name=this.props.componentName;
        if (this.props.componentObject!=null) {
            name=this.getTypeName( this.props.componentObject );
        }
        let inner = (
            <>
                <div className="block-info-label">{name}</div>
                {this.props.children}
            </>
        );

        if (this.withInfo()) {
            if (this.props.wrapper=="tr") {
                return <tr className="block-info">{inner}</tr>
            } else if (this.props.wrapper=="tbody") {
                return <tbody className="block-info">{inner}</tbody>
            } else {
                return <div className="block-info">{inner}</div>
            }
        } else {
            return (
                <>
                {this.props.children}
                </>
            );
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default InfoBlock;

