import React, { Component } from 'react';
import Sidebar from "./layout/sidebar";

import AppWraper from "./layout/AppWrapper";

class SummaryPage extends Component {
    render() {
        return (
                <AppWraper>
                    <header className="App-header">
                        Summary
                    </header>
                    Contenido                    
                </AppWraper>
        );
    }
}

export default SummaryPage;
