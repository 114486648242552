/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 * @flow
 */

import ApolloClientBoost from "apollo-boost";
import {ApolloClient} from 'apollo-client';
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'

import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

import { persistCache } from 'apollo-cache-persist';
import { AsyncStorage } from 'react';
import {withApollo} from "react-apollo";
import config from '../config/config';
import appState from '../state/AppState';

let clientApolo =null;

const cache = new InMemoryCache();

persistCache({
    cache,
    storage: window.localStorage,
    debug:true,
});

const errorLink = onError(({ graphQLErrors, networkError , operation, forward }) => {

    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, extensions }) =>
            console.log(`[GraphQL Error]: Message: ${message}, Location:${locations} , Path: ${path} , extensions: ${JSON.stringify(extensions)}`))
    }
    if (networkError) {
        console.log(`[GraphQL Network Error]: Message: ${networkError}`);
    }
/*
    console.log("On Error");
    if (operation.operationName === "IgnoreErrorsQuery") {
        response.errors = null;
    }
*/
})
/*
onError(({ response, operation }) => {
    console.log("On Error");
    if (operation.operationName === "IgnoreErrorsQuery") {
        response.errors = null;
    }
})
*/

let currentPromiseForRefresh=null;
let evaluatingPromise=false;

// action which happens on relevant error
let recoveryLink = new RetryLink({
    delay: {
        initial: 0,
    },
    attempts: {
        max: 2,
        retryIf: (error , operation) => {
            //let codigoEjecucion = Math.floor((Math.random() * 100) + 1);

            if (error.statusCode === 401) {
                //console.log("recoveryLink-1 "+codigoEjecucion+" currentPromiseForRefresh:"+currentPromiseForRefresh + " evaluatingPromise:"+evaluatingPromise);
                if (currentPromiseForRefresh==null && !evaluatingPromise) {
                    //console.log("recoveryLink-2 "+codigoEjecucion+" currentPromiseForRefresh:"+currentPromiseForRefresh + " evaluatingPromise:"+evaluatingPromise);
                    evaluatingPromise=true;
                    let promise = new Promise((resolve, reject) => {

                        // your refresh query here
                        appState.loginState.doRefreshToken().then(response => {
                            // do something with response
                            // retry original query
                            currentPromiseForRefresh=null;
                            evaluatingPromise=false;
                            resolve(true);
                        }).catch(() => {
                            currentPromiseForRefresh=null;
                            evaluatingPromise=false;
                            resolve(false);
                        })
                    });
                    currentPromiseForRefresh=promise;
                } else {
                    //§console.log("recoveryLink-3 "+codigoEjecucion+"currentPromiseForRefresh:"+currentPromiseForRefresh + " evaluatingPromise:"+evaluatingPromise);
                }
                return currentPromiseForRefresh;
            }
            return false;
        }
    }
});

//recoveryLink=new RetryLink();


const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = appState.loginState.getAccessToken;
    //token="";
    console.log("authLink.token:"+token);
    //let token;
    //token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6Impvc2UiLCJhY2NvdW50aWQiOiJkMjkzMzQ5MC1kYThlLTExZTItOGFlMy02ZDcxYzE4ZWU2MGIiLCJpYXQiOjE1MTk4NDc1ODF9.SnSpLP4yJYarzK6VhOC5t1cdiYnu2xqlqr_r89RZojw";
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            "Authorization": token ? `Bearer ${token}` : "",
        }
    }
});

const httpLink=new HttpLink({uri: config.apiHostBaseUrl});

//let link = authLink.concat(httpLink);
let link = ApolloLink.from([
    recoveryLink,
    errorLink,
    authLink,
    httpLink,
    ]
);
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'none',
    }
}
const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
    defaultOptions,
});


/*
// Pass your GraphQL endpoint to uri
const client = new ApolloClientBoost({
    uri: 'https://localhost:5001/graphql',
    cache,
    defaultOptions: {
        mutate: {
            errorPolicy:'all'
        }
    }
});
*/
export default client;
