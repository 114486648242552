import React from 'react';
import {observer} from "mobx-react/index";
import appState from '../../state/AppState';
import {withApollo} from "react-apollo";
import TabsData from "../../layout/TabsData";
// import {computed} from "mobx";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
// import VsfButton from "../../components/VsfButton";
import AppLogger from "../../util/AppLogger";
import withTranslate from "../../translator/withTranslate";
import TabsComponent from "../../components/fields/TabsComponent";
import PageTitle from "../../components/PageTitle";
import CheckboxUniqueComponent from "../../components/fields/CheckboxUniqueComponent";
import UserModel from "../../models/UserModel";
import BaseForm from "../base/BaseForm";
import FormButtons from "../../components/FormButtons";

// import util from "../../util/Util";


@observer
class EmployeeCapacitationTable extends BaseForm {

    nameMainType = "user";

    constructor(props) {
        super(props);
        this.log("EmployeeCapacitationTable.constructor()");
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        this.state = {
            capacitations: {},
            change: ""
        }
    }

    getModelQuery() {
        return new UserModel();

    }

    async componentDidMountImpl() {
        this.log("componentDidMountImpl");
        await super.componentDidMountImpl();
        await this.loadStateWithCurrentCapacitations();
        await appState.typifiedState.loadCapacitation();
    }

    /**
     * Carga en el state las capacitaciones actuales del user actual
     */
    loadStateWithCurrentCapacitations() {
        // this.log("loadStateWithCurrentCapacitations");
        let user = this.graphDataMainType;
        if (user.capacitationIds != null) {
            // this.log("user.capacitationIds!= null ");
            // this.log(user.capacitationIds);
            let arrayCurrentCapacitations = user.capacitationIds.split(",");

            for (let count = 0; count < arrayCurrentCapacitations.length; count++) {
                this.log(arrayCurrentCapacitations[count]);
                this.state.capacitations[arrayCurrentCapacitations[count]] = "";
            }
        } else {
            this.log("user.capacitationIds!=null");
        }

        //Para forzar un nuevo render
        this.setState({
            change: "loaded"
        })
    }

    /**
     * Chequea las actuales capacitationes desde el state del user para que los check aparezcan marcados
     */
    validateChecked(idTypified) {
        // this.log("validateChecked");
        // this.log(this.state.capacitations);
        // this.log(idTypified);
        if (this.state.capacitations[idTypified] != null) {
            // this.log("idTypified => true");
            // this.log(idTypified);
            return true;
        } else {
            // this.log("idTypified => false");
            // this.log(idTypified);
            return false;
        }
    }

    onChangeCheck(typifiedId) {
        // this.log("onChangeCheck");
        // this.log(typifiedId);
        appState.layoutState.formWithoutChanges = false;
        let value = typifiedId;
        if (this.state.capacitations[value] == null) {
            this.state.capacitations[value] = "";
            this.setState({
                change: value
            })
        } else {
            delete this.state.capacitations[value];
            this.setState({
                change: ""
            })
        }
        let result = "";
        for (let typified of Object.keys(this.state.capacitations)) {
            result += typified + ",";
        }
        // this.log("result");
        // this.log(result);
        //
        // this.log(this.state.capacitations);
        this.updateInput("capacitationIds", result.substr(0, result.length - 1));
    }

    render() {
        this.log("render");
        const {t} = this.props;
        this.propsUtil = new PropsUtil(this.props);
        // let user = this.graphDataMainType;
        let id = this.graphDataMainType.id;

        return (
            <InfoBlock componentObject={this}>
                <PageTitle
                    title={t("Usuario")}
                    subtitle={t('Capacitación Empleados')}
                />

                <form
                    className="model-form"
                    name="formulario"
                    onSubmit={(e) => this.handleFormSubmit(e)}
                >

                    <div className={'card mb-3 pb-3 pb-lg-0'}>

                        <TabsComponent
                            id={this.graphDataMainType.id}
                            tabs={this.tabsData.getDataUser()}
                            active="Capacitación"
                            fromRightModal={this.props.fromRightModal}
                        />

                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="table-edit">
                                    <table className="table visible-text"
                                           summary="Listado de capacitación de técnicos">

                                        <thead>

                                        <tr>
                                            {appState.typifiedState.capacitation.length == 0 &&
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna capacitación')}</td>
                                            }
                                        </tr>

                                        <tr>
                                            <th scope="col">{this.props.t("Id")}</th>
                                            <th scope="col">{t('Texto')}</th>
                                            <th scope="col">{t('Icon')}</th>
                                            <th scope="col">{t('Check')}</th>
                                            <th>&nbsp;</th>
                                        </tr>

                                        </thead>

                                        <tbody className="border-end">

                                        {appState.typifiedState.capacitation.map((typified, index) => (
                                            <tr key={typified.id} className={typified.isEditing ? "editing" : ""}>
                                                <td scope="row">
                                                    <div style={{color: '#3D708D'}}>
                                                        {typified.id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {typified.value}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className={typified.icon}/>
                                                </td>

                                                <td>
                                                    <CheckboxUniqueComponent
                                                        checked={this.validateChecked(typified.id)}
                                                        onChange={() => this.onChangeCheck(typified.id)}
                                                        name={typified.id}
                                                        value={this.state.change}
                                                        className={"col-2 mt-4"}
                                                        valueUnchecked={0}
                                                        title={t("Checkbox")}
                                                        classGroup={"col-12"}
                                                    />
                                                </td>
                                            </tr>
                                        ))}


                                        </tbody>

                                    </table>
                                </div>
                                <div className="text-right">

                                    {/*<FormButtons*/}
                                    {/*    id={id}*/}
                                    {/*    formStatus={this.graphStatus}*/}
                                    {/*/>*/}

                                    {/*<VsfButton*/}
                                    {/*    // disabled={this.state.hasSomeRowEditing}*/}
                                    {/*    label={"Guardar capacitaciones"}*/}
                                    {/*    onClick={_ => this.saveCapacitation()}*/}
                                    {/*    isEditing={this.state.hasSomeRowEditing}*/}
                                    {/*    classButton="btn--loader"*/}
                                    {/*/>*/}
                                    <FormButtons id={id} formStatus={this.graphStatus}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(withRouter(withApollo(EmployeeCapacitationTable)));
