import BaseValidation from "./BaseValidation";

export default class AlphanumericValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect = true;
        if (this.event==="change") {
            // alert("alpha " + inputValue);
            let rexAlphanumeric = /^[a-zA-Z0-9_]{0,15}$/;
            if (!rexAlphanumeric.test(inputValue)) {
                isCorrect = false;
            }
        }
        // alert(isCorrect);///
        return isCorrect;

    }
}
