import Links from "./Links";
import AppException from "./AppException";
import moment from "moment/moment";

class PropsUtil  {

    constructor() {

    }

    /**
     * A partir de una fecha en formato "AAAAMMDD" devuelve todas las semanas que cubre ese mes
     * @param from
     * @returns {any[]}
     */
    getWeeksFromDate(from) {
        let dias = [];
        let mDateFrom = moment(from, 'YYYYMMDD');
        let mDateTo = moment(mDateFrom).add(1, 'month');
        let mDateCurrent = moment(mDateFrom);
        mDateCurrent.locale('es');


        let weeks = {};
        while (mDateCurrent.unix() < mDateTo.unix()) {
            let isoWeek = mDateCurrent.isoWeek();
            if (weeks[isoWeek] == null) {
                let monthName = mDateCurrent.format("MMMM");
                let colspan = 0;
                let firstDay = mDateCurrent.format("D");
                weeks[isoWeek] = {monthName, colspan, firstDay};
            }
            weeks[isoWeek].colspan++;
            mDateCurrent = mDateCurrent.add(1, "days");
        }
        let result = Object.values(weeks);
        //console.log({result});
        return result;
    }


    getDiasMesFromDate(from) {
        let dias = [];
        let mDateFrom = moment(from, 'YYYYMMDD');
        let mDateTo = moment(mDateFrom).add(1, 'month');
        let mDateCurrent = moment(mDateFrom);

        while (mDateCurrent.unix() < mDateTo.unix()) {
            //console.log(mDateCurrent.toISOString());
            let labelWeekDay = mDateCurrent.format("ddd").substr(0, 1).toUpperCase();
            let dayOfMonth = mDateCurrent.format("D");
            let dayAndMonth = mDateCurrent.format("MMDD");
            let weekDay = mDateCurrent.isoWeekday(); //1 es Lunes
            let isoDate = mDateCurrent.format("YYYY-MM-DD");
            dias.push({isoDate, dayOfMonth, labelWeekDay, weekDay,dayAndMonth});
            mDateCurrent = mDateCurrent.add(1, "days");
        }
        //console.log({dias});
        return dias;
    }

}

export default PropsUtil;
