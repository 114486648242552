import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import ScheduleVisitModel from "../../models/ScheduleVisitModel";
import MoreButtons from "../../components/MoreButtons";
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";

@observer
class ScheduleVisitList extends BaseList {

    constructor(props) {
        super(props);
        this.quickEdit = true;
        this.quickEdit = true;
        this.quickView = true;
        this.includeSavedFavourites = false;
        this.includeChangeColumns = true;
        this.includeFilters = false;
        this.includeDownload = false;
        this.includeSearch = false;
    }

    getModelQuery() {
        return new ScheduleVisitModel();
    }

    getListState() {
        return appState.scheduleVisitState;
    }

    getListFields() {
        return [
            {
                title: this.props.t("Id"),
                name: "id",
                link: true,
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Nombre"),
                name: "name",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Tiempo de duracion"),
                name: "estimatedtotalTime",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Num. Tecnicos"),
                name: "technicalCount",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },

        ];
    }

    getDefaultOrder() {
        return "name";
    }


    render() {
        console.log("scheduleVisit.render");
        const params = new URLSearchParams(this.props.location.search);
        this.propsUtil = new PropsUtil(this.props);
        const { t, i18n } = this.props;
        let errorsMapped = this.getGraphErrorsFromStatus();
        console.log({ errorsMapped });
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <div>
                <ListItemSelect stateListColumnsSelect={this.stateListColumnsSelect} name={this.graphOperation} />

                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div className="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={this.props.t("visitas preventivas")}
                >
                    <MoreButtons
                        actions={
                            [
                                {
                                    title: "Crear Nuevo", onClick: () => {
                                        this.propsUtil.changeUrl("/schedule/visit/form/")
                                    }
                                },
                                {
                                    title: "Columnas", onClick: () => {
                                        this.getListState().listColumnsSelect.modalOpen = true
                                    }
                                }

                            ]
                        }
                    />
                </PageTitle>
                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent
                            listState={this.getListState()}
                            listData={this.getListData()}
                            numEntriesPerPage={this.numEntriesPerPage}
                            fields={fields}
                            includeSavedFavourites={this.includeSavedFavourites}
                            includeChangeColumns={this.includeChangeColumns}
                            includeFilters={this.includeFilters}
                            includeDownload={this.includeDownload}
                            stateListColumnsSelect={this.stateListColumnsSelect}
                            includeSearch={this.includeSearch}
                            model={this.getModelQuery()}
                            {...props}
                            listComponent={this}
                            favouriteModalOpen={this.state.favouriteModalOpen}
                            toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                        />
                        <ListComponent listState={this.getListState()} listData={this.getListData()}
                                       status={this.getListStatus()} fields={fields}
                                       urlForm={"/schedule/visit/form"} {...props}>
                            <EmailList name="name" />
                        </ListComponent>
                    </div>
                </div>
                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />
            </div>
        );
    }
}

export default withTranslate(withApollo(ScheduleVisitList));
