import React from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import Select2Component from "../../components/fields/Select2Component"
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import FiltersPage from "../FiltersPage";

@observer
class AssetListFilters extends FiltersPage {

    nameVariableStateFilter = "assetListFilters";


    initialize() {
        super.initialize();
        //Indica que aunque sea un Date se debe hacer la llamada a GQL como DateTime completo
        //this.log("constructor() this.nameVariableStateFilter:"+this.nameVariableStateFilter);
        //this.log(toJS(appState[this.nameVariableStateFilter]));
        appState[this.nameVariableStateFilter].filterType["createdAt"] = "DateTime";
    }

    render() {
        this.log("WorkOrderListFilters.render()");
        this.propsUtil = new PropsUtil(this.props);

        let { t } = this.props;

        return (
            <React.Fragment>
                <React.Fragment>
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.status}
                        onChange={(e, op) => this.updateInputFilterEvent(e, "STRIN")}
                        name={"status"}
                        title={t("Estado")}
                        classGroup={"col-3"}
                        options={appState.typifiedState.statusAssetsForSelect}
                        //errors={errorsMapped.status}
                    />
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.type}
                        onChange={(e, op) => this.updateInputFilterEvent(e, "STRIN")}
                        name={"type"}
                        title={t("Tipo")}
                        classGroup={"col-6"}
                        options={appState.typifiedState.assetTypesForSelect}
                        //errors={errorsMapped.type}
                    />
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.subType}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"subType"}
                        title={t("Subtipo")}
                        classGroup={"col-3"}
                        options={appState.typifiedState.assetSubtypeForSelect}
                        //errors={errorsMapped.type}
                    />
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.manufacturer}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"manufacturer"}
                        title={t("Fabricante")}
                        classGroup={"col-2"}
                        options={appState.typifiedState.manufacturerForSelect}
                        //errors={errorsMapped.manufacturer}
                    />
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.propietary}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"propietary"}
                        title={t("Propietario")}
                        classGroup={"col-md-3"}
                        options={appState.clientCacheState.clientCacheForSelect}
                    />
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.maintenance}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"maintenance"}
                        title={t("Mantenimiento")}
                        classGroup={"col-md-3"}
                        options={appState.clientCacheState.clientCacheForSelect}
                    />
                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.exploitation}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"exploitation"}
                        title={t("Explotación")}
                        classGroup={"col-md-3"}
                        options={appState.clientCacheState.clientCacheForSelect}
                    />
                </React.Fragment>
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}


export default withRouter(withApollo(withTranslate(AssetListFilters, "AssetListFilters")));
