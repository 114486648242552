import React, { Component } from 'react';
import appState from "../../state/AppState";
import { observer } from 'mobx-react';
import { findDOMNode } from "react-dom";
import WorkInMonthUnitDrag from "./WorkInMonthUnitDrag";
import ScheduleVisitModel from "../../models/ScheduleVisitModel";
import PropTypes from 'prop-types';
import OrderModel from "../../models/OrderModel";

const Types = {
    ITEM: 'workInMonthUnit'
}


const itemTarget = {
    hover(props, monitor, component) {
        console.log("itemTarget.hover2");
        console.log({ props });
        console.log({ component });
        if (!component) {
            return null
        }
        let item = monitor.getItem();
        console.log({ item });

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        console.log({ dragIndex, hoverIndex });
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // dragIndex puede ser 5 y hover 1. Debo poner la posición 5 antes de la 1
        let preventOrders = appState.preventOrders;

        // Creo un elemento "provisional". Si al final se cancela el movimiento debería quitar el elemento "ghost"
        let currentElementMoving = null;
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
                currentElementMoving = preventOrders[i];
                currentElementMoving.date = "2019prov";
                currentElementMoving.type = "ghost-moving";
            }
        }

        let newPreventOrders = [];
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
            } else {
                if (preventOrders[i].name == hoverIndex) {
                    newPreventOrders.push(currentElementMoving);
                }
                newPreventOrders.push(preventOrders[i]);
            }
        }
        appState.preventOrders = newPreventOrders;
        console.log("moved");

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()
        console.log({ hoverBoundingRect });
    },
    drop(props, monitor, component) {
        console.log("drop");
        // Obtain the dragged item
        const orderFromProps = monitor.getItem();
        console.log("drop=>");
        console.log(orderFromProps);
        console.log({ props });
        console.log({ component });
    }
}

function collectTarget(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class WorkInMonthUnit extends Component {
    static propTypes = {
        //Objeto que contiene el mes (code) y el nombre de la visita: {label:"Ene", year:19, code:'201901'},
        name: PropTypes.string,
        //Elemento que identifica el tipo de visita.
        visitType: PropTypes.instanceOf(ScheduleVisitModel),
        // Elemento que identifica a un elemento ya planificado en un mes. Tenemos el OrderModel asociado
    }

    render() {
        const { connectDropTarget } = this.props;
        let orderId = "";
        if (this.props.order) {
            orderId = this.props.order.id;
        }
        let claseSlot = "drag-hours2 ";
        if (this.props.order && (appState.layoutState.backgroundScheduled == this.props.order.orderPreventiveId ||
            appState.layoutState.backgroundScheduled == this.props.order.id)) {
            claseSlot += " seleccionada "
        }

        let result = (
            <div className={claseSlot} style={{ width: 60 }} data-order-id={orderId}>
                <WorkInMonthUnitDrag {...this.props} />
            </div>
        )
        if (connectDropTarget) {
            return connectDropTarget(result);
        } else {
            return result;
        }
    }
}

//export default DropTarget(Types.ITEM, itemTarget, collectTarget)(WorkInMonthUnit)
export default WorkInMonthUnit
