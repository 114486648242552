import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

@observer
export default class TextListItemResponsive extends Component {
	 render() {
		  let props = this.props;
		  return (
			  <div className="ancho-responsive text-truncate" title={props.value}>{props.value}</div>
		  );
	 }
}
