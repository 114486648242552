import React from 'react';
import {observer} from 'mobx-react';
import appState from "../../state/AppState";
import BaseCommon from "./BaseCommon";
import ApolloProxy from "../../network/ApolloProxy";
import GraphException from "../../network/GraphException";
import Links from "../../util/Links";
import AppException from "../../util/AppException";
import {computed, toJS} from 'mobx';
import util from "../../util/Util";
import ContactModel from "../../models/ContactModel";
import ClientModel from "../../models/ClientModel";
import AssetModel from "../../models/AssetModel";
import OrderModel from "../../models/OrderModel";
import AppLogger from "../../util/AppLogger";

@observer
class BaseList extends BaseCommon {

    includeSavedFavourites;
    includeChangeColumns;
    includeFilters;
    includeDownload;
    includeSearch;

    nameVariableStateFilter = "listFilters-personalizar-en-subclase";

    constructor(props) {
        super(props);
        console.log("BaseList.constructor(props)");
        //graphOperation se utiliza para guardar los filtros de este usuario
        this.graphOperation = this.getModelQuery().graphFindOperation;
        this.defaultOrder = "baseList";
        this.numEntriesPerPage = 10;
        let links = new Links(this.props.location);

        let currentFilterFromUrl = links.getCurrentFilterFromUrl();
        let currentFilter = {};
        let currentFilterOps = {};
        let currentFilterLabels = {};
        for (let [i, obj] of Object.entries(currentFilterFromUrl)) {
            currentFilter[obj.key] = obj.value;
            currentFilterOps[obj.key] = obj.op;
            currentFilterLabels[obj.key] = obj.label;
        }
        appState.layoutState.formWithoutChanges = true;
        appState.layoutState.loadingForm = false;
        this.state = {
            ...this.state,
            currentFilter: currentFilter,
            currentFilterOps: currentFilterOps,
            currentFilterLabels: currentFilterLabels,
            favouriteModalOpen: false
        };

        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        /*Para el desplegable de campos listado seleccionable*/
        this.stateListColumnsSelect.modalOpen = false;
        this.stateListColumnsSelect.listFields = this.getListFields();
        //Array simple de campos que se van a ordenar. Pueden ser los "names" de getListFields();
        this.stateListColumnsSelect.sortableFields = this.getListFields().map(listItem => listItem.name);
        this.stateListColumnsSelect.visibleFields = this.getVisibleFields();
    }

    @computed get stateListColumnsSelect() {
        return this.getListState().listColumnsSelect;
    }

    @computed get stateListFilter() {
        return this.getListState().listFilter;
    }

    @computed get stateListQuickEdit() {
        return this.getListState().quickEdit;
    }

    toggleFavouriteModal() {
        this.setState({
            favouriteModalOpen: !this.state.favouriteModalOpen
        })
    }


    getTitleFromRelations(nameField) {
        let model;
        let result = "";
        if (nameField.startsWith('request.')) {
            model = new ContactModel();
            result = model.getLabelFromFieldName(nameField.replace('request.', "")) + " Solicitante";
        } else if (nameField.startsWith('contact.')) {
            model = new ContactModel();
            result = model.getLabelFromFieldName(nameField.replace('contact.', "")) + " Contacto";
        } else if (nameField.startsWith('order.')) {
            model = new OrderModel();
            result = model.getLabelFromFieldName(nameField.replace('order.', ""));
        } else if (nameField.startsWith('client.')) {
            model = new ClientModel();
            result = model.getLabelFromFieldName(nameField.replace('client.', ""));
        } else if (nameField.startsWith('asset.')) {
            model = new AssetModel();
            result = model.getLabelFromFieldName(nameField.replace('asset.', ""));
        } else {
            model = this.getModelQuery();
            if (model != null) {
                result = model.getLabelFromFieldName(nameField);
            }
        }
        return result;
    }

    async onDownloadExcelOrCsv(event) {
        let newData = null;

        let modelQuery = this.getModelQuery();
        if (modelQuery != null) {
            const params = new URLSearchParams(this.props.location.search);
            // Establezco los valores de igualdad en modelQuery para que la busqueda se realice con esos filtros
            modelQuery.filters = this.getFiltersForGraph();
            let order = params.get('order');
            if (order == null) {
                order = this.getDefaultOrder();
            }
            let orderMode = params.get('orderMode');
            if (orderMode === null) {
                orderMode = "ASC";
            }
            let fieldsName = [];
            let fieldsLabel = [];
            this.stateListColumnsSelect.listFields.forEach(campo => {
                if (campo.visible) {
                    fieldsName.push(campo.name.replace(/\b\w/g, l => l.toUpperCase()))
                    fieldsLabel.push(this.getTitleFromRelations(campo.name))
                }

            });
            let q = params.get('q');
            modelQuery.orderBy = order;
            modelQuery.orderMode = orderMode;
            let responseWithUrl = await modelQuery.exportExcelOrCsv(event, q, fieldsName, fieldsLabel);

            if (responseWithUrl != null) {
                window.location.href = responseWithUrl;
            }
        } else {
            let variables = this.getGraphQLQueryVariables();
            let resultQuery = await apolloProxy.graphQuery({
                query: this.getGraphQLQuery(),
                variables,
            });
            let operationName = this.getGraphQLOperation();

            if (resultQuery != null && resultQuery.data != null) {
                newData = resultQuery.data[operationName];
            }
        }
        if (newData !== null) {
            this.getListData().connection = newData;
        }
    }

    getFieldsForGraphQLQuery() {
        //console.log('pasando por getFieldsForGraphQLQuery() ')
        return ["id"];
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */


    /**
     * Nombre de la operaci�n de Graphql
     * @returns {string}
     */
    getGraphQLOperation() {
        return "";
    }

    getListState() {
        throw "Method must be implemented";
    }

    getListStatus() {
        return this.getListState().listStatus;
    }

    getListData() {
        return this.getListState().listData;
    }

    getDefaultOrder() {
        return "id";
    }

    /**
     * A partir de la URL obtiene los parámetros de la llamada a GraphQL
     * @returns {*}
     */
    getFiltersForGraph() {
        const params = new URLSearchParams(this.props.location.search);
        let filtersText = params.get('filters');
        let filters = null;
        if (filtersText != null) {
            if (filtersText != "") {
                try {
                    let filtersWithKey = JSON.parse(filtersText);
                    filters = filtersWithKey.map((obj) => {
                        let filterItem = obj;
                        delete filterItem["fieldKey"];
                        delete filterItem["fieldLabel"];
                        return filterItem;
                    })
                    console.log({filters});
                } catch (e) {
                    console.log('error AppException')
                    throw new AppException("Filters sintax error");
                }

            }
        }
        return filters;
    }

    getGraphQLQueryVariables() {
        const params = new URLSearchParams(this.props.location.search);
        let order = params.get('order');
        if (order == null) {
            order = this.getDefaultOrder();
        }
        let orderMode = params.get('orderMode');
        if (orderMode === null) {
            orderMode = "ASC";
        }
        let after = params.get('after');
        let before = params.get('before');
        let filtersText = params.get('filters');
        let filters = this.getFiltersForGraph();
        let q = params.get('q');
        let first = this.numEntriesPerPage;
        return {
            query: {
                q,
                filters
            },
            sort: {
                field: order,
                orderMode: orderMode
            },
            first,
            after,
            before
        };
    }


    async componentDidMount() {
        await this.componentDidMountImpl();
    }

    async componentDidMountImpl() {
        return await this.reloadList();
    }


    /**
     * Si ha cabiado el orden del listado recargo el listado
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        let reload = false;
        const paramsOld = new URLSearchParams(prevProps.location.search);
        if (prevProps.location.search !== this.props.location.search) {
            reload = true;
        }
        const orderOld = paramsOld.get('order') + paramsOld.get('orderMode');

        const params = new URLSearchParams(this.props.location.search);
        const order = params.get('order') + params.get('orderMode');

        if (orderOld !== order) {
            reload = true;
        }
        if (reload) {
            this.reloadList();
        }
    }

    getGraphErrorsFromStatus() {
        return new GraphException().getGraphErrorsFromStatus(this.getListStatus().queryGraphQlResponse);
    }

    getModelQuery() {
        let modelQuery = null;
        return modelQuery;
    }

    async reloadList() {
        let apolloProxy = new ApolloProxy(this.props.client);
        //console.log(JSON.stringify(this.getGraphQLQueryVariables()));
        //console.log(this.props.location.search);
        //console.log(order);

        appState.loadingBarState.start();
        this.getListStatus().formLoading = true;
        this.getListStatus().formError = false;
        try {
            let newData = null;

            let modelQuery = this.getModelQuery();
            if (modelQuery != null) {
                const params = new URLSearchParams(this.props.location.search);
                let page = params.get('page');
                if (page == null) {
                    page = 1;
                }
                let pagination = params.get('pagination');
                if (pagination == null) {
                    pagination = this.numEntriesPerPage;
                }
                // Establezco los valores de igualdad en modelQuery para que la busqueda se realice con esos filtros

                modelQuery.filters = this.getFiltersForGraph();
                let q = params.get('q');
                let order = params.get('order');
                if (order == null) {
                    order = this.getDefaultOrder();
                }
                let orderMode = params.get('orderMode');
                if (orderMode === null) {
                    orderMode = "ASC";
                }
                modelQuery.orderBy = order;
                modelQuery.orderMode = orderMode;
                modelQuery.refineWhere["q"] = q;

                //console.log({modelQuery});
                let models = await modelQuery.findLimit(page, pagination);
                //console.log({models});
                let items = [];
                for (let model of models) {
                    let modelPlain = Object.assign({}, model);
                    items.push(modelPlain);
                }
                newData = {};
                newData.totalCount = modelQuery.totalCount;
                newData.pageInfo = modelQuery.pageInfo;
                newData.items = items;
                //console.log({newData});
            } else {
                let variables = this.getGraphQLQueryVariables();
                let resultQuery = await apolloProxy.graphQuery({
                    query: this.getGraphQLQuery(),
                    variables,
                });
                let operationName = this.getGraphQLOperation();

                if (resultQuery != null && resultQuery.data != null) {
                    newData = resultQuery.data[operationName];
                }
            }
            //console.log({newData});
            this.log("BaseList. newData=>");
            if (newData !== null) {
                this.getListData().connection = newData;
            } else {

            }
            console.log("/reloadList");
        } catch (e) {
            this.getListStatus().queryGraphQlResponse.errors = new GraphException().getErrorsFromException(e);
            this.getListStatus().formError = true;
        }
        this.getListStatus().formLoading = false;
        appState.loadingBarState.finalize();
    }


    /**
     * A partir del parámetro currentFilterState (que puede ser el estado) devuelve un array con los campos que se mostrarán en la URL de los filtros
     * @param currentFilterState
     * @returns {Array}
     */
    getFiltersFromStateForUrlObj(currentFilterState) {
        this.log("getFiltersFromStateForUrl=>");
        this.log(toJS(currentFilterState));
        let filtersArray = [];
        for ([key, value] of Object.entries(currentFilterState.currentFilter)) {
            //
            let fieldName = util.getDelim(key, "_", 0);
            let fieldExtParam = util.getDelim(key, "_", 1);
            let op = currentFilterState.currentFilterOps[key];
            let label = currentFilterState.currentFilterLabels[key];
            if (op == null) {
                op = "EQ";
            }
            if (label == null) {
                label = value;
            }
            let nameVariableStateFilter = appState["currentFilterName"];
            let filterType = appState[nameVariableStateFilter].filterType;
            if (filterType != null && filterType[fieldName]) {
                //Gestiono valores de fecha
                if (filterType[fieldName] == "DateTime") {
                    let dateAsString;
                    if (fieldExtParam == "To") {
                        dateAsString = value + "T23:59:59";
                    } else if (fieldExtParam == "From") {
                        dateAsString = value + "T00:00:00";
                    }
                    let momentDate = util.getMomentFromDateWithoutTimezone(dateAsString);
                    value = momentDate.toISOString();
                }
            }

            if (value != null && value != "") {
                filtersArray.push({
                    "fieldKey": key,
                    "fieldName": fieldName,
                    fieldValue: value,
                    filterOperator: op,
                    fieldLabel: label
                })

            }
        }
        console.log({filtersArray});
        return filtersArray;
    }

    /**
     * A partir del parámetro currentFilterState (que puede ser el estado) devuelve un array con los campos que se mostrarán en la URL de los filtros
     * @param currentFilterState
     * @returns {Array}
     */
    getFiltersFromStateForUrl(currentFilterState) {
        console.log("getFiltersFromStateForUrl");
        console.log(currentFilterState);
        console.log(this.state.currentFilterOps);
        console.log(this.state.currentFilterLabels);
        let filtersArray = [];
        for ([key, value] of Object.entries(currentFilterState)) {
            //
            let fieldName = util.getDelim(key, "_", 0);
            let op = this.state.currentFilterOps[key];
            let label = this.state.currentFilterLabels[key];
            if (op == null) {
                op = "EQ";
            }
            if (label == null) {
                label = value;
            }
            if (value != null && value != "") {
                filtersArray.push({
                    "fieldKey": key,
                    "fieldName": fieldName,
                    fieldValue: value,
                    filterOperator: op,
                    fieldLabel: label
                })

            }
        }
        console.log({filtersArray});
        return filtersArray;
    }

    /** Aplica los filtros en la pantalla de filtros **/
    onClickAplicarFiltros() {
        let objWithFilters = appState[this.nameVariableStateFilter];
        if (true) {
            let filtersArray = this.getFiltersFromStateForUrlObj(objWithFilters);
            let links = new Links(this.props.location);
            let newUrl = links.getUrlLinkWithFilters(filtersArray);
            appState.workOrderState.showFilters = false;
            this.props.history.push(newUrl)
        } else {
            //TODOJAL. Quitar cuando estén los filtros tirando del nuevo sistema
            let filtersArray = this.getFiltersFromStateForUrl(this.state.currentFilter);
            let links = new Links(this.props.location);
            let newUrl = links.getUrlLinkWithFilters(filtersArray);
            appState.workOrderState.showFilters = false;
            this.props.history.push(newUrl)
        }
    }

    /**
     * Guarda el elemento en edicion rapida
     */
    onClickSaveQuickEdit() {
        this.getListState().quickEdit.modalOpen = false;
        console.log("Saving!");
        console.log(this.getListState().quickEdit.row);
    }

    /**
     * Actualiza el valor de un filtro.
     * @param event
     */
    updateInputFilterEvent(event, op) {
        let name = event.target.name;
        let value = event.target.value;
        let label = event.target.label;
        if (op == null) {
            op = "EQ";
        }
        let currentFilter = {...this.state.currentFilter, [name]: value};
        let currentFilterOps = {...this.state.currentFilterOps, [name]: op};
        let currentFilterLabels = {...this.state.currentFilterLabels, [name]: label};
        this.setState({currentFilter, currentFilterOps, currentFilterLabels});
    }

    /**
     * Actualiza el valor de un objeto que esta en una edicion rapida.
     * @param event
     */
    updateInputQuickEditEvent(event) {

        let name = event.target.name;
        let value = event.target.value;

        let newValue = {...this.getListState().quickEdit.row, [name]: value};
        this.getListState().quickEdit.row = newValue;

    }

    /**  Funciones para modal con campos visibles  y orden**/
    /**  Funciones para modal con campos visibles  y orden**/

    /**  Funciones para modal con campos visibles  y orden**/

    /**
     * Obtiene el array de columnas visibles por defecto (campo visible:true)
     *
     * @returns {Array}
     */
    getVisibleFields() {
        let visibleFields = {};
        this.getListFields().forEach(function (listField, index) {
            visibleFields[listField.name] = listField.visible;
        });
        console.log("getVisibleFields() =>");
        console.log({visibleFields});
        return visibleFields;
    }

    /**
     * Obtiene el listado de elementos que aparecerán. Los campos que se pueden usar son los de este ejemplo
     *
     *
     * @returns {*}
     */
    getListFields() {
        return []
    }

    setListComponent(currentFilterValues) {

        this.log("setListComponent");
        this.log(currentFilterValues);

        let newCurrentFilter = {};
        let newCurrentOps = {};
        let newCurrentLabels = {};

        currentFilterValues.map((value, index) => {
            newCurrentFilter[value.fieldName] = value.fieldValue;
            newCurrentOps[value.fieldName] = value.filterOperator;
            newCurrentLabels[value.fieldName] = value.fieldLabel;
        });

        this.log(newCurrentFilter);
        this.log(newCurrentOps);
        this.log(newCurrentLabels);

        this.state.currentFilter = newCurrentFilter;
        this.state.currentFilterOps = newCurrentOps;
        this.state.currentFilterLabels = newCurrentLabels;

    }

    deleteFilter(filter, value = null, label = null) {

        this.log("deleteFilter");

        let newCurrentFilter;
        let newCurrentLabels;

        let currentFilterState = this.state;

        if (this.nameVariableStateFilter != null) {
            currentFilterState = appState[this.nameVariableStateFilter];
        }

        if (filter.op == "IN" || filter.op == "STRIN") {

            newCurrentFilter = currentFilterState.currentFilter;
            newCurrentLabels = currentFilterState.currentFilterLabels;

            // Eliminar values

            let values = newCurrentFilter[filter.key];
            let newValues = "";
            if (values != null) {
                newValues = values.replace("" + value + ",", "");
            }

            if (values == newValues) {
                newValues = values.replace("," + value + "", "");
            }

            if (values == newValues) {
                newValues = values.replace("" + value + "", "");
            }

            newCurrentFilter[filter.key] = newValues;

            // Eliminar labels

            let labels = newCurrentLabels[filter.key];

            if (labels != null) {

                let newLabels = labels.replace("/'" + label + "/',", "");

                if (labels == newLabels) {
                    newLabels = labels.replace(",/'" + label + "/'", "");
                }

                if (labels == newLabels) {
                    newLabels = labels.replace("/'" + label + "/'", "");
                }

                newCurrentLabels[filter.key] = newLabels;

                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                    delete newCurrentLabels[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter,
                        currentFilterLabels: newCurrentLabels
                    });
                }

            } else {

                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter
                    });
                }

            }

        } else {
            newCurrentFilter = currentFilterState.currentFilter;
            delete newCurrentFilter[filter.key];
            newCurrentLabels = currentFilterState.currentFilterLabels;
            delete newCurrentLabels[filter.key];
        }

        //TODOJAL. Cuando los listados filtren bien quitar esto
        let filtersArray = this.getFiltersFromStateForUrl(newCurrentFilter);

        if (this.nameVariableStateFilter != null) {
            appState[this.nameVariableStateFilter].currentFilter = newCurrentFilter;
            appState[this.nameVariableStateFilter].currentFilterLabels = newCurrentLabels;
            filtersArray = this.getFiltersFromStateForUrlObj(appState[this.nameVariableStateFilter]);
        }

        let links = new Links(this.props.location);
        let newUrl = links;
        if (filtersArray.length != 0) {
            newUrl = links.getUrlLinkWithFilters(filtersArray);
        }

        this.props.history.push(newUrl);

    }

    renderAbsoluteTextLoadingQuery() {
        return null;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default BaseList;
