import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import MonthDrop from "./MonthDrop";
import WorkInMonthUnit from "./WorkInMonthUnit";
import withTranslate from "../../translator/withTranslate";
import util from '../../util/Util';
import appState from "../../state/AppState";
import GraphException from "../../network/GraphException";
import GqlErrors from "../../components/status/GqlErrors";
import TabsComponent from "../../components/fields/TabsComponent";
import EmptyDrop from "./EmptyDrop";
import TabsData from "../../layout/TabsData"
import PageTitle from "../../components/PageTitle";
import BaseForm from "../../pages/base/BaseForm";
import { withRouter } from "react-router-dom";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import ClientModel from "../../models/ClientModel";
import OrderModel from "../../models/OrderModel";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import VsfButton from "../../components/VsfButton";
import ScheduleVisitModel from "../../models/ScheduleVisitModel";
import Select2Component from "../../components/fields/Select2Component";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/PopUp";
import ClientPreventModal from "./ClientPreventModal";
import SlotModel from "../../models/SlotModel";
import GroupOfField from "../../components/layout/GroupOfField";
import moment from "moment";

@observer
class ClientSchedule extends BaseForm {
    nameMainType = "client";
    @observable gqlErrors = [];
    @observable slots = [];

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.state = {
            totalHours: 0,
            totalVisits: 0,
            order: {},
            eliminar: false,
            fromClient: false,
            modalClient: false,
        };
        this.tabsData = new TabsData();

    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    getModelQuery() {
        return new ClientModel();
    }

    async loadOrdersWithinMonth() {
        this.log("loadSlotsWithinDates");
        let dateFrom = this.propsUtil.getRequest("date") || moment().format("YYYYMM");
        await appState.scheduleDropState.loadClientsFromScheduledByClient(dateFrom);
    }

    getMonth(type) {
        let year = this.year;
        let tipos = {
            '01': "Enero",
            '02': "Febrero",
            '03': "Marzo",
            '04': "Abril",
            '05': "Mayo",
            '06': "Junio",
            '07': "Julio",
            '08': "Agosto",
            '09': "Septiembre",
            '10': "Octubre",
            '11': "Noviembre",
            '12': "Diciembre",
        };
        let result = tipos[type];
        return result;
    }

    getMonths() {
        let year = this.year;
        let result = [
            { label: "Ene", year, code: this.year + '01' },
            { label: "Feb", year, code: this.year + '02' },
            { label: "Mar", year, code: this.year + '03' },
            { label: "Abr", year, code: this.year + '04' },
            { label: "May", year, code: this.year + '05' },
            { label: "Jun", year, code: this.year + '06' },
            { label: "Jul", year, code: this.year + '07' },
            { label: "Ago", year, code: this.year + '08' },
            { label: "Sep", year, code: this.year + '09' },
            { label: "Oct", year, code: this.year + '10' },
            { label: "Nov", year, code: this.year + '11' },
            { label: "Dic", year, code: this.year + '12' },
        ];
        return result;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("ClientSchedule.componentDidMount");
        this.year = this.getYearFromUrl() || 2019;
        try {
            await this.loadScheduledOrders();
            await this.loadScheduledVisits();
            await this.getSlots();
        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);
        }

        await this.getTotals();
    }

    async reloadTable() {
        await this.loadScheduledOrders();
        await this.loadScheduledVisits();
        await this.getTotals();
        await this.getSlots();
    }

    async deleteOrder() {
        try {
            await this.state.order.remove();
            await this.loadOrdersWithinMonth();
            appState.scheduleDropState.scheduledOrders = appState.scheduleDropState.scheduledOrders.filter(orderIterate => orderIterate.id != this.state.order.id);

        } catch (e) {
            this.log("EXCEPCION=>");
            this.log(e);
        }
        this.setState({
            eliminar: false
        });

    }

    onDeleteWithSlot(order) {
        this.setState({
            order,
            eliminar: true
        });
    }

    noCancel() {
        this.setState({
            eliminar: false
        });
    }

    /**
     * Carga los tipos de visitas que se pueden planificar
     * */
    async loadScheduledVisits() {
        appState.scheduleDropState.loadScheduledVisits();
    }

    /**
     * Carga las planificaciones realizadas con la herramienta (de ese cliente), para ello, la tabla "orders" tendrá los campos:
     * - clientId: para saber la tienda
     * - scheduledDate: Para saber el mes en el que está planificado (inicialmente)
     * */
    async loadScheduledOrders() {
        let orderQuery = new OrderModel();
        orderQuery.clientId = this.getFormId();
        let dateFrom = this.year + "01";
        let dateTo = this.year + "12";
        orderQuery.filters = [
            { "fieldName": "scheduledDate", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "scheduledDate", "fieldValue": dateTo, "filterOperator": "LTEQ" },
        ]
        let result = await orderQuery.find();
        appState.scheduleDropState.scheduledOrders = result;
    }

    async changeYear(e) {
        this.log("changeYear()");
        await this.propsUtil.changeUrlRequest({ year: e.target.value });
        this.year = e.target.value;
        this.reloadTable();
    }

    getYearFromUrl() {
        return this.propsUtil.getRequest("year") || 2019;
    }

    async loadVisits(visitId) {
        let scheduledQuery = new ScheduleVisitModel();
        scheduledQuery.addRelatedTable("scheduleVisitSlots");
        let scheduledModel = await scheduledQuery.findById(visitId);
        return scheduledModel.toPlainObject();
    }

    async getSlots() {
        this.log("getSlots()");
        let objWithOrderIds = {};
        for (let order of appState.scheduleDropState.scheduledOrders) {
            if (order.scheduleVisitId != null && order.scheduledDate.startsWith(this.year)) {
                objWithOrderIds[order.id] = "";
            }
        }
        let arrOrders = Object.keys(objWithOrderIds);
        this.log(objWithOrderIds);
        if (arrOrders.length > 0) {
            let slots;
            let slotQuery = new SlotModel();
            slotQuery.addRelatedTable("order");
            slotQuery.filters = [
                { "fieldName": "orderId", "fieldValue": arrOrders.join(","), "filterOperator": "IN" },
            ];
            slots = await slotQuery.findPlainObject();
            this.slots = util.getDictMultipleFromArrayToArray(slots, "scheduledDate");

        }

    }

    async getTotals() {
        let visitType = []
        for (let order of appState.scheduleDropState.scheduledOrders) {
            if (order.scheduleVisitId != null && order.scheduledDate.startsWith(this.year)) {
                visitType.push(order);
            }
        }
        let visit = [];
        for (let visitModel of visitType) {
            let visitQuery = await this.loadVisits(visitModel.scheduleVisitId)
            visit.push(visitQuery)
        }
        let sumHours = 0
        let sumVisits = 0
        for (let scheduleVisitSlot of visit) {
            scheduleVisitSlot.scheduleVisitSlots.map((slot) => {
                sumHours += parseInt(slot.scheduledDuration)
            });

            sumVisits++;
        }
        this.setState({
            totalHours: sumHours,
            totalVisits: sumVisits
        });
    }

    onOpenOrderModal(order) {
        if (util.hasValue(order)) {
            this.setState({
                modalClient: true,
                order
            })
        }
    }

    render() {
        this.log("render()");
        const { t, i18n } = this.props;
        let client = this.graphDataMainType;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let optionsYear = [
            { label: "2019", value: "2019" },
            { label: "2020", value: "2020" },
            { label: "2021", value: "2021" },
            { label: "2022", value: "2022" },
            { label: "2023", value: "2023" },
            { label: "2024", value: "2024" },
            { label: "2025", value: "2025" },
            { label: "2026", value: "2026" },
            { label: "2027", value: "2027" },
            { label: "2028", value: "2028" },
            { label: "2029", value: "2029" },
        ];


        if (client == null) {
            client = {};

        }
        let months = this.getMonths();
        let visitsType = appState.scheduleDropState.scheduleVisits;
        let visitsTypeDict = util.getDictSingleFromArray(appState.scheduleDropState.scheduleVisits);
        // scheduledInMonth tendrá un objeto con clave el mes "201901" y valor el OrderModel.
        let scheduledInMonth = {};
        for (let scheduledOrder of appState.scheduleDropState.scheduledOrders) {
            if (scheduledInMonth[scheduledOrder.scheduledDate] == null) {
                scheduledInMonth[scheduledOrder.scheduledDate] = [];
            }
            scheduledInMonth[scheduledOrder.scheduledDate].push(scheduledOrder);
        }

        return (
            <InfoBlock componentObject={this}>
                <EmptyDrop getTotals={() => this.getTotals()} getSlots={() => this.getSlots()}
                           loadClients={() => this.loadOrdersWithinMonth()}
                           onDeleteWithSlot={(e) => this.onDeleteWithSlot(e)}>
                    <GqlErrors errors={this.gqlErrors} />
                    <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal
                                    title={"Cliente"}
                                    onBackModal={() => this.onBackModal()}
                                    subtitle={(this.isPos == 0 ? client.companyName : client.name) + " " + client.code}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}
                                />


                                <TabsComponent
                                    id={this.graphDataMainType.id || this.props.clientId}
                                    tabs={this.graphDataMainType.isPos == 1 ? this.tabsData.getDataClientPos() : this.tabsData.getDataClient()}
                                    active={"Preventivas"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                            </div>
                            :
                            <PageTitle
                                title={"Cliente"}
                                subtitle={(this.isPos == 0 ? client.companyName : client.name) + " " + client.code}
                            />

                        }

                        <div className={'card mb-3'}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.graphDataMainType.isPos == 1 ? this.tabsData.getDataClientPos() : this.tabsData.getDataClient()}
                                active="Preventivas"
                                fromRightModal={this.props.fromRightModal}

                            />
                            }
                            <div className={modalClasses.body}>


                                <div className="card-body pt-0">

                                    <div className="row">

                                        <div className="col-md-12">

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade" id="detalles" role="tabpanel"
                                                     aria-labelledby="detalles-tab">{t('Detalles')}
                                                </div>
                                                <form action="#" method="#" className="mb-3">
                                                    <div className="row">
                                                        <div className="col-4 table-four table-four--small">
                                                            <table className="table visible-text"
                                                                   summary="Suma total de horas y de visitas">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col"
                                                                        className="cell-total"
                                                                        style={{ 'borderBottom': 0 }}>
                                                                        <span>{t('Año')}</span>
                                                                    </th>
                                                                    <th scope="col"
                                                                        className="cell-total">{t('total')}<span>{t('horas')}</span>
                                                                    </th>
                                                                    <th scope="col"
                                                                        className="cell-total">{t('total')}<span>{t('visitas')}</span>
                                                                    </th>
                                                                </tr>

                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td style={{ width: '100px' }}>
                                                                        <div>
                                                                            <Select2Component
                                                                                value={this.getYearFromUrl()}
                                                                                onChange={(e) => this.changeYear(e)}
                                                                                name={"scheduleDate"}
                                                                                type={"text"}
                                                                                clearable={false}
                                                                                forceMedia={this.props.fromRightModal ? "md" : ""}
                                                                                options={optionsYear}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className="total">{this.state.totalHours}<span
                                                                            className="small">h</span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className="total">{this.state.totalVisits}<span
                                                                            className="small">{t('Visitas')}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div
                                                            className="col-6 d-flex align-items-center o-flex-end table-four table-four--small">

                                                            <div>
                                                                <VsfButton
                                                                    label={"Planificar 12 meses"}
                                                                    onClick={_ => {
                                                                        this.setState({
                                                                            fromClient: true,
                                                                            modalClient: true
                                                                        })
                                                                    }}
                                                                    classButton="btn--loader"
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row mt-3 mb-3">
                                                        <div
                                                            className={this.props.fromRightModal ? "col-md-12" : "col-md-10" + " table-four"}>
                                                            <div className="">
                                                                <table className="table"
                                                                       summary="Visitas acordadas con el cliente donde muestra las horas por meses">
                                                                    <thead>
                                                                    <tr>
                                                                        {months.map((monthItem, index) =>
                                                                            <React.Fragment key={index}>
                                                                                <th scope="col">{monthItem.label}
                                                                                </th>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr className={"preventivas"}>
                                                                        {months.map((monthItem, index) =>
                                                                            <React.Fragment key={index}>
                                                                                <MonthDrop monthItem={monthItem}
                                                                                           loadClients={() => this.loadOrdersWithinMonth()}
                                                                                           getTotals={() => this.getTotals()}
                                                                                           getSlots={() => this.getSlots()}
                                                                                           storeId={this.getFormId()}>
                                                                                    {scheduledInMonth[monthItem.code] != null && scheduledInMonth[monthItem.code].map(order =>
                                                                                        <WorkInMonthUnit
                                                                                            name={order.id}
                                                                                            order={order}
                                                                                            onClick={(e) => this.onOpenOrderModal(e)}
                                                                                            visitType={appState.scheduleDropState.scheduleVisitsDict[order.scheduleVisitId]}
                                                                                            key={index + order.id} />
                                                                                    )}
                                                                                </MonthDrop>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {visitsType.map((visitType, index) =>
                                                            <div className="col-lg-3 mb-2" key={index}>
                                                                <div className="visits">
                                                                    <p className="visits__text">{t(visitType.name)}</p>
                                                                    <WorkInMonthUnit name={"sss"}
                                                                                     onClick={() => this.onOpenOrderModal()}
                                                                                     visitType={visitType} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <GroupOfField
                                                        title={t('Agenda.')}
                                                        icon="fas fa-calendar-check"
                                                        classGroup="sinestilo"

                                                    >
                                                        <div className="col-12">
                                                            <div className="c-modal-info__cell__body clientSchedule">

                                                                {this.slots != null && Object.keys(this.slots).map(scheduledDate =>

                                                                    this.slots[scheduledDate].map(slot => (
                                                                        <div className={"c-modal-info-cell__box mt-3"}>
                                                                            <ul className="c-modal-info-cell__orders blue">
                                                                                <li className="c-modal-info-cell__orders__item"
                                                                                    style={{ 'min-width': '50px' }}>
                                                                                    {slot.scheduledDuration + "h."}
                                                                                </li>
                                                                                <li className="c-modal-info-cell__orders__item">
                                                                                    {util.isValidDate(new Date(util.getMoment(slot.scheduledTime))) ?
                                                                                        util.getMoment(slot.scheduledTime).format("DD/MM/YY") :
                                                                                        slot.scheduledDate && this.getMonth(util.right(slot.scheduledDate, 2))
                                                                                    }
                                                                                </li>
                                                                                <li className="c-modal-info-cell__orders__item">
                                                                                    {util.isValidDate(new Date(util.getMoment(slot.scheduledTime))) ?
                                                                                        util.getMoment(slot.scheduledTime).format("HH:mm") :
                                                                                        "Sin asignar"
                                                                                    }
                                                                                </li>
                                                                                <li className="c-modal-info-cell__orders__item">
                                                                                    {visitsTypeDict[slot.order.scheduleVisitId] && visitsTypeDict[slot.order.scheduleVisitId].name}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    ))
                                                                )
                                                                }
                                                            </div>
                                                        </div>
                                                    </GroupOfField>
                                                </form>
                                                <div className="tab-pane fade" id="equipo" role="tabpanel"
                                                     aria-labelledby="equipo-tab">{t('Equipos')}
                                                </div>
                                                <div className="tab-pane fade" id="contactos" role="tabpanel"
                                                     aria-labelledby="contactos-tab">{t('Contactos')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AlertModal isOpen={this.state.eliminar}
                                onCloseModal={() => this.setState({
                                    eliminar: false
                                })}
                    >
                        <PopUp
                            title={t('Eliminar')}
                            subtitle={t('Pedido ')}
                            icon="fas fa-exclamation-triangle"
                            text={t('¿Estas Seguro de Eliminar este pedido y todos sus datos relaccionados?')}
                            label1={'No eliminar'}
                            label2={'Eliminar'}
                            onClick1={() => this.noCancel()}
                            onClick2={() => this.deleteOrder()}
                        >
                        </PopUp>
                    </AlertModal>
                    {this.state.modalClient &&
                    <ClientPreventModal
                        order={this.state.order}
                        year={this.year}
                        getTotals={() => this.getTotals()}
                        getSlots={() => this.getSlots()}
                        loadClients={() => this.loadClients()}
                        fromClient={this.state.fromClient}
                        openModal={this.state.modalClient}
                        closeModal={() => this.setState({ modalClient: false, fromClient: false })}
                    />
                    }
                </EmptyDrop>
            </InfoBlock>
        )
    }

    async loadClients() {
        if (!this.ifPathStartsWith("/schedule/bytechnical")) {
            await this.loadOrdersWithinMonth();
        }
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        this.log(this.props.location.pathname);
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(withTranslate(ClientSchedule, "ClientScheduleiveDrag"));
