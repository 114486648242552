import React, { Component } from 'react';
import { observer } from "mobx-react";
import Links from "../../util/Links";
import { computed } from 'mobx';
import TabsData from "../../layout/TabsData";
import AppLogger from "../../util/AppLogger";
import util from "../../util/Util";
import BaseForm from "./BaseForm";
import appState from "../../state/AppState"


@observer
class BaseEditableTable extends BaseForm {

    /**
     * Nombre del campo de la clase principal
     */
    foreingKeyfield;
    modalClases;

    /**
     * crear el elemento foreingKeyField
     * this.foreingKeyfield = "clientId";
     * Inicialmente no muestro ningun contacto
     *  appState.contactState.contacts = [];
     * @param props
     */
    constructor(props) {
        super(props);
        this.log(".constructor()");
        this.tabsData = new TabsData();
        this.state = {
            hasSomeRowEditing: false, // Muestra este formulario en modo edición en algono de los elementos
        }
        this.modalClases = util.getModalClasses();
    }

    /**
     * Get del estado para poder usarlo en eliminarTabla
     * Cambiar por el estado que se quiera conseguir
     * return appState.contactState.contacts;
     * @returns {Array}
     */
    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    onOpenRowNewEmpty() {
        this.log("onOpenRowNewEmpty()");
        let newModel = this.getModelTable().toPlainObject();
        if (this.state.fromWorkOrder) {
            newModel.orderId = this.state.orderId;
        }
        newModel.isEditing = true;
        this.mobxListado.push(newModel);
        this.setState({
            hasSomeRowEditing: true
        });
    };

    getFormId() {
        let id = super.getFormId();
        if (this.props[this.foreingKeyfield] != null) {
            id = this.props[this.foreingKeyfield];
        }
        return id;
    }

    async reloadForm() {

    }

    /**
     * Cambiar por el modelo que se quiera conseguir
     * return  new ContactModel();
     */
    getModelTable() {
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable(this.getFormId());
    }

    async reloadTable(orderId) {

    }

    componentWillReceiveProps(nextProps) {
        this.log(".componentWillReceiveProps()" + this.props[this.foreingKeyfield] + " - " + nextProps[this.foreingKeyfield]);
        if (nextProps[this.foreingKeyfield] !== this.props[this.foreingKeyfield]) {
            this.reloadTable(nextProps[this.foreingKeyfield]);
        }
    }

    async componentDidUpdate() {

    }

    getFormId() {
        let result = this.props[this.foreingKeyfield];
        if (result == null) {
            let links = new Links();
            result = links.getFormId(this.props);
        } else {
            result = result + "";
        }
        return result;
    }


    setHasSomeRowEditing(newHasSomeRowEditing) {
        this.setState({
            hasSomeRowEditing: newHasSomeRowEditing
        });
        //Si es false quito el isEditing De todos los contactos
        if (!newHasSomeRowEditing) {
            for (let row of this.mobxListado) {
                row.isEditing = false;
            }
        }
    };


    render() {
        let props = this.props;
        return (
            <></>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default BaseEditableTable;
