import React from 'react';
import {observer} from "mobx-react/index";
import appState from '../../state/AppState';
import {withApollo} from "react-apollo";
import PropTypes from 'prop-types';
import {computed} from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import VsfButton from "../../components/VsfButton";
import AppLogger from "../../util/AppLogger";
import ArticleCodesModel from "../../models/ArticleCodesModel";
import ArticleCodeForm from "./ArticleCodeForm";
import withTranslate from "../../translator/withTranslate";

@observer
class ArticleCodesTable extends BaseEditableTable {


    static defaultProps = {
        foreingKeyfield: "",
        fromOrder: false,
    }

    static propTypes = {
        foreingKeyfield: PropTypes.string,
        fromOrder: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.foreingKeyfield = this.props.foreingKeyfield;
    }

    @computed get mobxListado() {
        return appState.articleCodesState.articleCodes;
    }

    getModelTable() {
        return new ArticleCodesModel();
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("ArticleCodesTable componentDidMount =>");
        this.log(this.getFormId());

        await this.reloadTable(this.getFormId());
    }

    async reloadTable(articleId) {
        let articleCodes = new ArticleCodesModel();
        let articleModel = await articleCodes.getArticleCodesFromArticleId(articleId);
        appState.articleCodesState.articleCodes = util.arrayModelToPlainObjects(articleModel);
    }


    render() {
        this.log("ArticleCodesTable render =>");
        const {t, i18n} = this.props;
        this.propsUtil = new PropsUtil(this.props);


        return (
            <InfoBlock componentObject={this}>
                <div className={this.props.classGroup||"row mt-4"}>
                    <div className="col-12">
                        <div className="table-edit">
                            <table className="table visible-text"
                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                <thead>
                                <tr>
                                    <th scope="col">{t('Código')}</th>
                                    <th scope="col">{t('Tipo de Código')}</th>
                                    <th scope="col">{t('Fabricante/Proveedor')}</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody className="border-end">

                                {this.mobxListado.map((code, index) => (
                                    <ArticleCodeForm
                                        key={code && code.id}
                                        rowIndex={index}
                                        row={code}
                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                        {...this.props}
                                    />
                                ))}

                                </tbody>
                            </table>

                        </div>
                        <div className="text-right">
                            <VsfButton
                                disabled={this.state.hasSomeRowEditing}
                                label={"Crear nueva"}
                                onClick={_ => this.onOpenRowNewEmpty()}
                                isEditing={this.props.editing}
                                classButton="btn--loader"
                            />
                        </div>
                    </div>

                </div>

            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo(withTranslate(ArticleCodesTable)));
