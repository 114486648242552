import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost/lib/index";
import apoloClient from "../storage/ApoloClientInstance";
import util from '../util/Util';
import AppLogger from "../util/AppLogger";

export default class Model {

    id;
    orderBy = "id";
    orderMode = "ASC";
    nameMainType = "";
    graphFindOperation = "";
    graphFindByIdOperation = "";
    graphExportOperation = "";

    refineWhere = [];
    /**
     * Listado de filtros a a plicar en la llamada a Find() Ejemplo: [{"fieldName":"status","fieldValue":"sta1"}]
     * @type {Array}
     */
    filters = [];

    //Indica si debemos buscar información relacionada
    _relatedTables = {};

    // Campos iniciales para que solo se haga persist de los cambiados
    _fieldsOnLoad = {}

    /**
     * Indica que este objeto ya tiene los campos iniciales cargados. De esa forma se llevará un registro de lo que cambia para que solo se actualice lo cambiado. Sólo el primer nivel (no relaciones anidadas)
     */
    setAndGetInitialState() {
        let result = this.toPlainObjectMutation();
        //result={};
        this._fieldsOnLoad = result;
        return result;
    }

    recoverInitialState(plainObj) {
        this._fieldsOnLoad = plainObj;
    }

    async persist() {
        let apolloProxy = new ApolloProxy(apoloClient);
        this.log("persist");

        let modelToSavePlain = this.toPlainObjectMutation();
        let modelToSavePrevious = this._fieldsOnLoad;
        for (let key in modelToSavePrevious) {
            this.log("key:" + key + " " + modelToSavePrevious[key] + " " + modelToSavePlain[key]);
            if (modelToSavePrevious[key] === modelToSavePlain[key]) {
                delete modelToSavePlain[key];
            }
        }
        /*
        let allowedFields = this.getFieldsForGraphQLMutation();
        for(let [field,value] of Object.entries(modelToSavePlain)) {
            if (allowedFields.indexOf(field)==-1) {
                delete modelToSavePlain[field];
            }
        }
        */
        delete modelToSavePlain["id"];
        /*
        for(let key of Object.keys(modelToSavePlain)) {
            if (modelToSavePlain[key]=='') {
                modelToSavePlain[key]=null;
            }
        }
        */
        let id = this.id;
        if (util.esVacio(id)) {
            id = null;
        }
        let variables = {
            id
        }
        variables[this.nameMainType] = modelToSavePlain;
        this.postFillInputVariables(variables);
        let { operationUpdate } = this.getVariablesMutation();
        let mutation = this.getGraphQLMutationUpdate();
        const resultQuery = await apolloProxy.mutate({ mutation, variables, errorPolicy: 'all' });
        //console.log({resultQuery});
        for (let fieldName of this.getResponseFieldsFromMutation()) {
            this[fieldName] = resultQuery.data[operationUpdate][fieldName];
        }


        return resultQuery;
    }

    /**
     * Los campos que al guardar con el API se obtendrán (calculados) desde GQL
     * @returns {string[]}
     */
    getResponseFieldsFromMutation() {
        return ["id"];
    }

    /**
     * A partir de un objeto json rellena este objeto con todas sus relaciones
     * @param obj
     */
    hidrate(obj) {
        //console.log("Model.hidrate()");
        //console.log({obj});
        //TODOJAL
        if (obj != null) {
            let arrayFields = this.getArrayFields();
            //console.log({arrayFields});
            for (let [nameField, value] of Object.entries(arrayFields)) {
                //console.log("field:"+field);
                //console.log({value});
                if (obj[nameField] != null) {
                    //console.log("field:"+field + " => " + obj[field]);

                    //Reviso si hay algún campo con relaciones 1-n
                    if (value.type === "Relation") {
                        let Relation = value.childType;
                        let listado = [];
                        for (let relationChild of obj[nameField]) {

                            let relation = new Relation();
                            //Añado las tablas relacionadas que empiecen por este nombre de campo
                            this.addRelatedTablesToModel(nameField, relation);

                            //console.log({relationChild});
                            relation.hidrate(relationChild);
                            //console.log({relation});
                            listado.push(relation);
                        }
                        this[nameField] = listado;
                    } else if (value.type === "RelationOne") {
                        let Relation = value.childType;
                        let relation = new Relation();
                        //Añado las tablas relacionadas que empiecen por este nombre de campo
                        this.addRelatedTablesToModel(nameField, relation);

                        relation.hidrate(obj[nameField]);
                        this[nameField] = relation;
                    } else {
                        this[nameField] = obj[nameField];
                    }
                }
            }
        }
        //console.log("/Model.hidrate()");
        let result = this;
        //console.log({result});
    }

    /**
     * Si tengo un array de tablas relacionadas
     * Devuelve un array con las que empiecen por el prefijo (sin el prefijo)
     * Por ejemplo:
     *   workOrder
     *   workOrder.slot
     * getRelatedTablesWithPrefix("workOrder")=>["slot"]
     * @param prefix
     */
    getRelatedTablesWithPrefix(prefix) {
        let strCompare=prefix+".";
        let result=[];
        for(let nameTable of Object.keys(this._relatedTables)) {
            if (nameTable.startsWith(strCompare)) {
                result.push( nameTable.substr(strCompare.length));
            }
        }
        return result;
    }

    addRelatedTablesToModel(nameField, model) {
        //Añado las tablas relacionadas que empiecen por este nombre de campo
        let tablasRelacionadas = this.getRelatedTablesWithPrefix(nameField);
        for(let tablaRelacionada of tablasRelacionadas) {
            model.addRelatedTable(tablaRelacionada);
        }
    }

    toPlainObjectMutation() {
        return this.toPlainObject(true);
    }

    toPlainObject(forMutation) {
        let result = {};
        let arrayFields = this.getArrayFields();
        if (forMutation) {
            arrayFields = this.getArrayFieldsMutation();
        }
        //console.log({arrayFields});
        for (let [nameField, value] of Object.entries(arrayFields)) {
            //console.log("field:"+field);
            //console.log({value});
            if (this[nameField] != null) {

                //console.log("field:"+field + " => " + obj[field]);

                //Reviso si hay algún campo con relaciones 1-n
                if (value.type === "Relation") {
                    let Relation = value.childType;
                    let listado = [];
                    for (let relationChild of this[nameField]) {
                        let relation = new Relation();
                        //Añado las tablas relacionadas que empiecen por este nombre de campo
                        this.addRelatedTablesToModel(nameField, relation);
                        relation.hidrate(relationChild);
                        listado.push(relation.toPlainObject(forMutation));
                    }
                    result[nameField] = listado;
                } else if (value.type === "RelationOne") {
                    let Relation = value.childType;
                    let relation = new Relation();
                    //Añado las tablas relacionadas que empiecen por este nombre de campo
                    this.addRelatedTablesToModel(nameField, relation);
                    relation.hidrate(this[nameField]);
                    result[nameField] = relation;
                } else {
                    let valueForField = this[nameField];
                    if (value.type === "IntField") {
                        valueForField = util.str2int(valueForField);
                    } else if (value.type === "DateTimeField") {
                        if (valueForField === "") {
                            valueForField = null;
                        }
                    } else if (value.type === "BoolField") {
                        if (typeof valueForField === "boolean") {
                        } else {
                            valueForField = (util.str2int(valueForField) !== 0);
                        }
                    } else {

                    }
                    result[nameField] = valueForField;
                }
            } else {
                if (!forMutation) {
                    result[nameField] = null;
                }
            }
        }
        return result;
    }

    postFillInputVariables(variables) {
        //variables[this.nameMainType]["type"]="";
    }

    getArrayFields() {
        let result = {
            "id": { type: "CodeField" },
            //"zoneLabel":{readonly:true},
        };
        /*
        if (this._relatedTables["documents"]) {
            result["documents"]={type:"Relation", onlyRead:true, childType:DocumentModel, arrayFields: {
                    "id":"",
                    "size":"",
                    "url":""
                }
            }
        }
         */
        return result;
    }

    getArrayFieldsMutation() {
        let allFields = this.getArrayFields();
        //let result =allFields;

        let result = {};
        //console.log({arrayFields});
        for (let [field, value] of Object.entries(allFields)) {
            //console.log("field:"+field);
            //console.log({value});
            if (this[field] != null) {
                if (value.readonly) {
                } else {
                    result[field] = value;
                }
            }
        }

        return result;
    }

    getFieldsForGraphQLQuery() {
        let result = [];
        result = Object.keys(this.getArrayFields());
        return result;
    }


    getFieldsForGraphQLMutation() {
        let result = Object.keys(this.getArrayFieldsMutation());
        let forDeletion = ["createdAt", "updatedAt", "createdBy", "updatedBy"];
        //Elimino los que no se necesitan para la mutaciópn
        result = result.filter(item => !forDeletion.includes(item));
        return result;
    }

    getGraphQLMutationUpdate() {
        let responseFieldsArr = this.getResponseFieldsFromMutation();
        let responseFieldsStr = "id";
        responseFieldsStr = responseFieldsArr.join(",");
        let {
            nameMainType,
            nameMainTypePascal,
            operationPascal,
            operationUpdate,
            inputType,
            inputVariable
        } = this.getVariablesMutation();
        let result = gql`
            mutation ${operationPascal}(${inputVariable}: ${inputType}!, $id: ID) {
            ${operationUpdate}(${nameMainType}: ${inputVariable}, id: $id) {
            ${responseFieldsStr}
            }
            }
        `;
        return result;
    }

    getGraphQLMutationDelete() {
        let {
            nameMainType,
            nameMainTypePascal,
            operationPascalDelete,
            operationDelete,
            inputType,
            inputVariable
        } = this.getVariablesMutation();
        let result = gql`
            mutation ${operationPascalDelete}( $id: ID) {
                ${operationDelete}(id: $id) {
                id
            }
            }
        `;
        return result;
    }

    getVariablesMutation() {
        let nameMainType = this.nameMainType;
        let nameMainTypePascal = util.toPascalCase(nameMainType);
        let operationPascal = "CreateOrModify" + nameMainTypePascal;
        let operationUpdate = nameMainType + "Update";
        let operationCreate = nameMainType + "Create";
        let operationDelete = nameMainType + "Delete";
        let operationPascalDelete = util.toPascalCase(operationDelete);
        let inputType = nameMainTypePascal + "InputType";
        let inputVariable = "$" + nameMainType;
        return {
            nameMainType,
            nameMainTypePascal,
            operationPascal,
            operationUpdate,
            operationCreate,
            operationDelete,
            operationPascalDelete,
            inputType,
            inputVariable
        }
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLExport(q) {
        let nameQuery = util.toPascalCase(this.graphExportOperation);
        let graphOperation = this.graphExportOperation;
        let fields = this.getFieldsForGql();

        let result = gql`
            query ${nameQuery}($query: QueryInputType, $sort: SortInputType, $fields:String,$format: FormatEnumType) {
                ${graphOperation}(query:$query, sort: $sort, fields:$fields,format: $format)
            }
        `;
        return result;
    }


    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLFindQuery() {
        let nameQuery = util.toPascalCase(this.graphFindOperation);
        let graphOperation = this.graphFindOperation;
        let fields = this.getFieldsForGql();

        let result = gql`
            query ${nameQuery}($query: QueryInputType, $sort: SortInputType, $first: Int, $page: Int, $after: String, $before: String) {
                ${graphOperation}(query:$query, sort: $sort, first: $first, , page: $page, after: $after, before: $before) {
                totalCount,
                pageInfo {
                    endCursor,
                    hasNextPage,
                    hasPreviousPage,
                    startCursor
                },
                items {
                    ${fields}
                }
            }
            }
        `;
        return result;
    }

    getFieldsForGql() {
        let result = this.getFieldsForGqlFromArray(this.getArrayFields());
        return result;
    }

    getFieldsForGqlFromArray(fieldsImArray) {
        let result = "";
        //this.log("getFieldsForGqlFromArray fieldsImArray: " + JSON.stringify(fieldsImArray));
        for (let [nameField, objParam] of Object.entries(fieldsImArray)) {
            let arrayFields = objParam["arrayFields"];
            let Relation = objParam["childType"];
            //Si tengo arrayFields los añado. Si no los tengo los busco del objeto
            if (objParam.type === "Relation" || objParam.type === "RelationOne") {
                if (arrayFields != null) {
                    result += "," + nameField;
                    result += "{" + this.getFieldsForGqlFromArray(arrayFields) + "}";
                    //this.log("getFieldsForGqlFromArray1 " + name + " " + JSON.stringify(objParam));
                } else if (Relation != null) {
                    let relation = new Relation();
                    //Añado las tablas relacionadas que empiecen por este nombre de campo
                    this.addRelatedTablesToModel(nameField, relation);
                    if (relation) {
                        result += "," + nameField;
                        result += "{" + this.getFieldsForGqlFromArray(relation.getArrayFields()) + "}";
                        //this.log("getFieldsForGqlFromArray2 " + name + " " + JSON.stringify(objParam));
                    }
                }
            } else {
                result += "," + nameField;
                //this.log("getFieldsForGqlFromArray3 " + name + " " + JSON.stringify(objParam));
            }
        }
        if (result != "") {
            result = result.substring(1);
        }
        //this.log("getFieldsForGqlFromArray" + result);
        return result;
    }


    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLFindByIdQuery() {
        let nameQuery = util.toPascalCase(this.graphFindByIdOperation);
        let graphOperation = this.graphFindByIdOperation;
        let fields = this.getFieldsForGql();

        let query = `
            query ${nameQuery}($id: ID) {
                ${graphOperation}(id:$id) {
                    ${fields}
                
                }
            }`;
        console.log("getGraphQLFindByIdQuery");
        console.log({ query });

        let result = gql`
            query ${nameQuery}($id: ID) {
                ${graphOperation}(id:$id) {
                ${fields}

            }
            }
        `;


        return result;
    }

    getGraphQLExportVariables(format, q, fieldsName, fieldsLabel) {
        let filters = this.getFiltersForGraph();
        let order = this.orderBy;
        let orderMode = this.orderMode;
        return {
            query: {
                q,
                filters
            },
            fields: "{\"title\":\"test\",\"fieldsName\":\"" + fieldsName.join(",") + "\",\"fieldsLabel\":\"" + fieldsLabel.join(",") + "\"}",
            sort: {
                field: order,
                orderMode: orderMode
            },
            format: format
        };
    }

    getGraphQLQueryFindVariables() {
        let filters = this.getFiltersForGraph();
        let q = this.refineWhere["q"];
        let first = 5000;
        let order = this.orderBy;
        let orderMode = this.orderMode;
        let after = null;
        let before = null;
        let page = 1;
        return {
            query: {
                q,
                filters
            },
            sort: {
                field: order,
                orderMode: orderMode
            },
            page,
            first,
            after,
            before
        };
    }

    getFiltersForGraph() {
        let fields = this.getArrayFields();
        let filters = this.filters;
        if (filters == null) {
            filters = [];
        }
        for (let [key, value] of Object.entries(fields)) {
            //console.log("getFiltersForGraph " + key+":"+this[key]);
            if (util.hasValue(this[key])) {
                let value = this[key];
                if (value) {
                    value = "" + value;
                }
                filters.push({ fieldName: key, filterOperator: "EQ", fieldValue: value });
            }
        }
        //console.log("getFiltersForGraph");
        //console.log({filters});
        return filters;
    }

    newModel() {
        return Object.create(this);
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async find() {
        let variables = this.getGraphQLQueryFindVariables();
        return await this.findImpl(variables);
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findPlainObject() {
        let models = await this.find();
        return models.map(model => model.toPlainObject())
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async exportExcelOrCsv(format, q, fieldsName, fieldsLabel) {
        this.log("exportExcelOrCsv");

        let variables = this.getGraphQLExportVariables(format, q, fieldsName, fieldsLabel);
        this.log(variables);
        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({
            query: this.getGraphQLExport(),
            variables,
            //errorPolicy:'all' //Las queries no lanzan excepciones a pesar de tener este parametro. solo mutations
        });
        return await resultQuery.data[this.graphExportOperation];
        //console.log("/findImpl");
    }


    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findLimit(fromPage, pageSize) {
        let variables = this.getGraphQLQueryFindVariables();
        variables.first = pageSize;
        variables.page = fromPage;
        return await this.findImpl(variables);
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findImpl(variables) {
        let apolloProxy = new ApolloProxy(apoloClient);
        console.log("findImpl");
        let resultQuery = await apolloProxy.graphQuery({
            query: this.getGraphQLFindQuery(),
            variables,
            //errorPolicy:'all' //Las queries no lanzan excepciones a pesar de tener este parametro. solo mutations
        });
        console.log("/findImpl");
        let operationName = this.graphFindOperation;

        let result = null;
        if (resultQuery && resultQuery.data && resultQuery.data[operationName] && resultQuery.data[operationName].items) {
            let newData = resultQuery.data[operationName];
            let rows = newData.items;
            result = [];
            for (let row of rows) {
                let model = this.newModel();
                model.hidrate(row);
                model._fieldsOnLoad = model.toPlainObjectMutation();

                result.push(model);
            }
            //Asigno a este objeto el valor del total de elementos para poder consultarlo posteriormente
            this.totalCount = newData.totalCount;
            this.pageInfo = newData.pageInfo;
        } else {
            //throw new GraphException("Error al realizar la consulta GraphQL");
            result = [];
        }
        return result;
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findById(id) {
        if (util.esVacio(id)) {
            //throw new AppException("findById con valor nulo");
        }
        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({
            query: this.getGraphQLFindByIdQuery(),
            variables: { id: id },
            //errorPolicy:'all' //Las queries no lanzan excepciones a pesar de tener este parametro. solo mutations
        });
        let operationName = this.graphFindByIdOperation;

        let result = null;
        if (resultQuery && resultQuery.data && resultQuery.data[operationName]) {
            let newData = resultQuery.data[operationName];
            if (newData != null) {
                let model = this.newModel();
                model.hidrate(newData);
                model._fieldsOnLoad = model.toPlainObjectMutation();
                result = model;
            }
        } else {
            //throw new GraphException("Error al realizar la consulta GraphQL");
        }
        return result;
    }

    async findByIdNotNull(id) {
        let result = await this.findById(id);
        if (result == null) {
            result = this.newModel();
        }
        return result;
    }


    async remove() {
        let apolloProxy = new ApolloProxy(apoloClient);
        let variables = { id: this.id };
        let mutation = this.getGraphQLMutationDelete();
        const resultQuery = await apolloProxy.mutate({ mutation, variables });
        console.log("Model.remove()");
        console.log({ resultQuery });
    }

    addRelatedTable(tableName) {
        this._relatedTables[tableName] = 1;
    }

    getResponseFieldsFromMutation() {
        return ["id"];
    }

    getNameModelInDotNet() {
        let nameMainTypePascal = util.toPascalCase(this.nameMainType);
        let nombreModelo = nameMainTypePascal;
        return "dotnetwebapi.Models." + nombreModelo;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
