import { observable, computed, autorun } from 'mobx';
import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost";
import ClientModel from "../models/ClientModel";
import AppLogger from "../util/AppLogger";

class ClientCacheState {

    //bloque client
    @observable clients = [];
    @observable arrClients = [];
    @observable formId = 0;

    /**
     * Obtiene el array de valores creando el arbol e iterando en preorden posteriormente
     *
     * @returns {Array}
     */
    @computed get clientCacheForSelect() {
        this.log("clientCacheForSelect() =>");
        // Primero, obtiene el arbol ordenado de los establecimientos
        let valuesHierarchy = this.getValuesHierarchy(this.clients);
        // Segundo, obtenemos el array en orden
        let valuesHierarchyArray = this.getValuesHierarchyArray(valuesHierarchy);
        return valuesHierarchyArray;
    }

    @computed get clientCacheForOrderSelect() {
        this.log("clientCacheForSelect() =>");
        // Primero, obtiene el arbol ordenado de los establecimientos
        let valuesHierarchy = this.getValuesHierarchy(this.clients);
        // Segundo, obtenemos el array en orden
        let valuesHierarchyArray = this.getValuesHierarchyArrayOrder(valuesHierarchy);
        return valuesHierarchyArray;
    }

    async loadClientCache() {
        this.clients = await this.loadAndGetClientCacheRowsByType();
    }

    /* JERARQUIA */

    /**
     * Metodo para sacar los registros de clientes
     *
     * @returns {Promise<null>}
     */
    async loadAndGetClientCacheRowsByType() {
        let query = gql`
            query client ($query:QueryInputType){
                clients(query:$query) {
                    id,
                    name,
                    code,
                    posZoneId,
                    companyName,
                    isPos,
                    isVAT,
                    vatID,
                    parentId
                }
            }
        `;
        /* let variables={
             query: {
                 filters:[
                     {fieldName:"type",fieldValue:type}
                 ]
             }
         }*/
        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({ query });
        let newData = resultQuery.data;
        let result = [];
        if (newData.clients != null) {
            result = newData.clients;
        }
        return result;
    }

    /**
     * Obtiene el objeto cliente desde el array de clientes (cargados en memoria)
     * @param clientId
     */
    getClientById(clientId) {
        let client = new ClientModel();
        let plainClientFounded = null;
        for (let client of this.clients) {
            if (client.id == clientId) {
                plainClientFounded = client;
            }
        }
        if (plainClientFounded != null) {
            client.hidrate(plainClientFounded);
        }
        return client;
    }

    loadArrayParentId() {
        let arrClient = [];
        for (let client of this.clients) {
            if (arrClient[client.id] == null) {
                arrClient[client.id] = client.companyName;
            }
        }
        this.arrClients = arrClient;
    }

    /**
     * Metodo que recorre los nodos padres para llamar recursivamente al metodo que creara los hijos
     *
     * @param nodos
     * @returns {Array}
     */
    getValuesHierarchy(nodos) {
        let result = [];
        let nodosLibres = nodos.slice(0);
        for (let nodo of nodos) {
            // Si es un nodo padre, construimos el arbol hijo
            if (this.isParent(nodo, nodos)) {
                // Quitamos el nodo padre de la lista de nodos libres
                nodosLibres = nodosLibres.filter(function (obj) {
                    return obj.id !== nodo.id;
                });
                result.push({
                    id: nodo.id,
                    name: nodo.name,
                    code: nodo.code,
                    vatID: nodo.vatID,
                    isVAT: nodo.isVAT,
                    companyName: nodo.companyName,
                    isPos: nodo.isPos,
                    posZoneId: nodo.posZoneId,
                    parentId: nodo.parentId,
                    childs: this.getValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            } else {
                result.push({
                    id: nodo.id,
                    name: nodo.name,
                    code: nodo.code,
                    vatID: nodo.vatID,
                    isVAT: nodo.isVAT,
                    isPos: nodo.isPos,
                    posZoneId: nodo.posZoneId,
                    parentId: nodo.parentId,
                    childs: this.getValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            }

        }
        return result;
    }

    /**
     * Metodo que a partir de un nodo padre, busca nodos hijos y los coloca recursivamente
     *
     * @param parentId
     * @param nodos
     * @returns {Array}
     */
    getValuesHierarchyChilds(parentId, nodos) {
        let childs = [];
        // Estos seran los nodos libres
        let nodosLibres = nodos.filter(function (obj) {
            return obj.parentId != parentId;
        });
        for (let nodo of nodos) {
            // Si es un nodo hijo, lo agregamos al array de hijos
            if (nodo.parentId == parentId) {
                childs.push({
                    id: nodo.id,
                    name: nodo.name,
                    code: nodo.code,
                    vatID: nodo.vatID,
                    companyName: nodo.companyName,
                    isVAT: nodo.isVAT,
                    isPos: nodo.isPos,
                    posZoneId: nodo.posZoneId,
                    childs: this.getValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            }
        }
        return childs;
    }

    /**
     * A partir del arbol ordenado de valores, obtiene el array en preorden recursivamente para ser mostrado en el select
     *
     * @param nodos
     * @param list
     * @param altura
     * @param disabled
     * @returns {Array}
     */
    getValuesHierarchyArray(nodos, list = false, altura = 0, disabled = false) {
        let result = [];
        for (let nodo of nodos) {
            // Si es el mismo nodo del formulario, el y sus hijos no podran ser seleccionados como padres
            if (nodo.id == this.formId) {
                disabled = true;
            }
            // No añadimos la jerarquia si no es establecimiento
            if (nodo.isPos === false) {
                if (list) {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        isVAT: nodo.isVAT,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.name, altura, true),
                        parent: nodo.parentId,
                        altura

                    });
                } else {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        code: nodo.code,
                        vatID: nodo.vatID,
                        isVAT: nodo.isVAT,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        companyName: nodo.companyName,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.companyName + " " + (nodo.code || "") + " " + (nodo.vatID || ""), altura),
                        parent: nodo.parentId,
                        altura
                    });
                }
                // Si el nodo tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
                if (nodo.childs.length != 0) {
                    let childs = this.getValuesHierarchyArray(nodo.childs, list, altura + 1, disabled);
                    result = result.concat(childs);
                }
            }
            // Desactivamos el disabled para los hermanos
            // if (nodo.id == this.formIdTypifiedValue) {
            //     disabled = false;
            // }
        }
        return result;
    }


    getValuesHierarchyArrayOrder(nodos, list = false, altura = 0, disabled = false) {
        let result = [];
        for (let nodo of nodos) {
            // Si es el mismo nodo del formulario, el y sus hijos no podran ser seleccionados como padres
            if (nodo.id == this.formId) {
                disabled = true;
            }
            // No añadimos la jerarquia si no es VAT
            if (nodo.isPos === true) {
                if (list) {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        isVAT: nodo.isVAT,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.name, altura, true),
                        parent: nodo.parentId,
                        altura,
                        disabled
                    });
                } else {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        code: nodo.code,
                        vatID: nodo.vatID,
                        isVAT: nodo.isVAT,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.name, altura),
                        parent: nodo.parentId,
                        altura,
                        disabled
                    });
                }
                // Si el nodo tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
                if (nodo.childs.length != 0) {
                    let childs = this.getValuesHierarchyArrayOrder(nodo.childs, list, altura + 1, disabled);
                    result = result.concat(childs);
                }
            }
            // // Desactivamos el disabled para los hermanos
            // if (nodo.id == this.formIdTypifiedValue) {
            //     disabled = false;
            // }
        }
        return result;
    }

    /**
     * Obtiene la etiqueta con guiones para una opcion de un selector jerarquico o una lista jerarquica
     *
     * @param value
     * @param altura
     * @param list
     * @returns {string}
     */
    getValueHierarchyLabel(value, altura, list = false) {

        let separacion = "";
        let separador = " - ";

        if (list) {
            separador = "----";
        }

        for (let i = 0; i < altura; ++i) {
            separacion += separador;
        }

        return separacion + " " + value;

    }

    /**
     * Devuelve si es un nodo padre.
     *
     * Es un nodo padre si no tiene parentId o el parentId apunta a un elemento que no existe
     *
     * @param nodo
     * @param nodos
     * @returns {boolean}
     */
    isParent(nodo, nodos) {

        let result = false;

        // Si el parentId es null, directamente ya es padre
        if (nodo.parentId == null) {
            result = true;
        } else {

            // Si el parentId no es null, cabe aun la posibilidad de que sea padre, ya sea porque su padre ya no exista

            let parentIdFound = nodos.find(function (obj) {
                return obj.id == nodo.parentId;
            });

            if (parentIdFound === undefined) {
                result = true;
            }

        }

        return result;

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default ClientCacheState;
