import React, { Component } from 'react';
import VsfModal from "../components/listaddons/VsfModal";
import { observer } from "mobx-react";
import withTranslate from "../translator/withTranslate";

@observer
class LeftModalInList extends Component {

    closeModal() {
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        } else {
            this.props.modalState.modalOpen = false;
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <VsfModal
                stateFilter={this.props.modalState}
                isOpen={this.props.isOpen}
                className="list-columns-modal"
                onCloseModal={() => this.closeModal()}
            >
                <div className="modal-push" style={{ display: "block" }}>
                    <div className="modal-head">
                        <div className="row">
                            <div className="col-10 text-left">
                                <div className="modal-title">
                                    <h2 ref={subtitle => this.subtitle = subtitle}>{t(this.props.title)}
                                        <span>{this.props.subtitle}</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="col-2 text-right">
                                <button type="button" onClick={_ => this.closeModal()} className="close"
                                        aria-label="Cerrar"><span className="fas fa-times"></span></button>
                            </div>
                        </div>

                    </div>
                    <div className="modal-body">
                        <div className="mt-2 mb-4">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </VsfModal>
        );

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(LeftModalInList)

