import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import appState from "../../state/AppState";
import {observer} from 'mobx-react';
import {DropTarget} from "react-dnd/lib/index";
import {findDOMNode} from "react-dom";

const Types = {
    ITEM: 'toy'
}
const itemSource = {
    beginDrag(props) {
        console.log("beginDrag");
        console.log({props});
        return {name:props.name, index:props.index};
    },
    /*
    endDrag(props) {
        console.log("endDrag");
        console.log({props});
        let ordersWithName = appState.preventOrders.filter(order=>order.name==props.name);
        console.log("endDrag length:"+ordersWithName.length);
        ordersWithName[0].date="20190101";
    }
    */
}
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}



@observer
class ToySource extends Component {
    render() {
        const { isDragging, connectDragSource, src } = this.props;
        return connectDragSource(
            <div style={{width:200, height:40, backgroundColor:'#aa0000'}}>
                Texto inicial {this.props.name}
            </div>
        )
    }
}
export default DragSource(Types.ITEM, itemSource, collect)(ToySource)