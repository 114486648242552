import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import appState from "../../state/AppState";
import {observer} from 'mobx-react';
import {DropTarget} from "react-dnd/lib/index";
import {findDOMNode} from "react-dom";
import ToySource from "./ToySource";

const Types = {
    ITEM: 'toy'
}


const itemTarget = {
    hover(props, monitor, component) {
        console.log("itemTarget.hover2");
        console.log({props});
        console.log({component});
        if (!component) {
            return null
        }
        let item = monitor.getItem();
        console.log({item});

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        console.log({dragIndex,hoverIndex});
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // dragIndex puede ser 5 y hover 1. Debo poner la posici�n 5 antes de la 1
        let preventOrders = appState.preventOrders;

        // Creo un elemento "provisional". Si al final se cancela el movimiento deber�a quitar el elemento "ghost"
        let currentElementMoving=null;
        for(let i=0;i<preventOrders.length;i++) {
            if (preventOrders[i].name==dragIndex) {
                currentElementMoving = preventOrders[i];
                currentElementMoving.date="2019prov";
                currentElementMoving.type="ghost-moving";
            }
        }

        let newPreventOrders = [];
        for(let i=0;i<preventOrders.length;i++) {
            if (preventOrders[i].name==dragIndex) {
            } else {
                if (preventOrders[i].name==hoverIndex) {
                    newPreventOrders.push(currentElementMoving);
                }
                newPreventOrders.push(preventOrders[i]);
            }
        }
        appState.preventOrders=newPreventOrders;
        console.log("moved");

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()
        console.log({hoverBoundingRect});
    },
    drop(props, monitor, component) {
        console.log("drop");
        // Obtain the dragged item
        const orderFromProps = monitor.getItem();
        console.log("drop=>");
        console.log(orderFromProps);
        console.log({props});
        console.log({component});
    }
}
function collectTarget(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class Toy extends Component {
    render() {
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div>
                <ToySource {...this.props} />
            </div>
        )
    }
}
export default DropTarget(Types.ITEM, itemTarget, collectTarget)(Toy)