import { computed, observable } from 'mobx';
import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost";
import AppLogger from "../util/AppLogger";
import BaseModelState from "./BaseModelState";

class TypifiedState  extends BaseModelState {
    //bloque status WO
    @observable status=[];
    @observable statusDispatcher=[];
    @observable arrStatus=[];
    @observable arrStatusCodes={};
    @observable arrStatusDispatcher=[];

    @computed get statusForSelect(){
        let result=[];
        for(let sta of this.status){
            result.push({value:sta.id, label:sta.value})
        }
        return result;
    }
    @computed get statusDispatcherForSelect(){
        let result=[];
        for(let sta of this.statusDispatcher){
            result.push({value:sta.id, label:sta.value})
        }
        return result;
    }
    async loadStatus() {
        if (this.status.length==0) {
            this.status = await this.loadAndGetTypifiedRowsByType("status");
        }
        if (this.statusDispatcher.length==0) {
            this.statusDispatcher = await this.loadAndGetTypifiedRowsByType("statusDispatcherWorkOrder");
        }
    }

    arrayLoadWorkOrderStatus(){
        let result=[];
        let resultCodes={};
        for(let status of this.status){
            result[status.id]= status;
            resultCodes[status.code]= status;
        }
        this.arrStatus = result;
        this.arrStatusCodes = resultCodes;
    }


    arrayLoadWorkOrderStatusDispatcher(){
        let result=[];
        for(let status of this.statusDispatcher){
            if(result[status.id] == null && status.value !=null){
                result[status.id]= status;
            }
        }
        this.arrStatusDispatcher = result;
    }

    //bloque employeeCapacitation
    @observable capacitation=[];
    @observable arrCapacitation=[];

    @computed get capacitationForSelect(){
        let result=[];
        for(let cap of this.capacitation){
            result.push({value:cap.id, label:cap.value})
        }
        return result;
    }
    async loadCapacitation() {
        if (this.capacitation.length==0) {
            this.capacitation = await this.loadAndGetTypifiedRowsByType("employeeCapacitation");
        }
    }

    arrayLoadCapacitation(){
        let result=[];
        for(let capacitation of this.capacitation){
                result[capacitation.id]= capacitation;
        }
        this.arrCapacitation = result;
    }

    //bloque articlesFamily
    @observable articleFamily=[];
    @observable arrArticleFamily=[];
    @computed get articleFamilyForSelect(){
        let result=[];
        for(let articleFam of this.articleFamily){
            result.push({value:articleFam.id, label:articleFam.value})
        }
        return result;
    }

    arrayLoadArticleFamily(){
        let result=[];
        let vueltas = 0;
        for(let articleFamily of this.articleFamily){
            vueltas++;
            if(result[articleFamily.id] == null && articleFamily.value !=null){
                result[articleFamily.id]= articleFamily.value;
            }
            this.log(articleFamily);
            this.log(vueltas);
        }
        this.arrArticleFamily = result;
    }


    async loadArticleFamily() {
        if (this.articleFamily.length==0) {
            this.articleFamily = await this.loadAndGetTypifiedRowsByType("articleFamily");
        }
    }
    //bloque articleType
    @observable articleType=[];
    @observable arrArticleType=[];

    @computed get articleTypeForSelect(){
        let result=[];
        for(let type of this.articleType){
            result.push({value:type.id, label:type.value})
        }
        return result;
    }
    async loadArticleType() {
        if (this.articleType.length==0) {
            this.articleType = await this.loadAndGetTypifiedRowsByType("articleType");
        }
    }

    arrayLoadArticleType(){
        let result=[];
        for(let articleType of this.articleType){

            if(result[articleType.id] == null && articleType.value !=null){
                result[articleType.id]= articleType.value;
            }
        }
        this.arrArticleType = result;
    }


    //bloque articlesSubfamily
    @observable articleSubfamily=[];
    @observable arrArticleSubfamily=[];

    arrayLoadArticleSubFamily(){
        let result=[];
        for(let articleSubfamily of this.articleSubfamily){

            if(result[articleSubfamily.id] == null && articleSubfamily.value !=null){
                result[articleSubfamily.id]= articleSubfamily.value;
            }
        }
        this.arrArticleSubfamily = result;
    }

    @computed get articleSubfamilyForSelect(){
        let result=[];
        for(let articleSubfam of this.articleSubfamily){
            result.push({value:articleSubfam.id, label:articleSubfam.value})
        }
        return result;
    }
    async loadArticleSubfamily() {
        if (this.articleSubfamily.length==0) {
            this.articleSubfamily = await this.loadAndGetTypifiedRowsByType("articleSubfamily");
        }
    }
    // bloque status OrderModel
    @observable orderStatus=[];
    @observable arrOrderStatus={};


    @computed get orderStatusForSelect(){
        let result=[];
        for(let status of this.orderStatus){
            result.push({value:status.id, label:status.value})
        }
        return result;
    }
    async loadOrderStatus() {
        if (this.orderStatus.length==0) {
            this.orderStatus = await this.loadAndGetTypifiedRowsByType("orderStatus");
        }
    }

    arrayLoadOrderStatus(){
        let result=[];
        for(let orderStatus of this.orderStatus){
            if(result[orderStatus.id] == null && orderStatus.value !=null){
                result[orderStatus.id]= orderStatus;
            }
        }
        this.arrOrderStatus = result;
    }

    // bloquestatus isGroup
    @observable groups=[];
    @computed get groupsForSelect(){
        let result=[];
        for(let group of this.groups){
            result.push({value:group.id, label:group.value})
        }
        return result;
    }
    async loadGroup() {
        if (this.groups.length==0) {
            this.groups = await this.loadAndGetTypifiedRowsByType("groups");
        }
    }

    // bloquestatusAsset
    @observable statusAssets=[];
    @computed get statusAssetsForSelect(){
        let result=[];
        for(let staAsset of this.statusAssets){
            result.push({value:staAsset.id, label:staAsset.value})
        }
        return result;
    }
    async loadAssetStatus() {
        if (this.statusAssets.length==0) {
            this.statusAssets = await this.loadAndGetTypifiedRowsByType("statusAssets");
        }
    }
    // bloque Emploiment
    @observable emploiments=[];
    @observable arrEmploiments=[];
    async loadEmploiments() {
        if (this.emploiments.length==0) {
            this.emploiments = await this.loadAndGetTypifiedRowsByType("emploiments");
        }
    }

    arrayLoadEmploimets(){
        let result=[];
        for(let option of this.emploiments){
            if(result[option.id] == null && option.value !=null){
                result[option.id]= option.value;
            }
        }
        this.arrEmploiments = result;
    }

    @computed get emploimentsForSelect(){
        let result=[];
        for(let emploiment of this.emploiments){
            result.push({value:emploiment.id, label:emploiment.value})
        }
        return result;
    }

    // bloque UserROl
    @observable roles=[];
    @observable arrRoles=[];
    @observable arrRolesByCode=[];
    async loadRolUser() {
        if (this.roles.length==0) {
            this.roles = await this.loadAndGetTypifiedRowsByType("rolUser");
        }
    }

    @computed get rolesForSelect(){
        let result=[];
        for(let role of this.roles){
            result.push({value:role.id, label:role.value})
        }
        return result;
    }


    arrayLoadRoles(){
        let result={};
        let resultByCode={};
        for(let option of this.roles){
                result[option.id]= option;
                resultByCode[option.code]= option

        }
        this.arrRolesByCode= resultByCode
        this.arrRoles = result;
    }

    //manufacturer
    @observable manufacturer=[];
    @observable arrManufacturer=[];

    @computed get manufacturerForSelect(){
        let result=[];
        for(let manuf of this.manufacturer){
            result.push({value:manuf.id, label:manuf.value})
        }
        return result;
    }
    async loadManufacturer() {
        if (this.manufacturer.length==0) {
            this.manufacturer = await this.loadAndGetTypifiedRowsByType("manufacturer");
        }
    }
    arrayLoadManufacturer(){
        let result=[];
        for(let manufacturer of this.manufacturer){
            if(result[manufacturer.id] == null && manufacturer.value !=null){
                result[manufacturer.id]= manufacturer.value;
            }
        }
        this.arrManufacturer = result;
    }

    //model
    @observable model=[];
    @observable arrModel=[];

    @computed get modelForSelect(){
        let result=[];
        for(let model of this.model){
            result.push({value:model.id, label:model.value})
        }
        return result;
    }
    async loadModel() {
        if (this.model.length==0) {
            this.model = await this.loadAndGetTypifiedRowsByType("model");
        }
    }
    arrayLoadModel(){
        let result=[];
        for(let model of this.model){
            if(result[model.id] == null && model.value !=null){
                result[model.id]= model.value;
            }
        }
        this.arrModel = result;
    }

    //bloque timeZones
    @observable timeZones=[];
    @computed get timeZonesForSelect(){
        let result=[];
        for(let timeZones of this.timeZones){
            result.push({value:timeZones.id, label:timeZones.value})
        }
        return result;
    }
    async loadTimeZones() {
        if (this.timeZones.length==0) {
            this.timeZones = await this.loadAndGetTypifiedRowsByType("timeZones");
        }
    }
   // bloque locale user
    @observable locales=[];
    @computed get localesForSelect(){
        let result=[];
        for(let locales of this.locales){
            result.push({value:locales.id, label:locales.value})
        }
        return result;
    }
    async loadLocale() {
        if (this.locales.length==0) {
            this.locales = await this.loadAndGetTypifiedRowsByType("locales");
        }
    }
    // Bloque zones
    @observable zones = [];
    @observable arrZones = [];


    @computed get zonesForSelect() {
        let result=[];
        for(let zone of this.zones) {
            result.push( {value:zone.id, label:zone.value})
           // result.push( {value:c++, label:zone.value})
        }
        return result;
    }
    async loadZones() {
        if (this.zones.length==0) {
            this.zones = await this.loadAndGetTypifiedRowsByType("zone");
        }
    }
    arrayLoadZones(){
        let result=[];
        for(let zones of this.zones){
                result[zones.id]= zones;
        }
        this.arrZones = result;
    }

    // Bloque manufacturer ref
    @observable manufacturerRef = [];

    @computed get manufacturerRefForSelect() {
        let result=[];
        for(let mref of this.manufacturerRef) {
            result.push( {value:mref.id, label:mref.value})
        }
        return result;
    }
    async loadManufacturerRef() {
        if (this.manufacturerRef.length==0) {
            this.manufacturerRef = await this.loadAndGetTypifiedRowsByType("manuref");
        }
    }

    // Bloque manufacturer ref
    @observable priority = [];

    @computed get priorityForSelect() {
        let result=[];
        for(let mref of this.priority) {
            result.push( {value:mref.id, label:mref.value})
        }
        return result;
    }
    async loadPriority() {
        if (this.priority.length==0) {
            this.priority = await this.loadAndGetTypifiedRowsByType("priority");
        }
    }
    //sympthomid
    @observable symptom=[];
    @computed get symptomForSelect(){
        let result=[];
        for(let sym of this.symptom){
            result.push({value:sym.id, label:sym.value})
        }
        return result;
    }
    async loadSymptom() {
        if (this.symptom.length==0) {
            this.symptom = await this.loadAndGetTypifiedRowsByType("diagnosis");
        }
    }
    // Bloque stoeId
    @observable storeId = [];

    @computed get storeIdForSelect() {
        let result=[];
        for(let sto of this.storeId) {
            result.push( {value:sto.id, label:sto.value})
        }
        return result;
    }
    async loadStoreId() {
        if (this.storeId.length==0) {
            this.storeId = await this.loadAndGetTypifiedRowsByType("storeId");
        }
    }

    // Bloque relationships
    @observable relationships = [];
    @observable arrRelationships = [];

    @computed get relationshipsForSelect() {
        let result=[];
        for(let relationship of this.relationships) {
            result.push( {value:relationship.id, label:relationship.value})
        }
        return result;
    }

    async loadRelationships() {
        if (this.relationships.length==0) {
            this.relationships = await this.loadAndGetTypifiedRowsByType("relationship");
        }
    }

    arrayLoadRelationships(){
        let result=[];
        for(let relationships of this.relationships){
            if(result[relationships.id] == null && relationships.value !=null){
                result[relationships.id]= relationships.value;
            }
        }
        this.arrRelationships = result;
    }

    // Bloque Tipos de Codigos de Equipo
    @observable assetCodes = [];
    @observable arrAssetCodes = [];


    @computed get assetCodesForSelect() {
        let result=[];
        for(let assetCode of this.assetCodes) {
            result.push( {value:assetCode.id, label:assetCode.value})
        }
        return result;
    }
    async loadAssetCodes() {
        if (this.assetCodes.length==0) {
            this.assetCodes = await this.loadAndGetTypifiedRowsByType("assetCodes");
        }
    }
    arrayLoadAssetCodes(){
        let result=[];
        for(let assetCodes of this.assetCodes){

            if(result[assetCodes.id] == null && assetCodes.value !=null){
                result[assetCodes.id]= assetCodes.value;
            }
        }
        this.arrAssetCodes = result;
    }

    // Bloque statusArticles
    @observable statusArticles = [];
    @observable arrStatusArticles = [];

    @computed get statusArticlesForSelect() {
        let result=[];
        for(let statusArticle of this.statusArticles) {
            result.push( {value:statusArticle.id, label:statusArticle.value})
        }
        return result;
    }
    arrayLoadArticlesStatus(){
        let result=[];
        for(let statusArticle of this.statusArticles){

            if(result[statusArticle.id] == null && statusArticle.value !=null){
                result[statusArticle.id]= statusArticle.value;
            }
        }
        this.arrStatusArticles = result;
    }

    async loadStatusArticle() {
        if (this.statusArticles.length==0) {
            this.statusArticles = await this.loadAndGetTypifiedRowsByType("statusArticles");
        }
    }

    // Bloque type intervention
    @observable workOrdersType = [];
    @observable arrWorkOrderType = [];
    @observable arrWorkOrderTypeCodes = [];
    @observable workOrderTypeSelected = "";

    @computed get workOrderTypeForSelect() {
        let result=[];
        for(let typeIntervention of this.workOrdersType) {
            if(this.isParent(typeIntervention, this.workOrdersType)){
                result.push( {value:typeIntervention.id, label:typeIntervention.value})
            }
        }
        return result;
    }

    async loadWorkOrderType() {
        if (this.workOrdersType.length===0) {
            this.workOrdersType = await this.loadAndGetTypifiedRowsByType("workOrderType");
        }
    }

    arrayLoadWorkOrderType(){
        let result=[];
        for(let workOrdersType of this.workOrdersType){

            if(result[workOrdersType.id] == null && workOrdersType.value !=null){
                result[workOrdersType.id]= workOrdersType.value;
            }
        }
        this.arrWorkOrderType = result;
    }

    arrayLoadWorkOrderTypeCodes(){
        let result=[];
        for(let workOrdersType of this.workOrdersType){

            if(result[workOrdersType.id] == null && workOrdersType.value !=null){
                result[workOrdersType.id]= workOrdersType.code;
            }
        }
        this.arrWorkOrderTypeCodes = result;
    }

    // Bloque subTypeWorkOrders
    @observable subTypeWorkOrders = [];
    @observable arrSubTypeWorkOrders = [];
    @observable filterSubTypeWorkOrders = false;

    @computed get subTypeWorkOrdersForSelect() {
        let result=[];
        for(let subTypeWorkOrder of this.subTypeWorkOrders) {
            if(this.filterSubTypeWorkOrders){
                if(!this.isParent(subTypeWorkOrder, this.subTypeWorkOrders)){
                    result.push( {value:subTypeWorkOrder.id, label:subTypeWorkOrder.value})
                }
            } else {
                if(subTypeWorkOrder.parentId == this.workOrderTypeSelected){
                    result.push( {value:subTypeWorkOrder.id, label:subTypeWorkOrder.value})
                }
            }
        }
        return result;
    }

    async loadSubTypeWorkOrder() {
        if (this.subTypeWorkOrders.length==0) {
            this.subTypeWorkOrders = await this.loadAndGetTypifiedRowsByType("workOrderType");
        }
    }

    arrayLoadsubTypeWorkOrders(){
        let result=[];
        for(let subTypeWorkOrders of this.subTypeWorkOrders){

            if(result[subTypeWorkOrders.id] == null && subTypeWorkOrders.value !=null){
                result[subTypeWorkOrders.id]= subTypeWorkOrders.value;
            }
        }
        this.arrSubTypeWorkOrders  = result;
    }

    // Bloque assetType
    @observable assetTypes = [];
    @observable arrAssetTypes = [];
    @observable arrAssetTypesCodes = [];
    @observable assetTypeSelected = "";

    @computed get assetTypesForSelect() {
        let result=[];
        for(let assetType of this.assetTypes) {
            if(this.isParent(assetType, this.assetTypes)) {
                result.push({value: assetType.id, label: assetType.value})
            }
        }
        return result;
    }

    async loadAssetType() {
        if (this.assetTypes.length==0) {
            this.assetTypes = await this.loadAndGetTypifiedRowsByType("assetType");
        }
    }

    arrayLoadAssetType(){
        let result=[];
        for(let assetTypes of this.assetTypes){

            if(result[assetTypes.id] == null && assetTypes.value !=null){
                result[assetTypes.id]= assetTypes.value;
            }
        }
        this.arrAssetTypes = result;
    }
    arrayLoadAssetTypeCodes(){
        let result=[];
        for(let assetTypes of this.assetTypes){

            if(result[assetTypes.id] == null && assetTypes.value !=null){
                result[assetTypes.id]= assetTypes.code;
            }
        }
        this.arrAssetTypesCodes = result;
    }

    //asset Subtype
    @observable assetSubtypes=[];
    @observable arrAssetSubtypes=[];

    @computed get assetSubtypeForSelect(){
        let result=[];
        for(let assetSubtype of this.assetSubtypes){
            if(assetSubtype.parentId == this.assetTypeSelected) {
                result.push({value: assetSubtype.id, label: assetSubtype.value})
            }
        }
        return result;
    }

    async loadAssetSubtype() {
        if (this.assetSubtypes.length==0) {
            this.assetSubtypes = await this.loadAndGetTypifiedRowsByType("assetType");
        }
    }
    arrayLoadAssetSubType(){
        let result=[];
        for(let assetSubtypes of this.assetSubtypes){
            if(result[assetSubtypes.id] == null && assetSubtypes.value !=null){
                result[assetSubtypes.id]= assetSubtypes.value;
            }
        }
        this.arrAssetSubtypes = result;
    }

    //bloque chain (cadena)
    @observable chains=[];
    @observable arrChains=[];

    @computed get chainsForSelect(){
        let result=[];
        for(let chains of this.chains){
            result.push({value: chains.id, label: chains.value})
        }
        return result;
    }

    async loadChains() {
        if (this.chains.length==0) {
            this.chains = await this.loadAndGetTypifiedRowsByType("chainPos");
        }
    }
    arrayLoadChains(){
        let result=[];
        for(let chains of this.chains){
            if(result[chains.id] == null && chains.value !=null){
                result[chains.id]= chains.value;
            }
        }
        this.arrChains = result;
    }


    // Bloque vehicles
    @observable vehicles = [];
    @observable arrVehicles = [];



    @computed get vehiclesForSelect() {
        let result=[];
        let c = 1;
        for(let vehicle of this.vehicles) {
            //result.push( {value:vehicle.code, label:vehicle.value})
            result.push( {value:vehicle.id, label:vehicle.value})
        }
        return result;
    }
    async loadVehicles() {
        if (this.vehicles.length==0) {
            this.vehicles = await this.loadAndGetTypifiedRowsByType("vehicles");
        }
    }
    arrayLoadVehicles(){
        let result=[];
        for(let vehicles of this.vehicles){

            if(result[vehicles.id] == null && vehicles.value !=null){
                result[vehicles.id]= vehicles.value;
            }
        }
        this.arrVehicles = result;
    }

    /**
     * Metodo para sacar los registros del tipo pasado como parametro
     * @param type
     * @returns {Promise<null>}
     */
    async loadAndGetTypifiedRowsByType(type = null) {
        let query = gql`
            query typifiedValues($query:QueryInputType){
                typifiedValues(query:$query) {
                    id,
                    code,
                    value,
                    parentId,
                    icon,
                    color,
                    image,
                  
                }
            }
        `;

        let variables;

        if(type != null){
            variables={
                query: {
                    filters:[
                        {fieldName:"type",fieldValue:type}
                    ]
                }
            }
        } else {
            variables={
                query: {
                    filters:[

                    ]
                }
            }
        }

        let apolloProxy = new ApolloProxy(apoloClient);
        let resultQuery = await apolloProxy.graphQuery({query,variables});
        let newData=resultQuery.data;
        let result=[];
        if (newData.typifiedValues!=null) {
            result = newData.typifiedValues;
        }
        return result;
    }

    /**
     * Variable que almacena los typifed que vienen de la BBDD directamente
     * @type {Array}
     */
    @observable typifiedValuesRaw = [];

    /**
     * Id del elemento que esta siendo editado en el formulario
     *
     * @type {number}
     */
    @observable formIdTypifiedValue = 0;

    /**
     * Llama al metodo para obtener los typifed de la BBDD
     *
     * @param type
     */
    async loadTypifiedValues(type){
        this.typifiedValuesRaw = await this.loadAndGetTypifiedRowsByType(type);
    }

    /**
     * Obtiene el array de typifed para select jerarquico piramidal creando el arbol e iterando en preorden posteriormente
     *
     * @returns {Array}
     */
    @computed get typifiedValuesForSelectPiramidal(){

        this.log("typifiedValuesForSelectPiramidal() =>");

        // Primero, obtiene el arbol ordenado de los typifed
        let typifiedValuesHierarchy = this.getTypifiedValuesHierarchy(this.typifiedValuesRaw);

        // Segundo, obtenemos el array en orden
        let typifiedValuesHierarchyArray = this.getTypifiedValuesHierarchyArray(typifiedValuesHierarchy);

        return typifiedValuesHierarchyArray;

    }

    /**
     * Obtiene el array de typifed para select jerarquico lineal creando el arbol e iterando en preorden posteriormente
     *
     * @returns {Array}
     */
    @computed get typifiedValuesForSelectLineal(){

        this.log("typifiedValuesForSelectLineal() =>");

        // Primero, obtiene el arbol ordenado de los typifed
        let typifiedValuesHierarchy = this.getTypifiedValuesHierarchy(this.typifiedValuesRaw);

        // Segundo, obtenemos el array en orden
        let typifiedValuesHierarchyArray = this.getTypifiedValuesHierarchyArray(typifiedValuesHierarchy, false);

        return typifiedValuesHierarchyArray;

    }

    /**
     * Obtiene el array de typifed para listas jerarquicas creando el arbol e iterando en preorden posteriormente
     *
     * @returns {Array}
     */
    @computed get typifiedValuesForList(){

        this.log("typifiedValuesForList() =>");

        // Primero, obtiene el arbol ordenado de los typifed
        let typifiedValuesHierarchy = this.getTypifiedValuesHierarchy(this.typifiedValuesRaw);

        // Segundo, obtenemos el array en orden
        let typifiedValuesHierarchyArray = this.getTypifiedValuesHierarchyArray(typifiedValuesHierarchy, false, true);

        return typifiedValuesHierarchyArray;

    }

    /**
     * Metodo que recorre los nodos padres para llamar recursivamente al metodo que creara los hijos
     *
     * @param nodos
     * @returns {Array}
     */
    getTypifiedValuesHierarchy(nodos){

        let result = [];

        let nodosLibres = nodos.slice(0);

        for(let nodo of nodos) {

            // Si es un nodo padre, construimos el arbol hijo
            if(this.isParent(nodo, nodos)){

                // Quitamos el nodo padre de la lista de nodos libres
                nodosLibres = nodosLibres.filter(function( obj ) {
                    return obj.id !== nodo.id;
                });

                result.push({id: nodo.id, code: nodo.code, value: nodo.value, parentId: nodo.parentId, childs: this.getTypifiedValuesHierarchyChilds(nodo.id, nodosLibres)})

            }

        }

        return result;

    }

    /**
     * Metodo que a partir de un nodo padre, busca nodos hijos y los coloca recursivamente
     *
     * @param parentId
     * @param nodos
     * @returns {Array}
     */
    getTypifiedValuesHierarchyChilds(parentId, nodos){

        let childs = [];

        // Estos seran los nodos libres
        let nodosLibres = nodos.filter(function( obj ) {
            return obj.parentId != parentId;
        });

        for (let nodo of nodos){
            // Si es un nodo hijo, lo agregamos al array de hijos
            if(nodo.parentId == parentId){
                childs.push({id: nodo.id, code: nodo.code, value: nodo.value, parentId: nodo.parentId, childs: this.getTypifiedValuesHierarchyChilds(nodo.id, nodosLibres)})
            }
        }

        return childs;

    }

    /**
     * A partir del arbol ordenado de typified, obtiene el array en preorden recursivamente para ser mostrado en el select
     *
     * @param nodos
     * @param piramidal
     * @param list
     * @param altura
     * @param disabled
     * @param label
     *
     * @returns {Array}
     */
    getTypifiedValuesHierarchyArray(nodos, piramidal = true, list = false, altura = 0, disabled = false, label = ""){

        let result = [];

        for (let nodo of nodos){

            // Si es el mismo nodo del formulario, el y sus hijos no podran ser seleccionados como padres
            if(nodo.id == this.formIdTypifiedValue){
                disabled = true;
            }

            let newLabel = label;

            // Añadimos el nodo padre al array de retorno dependiendo si es para una lista o un select
            if(list){
                result.push({id: nodo.id, code: nodo.code, value: nodo.value, label: this.getTypifiedValueHierarchyLabel(nodo.code, altura, true), parent: nodo.parentId, altura, disabled});
            } else {

                // Distinguimos entre select piramidal o lineal
                if(piramidal){
                    result.push({id: nodo.id, code: nodo.code, value: nodo.id, label: this.getTypifiedValueHierarchyLabel(nodo.value, altura), parent: nodo.parentId, altura, disabled});
                } else {

                    // Si es un nodo padre, en un selector lineal no lo incluimos, unicamente creamos la label para pasarla a sus hijos
                    if(newLabel == ""){
                        newLabel = nodo.value;
                    } else {
                        newLabel += " → " + nodo.value;
                        result.push({id: nodo.id, code: nodo.code, value: nodo.id, label: newLabel, parent: nodo.parentId, altura, disabled});
                    }

                }

            }

            // Si el nodo tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
            if(nodo.childs.length != 0){
                let childs = this.getTypifiedValuesHierarchyArray(nodo.childs, piramidal, list, altura + 1, disabled, newLabel);
                result = result.concat(childs);
            }

            // Desactivamos el disabled para los hermanos
            if(nodo.id == this.formIdTypifiedValue){
                disabled = false;
            }

        }

        return result;

    }

    /**
     * Obtiene la etiqueta con guiones para una opcion de un selector jerarquico o una lista jerarquica
     *
     * @param value
     * @param altura
     * @param list
     *
     * @returns {string}
     */
    getTypifiedValueHierarchyLabel(value, altura, list = false){

        let separacion = "";
        let separador = " - ";

        if(list) {
            separacion = "";
        } else {
            for (let i = 0; i < altura; ++i){
                separacion += separador;
            }
            separacion += " ";
        }

        return separacion + value;

    }

    /**
     * Devuelve si es un nodo padre.
     *
     * Es un nodo padre si no tiene parentId o el parentId apunta a un elemento que no existe
     *
     * @param nodo
     * @param nodos
     * @returns {boolean}
     */
    isParent(nodo, nodos){

        let result = false;

        // Si el parentId es null, directamente ya es padre
        if(nodo.parentId == null){
            result = true;
        } else {

            // Si el parentId no es null, cabe aun la posibilidad de que sea padre, ya sea porque su padre ya no exista

            let parentIdFound = nodos.find(function(obj) {
                return obj.id == nodo.parentId;
            });

            if(parentIdFound === undefined){
                result = true;
            }

        }

        return result;

    }

    /**
     * Obtiene si el tipo de typifed value se puede ordenar jerarquicamente
     *
     * @returns {boolean}
     */
    isTypifiedValueHierarchy(type){

        let result = false;

        if(type == "workOrderType" || type == "assetType"){
            result = true;
        }

        return result;

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default TypifiedState;
