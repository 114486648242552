import Model from "./Model";
import AbstractModel from "./AbstractModel";

export default class DocumentModel extends AbstractModel {

    id;
    size;
    url;
    field;
    urlSource;
    createdAt;
    name;

    nameMainType="document";
    graphFindByIdOperation="document";
    graphFindOperation="documentsConnection";

    getArrayFields() {
        let resultBase=super.getArrayFields();
        let result = {
            ...resultBase,
            "size":"",
            "urlSource":"",
            "field":"",
            "url": { readonly: true },
            "name": { readonly: true },
           // "url":{readonly:true},
        };
        return result;
    }

}
