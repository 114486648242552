import React, { Component } from 'react'

import appState from "../state/AppState";

import LoadingBar from 'react-top-loading-bar'
import {observer} from "mobx-react";

@observer
export default class VsfLoadingBar extends Component {
    state = {
        loadingBarProgress: 0
    }

    add = value => {
        appState.loadingBarState.loadingBarProgress += value;
    }

    complete = () => {
        appState.loadingBarState.loadingBarProgress =100;
    }

    onLoaderFinished = () => {
        appState.loadingBarState.loadingBarProgress =0;
    }

    render() {
        return (
            <div style={{"position":"fixed", "top":0, "left":"0"}}>
                <LoadingBar
                    progress={appState.loadingBarState.loadingBarProgress}
                    height={3}
                    color="#E51227"
                    onLoaderFinished={() => this.onLoaderFinished()}
                />
            </div>
        )
    }
}