import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute } from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import withTranslate from "../../translator/withTranslate";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import Select2Component from "../../components/fields/Select2Component";
import AppLogger from "../../util/AppLogger";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import { observable } from "mobx";
import InputColorComponent from "../../components/fields/InputColorComponent";

@observer
class TypifiedValueForm extends BaseForm {

    nameMainType = "typifiedValue";
    @observable type = "";

    constructor(props) {
        super(props);
        this.log("TypifiedValueForm.constructor()");
        this.graphData.data[this.nameMainType] = {};
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();

        const params = new URLSearchParams(this.props.location.search);

        // Si no tenemos en los parametros el type, es que es un registro ya guardado y obtenemos su informacion
        if (params.get('type') == null) {
            appState.typifiedState.formIdTypifiedValue = this.getFormId();
            let typifiedValue = this.graphDataMainType;
            this.type = typifiedValue.type;
        } else {
            // Si es un nuevo elemento, obtenemos el type de parametros y dejamos a 0 el ID actual
            // de formulario para que no bloquee ninguna opcion del selector jerarquicco
            appState.typifiedState.formIdTypifiedValue = 0;
            this.type = params.get('type');
        }

        await appState.typifiedState.loadTypifiedValues(this.type);
    }

    getModelQuery() {
        return new TypifiedValueModel();
    }

    /**
     * Relleno el campo type desde la query (si el id es vacio)
     */
    postFillInputVariables(variables) {
        let id = this.getFormId();
        if (id == null || id === "") {
            const params = new URLSearchParams(this.props.location.search);
            let type = params.get('type');
            if (type === "" || type == null) {
                type = "-1";
            }
            variables["type"] = type;
        }
        //console.log({variables});
    }

    /**
     * Actualiza el select jerarquico
     */
    reloadTypifiedValues() {
        appState.typifiedState.loadTypifiedValues(this.type);
    }

    /**
     * Metodo que tras actualizar los valores del typified, actualiza el select
     *
     * @param e
     */
    async handleFormSubmitTypifiedValue(e) {
        await this.handleFormSubmit(e);
        this.reloadTypifiedValues();
    }

    render() {
        let id = this.getFormId();
        let { t } = this.props;
        const params = new URLSearchParams(this.props.location.search);
        const type = params.get('type');
        let typifiedValue = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();

        if (typifiedValue == null) {
            typifiedValue = {};
        }
        let typeTipifiedValue = typifiedValue.type;
        if (typeTipifiedValue == null) {
            typeTipifiedValue = this.type;
        }
        //console.log(this.graphStatus.toJS());
        let typifiedValueModel = new TypifiedValueModel();
        return (
            <React.Fragment>
                <PageTitle
                    title={t("Maestros") + " - " + t(typifiedValueModel.getTextFromType(typeTipifiedValue) || t("Nuevo"))}
                />
                <form
                    className="model-form"
                    name="formulario"
                    novalidate
                    onSubmit={(e) => this.handleFormSubmitTypifiedValue(e)}
                >
                    <div className={'card mb-3'}>

                        <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                           error={this.graphStatus.mutationError}
                                           working={this.graphStatus.networkWorking}
                        />
                        <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                            formError={this.graphStatus.formError}
                        />


                        {errorsMapped[""] != null ?
                            <div class="alert alert-danger" role="alert">
                                {errorsMapped[""].map(error => <div>{error.message}</div>)}
                            </div>
                            :
                            null
                        }
                        <FormWrapper>
                            <GroupOfField icon="fas fa-list"
                                          title={t(typifiedValueModel.getTextFromType(typeTipifiedValue))}>
                                <InputTypeComponent
                                    value={typifiedValue.code}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name="code"
                                    title={t("Código")}
                                    placeholder=""
                                    classGroup="col-4"
                                    errors={errorsMapped.code}
                                />
                                <InputTypeComponent
                                    value={typifiedValue.value}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name="value"
                                    title={t("Valor")}
                                    placeholder=""
                                    classGroup="col-4"
                                    errors={errorsMapped.value}
                                />

                                <InputColorComponent
                                    value={typifiedValue.color}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name="color"
                                    title={t("Color")}
                                    placeholder=""
                                    classGroup="col-4"
                                    errors={errorsMapped.color}
                                />

                                {/*<InputTypeComponent*/}
                                {/*value={typifiedValue.image}*/}
                                {/*onChange={(e) => this.updateInputEvent(e)}*/}
                                {/*name="image"*/}
                                {/*title={t("Imagen")}*/}
                                {/*placeholder=""*/}
                                {/*classGroup="col-4"*/}
                                {/*errors={errorsMapped.image}*/}
                                {/*/>*/}
                                <InputTypeComponent
                                value={typifiedValue.icon}
                                onChange={(e) => this.updateInputEvent(e)}
                                name="icon"
                                title={t("Icono")}
                                placeholder=""
                                classGroup="col-4"
                                errors={errorsMapped.icon}
                                />
                                {appState.typifiedState.isTypifiedValueHierarchy(this.type) &&
                                <Select2Component
                                    value={typifiedValue.parentId}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"parentId"}
                                    title={t("Pertenece a")}
                                    classGroup={"col-md-4"}
                                    options={appState.typifiedState.typifiedValuesForSelectPiramidal}
                                    errors={errorsMapped.model}
                                />
                                }
                            </GroupOfField>
                        </FormWrapper>
                    </div>
                    <FormButtons id={id} formStatus={this.graphStatus}></FormButtons>
                </form>
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withApollo(withTranslate(TypifiedValueForm, "TypifiedValueForm"));
