import {observable} from 'mobx';
import util from "../util/Util";
import BaseModelState from "./BaseModelState";
import AssetCodesModel from "../models/AssetCodesModel";


class AssetCodesState extends BaseModelState{

    @observable assetCodes=[];
    async reloadAssetCodesFromAssetId(assetId) {
        console.log("AssetCodesState.reloadContactsFromOrderId:"+assetId);
        let assetCodesQuery = new AssetCodesModel();
        assetCodesQuery.assetId = assetId;
        if (util.hasValue(assetCodesQuery.assetId)) {
            let result = await assetCodesQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.assetCodes = result;
        }
    }


}

export default AssetCodesState;
