import AbstractModel from "./AbstractModel";
import WorkOrderModel from "./WorkOrderModel";
import DocumentModel from "./DocumentModel";
import ClientModel from "./ClientModel";
import util from "../util/Util";
import ContactModel from "./ContactModel";


export default class OrderModel extends AbstractModel {
    clientId;
    clientName;
    contactId;
    requestContactId;
    code;
    comments;
    createdAt;
    createdBy;
    generalDescription;
    id;
    status;
    updatedAt;
    updatedBy;
    weekNumber;
    orderPreventiveId;
    hourPreventive;
    dayNumber;
    workOrders;
    scheduleVisitId;
    scheduledDate;
    nameMainType = "order";
    graphFindByIdOperation = "order";
    graphFindOperation = "ordersConnection";
    graphExportOperation = "ordersExport";


    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "clientId": { label: "Cliente" },
            "contactId": { label: "Contacto" },
            "requestContactId": { label: "Solicitante" },
            "weekNumber": "",
            "hourPreventive": "",
            "orderPreventiveId": "",
            "dayNumber": "",
            "scheduleVisitId": "",
            "scheduledDate": "",
            "code": { label: 'Número Pedido' },
            "comments": { label: "Comentarios" },
            "createdAt": { readonly: true, label: "Creado" },
            "createdBy": { readonly: true, label: "Creado por" },
            "status": { label: "Estado" },
            "updatedAt": { readonly: true, label: "Actualizado en" },
            "updatedBy": { readonly: true, label: "Actualizado por" },
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        this.addRelatedTableClients(result, ClientModel);
        this.addRelatedTableContacts(result, ContactModel);
        this.addRelatedTableRequestContacts(result, ContactModel);
        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["code","scheduledDate", ...baseFields];
    }

    async getWorkOrdersFromOrderId(orderId) {
        console.log("WorkOrderState.reloadContactsFromClientId clientId:" + orderId);
        let workOrderQuery = new WorkOrderModel();
        workOrderQuery.orderId = orderId;
        console.log('order id ====>', workOrderQuery.orderId)
        if (util.hasValue(workOrderQuery.orderId)) {
            let result = await workOrderQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            return result;
        }
    }

}

