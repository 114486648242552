import React from 'react';
import { observer } from 'mobx-react';
import AppLogger from "../util/AppLogger";
import BaseForm from "../pages/base/BaseForm";
import ChangeModel from "../models/ChangeModel";
import util from "../util/Util";
import appState from '../state/AppState';
import withTranslate from "../translator/withTranslate";

@observer
class HistoryComponent extends BaseForm {

    constructor(props) {
        super(props);
        this.state = {
            changes: [],
            historyVisible: true,
            errorFormulario: false
        };
    }

    async reloadForm() {
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("componentDidMount");
        await this.processHistory();

    }

    showItemHistory(index) {
        this.log("showItemHistory =>");
        let changes = this.state.changes;
        changes[index].visible = !changes[index].visible;
        this.setState({
            changes
        });
    };

    showHistory() {
        this.log("showHistory =>");
        this.setState({
            historyVisible: !this.state.historyVisible
        });
    };


    async processHistory() {
        this.log("processHistory =>");
        let modelo = this.props.modelo;
        let changeQuery = new ChangeModel();
        changeQuery.modelName = modelo.getNameModelInDotNet();
        changeQuery.modelId = this.props.id;
        changeQuery.orderBy = "id";
        changeQuery.orderMode = "DESC";
        let encontrado = await changeQuery.find();
        let changes = [];
        let tamanyo = 10;
        for (let i = 0; i < tamanyo; i++) {
            if (encontrado[i] != null) {
                if (encontrado[i].fieldsChanged != "")
                    changes.push(encontrado[i]);
                else
                    tamanyo++;
            }
        }
        // LO AÑADIMOS AL ESTADO
        this.setState({
            changes,
            historyVisible: true
        });
    }

    getTitle(nameField) {
        let model = this.props.modelo;
        let result = nameField.replace(/\b\w/g, l => l.toLowerCase());
        if (model != null) {
            result = model.getLabelFromFieldName(nameField.replace(/\b\w/g, l => l.toLowerCase()));
        }
        return result;
    }


    render() {
        this.log("render() =>");
        let changes = this.state.changes;
        const { t, i18n } = this.props;

        return (
            <div className="col-12 history-container">
                {this.state.historyVisible &&
                <div className="history-list-container">
                    <div className="history-list-rows">
                        {changes.map((change, index) => (
                            <div className="history-list-item">
                                <div className="row history-list-item-title"
                                     onClick={this.showItemHistory(index)}>
                                    <div
                                        className="col-md-9 history-list-item-updated">{change.action == "U" ? t('Actualizado por') + " " : t('Creado por') + " "}
                                        <strong>{change.createdBy != null ? appState.userCacheState.getUserById(change.createdBy).email : ""}</strong> el <strong>{change.createdAt}</strong>
                                    </div>
                                    {change.action == "U" &&
                                    <div
                                        className="col-md-3 history-list-item-changes"> {Object.keys(JSON.parse(change.jsonInitial)).length} {t("cambios")}
                                        <i
                                            className="fa fa-angle-right"> </i></div>
                                    }
                                </div>
                                {change.visible &&
                                <div className="history-change-container">
                                    {change.action == "U" &&
                                    <div className="history-change previous">
                                        <span
                                            className="history-change-title history-change-item previous">{t("Anterior")}</span>
                                        {Object.entries(JSON.parse(change.jsonInitial)).map((value) => (
                                            <>
                                                {util.perteneceLista(value[0], change.fieldsChanged) &&
                                                <div className="history-change-item">
                                                    <span
                                                        className="history-change-item-text">{this.getTitle(value[0])}</span>
                                                    <span className="history-change-item-text value">
                                                    <i className="fa fa-arrow-right"> </i> {value[1]}</span>
                                                </div>
                                                }
                                            </>

                                        ))}
                                    </div>
                                    }
                                    <div className="history-change modified">
                                        <span
                                            className="history-change-title history-change-item modified">{change.action == "U" ? t("Modificado") : t("Creado")}</span>
                                        {Object.entries(JSON.parse(change.jsonChange)).map((value) => (
                                            <div className="history-change-item">
                                                <span
                                                    className="history-change-item-text">{this.getTitle(value[0])}</span>
                                                <span className="history-change-item-text value"><i
                                                    className="fa fa-arrow-right"> </i> {value[1]}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                }
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(HistoryComponent);
