import React, { Component } from 'react';
import VsfModal from "../../components/listaddons/VsfModal";
import AppLogger from "../../util/AppLogger";
import withTranslate from "../../translator/withTranslate";
import Select2Component from "../../components/fields/Select2Component";
import VsfButton from "../../components/VsfButton";
import { observer } from "mobx-react";
import { observable } from "mobx";
import OrderModel from "../../models/OrderModel";
import CheckboxUniqueComponent from "../../components/fields/CheckboxUniqueComponent";
import appState from "../../state/AppState";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import WorkOrderModel from "../../models/WorkOrderModel";
import SlotModel from "../../models/SlotModel";
import ScheduleVisitSlotModel from "../../models/ScheduleVisitSlotModel";
import util from '../../util/Util';
import GqlErrors from "../../components/status/GqlErrors";
import GraphException from "../../network/GraphException";
import PropsUtil from "../../util/DateUtil";
import { withRouter } from "react-router-dom";

@observer
class ClientPreventModal extends Component {
    @observable order = {};
    @observable    gqlErrors = null;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            meses: {},
            change: false,
            seleccionadas: 0,
        }

    }

    async componentDidMount() {
        if (this.props.fromClient) {
            this.setState({
                change: true,
            })
        }
        this.order = this.props.order
    }

    async createOrders(arrOrders) {
        let typifiedType = new TypifiedValueModel();
        typifiedType.filters = [
            { "fieldName": "type", "fieldValue": "typeIntervention", "filterOperator": "EQ" },
            { "fieldName": "value", "fieldValue": "Preventiva", "filterOperator": "EQ" },
        ];
        let typified = await typifiedType.find();
        let orderModel = new OrderModel();
        let workOrder = new WorkOrderModel();
        let tamanyo = 12 / this.state.seleccionadas;
        for (let order of arrOrders) {

            let scheduledYear = order.scheduledDate.substr(0, 4);
            let scheduledMonth = order.scheduledDate.substr(4);
            let idOrderCreada = "";
            for (let i = 1; i <= tamanyo; i++) {
                scheduledMonth = parseInt(scheduledMonth) + parseInt(this.state.seleccionadas);
                if (scheduledMonth > 12) {
                    scheduledMonth -= 12;
                    scheduledYear++;
                }
                orderModel.id = "";
                orderModel.hourPreventive = order.hourPreventive;
                orderModel.dayNumber = order.dayNumber;
                orderModel.weekNumber = order.weekNumber;
                orderModel.scheduleVisitId = order.scheduleVisitId;
                orderModel.status = order.status;
                orderModel.clientId = order.clientId;
                // orderModel.orderPreventiveId = order.id;
                orderModel.comments = order.comments;
                orderModel.scheduledDate = scheduledYear + "" + util.numDigitos(scheduledMonth, 2);
                await orderModel.persist();
                idOrderCreada = orderModel.id;
                let orderModelPlain = orderModel.toPlainObject();
                await appState.scheduleDropState.scheduledOrders.push(orderModelPlain);
                //Creo una O.T
                workOrder.id = "";
                workOrder.orderId = idOrderCreada;
                workOrder.assetType = "No Asignado";
                workOrder.assetSubType = "No Asignado";
                workOrder.generalDescription = order.comments;
                workOrder.type = typified[0].id;
                await workOrder.persist();
                // Guardo los slots
                this.createSlots(order, idOrderCreada);
            }
        }
        await this.props.loadClients();
    }

    async createSlots(order, idOrderCreada) {
        let visitSlotModel = new ScheduleVisitSlotModel();
        let slot = new SlotModel();
        visitSlotModel.scheduleVisitId = order.scheduleVisitId;
        let arrayScheduleVisitSlots = await visitSlotModel.find();
        let assignedSlotMainId = "";
        for (let scheduleVisitSlot of arrayScheduleVisitSlots) {
            slot.id = "";
            slot.assignedSlotMainId = "";
            slot.createdByPreventive = 1;
            slot.isDateTimeFixed = 1;
            slot.orderId = idOrderCreada;
            slot.scheduledDuration = scheduleVisitSlot.scheduledDuration;
            slot.slotUserRole = scheduleVisitSlot.slotUserRole;
            slot.workOrderIds = "-1";
            //     slot.slotUserRole = scheduleVisitSlot.slotUserRole;
            if (scheduleVisitSlot.slotUserRole === SlotModel.MAIN) {
                await slot.persist();
                let slotModel = new SlotModel();
                let slotQuery = await slotModel.findById(slot.id);
                assignedSlotMainId = slot.id
            }
            if (scheduleVisitSlot.slotUserRole === SlotModel.SUPPORT) {
                slot.assignedSlotMainId = assignedSlotMainId;
                await slot.persist();
                let slotModel = new SlotModel();
                let slotQuery = await slotModel.findById(slot.id);
            }
        }
    }

    async modifySlots(order) {
        this.log("modifySlots");
        let slot = new SlotModel();
        slot.filters = [
            { "fieldName": "orderId", "fieldValue": order.id, "filterOperator": "IN" },
        ];
        let slots = await slot.find();
        this.log(slots);
    }

    async savePrevent() {
        this.log("savePrevent");
        this.setState({
            loading: true
        });
        if (this.props.fromClient) {
            let arrOrders = [];
            for (let mes of Object.keys(this.state.meses)) {
                for (let scheduledOrder of appState.scheduleDropState.scheduledOrders) {
                    if (scheduledOrder.scheduledDate === mes) {
                        arrOrders.push(scheduledOrder);
                    }
                }
            }
            try {
                await this.createOrders(arrOrders);
                await this.props.getTotals();
                await this.props.getSlots();
                setTimeout(() => {
                    this.props.closeModal();
                }, 2000);
            } catch (e) {
                this.log("componentDidMount. Exception");
                let gqlErrors = new GraphException().getErrorsFromException(e);
                this.log({ gqlErrors });
                this.gqlErrors = gqlErrors;
            }
        } else {
            let order = new OrderModel();
            order.hidrate(this.order);
            try {
                await order.persist();
                await this.modifySlots(order);
                await this.props.loadClients();
                await this.props.getSlots();

                setTimeout(() => {
                    this.props.closeModal();
                }, 2000)
            } catch (e) {
                this.log("componentDidMount. Exception");
                let gqlErrors = new GraphException().getErrorsFromException(e);
                this.log({ gqlErrors });
                this.gqlErrors = gqlErrors;
            }
        }
        this.setState({
            loading: false
        });

    }

    updateInputEvent(e) {
        this.log("updateInputEvent");
        let value = e.target.value;
        let name = e.target.name;
        this.state.seleccionadas = Object.keys(this.state.meses).length + 1;

        if (this.props.fromClient) {
            this.state.meses[name] = value;
            this.log(this.state.meses);

        } else {
            this.setState({
                change: true
            });
            this.log(value);
            this.order[name] = value;
        }
    }

    getOptionsHours() {
        return ([
            { label: "08:00", value: "08:00" },
            { label: "09:00", value: "09:00" },
            { label: "10:00", value: "10:00" },
            { label: "11:00", value: "11:00" },
            { label: "12:00", value: "12:00" },
            { label: "13:00", value: "13:00" },
            { label: "14:00", value: "14:00" },
            { label: "15:00", value: "15:00" },
            { label: "16:00", value: "16:00" },
            { label: "17:00", value: "17:00" },
            { label: "18:00", value: "18:00" },
            { label: "19:00", value: "19:00" },
            { label: "20:00", value: "20:00" },
        ]);
    }

    optionsWeek() {
        return [
            { label: "Primera", value: 1 },
            { label: "Segunda", value: 2 },
            { label: "Tercera", value: 3 },
            { label: "Cuarta", value: 4 },
        ];
    }

    optionsDay() {
        return [
            { label: "Lunes", value: 1 },
            { label: "Martes", value: 2 },
            { label: "Miércoles", value: 3 },
            { label: "Jueves", value: 4 },
            { label: "Viernes", value: 5 },
            { label: "Sábado", value: 6 },
            { label: "Domingo", value: 7 },
        ];
    }

    render() {
        this.log("render");
        let { t } = this.props;
        let optionsWeek = this.optionsWeek();
        this.propsUtil = new PropsUtil(this.props);
        let optionsday = this.optionsDay();
        let hourPreventive = this.getOptionsHours();
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client"
            >
                <GqlErrors errors={this.gqlErrors} />
                <div className="modal-push" style={{ display: "block" }}>
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{t('Planificación')}<span>{t('Preventiva mensual')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">
                        {!this.props.fromClient ?

                            <div className="row">
                                <Select2Component
                                    value={this.order.dayNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"dayNumber"}
                                    title={t("Dia de la semana")}
                                    classGroup={"col-3"}
                                    options={optionsday}
                                    // errors={errorsMapped.dayNumber}
                                />
                                <Select2Component
                                    value={this.order.weekNumber}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"weekNumber"}
                                    title={t("Posición en el mes")}
                                    classGroup={"col-3"}
                                    options={optionsWeek}
                                    // errors={errorsMapped.weekNumber}
                                />
                                <Select2Component
                                    value={this.order.hourPreventive}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"hourPreventive"}
                                    title={t("Hora")}
                                    classGroup={"col-3"}
                                    options={hourPreventive}
                                    // errors={errorsMapped.hourPreventive}
                                />
                            </div>
                            : <div className="col-12">

                                <div className="row mt-3 ml-2">
                                    <p>{t("Tu selección se repetirá en los siguientes 12 meses")}</p>
                                </div>
                                <div className="row">
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "01"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "01"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Enero")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "02"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "02"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Febrero")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "03"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "03"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Marzo")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "04"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "04"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Abril")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "05"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "05"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Mayo")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "06"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "06"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Junio")}
                                        classGroup={"col-12"}
                                    />
                                </div>
                                <div className="row">
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "07"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "07"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Julio")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "08"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "08"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Agosto")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "09"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "09"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Septiembre")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "10"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "10"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Octubre")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "11"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "11"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Noviembre")}
                                        classGroup={"col-12"}
                                    />
                                    <CheckboxUniqueComponent
                                        checked={this.state.meses[this.props.year + "12"]}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={this.props.year + "12"}
                                        value={""}
                                        className={"col-2"}
                                        title={t("Diciembre")}
                                        classGroup={"col-12"}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="modal-footer ">
                        <div className="row mt-4">
                            <div className="col-12 d-flex justify-content-end mb-2">
                                <VsfButton
                                    label={'Cancelar'}
                                    classButton="btn btn-secondary "
                                    onClick={() => this.props.closeModal()}
                                />
                                <VsfButton
                                    label={'Guardar'}
                                    disabled={!this.state.change}
                                    loading={this.state.loading}
                                    classButton="btn--loader ml-3 "
                                    onClick={() => this.savePrevent()}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withTranslate(ClientPreventModal));
