import React from 'react';
import ContactModel from "../../models/ContactModel";
import appState from '../../state/AppState';
import { observer } from "mobx-react";
import TabsComponent from "../../components/fields/TabsComponent";
import ClientContact from "./ClientContact";
import PageTitle from "../../components/PageTitle";
import ClientModel from "../../models/ClientModel";
import withTranslate from "../../translator/withTranslate";
import GroupOfField from "../../components/layout/GroupOfField";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { computed, observable } from 'mobx';
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import VsfButton from "../../components/VsfButton";
import BaseEditableTable from "../base/BaseEditableTable";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';

@observer
class ClientContacts extends BaseEditableTable {

    static defaultProps = {
        clientId: null,
    }

    static propTypes = {
        //Acción de descarga de Excel en el listado
        clientId: PropTypes.string,
    }
    /**
     * Nombre del campo de la clase principal
     */
    foreingKeyfield;
    @observable isPos;

    constructor(props) {
        super(props);
        console.log("ClientContacts.constructor()");
        this.nameMainType = "client";
        this.initializeGraphData(this.nameMainType);
        appState.contactState.contacts = []; //Incialmente no muestro nigún contacto
        this.foreingKeyfield = "clientId";
    }

    @computed get mobxListado() {
        this.log("ClientContacts.mobxListado");
        return appState.contactState.contacts;
    }

    async componentDidMount() {
        super.componentDidMount();
        this.log(this.isPos)
        if (!util.hasValue(this.isPos)) {
            let client = await this.graphDataMainType;
            this.isPos = client.isPos
        }
        this.log(this.isPos)
    }

    getModelQuery() {
        return new ClientModel();
    }

    getModelTable() {
        return new ContactModel();
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    async reloadTable(clientId) {
        appState.contactState.reloadContactsFromClientId(clientId);

        let clientQuery = new ClientModel();
        appState.clientState.row = await clientQuery.findByIdNotNull(clientId);
    }

    render() {
        // console.log("ClientContacts.render() clientId:" + this.props.clientId);
        let { t } = this.props;
        let contactos = this.mobxListado;
        let client = this.graphDataMainType;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Cliente"}
                                subtitle={(this.isPos == 0 ? client.companyName : client.name) + " " + client.code}
                                onBackModal={() => this.onBackModal()}
                                mode={"edit"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.graphDataMainType.id || this.props.clientId}
                                tabs={this.isPos == 1 ? this.tabsData.getDataClientPos() : this.tabsData.getDataClient()}
                                active="Contactos"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={"Cliente"}
                            subtitle={(this.isPos == 0 ? client.companyName : client.name) + " " + client.code}
                        />
                    }

                    <div className={'card mb-3'}>
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.graphDataMainType.id}
                            tabs={this.graphDataMainType.isPos == 1 ? this.tabsData.getDataClientPos() : this.tabsData.getDataClient()}
                            active="Contactos"
                            fromRightModal={this.props.fromRightModal}
                        />
                        }
                        <div className={modalClasses.body}>
                            <div className="card-body">
                                <div className="row">
                                    <GroupOfField
                                        title={t('Datos Contactos')}
                                        icon="fas fa-id-card"
                                    />

                                </div>
                                <div className="row align-items-center">
                                    <div className="col-12 text-left mt-2 mb-1">
                                        <p className="contacts-num">
                                            <span>{contactos.length}</span> {contactos.length == 1 ? t("Contacto Añadido ") : t('Contactos Añadidos')}
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="table-edit">
                                        <div className="col-12">
                                            <table className="table visible-text"
                                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                                <thead>
                                                {this.mobxListado.length == 0 &&
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{t('No hay ningun contacto creado')}
                                                    </td>
                                                </tr>
                                                }
                                                {this.mobxListado.length != 0 &&

                                                <tr>
                                                    <th scope="col">{t('nombre apellido')}</th>
                                                    <th scope="col">{t('teléfono')}</th>
                                                    <th scope="col">{t('email')}</th>
                                                    <th scope="col">{t('cargo')}</th>
                                                    <th scope="col">&nbsp;</th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">

                                                {contactos.map((contacto, index) => (
                                                    <ClientContact
                                                        key={contacto.id}
                                                        clientId={this.props.clientId}
                                                        rowIndex={index}
                                                        row={contacto}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    />
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.props.fromRightModal ? "fixed-bottom mb-4" : " "}>
                                    <div className="row">
                                        <div className="col-12 text-right">

                                            <VsfButton
                                                disabled={this.state.hasSomeRowEditing}
                                                label={"Crear nuevo contacto"}
                                                onClick={_ => this.onOpenRowNewEmpty()}
                                                classButton={"btn btn--loader"}
                                            />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        )
    }

}

export default withRouter(withTranslate(ClientContacts, "ClientContacts"));
//export default ClientContacts;

