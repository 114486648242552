import { observable } from 'mobx';
import ClientState from "./ClientState";
import UserState from "./UserState";
import WorkOrderState from "./WorkOrderState";
import AssetState from "./AssetState";
import LoginState from "./LoginState";
import TypifiedState from "./TypifiedState";
import LayoutState from "./LayoutState";
import TypifiedValueState from "./TypifiedValueState";
import UserCacheState from "./UserCacheState";
import OrderState from "./OrderState";
import ClientCacheState from './ClientCacheState'
import ScheduleVisitState from "./ScheduleVisitState";
import ScheduleDropState from "./ScheduleDropState";
import ContactState from "./ContactState";
import LoadingBarState from "./LoadingBarState";
import SlotState from "./SlotState";
import WorkOrderCacheState from "./WorkOrderCacheState";
import ArticleState from "./ArticleState";
import WorkOrderCartArticlesState from "./WorkOrderCartArticlesState";
import AssetCodesState from "./AssetCodesState";
import ArticleCodesState from "./ArticleCodesState";
import ScheduleSlotState from "./ScheduleSlotState";
import AssetCacheState from "./AssetCacheState";
import DateRangesState from "./DateRangesState";
import VehicleState from "./VehicleState";
import TranslationsState from "./TranslationsState";

class AppState {

    /**
     * @type ClientState
     */
    client = null;
    /**
     *
     * @type OrderState
     */
    order = null;
    /**
     * @type UserState
     */
    user = null;
    /**
     * @type AssetState
     */
    AssetModel = null;
    /**
     * Idioma en el que se verá el contenido
     * @type {string}
     */
    @observable lang = "ES";
    @observable bookingData = {
        lang: "es"
    };
    @observable workOrderStatus = {
        mutationGraphQlResponse: {
            errors: []
        },
        mutationLoading: false,
        mutationError: false,
    };
    @observable preventOrders = [];
    // Guarda los filtros del listado de usuarios
    @observable userListFilters = { currentFilter: {}, currentFilterOps: {}, currentFilterLabels: {} };
    @observable clientListFilters = { currentFilter: {}, currentFilterOps: {}, currentFilterLabels: {} };
    @observable orderFormSlotState = { mutationGraphQlResponse: { errors: [] }, mutationError: 0 }
    @observable articleFormSlotState = { mutationGraphQlResponse: { errors: [] }, mutationError: 0 }
    @observable genericFormRowState = { mutationGraphQlResponse: { errors: [] }, mutationError: 0 }
    @observable orderListFilters = { currentFilter: {}, currentFilterOps: {}, currentFilterLabels: {} };
    @observable workOrderListFilters = { currentFilter: {}, currentFilterOps: {}, currentFilterLabels: {} };
    @observable assetListFilters = { currentFilter: {}, currentFilterOps: {}, currentFilterLabels: {} };
    @observable articleListFilters = { currentFilter: {}, currentFilterOps: {}, currentFilterLabels: {} };

    constructor() {
        this.clientState = new ClientState();
        this.userState = new UserState();
        this.workOrderState = new WorkOrderState();
        this.assetState = new AssetState();
        this.translationState = new TranslationsState();
        this.assetCacheState = new AssetCacheState();
        this.loginState = new LoginState();
        this.typifiedState = new TypifiedState();
        this.typifiedValueState = new TypifiedValueState();
        this.userCacheState = new UserCacheState();
        this.layoutState = new LayoutState();
        this.orderState = new OrderState();
        this.clientCacheState = new ClientCacheState();
        this.workOrderCacheState = new WorkOrderCacheState();
        this.scheduleVisitState = new ScheduleVisitState();
        this.scheduleDropState = new ScheduleDropState();
        this.dateRangesState = new DateRangesState();
        this.contactState = new ContactState();
        this.slotState = new SlotState();
        this.scheduleSlotState = new ScheduleSlotState();
        this.assetCodesState = new AssetCodesState();
        this.articleCodesState = new ArticleCodesState();
        this.loadingBarState = new LoadingBarState();
        this.articlesState = new ArticleState();
        this.vehicleState = new VehicleState();
        this.workOrderCartArticlesState = new WorkOrderCartArticlesState();
    }
}

export default new AppState();
