import React from 'react';
import { observer } from 'mobx-react';
import { withApollo } from "react-apollo";
import BaseForm from "../base/BaseForm";
import withTranslate from "../../translator/withTranslate";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import VehicleModel from "../../models/VehicleModel";

@observer
class VehicleForm extends BaseForm {


    constructor(props) {
        super(props);
        this.nameMainType = "vehicle";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false
        }
    }


    getModelQuery() {
        return new VehicleModel;
    }


    render() {
        const { t, i18n } = this.props;
        let vehicle = this.graphDataMainType;
        if (vehicle == null) {
            vehicle = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();

        return (

            <React.Fragment>
                <PageTitle
                    title={t("Vehiculo")}
                    subtitle={this.graphDataMainType.model || t("Nuevo")}
                >
                </PageTitle>
                <form
                    className="model-form"
                    name="formulario"
                    noValidate
                    onSubmit={(e) => this.handleFormSubmit(e)}
                >

                    <div className={'card mb-3'}>
                        <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                           error={this.graphStatus.mutationError}
                                           working={this.graphStatus.networkWorking}
                        />
                        <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                            formError={this.graphStatus.formError}
                        />
                        {errorsMapped[""] != null ?
                            <div class="alert alert-danger" role="alert">
                                {errorsMapped[""].map(error => <div>{error.message}</div>)}
                            </div>
                            :
                            null
                        }
                        <FormWrapper>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataVehicle()}
                                active={"Vehiculo"}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={" col-12"}
                            />
                            <GroupOfField title={t("Datos")}
                                          subtitle={t("Vehiculo")}
                                          icon="fa-clipboard"

                            >
                                <InputTypeComponent
                                    value={vehicle.plate}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"plate"}
                                    title={t("Matricula")}
                                    classGroup={"col-6"}
                                    type={"text"}
                                    errors={errorsMapped.plate}
                                />
                                <InputTypeComponent
                                    value={vehicle.brand}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"brand"}
                                    title={t("Marca")}
                                    classGroup={"col-6"}
                                    type={"text"}
                                    errors={errorsMapped.brand}
                                />
                                <InputTypeComponent
                                    value={vehicle.model}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"model"}
                                    title={t("Modelo")}
                                    classGroup={"col-6"}
                                    type={"text"}
                                    errors={errorsMapped.model}
                                />
                                <InputTypeComponent
                                    value={vehicle.subInventory}
                                    onChange={(e) => this.updateInputEvent(e)}
                                    name={"subInventory"}
                                    title={t("Almacén")}
                                    classGroup={"col-6"}
                                    type={"text"}
                                    errors={errorsMapped.model}
                                />


                            </GroupOfField>

                        </FormWrapper>
                    </div>
                    <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus}></FormButtons>
                </form>
            </React.Fragment>

        )
    }
}

export default withTranslate(withApollo(VehicleForm));
