import React, { Component } from 'react';
import { observer } from 'mobx-react';
import IconsInputsComponent from "./IconsInputsComponent";
import PropTypes from 'prop-types';
import util from '../../util/Util';
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";

@observer
export default class FormInputWrapper extends Component {

    static defaultProps={
        classInputType:"",
    }
    static propTypes={
        classInputType:PropTypes.string,
    }

    constructor(props) {
        super(props);
    }

    getClassGroupWithForceMedia(forceMedia, classGroup) {
        //si classGroup tiene un col-xl-4 y un col-sm-6 reemplaza los superiores (no mostrandolos)
        let valores = classGroup.split(" ");
        let newClassGroup=[];

        let arrSizes=["sm","md","lg","xl"];
        let arrSizesEliminar=[];
        let pos = arrSizes.indexOf(forceMedia);
        for(let i=pos+1;i<arrSizes.length;i++) {
            arrSizesEliminar.push(arrSizes[i]);
        }
        let strSizesEliminar=arrSizesEliminar.join(";");
        for(let valor of valores) {
            let claveTamanio = util.getDelim(valor,"-",1);
            //this.log("claveTamanio:"+claveTamanio+" strSizesEliminar: "+strSizesEliminar);
            if (util.perteneceLista(claveTamanio,strSizesEliminar)) {

            } else {
                newClassGroup.push(valor);
            }
        }
        classGroup=newClassGroup.join(" ");
        return classGroup;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props= this.props;
        let form = this.form;
        if (form==null) {
            form={};
        }

        let errorsProps = this.props.errors;
        if (errorsProps==null) {
            errorsProps=[];
        }
        let errorsClientValidation = this.props.errorsClientValidation;
        if (errorsClientValidation==null) {
            errorsClientValidation=[];
        }
        let errors = [...errorsProps, ...errorsClientValidation];
        let hasNoErrors = this.props.hasNoErrors;
        let hasErrors = errors.length > 0;
        let classNameInput = "form-control " + this.props.classInput;
        if (hasErrors) {
            classNameInput+=" is-invalid text-danger";
        }
        let labelClassName="control-label";
        if (hasErrors) {
            labelClassName+=" text-danger";
        }
        if (hasNoErrors) {
            labelClassName += " text-success";
        }
        let classGroup = props.classGroup;
        if (util.hasValue(this.props.forceMedia)) {
            classGroup = this.getClassGroupWithForceMedia(this.props.forceMedia, props.classGroup);
        }

        return (
            <div className={classGroup}>
                <div className={"form-group "+props.classInputType}>

                    {util.hasValue(props.title) &&
                        <label htmlFor={props.name}
                               className={labelClassName}>{props.title}
                            {props.required && (<React.Fragment> *</React.Fragment>)}
                            {props.labelClick  &&
                                <>
                                ...props
                                </>
                            }
                        </label>
                    }
                    <IconsInputsComponent
                        postfix={this.props.postfix}
                        prefix={this.props.prefix}
                    >
                        { props.children }
                    </IconsInputsComponent>
                    {hasErrors ? (
                        <>
                            <i className="js-error form-control-feedback glyphicon glyphicon-remove"></i>
                            {errors.map(error => <small className="js-error help-block text-danger">{error.message}</small>)}
                        </>
                    ):
                        <>
                            <div className="js-info ">{props.info || " "}</div>
                        </>

                    }
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


