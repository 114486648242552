import { observable,computed, autorun } from 'mobx';

import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import {gql} from "apollo-boost";
import BaseModelState from "./BaseModelState";

class TypifiedValueState extends BaseModelState {

}
export default TypifiedValueState;
