import React from 'react';
import { observer } from 'mobx-react';
import appState from '../../state/AppState';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import { computed } from 'mobx';
import BaseEditableRow from "../base/BaseEditableRow";
import InfoBlock from "../../components/InfoBlock";
import DateInputField from "../../components/fields/DateInputField";
import DateRangesModel from "../../models/DateRangesModel";
import util from "../../util/Util";
import Select2Component from "../../components/fields/Select2Component";

@observer
class UserAvailability extends BaseEditableRow {

    constructor(props) {
        super(props);
        this.state = {};
        this.foreingKeyfield = "userId";
    }

    @computed
    get mobxListado() {
        return appState.dateRangesState.dateRanges;
    }

    setMobxListado(newValue) {
        appState.dateRangesState.dateRanges = newValue;
    }

    getModelTable() {
        return new DateRangesModel();
    }

    async componentDidMount() {
        await super.componentDidMount();
    }

    render() {
        let user = this.props.row;
        let userStatus = [
            { label: "Baja", value: "Baja" },
            { label: "Vacaciones", value: "Vacaciones" },
            { label: "Guardia", value: "Guardia" },
        ];
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr>
                    <td>
                        {user.isEditing ?
                            <div className="form-group">
                                <Select2Component
                                    value={user.type}
                                    onChange={(e) => this.handleInputChange(e)}
                                    name={"type"}
                                    type={"text"}
                                    options={userStatus}
                                    className="form-control"
                                />
                            </div>
                            :
                            <div>
                                {util.hasValue(user.type) ?user.type : " "}
                            </div>
                        }
                    </td>
                    <td>
                        {user.isEditing ?
                            <DateInputField
                                value={user.startDate || "" }
                                onChange={(e) => this.handleInputChange(e)}
                                allowEmptyDate={true}
                                name={"startDate"}
                                prefix={"fas fa-calendar-alt"}
                                type={"text"}
                            />
                            :
                            <div>
                                {util.hasValue(user.startDate) ? util.getMoment(user.startDate).format("YYYY-MM-DD") : " "}
                            </div>
                        }
                    </td>

                    <td>
                        {user.isEditing ?
                            <DateInputField
                                value={user.endDate || "" }
                                prefix={"fas fa-calendar-alt"}
                                onChange={(e) => this.handleInputChange(e)}
                                allowEmptyDate={true}
                                name={"endDate"}
                                type={"text"}
                            />
                            :
                            <div>
                                {util.hasValue(user.endDate) ? util.getMoment(user.endDate).format("YYYY-MM-DD") : " "}
                            </div>
                        }

                    </td>
                    <td>
                        <div className="form-group">
                            {user.isEditing ?
                                <InputTypeComponent
                                    className={" form-control"}
                                    value={user.comments}
                                    onChange={(e) => this.handleInputChange(e)}
                                    name={"comments"}
                                    type={"text"}
                                    showView={!user.isEditing}

                                />
                                :
                                <>
                                    {user.comments}
                                </>
                            }
                        </div>
                    </td>
                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }
}

export default UserAvailability;
