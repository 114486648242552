import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types'
import PropsUtil from "../../util/PropsUtil";
import VsfLink from "../VsfLink";
import appState from "../../state/AppState";
import withTranslate from "../../translator/withTranslate";

@observer
class TabsComponent extends Component {

    static defaultProps = {
        tabs: [],
        active: "",
        fromRightModal: false,
        classNameModal: ""
    };

    static propTypes = {
        // Tabs que se mostrarán
        tabs: PropTypes.array,
        // identificador de la pestaña activa
        active: PropTypes.string,
        fromRightModal: PropTypes.bool,
        classNameModal: PropTypes.string,
    }

    constructor(props) {
        super(props);

    }

    render() {

        let props = this.props;
        const { t } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        console.log('numero de piezas order', appState.assetState.workorderArticles)
        return (
            <ul className={" nav nav-tabs pl-3" + this.props.classNameModal} role="tablist">
                {this.props.tabs.map(tab =>
                    <li key={"tab-" + tab.id} className="nav-item">

                        <VsfLink
                            className={"nav-link " + (this.props.id == null ? "prohibido " : "") + (props.active === tab.name ? "active" : "")}
                            disabled={this.props.id == null}
                            to={!this.props.fromRightModal ? tab.value + this.props.id + tab.valuePage
                                :
                                this.propsUtil.getUrlRequest({ "rightModalTab": tab.rightModalTab })}

                        >
                            <div>
                                {t(tab.label)}
                                {tab.alarm &&
                                <i className="badge badge-danger">{appState.assetState.orderArticles}</i>}
                                {tab.alarm2 &&
                                <i className="badge badge-danger">{appState.assetState.workOrderArticles}</i>
                                }
                                {tab.alarm3 &&
                                <i className="badge badge-danger">{appState.assetState.orderSlots}</i>
                                }
                                {tab.alarm4 &&
                                <i className="badge badge-danger">{appState.assetState.workOrderSlots}</i>
                                }
                            </div>
                        </VsfLink>


                    </li>
                )}
            </ul>
        );
    }
}


export default withTranslate(withRouter(TabsComponent));
