import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import IconsInputsComponent from "./IconsInputsComponent";
import AppLogger from "../../util/AppLogger";
import { now } from "moment";
import PropTypes from 'prop-types';
import util from '../../util/Util';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import moment from "moment";

registerLocale('es-ES', es);

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

@observer
export default class DateInputHours extends React.Component {

    static defaultProps = {
        allowEmptyDate: true,
    };

    static propTypes = {
        allowEmptyDate: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    };

    /**
     * A partir de una feche en formato del navegador
     * (por ejemplo Fri Sep 13 2019 15:00:00 GMT+0200 (hora de verano de Europa central))
     * devuelve 2019-09-13T15:00:00
     * @param today
     * @returns {string}
     */
    getDateStringFromDateLocalizedBroser(today) {
        return util.getDateStringFromDateLocalizedBroser(today);
    }

    /**
     * date tiene un objeto Date con la fecha escogida.
     * Pero está en formato timezone del navegador.
     * Tenemos que coger esa fecha sin timezone y trabajar con moment.
     * @param dateInitial objeto Date
     */
    handleChange(dateInitial) {
        let eventObj;
        let dateAsString = "";
        if (dateInitial != null) {
            dateAsString = this.getDateStringFromDateLocalizedBroser(dateInitial);
        }
        let momentDate = util.getMomentFromDateWithoutTimezone(dateAsString);
        let date = momentDate.toDate();
        if (this.props.allowEmptyDate) {
            if (dateInitial != null) {
                if (momentDate.format("HH") < "08") {
                    //date.setHours(8);
                }
                eventObj = {
                    target: {
                        name: this.props.name,
                        value: date.toISOString()
                    }
                }
            } else {
                eventObj = {
                    target: {
                        name: this.props.name,
                        value: ""
                    }
                }
            }
        } else {
            if (this.props.onChange != null) {
                eventObj = {
                    target: {
                        name: this.props.name,
                        value: date.toISOString()
                    }
                }
            }
        }
        this.log({dateInitial, dateAsString,date,eventObj});
        this.props.onChange(eventObj);
    }

    fechaSeleccionada() {
        let value = toJS(this.props.value);

        let momentValue = util.getMoment(value);
        /*
        El componente permite meter cualquier hora
        if (momentValue.format("HH") === "00") {
            let fechaNow = new Date(now());
            let horaActual = util.getMoment(fechaNow).format("H");
            momentValue.hour(horaActual);
        }
        */
        if (this.props.allowEmptyDate) {
            if (util.hasValue(value)) {
                value = momentValue.format("YYYY-MM-DDTHH:mm:ss");
            } else {
                value = "";
            }
        } else {
            if (util.hasValue(value)) {
                value = momentValue.format("YYYY-MM-DDTHH:mm:ss");
            } else {
                value = "";
            }
        }
        let result;
        if (util.hasValue(value)) {
            //Obtengo el valor como si fuese el timezone del navegador.
            //Arreglar asignación de fechas en SAfari con Timezones. No es bueno poner new Date("2019-01-01-T16:00:00"). A veces no te la devuelve en el timezone del navegador.
            let momentDate = moment(value);
            result=momentDate.toDate();
            if (!this.isValidDate(result)) {
                result="";
            }
            //result = new Date(value);
        } else {
            result="";
        }
        this.log("fechaSeleccionada(): =>");
        this.log({value,result});
        return result;
    }

    isValidDate(d) {
        return d instanceof Date && isFinite(d);
    }

    render() {
        let maxTime = new Date();
        maxTime.setHours(20);
        let seletedDateStr = this.fechaSeleccionada();

        let props = this.props;
        let form = this.form;
        if (form == null) {
            form = {};
        }
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        return (
            <div className={props.classGroup}>
                <div style={{ width: "200px" }}
                     className={"form-group DateInputField " + form.formCssClass}>

                    <label htmlFor={props.name}
                           className="control-label">{props.title}
                        {props.required && (<React.Fragment> *</React.Fragment>)}
                    </label>
                    <IconsInputsComponent
                        postfix={this.props.postfix}
                        prefix={this.props.prefix}
                    />
                    <DatePicker
                        selected={seletedDateStr}
                        onChange={(date) => this.handleChange(date)}
                        showTimeSelect
                        timeIntervals={this.props.interval ||60}
                        dateFormat="dd-MM-yyyy HH:mm"
                        minDate={this.props.minDate || new Date(now())}
                        timeFormat="HH:mm"
                        timeCaption="time"
                        className="form-control"
                        locale="es"
                        isClearable={this.props.allowEmptyDate}
                    />
                    <div className="js-info ">{props.info || " "}</div>
                    {errors.map(error => <small className="js-error help-block text-danger">{error.message}</small>)}
                </div>

            </div>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

