import React from 'react';
import { ItemTypes } from './Constants';
import { DragSource } from 'react-dnd';

const knightSource = {
    beginDrag(props) {
        return {};
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

function Knight({ connectDragSource, isDragging }) {
    return connectDragSource(
        <div style={{
            opacity: isDragging ? 0.9 : 1,
            fontSize: 10,
            fontWeight: 'bold',
            width:15,
            height:24,
            backgroundColor:'#aaaaaa',
            cursor: 'move'
        }}>
            ?
        </div>
    );
}

export default DragSource(ItemTypes.KNIGHT, knightSource, collect)(Knight);