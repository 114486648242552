import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import MoreButtons from "../../components/MoreButtons";
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";
import AppLogger from "../../util/AppLogger";
import VehicleModel from "../../models/VehicleModel";

@observer
class VehicleList extends BaseList {

    constructor(props) {
        super(props);
        this.quickView = true;
        this.includeSavedFavour = true;
        this.includeChangeColum = true;
        this.quickEdit = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.deleteFilter = this.deleteFilter.bind(this);
        this.nameVariableStateFilter = "vehicleListFilters";
    }

    getListFields() {
        return [
            {
                title: this.props.t("Matricula"),
                name: "plate",
                link: true,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Marca"),
                name: "brand",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Modelo"),
                name: "model",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Almacén"),
                name: "subInventory",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },

        ];
    }


    getListState() {
        return appState.vehicleState;
    }

    getDefaultOrder() {
        return "id";
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();

    }

    getModelQuery() {
        return new VehicleModel();
    }

    render() {
        this.log("VehicleList.render");
        this.propsUtil = new PropsUtil(this.props);
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let { t } = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <div>
                <ListItemSelect
                    stateListColumnsSelect={this.stateListColumnsSelect}
                    name={this.graphOperation}
                    subtitle='Vehiculos'
                />

                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div class="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={t("Vehiculos")}
                    subtitle={t("Listado")}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nuevo Vehiculo", onClick: () => {
                                    this.propsUtil.changeUrl("/vehicle/form/")
                                }
                            }
                        ]
                    }
                />
                </PageTitle>

                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent deleteFilter={this.deleteFilter}
                                         listState={this.getListState()}
                                         listData={this.getListData()}
                                         numEntriesPerPage={this.numEntriesPerPage}
                                         fields={fields}
                                         onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                         includeSavedFavourites={this.includeSavedFavourites}
                                         includeChangeColumns={this.includeChangeColumns}
                                         includeFilters={this.includeFilters}
                                         includeDownload={this.includeDownload}
                                         includeSearch={this.includeSearch}
                                         stateListColumnsSelect={this.stateListColumnsSelect}
                                         {...props}
                                         model={this.getModelQuery()}
                                         listComponent={this}
                                         favouriteModalOpen={this.state.favouriteModalOpen}
                                         toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                        />
                        <ListComponent listState={this.getListState()}
                                       listData={this.getListData()}
                                       status={this.getListStatus()}
                                       fields={fields}
                                       urlForm={"/vehicle/form"}
                                       {...props}
                                       getListState={this.getListState()}
                                       openQuickEdit={(row) => this.openQuickEdit(row)}
                                       quickEdit={this.quickEdit}>
                            <EmailList name="name" />
                        </ListComponent>

                    </div>
                </div>
                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />
            </div>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withTranslate(withApollo(VehicleList));
