import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import WorkOrderCartArticleModel from "../models/WorkOrderCartArticleModel";
import SlotModel from "../models/SlotModel";
import WorkOrderModel from "../models/WorkOrderModel";
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import AssetModel from "../models/AssetModel";


class AssetState extends BaseModelState {

    @observable orderArticles;
    @observable workOrderArticles;
    @observable orderSlots;
    @observable workOrderSlots;
    @observable assets=[];



    async getAssets() {
        let assetQuery = new AssetModel();{
            let result = await assetQuery.find();
            if (result == null) {
                result = [];
            }
            this.assets = result;
        }
    }


    async reloadAssetFromAssetId(assetId) {
        console.log("AssetState.reloadAssetFromAssetId assetId:"+assetId);
        let assetQuery = new AssetModel();
        assetQuery.id = assetId;
        if (util.hasValue(assetQuery.id)) {
            let result = await assetQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.assets = result;
        }
    }

    async getCountArticlesFromOrder(orderId) {
        let result = [];
        if (orderId !== "" && orderId !== undefined) {
            let orderQuery = new WorkOrderCartArticleModel();
            orderQuery.orderId = orderId;
            result = await orderQuery.find();
        }
        let vueltas = 0;
        result.map(() => {
            vueltas++;
        });

        this.orderArticles = vueltas === 0 ? "0" : vueltas;
    }

    async getCountSlotsFromOrder(orderId) {
        let result = [];
        if (orderId !== "" && orderId !== undefined) {
            let orderQuery = new SlotModel();
            orderQuery.orderId = orderId;
            result = await orderQuery.find();
        }
        let vueltas = 0;
        result.map(() => {
            vueltas++;
        });

        this.orderSlots = vueltas === 0 ? "0" : vueltas;
    }

    async getCountSlotsFromWorkOrder(workOrderId) {
        let result = [];
        let vueltas = 0;
        let orderId;
        if (workOrderId !== "") {
            let workOrderQuery = new WorkOrderModel();
            workOrderQuery.id = workOrderId;
            let order = await workOrderQuery.find();
            orderId = order[0] &&  order[0].orderId;
        }
        if (orderId != null) {
            let orderQuery = new SlotModel();
            orderQuery.orderId = orderId;
            result = await orderQuery.find();
        }
        this.log(result);
        result.map((slot) => {
            if (util.perteneceA(workOrderId, slot.workOrderIds) || util.perteneceA(-1, slot.workOrderIds))
                vueltas++;
        });
        this.workOrderSlots = vueltas === 0 ? "0" : vueltas;


    }

    async getCountArticlesFromWorkOrder(workOrderId) {
        let result = [];
        if (workOrderId !== "") {
            let workOrderQuery = new WorkOrderCartArticleModel();
            workOrderQuery.workOrderId = workOrderId;
            result = await workOrderQuery.find();
        }
        let vueltas = 0;
        result.map(() => {
            vueltas++;
        });
        this.workOrderArticles = vueltas === 0 ? "0" : vueltas;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default AssetState;
