
import appState from  '../state/AppState'

export default class TabsData {

    getDataWorkOrder() {
        let tabs = [
            {id:"1", name: "Datos Generales", label: "Datos Generales", value: "/work-order/form/", valuePage: "", rightModalTab: "workorder"},
            {id:"2", name: "Planificación", label: "Planificación", value: "/work-order/form/", valuePage: "/assigment", rightModalTab: "schedule", alarm4:appState.assetState.workOrderSlots},
            {id:"3", name: "Piezas", label: "Piezas ", value: "/work-order/form/", valuePage: "/items", rightModalTab: "articles", alarm2:appState.assetState.workOrderArticles},
            {id:"4", name: "Histórico", label: "Histórico", value: "/work-order/form/", valuePage: "/history" ,rightModalTab: "history"},
            {id:"5", name: "Histórico técnico", label: "Histórico técnico", value: "/work-order/form/", valuePage: "/historytech", rightModalTab: "historytech"},
            {id:"5", name: "Documentos", label: "Documentos", value: "/work-order/form/", valuePage: "/document", rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataClient() {
        let tabs = [
            {id:"100", name: "Datos Generales", label: "Datos Generales", value: "/client/form/", valuePage: "", rightModalTab: "general"},
            {id:"102", name: "Contactos", label: "Contactos", value: "/client/form/", valuePage: "/contacts", rightModalTab: "contacts"},
            {id:"103", name: "Histórico",label:"Histórico", value: "/client/form/", valuePage: "/history" ,rightModalTab: "history"},
            {id:"104", name: "Documentos", label: "Documentos", value: "/client/form/", valuePage: "/document" , rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataClientPos() {
        let tabs = [
            {id:"100", name: "Datos Generales", label: "Datos Generales", value: "/client/form/", valuePage: "", rightModalTab: "general"},
            {id:"101", name: "Preventivas", label: "Preventivas", value: "/client/form/", valuePage: "/prevent", rightModalTab: "prevent"},
            {id:"102", name: "Contactos", label: "Contactos", value: "/client/form/", valuePage: "/contacts", rightModalTab: "contacts"},
            {id:"103", name: "Histórico",label:"Histórico", value: "/client/form/", valuePage: "/history" ,rightModalTab: "history"},
            {id:"104", name: "Documentos", label: "Documentos", value: "/client/form/", valuePage: "/document" , rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataOrder() {
        let tabs = [
            {id:"200", name: "Datos Generales", label: "Datos Generales", value: "/order/form/", valuePage: "", rightModalTab: "order"},
            {id:"201", name: "Órdenes de Trabajo", label: "Órdenes de Trabajo", value: "/order/form/", valuePage: "/work-order", rightModalTab: "workorders"},
            {id:"202", name: "Planificación", label: "Planificación", value: "/order/form/", valuePage: "/slots", rightModalTab: "slots", alarm3:appState.assetState.orderSlots} ,
            {id:"203", name: "Piezas", label: "Piezas ", value: "/order/form/", valuePage: "/articles", rightModalTab: "articles", alarm:appState.assetState.orderArticles},
            {id:"204", name: "Histórico",label:"Histórico", value: "/order/form/", valuePage: "/history" ,rightModalTab: "history"},
            {id:"205", name: "Documentos", label: "Documentos", value: "/order/form/", valuePage: "/document" , rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataArticles() {
        let tabs = [
            {id:"300", name: "Datos Generales", label: "Datos Generales", value: "/article/form/", valuePage: "",rightModalTab: "articles"},
            {id:"301", name: "Ubicación",label: "Ubicación", value: "/article/form/", valuePage: "/location",rightModalTab: "location"},
            {id:"302", name: "Histórico", label: "Histórico", value: "/article/form/", valuePage: "/history",rightModalTab: "history"},
            {id:"303", name: "Documentos", label: "Documentos", value: "/article/form/", valuePage: "/document", rightModalTab: "document"},
        ];
        return tabs;
    }
    getDataAssets() {
        let tabs = [
            {id:"400", name: "Datos Generales", label: "Datos Generales", value: "/asset/form/", valuePage: "", rightModalTab: "general"},
            {id:"401", name: "Ubicación",label: "Ubicación", value: "/asset/form/", valuePage: "/location",rightModalTab: "location"},
            {id:"402", name: "Planograma Decoración",label: "Planograma Decoracion", value: "/asset/form/", valuePage: "/location",rightModalTab: "location"},
            {id:"403", name: "Perifericos",label: "Perifericos", value: "/asset/form/", valuePage: "/location",rightModalTab: "location"},
            {id:"404", name: "Histórico",label:"Histórico", value: "/asset/form/", valuePage: "/history",rightModalTab: "history"},
            {id:"405", name: "Documentos", label: "Documentos", value: "/asset/form/", valuePage: "/document",rightModalTab: "document"},
        ];
        return tabs;
    }
    getDataTypified() {
        let tabs = [
            {id:"500", name: "Pedidos", label: "Pedidos", value: "/typifiedValue/ls/", valuePage: ""},
            {id:"501", name: "Órdenes de Trabajo",label: "Órdenes de Trabajo", value: "/typifiedValue/ls/", valuePage: ""},
            {id:"502", name: "Usuarios",label: "Usuarios", value: "/typifiedValue/ls/", valuePage: ""},
            {id:"503", name: "Clientes",label: "Clientes", value: "/typifiedValue/ls/", valuePage: ""},
            {id:"504", name: "Artículos",label:"Artículos", value: "/typifiedValue/ls/", valuePage: ""},
        ];
        return tabs;
    }
    getDataScheduleVisit() {
        let tabs = [
            {id:"600", name: "Datos Generales", label: "Datos Generales", value: "/schedule/visit/form/", valuePage: ""},
            {id:"601", name: "Documentos", label: "Documentos", value: "/schedule/visit/form/", valuePage: "/document"},
        ];
        return tabs;
    }
    getDataVehicle() {
        let tabs = [
            {id:"600", name: "Datos Generales", label: "Datos Generales", value: "/vehicle/form/", valuePage: ""},
            {id:"601", name: "Documentos", label: "Documentos", value: "/vehicle/form/", valuePage: "/document"},
        ];
        return tabs;
    }
    getDataUser() {
        let tabs = [
            {id:"700", name: "Datos Generales", label: "Datos Generales", value: "/user/form/", valuePage: ""},
            {id:"703", name: "Capacitación", label: "Capacitación", value: "/user/form/", valuePage: "/employeeCapacitation"},
            {id:"705", name: "Disponibilidad", label: "Disponibilidad", value: "/user/form/", valuePage: "/userAvailability"},
            {id:"701", name: "Histórico", label: "Histórico", value: "/user/form/", valuePage: "/history"},
            {id:"702", name: "Documentos", label: "Documentos", value: "/user/form/", valuePage: "/document"},
        ];
        return tabs;
    }
}
