import AbstractModel from "./AbstractModel";

export default class SavedFilterModel extends AbstractModel {

    id;
    configuration;
    isShared;
    modelName;
    name;

    nameMainType="savedFilter";
    graphFindByIdOperation="savedFilter";
    graphFindOperation="savedFiltersConnection";

    getArrayFields() {
        let resultBase=super.getArrayFields();
        let result = {
            ...resultBase,
            "configuration":"",
            "isShared":"",
            "modelName": "",
            "name": ""
        };
        return result;
    }

}
