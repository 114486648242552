import React, {Component} from 'react';
import {observer} from "mobx-react/index";
import {withApollo} from "react-apollo";
import PropTypes from 'prop-types';
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import withTranslate from "../../translator/withTranslate";

@observer
class TypifiedTableLinks extends Component {
    static propTypes = {
        lista: PropTypes.array, //lista con los titulos de las direcciones que tiene la pestaña
    }
    static defaultProps = {
        lista: []
    }


    constructor(props) {
        super(props);
    }

    cargarLista() {
        let result =
            [
                {title: "Estados", url: "statusDispatcherWorkOrder"},
                {title: "Tipos de Orden de Trabajo ", url: "workOrderType"},
                {title: "Tipos de Equipos ", url: "assetType"},
                {title: "Síntomas de Averias ", url: "diagnosis"},
                {title: "Capacitaciones ", url: "employeeCapacitation"},
                // {title: "Categorias de intervención", url: "interventionCategory"},
                // {title: "Revisiones de Calidad", url: "quality"},
                {title: "Zonas", url: "zone"},
                {title: "Fabricantes", url: "manufacturer"},
                // {title: "Modelos", url: "model"},
                {title: "Relaciones de contactos", url: "relationship"},
            ]

        return result;
    }


    render() {
        this.log("TypifiedTableLinks render =>");
        const {t, i18n} = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let lista = this.cargarLista()

        return (
            <InfoBlock componentObject={this}>

                <div className="sidebar sidebar--float col-xl-2">
                    <nav className="sidebar-nav">
                        <ul className="nav">
                            {lista.map((link, index) => (
                                <li className={"nav-link"}>
                                <span className={this.props.getUrl == link.url ? "nav-link active " : "nav-link " + "text-click"}
                                      onClick={() => this.propsUtil.changeUrlRequest({type: link.url})}>{this.props.t(link.title)}
                                </span>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo( withTranslate(TypifiedTableLinks)));
