import { observable,computed, autorun } from 'mobx';
import BaseModelState from "./BaseModelState";
import util from "../util/Util";
import ContactModel from "../models/ContactModel";
import WorkOrderModel from "../models/WorkOrderModel";

class  WorkOrderState extends BaseModelState {

    @observable workOrders=[];

    t(s) {
        return s;
    }


    getModelTable() {
        let result =new WorkOrderModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("asset");
        return result;
    }

    async reloadFromOrderId(orderId) {
        let workOrderQuery = this.getModelTable();
        workOrderQuery.orderId = orderId;
        if (util.hasValue(workOrderQuery.orderId)) {
            let result = await workOrderQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.workOrders = result;
        }
    }
}

export default WorkOrderState;
