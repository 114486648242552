/**
 * Created by jlopez on 8/9/17.
 */

class Config {

    /**
     * Indica si estamos configurados como "REAL"
     */
    isLive=false;

    analyticsCode = "XXXXX";



    constructor() {
        this.isLive=true;

        if (this.isLive) {
            this.setRealUrl();
        } else {
            this.setTestUrl();
        }
        //this.apiHostBaseUrl='http://192.168.0.16:5003/graphql';
        //this.apiHostBaseUrl='http://192.168.0.16:5000/graphql';
        //this.apiHostBaseUrl='https://192.168.2.185:5001/graphql';
        this.apiHostBaseUrl='http://localhost:5000/graphql';
        this.apiRestHostBaseUrl='http://localhost:5000/api';

        if (process && process.env) {
            if (process.env.REACT_APP_API_GRAPHQL_ENDPOINT) {
                this.apiHostBaseUrl=process.env.REACT_APP_API_GRAPHQL_ENDPOINT;
            }
            if (process.env.REACT_APP_API_GRAPHQL_ENDPOINT) {
                this.apiRestHostBaseUrl=process.env.REACT_APP_API_REST_ENDPOINT;
            }
        }
    }

    setTestUrl() {
        this.apiHostBaseUrl='https://static.vsf.es/frs/api/v1';
    }

    setRealUrl() {
        this.apiHostBaseUrl='https://booking.frs.es/api/v1';
    }

}


export default new Config();
