import React, { Component } from 'react';
import { observer } from 'mobx-react';


@observer
export default class FormWrapper extends Component {
    render() {
        return (
            <div className="container-fluid formWrapper">
                <div className="row">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

