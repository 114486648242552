import { observable } from 'mobx';
import Article from "../models/ArticleModel";
import BaseModelState from "./BaseModelState";
import TranslationsModel from "../models/TranslationsModel";


class TranslationsState extends BaseModelState {

    @observable translations = [];

    async getArticles() {
        let articleQuery = new TranslationsModel();
        {
            let result = await articleQuery.find();
            if (result == null) {
                result = [];
            }
            this.articles = result;
        }
    }


}

export default TranslationsState;
