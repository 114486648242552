import GraphException from "./GraphException";
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";

export default class ApolloProxy {

    constructor(apolloClient) {
        this.apolloClient=apolloClient;
    }

    /**
     * Lanza una query contra graphQL
     * Si devuelve error Timeout lanza una excepci�n como esta: {extensions:{data:{field:""}}, message:"Error al conectar con servidor"}
     * Si devuelve un error 400 (con datos nulos) devuelve los mensajes de error de esta forma
     * En caso de exito devuelve la respuesta Graphql
     * @returns {Promise<void>}
     */
    async graphQuery(queryParams) {
        let resultQuery={data:null};
        let errorMessages=[{message:"GraphException. Error al realizar llamada a servidor"}];
        try {
            console.log("ApolloProxy.graphQuery");

            resultQuery = await this.apolloClient.query(queryParams);
            console.log({resultQuery});
            console.log("/ApolloProxy.graphQuery");
        } catch (e) {
            console.log("ApolloProxy.graphQuery. Exception");
            if (e.networkError && e.networkError.statusCode=="401") {
                //throw new GraphException({message:"401. Acceso no autorizado", errors:errorMessages});
                await appState.loginState.doLogout();
                window.location.href="/";
            } else {
                let jsonObj = JSON.parse(JSON.stringify(e));
                //Si hay error graphQlResponse ser� null. Pero se podr� obtener desde message
                let graphQlResponse = await jsonObj.networkError ? jsonObj.networkError.result: null;
                /*
                console.log("Exception e=>");
                console.log(e);
                console.log("graphQlResponse =>");
                console.log(graphQlResponse);
                */
                console.log("jsonObj2 =>");
                console.log(jsonObj);
                if (graphQlResponse==null) {
                    //Si el servidor no se alcanza entrar� por aqu�:. Por ejemplo con "Network error: Failed to fetch"
                    // En otro caso ser� un mensaje error 400 con un listado de errores de GraphQL
                    errorMessages=[{extensions:{data:{field:""}}, message:jsonObj.message}];
                    //graphQlResponse = { errors:[errorMessage] };
                } else {
                    if (graphQlResponse.errors!=null) {
                        errorMessages = graphQlResponse.errors;
                    }
                }

                console.log("graphQuery catch(e)");
                throw new GraphException({message:"GraphException.Error al conectar con servidor", errors:errorMessages});
            }
        }
        // Si la query tiene errores, los muestro y lanzo excepci�n
        if (resultQuery.errors && resultQuery.errors.length>0) {
            console.log("ApolloProxy.graphQuery. withErrors");
            if (this.objetoSoloTienePropiedadesNull(resultQuery.data)) {
                console.log("ApolloProxy.graphQuery. throw Exception");
                throw new GraphException({message:"GraphException.Error al procesar la petici�n", errors:resultQuery.errors});
            }
        }
        if (resultQuery && resultQuery.data==null) {
            console.log("graphQuery Exception");
            let graphDataException={message:"GraphException.Error al conectar con servidor", errors:errorMessages};
            console.log({graphDataException});
            throw new GraphException(graphDataException);
        }
        return resultQuery;
    }

    objetoSoloTienePropiedadesNull(objeto) {
        let result=true;
        for([key,value] of Object.entries(objeto)) {
            if (objeto[key]==null) {

            } else {
                result=false;
            }
        }
        return result;
    }


    async mutate(mutationParams) {
        let resultQuery={data:null};
        let errorMessages=[{message:"GraphException.Error al conectar con servidor"}];
        try {
            resultQuery = await this.apolloClient.mutate(mutationParams);
        } catch (e) {
            let jsonObj = JSON.parse(JSON.stringify(e));
            //Si hay error graphQlResponse ser� null. Pero se podr� obtener desde message
            let graphQlResponse = jsonObj.networkError.result;
            /*
            console.log("Exception e=>");
            console.log(e);
            console.log("graphQlResponse =>");
            console.log(graphQlResponse);
            */
            console.log("jsonObj2 =>");
            console.log(jsonObj);
            if (graphQlResponse==null) {
                //Si el servidor no se alcanza entrar� por aqu�:. Por ejemplo con "Network error: Failed to fetch"
                // En otro caso ser� un mensaje error 400 con un listado de errores de GraphQL
                errorMessages=[{extensions:{data:{field:""}}, message:jsonObj.message}];
                //graphQlResponse = { errors:[errorMessage] };
            } else {
                if (graphQlResponse.errors!=null) {
                    errorMessages = graphQlResponse.errors;
                }
            }

            console.log("graphQuery catch(e)");
            throw new GraphException({message:"GraphException.Error al conectar con servidor", errors:errorMessages});
        }
        if (resultQuery.data==null) {
            console.log("graphQuery data=null");
            throw new GraphException({message:"GraphException.Error al conectar con servidor", errors:[errorMessage]});
        }
        return resultQuery;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
