import React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import BaseListFilter from "../../subpages/BaseListFilter";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import UserModel from "../../models/UserModel";
import PropsUtil from "../../util/PropsUtil";
import MoreButtons from "../../components/MoreButtons";
import InfoBlock from "../../components/InfoBlock";
import UserListFilters from "./UserListFilters";
import UserRoleListItem from "../../components/list/UserRoleListItem";
import ListPageCounts from "../../components/ListPageCounts";
import AppLogger from "../../util/AppLogger";
import ZoneColorList from "../../components/list/custom/ZoneColorList";

@observer
class UserList extends BaseList {

    constructor(props) {
        super(props);
        this.nameMainType = "user";
        this.nameVariableStateFilter = "userListFilters";
        this.deleteFilter = this.deleteFilter.bind(this); // Si hacemos aqui el bind o como propiedad de clase en lugar de en el render, ganamos rendimiento a costa de unas linea fea en el constructor o en el cuerpo de la clase
        this.quickEdit = true;
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
    }


    getListFields() {
        return [
            {
                title: this.props.t("Email"),
                name: "email",
                link: true,
                component: (props) => <EmailList link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Nombre"),
                name: "firstName",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Apellidos"),
                name: "lastName",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Rol"),
                name: "role",
                component: (props) => <UserRoleListItem role={true} link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("NIF"),
                name: "idNumber",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Número SS"),
                name: "insuranceNumber",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },

            {
                title: this.props.t("Localidad"),
                name: "city",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },

            {
                title: this.props.t("Provincia"),
                name: "province",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Código Postal"),
                name: "postalCode",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Empresa"),
                name: "company",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Puesto de trabajo"),
                withOrder: false,
                name: "employmentUser.value",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Teléfono de Empresa"),
                name: "companyPhoneNumber",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Email de Empresa"),
                name: "companyEmail",
                component: (props) => <TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Vehículo Asociado"),
                name: "vehicle.value",
                component: (props) =><TextListItem link={false} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Zona de Trabajo"),
                name: "zoneAsigned.value",
                component: (props) => <ZoneColorList link={true}  user={true}
                                                     icon="fas fa-map-marked-alt" {...props} />,
                visible: true
            },
        ];
    }


    getListState() {
        return appState.userState;
    }

    @action
    async componentDidMountImpl() {
        await Promise.all([
            appState.typifiedState.loadRolUser(),
            // appState.typifiedState.loadRelationships(),
            // appState.typifiedState.loadZones(),
            // appState.typifiedState.loadVehicles(),
            // appState.typifiedState.loadEmploiments(),
        ]);

        // appState.typifiedState.arrayLoadEmploimets();
        appState.typifiedState.arrayLoadRoles();
        // appState.typifiedState.arrayLoadRelationships();
        // appState.typifiedState.arrayLoadVehicles();
        // appState.typifiedState.arrayLoadZones();

        await super.componentDidMountImpl();

    }

    getModelQuery() {
        let result = new UserModel();
        result.addRelatedTable('zoneAsigned');

        //result.addRelatedTable('relationship'); //  No tenemos columna relationship en el listado

        result.addRelatedTable('vehicle');
        result.addRelatedTable('employmentUser');
        return result;
    }

    render() {
        this.log("UserList.render()");
        this.propsUtil = new PropsUtil(this.props);
        let { t } = this.props;

        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <InfoBlock componentObject={this}>

                <ListItemSelect
                    stateListColumnsSelect={this.stateListColumnsSelect}
                    name={this.graphOperation}
                    subtitle={t('Usuarios')}
                />

                <BaseListFilter title={'Usuarios'} listComponent={this} stateFilter={this.stateListFilter}>
                    {this.renderFilters()}
                </BaseListFilter>
                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div className="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }


                <PageTitle
                    title={t("Usuarios")}
                    subtitle={t("Listado")}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nuevo Usuario", onClick: () => {
                                    this.propsUtil.changeUrl("/user/form/")
                                }
                            }
                        ]
                    }
                />
                </PageTitle>
                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent deleteFilter={this.deleteFilter}
                                         listState={this.getListState()}
                                         listData={this.getListData()}
                                         numEntriesPerPage={this.numEntriesPerPage}
                                         fields={fields}
                                         onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                         includeSavedFavourites={this.includeSavedFavourites}
                                         includeChangeColumns={this.includeChangeColumns}
                                         includeFilters={this.includeFilters}
                                         stateListColumnsSelect={this.stateListColumnsSelect}
                                         includeDownload={this.includeDownload}
                                         includeSearch={this.includeSearch}
                                         {...props}
                                         model={this.getModelQuery()}
                                         listComponent={this}
                                         favouriteModalOpen={this.state.favouriteModalOpen}
                                         toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                        />
                        <ListComponent listState={this.getListState()}
                                       listData={this.getListData()}
                                       status={this.getListStatus()}
                                       fields={fields}
                                       urlForm={"/user/form"}
                                       {...props}
                                       getListState={this.getListState()}
                                       quickEdit={this.quickEdit}>
                            <EmailList name="name" />
                        </ListComponent>
                    </div>
                </div>
                <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                                listState={this.getListState()}
                                listData={this.getListData()} {...props} />
                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />
            </InfoBlock>
        );
    }

    renderFilters() {
        let { t } = this.props;
        return (
            <React.Fragment>
                <UserListFilters key={"userlist"} />
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(withApollo(withTranslate(UserList, "UserList")));
