import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import ScheduleVisitSlotModel from "./ScheduleVisitSlotModel";

export default class ScheduleVisitModel extends AbstractModel {

    id;

    name;
    estimatedPrincipalTime;
    estimatedtotalTime;
    technicalCount;


    nameMainType="scheduleVisit";
    graphFindByIdOperation = "scheduleVisit";
    graphFindOperation="scheduleVisitsConnection";

    getArrayFields() {
        let resultBase=super.getArrayFields();
        let result = {
            ...resultBase,
            "name":"",
            "estimatedPrincipalTime":"",
            "estimatedtotalTime":"",
            "technicalCount":"",
        };
        if (this._relatedTables["scheduleVisitSlots"]) {
            result["scheduleVisitSlots"]={type:"Relation", onlyRead:true, other:"", childType:ScheduleVisitSlotModel}
        }
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }

}
