import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withTranslate from "../../translator/withTranslate";
import appState from "../../state/AppState";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import VsfLink from "../VsfLink";

@observer
class HierarchicalEditTextListItem extends Component {

    getTabulation(nivel){
        var rows = [];
        for (var i = 0; i < nivel; i++) {
            rows.push(<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>);
        }
        return <span>{rows}</span>;
    }

    render() {
        let props = this.props;
        let value = props.value;
        let row = this.props.row;
        return (
            <div>
                {this.getTabulation(row.altura)}
                <VsfLink to={`${this.props.urlForm}/${row.id}`} className="hieralchicalTitle">
                    <span>{value}</span>
                </VsfLink>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withTranslate(HierarchicalEditTextListItem)
