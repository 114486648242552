import React from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import FiltersPage from "../FiltersPage";

@observer
class ArticleListFilters extends FiltersPage {

    nameVariableStateFilter = "articleListFilters";

    initialize() {
        super.initialize();
        //Indica que aunque sea un Date se debe hacer la llamada a GQL como DateTime completo
        //this.log("constructor() this.nameVariableStateFilter:"+this.nameVariableStateFilter);
        //this.log(toJS(appState[this.nameVariableStateFilter]));
        appState[this.nameVariableStateFilter].filterType["createdAt"] = "DateTime";
    }


    render() {
        this.log("ArticleListFilters.render()");
        this.propsUtil = new PropsUtil(this.props);

        let { t } = this.props;

        return (
            <React.Fragment>
                <React.Fragment>
                    <InputTypeComponent
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        value={appState[this.nameVariableStateFilter].currentFilter.email}
                        name="email"
                        title={t("Email")}
                        classGroup={"col-4"}
                        placeholder={t("Email")}
                    />
                </React.Fragment>
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}


export default withRouter(withApollo(withTranslate(ArticleListFilters, "ArticleListFilters")));
