import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import BaseListFilter from "../../subpages/BaseListFilter";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import RightModalInList from "../../subpages/RightModalInList";
import ArticleModel from "../../models/ArticleModel";
import ArticleForm from "./ArticleForm";
import MoreButtons from "../../components/MoreButtons";
import PropsUtil from "../../util/PropsUtil";
import ArticleListFilters from "./ArticleListFilters";
import ListPageCounts from "../../components/ListPageCounts";
import AppLogger from "../../util/AppLogger";

@observer
class ArticleList extends BaseList {

    constructor(props) {
        super(props);
        this.defaultArticle = "name";
        this.state = { ...this.state, appear: false };
        this.deleteFilter = this.deleteFilter.bind(this); // Si hacemos aqui el bind o como propiedad de clase en lugar de en el render, ganamos rendimiento a costa de unas linea fea en el constructor o en el cuerpo de la clase
        // Variable que activa el Quick Edit
        this.quickView = true;
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.nameVariableStateFilter = "articleListFilters";
    }

    getListFields() {
        return [
            {
                title: this.props.t("Ref. Interna"),
                name: "code",
                link: true,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Tipo"),
                name: "type",
                link: false,
                component: (props) => <TextListItem link={true} articleType={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Disponibilidad"),
                name: "enable",
                link: false,
                component: (props) => <TextListItem disponible={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Nombre"),
                name: "name",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Familia"),
                name: "family",
                link: false,
                component: (props) => <TextListItem link={true} articleFamily={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Subfamilia"),
                name: "subfamily",
                link: false,
                component: (props) => <TextListItem link={true} articleSubFamily={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Descripción"),
                name: "description",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Garantía"),
                name: "warranty",
                link: false,
                component: (props) => <TextListItem garantia={true} link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Fecha Fin de Garantía"),
                name: "endDateWarranty",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
        ];
    }

    openQuickEdit(row) {
        this.getListState().quickEdit.row = row;
        this.propsUtil.changeUrlRequest({
            articleId: row.id,
            rightModal: "modalarticles",
            rightModalTab: "articles",
            fromRightModal: true
        })
    }

    getListState() {
        return appState.articlesState;
    }

    getModelQuery() {
        return new ArticleModel();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.log("componentDidMount");
        await appState.typifiedState.loadRelationships();
        await appState.typifiedState.loadArticleType();
        await appState.typifiedState.loadArticleFamily();
        await appState.typifiedState.loadArticleSubfamily();

        await appState.typifiedState.arrayLoadArticleFamily();
        await appState.typifiedState.arrayLoadArticleType();
        await appState.typifiedState.arrayLoadArticleSubFamily();

    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const { t, i18n } = this.props;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <div>
                <ListItemSelect stateListColumnsSelect={this.stateListColumnsSelect} name={this.graphOperation} />

                <BaseListFilter listComponent={this} stateFilter={this.stateListFilter}>
                    {this.renderFilters()}
                </BaseListFilter>

                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div className="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }
                <PageTitle
                    title={t("Repuestos")}
                    subtitle={t("Listado")}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nueva Pieza", onClick: () => {
                                    this.propsUtil.changeUrl("/article/form/")
                                }
                            }
                        ]
                    }
                />
                </PageTitle>
                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent deleteFilter={this.deleteFilter}
                                         listState={this.getListState()}
                                         listData={this.getListData()}
                                         numEntriesPerPage={this.numEntriesPerPage}
                                         fields={fields}
                                         onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                         {...props}
                                         includeSavedFavourites={this.includeSavedFavourites}
                                         includeChangeColumns={this.includeChangeColumns}
                                         includeFilters={this.includeFilters}
                                         stateListColumnsSelect={this.stateListColumnsSelect}
                                         includeDownload={this.includeDownload}
                                         includeSearch={this.includeSearch}
                                         model={this.getModelQuery()}
                                         listComponent={this}
                                         favouriteModalOpen={this.state.favouriteModalOpen}
                                         toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                        />
                        <ListComponent listState={this.getListState()}
                                       listData={this.getListData()}
                                       status={this.getListStatus()}
                                       fields={fields}
                                       urlForm={"/article/form"}
                                       {...props}
                                       quickView={this.quickView}
                                       getListState={this.getListState()}
                                       openQuickEdit={(row) => this.openQuickEdit(row)}
                                       quickEdit={this.quickEdit}>
                            <EmailList name="name" />
                        </ListComponent>
                    </div>
                </div>
                <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                                listState={this.getListState()}
                                listData={this.getListData()} {...props} />
                <PaginationComponent
                    listData={this.getListData()}
                    numEntriesPerPage={this.numEntriesPerPage}
                    {...props} />

            </div>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <ArticleListFilters key={"articlelist"} />
            </React.Fragment>
        )
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withApollo(withTranslate(ArticleList, "ArticleList")));
