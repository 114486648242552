import React, {Component} from 'react';
import {observer} from 'mobx-react';
import withTranslate from "../../translator/withTranslate";


@observer
export default class TextAreaComponent extends Component {

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
    render() {
        let props = this.props;
        const {t, i18n} = this.props;
        let errors = this.props.errors;
        if (errors==null) {
            errors=[];
        }
        TextAreaComponent.defaultProps={
            title:"title default",
            name:"default name",
            required: true,
            placeholder:"default placeholder",
            classGroup: "col-12",
        };
        return (


            <div className={props.classGroup}>
                <div className={"form-group"}>
                    <label htmlFor={props.name}
                           className="control-label">
                        {props.title}
                        {props.required && (<React.Fragment> *</React.Fragment>)}
                    </label>

                    <textarea
                        value={props.value}
                        name={props.name}
                        id={props.name}
                        cols="15"
                        rows="5"
                        placeholder={props.placeholder}
                        className="form-control"
                        onChange={e=>this.onChange(e)}
                    />
                    {errors.map(error => <div>{error.message}</div>)}
                </div>
            </div>

        );
    }
}


