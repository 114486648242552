import React, {Component} from 'react';
import {observer} from 'mobx-react';
import withTranslate from "../../translator/withTranslate";
import appState from "../../state/AppState";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import PropTypes from 'prop-types';

@observer
class TextListItem extends Component {

    static defaultProps = {}

    static propTypes = {
        value: PropTypes.string,
        row: PropTypes.object,
        urlForm: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.string,
    }

    render() {
        let props = this.props;
        let value = props.value;
        if (props.clients != null) {
            value = appState.clientCacheState.getClientById(props.value).name;
        }
        if (props.disponible || props.garantia) {
            if (value != null)
                value = value == 0 ? "No" : "Si";
            else value = "";
        }
        if (props.assetType) {
            if (util.hasValue(value)) {
                value = appState.typifiedState.arrAssetTypes[value];
            } else {
                value = appState.typifiedState.arrAssetTypes[props.row.assetType];
                if (!util.hasValue(value)) {
                    value = "No asignado";
                }
            }
        }
        if (props.assetPlate && value == null) {
            value = "No asignado";
        }
        if (props.assetSubType) {
            if (util.hasValue(value)) {
                value = appState.typifiedState.arrAssetSubtypes[value];
            } else {
                value = appState.typifiedState.arrAssetSubtypes[props.row.assetSubType];
                if (!util.hasValue(value)) {
                    value = "No asignado";
                }
            }
        }
        if (props.articleFamily) {
            value = appState.typifiedState.arrArticleFamily[value];
        }
        if (props.articleType) {
            value = appState.typifiedState.arrArticleType[value];
        }
        if (props.articleSubFamily) {
            value = appState.typifiedState.arrArticleSubfamily[value];
        }

        if (props.assetLocationOTList) {
            if (!util.hasValue(value)) {
                value = "No asignado";
            }
        }
        if (props.typeOTList) {
            value = appState.typifiedState.arrWorkOrderType[value];
        }
        if (props.subTypeOTList) {
            value = appState.typifiedState.arrSubTypeWorkOrders[value];
        }
        if (props.vehiclesUserList) {
            value = appState.typifiedState.arrVehicles[value];
        }
        if (props.assetLocationList && props.clients != null) {
            value = appState.typifiedState.arrZones[appState.clientCacheState.getClientById(props.row.storeId).posZoneId];
        }
        if (props.warrantyAsset) {
            if (value==1){
                value = "Si";
            }
            else{
                value="No";
            }
        }

        // if (props.assetManufacturerList) {
        //     value = appState.typifiedState.arrManufacturer[value];
        // }
        // if (props.assetTypeList) {
        //     value = appState.typifiedState.arrAssetTypes[value];
        // }
        // if (props.assetSubTypeList) {
        //     value = appState.typifiedState.arrAssetSubtypes[value];
        // }
        // if (props.userRelationship) {
        //     value = appState.typifiedState.arrRelationships[value];
        // }


        // if (props.zonesUserList) {
        //     value = appState.typifiedState.arrZones[value];
        // }
        // if (props.zonesClientList) {
        //     value = appState.typifiedState.arrZones[value];
        // }


        return (
            <div>
                {props.icon &&
                <div className='col-6'><span className={props.icon}/></div>}
                <div>{value}</div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withTranslate(TextListItem)
