import React, { Component } from 'react';

class NetworkStatusInfo extends Component {

    render() {
        let props=this.props;
        let className = "";
        if (props.working) {
            if (props.loading) {
                className = "show loading";
            } else if (props.error) {
                className = "show error";
            } else {
                className = "show success";
            }
        } else {
            className = "";
        }
        return null;

        return (
            <div id="ajax-actions" className={className}>
                {props.error &&
                    <div className="error"><i className="fa fa-exclamation-triangle"></i> Error</div>
                }
                {props.loading &&
                    <div className="loading"><i className="fa fa-spinner"></i> Cargando...</div>
                }

                <div className="success"><i className="fa fa-check"></i> Completado</div>
            </div>
        )
    }
}
export default NetworkStatusInfo;