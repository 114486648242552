import React from 'react';
import { observer } from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import { withApollo } from "react-apollo";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import SlotModel from "../../models/SlotModel";
import { computed, observable } from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import GroupOfField from "../../components/layout/GroupOfField";
import PropsUtil from "../../util/PropsUtil";
import { withRouter } from "react-router-dom";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import SlotsTable from "../../components/SlotsTable";
import util from '../../util/Util';
import OrderModel from "../../models/OrderModel";
import withTranslate from "../../translator/withTranslate";

@observer
class OrderFormSlots extends BaseEditableTable {


    @observable slots = [];

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
    }

    @computed get mobxListado() {
        this.log("OrderFormSlots.mobxListado");
        return this.slots;
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelTable() {
        return new SlotModel();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        let id = this.getFormId();

        await this.reloadTable(id);
    }

    async reloadTable(orderId) {
        let slotQuery = new SlotModel();
        let slotsModels = await slotQuery.getSlotsFromOrder(orderId);

        this.slots = util.arrayModelToPlainObjects(slotsModels);
    }


    render() {
        console.log("OrderFormSlots render =>");
        let order = this.graphDataMainType;
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);

        let modalClasses = util.getModalClasses();

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>


                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={"Pedido"}
                                subtitle={order.code || t("Nuevo")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder()}
                                active="Planificación"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Pedido")}
                            subtitle={order.code || t("Nuevo")}
                        />
                    }
                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.getFormId()}
                            tabs={this.tabsData.getDataOrder()}
                            active={"Planificación"}
                        />
                        }
                        <div className={modalClasses.body}>

                            <div className="card-body">

                                <div className="row align-items-center">
                                    <GroupOfField
                                        title={t('Pedido')}
                                        subtitle={t('Planificación')}
                                    />
                                </div>

                                <SlotsTable
                                    key={order.id}
                                    id={order.id}
                                    foreingKeyfield={"orderId"}
                                    workOrders={appState.workOrderState.workOrders}
                                    {...this.props}
                                />

                            </div>
                        </div>

                        {/*<OrderSlot key={index} slot={slot} />*/}
                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withTranslate(withRouter(withApollo(OrderFormSlots)));
