import Model from "./Model";

export default class ChangeModel extends Model {

    id;
    fieldsChanged;
    jsonInitial;
    createdBy;
    createdAt;
    modelId;
    jsonChange;
    action;
    modelName;

    nameMainType="change";
    graphFindByIdOperation="change";
    graphFindOperation="changesConnection";

    getArrayFields() {
        let result = {
            "id":{type:"CodeField"},
            "fieldsChanged" : "",
            "jsonInitial" : "",
            "createdBy" : "",
            "createdAt" : "",
            "modelId" : "",
            "jsonChange" : "",
            "action" : "",
            "modelName" : "",
        };
        return result;
    }

}
