import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute } from 'react-router-dom'
import appState from "../state/AppState";
import '../scss/components/info-block.scss';

@observer
class InfoBlockSwitch extends Component {
        setEnabled(enabled) {
        if (enabled) {
            appState.layoutState.infoBlockEnabled = true;
        } else {
            appState.layoutState.infoBlockEnabled = false;
        }
    }

    render() {

            return (
                <div className="block-info-buttons">
                    Mostrar archivos:
                    <button type="button" onClick={()=>this.setEnabled(true)}>ON</button>
                    <button type="button" onClick={()=>this.setEnabled(false)}>OFF</button>
                </div>
            );

    }
}

export default InfoBlockSwitch;

