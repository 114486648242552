import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, observable, toJS } from 'mobx';
import InfoBlock from "./InfoBlock";
import PropTypes from 'prop-types';
import SlotModel from "../models/SlotModel";
import AppLogger from "../util/AppLogger";
import Overlay from "./Overlay";
import appState from "../state/AppState";
import PropsUtil from "../util/PropsUtil";
import { withRouter } from "react-router-dom";
import util from "../util/Util";
import withTranslate from "../translator/withTranslate";
import GraphException from "../network/GraphException";
import GqlErrors from "./status/GqlErrors";
import StatusWorkOrderModal from "../pages/workOrders/StatusWorkOrderModal";
import TypifiedValueModel from "../models/TypifiedValueModel";


@observer
class SlotDetailView extends Component {
    static defaultProps = {
        slot: null
    };
    static propTypes = {
        slot: PropTypes.instanceOf(SlotModel),
    };
    type;
    subType;
    @observable mismoSlot = false;
    @observable slot = "";
    @observable workOrderFinished = {};
    @observable
    gqlErrors = null;

    constructor(props) {
        super(props);
        this.state = {
            modalFinalizada: false
        }
    }

    @computed get mobxListado() {
        return this.props.slot;
    }

    getWorkLogOfSlotAndWorkOrder(workOrder, slotId) {
        let result = [];
        if (workOrder.workLog != null) {
            for (let workLog of workOrder.workLog) {
                if (workLog.slotId === slotId && workLog.workOrderId === workOrder.id) {
                    if (workLog.action != "TASKS" && workLog.action != "DIAGNOSIS") {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    /**
     * Devuelve el nombre de la acción última de esta workOrder: P ej: INPROCESS_WORK_ORDER
     * @param workOrder
     */
    getStatusOfWorkOrder(workOrder) {
        let result = appState.typifiedState.arrStatus[workOrder.status];
        let workLogs = this.getWorkLogOfSlotAndWorkOrder(workOrder, this.props.slot.id);
        //Cojo la última acción hecha con esta workOrder
        if (workLogs.length > 0) {
            result = appState.typifiedState.arrStatusCodes[workLogs[workLogs.length - 1].action];
        }
        this.log("getStatusOfWorkOrder=>");
        this.log({
            getStatusOfWorkOrder: "1",
            typified: appState.typifiedState.arrStatusCodes,
            workLogOfWorkOrder: toJS(workOrder.workLog),
            workLogOfWorkOrderCount: workOrder.workLog != null ? workOrder.workLog.length : "",
            workLogs,
            result,
            slotId: this.props.slot.id
        });
        return result;
    }

    async componentDidMount() {
        this.log("componentDidMount()");
        try {
            await Promise.all([
                this.type = appState.typifiedState.arrWorkOrderType,
                this.subType = appState.typifiedState.arrSubTypeWorkOrders,
                await this.reloadDetail(),
            ]);
            let typifiedModel = new TypifiedValueModel();
            typifiedModel.type = "status";
            let typifiedStatus = await typifiedModel.find();
            this.workOrderFinished = util.getDictSingleFromArray(typifiedStatus);
        } catch (e) {
            this.log("componentDidMount. Exception");
            let gqlErrors = new GraphException().getErrorsFromException(e);
            this.log({ gqlErrors });
            this.gqlErrors = gqlErrors;
        }

    }

    getAssetFromAssetId(assetId) {
        let result = {};
        for (let asset of appState.assetState.assets) {
            if (util.hasValue(assetId)) {
                if (asset.id === assetId) {
                    result = asset;
                    break;
                }
            }
        }
        return result;
    }

    async reloadDetail() {
        this.log("reloadDetail()");
        appState.scheduleDropState.workOrderIds = this.props.slot ? this.props.slot.workOrderIds : "";
        if (this.props.slot && this.props.slot.id != this.slot) {
            this.mismoSlot = false;
        }
        if (this.props.slot != null) {
            await appState.scheduleDropState.loadOrder(this.props.slot.orderId);
            await appState.scheduleDropState.calcularHoras(this.props.slot.orderId);
            await appState.scheduleDropState.calcularTecnicos(this.props.slot.orderId);
            await appState.scheduleDropState.getWorkOrders(this.props.slot.orderId, this.props.slot.workOrderIds);
            this.mismoSlot = true;
            this.slot = this.props.slot.id;
        }
    }


    colorBox() {
        let slot = this.props.slot;
        let claseSlot = "prevent";
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Preventiva") {
            claseSlot = " prevent ";
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Programada") {
            claseSlot = " program ";
        }
        // if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Evento") {
        //     claseSlot = " events ";
        // }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Correctiva") {
            claseSlot = " corrective ";
        }

        return claseSlot
    }

    renderWorkOrders(array, text) {
        const { t } = this.props;
        let claseName = this.colorBox();


        return (
            Object.keys(array).length > 0 &&
            <>
                <div
                    className={text == "slot" ? "c-modal-info__cell__body" : "c-modal-info__cell__body transparent"}>
                    <p className="c-modal-info-cell__text regular">{(text == "slot" ? t("Órdenes de trabajo asignadas a este slot") :
                        t("Otras Ordenes de Trabajo del Pedido")) + ":"}</p>

                    {Object.keys(array).map((nombreGrupo) => (
                            <>
                                <div className={"c-modal-info-cell__box " + claseName}>
                                    <p className="c-modal-info-cell__text blue">{(this.type[nombreGrupo.split(" ")[0]] || "") +
                                    " " + (this.subType[nombreGrupo.split(" ")[1]] || "")}</p>
                                    {array[nombreGrupo].map((workOr, index) => (
                                        <>
                                            {this.getStatusOfWorkOrder(workOr) &&
                                            <p className="c-modal-info-cell__text blue"
                                               style={{ color: this.getStatusOfWorkOrder(workOr).color }}>{this.getStatusOfWorkOrder(workOr).value}</p>
                                            }
                                            <p className="c-modal-info-cell__text blue--italic">{workOr.generalDescription} </p>
                                            <ul className={"c-modal-info-cell__orders blue"}
                                                style={{ color: this.workOrderFinished[workOr.status] && this.workOrderFinished[workOr.status].color }}>
                                                <li className="c-modal-info-cell__orders__item text-uppercase medium">
                                            <span
                                                className="text-click c-modal-info-cell__text text-uppercase"
                                                onClick={() => this.openWorkOrderModal(workOr.id)}>{workOr.code}</span>
                                                </li>
                                                <li className="c-modal-info-cell__orders__item">{workOr.assetId != null ?
                                                    workOr.assetPlate : t("Sin equipo")}</li>
                                                <li className="c-modal-info-cell__orders__item">{workOr.assetId != null ?
                                                    appState.typifiedState.arrAssetTypes[this.getAssetFromAssetId(workOr.assetId).type] :
                                                    util.hasValue(appState.typifiedState.arrAssetTypes[workOr.assetType]) ?
                                                        appState.typifiedState.arrAssetTypes[workOr.assetType] : t("Sin tipo de equipo")}</li>
                                                <li className="c-modal-info-cell__orders__item">{workOr.assetId != null ?
                                                    appState.typifiedState.arrAssetSubtypes[this.getAssetFromAssetId(workOr.assetId).subType] :
                                                    appState.typifiedState.arrAssetSubtypes[workOr.assetSubType] || t("Sin subtipo de equipo")}</li>
                                            </ul>

                                        </>
                                    ))}
                                </div>
                            </>
                        )
                    )}
                </div>
            </>

        )
    }

    async finalizarPedido() {
        this.setState({
            modalFinalizada: true
        })
    }

    openOrderModal() {
        let orderId = this.props.slot.orderId;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalorderls",
            "orderId": orderId,
            "rightModalTab": "order"
        });
    }

    openWorkOrderModal(workOrderId) {
        let orderId = this.props.slot.orderId;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalworkorderls",
            "orderId": orderId,
            "workOrderId": workOrderId,
            "rightModalTab": "workorder"
        });
    }

    onCloseOverlay() {
        this.gqlErrors = [];
        appState.layoutState.backgroundScheduled = 0;
        appState.layoutState.backgroundRowScheduled = 0;
        appState.layoutState.slotview = false;
    }

    downloadPDF(slot) {

    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        const { t } = this.props;
        if (props.slot == null) {
            return null;
        }
        let topSlot = appState.scheduleDropState.slotClickedPosition.top;
        let leftSlot = appState.scheduleDropState.slotClickedPosition.left;
        let pantallaEmpizaEn = window.pageYOffset;
        let altoPantalla = window.innerHeight;
        let styleAbsolute = {};
        let classFlecha = "flecha-arriba";
        let bottom = altoPantalla - topSlot;
        let posicionLimite = altoPantalla / 2 + pantallaEmpizaEn;
        if (topSlot > posicionLimite) {
            //Arriba
            styleAbsolute.bottom = bottom + 20;
            classFlecha = "flecha-debajo";
        } else {
            //Abajo
            styleAbsolute.top = topSlot + 40;
            classFlecha = "flecha-arriba";
        }
        let order = appState.scheduleDropState.order;

        //Calculo del estilo para eje X
        let pantallaYEmpizaEn = window.pageXOffset;
        let anchoPantalla = window.innerWidth;
        let posicionYLimite = anchoPantalla / 2 + pantallaYEmpizaEn;
        if (leftSlot > posicionYLimite) {
            //Izquierda
            styleAbsolute.right = anchoPantalla - leftSlot - 40;
            classFlecha += " flecha-derecha";
        } else {
            //Derecha
            styleAbsolute.left = leftSlot - 15;
            classFlecha += " flecha-izquierda";
        }
        let className = "c-modal-info-cell " + classFlecha;
        return (
            <>
                <GqlErrors errors={this.gqlErrors} />
                <Overlay show={appState.layoutState.slotview} onClick={() => this.onCloseOverlay()} />
                {appState.layoutState.slotview &&
                <InfoBlock componentObject={this}>
                    <div className={className} style={styleAbsolute}>
                        <div className="c-modal-info-cell__header">
                            <div className="c-modal-info-cell__header__left">
                                <span onClick={() => this.openOrderModal()}
                                      className="text-click c-modal-info-cell__text blue text-uppercase">{order && order.code}</span>
                                <p className="c-modal-info-cell__text">{t("Tiempo estimado pedido:")}
                                    <span>{appState.scheduleDropState.horas + "h"}</span>.</p>

                                <p className="c-modal-info-cell__text regular technicals">
                                    <span>{appState.scheduleDropState.tecnicos}</span> {t("Técnicos")}
                                    <p className="c-modal-info-cell__text regular no-technicals">
                                        <span>{appState.scheduleDropState.tecnicosSinAsignar}</span>{t("Técnicos sin asignar")}
                                    </p>
                                </p>

                                <p className="c-modal-info-cell__text medium place">{(order.client && order.client.name + " - " +
                                    order.client.posAddress + " - ") + (order.client && appState.typifiedState.arrZones[order.client.posZoneId] && appState.typifiedState.arrZones[order.client.posZoneId].value)}</p>
                                {util.hasValue(order.comments) &&
                                <p className="c-modal-info-cell__text blue comment">{order.comments}</p>
                                }
                                {util.hasValue(props.slot.comments) &&
                                <p className="c-modal-info-cell__text blue--italic comment">{props.slot.comments} </p>
                                }
                            </div>

                            <div className="c-modal-info-cell__header__right">
                                {props.slot.scheduledTime != null &&
                                <div
                                    className="c-modal-info-cell__square time">{util.getMoment(props.slot.scheduledTime).format('HH:mm') + "h."}</div>
                                }
                                <div
                                    className="c-modal-info-cell__square hours">{props.slot.scheduledDuration + "h."}</div>
                                {props.slot.slotUserRole !== SlotModel.SUPPORT && props.slot.userId != null &&
                                <div
                                    className="text-click btn btn--orders"
                                    onClick={() => this.finalizarPedido()}>
                                    {props.slot.isFinished == 1 ? t("Resumen") : t("Finalizar")}
                                </div>
                                }
                                {props.slot.isFinished == 1 &&
                                <div
                                    className="text-click " onClick={() => this.downloadPDF(slot)}>
                                    <div className="remove-add">
                                        <div className="remove">
                                            <span className="fas fa-file-pdf" />&nbsp;
                                        </div>
                                    </div>
                                </div>
                                }    </div>

                        </div>


                        {this.mismoSlot &&
                        <>
                            {this.renderWorkOrders(appState.scheduleDropState.workOrdersSlotGrouped, "slot")}
                        </>
                        }
                        {this.mismoSlot &&
                        <>
                            {this.renderWorkOrders(appState.scheduleDropState.workOrdersNotSlotGrouped, "notSlot")}
                        </>
                        }

                        {this.state.modalFinalizada &&
                        <StatusWorkOrderModal
                            openModal={this.state.modalFinalizada}
                            slot={this.props.slot}
                            closeModal={() =>
                                this.setState({ modalFinalizada: false })
                            }
                        />
                        }
                    </div>
                </InfoBlock>
                }
            </>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(withTranslate(SlotDetailView));
