import React from 'react';
import { observer } from 'mobx-react';
import { gql } from 'apollo-boost';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import Select2Component from "../../components/fields/Select2Component";
import withTranslate from "../../translator/withTranslate";
import MoreButtons from "../../components/MoreButtons";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/PopUp";
import PropsUtil from "../../util/PropsUtil";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import AssetModel from "../../models/AssetModel";
import InfoBlock from "../../components/InfoBlock";
import util from "../../util/Util";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import AppLogger from "../../util/AppLogger";


@observer
class AssetFormLocation extends BaseForm {

    nameMainType = "asset";
    appState
    clientState

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false
        }
    }

    onCancelOrder() {
        return this.propsUtil.changeUrlRequest({ rightModal: "2" });
    }

    async componentDidMountImpl() {
        this.log("componentDidMountImpl")
        await super.componentDidMountImpl();
        await this.onChangeClientId();
        await appState.typifiedState.loadZones();
        await appState.clientCacheState.loadClientCache();
        await appState.typifiedState.arrayLoadZones();

    }

    getModelQuery() {
        return new AssetModel();
    }


    onEditarCliente(asset) {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalclient",
            "idOrderClient": asset.storeId,
            "assetId": asset.id,
            "rightModalTab": "general"
        });
    }


    client() {
        this.log("appState.clientState.client")

        return appState.clientState.client;
    }

    async onChangeClientId() {
        this.log("onChangeClientId");
        let asset = this.graphDataMainType;
        await appState.clientState.reloadClientFromClientId(asset.storeId);
    }

    getZoneFromAppState(storeId) {
        let result = '';
        if (util.hasValue(storeId)) {
            let client = appState.clientState.client;
            result = client && appState.typifiedState.arrZones[client.posZoneId] && appState.typifiedState.arrZones[client.posZoneId].value;
        }
        return result;

    }

    render() {
        let modalClasses = util.getModalClasses();
        const { t } = this.props;

        this.propsUtil = new PropsUtil(this.props);
        let id = this.graphDataMainType.id || this.props.id;
        let asset = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        return (


            <form
                className="model-form"
                name="formulario"
                noValidate
                onSubmit={(e) => this.handleFormSubmit(e)}
            >

                <InfoBlock componentObject={this}>
                    <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                        {this.props.fromRightModal ?

                            <div className={modalClasses.head}>
                                <PageTitleRightModal
                                    title={"Equipos"}
                                    subtitle={this.graphDataMainType.plate || t("Nuevo")}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}
                                />
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataAssets()}
                                    active={"Ubicación"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                            </div>
                            :
                            <PageTitle
                                title={t("Equipos")}
                                subtitle={this.graphDataMainType.plate || t("Nuevo")}
                            >
                                <MoreButtons
                                    actions={
                                        [
                                            {
                                                title: "Duplicar Equipo ", onClick: () => this.copyAsset(asset)
                                            },
                                            {
                                                title: "Dar de Baja",
                                                icon: 'fas fa-exclamation-triangle',
                                                onClick: () => this.onCancelOrder()
                                            }
                                        ]
                                    }
                                />
                            </PageTitle>
                        }

                        <div className={'card mb-3'}>

                            <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                               error={this.graphStatus.mutationError}
                                               working={this.graphStatus.networkWorking}
                            />
                            <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                                formError={this.graphStatus.formError}
                            />
                            {errorsMapped[""] != null ?
                                <div className="alert alert-danger" role="alert">
                                    {errorsMapped[""].map(error => <div>{error.message}</div>)}
                                </div>
                                :
                                null
                            }
                        </div>

                        <div className={'card mb-3'}>
                            <FormWrapper>
                                {!this.props.fromRightModal &&
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataAssets()}
                                    active={"Ubicación"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                                }


                                <div className={modalClasses.body}>
                                    <GroupOfField title={t('Datos')}
                                                  subtitle={t('Ubicación')}
                                                  icon="fas fa-map-marker-alt"
                                    >

                                        <div className={"col-12"}>
                                            <div className={"row"}>
                                                <Select2Component
                                                    value={asset.storeId}
                                                    onChange={(e) => {
                                                        let result = this.updateInputEvent(e);
                                                        this.onChangeClientId();
                                                        return result;
                                                    }}
                                                    name={"storeId"}
                                                    title={t("Establecimientos")}
                                                    classGroup={"col-md-3"}
                                                    options={appState.clientCacheState.clientCacheForOrderSelect}
                                                    errors={errorsMapped.storeId}
                                                    forceMedia={this.props.fromRightModal ? "md" : ""}
                                                />


                                                <InputTypeComponent
                                                    value={appState.clientState.client && appState.clientState.client.posAddress}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"pos"}
                                                    readOnly={true}
                                                    title={t("Ubicación")}
                                                    placeholder="Ubicación"
                                                    classGroup={"col-md-3 col-lg-3"}
                                                    type={"text"}
                                                    // errors={errorsMapped.pos}
                                                />
                                                <InputTypeComponent
                                                    value={this.getZoneFromAppState(asset.storeId)}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"zone"}
                                                    readOnly={true}
                                                    title={t("Zona")}
                                                    classGroup={"col-md-3"}
                                                    // options={appState.typifiedState.zonesForSelect}
                                                    // errors={errorsMapped.zone}
                                                />


                                                <div className="col-xl-2 text-right">
                                                    <div className="button-form-group">
                                                        {util.hasValue(asset.storeId) ?
                                                            <span className="link-underline ico ico--edit"
                                                                  onClick={() => this.onEditarCliente(asset)}
                                                            >{t("Editar Datos del cliente")}</span>
                                                            :
                                                            <span className="link-underline prohibido ico ico--edit"
                                                            >{t("Editar Datos del cliente")}</span>
                                                        }
                                                    </div>
                                                </div>

                                                <InputTypeComponent
                                                    value={asset.posAddress}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"posAddress"}
                                                    title={t("Ubicación del Equipo")}
                                                    placeholder=""
                                                    classGroup={"col-md-6 col-lg-6"}
                                                    type={"text"}
                                                    info={t('Sólo si tiene una ubicación diferente al establecimiento')}
                                                    errors={errorsMapped.posAddress}
                                                />
                                                <InputTypeComponent
                                                    value={asset.location}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"location"}
                                                    title={t("Emplazamiento")}
                                                    placeholder=""
                                                    classGroup={"col-md-6 col-lg-6"}
                                                    type={"text"}
                                                    info={t('Lugar donde se encuentra el equipo dentro de las instalaciones del Cliente')}
                                                    errors={errorsMapped.comments}
                                                />
                                            </div>
                                        </div>
                                    </GroupOfField>
                                </div>
                            </FormWrapper>
                        </div>
                        {this.props.fromRightModal ?
                            <div className=" fixed-bottom">
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </div>
                            :
                            <FormButtons id={id} formStatus={this.graphStatus} />
                        }

                        <AlertModal isOpen={this.propsUtil.getRequest("rightModal") === "2"}
                                    onCloseModal={() => this.propsUtil.changeUrlRequest({ rightModal: null })}
                        >
                            <PopUp
                                title={t('Dar de Baja')}
                                icon="fas fa-exclamation-triangle"
                                text='¿Estas Seguro de que desea Dar de baja este equipo?'
                                label1='No cancelar'
                                label2='Cancelar'
                                onClick1={this.noCancelOrder}
                                onClick2={this.cancelOrder}
                                func={this.handleFormSubmit}
                            />
                        </AlertModal>


                    </div>
                </InfoBlock>
            </form>

        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(withApollo(AssetFormLocation));
