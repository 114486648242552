import React from 'react';
import { observer } from "mobx-react/index";
import appState from '../state/AppState';
import { withApollo } from "react-apollo";
import PropTypes from 'prop-types';
import TabsData from "../layout/TabsData";
import SlotModel from "../models/SlotModel";
import OrderFormSlot from "../pages/orders/OrderFormSlot";
import { computed } from "mobx";
import BaseEditableTable from "../pages/base/BaseEditableTable";
import util from "../util/Util";
import PropsUtil from "../util/PropsUtil";
import { withRouter } from "react-router-dom";
import InfoBlock from "./InfoBlock";
import VsfButton from "./VsfButton";
import WorkOrderModel from "../models/WorkOrderModel";
import AppLogger from "../util/AppLogger";
import withTranslate from "../translator/withTranslate";

@observer
class SlotsTable extends BaseEditableTable {


    static defaultProps = {
        workOrders: [],
        foreingKeyfield: "",
        fromOrder: false,
        idWorkOrder: null
    };

    static propTypes = {
        workOrders: PropTypes.array, // Listado de workoOrders Para poder mostrar en el desplegable de Selects2
        foreingKeyfield: PropTypes.string,
        fromOrder: PropTypes.bool,
        idWorkOrder: PropTypes.number
    };

    //@observable slots = [];

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.tabsData = new TabsData();
        this.foreingKeyfield = this.props.foreingKeyfield;
        this.state = {
            orderId: 0,
            fromWorkOrder: this.props.fromWorkOrder,
            hasSomeRowEditing: false
        }
    }

    @computed get mobxListado() {
        return appState.slotState.slots;
    }

    getModelTable() {
        return new SlotModel();
    }

    async componentDidMountImpl() {
        super.componentDidMountImpl();
        await this.reloadTable(this.getFormId());
        await appState.assetState.getAssets();
        await appState.typifiedState.loadWorkOrderType();
        appState.typifiedState.arrayLoadWorkOrderType();
        await appState.typifiedState.loadSubTypeWorkOrder();
        appState.typifiedState.arrayLoadsubTypeWorkOrders();
        await appState.typifiedState.loadZones();
        appState.typifiedState.arrayLoadZones();
    }

    async reloadTable(orderId) {
        let slotQuery = new SlotModel();
        let slotsModels;
        if (this.props.idWorkOrder != null) {
            let workOrderQuery = new WorkOrderModel();
            let result = await workOrderQuery.findById(this.props.idWorkOrder);
            slotsModels = await slotQuery.getSlotsFromOrder(result.orderId);
            let miSlot = [];
            for (let slot of util.arrayModelToPlainObjects(slotsModels)) {
                if (util.perteneceA(this.props.idWorkOrder, slot.workOrderIds)) {
                    miSlot.push(slot);
                } else if (util.perteneceA("-1", slot.workOrderIds)) {
                    miSlot.push(slot);
                }
            }
            await appState.workOrderState.reloadFromOrderId(result.orderId);
            this.setState({
                orderId: result.orderId
            });
            appState.slotState.slots = this.ordenarSlots(miSlot);
        } else {
            if (util.hasValue(orderId)) {
                slotsModels = await slotQuery.getSlotsFromOrder(orderId);
                appState.slotState.slots = this.ordenarSlots(util.arrayModelToPlainObjects(slotsModels));
                await appState.workOrderState.reloadFromOrderId(orderId);
            }
        }
    }

    ordenarSlots(array) {
        let slotsGrouped = {};
        for (let slot of array) {
            let key = slot.assignedSlotMainId != null ? slot.assignedSlotMainId : slot.id;
            if (slotsGrouped[key] == null) {
                slotsGrouped[key] = [];
            }
            slotsGrouped[key].push(slot);
        }
        let keysSlotGrouped = Object.keys(slotsGrouped);
        let arrSlots = [];
        keysSlotGrouped.map(slotId => {
            slotsGrouped[slotId].map(slot => {
                arrSlots.push(slot);
            })
        });
        return arrSlots;
    }

    calcularHoras() {
        let result = 0.0;
        this.mobxListado.map((slot, index) => {
            if (slot.scheduledDuration != null) {
                //parseFloat(slot.scheduledDuration).replace(',', '');
                if (result == 0)
                    result = parseFloat(slot.scheduledDuration);
                else if (slot.scheduledDuration != '') {
                    result = parseFloat(slot.scheduledDuration) + parseFloat(result);
                }
            }
        });
        return result;
    }

    calculaFechas() {
        let result = "1-1-2089";
        this.mobxListado.map((slot, index) => {
            if (slot.scheduledTime != null) {
                if (util.localizeIsoDate(slot.scheduledTime) < util.localizeIsoDate(result))
                    result = util.localizeIsoDate(slot.scheduledTime);
            }
        });
        return result;
    }


    render() {
        const { t } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let horas = this.calcularHoras();
        let orderId= this.props.id;
        if (this.props.fromWorkOrder) {
            orderId = this.state.orderId
        } else {
            orderId = this.props.id;
        }

        return (
            <InfoBlock componentObject={this}>

                <div className="row mt-4">
                    <div className="col-12">
                        <div className="table-edit">
                            <table className="table visible-text"
                                   summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                <thead>
                                {this.mobxListado.length == 0 &&
                                <tr>
                                    <td colSpan="8" className="title-cell">{t('No hay ninguna planificacion creada')}
                                    </td>
                                </tr>
                                }
                                {this.mobxListado.length != 0 &&
                                <tr>
                                    <th scope="col">{t('Tecnico')}</th>
                                    <th scope="col">{t('Fecha y Hora')}</th>
                                    <th scope="col">{t('Duración')}</th>
                                    <th scope="col">{this.props.fromOrder ? t("Orden de Trabajo") : t("O.T. Relacionadas")}</th>
                                    <th>&nbsp;</th>
                                </tr>
                                }
                                </thead>
                                <tbody className="border-end">

                                {this.mobxListado.map((slot, index) => (
                                    <OrderFormSlot
                                        key={slot && slot.id}
                                        orderId={orderId}
                                        workOrderId={this.props.idWorkOrder}
                                        rowIndex={index}
                                        row={slot}
                                        reload={(e) => this.reloadTable(e)}
                                        slotsLenghtCero={this.mobxListado.length == 1}
                                        fromWorkOrder={this.props.fromWorkOrder}
                                        workOrders={this.props.workOrders}
                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                        {...this.props}
                                    />
                                ))}

                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan="3">{t('TOTAL')}</td>
                                    <td colSpan="4">{isNaN(horas) ?
                                        0 + " h."
                                        :
                                        horas
                                        + " h."}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="text-right">
                            <VsfButton
                                disabled={this.state.hasSomeRowEditing}
                                label={"Crear nueva"}
                                onClick={_ => this.onOpenRowNewEmpty()}
                                isEditing={this.state.hasSomeRowEditing}
                                classButton="btn--loader"
                            />
                        </div>
                    </div>

                </div>

            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withTranslate(withRouter(withApollo(SlotsTable)));
