import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { dir } from "async";
import PropTypes from 'prop-types';
import VsfLink from "./VsfLink";

@observer
export default class FieldListComponent extends Component {

    static defaultProps = {
        openQuickEdit: () => {
        }
    }

    static propTypes = {
        //Acción de pulsar en el botón de quick view
        openQuickEdit: PropTypes.func,
    }

    /**
     * Obtiene el valor de esa expresión path (tipo cliente.nombre) dentro de este objeto javascript "row"
     *  pathStringWithDots puede tener valores tipo {titulo:"Mi titulo", client: { name:"nombre" } por lo que este sistema puede acceder a esas propiedades como "titulo" o como "client.name"

     * @param row
     * @param pathStringWithDots
     * @returns {string}
     */
    getValueFromPath(row, pathStringWithDots) {
        let value = "";
        let pathNames = pathStringWithDots.split(".");
        let parcial = row;
        for (let pathName of pathNames) {
            if (parcial != null) {
                parcial = parcial[pathName];
            }
        }
        value = parcial;
        return value;
    }

    openQuickEdit(row) {
        /*
        this.props.listState.quickEdit.row = row;
        this.props.listState.quickEdit.modalOpen = true;

        let links = new Links(this.props.location);
        let newUrl = links.getUrlLink({contacts:row.id});
        this.props.history.replace(newUrl);
        */
        this.props.openQuickEdit(row);
    }

    render() {

        let props = this.props;
        let fields = this.props.fields;
        let urlForm = this.props.urlForm;
        let listData = this.props.listData;
        let params = this.props.params;

        let rows = this.props.listData.connection.items;
        let showColumnActions = false;
        if (props.quickEdit || this.props.quickView) {
            showColumnActions = true;
        }

        showColumnActions = true;

        return (
            <React.Fragment>
                <tbody>
                {props.status.formError && <tr>
                    <td>
                        {/* (props.status.formLoading) && <div>Loading ... </div> */}
                        <p>ERROR Query ...</p>
                    </td>
                </tr>}
                {rows.map(((row, index) =>
                        <tr key={props.urlForm + "-" + row.id + "-" + index}>
                            {showColumnActions &&
                            <td>
                                {props.quickView &&
                                <div onClick={() => this.openQuickEdit(row)}
                                     className="list-quickview-button">

                                    <span className="fas fa-eye"> </span>
                                </div>
                                }
                                {props.quickEdit &&
                                <VsfLink to={`${urlForm}/${row.id}`} className="list-edit-button">
                                {/*<VsfLink to={`${urlForm}/${row.id}?${params}`} className="list-edit-button">*/}
                                    <span className="fas fa-edit"> </span>
                                </VsfLink>
                                }
                            </td>
                            }
                            {fields.map(((field, fieldIndex) => {
                                    let showLink = false;
                                    let CustomItemComponent = field.component;
                                    showLink = field.link === true;
                                    //let value = row[field.name];

                                    let value = this.getValueFromPath(row, field.name);
                                    return (

                                        field.visible &&
                                        <td key={props.urlForm + "-" + row.name + " - " + field.name + " - " + fieldIndex}>
                                            {showLink ?
                                                <VsfLink to={`${urlForm}/${row.id}`}>
                                                    {/*<VsfLink to={`${urlForm}/${row.id}?${params}`}>*/}
                                                    {props.hierarchy && field.name == "code"
                                                        ?
                                                        <CustomItemComponent id={row.id} row={row} name={field.name}
                                                                             value={row.label} urlForm={urlForm} />
                                                        :
                                                        <CustomItemComponent id={row.id} row={row} name={field.name}
                                                                             value={value} urlForm={urlForm} />
                                                    }
                                                </VsfLink>
                                                :
                                                <CustomItemComponent id={row.id} row={row} name={field.name}
                                                                     value={value} urlForm={urlForm} />
                                            }
                                        </td>
                                    )
                                }
                            ))}

                        </tr>
                ))}
                </tbody>
            </React.Fragment>
        );
    }

}
