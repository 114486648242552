import { observable } from 'mobx';
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import ScheduleVisitModel from "../models/ScheduleVisitModel";
import OrderModel from "../models/OrderModel";
import SlotModel from "../models/SlotModel";
import WorkOrderModel from "../models/WorkOrderModel";
import moment from "moment";

class ScheduleDropState {

    @observable scheduledOrders = [];
    @observable order = {};
    @observable horas = 0;
    @observable tecnicos = 0;
    @observable tecnicosSinAsignar = 0;
    @observable scheduleVisits = [];
    @observable workOrdersNotSlotGrouped = [];
    @observable workOrdersSlot = [];
    @observable workOrdersSlotGrouped = [];
    @observable workOrderIds = "";
    @observable zona;
    /**
     * Almaceno un diccionario con la key el id de ScheduleVisit y el value el objeto
     * @type {{}}
     */
    @observable scheduleVisitsDict = {};
    @observable workOrdersOnlyWithMonth = [];
    @observable workOrdersOnlyWithMonthGrouped = {};
    @observable rowsWithoutTechnicals = [];
    //Slots para la parte de planificación
    @observable allSlotsDict = [];
    //Slots agrupados para poder saber el número de elementos en base a tecnico - dia - etc
    @observable allSlotsGrouped = {};
    @observable entradaComida = 12;
    @observable salidaComida = 14;
    @observable slotClicked = null;
    @observable slotClickedPosition = { top: 0, left: 0 };
    @observable clients = [];
    @observable horas = {};
    @observable slotsFromClientDict = {};

    /**
     * Contendrá un array con las horas forzadas. Por ejemplo: {"2019-01-01":{min:5,max:23}}
     * A la hora de pintar tendrá en cuenta este array para que sea el que se utilice
     * @type {{}}
     */
    @observable dateRangeForced = {};
    @observable ordersDict = [];
    @observable scheduledDict = [];

    /**
     * A partir de; hora - tecnico - lineasinasignar - fecha devuelve el índice dentro de la tabla allSlotsDict con el objeto asociado.
     * @returns {{}}
     */
    getCacheWorkUnits(optionTime) {
        let cacheWorkUnits = {};
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            let hour = "";
            if (optionTime === "mes") {
                if (util.getHourFromDatetime(slot.scheduledTime) < 14) {
                    hour = 8
                } else {
                    hour = 14;
                }
            } else {
                hour = util.getString(util.getHourFromDatetime(slot.scheduledTime));
            }
            let technical = util.getString(slot.userId);
            let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime));
            let lineaSinAsignar = util.getString(slot.lineaSinAsignar);
            let key = hour + ";" + util.getString(technical) + ";" + lineaSinAsignar + ";" + isoDate;
            if (cacheWorkUnits[key] == null) {
                cacheWorkUnits[key] = [];
            }

            cacheWorkUnits[key].push(slotId);
            //console.log({cached:1,key,hour,technical,isoDate,lineaSinAsignar,workOrder});
        }
        return cacheWorkUnits;
    }

    getInitialHourByTechnical() {
        let result = {};
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            let technical = util.getString(slot.userId);
            let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime));
            if (result[technical + "-" + isoDate] == null) {
                result[technical + "-" + isoDate] = 8;
            }
            result[technical + "-" + isoDate] = Math.min(result[technical + "-" + isoDate], util.getHourFromDatetime(slot.scheduledTime));
        }
        //this.log("getInitialHourByTechnical=>");
        //this.log({result});
        return result;
    }

    /**
     * Coloca los slots y los junta a partir de las 8 o la primera hora anterior a ella.
     * @returns {{}}
     */
    getCacheWorkUnitsFlags() {
        let cacheWorkUnitsFlags = {};
        let horaFlag = {};
        let objWithInitialHourByTechnical = this.getInitialHourByTechnical();
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            let technical = util.getString(slot.userId);
            let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime));
            let lineaSinAsignar = util.getString(slot.lineaSinAsignar);
            let keyFlags = util.getString(technical) + ";" + lineaSinAsignar + ";" + isoDate;
            // Me devolvera un array como este: {"{technical}-{date}":8, ... }

            if (!slot.isDateTimeFixed) {
                if (horaFlag[keyFlags] == null) {
                    horaFlag[keyFlags] = objWithInitialHourByTechnical[technical + "-" + isoDate] + slot.scheduledDuration;
                    cacheWorkUnitsFlags[objWithInitialHourByTechnical[technical + "-" + isoDate] + ";" + keyFlags] = [];
                    cacheWorkUnitsFlags[objWithInitialHourByTechnical[technical + "-" + isoDate] + ";" + keyFlags].push(slotId);
                } else {
                    cacheWorkUnitsFlags[horaFlag[keyFlags] + ";" + keyFlags] = [];
                    cacheWorkUnitsFlags[horaFlag[keyFlags] + ";" + keyFlags].push(slotId);
                    horaFlag[keyFlags] = util.getString(parseInt(horaFlag[keyFlags]) + slot.scheduledDuration);
                }
            } else {
                let hour = util.getString(util.getHourFromDatetime(slot.scheduledTime));
                cacheWorkUnitsFlags[hour + ";" + keyFlags] = [];
                cacheWorkUnitsFlags[hour + ";" + keyFlags].push(slotId);
            }
        }
        return cacheWorkUnitsFlags;
    }

    async loadPendingClients(zona, cliente) {
        let objWithOrderIds = {};
        for (let slotId in this.allSlotsDict) {
            let slot = this.allSlotsDict[slotId];
            if (!util.hasValue(slot.scheduledTime)) {
                objWithOrderIds[slot.orderId] = "";
            }
        }
        let arrayDeOrdersIds = Object.keys(objWithOrderIds);
        let query = new OrderModel();
        query.filters = [
            { "fieldName": "id", "fieldValue": arrayDeOrdersIds.join(","), "filterOperator": "IN" },
        ];
        query.addRelatedTable("client");
        let orders = await query.find();
        let ordersDict = util.getDictSingleFromArray(orders);
        let clients = {};
        for (let order of orders) {
            if (clients[order.clientId] == null) {
                if (zona == null && cliente == null) {
                    clients[order.clientId] = [];
                    clients[order.clientId].push(order.client);
                } else if (order.client.posZoneId == zona) {
                    clients[order.clientId] = [];
                    clients[order.clientId].push(order.client);
                } else if (order.clientId == cliente) {
                    clients[order.clientId] = [];
                    clients[order.clientId].push(order.client);
                }
            }
        }
        let clientKey = Object.keys(clients);
        let clientesArray = [];
        for (let client of clientKey) {
            clients[client].map(cliente => {
                clientesArray.push(cliente);
            })
        }
        let slotsFromClientDict = {};
        for (let slotId in this.allSlotsDict) {
            let slot = this.allSlotsDict[slotId];
            let order = ordersDict[slot.orderId];
            if (!util.hasValue(slot.scheduledTime)) {
                if (order != null) {
                    if (slotsFromClientDict[order.clientId] == null) {
                        slotsFromClientDict[order.clientId] = [];
                    }
                    slotsFromClientDict[order.clientId].push(slot);
                }
            }
        }

        this.clients = util.arrayModelToPlainObjects(clientesArray);
        this.slotsFromClientDict = slotsFromClientDict;

        localStorage.setItem('loadClients', "Pendiente");

    }

    async loadClients(zona, cliente) {
        let objWithOrderIds = {};
        for (let slotId in this.allSlotsDict) {
            let slot = this.allSlotsDict[slotId];
            objWithOrderIds[slot.orderId] = "";
        }
        let arrayDeOrdersIds = Object.keys(objWithOrderIds);
        let query = new OrderModel();
        query.filters = [
            { "fieldName": "id", "fieldValue": arrayDeOrdersIds.join(","), "filterOperator": "IN" },
        ];
        query.addRelatedTable("client");
        let orders = await query.find();
        let ordersDict = util.getDictSingleFromArray(orders);
        let clients = {};
        for (let order of orders) {
            if (clients[order.clientId] == null) {
                if (zona == null && cliente == null) {
                    clients[order.clientId] = [];
                    clients[order.clientId].push(order.client);
                } else if (order.client.posZoneId == zona) {
                    clients[order.clientId] = [];
                    clients[order.clientId].push(order.client);
                } else if (order.clientId == cliente) {
                    clients[order.clientId] = [];
                    clients[order.clientId].push(order.client);
                }

            }
        }
        let clientKey = Object.keys(clients);
        let clientesArray = [];
        for (let client of clientKey) {
            clients[client].map(cliente => {
                clientesArray.push(cliente);

            })
        }
        this.clients = util.arrayModelToPlainObjects(clientesArray);
        let slotsFromClientDict = {};
        for (let slotId in this.allSlotsDict) {
            let slot = this.allSlotsDict[slotId];
            let order = ordersDict[slot.orderId];
            if (order != null) {
                if (slotsFromClientDict[order.clientId] == null) {
                    slotsFromClientDict[order.clientId] = [];
                }
                slotsFromClientDict[order.clientId].push(slot);
            }
        }
        this.slotsFromClientDict = slotsFromClientDict;
        localStorage.setItem('loadClients', "Todas");
    }

    async loadClientsFromScheduledByClient(dateFrom) {
        let query = new OrderModel();
        query.filters = [
            { "fieldName": "scheduledDate", "fieldValue": dateFrom, "filterOperator": "EQ" },
        ];
        query.addRelatedTable("client");
        let orders = await query.find();
        this.ordersDict = util.getDictSingleFromArray(orders);
        let scheduledVisitsIds = {};
        let clients = {};
        for (let order of orders) {
            if (clients[order.clientId] == null) {
                clients[order.clientId] = [];
                if (this.zona == null) {
                    clients[order.clientId].push(order.client);
                } else if (order.client.posZoneId === this.zona) {
                    clients[order.clientId].push(order.client);
                }
            }
            scheduledVisitsIds[order.scheduleVisitId] = "";
        }
        let scheduledKey = Object.keys(scheduledVisitsIds);
        let queryScheduled = new ScheduleVisitModel();
        queryScheduled.filters = [
            { "fieldName": "id", "fieldValue": scheduledKey.join(','), "filterOperator": "IN" },
        ];
        let schedules = await queryScheduled.find();
        this.scheduledDict = util.getDictSingleFromArray(schedules);
        let clientKey = Object.keys(clients);
        let clientesArray = [];
        for (let client of clientKey) {
            clients[client].map(cliente => {
                clientesArray.push(cliente);
            })
        }
        this.clients = util.arrayModelToPlainObjects(clientesArray);

        let objWithOrderIds = {};
        for (let order of orders) {
            objWithOrderIds[order.id] = "";
        }
        let arrOrders = Object.keys(objWithOrderIds);
        let slotsQuery = new SlotModel();
        slotsQuery.addRelatedTable("order");
        slotsQuery.filters = [
            { "fieldName": "orderId", "fieldValue": arrOrders.join(","), "filterOperator": "IN" },
        ];
        let slots = await slotsQuery.find();

        let allSlotsDict = {};
        let slotsFromClientDict = {};
        for (let slot of slots) {
            let order = this.ordersDict[slot.orderId];
            if (order != null) {
                if (slotsFromClientDict[order.clientId] == null) {
                    slotsFromClientDict[order.clientId] = [];
                }
                if (slot.slotUserRole == SlotModel.MAIN) {
                    slotsFromClientDict[order.clientId].push(slot.id);
                }
            }
            allSlotsDict[slot.id] = slot;
        }
        this.slotsFromClientDict = slotsFromClientDict;
        this.allSlotsDict = allSlotsDict;
        this.allSlotsGrouped = this.getCacheWorkUnitsByClient();
    }

    getDayAndMonth(date) {
        return moment(date).format("MMDD");
    }

    getCacheWorkUnitsByClient() {
        let optionTime = "mes";
        let cacheWorkUnits = {};
        let horas = { "manyana": {}, "tarde": {} };
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            if (slot.scheduledTime != null) {
                if (this.zona == null) {
                    if (horas.manyana[this.getDayAndMonth(slot.scheduledTime)] == null) {
                        horas.manyana[this.getDayAndMonth(slot.scheduledTime)] = 0;
                    }
                    if (horas.tarde[this.getDayAndMonth(slot.scheduledTime)] == null) {
                        horas.tarde[this.getDayAndMonth(slot.scheduledTime)] = 0;
                    }
                    if (util.getMoment(slot.scheduledTime).format("H") < 14) {
                        horas.manyana[this.getDayAndMonth(slot.scheduledTime)] += slot.scheduledDuration;
                    } else {
                        horas.tarde[this.getDayAndMonth(slot.scheduledTime)] += slot.scheduledDuration;
                    }
                } else if (order && this.zona == order.client.posZoneId) {
                    if (horas.manyana[this.getDayAndMonth(slot.scheduledTime)] == null) {
                        horas.manyana[this.getDayAndMonth(slot.scheduledTime)] = 0;
                    }
                    if (horas.tarde[this.getDayAndMonth(slot.scheduledTime)] == null) {
                        horas.tarde[this.getDayAndMonth(slot.scheduledTime)] = 0;
                    }
                    if (util.getMoment(slot.scheduledTime).format("H") < 14) {
                        horas.manyana[this.getDayAndMonth(slot.scheduledTime)] += slot.scheduledDuration;
                    } else {
                        horas.tarde[this.getDayAndMonth(slot.scheduledTime)] += slot.scheduledDuration;
                    }
                }
            }
            let hour = "";
            if (optionTime === "mes") {
                if (util.getHourFromDatetime(slot.scheduledTime) < 14) {
                    hour = 8
                } else {
                    hour = 14;
                }
            } else {
                hour = util.getString(util.getHourFromDatetime(slot.scheduledTime));
            }
            let order = this.ordersDict[slot.orderId];
            let clientId = "";
            if (order != null) {
                clientId = util.getString(order.clientId);
            }
            if (slot.scheduledTime != null && slot.slotUserRole == SlotModel.MAIN) {
                let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime));
                let key = hour + ";" + util.getString(clientId) + ";" + isoDate;
                if (cacheWorkUnits[key] == null) {
                    cacheWorkUnits[key] = [];
                }
                cacheWorkUnits[key].push(slotId);
            }
        }

        this.horas = horas;
        //this.log({ cacheWorkUnits });
        return cacheWorkUnits;
    }

    /**
     * Carga los tipos de visitas que se pueden planificar
     * */
    async loadScheduledVisits() {
        let scheduleVisit = new ScheduleVisitModel();
        scheduleVisit.addRelatedTable("scheduleVisitSlots");
        let result = await scheduleVisit.find();
        this.scheduleVisits = result;
        let resultDict = {};
        for (let visit of result) {
            resultDict[visit.id] = visit;
        }
        this.scheduleVisitsDict = resultDict;
    }


    createTecnicosSinAsignar() {
        let workOrders = Object.values(this.allSlotsDict);
        let maxLineas = 0;
        for (let i = 0; i < workOrders.length; i++) {
            if (workOrders[i].lineaSinAsignar != null) {
                maxLineas = Math.max(workOrders[i].lineaSinAsignar, maxLineas);
            }
        }
        maxLineas++;
        let result = [];
        for (let i = 1; i <= maxLineas; i++) {
            result.push({ id: i, name: "Técnico " + i });
        }
        this.rowsWithoutTechnicals = result;
    }


    /**
     *
     * @param slot
     * @param lineas objeto con un indice cada linea { 0: [slot1, slot2] }
     * @param j indice que indica si esta OT se puede incluir en esta linea
     */
    cabeEnLinea(slot, lineas, j) {
        // this.log("cabeEnLinea");
        // this.log("slot");
        // this.log(slot);
        // this.log("lineas");
        //
        // this.log(lineas);
        // this.log("j");
        //
        // this.log(j);
        let slotsEnLinea = lineas[j];
        let inicio = moment(slot.scheduledTime);
        let final = moment(slot.scheduledTime);
        final.add(slot.scheduledDuration, 'hours');
        let cabe = true;
        for (/** @type SlotModel */ let slotComparar of slotsEnLinea) {
            let inicioComparar = moment(slotComparar.scheduledTime);
            let finalComparar = moment(slotComparar.scheduledTime);
            finalComparar.add(slotComparar.scheduledDuration, 'hours');
            if (inicioComparar.unix() <= inicio.unix() && inicio.unix() < finalComparar.unix()) {
                cabe = false;
                break;
            }
            if (inicioComparar.unix() < final.unix() && final.unix() <= finalComparar.unix()) {
                cabe = false;
                break;
            }
            //this.log("cabeEnLinea. fila " + j + " slot.id:" + slot.id + " rango " + inicioComparar.format() + " - " + finalComparar.format() + " =>" + cabe);
        }
        //  this.log("**** cabeEnLinea.Final fila " + j + " slot.id:" + slot.id + " " + inicio.format() + " - " + final.format() + "=>" + cabe);
        //cabe=false;
        return cabe;
    }

    /**
     * A partir de un listado de workOrders, los que no tengan técnico debe asignarles un campo "lineaSinAsignar" desde 1 hasta n
     * @param workOrders
     */
    addEmptyCellToWorkOrders() {
        let slotsArr = Object.values(this.allSlotsDict);
        let lineasObj = { 1: [] };
        // this.log("lineasObj");
        // this.log(lineasObj);
        for (let i = 0; i < slotsArr.length; i++) {
            // this.log("addEmptyCellToWorkOrders i:" + i + " slotsArr[i].id:" + slotsArr[i].id);
            if (slotsArr[i].scheduledTime != null && slotsArr[i].userId == null) {
                let numLineas = Object.keys(lineasObj).length;
                let haCabidoEnFila = false;
                // this.log("addEmptyCellToWorkOrders itero sobre numLineas:" + numLineas);
                for (let j = 1; j <= numLineas; j++) {
                    if (this.cabeEnLinea(slotsArr[i], lineasObj, j)) {
                        slotsArr[i].lineaSinAsignar = j;
                        if (lineasObj[j] == null) {
                            lineasObj[j] = [];
                        }
                        lineasObj[j].push(slotsArr[i]);
                        haCabidoEnFila = true;
                        break;
                    }
                }
                if (!haCabidoEnFila) {
                    lineasObj[numLineas + 1] = [];
                    slotsArr[i].lineaSinAsignar = numLineas + 1;
                    lineasObj[numLineas + 1].push(slotsArr[i]);
                }
            }
        }
        for (let i = 0; i < slotsArr.length; i++) {
            if (slotsArr[i].lineaSinAsignar != null) {
                //console.log("addEmptyCellToWorkOrders i:" + i + " lineaSinAsignar:" + slotsArr[i].lineaSinAsignar);
            }
        }
    }

    async loadOrder(orderId) {
        let orderModel = [];
        if (orderId != null) {
            let orderQuery = new OrderModel();
            orderQuery.addRelatedTable("client");
            orderModel = await orderQuery.findById(orderId);
            this.order = orderModel.toPlainObject();
        }
    }

    async getWorkOrders(orderId, workIds) {
        if (orderId != null) {
            let workOrderIds = workIds;
            let workOrderQuery = new WorkOrderModel();
            let workOrders = await workOrderQuery.getWorkOrdersFromOrderId(orderId);
            let workOrdersSlot = [];
            let workOrdersNotSlot = [];
            for (let workOrder of workOrders) {
                if (util.perteneceA("-1", workOrderIds)) {
                    workOrdersSlot.push(workOrder);
                } else {
                    if (util.perteneceA(workOrder.id, workOrderIds)) {
                        workOrdersSlot.push(workOrder);
                    } else {
                        workOrdersNotSlot.push(workOrder);
                    }
                }
            }
            this.workOrdersSlot = workOrdersSlot
            this.workOrdersSlotGrouped = this.ordenar(workOrdersSlot);
            this.workOrdersNotSlotGrouped = this.ordenar(workOrdersNotSlot);
        }
    }

    ordenar(array) {
        let workOrdersGrouped = {};
        //Ordeno el array que se me pasa por el tipo para que salgan ordenadas en la impresion
        array.sort(function (a, b) {
            if (a.type > b.type) {
                return 1;
            }
            if (a.type < b.type) {
                return -1;
            }
            return 0;
        });
        for (let workOrder of array) {
            let key = (util.getString(workOrder.type) || ".") + " " + (util.getString(workOrder.subType) || ".");
            if (workOrdersGrouped[key] == null) {
                workOrdersGrouped[key] = [];
            }
            workOrdersGrouped[key].push(workOrder);
        }
        return workOrdersGrouped;
    }

    async calcularHoras(orderId) {
        let horas = 0;
        if (orderId != null) {
            let slotQuery = new SlotModel();
            let slotsModels = await slotQuery.getSlotsFromOrder(orderId);
            slotsModels.map((slot, index) => {
                if (slot.scheduledDuration != null) {
                    horas += slot.scheduledDuration;
                }
            });
        }
        this.horas = horas;
    }

    async calcularTecnicos(orderId) {
        let result = 0;
        let resultNoAsignados = 0;
        if (orderId != null) {
            let slotQuery = new SlotModel();
            let slotsModels = await slotQuery.getSlotsFromOrder(orderId);
            slotsModels.map((slot, index) => {
                if (slot.userId != null) {
                    result++;
                } else {
                    resultNoAsignados++;
                }
            });
        }
        this.tecnicos = result;
        this.tecnicosSinAsignar = resultNoAsignados;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default ScheduleDropState;
