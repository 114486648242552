import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import { withApollo } from "react-apollo";
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import withTranslate from "../../translator/withTranslate";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import Select2Component from "../../components/fields/Select2Component";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import Client from "../../models/ClientModel";
import PageTitleRightModal from "../../components/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import MoreButtons from "../../components/MoreButtons";
import PopUp from "../../components/PopUp";
import AlertModal from "../../subpages/AlertModal";
import util from '../../util/Util';
import InfoBlock from "../../components/InfoBlock";
import RadioButtonComponent from "../../components/fields/RadioButtonComponent";
import { observable } from "mobx";

@observer
class ClientForm extends BaseForm {
    @observable isPos;

    nameMainType = "client";

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false
        }
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.typifiedState.loadGroup();
        await appState.typifiedState.loadZones();
        await appState.typifiedState.loadChains();

        await appState.clientCacheState.loadClientCache();
        appState.clientCacheState.formId = this.getFormId();

        this.log("componentDidMountImpl")

        this.log(this.isPos)
        if (!util.hasValue(this.isPos)) {
            let client = await this.graphDataMainType;
            this.isPos = client.isPos
        }
        this.log(this.isPos)
    }

    getModelQuery() {
        return new Client();
    }

    onCancelOrder() {
        return this.propsUtil.changeUrlRequest({ rightModal: "2" });
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        this.isPos = model.isPos

    }

    clickErroresFormulario() {
        this.setState({
            errorFormulario: false
        });
        setTimeout(() => {
            appState.layoutState.loadingForm = false;
        }, 3000);
        setTimeout(() => {
            this.graphStatus.mutationError = 0;
        }, 5000);
    }

    urlForOrderFromClient(client) {
        let url = '/order/ls/?filters=';
        url += '[{\"fieldKey\":\"clientId\",\"fieldName\":\"clientId\",\"fieldValue\":\"' + client.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + client.name + '\"}]';
        return url;
    }

    urlForAssetFromClient(client) {
        let url = '/asset/ls/?filters=';
        url += '[{\"fieldKey\":\"storeId\",\"fieldName\":\"storeId\",\"fieldValue\":\"' + client.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + client.name + '\"}]';
        return url;
    }

    urlForFormAssetFromClient(client) {
        return '/asset/form/?propietary=' + client.parentId + '&storeId=' + client.id;
    }

    urlForClientFromClient(client) {
        let url = "";
        if (client.isPos == 1) {
            url = '/client/form/' + client.parentId;
        } else {
            url = '/posClients/ls/?filters=';
            url += '[{\"fieldKey\":\"parentId\",\"fieldName\":\"parentId\",\"fieldValue\":\"' + client.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + client.companyName + '\"}]';
        }
        return url;
    }

    /**
     * Metodo que tras actualizar los valores del typified, actualiza el select
     *
     * @param e
     */
    async handleFormSubmitClientValue(e) {
        await this.handleFormSubmit(e);
        this.reloadClientValues();
    }

    /**
     * Actualiza el select jerarquico
     */
    reloadClientValues() {
        appState.clientCacheState.loadClientCache();
    }

    render() {
        let id = this.getFormId();
        let { t } = this.props;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);

        let client = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""] && errorsMapped[""].map(error => error.message);
            this.state.errorFormulario = true;
        }
        let optionsPropietary = [
            { label: "Datos Fiscales", value: "0", id: "0" },
            { label: "Datos de establecimiento", value: "1", id: "1" },
        ];
        if (client == null) {
            client = {};
        }

        return (
            <form
                className="model-form"
                name="formulario"
                novalidate
                onSubmit={(e) => this.handleFormSubmitClientValue(e)}
            >
                <InfoBlock componentObject={this}>
                    <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal
                                    title={"Cliente"}
                                    subtitle={(this.isPos == 0 ? client.companyName : client.name) + " " + client.code}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}
                                />


                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={!this.isPos ? this.tabsData.getDataClient() : this.tabsData.getDataClientPos()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" col-12"}
                                />
                            </div>
                            :
                            <PageTitle
                                title={"Cliente"}
                                subtitle={(this.isPos == 0 ? client.companyName : client.name) + " " + client.code}
                            >
                                {util.hasValue(this.getFormId()) &&
                                <MoreButtons
                                    actions={this.isPos == 0 ?
                                        [
                                            {
                                                title: "Ver sus establecimientos",
                                                onClick: () => {
                                                    this.propsUtil.changeUrl(this.urlForClientFromClient(client))
                                                }
                                            }

                                        ] :
                                        [
                                            {
                                                title: "Ver en Pedidos", onClick: () => {
                                                    this.propsUtil.changeUrl(this.urlForOrderFromClient(client))
                                                }
                                            },
                                            {
                                                title: "Ver sus equipos", onClick: () => {
                                                    this.propsUtil.changeUrl(this.urlForAssetFromClient(client))
                                                }
                                            },
                                            {
                                                title: "Crear equipo", onClick: () => {
                                                    this.propsUtil.changeUrl(this.urlForFormAssetFromClient(client))
                                                }
                                            },
                                            {
                                                title: "Ver cliente",
                                                onClick: () => {
                                                    this.propsUtil.changeUrl(this.urlForClientFromClient(client))
                                                }
                                            }

                                        ]
                                    }
                                />
                                }
                            </PageTitle>
                        }


                        <AlertModal isOpen={this.state.errorFormulario && appState.layoutState.loadingForm}
                                    onCloseModal={() => this.clickErroresFormulario()}
                        >
                            <PopUp
                                title={t('Error')}
                                icon="fas fa-exclamation-triangle"
                                text={textoErrores}
                                label1={'Aceptar'}
                                onClick1={() => this.clickErroresFormulario()}
                            >
                            </PopUp>
                        </AlertModal>
                        <div className={'card mb-3'}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.isPos == 1 ? this.tabsData.getDataClientPos() : this.tabsData.getDataClient()}
                                active="Datos Generales"
                                fromRightModal={this.props.fromRightModal}

                            />
                            }
                            <div className={modalClasses.body}>
                                <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                   error={this.graphStatus.mutationError}
                                                   working={this.graphStatus.networkWorking}
                                />
                                <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                                    formError={this.graphStatus.formError}
                                />


                                <FormWrapper>
                                    {!util.hasValue(this.graphDataMainType.id) &&
                                    <RadioButtonComponent
                                        value={client.isPos == 1 ? '1' : '0'}
                                        styleLabel={'m-5'}
                                        name={"isPos"}
                                        checked={client.isPos == 1 ? '1' : '0'}
                                        options={optionsPropietary}
                                        classGroup={"col-6 mr-5"}
                                        onChange={(e) => this.updateInputEvent(e)}
                                    />
                                    }

                                    {(client.isPos == 0 || client.isPos == null) &&
                                    <GroupOfField
                                        title={t("Datos Fiscales")}
                                        icon="fas fa-file-invoice"
                                    >
                                        <InputTypeComponent
                                            value={client.code}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="code"
                                            title={t("Número de Cliente")}
                                            placeholder={t("Número de Cliente")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            validate={"alphanumeric"}
                                            errors={errorsMapped.code}
                                        />

                                        <InputTypeComponent
                                            required={true}
                                            value={client.companyName}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"companyName"}
                                            title={t("Razón Social")}
                                            placeholder={t("Razón Social")}
                                            classGroup={"col-6"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.name}

                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.vatID}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"vatID"}
                                            title={t("NIF")}
                                            placeholder={t("NIF")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.vatID}

                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.address}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"address"}
                                            title={t("Dirección Físcal")}
                                            placeholder={t("Dirección Físcal")}
                                            classGroup={"col-6"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.address}

                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.city}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"city"}
                                            title={t("Localidad")}
                                            placeholder={t("Localidad")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.city}

                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.district}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"district"}
                                            title={t("Provincia")}
                                            placeholder={t("Provincia")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.district}

                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.postalCode}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"postalCode"}
                                            title={t("Código Postal")}
                                            placeholder={t("CP")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            validate={"postalCode"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.postalCode}
                                        />

                                    </GroupOfField>
                                    }
                                    {client.isPos == 1 &&
                                    <GroupOfField
                                        title={t('Datos de Establecimiento')}
                                        icon='fas fa-store'
                                    >
                                        <InputTypeComponent
                                            value={client.code}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="code"
                                            title={t("Número de establecimiento")}
                                            placeholder={t("Número de establecimiento")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            validate={"alphanumeric"}
                                            errors={errorsMapped.code}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.name}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="name"
                                            title={t("Nombre")}
                                            placeholder="Nombre"
                                            classGroup={"col-6"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            readOnly={false}
                                            errors={errorsMapped.name}
                                        />

                                        <Select2Component
                                            value={client.parentId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"parentId"}
                                            title={t("Pertenece A")}
                                            classGroup={"col-7"}
                                            options={appState.clientCacheState.clientCacheForSelect}
                                            errors={errorsMapped.parentId}
                                        />
                                        <Select2Component
                                            value={client.chainId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"chainId"}
                                            title={t("Grupo")}
                                            classGroup={"col-3"}
                                            options={appState.typifiedState.chainsForSelect}
                                            errors={errorsMapped.parentId}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.posAddress}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posAddress"}
                                            title={t("Dirección Establecimiento")}
                                            placeholder={t("Dirección Establecimiento")}
                                            classGroup={"col-6"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posAddress}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.posCity}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posCity"}
                                            title={t("Localidad Establecimiento")}
                                            placeholder={t("Localidad Establecimiento")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posCity}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.posDistrict}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posDistrict"}
                                            title={t("Provincia")}
                                            placeholder={t("Provincia")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posDistrict}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.posPostalCode}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posPostalCode"}
                                            title={t('CP')}
                                            placeholder={t("CP")}
                                            classGroup={"col-3"}
                                            type={"text"}
                                            validate={"postalCode"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posPostalCode}
                                        />
                                        <Select2Component
                                            required={true}
                                            value={client.posZoneId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posZoneId"}
                                            title={t("Zona Establecimiento")}
                                            classGroup={"col-3"}
                                            options={appState.typifiedState.zonesForSelect}
                                            errors={errorsMapped.posZoneId}
                                        />
                                    </GroupOfField>
                                    }
                                </FormWrapper>
                                {this.props.fromRightModal ?
                                    <div className=" fixed-bottom">
                                        <FormButtons id={id} formStatus={this.graphStatus} />
                                    </div>
                                    :
                                    <FormButtons id={id} formStatus={this.graphStatus} />
                                }
                            </div>
                        </div>
                    </div>
                </InfoBlock>
            </form>

        )
    }


}

export default withRouter(withApollo(withTranslate(ClientForm, "ClientModel")));
