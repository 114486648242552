import React, { Component } from 'react';
import VsfModal from "../../components/listaddons/VsfModal";
import AppLogger from "../../util/AppLogger";
import withTranslate from "../../translator/withTranslate";
import VsfButton from "../../components/VsfButton";
import { observer } from "mobx-react";
import { action, observable, runInAction } from "mobx";
import DateInputHours from "../../components/fields/DateInputHours";
import appState from '../../state/AppState';
import WorkLogModel from "../../models/WorkLogModel";
import util from '../../util/Util';
import Select2Component from "../../components/fields/Select2Component";
import SlotModel from "../../models/SlotModel";
import GraphException from "../../network/GraphException";
import GqlErrors from "../../components/status/GqlErrors";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import PropsUtil from "../../util/PropsUtil";
import { withRouter } from "react-router-dom";

@observer
class StatusWorkOrderModal extends Component {
    @observable objWithWorkOrders = {};
    @observable objWithWorkLogs = {};
    @observable inicioJornada = {};
    @observable workOrdersStatus = {};
    @observable workOrdersStatusDict = {};
    @observable idWorkOrderFinalizada = {};
    type;
    subType;
    @observable    gqlErrors = null;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    getDateFromForGql() {
        return util.formatToISOString(util.getMomentFromDateWithoutTimezone(util.getMoment(this.props.slot.scheduledTime).format("YYYY-MM-DD") + "T00:00:00"));
    }

    getDateToForGql() {
        return util.formatToISOString(util.getMomentFromDateWithoutTimezone(util.getMoment(this.props.slot.scheduledTime).format("YYYY-MM-DD") + "T23:59:00"));
    }

    async loadStartDay() {
        this.log("loadStartDay");
        let dateFrom = this.getDateFromForGql();
        let dateTo = this.getDateToForGql();
        this.log(dateFrom);
        this.log(dateTo);

        let workLogModel = new WorkLogModel();
        workLogModel.filters = [
            { "fieldName": "action", "fieldValue": "START_DAY", "filterOperator": "EQ" },
            { "fieldName": "userId", "fieldValue": this.props.slot.userId, "filterOperator": "IN" },
            { "fieldName": "time", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "time", "fieldValue": dateTo, "filterOperator": "LTEQ" },
        ];
        let workLog = await workLogModel.findPlainObject();

        if (workLog.length > 0) {
            this.inicioJornada.startJourney = workLog[0].time;
            this.inicioJornada.startDateTimeBD = true;
        } else {
            this.inicioJornada.startDateTimeBD = false;
        }
    }

    async loadStartClient() {
        this.log("loadStartClient");
        let dateFrom = this.getDateFromForGql();
        let dateTo = this.getDateToForGql();

        let workLogModel = new WorkLogModel();
        workLogModel.filters = [
            { "fieldName": "action", "fieldValue": "START_VISIT", "filterOperator": "EQ" },
            { "fieldName": "clientId", "fieldValue": this.props.slot.clientId, "filterOperator": "IN" },
            { "fieldName": "orderId", "fieldValue": this.props.slot.orderId, "filterOperator": "IN" },
            { "fieldName": "time", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "time", "fieldValue": dateTo, "filterOperator": "LTEQ" },
        ];
        let workLog = await workLogModel.findPlainObject();
        this.log("loadStartClient");
        this.log(workLog);
        if (workLog.length > 0) {
            this.inicioJornada.startClientTime = workLog[0].time;
            this.inicioJornada.startClientTimeBD = true;
        } else {
            this.inicioJornada.startClientTimeBD = false;
        }
    }

    async componentDidMount() {
        this.log("componentDidMount");
        this.type = appState.typifiedState.arrWorkOrderType;
        this.subType = appState.typifiedState.arrSubTypeWorkOrders;
        await this.loadTypesStatus();
        await this.loadStartDay();
        await this.loadStartClient();
        await this.loadWorkOrders();
        this.inicioJornada.userId = this.props.slot.userId;
        this.inicioJornada.slotId = this.props.slot.id;
        this.inicioJornada.orderId = this.props.slot.orderId;
        this.inicioJornada.clientId = this.props.slot.clientId;
    }

    async loadTypesStatus() {
        let typifiedModel = new TypifiedValueModel();
        typifiedModel.type = "status";
        let typifiedStatus = await typifiedModel.find();
        this.idWorkOrderFinalizada = util.getDictSingleFromArray(typifiedStatus);
    }


    colorBox() {
        let slot = this.props.slot;
        let claseSlot = "";
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Preventiva") {
            claseSlot = " prevent ";
        }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Programada") {
            claseSlot = " program ";
        }
        // if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Evento") {
        //     claseSlot = " events ";
        // }
        if (appState.typifiedState.arrWorkOrderType[slot.typeWorkOrder] === "Correctiva") {
            claseSlot = " corrective ";
        }

        return claseSlot
    }

    getDictMultipleFromArray(arr, fieldName) {
        let result = {};
        for (let obj of arr) {
            let key = obj[fieldName];
            if (result[key] == null) {
                result[key] = [];
            }
            result[key].push(obj);
        }
        return result;
    }

    ordenarFechas() {
        let objWithFechas = {};
        for (let id of Object.keys(this.workOrdersStatus)) {
            let key = this.workOrdersStatus[id].endDateTime;
            objWithFechas[key] = this.workOrdersStatus[id];
            objWithFechas[key].workOrderId = id;
        }
        this.workOrdersStatusDict = objWithFechas


    }

    @action
    async loadWorkOrders() {
        this.log("loadWorkOrders");
        let objWithWorkOrders = {};
        for (let workOrder of appState.scheduleDropState.workOrdersSlot) {
            objWithWorkOrders[workOrder.id] = workOrder;
        }
        let objWithWorkOrderIds = Object.keys(objWithWorkOrders);
        let workLogModel = new WorkLogModel();
        workLogModel.filters = [
            { "fieldName": "workOrderId", "fieldValue": objWithWorkOrderIds.join(","), "filterOperator": "IN" },
        ];
        let workLogs = await workLogModel.find();
        let objWithWorkLogs = util.getDictMultipleFromArrayToArray(workLogs, "action");
        let workOrdersStatus = {};
        for (let idWorkOrder of Object.keys(objWithWorkOrders)) {
            let workOrder = objWithWorkOrders[idWorkOrder];
            workOrdersStatus[workOrder.id] = {
                id: workOrder.id, status: workOrder.status
            };
        }
        for (let action of Object.keys(objWithWorkLogs)) {
            for (let workOrderLog of objWithWorkLogs[action]) {
                if (workOrdersStatus[workOrderLog.workOrderId] && (
                    action === "END_WORK_ORDER" || action === "INPROCESS_WORK_ORDER" || action === "PAUSE_WORK_ORDER")) {
                    workOrdersStatus[workOrderLog.workOrderId].endDateTime = workOrderLog.time;
                    workOrdersStatus[workOrderLog.workOrderId].startDateTimeBD = true;
                    workOrdersStatus[workOrderLog.workOrderId].endDateTimeBD = true;
                } else {
                    workOrdersStatus[workOrderLog.workOrderId].startDateTimeBD = false;
                    workOrdersStatus[workOrderLog.workOrderId].endDateTimeBD = false;
                }
            }
        }
        runInAction(() => {
            this.objWithWorkLogs = objWithWorkLogs;
            this.objWithWorkOrders = objWithWorkOrders;
            this.workOrdersStatus = workOrdersStatus;
        });
        this.log(this.objWithWorkOrders)
    }

    updateInputEventStart(event) {
        this.inicioJornada[event.target.name] = event.target.value;
    }

    updateInputEventStatus(event, id) {
        this.log("updateInputEventStatus");
        this.workOrdersStatus[id][event.target.name] = event.target.value;
        this.log(this.workOrdersStatus[id]);

    }

    async startDay() {
        if (!this.inicioJornada.startDateTimeBD) {
            let workLogModel = new WorkLogModel();
            workLogModel.userId = this.props.slot.userId;
            workLogModel.time = this.inicioJornada.startJourney;
            workLogModel.action = "START_DAY";
            await workLogModel.persist();
        }
    }

    async startClient() {
        if (!this.inicioJornada.startClientTimeBD) {
            let workLogModel = new WorkLogModel();
            workLogModel.userId = this.inicioJornada.userId;
            workLogModel.clientId = this.inicioJornada.clientId;
            workLogModel.slotId = this.inicioJornada.slotId;
            workLogModel.orderId = this.props.slot.orderId;
            workLogModel.time = this.inicioJornada.startClientTime;
            workLogModel.action = "START_VISIT";
            await workLogModel.persist();

        }
    }

    async saveStart() {
        let startDay = this.inicioJornada.startClientTime;
        let workLogModel = new WorkLogModel();
        workLogModel.userId = this.props.slot.userId;
        workLogModel.slotId = this.props.slot.id;
        // Inserto en BD las fechas de inicio de las work Orders
        for (let id of Object.keys(this.workOrdersStatusDict)) {
            if (!this.workOrdersStatusDict[id].startDateTimeBD) {
                workLogModel.workOrderId = this.workOrdersStatusDict[id].workOrderId;
                workLogModel.action = "START_WORK_ORDER";
                workLogModel.orderId = this.inicioJornada.orderId;
                workLogModel.clientId = this.inicioJornada.clientId;
                workLogModel.time = startDay;
                await workLogModel.persist();
                workLogModel.id = "";
            }
            startDay = this.workOrdersStatusDict[id].endDateTime;
        }
    }

    async saveEnd() {
        let workLogModel = new WorkLogModel();
        workLogModel.userId = this.props.slot.userId;
        workLogModel.slotId = this.props.slot.id;
        // Inserto en BD las fechas de fin de las work Orders
        for (let id of Object.keys(this.workOrdersStatusDict)) {
            if (!this.workOrdersStatusDict[id].endDateTimeBD) {
                workLogModel.workOrderId = this.workOrdersStatusDict[id].workOrderId;
                workLogModel.orderId = this.inicioJornada.orderId;
                workLogModel.clientId = this.inicioJornada.clientId;
                workLogModel.action = this.workOrdersStatusDict[id].status;
                workLogModel.time = this.workOrdersStatusDict[id].endDateTime;
                await workLogModel.persist();
                workLogModel.id = "";
            }
        }
    }

    getAssetFromAssetId(assetId) {
        let result = {};
        for (let asset of appState.assetState.assets) {
            if (util.hasValue(assetId)) {
                if (asset.id === assetId) {
                    result = asset;
                    break;
                }
            }
        }
        return result;
    }

    async reloadSlot() {
        let optionTime = this.propsUtil.getRequest("optionTime") || "semana";
        let slotQuery = new SlotModel();
        slotQuery.addRelatedTable("workOrder");
        let slotModel = await slotQuery.findById(this.props.slot.id);
        appState.scheduleDropState.allSlotsDict[this.props.slot.id] = slotModel.toPlainObject();
        if (!appState.scheduleDropState.flagSlots) {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnits(optionTime);
        } else {
            appState.scheduleDropState.allSlotsGrouped = appState.scheduleDropState.getCacheWorkUnitsFlags();
        }

    }

    async saveStatus() {
        this.log("saveStatus");
        this.setState({
            loading: true
        });
        try {
            await this.ordenarFechas();
            await this.startDay();
            await this.startClient();
            await this.saveStart();
            await this.saveEnd();
            await this.reloadSlot();
            setTimeout(() => {
                appState.layoutState.slotview = false;
                this.props.closeModal();
            }, 3000)
        } catch (e) {
            this.log("componentDidMount. Exception");
            let gqlErrors = new GraphException().getErrorsFromException(e);
            this.log({ gqlErrors });
            this.gqlErrors = gqlErrors;
        }

        this.setState({
            loading: false
        });
    }

    render() {
        this.log("render");
        this.propsUtil = new PropsUtil(this.props);
        let classBackground = this.colorBox();
        let { t } = this.props;
        let objWithWorkOrderIds = Object.keys(this.objWithWorkOrders);
        let optionsSelect = [
            { label: "Finalizada", value: "END_WORK_ORDER" },
            { label: "Pausada-En proceso", value: "INPROCESS_WORK_ORDER" },
            { label: "Pausada", value: "PAUSE_WORK_ORDER" },
        ];
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client"
            >
                <div className="modal-push" style={{ display: "block" }}>
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{t('Finalizar')}<span>{" " + t('visita')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">

                        <div className="table d-flex">
                            <div className="mr-4">
                                <p className="c-modal-info-cell__text regular">{t("Inicio de la jornada")}</p>
                                {this.inicioJornada.startDateTimeBD ?
                                    util.getMoment(this.inicioJornada.startJourney).format("YYYY-MM-DD HH:mm")
                                    :
                                    <DateInputHours
                                        prefix={"fas fa-calendar-alt"}
                                        value={this.inicioJornada.startJourney || this.getDateFromForGql()}
                                        interval={5}
                                        minDate={this.getDateFromForGql()}
                                        onChange={(e) => this.updateInputEventStart(e)}
                                        name={"startJourney"}
                                        type={"text"}
                                        hours={true}
                                        allowEmptyDate={false}
                                    />
                                }
                            </div>
                            <div>
                                <p className="c-modal-info-cell__text regular">{t("Inicio de la visita ")}</p>
                                {this.inicioJornada.startClientTimeBD ?
                                    util.getMoment(this.inicioJornada.startClientTime).format("YYYY-MM-DD HH:mm")
                                    :
                                    <DateInputHours
                                        prefix={"fas fa-calendar-alt"}
                                        value={this.inicioJornada.startClientTime || this.getDateFromForGql()}
                                        interval={5}
                                        minDate={this.getDateFromForGql()}
                                        onChange={(e) => this.updateInputEventStart(e)}
                                        name={"startClientTime"}
                                        type={"text"}
                                        hours={true}
                                        allowEmptyDate={false}
                                    />
                                }
                            </div>
                        </div>

                        <div className="c-modal-info__cell__body">

                            <p className="c-modal-info-cell__text regular">{t("Selecciona el estado y la hora de finalización de todas las órdenes de trabajo") + ":"}</p>

                            {objWithWorkOrderIds && objWithWorkOrderIds.map(id => (
                                <div className={"c-modal-info-cell__box " + classBackground} key={id}>
                                    <table className="table visible-text"
                                           summary="Listado de contactos que se tienen agregados donde se muestra nombre, teléfonos, correo electrónico y se permite eliminar o agregar contacto">
                                        <tbody>
                                        <tr key={id}>
                                            <td width={400}>
                                                <tr>
                                                    <p className="c-modal-info-cell__text blue">{(this.type[this.objWithWorkOrders[id].type] || "") + " " + (this.subType[this.objWithWorkOrders[id].subType] || "")}</p>
                                                </tr>
                                                <tr>
                                                    <p className="c-modal-info-cell__text blue--italic"> {this.objWithWorkOrders[id].generalDescription}</p>
                                                </tr>
                                                <tr>
                                                    <ul className={this.objWithWorkOrders[id].status == this.idWorkOrderFinalizada.id ? "c-modal-info-cell__orders success " : "c-modal-info-cell__orders blue"}>
                                                        <li className="c-modal-info-cell__orders__item-status">{this.objWithWorkOrders[id].code}</li>
                                                        <li className="c-modal-info-cell__orders__item-status">{this.objWithWorkOrders[id].assetId != null ?
                                                            this.objWithWorkOrders[id].assetPlate : t("No asignado")}</li>
                                                        <li className="c-modal-info-cell__orders__item-status">{this.objWithWorkOrders[id].assetId != null ?
                                                            appState.typifiedState.arrAssetTypes[this.getAssetFromAssetId(this.objWithWorkOrders[id].assetId).type] : util.hasValue(appState.typifiedState.arrAssetTypes[this.objWithWorkOrders[id].assetType]) ? appState.typifiedState.arrAssetTypes[this.objWithWorkOrders[id].assetType] : t("No asignado")}{" "}</li>
                                                        <li className="c-modal-info-cell__orders__item-status">{this.objWithWorkOrders[id].assetId != null ?
                                                            appState.typifiedState.arrAssetSubtypes[this.getAssetFromAssetId(this.objWithWorkOrders[id].assetId).subType] : appState.typifiedState.arrAssetSubtypes[this.objWithWorkOrders[id].assetSubType] || t("No asignado")}{" "}</li>
                                                    </ul>
                                                </tr>

                                            </td>
                                            <td className="d-flex align-items-center" style={{ "minHeight": "57px" }}>

                                                <div className={"mt-3 mr-4"}>
                                                    {this.workOrdersStatus[id].endDateTimeBD ?
                                                        "Finalizada"
                                                        :
                                                        <Select2Component
                                                            value={this.workOrdersStatus[id].status}
                                                            onChange={(e) => this.updateInputEventStatus(e, id)}
                                                            name={"status"}
                                                            clearable={false}
                                                            classGroup={"col-12"}
                                                            options={optionsSelect}
                                                            // errors={errorsMapped.status}
                                                        />
                                                    }

                                                </div>
                                                <div className={"mt-3 mr-4"}>
                                                    {this.workOrdersStatus[id].endDateTimeBD ?
                                                        util.getMoment(this.workOrdersStatus[id].endDateTime).format("YYYY-MM-DD HH:mm")
                                                        :
                                                        <DateInputHours
                                                            prefix={"fas fa-calendar-alt"}
                                                            value={this.workOrdersStatus[id].endDateTime}
                                                            interval={5}
                                                            minDate={this.getDateFromForGql()}
                                                            onChange={(e) => this.updateInputEventStatus(e, id)}
                                                            name={"endDateTime"}
                                                            hours={true}
                                                            allowEmptyDate={false}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="modal-footer ">
                        <div className="mt-4">
                            <div className="d-flex justify-content-end mb-2">
                                <VsfButton
                                    label={this.props.slot.isFinished == 1 ? t('Salir') : t('Salir sin guardar')}
                                    classButton="btn btn-secondary btn--large"
                                    onClick={() => this.props.closeModal()}
                                />
                                {this.props.slot.isFinished != 1 &&
                                <VsfButton
                                    label={'Guardar'}
                                    loading={this.state.loading}
                                    classButton="btn btn--red btn--large with-icon-save ml-3 "
                                    onClick={() => this.saveStatus()}
                                />
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <GqlErrors errors={this.gqlErrors} />

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(withTranslate(StatusWorkOrderModal));
