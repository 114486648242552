import React, { Component } from 'react';
import { observer } from 'mobx-react';
import WorkOrderModel from "../models/WorkOrderModel";
import PropTypes from 'prop-types';
import util from '../util/Util';
import AppLogger from "../util/AppLogger";
import appState from "../state/AppState";

@observer
export default class WorkOrdersSelectInOrderForm extends Component {

    static defaultProps = {
        values: "",
        workOrders: [],
        fromArticles: true,
    };

    static propTypes = {
        values: PropTypes.string, //Listado separado por comas con los WorkOrderId
        workOrders: PropTypes.array, //Listado de todas las WorkOrders de este pedido
        fromArticles: PropTypes.bool //Si viene de Articulos no me muestra seleccionar todas
    };

    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    getAssetFromAssetId(assetId) {
        let result = {};
        for (let asset of appState.assetState.assets) {
            if (util.hasValue(assetId)) {
                if (asset.id === assetId) {
                    result = asset;
                    break;
                }
            }
        }
        return result;
    }

    render() {
        let values;
        if (typeof this.props.values === 'number') {
            values = this.props.values.toString();
        } else values = this.props.values;
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        let workOrdersOptions = this.props.workOrders.map((woJs) => {
            let workOrderModel = new WorkOrderModel();
            workOrderModel.hidrate(woJs);
            return (
                <tr className='dropdown-sublist__head'>

                    <td scope="row">
                        <input
                            type="checkbox"
                            style={{ display: "block" }}
                            name={"workorder-" + workOrderModel.id}
                            checked={util.perteneceA(workOrderModel.id, values)}
                            id={"workorder-" + workOrderModel.id}
                            value={workOrderModel.id}
                            onChange={(e) => this.props.onChange(e)}
                        />
                    </td>
                    {util.hasValue(workOrderModel.assetPlate) ?
                        <>
                            <td>
                                {workOrderModel.code}
                            </td>
                            <td>
                                {workOrderModel.assetPlate}
                            </td>
                            <td>
                                {util.getString(this.getAssetFromAssetId(workOrderModel.assetId).type)}
                            </td>
                            <td>
                                {util.getString(this.getAssetFromAssetId(workOrderModel.assetId).subType)}
                            </td>
                            <td>
                                {appState.typifiedState.arrWorkOrderType[workOrderModel.type]}
                            </td>
                            <td>
                                {appState.typifiedState.arrSubTypeWorkOrders[workOrderModel.subType]}
                            </td>
                        </>
                        :
                        <>
                            <td>
                                {workOrderModel.code}
                            </td>
                            <td>
                                {"No asignado"}
                            </td>
                            <td>
                                {"No asignado"}
                            </td>
                            <td>
                                {"No asignado"}
                            </td>
                            <td>
                                {appState.typifiedState.arrWorkOrderType[workOrderModel.type]}
                            </td>
                            <td>
                                {appState.typifiedState.arrSubTypeWorkOrders[workOrderModel.subType]}
                            </td>
                        </>
                    }
                </tr>
            )
        });
        return (
            <>
                {this.props.fromArticles &&
                <tr>
                    <td>
                        <input
                            autoComplete="off"
                            type="checkbox"
                            style={{ display: "block" }}
                            checked={util.perteneceA('-1', values)}
                            name={"allWO"}
                            id={"all"}
                            value={'-1'}
                            onChange={(e) => this.props.onChange(e)}
                        />
                    </td>
                    <td colSpan="7">Seleccionar Todas</td>
                </tr>
                }
                {workOrdersOptions}
            </>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
