import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { observer } from 'mobx-react';
import IconsInputsComponent from "./IconsInputsComponent";
import AppLogger from "../../util/AppLogger";
import { now } from "moment";
import util from '../../util/Util';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

registerLocale('es-ES', es);

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

@observer
export default class DateInputField extends React.Component {


    constructor(props) {
        super(props);
    }

    handleChange(date) {
        let eventObj;
        if (this.props.onChange != null) {
            eventObj = {
                target: {
                    name: this.props.name,
                    value: date.toISOString()
                }
            };
        }
        this.props.onChange(eventObj);
    }

    fechaSeleccionada() {
        let value = this.props.value;
        if (util.hasValue(value)) {
            value = new Date(this.props.value.substr(0, 10));
        } else {
            value = new Date(now());
        }
        return value;

    }

    render() {
        let seletedDateStr = this.fechaSeleccionada();

        let props = this.props;
        let form = this.form;
        if (form == null) {
            form = {};
        }
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        return (
            <div className={props.classGroup}>
                <div style={{ width: "200px" }}
                     className={"form-group DateInputField " + form.formCssClass}>

                    <label htmlFor={props.name}
                           className="control-label">{props.title}
                        {props.required && (<React.Fragment> *</React.Fragment>)}
                    </label>
                    {this.props.fromCalendar ?
                        <IconsInputsComponent
                            postfix={this.props.postfix}
                            prefix={this.props.prefix}
                        >
                            <>
                                <DatePicker
                                    selected={seletedDateStr}
                                    onChange={(date) => this.handleChange(date)}
                                    showYearDropdown
                                    dateFormat="dd-MM-yyyy"
                                    scrollableYearDropdown
                                    locale="es"
                                    yearDropdownItemNumber={15}
                                    className="form-control"
                                    timeCaption="Time"
                                    placeholderText={"DD-MM-YYY"}
                                />
                                <div className="js-info ">{props.info || " "}</div>
                            </>
                        </IconsInputsComponent>


                        :
                        <><IconsInputsComponent
                            postfix={this.props.postfix}
                            prefix={this.props.prefix}
                        />
                            <>
                                <DatePicker
                                    selected={seletedDateStr}
                                    onChange={(date) => this.handleChange(date)}
                                    showYearDropdown
                                    dateFormat="dd-MM-yyyy"
                                    scrollableYearDropdown
                                    locale="es"
                                    yearDropdownItemNumber={15}
                                    className="form-control"
                                    timeCaption="Time"
                                    placeholderText={"DD-MM-YYY"}
                                />
                                <div className="js-info ">{props.info || " "}</div>
                            </>
                        </>
                    }


                    {errors.map(error => <small className="js-error help-block text-danger">{error.message}</small>)}
                </div>

            </div>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

