import React, { Component } from 'react';
import { observer } from 'mobx-react';
@observer
export default class IconsInputsComponent extends Component {
    render() {
        let props= this.props;
        let withInputGroup = false;
        if (this.props.prefix || this.props.postfix) {
            withInputGroup=true;
        }
        return (
            <React.Fragment>
                {withInputGroup?
                    <div className={'input-group'}>
                        {props.prefix &&
                        <div className="input-group-prepend">
                                <span className={props.iconClass || "input-group-text"} style={{color: props.color}}>
                                    <span className={props.prefix}></span>
                                </span>
                        </div>
                        }

                        {props.children}
                        {props.postfix &&
                        <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className={props.postfix}></span>
                                </span>
                        </div>
                        }
                    </div>

                : <>
                    {
                        props.children
                    }
                    </>
                }


            </React.Fragment>
        );
    }
}
