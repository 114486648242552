import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { dir } from "async";
import PropTypes from 'prop-types';
import appState from "../state/AppState";
import withTranslate from "../translator/withTranslate";

@observer
class VsfButton extends Component {


    static propTypes = {
        onClick: PropTypes.func, //Función que se llama cuando se clicka el elemento
        label: PropTypes.string, //Valor del botón
        icon: PropTypes.string, //Valor del botón
        loading: PropTypes.bool, //Valor del botón
        disabled: PropTypes.bool, //Valor del botón
        classGroup: PropTypes.string, //Clase del div que envuelve al elemento
        classButton: PropTypes.string, //Clase del boton bootstrap
        type: PropTypes.string, //Tipo de botón. Si es submit, mandará el formulario con un enter.
        formButton: PropTypes.bool, //Si viene de un formulario está desactivado hasta que haya un cambio. En ese caso esta propiedad sea true
        isEditing: PropTypes.bool, //Si se esta editando un row de una tabla cambia
        messageOnLoading: PropTypes.string,//Mensaje que sale en la transiccion cuando esta loading
        messageOnSuccess: PropTypes.string,//Mensaje que sale en la transiccion cuando es success
        messageOnFailure: PropTypes.string,//Mensaje que sale en la transiccion cuando falla
    };
    static defaultProps = {
        onClick: () => {
        },
        label: "",
        icon: null,
        loading: false,
        disabled: false,
        classGroup: "",
        classButton: "",
        type: "button",
        formButton: false,
        isEditing: false,
        messageOnLoading: "Guardando",
        messageOnSuccess: "Guardado",
        messageOnFailure: "Error al guardar",
    };

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            clase: this.props.classButton,
            label: this.props.label,
            icono: this.props.icon
        }
    }

    componentWillReceiveProps(props) {
        if (props.isEditing) {
            this.setState({
                clase: 'btn--disabled',
            })
        } else {
            this.setState({
                clase: this.props.classButton,
            })
        }
        if (this.props.formButton) {
            if (!this.isStyleDisabled()) {
                this.setState({
                    clase: 'btn--loader',
                    label: props.label,
                    icono: props.icon,
                })
            }
        }
        clearTimeout(this.timeout);
        if (props.loading) {
            this.setState({
                show: true,
                label: this.props.messageOnLoading,
                clase: 'btn--loading',
                icono: "fas fa-spinner fa-pulse"
            })
        }
        if (!props.loading && this.state.show) {
            if (props.loadedError) {
                //por aqui entra cuando hay un fallo, si no hay un fallo es que es succes y entra por el else
                this.setState({
                    clase: 'btn--loading',
                    label: this.props.messageOnFailure,
                    icono: "fas fa-exclamation-triangle"
                });
                setTimeout(() => {
                    this.setState({
                        show: false,
                        clase: props.classButton,

                    });
                    appState.layoutState.formWithoutChanges = true;
                    appState.layoutState.loadingForm = false;
                }, 2000);
            } else {
                //cuando ya se ha cargado y es success
                this.setState({
                    icono: "far fa-check-circle",
                    clase: 'btn--loaded',
                    label: this.props.messageOnSuccess,
                });
                setTimeout(() => {
                    this.setState({
                        show: false,
                        clase: props.classButton,
                    });
                    appState.layoutState.formWithoutChanges = true;
                    appState.layoutState.loadingForm = false;

                }, 3000);
            }
        }
    }

    onClick() {
        if (this.isClickActive()) {
            this.props.onClick();
        }
    }

    isClickActive() {
        let result = true;
        if (this.isStyleDisabled()) {
            result = false;
        }
        return result;
    }

    isStyleDisabled() {
        let disabled;
        if (this.props.formButton) {
            disabled = appState.layoutState.formWithoutChanges;
        } else {
            disabled = this.props.disabled;
        }
        return disabled;
    }

    render() {
        const { t, i18n } = this.props;
        let disabled = this.isStyleDisabled();
        return (
            <div className={this.props.classGroup}>
                <button type={this.props.type} className={"btn " + this.state.clase}
                        onClick={() => this.onClick()}
                        disabled={disabled}
                >
                    <i className={this.state.icono} />
                    <span>{this.state.label && t(this.state.label)}</span>
                </button>
            </div>
        );
    }
}

export default withTranslate(VsfButton);
