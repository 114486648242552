import React from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import Select2Component from "../../components/fields/Select2Component"
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import FiltersPage from "../FiltersPage";

@observer
class UserListFilters extends FiltersPage {

    nameVariableStateFilter = "userListFilters";


    initialize() {
        super.initialize();
        //Indica que aunque sea un Date se debe hacer la llamada a GQL como DateTime completo
        //this.log("constructor() this.nameVariableStateFilter:"+this.nameVariableStateFilter);
        //this.log(toJS(appState[this.nameVariableStateFilter]));
        appState[this.nameVariableStateFilter].filterType["createdAt"] = "DateTime";
    }

    render() {
        // this.log("UserListFilters.render()");
        this.propsUtil = new PropsUtil(this.props);

        let { t } = this.props;
        return (

            <React.Fragment>
                <React.Fragment>

                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.role}
                        onChange={(e) => this.updateInputFilterEvent(e,"STRIN")}
                        name={"role"}
                        title={t("Rol")}
                        classGroup="col-md-12"
                        options={appState.typifiedState.rolesForSelect}
                        multi={true}
                        optionsWrappedWithCommas={false}
                    />

                    <Select2Component
                        value={appState[this.nameVariableStateFilter].currentFilter.zoneAsignedId}
                        onChange={(e) => this.updateInputFilterEvent(e,"IN")}
                        name="zoneAsignedId"
                        title={t("Zona")}
                        classGroup={"col-12"}
                        options={appState.typifiedState.zonesForSelect}
                        multi={true}
                        optionsWrappedWithCommas={false}
                    />

                    <InputTypeComponent
                        value={appState[this.nameVariableStateFilter].currentFilter.country}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"country"}
                        title={t("País")}
                        placeholder={t("País")}
                        classGroup={"col-4"}
                        type={"text"}
                    />

                    <InputTypeComponent
                        value={appState[this.nameVariableStateFilter].currentFilter.city}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"city"}
                        title={t("Localidad")}
                        placeholder={t("Ciudad")}
                        classGroup={"col-4"}
                        type={"text"}
                    />

                    <InputTypeComponent
                        value={appState[this.nameVariableStateFilter].currentFilter.company}
                        onChange={(e) => this.updateInputFilterEvent(e)}
                        name={"company"}
                        title={t("Empresa")}
                        placeholder={t("Empresa del usuario")}
                        classGroup={"col-4"}
                        type={"text"}
                    />

                    {/*<DateInputField*/}
                    {/*    birthDate={true}*/}
                    {/*    value={appState[this.nameVariableStateFilter].currentFilter.birthDate}*/}
                    {/*    onChange={(e) => this.updateInputFilterEvent(e)}*/}
                    {/*    name={"birthDate"}*/}
                    {/*    title={t("Fecha de Nacimiento")}*/}
                    {/*    classGroup={"col-4"}*/}
                    {/*    type={"text"}*/}
                    {/*    placeholder={t("Fecha de Nacimiento")}*/}
                    {/*/>*/}

                    {/*<InputTypeComponent*/}
                    {/*    value={appState[this.nameVariableStateFilter].currentFilter.idNumber}*/}
                    {/*    onChange={(e) => this.updateInputFilterEvent(e)}*/}
                    {/*    name={"idNumber"}*/}
                    {/*    title={t("NIF")}*/}
                    {/*    placeholder={t("NIF")}*/}
                    {/*    classGroup={"col-4"}*/}
                    {/*    type={"text"}*/}
                    {/*/>*/}


                    {/*<InputTypeComponent*/}
                    {/*    value={appState[this.nameVariableStateFilter].currentFilter.province}*/}
                    {/*    onChange={(e) => this.updateInputFilterEvent(e)}*/}
                    {/*    name={"province"}*/}
                    {/*    title={t("Provincia")}*/}
                    {/*    placeholder={t("Provincia")}*/}
                    {/*    classGroup={"col-4"}*/}
                    {/*    type={"text"}*/}
                    {/*/>*/}


                    {/*<InputTypeComponent*/}
                    {/*    value={appState[this.nameVariableStateFilter].currentFilter.postalCode}*/}
                    {/*    onChange={(e) => this.updateInputFilterEvent(e)}*/}
                    {/*    name={"postalCode"}*/}
                    {/*    title={t("Código Postal")}*/}
                    {/*    placeholder={t("Código Postal")}*/}
                    {/*    classGroup={"col-4"}*/}
                    {/*    type={"text"}*/}
                    {/*/>*/}

                    {/*<InputTypeComponent*/}
                    {/*    value={appState[this.nameVariableStateFilter].currentFilter.company}*/}
                    {/*    onChange={(e) => this.updateInputFilterEvent(e)}*/}
                    {/*    name={"company"}*/}
                    {/*    title={t("Empresa")}*/}
                    {/*    placeholder={t("Empresa del usuario")}*/}
                    {/*    classGroup={"col-4"}*/}
                    {/*    type={"text"}*/}
                    {/*/>*/}


                </React.Fragment>
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(withApollo(withTranslate(UserListFilters, "UserListFilters")));
