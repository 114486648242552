import React from 'react';
import { observer } from 'mobx-react';
import FormInputWrapper from "./FormInputWrapper";
import BaseFieldComponent from "./BaseFieldComponent";
import PropTypes from 'prop-types';
import AppLogger from "../../util/AppLogger";

@observer
export default class InputTypeComponent extends BaseFieldComponent {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        readOnly: PropTypes.bool, // Muestra el campo editable, pero con fondo gris en modo read Only
        showView: PropTypes.bool, // Muestra el campo en modo solo texto
    };

    static defaultProps = {
        readOnly: false,
        showView: false
    };

    constructor(props) {
        super(props);
        this.log("constructor()");

        this.log({ state: this.state, props: this.props });
    }

    componentDidMount() {
        this.state = {
            value: this.props.value != null ? this.props.value : ''
        }
    }

    onBlurFunction(e) {
        if (this.props.onBlur != null) {
            this.props.onBlur(e)
        } else {
            this.validateOnBlurFunction(e)
        }
    }

    render() {
        this.log("render()");
        this.log({ state: this.state, props: this.props });
        let props = this.props;
        let classNameInput = this.getClassNameInput();
        let value = props.value;
        if (value == null) {
            value = "";
        } else {
            value = props.value;
        }

        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                {...props}
            >
                {this.props.showView ?
                    <div>
                        {props.value}
                    </div>
                    :
                    <input
                        autoComplete="nope"
                        className={classNameInput}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={value}
                        onChange={e => this.validateOnChangeFunction(e)}
                        readOnly={props.readOnly}
                        type={props.type}
                        onBlur={e => this.onBlurFunction(e)}
                    />
                }
                {props.children}
            </FormInputWrapper>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


