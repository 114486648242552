import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/SearchComponent';
import ListComponent from '../../components/ListComponent';
import PaginationComponent from "../../components/PaginationComponent";
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
import BaseList from "../base/BaseList";
import EmailList from "../../components/list/EmailList";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import TextListItem from "../../components/list/TextListItem";
import BaseListFilter from "../../subpages/BaseListFilter";
import ClientModel from "../../models/ClientModel";
import MoreButtons from "../../components/MoreButtons";
import PropsUtil from "../../util/PropsUtil";
import withTranslate from "../../translator/withTranslate";
import ClientListFilters from "./ClientListFilters";
import AppLogger from "../../util/AppLogger";
import CreatedByListItem from "../../components/list/CreatedByListItem";
import ZoneColorList from "../../components/list/custom/ZoneColorList";

@observer
class ClientList extends BaseList {

    constructor(props) {
        super(props);
        this.quickView = true;
        this.includeSavedFavour = true;
        this.includeChangeColum = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.deleteFilter = this.deleteFilter.bind(this);
        this.nameVariableStateFilter = "clientListFilters";
    }

    getListFields() {
        return [
            {
                title: this.props.t("Código"),
                name: "code",
                link: true,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Razón Social"),
                name: "companyName",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("NIF"),
                name: "vatID",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Dirección"),
                name: "address",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Localidad"),
                name: "city",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Provincia"),
                name: "district",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: this.props.t("Código Postal"),
                name: "postalCode",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },

        ];
    }

    openQuickEdit(row) {
        this.getListState().quickEdit.row = row;
        this.propsUtil.changeUrlRequest({
            idOrderClient: row.id,
            rightModal: "modalclient",
            rightModalTab: "general"
        })
    }

    getListState() {
        return appState.clientState;
    }

    getDefaultOrder() {
        return "name";
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();

        // await appState.typifiedState.loadZones();
        // await appState.typifiedState.arrayLoadZones();
    }

    getModelQuery() {
        let result = new ClientModel();
        result.addRelatedTable("chain");
        result.addRelatedTable('posZone');

        // // return new ClientModel();
        return result;
    }

    render() {
        this.log("ClientList.render");
        this.propsUtil = new PropsUtil(this.props);
        let errorsMapped = this.getGraphErrorsFromStatus();
        let props = this.props;
        let { t } = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <div>
                <ListItemSelect
                    stateListColumnsSelect={this.stateListColumnsSelect}
                    name={this.graphOperation}
                    subtitle='Clientes'
                />
                <BaseListFilter title={'Clientes'} listComponent={this} stateFilter={this.stateListFilter}>
                    {this.renderFilters()}
                </BaseListFilter>

                {this.renderAbsoluteTextLoadingQuery()}

                {this.getListStatus().formError &&
                <div class="alert alert-danger" role="alert">
                    {errorsMapped[""] != null &&
                    <React.Fragment>
                        {errorsMapped[""].map(error => <div>{error.message}</div>)}
                    </React.Fragment>
                    }
                </div>
                }

                <PageTitle
                    title={t("Clientes")}
                    buttons={[{ label: 'Nuevo', iconName: "fas fa-plus-circle", link: '/client/form/' }]}
                > <MoreButtons
                    actions={
                        [
                            {
                                title: "Crear Nuevo Cliente", onClick: () => {
                                    this.propsUtil.changeUrl("/client/form/")
                                }
                            },

                        ]
                    }
                />
                </PageTitle>
                <div className="card mb-3">
                    <div className="card-body pb-0">
                        <SearchComponent deleteFilter={this.deleteFilter}
                                         listState={this.getListState()}
                                         listData={this.getListData()}
                                         numEntriesPerPage={this.numEntriesPerPage}
                                         fields={fields}
                                         onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                         includeSavedFavourites={this.includeSavedFavourites}
                                         includeChangeColumns={this.includeChangeColumns}
                                         includeFilters={this.includeFilters}
                                         includeDownload={this.includeDownload}
                                         includeSearch={this.includeSearch}
                                         stateListColumnsSelect={this.stateListColumnsSelect}
                                         {...props}
                                         model={this.getModelQuery()}
                                         listComponent={this}
                                         favouriteModalOpen={this.state.favouriteModalOpen}
                                         toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                        />
                        <ListComponent listState={this.getListState()}
                                       quickView={this.quickView}
                                       getListState={this.getListState()}
                                       listData={this.getListData()}
                                       status={this.getListStatus()}
                                       fields={fields} urlForm={"/client/form"}
                                       openQuickEdit={(row) => this.openQuickEdit(row)}
                                       {...props}>
                            <EmailList name="name" />
                        </ListComponent>
                    </div>
                </div>
                <PaginationComponent listData={this.getListData()}
                                     numEntriesPerPage={this.numEntriesPerPage} {...props} />
            </div>
        );
    }

    renderFilters() {
        let { t } = this.props;
        return (
            <React.Fragment>
                <ClientListFilters key={"clientlist"} />
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withTranslate(withApollo(ClientList));
