import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import Sidebar from "./sidebar";
import appState from "../state/AppState";
import CreateNew from "./CreateNew";
import VsfLoadingBar from "../network/VsfLoadingBar";
import UserButton from "./UserButton";
import RightModals from "../components/RightModals";
import InfoBlockSwitch from "../components/InfoBlockSwitch";
import VsfLink from "../components/VsfLink";
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";
import { observable } from "mobx";


@observer
class AppWraper extends Component {

    @observable user = {};

    constructor() {
        super();
        this.state = { "sidebarOpened": false }

    }

    clickHeaderToogler() {
        console.log("clickHeaderToogler");
        document.body.classList.add('header-hidden');
        document.body.classList.remove('header-minimized');
        this.setState({ "sidebarOpened": !this.state.sidebarOpened });
    }

    async componentDidMount() {
        open = true;
        let mail = localStorage.getItem('mail');
        await appState.typifiedState.loadRolUser();
        appState.typifiedState.arrayLoadRoles();

        this.user = await appState.loginState.getUser();
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


    render() {
        let mail = localStorage.getItem('mail');
        const { t, i18n } = this.props;
        let open = true;
        let props = this.props;
        let classApp = "";
        if (appState.layoutState.sidebarMinimized) {
            classApp = "App web-body header-minimized sidebar-minimized";
        } else {
            classApp = "App web-body";
        }
        this.log("appWrapper")
        //Si no estoy logado no muestro sidebar
        if (!appState.loginState.isUserLogged) {
            return (
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            )
        }
        return (
            <div className={classApp}>

                <header className="web-header">
                    <div className="web-header__left">
                        <VsfLink to="" className="header-brand">
                            <div className="red-square" />
                            <img src="/img/logo.png" alt="Nuddo" className="header-brand-full" />
                            <img src="/img/logo-small.png" alt="Nuddo" className="header-brand-minimized" />
                        </VsfLink>
                    </div>
                    <div className="web-header__right">
                        <button className={"header-minimizer sidebar-minimizer"}
                                onClick={() => appState.layoutState.clickHiddenMenu()}>
                            <i className="fa fa-bars"></i>
                        </button>
                        <div className="header-search ml-auto">
                            <form action="" method="get">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <select className="form-control">
                                            <option value="">Todo</option>
                                            <option value="Opcion 1">Opcion 1</option>
                                            <option value="Opcion 2">Opcion 2</option>
                                        </select>
                                    </div>
                                    <input type="text" className="form-control"
                                           aria-label="Text input with dropdown button"
                                           placeholder="Buscar ..." />
                                    <div className="input-group-append">
                                        <button className="btn" type="submit" aria-expanded="false"><i
                                            className="fa fa-search" /></button>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div className="web-header__right__end">
                            <CreateNew />
                            <div className="header-alerts dropdown">
                                <a href="#" className="alerts-dropdown" data-toggle="dropdown" role="button"
                                   aria-haspopup="true"
                                   aria-expanded="true"><i className="fa fa-bell" /> <span
                                    className="badge badge-danger" /></a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="dropdown-header text-center">
                                        <strong>You have 5 notifications</strong>
                                    </div>
                                    <a className="dropdown-item" href="#">
                                        <i className="fa fa-user text-success" /> New user registered
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fa fa-user text-danger" /> UserModel deleted
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fa fa-chart-bar text-info" /> Sales report is ready
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fa fa-shopping-cart text-primary" /> New client
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fa fa-chart-pie text-warning" /> Server overloaded
                                    </a>
                                </div>
                            </div>

                            <div className="header-user dropdown">
                                <UserButton
                                    userInfo={() => this.getUserHeader(mail)}
                                    user={this.user}
                                />
                                {appState.layoutState.userDropMenuOpen &&
                                <div className="dropdown-menu dropdown-menu-right" style={{ "display": "block" }}

                                >
                                    <div className="dropdown-header text-center">
                                        <strong>Account</strong>
                                    </div>
                                    <VsfLink className="dropdown-item" to="/profile/form">
                                        <i className="fa fa-user"></i>{t('Perfil')}</VsfLink>
                                    <div className="divider"></div>
                                    <VsfLink className="dropdown-item" to="/change/password">
                                        <i className="fa fa-shield"></i>{t('Cambiar contraseña')}</VsfLink>
                                    <VsfLink className="dropdown-item" onClick={(e) => {
                                        appState.loginState.doLogout();
                                    }}>
                                        <i className="fa fa-lock"></i>{t('Cerrar Sesion')}</VsfLink>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </header>

                <div className="web-main">
                    <Sidebar />
                    <main className="main">
                        <div className="container-fluid">
                            <RightModals />
                            {props.children}
                        </div>
                    </main>
                </div>

                <footer className="web-footer">
                    <div className="footer-poweredby">
                        Powered by:
                        <img src="/img/poweredby-logo.png" alt="Nuddo" />
                    </div>
                    <nav className="footer-nav ml-auto">
                        <ul className="nav">
                            <li><a href="#">{t("Politica de Privacidad")}</a></li>
                            <li><a href="#">{t("Aviso legal")}</a></li>
                            <li><a href="#">{t("Terminos de uso")}</a></li>
                        </ul>
                    </nav>
                </footer>
                <VsfLoadingBar />
                <InfoBlockSwitch />
            </div>
        );
    }

    getUserHeader(mail) {
        const { t, i18n } = this.props;
        return (
            <React.Fragment>
            <span className="user-info">
                <span className="user-name">
                    {t("Usuario")}
                </span>
                <span className="user-job">
                    {mail}
                </span>
            </span>
            </React.Fragment>
        )
    }

}

//withRouter es necesario para que AppWrapper pueda estar dentro de <Router> siendo @observable
export default withTranslate(withRouter(AppWraper));

