import React, {Component} from 'react';
import {observer} from 'mobx-react';
import FieldListComponent from './FieldListComponent';
import SortTableHeadComponent from './SortTableHeadsComponent';

import AppLogger from "../util/AppLogger";

import PropTypes from 'prop-types';
import SpinnerClipLoaderComponent from "../network/SpinnerClipLoaderComponent";

@observer
export default class ListComponent extends Component {

    static defaultProps = {
        openQuickEdit:()=>{}
    }

    static propTypes = {
        //Acci�n de pulsar en el bot�n de quick view
        openQuickEdit: PropTypes.func,
    }

    constructor(props){
        super(props);
        this.state = {
            scrollLeft: 0,
            scrollWidth: 0
        };
    };

    handleScroll(e) {
        let element = e.target;
        this.setState({
            scrollLeft: element.scrollLeft,
            scrollWidth: element.scrollWidth
        });
    };

    render() {
        let props= this.props;
        return (
            <div className="table-main-list" >
                <div className="table-responsive list-component" onScroll={ (e) => this.handleScroll(e)}>
                    {props.status.formLoading &&
                    <SpinnerClipLoaderComponent/>
                    }

                    <table id="table" className={"table"}>
                        <SortTableHeadComponent fields={props.fields} {...props} scrollLeft={this.state.scrollLeft} scrollWidth={this.state.scrollWidth/2}/>
                        <FieldListComponent urlForm={props.urlForm}
                                            {...props}
                                            listData={props.listData}
                                            status={props.status}
                                            openQuickEdit={props.openQuickEdit}
                                            fields={props.fields}  />
                    </table>
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
