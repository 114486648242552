import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DefaultRoute, withRouter } from 'react-router-dom'
import BaseForm from "../../pages/base/BaseForm";
import withTranslate from "../../translator/withTranslate";
import InfoBlock from "../../components/InfoBlock";
import moment from "moment/moment";
import GraphException from "../../network/GraphException";
import util from "../../util/Util";
import PropsUtil from "../../util/PropsUtil";
import ClientModel from "../../models/ClientModel";
import DateUtil from "../../util/DateUtil";
import ClientHourDrop from "./ClientHourDrop";
import WorkUnitInClient from "./WorkUnitInClient";
import appState from "../../state/AppState";
import SlotDetailView from "../../components/SlotDetailView";
import Select2Component from "../../components/fields/Select2Component";
import Links from "../../util/Links";

@observer
class ScheduleByClient extends BaseForm {
    nameMainType = "client";
    @observable clients = [];
    @observable currentUser = {};

    month = "02";

    constructor(props) {
        super(props);
        this.dateUtil = new DateUtil();
        let links = new Links(this.props.location);
        let currentFilterFromUrl = links.getCurrentFilterFromUrl();
        let currentFilter = {};
        let currentFilterOps = {};
        let currentFilterLabels = {};
        for (let [i, obj] of Object.entries(currentFilterFromUrl)) {
            currentFilter[obj.key] = obj.value;
            currentFilterOps[obj.key] = obj.op;
            currentFilterLabels[obj.key] = obj.label;
        }
        this.state = {
            stateFilter: false,
            currentFilter: currentFilter,
            currentFilterOps: currentFilterOps,
            currentFilterLabels: currentFilterLabels,
        }
    }

    getDateFrom() {
        let from = this.getDateFromIso().replace('-', '');
        return from;
    }

    /**
     * El parámetro date es de la forma 201902
     * @returns {*}
     */
    getDateFromIso() {
        let requestFecha = this.propsUtil.getRequest("date");
        let from;
        if (requestFecha == null) {
            from = moment().format("YYYY-MM-DD");
        } else {
            from = moment(requestFecha + "01").format("YYYY-MM-DD");
        }

        return from;
    }

    getMonth() {
        let from = ("" + this.getDateFrom()).substring(0, 6).replace(this.year, "");
        return from;
    }

    getWeeks() {
        return this.dateUtil.getWeeksFromDate(util.left(this.getDateFromIso(), 7) + "-01");
    }

    getDiasMes() {
        return this.dateUtil.getDiasMesFromDate(util.left(this.getDateFromIso(), 7) + "-01");
    }


    async componentDidMount() {
        this.log("componentDidMount()");
        try {
            this.year = 2019;
            this.month = this.getMonth();
            await appState.typifiedState.loadWorkOrderType();
            await appState.typifiedState.loadZones();
            await this.loadOrdersWithinMonth();
            appState.typifiedState.arrayLoadWorkOrderType();
            this.currentUser = await appState.loginState.getUser();
        } catch (e) {
            this.log("componentDidMount. Exception");
            let gqlErrors = new GraphException().getErrorsFromException(e);
            this.log({ gqlErrors });
            this.gqlErrors = gqlErrors;
        }
    }

    async loadOrdersWithinMonth() {
        this.log("loadSlotsWithinDates");
        let dateFrom = util.left(this.getDateFromIso(), 7).replace("-", "");
        await appState.scheduleDropState.loadClientsFromScheduledByClient(dateFrom);
    }

    onEditarCliente(id) {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalclient",
            "idOrderClient": id,
            "rightModalTab": "general"
        });
    }

    async changeYear(e) {
        let date = e.target.value + this.month;
        await this.propsUtil.changeUrlRequest({ date: date });
        let url = this.props.location.pathname + this.props.location.search;
        if (!appState.layoutState.arrayWithLocations.includes(url)) {
            appState.layoutState.arrayWithLocations.pop();
            appState.layoutState.arrayWithLocations.push(url);
            appState.layoutState.arrayWithLocations.push(url);
        }
        this.year = e.target.value;
        await this.loadOrdersWithinMonth();
    }

    renderColoresAndInputs() {
        const { t, i18n } = this.props;
        let options = [
            { label: "Enero", value: "01" },
            { label: "Febrero", value: "02" },
            { label: "Marzo", value: "03" },
            { label: "Abril", value: "04" },
            { label: "Mayo", value: "05" },
            { label: "Junio", value: "06" },
            { label: "Julio", value: "07" },
            { label: "Agosto", value: "08" },
            { label: "Septiembre", value: "09" },
            { label: "Octubre", value: "10" },
            { label: "Noviembre", value: "11" },
            { label: "Diciembre", value: "12" },
        ];
        let optionsYear = [
            { label: "2019", value: "2019" },
            { label: "2020", value: "2020" },
            { label: "2021", value: "2021" },
            { label: "2022", value: "2022" },
            { label: "2023", value: "2023" },
            { label: "2024", value: "2024" },
            { label: "2025", value: "2025" },
            { label: "2026", value: "2026" },
            { label: "2027", value: "2027" },
            { label: "2028", value: "2028" },
            { label: "2029", value: "2029" },
        ];
        return (<>
                <div className="color-info-wrapper">
                    <div className="color-info-wrapper__item">
                        <span className="color-info urgent" />
                        <p className="color-info-text">{t('urgente')}</p>
                    </div>
                    <div className="color-info-wrapper__item">
                        <span className="color-info preventive" />
                        <p className="color-info-text">{t('preventiva')}</p>
                    </div>
                    <div className="color-info-wrapper__item">
                        <span className="color-info corrective" />
                        <p className="color-info-text">{t('correctiva')}</p>
                    </div>
                    <div className="color-info-wrapper__item">
                        <span className="color-info tec-colaborate" />
                        <p className="color-info-text">{t('técnico colaborador')}</p>
                    </div>
                    <div className="color-info-wrapper__item">
                        <span className="color-info holidays" />
                        <p className="color-info-text">{t('vacaciones')}</p>
                    </div>
                    <div className="color-info-wrapper__item">
                        <span className="color-info other-events" />
                        <p className="color-info-text">{t('otros evento')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 ml-5">
                        <Select2Component
                            value={this.month}
                            onChange={(e) => this.changeDate(e)}
                            name={"scheduleDate"}
                            clearable={false}
                            classGroup={"col-12 mt-3"}
                            type={"text"}
                            options={options}
                        />
                    </div>
                    <div className="col-2 ">
                        <Select2Component
                            value={this.year}
                            classGroup={"col-12 mt-3"}
                            onChange={(e) => this.changeYear(e)}
                            name={"scheduleDate"}
                            clearable={false}
                            type={"text"}
                            forceMedia={this.props.fromRightModal ? "md" : ""}
                            options={optionsYear}
                        />
                    </div>
                    <div className="col-3 ">
                        <Select2Component
                            value={this.state.currentFilter.posZoneId}
                            onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                            name={"posZoneId"}
                            optionsWrappedWithCommas={false}
                            clearable={false}
                            operatorFilter={"STRIN"}
                            classGroup={"col-6 mt-3"}
                            options={appState.typifiedState.zonesForSelect}
                        />
                    </div>

                </div>
            </>
        )
    }

    async changeDate(e) {
        await this.propsUtil.changeUrlRequest({ date: this.year + e.target.value });
        let url = this.props.location.pathname + this.props.location.search;
        if (!appState.layoutState.arrayWithLocations.includes(url)) {
            appState.layoutState.arrayWithLocations.pop();
            appState.layoutState.arrayWithLocations.push(url);
            appState.layoutState.arrayWithLocations.push(url);
        }
        this.month = e.target.value;
        await this.loadOrdersWithinMonth();
    }

    /**
     * Devuelve un array con los indices de las WorkOrders
     * @param hour
     * @param technical
     * @param isoDate
     * @param emptyCell
     */
    getWorkUnitsIdFrom({ hour, client, isoDate }) {
        let cache = appState.scheduleDropState.allSlotsGrouped;
        let clientId = client && client.id;
        let key = util.getString(hour) + ";" + util.getString(clientId) + ";" + isoDate;
        let result = cache[key];
        if (result == null) {
            result = [];
        }
        return result;
    }

    render() {
        this.log("render()");
        const { t, i18n } = this.props;
        let links = new Links(this.props.location);
        const currentFilter = links.getCurrentFilterFromUrl();
        this.propsUtil = new PropsUtil(this.props);

        let dias = this.getDiasMes();
        let weeks = this.getWeeks();

        let particionesDia = [8, 14];
        let pixelsPerHour = 18;
        let numHoursSlot = 1;

        return (
            <InfoBlock componentObject={this}>
                <SlotDetailView
                    key={appState.scheduleDropState.slotClicked && appState.scheduleDropState.slotClicked.id}
                    slot={appState.scheduleDropState.slotClicked} />
                <div className="title-section">
                    <div className="row">
                        <div className="col-8 text-left d-md-flex align-items-md-center">
                            <h1 className="title title--inline">{t("Planificación por cliente")}</h1>
                            <h2 className="subtitle">                                {t('Calendario') + ' - ' + t('Time zone in') + " " + this.currentUser.timeZone}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="card mb-3">
                    <div className="card-body">
                        {this.renderColoresAndInputs()}

                        <div className="row">

                            <div className="filter-tags ml-5 mb-4">
                                <div className="filter-tags__left">
                                    <div className="filter-tags__block">
                                        {Object.entries(currentFilter).map(([index, valueObj]) => (

                                                <React.Fragment>
                                                    <label
                                                        className='m-2 filter-tags__block__label2'>{this.getNameFilter(valueObj)}:</label>
                                                    {
                                                        this.getInFilterValues(valueObj).map((filter, index) => {
                                                            return (
                                                                <ul key={index} className="filter-tags__block__tags"
                                                                    aria-labelledby="type-order">

                                                                    <li>
                                                                        <span
                                                                            className="tag-item tag-item--text">{filter.label}</span>
                                                                        <span
                                                                            onClick={() => this.deleteFilter(valueObj, filter.value, filter.label)}
                                                                            className="tag-item tag-item--close text-click"
                                                                            title="Cerrar etiqueta">
                                                                    <span className="fas fa-times"> </span>
                                                                </span>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-second second mb-3">

                            <div className="table-responsive">
                                <table className={"table drop-work-unit"}>
                                    <caption>{t("Planificación Cargas de Trabajo")} </caption>
                                    <thead>
                                    {weeks &&
                                    <tr>
                                        <td className={"week"}>

                                        </td>
                                        {weeks.map(week => (
                                            <td key={"week" + "-" + week.firstDay}
                                                className={"week overflow-text pr-0 pl-0"}
                                                colSpan={week.colspan}>
                                                {week.firstDay} {week.monthName.substr(0, 3)}
                                            </td>
                                        ))}
                                    </tr>
                                    }
                                    <tr>
                                        <th className="th-bottom__item">
                                            <ul className="th-bottom text th-cliente">
                                                <li className="th-bottom__item ">
                                                    {t("Establecimientos")}</li>
                                                <li className="th-bottom__item">
                                                    {t("Cargas de trabajo")}</li>
                                            </ul>
                                        </th>
                                        {dias.map(dia => (
                                            <td scope="col" key={"head" + "-" + dia.isoDate}
                                                className={"day p-0 weekDay-" + dia.weekDay}>
                                                {/*<b>{dia.isoDate}</b>*/}
                                                <ul className="th-bottom num" style={{ width: "36px" }}>
                                                    <li className="th-bottom__item"> {dia.dayOfMonth}<span
                                                        className="day"> {dia.labelWeekDay}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {appState.scheduleDropState.clients &&
                                    appState.scheduleDropState.clients.map(client => (
                                        <tr key={client.id}>
                                            <th scope="row">
                                                <div className="th-bottom text">
                                                        <span className="th-bottom__item text-click"
                                                              onClick={() => {
                                                                  this.onEditarCliente(client.id);
                                                              }}
                                                        >{client.name}</span>

                                                    {appState.scheduleDropState.slotsFromClientDict[client.id] &&
                                                    appState.scheduleDropState.slotsFromClientDict[client.id].map((slotId) => (
                                                        <WorkUnitInClient inClientBlock={true}
                                                                          slotId={slotId} />
                                                    ))}
                                                </div>
                                            </th>
                                            {dias.map(dia => (
                                                <td key={client.name + "-" + dia.isoDate}
                                                    className={"weekDay-" + dia.weekDay} style={{ width: '18px' }}>

                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            {particionesDia.map((hour, i) => (
                                                                <ClientHourDrop key={i} hour={hour} client={client}
                                                                                isoDate={dia.isoDate}
                                                                                finSemana={dia.weekDay == 6 || dia.weekDay == 7}
                                                                                loadClients={() => this.loadOrdersWithinMonth()}
                                                                                pixelsPerHour={pixelsPerHour}
                                                                                numHoursSlot={numHoursSlot}>
                                                                    {/*<span title={JSON.stringify({hour,technical:tecnico,isoDate:dia.isoDate,emptyCell:null})}></span>*/}
                                                                    {this.getWorkUnitsIdFrom({
                                                                        hour,
                                                                        client: client,
                                                                        isoDate: dia.isoDate,
                                                                    }).map(slotId => (
                                                                        <>
                                                                            <WorkUnitInClient
                                                                                pixelsPerHour={pixelsPerHour}
                                                                                hour={hour}
                                                                                isoDate={dia.isoDate}
                                                                                slotId={slotId} />
                                                                        </>
                                                                    ))}
                                                                </ClientHourDrop>
                                                            ))}
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div className="table-third mt-5">
                                    <table className="table"
                                           summary="Número total de horas de trabajo planificadas">
                                        <tbody>
                                        <tr>
                                            <th scope="row">
                                                <div className="th-top th-top-cliente">
                                                    {t("Planificación Cargas de Trabajo")}
                                                </div>
                                                <div className="th-bottom text">
                                                    {t("Horas planificadas")}
                                                </div>
                                            </th>
                                            <td>
                                                <ul className="th-bottom num">
                                                    {dias.map((dia, index) => (
                                                        <>
                                                            <li className="th-bottom__item th-bottom-client">
                                                                <div className="left">
                                                                    <div className="th-top "
                                                                         style={{ 'text-align': 'right' }}>
                                                                        {dia.dayOfMonth}
                                                                    </div>
                                                                    <div className="contenedor-client">

                                                                        {appState.scheduleDropState.horas.manyana && appState.scheduleDropState.horas.manyana[dia.dayAndMonth] ?
                                                                            <>
                                                                                {appState.scheduleDropState.horas.manyana[dia.dayAndMonth] > 10 ?
                                                                                    <div
                                                                                        className="th-bottom calc-hour10">
                                                                                    </div>
                                                                                    :
                                                                                    <div
                                                                                        className={"th-bottom calc-hour" + appState.scheduleDropState.horas.manyana[dia.dayAndMonth]}>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <div
                                                                                className={"th-bottom calc-hour"}>
                                                                            </div>
                                                                        }
                                                                        <div className={"hour-client"}>
                                                                            {appState.scheduleDropState.horas.manyana && appState.scheduleDropState.horas.manyana[dia.dayAndMonth] !== 0 && appState.scheduleDropState.horas.manyana[dia.dayAndMonth]}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="right">
                                                                    <div className="th-top ">
                                                                        {dia.labelWeekDay}
                                                                    </div>
                                                                    <div className="contenedor-client">
                                                                        {appState.scheduleDropState.horas.tarde && appState.scheduleDropState.horas.tarde[dia.dayAndMonth] ?
                                                                            <>
                                                                                {appState.scheduleDropState.horas.tarde[dia.dayAndMonth] > 10 ?
                                                                                    <div
                                                                                        className="th-bottom calc-hour10">
                                                                                    </div>
                                                                                    :
                                                                                    <div
                                                                                        className={"th-bottom calc-hour" + appState.scheduleDropState.horas.tarde[dia.dayAndMonth]}>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <div
                                                                                className={"th-bottom calc-hour"}>
                                                                            </div>
                                                                        }
                                                                        <div className={"hour-client"}>
                                                                            {appState.scheduleDropState.horas.tarde && appState.scheduleDropState.horas.tarde[dia.dayAndMonth] !== 0 && appState.scheduleDropState.horas.tarde[dia.dayAndMonth]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </InfoBlock>
        )
    }


    getFiltersFromStateForUrl(currentFilterState) {
        let filtersArray = [];
        for ([key, value] of Object.entries(currentFilterState)) {
            let fieldName = util.getDelim(key, "_", 0);
            let op = this.state.currentFilterOps[key];
            let label = this.state.currentFilterLabels[key];
            if (op == null) {
                op = "EQ";
            }
            if (label == null) {
                label = value;
            }
            if (value != null && value !== "") {
                filtersArray.push({
                    "fieldKey": key,
                    "fieldName": fieldName,
                    fieldValue: value,
                    filterOperator: op,
                    fieldLabel: label
                })

            }
        }
        return filtersArray;
    }

    async updateInputFilterEvent(event, op) {
        let name = event.target.name;
        let value = event.target.value;
        let label = event.target.label;
        if (op == null) {
            op = "EQ";
        }
        let currentFilter = { ...this.state.currentFilter, [name]: value };
        let currentFilterOps = { ...this.state.currentFilterOps, [name]: op };
        let currentFilterLabels = { ...this.state.currentFilterLabels, [name]: label };
        this.setState({ currentFilter, currentFilterOps, currentFilterLabels });
        let date = this.propsUtil.getRequest("date");
        let newUrl = '?filters=[{\"fieldKey\":\"posZoneId\",\"fieldName\":\"posZoneId\",\"fieldValue\":\"' + value + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + label + '\"}]';
        if (date != null) {
            newUrl += '&date=' + date;
        }
        if (!appState.layoutState.arrayWithLocations.includes(newUrl)) {
            appState.layoutState.arrayWithLocations.pop();
            appState.layoutState.arrayWithLocations.push(newUrl);
            appState.layoutState.arrayWithLocations.push(newUrl);
        }
        appState.scheduleDropState.zona = value;
        this.props.history.push(newUrl);
        await this.loadOrdersWithinMonth();

    }

    getNameFilter(valueObj) {
        let result = this.getTitle(valueObj.name);
        if (valueObj.op == "GT") {
            result += " (Desde)";
        }
        if (valueObj.op == "LT") {
            result += " (Hasta)";
        }
        return result;
    }

    getTitle(nameField) {
        let model = new ClientModel();
        let result = nameField;
        if (model != null) {
            result = model.getLabelFromFieldName(nameField);
        }
        return result;
    }

    getInFilterValues = (filter) => {
        let filterKeysArray = [];
        let filterKeysArrayWithoutCommas = [];
        if (filter.label != null) {
            filterKeysArray = filter.label.split(",");
        } else {
            filterKeysArray = filter.value.split(",");
        }
        filterKeysArray.map((label) => {
            // filterKeysArrayWithoutCommas.push(label.slice(1, -1));
            filterKeysArrayWithoutCommas.push(label);
        });
        let filterValuesArray = filter.value.split(",");
        let filterValueArrayWithoutCommas = [];
        filterValuesArray.map((value) => {
            filterValueArrayWithoutCommas.push(value.slice(1, -1));
        });
        let filterArray = [];
        for (var i = 0; i < filterKeysArrayWithoutCommas.length; i++) {
            let filterObj = {};
            filterObj.label = filterKeysArrayWithoutCommas[i];
            filterObj.value = filterValueArrayWithoutCommas[i];
            filterArray.push((filterObj));
        }
        return filterArray;
    };

    async deleteFilter(filter, value = null, label = null) {

        this.log("deleteFilter");

        let newCurrentFilter;
        let newCurrentLabels;

        let currentFilterState = this.state;

        if (filter.op == "IN" || filter.op == "STRIN") {

            newCurrentFilter = currentFilterState.currentFilter;
            newCurrentLabels = currentFilterState.currentFilterLabels;

            // Eliminar values

            let values = newCurrentFilter[filter.key];
            let newValues = "";
            if (values != null) {
                newValues = values.replace("" + value + ",", "");
            }

            if (values == newValues) {
                newValues = values.replace("," + value + "", "");
            }

            if (values == newValues) {
                newValues = values.replace("" + value + "", "");
            }

            newCurrentFilter[filter.key] = newValues;

            // Eliminar labels

            let labels = newCurrentLabels[filter.key];

            if (labels != null) {

                let newLabels = labels.replace("/'" + label + "/',", "");

                if (labels == newLabels) {
                    newLabels = labels.replace(",/'" + label + "/'", "");
                }

                if (labels == newLabels) {
                    newLabels = labels.replace("/'" + label + "/'", "");
                }

                newCurrentLabels[filter.key] = newLabels;

                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                    delete newCurrentLabels[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter,
                        currentFilterLabels: newCurrentLabels
                    });
                }

            } else {

                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter
                    });
                }

            }

        } else {
            newCurrentFilter = currentFilterState.currentFilter;
            delete newCurrentFilter[filter.key];
            newCurrentLabels = currentFilterState.currentFilterLabels;
            delete newCurrentLabels[filter.key];
        }

        let filtersArray = this.getFiltersFromStateForUrl(newCurrentFilter);

        let links = new Links(this.props.location);
        let newUrl = links;
        if (filtersArray.length != 0) {
            newUrl = links.getUrlLinkWithFilters(filtersArray);
        }
        appState.scheduleDropState.zona = null;

        this.props.history.push(newUrl);

        await this.loadOrdersWithinMonth();

    }
}


export default withRouter(withTranslate(ScheduleByClient));
