import React from 'react';
import { observer } from 'mobx-react';
// import { toJS } from 'mobx';
import appState from "../../state/AppState";
import { withApollo } from "react-apollo";
// import InputTypeComponent from "../../components/fields/InputTypeComponent";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import Select2Component from "../../components/fields/Select2Component"
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import FiltersPage from "../FiltersPage";
import DateIntervalInputField from "../../components/fields/DateIntervalInputField";

@observer
class WorkOrderListFilters extends FiltersPage {

    nameVariableStateFilter = "workOrderListFilters";

    initialize() {
        super.initialize();
        //Indica que aunque sea un Date se debe hacer la llamada a GQL como DateTime completo
        //this.log("constructor() this.nameVariableStateFilter:"+this.nameVariableStateFilter);
        //this.log(toJS(appState[this.nameVariableStateFilter]));
        appState[this.nameVariableStateFilter].filterType["createdAt"]="DateTime";

        // Tipo y subtipo de tipo de orden de trabajo

        // appState.typifiedState.filterSubTypeWorkOrders = true;
        appState.typifiedState.loadTypifiedValues("workOrderType");

    }

    componentWillUnmount(){
        appState.typifiedState.filterSubTypeWorkOrders = false;
    }

    render() {
        this.log("WorkOrderListFilters.render()");
        this.propsUtil = new PropsUtil(this.props);

        let { t } = this.props;

        return (
            <React.Fragment>
                <DateIntervalInputField
                    prefix={"fa fa-calendar"}
                    value={appState[this.nameVariableStateFilter].currentFilter.createdAt_From}
                    valueTo={appState[this.nameVariableStateFilter].currentFilter.createdAt_To}
                    onChange={(e) => this.updateInputFilterEvent(e, "GTEQ")}
                    onChangeTo={(e) => this.updateInputFilterEvent(e, "LTEQ")}
                    name={'createdAt_From'}
                    nameTo={'createdAt_To'}
                    title={t("Fecha Creación")}
                    title2={t("Fecha Hasta")}
                    classGroup={"col-8"}
                    type={"text"}
                    info={t("Desde")}
                    info2={t("Hasta")}
                    // errors={errorsMapped.interventionProposedDate}
                />
                <Select2Component
                    onChange={(e) => this.updateInputFilterEvent(e, "IN")}
                    value={appState[this.nameVariableStateFilter].currentFilter.status}
                    name="status"
                    title={t("Estado")}
                    classGroup={"col-lg-12"}
                    operatorFilter={"STRIN"}
                    optionsWrappedWithCommas={false}
                    options={appState.typifiedState.statusForSelect}
                    multi={true}
                />

                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.type}
                    name="type"
                    operatorFilter={"IN"}
                    optionsWrappedWithCommas={false}
                    title={t("Tipo Orden de Trabajo")}
                    classGroup={"col-12"}
                    options={appState.typifiedState.workOrderTypeForSelect}
                    multi={true}
                />
                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.subType}
                    name="subType"
                    operatorFilter={"IN"}
                    optionsWrappedWithCommas={false}
                    title={t("Subtipo Orden de Trabajo")}
                    classGroup={"col-12"}
                    options={appState.typifiedState.typifiedValuesForSelectLineal}
                    multi={true}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.clientId}
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    name="clientId"
                    title={t("Establecimiento")}
                    classGroup={"col-12"}
                    optionsWrappedWithCommas={false}
                    options={appState.clientCacheState.clientCacheForOrderSelect}
                    multi={true}
                    //errors={errorsMapped.clientName}
                />

                {/*<Select2Component*/}
                    {/*value={appState[this.nameVariableStateFilter].currentFilter.posZoneId}*/}
                    {/*onChange={(e, op) => this.updateInputFilterEvent(e, op)}*/}
                    {/*name="posZoneId"*/}
                    {/*title={t("Zona")}*/}
                    {/*classGroup={"col-12"}*/}
                    {/*optionsWrappedWithCommas={false}*/}
                    {/*options={appState.typifiedState.zonesForSelect}*/}
                    {/*multi={true}*/}
                    {/*//errors={errorsMapped.clientName}*/}
                {/*/>*/}


                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.assignedToMainId}
                    name="assignedToMainId"
                    title={t("Técnico principal")}
                    optionsWrappedWithCommas={false}
                    classGroup={"col-lg-12"}
                    options={appState.userCacheState.userCacheForSelectList}
                    multi={true}
                />
                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.assetPlate}
                    name="assetPlate"
                    title={t("Número de Equipo")}
                    classGroup={"col-lg-12"}
                    options={appState.typifiedState.assetsPlateForSelect}
                />

            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}


export default withRouter(withApollo(withTranslate(WorkOrderListFilters, "WorkOrderListFilters")));
