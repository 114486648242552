import React, {Component} from 'react';
import {observer} from 'mobx-react';
import util from "../../util/Util";
import {observable} from "mobx";

@observer
export default class DateListItem extends Component {

    @observable value = "";

    componentDidMount() {
        let props = this.props;
        let value = props.value;
        if (util.hasValue(value)) {
            let fecha = new Date(value);
            this.value = util.getMoment(fecha).format("DD-MM-YYYY HH:mm");
        } else {
            this.value = "";
        }
    }

    render() {

        return (
            <div>
                {this.value}
            </div>
        );
    }
}
